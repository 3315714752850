import Modal from "modal-enhanced-react-native-web";
import React, { useEffect } from "react";
import {
  Dimensions,
  Platform,
  View,
} from "react-native";
import Overlay from "react-native-modal-overlay";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setCURRENTDEVICEWIDTHACTION } from "../actionCreator/CurrentDeviceWidthAction";
import { setFEEDREPORTMODALACTION } from "../actionCreator/FeedReportModalAction";
import { resetHomefeedList } from "../actionCreator/HomeFeedAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setRESETPASSWORDMODALACTION } from "../actionCreator/ResetPasswordModalAction";
import { setSHARELINKMODALACTION } from "../actionCreator/ShareLinkModalAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import { setLoginStatus } from "../actionCreator/UserAction";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import { setVERIFYEMAILMODALACTION } from "../actionCreator/VerifyEmailModalAction";
import AddUsernameCard from "../components/AddUsernameCard";
import FeedReportModal from "../components/FeedReportModal";
import LinkAddAlertCard from "../components/LinkAddAlertCard";
import ResetPassword from "../components/ResetPassword";
import SignupInvite from "../components/SignupInvite";
import LoginModal from "./LoginModal";
import SignUpModalStep1 from "./SignUpModalStep1";
import { setInviteSIGNUPMODALACTION } from "../actionCreator/InviteSignUpModalAction";

import SignUpMessage from "./SignUpMessage";
import EarningModalMsg from "./EarningModalMsg";
import LeftPanel from "./LeftPanel";
import PostShareModel from "./PostShareModel";
import SubscriptionAlert from "./subscriptionAlert";
import { subscriptionAlertOpen } from '../reducers/action/Completed';


const Layout = (props) => {
  useEffect(() => {
    props.__resetHomefeedList({ identifier: "reset" });
  }, []);

  const onClose = () => {
    props.setLoginModalStatus(false);
    props.setSignUpModalStatus(false);
    props.setUsernameModalStatus(false);
    props.setResetPasswordModalStatus(false);
    props.setVerifyEmailModalStatus(false);
    props.setShareLinkModalStatus(false);
    props.setFeedReportModalStatus(false);
    props.setFeedShareModalStatus(false);
    props.setInviteSignUpModalStatus(false);
    props.setMessageModalStatus(false);
    props.setEarningModalStatus(false);
    props.openCreateComment(false);
    props.leftPanelModalFunc(false);
    props.setPostShareModel(false);
    props.subscriptionAlertOpen(false)
  };

  const handleLoginButton = () => {
    props.setSignUpModalStatus(false);
    props.setLoginModalStatus(true);
    props.setInviteSignUpModalStatus(false);
  };

  const handleSignButton = () => {
    props.setSignUpModalStatus(true);
    props.setLoginModalStatus(false);
    props.setInviteSignUpModalStatus(false);
  };

  const handleInviteSignButton = () => {
    props.setUsernameModalStatus(true);
    props.setLoginModalStatus(false);
    props.setInviteSignUpModalStatus(false);
  };

  return (
    <View
      onLayout={(event) => {
        let { width, height } = event.nativeEvent.layout;
        props.__setCURRENTDEVICEWIDTHACTION(width);
      }}
    >
      {props.getShareLinkModalStatus == true ? (
        Platform.OS !== "web" ? (
          <Overlay
            animationType="zoomIn"
            visible={props.getShareLinkModalStatus}
            onClose={onClose}
            closeOnTouchOutside
            childrenWrapperStyle={{
              margin: 0,
              padding: 0,
              borderRadius: 6,
            }}
            children={<LinkAddAlertCard onClose={onClose} />}
          />
        )
          :
          (
            <Modal
              isVisible={props.getShareLinkModalStatus}
              onBackdropPress={onClose}
              style={{
                marginHorizontal:
                  Dimensions.get("window").width > 750 ? "30%" : 10,
                padding: 0,
              }}
            >
              <LinkAddAlertCard onClose={onClose} />
            </Modal>
          )
      ) : null}

      {props.getFeedReportModalStatus == true ? (
        Platform.OS !== "web" ? (
          <Overlay
            animationType="zoomIn"
            visible={props.getFeedReportModalStatus}
            onClose={onClose}
            closeOnTouchOutside
            children={<FeedReportModal onClose={onClose} />}
            childrenWrapperStyle={{
              padding: 0,
              margin: 0,
              borderRadius: 6,
            }}
          />
        ) : (
          <Modal
            isVisible={props.getFeedReportModalStatus}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 10,
              padding: 0,
            }}
          >
            <FeedReportModal onClose={onClose} />
          </Modal>
        )
      ) : null}


      {props.getResetPasswordModalStatus == true ? (
        Platform.OS !== "web" ? (
          <Overlay
            animationType="zoomIn"
            visible={props.getResetPasswordModalStatus}
            onClose={onClose}
            closeOnTouchOutside
            children={<ResetPassword onClose={onClose} />}
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          />
        ) : (
          <Modal
            isVisible={props.getResetPasswordModalStatus}
            onBackdropPress={onClose}
            style={{
              padding: 0,
              alignItems: Dimensions.get("window").width >= 750 && "center",
              justifyContent: "center",
            }}
          >
            <ResetPassword onClose={onClose} />
          </Modal>
        )
      ) : null}

      {props.getMessageModalStatus == true ? (
        Platform.OS != "web" ? (
          <Overlay
            animationType="zoomIn"
            visible={props.getMessageModalStatus}
            onClose={onClose}
            children={<SignUpMessage onClose={onClose} />}
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6
            }}
          />
        ) : (
          <Modal
            isVisible={props.getMessageModalStatus}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              paddingHorizontal: 10,
            }}
          >
            <View
              style={{
                paddingVertical: 10,
              }}
            >
              <SignUpMessage onClose={onClose} />
            </View>
          </Modal>
        )
      ) : null}





      {props.getSignUpModalStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getSignUpModalStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
            }}
          >
            <View
              style={{
                paddingVertical: 10,
              }}
            >
              <SignUpModalStep1
                onClose={onClose}
                navigate={props.navigation}
                handleLoginButton={handleLoginButton}
              />
            </View>
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getSignUpModalStatus}
            onClose={onClose}
            children={
              <SignUpModalStep1
                onClose={onClose}
                navigate={props.navigation}
                handleSignButton={handleSignButton}
              />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6
            }}
          ></Overlay>
        )
      ) : null}

      {props.getUsernameModalStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getUsernameModalStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              padding: 0,
              alignItems: Dimensions.get("window").width >= 750 && "center",
              justifyContent: "center",
            }}
          >
            <AddUsernameCard onClose={onClose} navigate={props.navigation} />
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getUsernameModalStatus}
            onClose={onClose}
            children={
              <AddUsernameCard onClose={onClose} navigate={props.navigation} />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          />
        )
      ) : null}

      {props.getLoginModalStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getLoginModalStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              padding: 0,
              alignItems: Dimensions.get("window").width >= 750 && "center",
              justifyContent: "center",
            }}
          >
            <LoginModal
              onClose={onClose}
              navigate={props.navigation}
              handleSignButton={handleSignButton}
            />
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getLoginModalStatus}
            onClose={onClose}
            children={
              <LoginModal
                onClose={onClose}
                navigate={props.navigation}
                handleSignButton={handleSignButton}
              />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}
      {props.getInviteSignUpModalStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getInviteSignUpModalStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
            }}
          >
            <View
              style={{
                height: Dimensions.get("window").height,
                paddingVertical: 10,
              }}
            >
              <SignupInvite
                onClose={onClose}
                navigate={props.navigation}
                handleInviteSignButton={handleInviteSignButton}
              />
            </View>
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getInviteSignUpModalStatus}
            onClose={onClose}
            children={
              <SignupInvite
                onClose={onClose}
                navigate={props.navigation}
                handleInviteSignButton={handleInviteSignButton}
              />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}

      {props.getEarningModalStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getEarningModalStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
            }}
          >
            <View
              style={{
                height: Dimensions.get("window").height,
                paddingVertical: 10,
              }}
            >
              <EarningModalMsg onClose={onClose} />
            </View>
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getEarningModalStatus}
            onClose={onClose}
            children={<EarningModalMsg onClose={onClose} />}
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}
      {props.getLeftPanelStatus ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getLeftPanelStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
            }}
          >
            <View
              style={{
                height: Dimensions.get("window").height,
              }}
            >
              <LeftPanel onClose={onClose} />
            </View>
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getLeftPanelStatus}
            onClose={onClose}
            children={<LeftPanel onClose={onClose} />}
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
              height: '80%'
            }}
          ></Overlay>
        )
      ) :
        null}

      {props.getPostShareModelStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getPostShareModelStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
              alignItems: Dimensions.get("window").width >= 750 && "center",
              justifyContent: "center",
              height: Dimensions.get('window').width >= 750 ? 480 : 500,

            }}

          >
            <PostShareModel
              onClose={onClose}
              navigate={props.navigation}
            />
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getPostShareModelStatus}
            onClose={onClose}
            children={
              <PostShareModel
                onClose={onClose}
                navigate={props.navigation}
              />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}


      {props.getSubscripionAlertStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getSubscripionAlertStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
              alignItems: Dimensions.get("window").width >= 750 && "center",
              justifyContent: "center",
              height: Dimensions.get('window').width >= 750 ? 300 : 500,

            }}

          >
            <SubscriptionAlert
              onClose={onClose}
              navigate={props.navigation}
            />
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getSubscripionAlertStatus}
            onClose={onClose}
            children={
              <SubscriptionAlert
                onClose={onClose}
                navigate={props.navigation}
              />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}



    </View>
  );
};

const mapStateToProps = (state) => ({

  getLoginModalStatus: state.LoginModalReducer.get("modalStatus"),
  getSignUpModalStatus: state.SignUpModalReducer.get("modalStatus"),
  getInviteSignUpModalStatus: state.InviteSignUpModalReducer.get("modalStatus"),
  getUsernameModalStatus: state.UsernameModalReducer.get("modalStatus"),
  getResetPasswordModalStatus: state.ResetPasswordModalReducer.get(
    "modalStatus"
  ),
  getVerifyEmailModalStatus: state.VerifyEmailModalReducer.get("modalStatus"),
  getSignupFollowModalStatus: state.VerifyEmailModalReducer.get(
    "signupfollowmodal"
  ),
  getSignupJoinModalStatus: state.VerifyEmailModalReducer.get(
    "signupjoinmodal"
  ),
  getShareLinkModalStatus: state.ShareLinkModalReducer.get("modalStatus"),
  getFeedReportModalStatus: state.FeedReportModalReducer.get("modalStatus"),
  getFeedShareModalStatus: state.FeedReportModalReducer.get("shareFeedStatus"),
  getMessageModalStatus: state.AdminReducer.get("messageModalStatus"),
  getEarningModalStatus: state.AdminReducer.get("earningModalStatus"),
  getLeftPanelStatus: state.AdminReducer.get("leftPanelOpenStatus"),
  getPostShareModelStatus: state.PostShareReducer.get("postShareStatus"),
  getSubscripionAlertStatus: state.SubscriptionAlertReducer.subsAlert

});

const mapDispatchToProps = (dispatch) => ({
  __setCURRENTDEVICEWIDTHACTION: (payload) =>
    dispatch(setCURRENTDEVICEWIDTHACTION(payload)),
  __resetHomefeedList: (payload) => dispatch(resetHomefeedList(payload)),
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  setSignUpModalStatus: (payload) => dispatch(setSIGNUPMODALACTION(payload)),
  setUsernameModalStatus: (payload) =>
    dispatch(setUSERNAMEMODALACTION(payload)),
  setInviteSignUpModalStatus: (payload) =>
    dispatch(setInviteSIGNUPMODALACTION(payload)),
  setResetPasswordModalStatus: (payload) =>
    dispatch(setRESETPASSWORDMODALACTION(payload)),
  setVerifyEmailModalStatus: (payload) =>
    dispatch(setVERIFYEMAILMODALACTION(payload)),
  setShareLinkModalStatus: (payload) =>
    dispatch(setSHARELINKMODALACTION(payload)),
  setFeedReportModalStatus: (payload) =>
    dispatch(setFEEDREPORTMODALACTION(payload)),
  changeLoginStatus: (payload) => dispatch(setLoginStatus(payload)),
  setFeedShareModalStatus: (payload) =>
    dispatch({ type: "SHAREFEEDSTATUS", payload }),
  setMessageModalStatus: (payload) =>
    dispatch({ type: "MESSAGEMODALSTATUS", payload }),
  setEarningModalStatus: (payload) =>
    dispatch({ type: "EARNINGMODALSTATUS", payload }),
  openCreateComment: (payload) =>
    dispatch({ type: "OPEN_CREATE_COMMENT", payload }),
  leftPanelModalFunc: (payload) =>
    dispatch({ type: "LEFT_PANEL_OPEN", payload }),
  setPostShareModel: (payload) =>
    dispatch({ type: "POSTSHARESTATUS", payload }),
  subscriptionAlertOpen: (payload) => dispatch(subscriptionAlertOpen(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout);
