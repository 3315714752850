import { fromJS } from 'immutable';
import { TOPICSFEED_SUCCESS, TOPICSFEED_FAILURE } from "../constants/Action";



const initialState = fromJS({
    TopicsFeedList: null,
    TopicsFeedListPagination: null,
});

export default function TopicsFeedReducer(state = initialState, action) {
    const { type, payload } = action;    
    switch (type) {
        case TOPICSFEED_SUCCESS:
            return state
                .set('TopicsFeedList', payload.data.node.posts.edges)
                .set('TopicsFeedListPagination', payload.data.node.posts.pageInfo);
        case TOPICSFEED_FAILURE:
            return initialState;
    }
    return state;
}