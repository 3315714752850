import React, { Component } from "react";
import { View, Dimensions, StyleSheet, Platform } from "react-native";
import IconMenu from "./IconMenu";
import NavigationService from "../library/NavigationService";
import RNPickerSelect from "react-native-picker-select";
import { connect } from "react-redux";
import ConstantFontFamily from "../constants/FontFamily";

const roleWiseViewOption = [
  {
    label: "Trending",
    value: "Trending",
  },
  {
    label: "New",
    value: "New",
  },
  {
    label: "Bookmarks",
    value: "Bookmarks",
  },
  {
    label: "Reported",
    value: "Reported",
  },
];

class BottomScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabType: "Trending",
    };
  }

  render() {
    return (
      <View
        style={{
          width: "100%",
          backgroundColor:
            Dimensions.get("window").width <= 750 ? "#fff" : "#000",
          height: 50,
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          borderTopWidth: 1,
          borderTopColor: "#c5c5c5",
        }}
      >
        {/* <IconMenu navigation={NavigationService} /> */}
        
            <View
              style={{
                borderRadius: 8,
                borderWidth: 0,
                width: 120,
                alignItems:'center',
                justifyContent:'center',
                marginVertical:2,
                height: 50
              }}
            >
              <RNPickerSelect
                placeholder={{}}
                items={                  
                  roleWiseViewOption
                }
                onValueChange={(itemValue, itemIndex) => {
                  this.setState({ tabType: itemValue }, () => {
                    if ( Platform.OS == 'web'){
                     this.props.setTabView(this.state.tabType);
                    }
                  });
                }}  
                onDonePress= {()=>  this.props.setTabView(this.state.tabType)}   
                           
                value={this.state.tabType}
                useNativeAndroidPickerStyle={Platform.OS != 'web' ? false : true}  
                style={{
                  ...styles,
                  iconContainer: {
                    top: 10,
                    right: 10
                  }
                }}               
              />               
            </View>
      </View>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setTabView: (payload) => dispatch({ type: "SET_TAB_VIEW", payload }),
});
export default connect(null, mapDispatchToProps)(BottomScreen);

const styles = StyleSheet.create({
  inputIOS: {
    paddingVertical: 2,
    paddingHorizontal: 10,
    borderRadius: 4,
    backgroundColor: "#fff",
    color: "#000",
    fontSize: 16,
    fontFamily: ConstantFontFamily.defaultFont,
    borderColor:'#c5c5c5',
    borderWidth: 1,
    textAlign: "center",
  },
  inputAndroid: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 8,
    backgroundColor: "#fff",
    color: "#000",
    fontSize: 16,
    fontFamily: ConstantFontFamily.defaultFont,
      borderColor:'#c5c5c5',
      borderWidth: 1,
      textAlign: "center",
  },
  TextStyle: {
    textAlign: "center"
  }
  
});

