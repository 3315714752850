import React, { Component } from "react";
import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import applloClient from "../client";
import "../components/Firebase";
import LoaderComponent from "../components/LoaderComponent";
import ConstantFontFamily from "../constants/FontFamily";
import {
  ChangeSubscriptionMutation
} from "../graphqlSchema/graphqlMutation/UserMutation";
import { UserLoginMutation,  } from "../graphqlSchema/graphqlMutation/UserMutation";


class CancelSubscriptionModal extends Component {
  state = {
    loading: false,
  };

  constructor(props) {
    super(props);
  }

  handleSubmit = async (evt) => {
    let __self = this;
    this.setState({
      loading: true,
    });
    applloClient
      .query({
        query: ChangeSubscriptionMutation,
        variables: {
          payment_id: null,
          subscription: "BASIC",
          pricing: null
        },
        fetchPolicy: "no-cache",
      })
      .then(async (r) => {

       let loginData= applloClient
          .query({
            query: UserLoginMutation,
            variables: {
              id: "Account:" + "CurrentUser"
            },
            fetchPolicy: "no-cache",
          }).then(res=>{
            return res.data.node
          })


        let userData = {
          data: loginData,
          externalfollow: this.props.getUserFollowFeedList,
          clikfollow: this.props.getUserFollowCliksList,
          topicfollow: this.props.getUserFollowTopicList,
          userfollow: this.props.getUserFollowUserList

        }
        await __self.props.saveLoginUser(userData);
        __self.props.onClose();
        __self.setState({
          loading: false,
        });
      })
      .catch((e) => {
        __self.props.onClose();
        __self.setState({
          loading: false,
        });
        console.log(e);
      });
  };

  render() {
    const { loading } = this.state;

    return (
      <View
        style={{
          width: "100%",
        }}
      >
        <Hoverable>
          {(isHovered) => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                flex: 1,
                position: "absolute",
                zIndex: 999999,
                left: 0,
                top: 0,
              }}
              onPress={this.props.onClose}
            >
              <Icon
                color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                iconStyle={{
                  color: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                reverse
                name="close"
                type="antdesign"
                size={16}
              />
            </TouchableOpacity>
          )}
        </Hoverable>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#000",
            alignItems: "center",
            height: 50,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
          }}
        >
          <Image
            source={
              Platform.OS == "web" &&
                this.props.getCurrentDeviceWidthAction > 750
                ? require("../assets/image/weclickd-logo.png")
                : Platform.OS == "web"
                  ? require("../assets/image/weclickd-logo.png")
                  : require("../assets/image/weclickd-logo-only-icon.png")
            }
            style={
              Platform.OS == "web" &&
                this.props.getCurrentDeviceWidthAction > 750
                ? {
                  height: 30,
                  width: Platform.OS == "web" ? 90 : 30,
                  padding: 0,
                  margin: 0,
                  marginVertical: 10,
                }
                : {
                  height: 30,
                  width: Platform.OS == "web" ? 90 : 30,
                  padding: 0,
                  margin: 0,
                  marginVertical: 10,
                }
            }
          />
        </View>

        <View
          style={{
            borderRadius: 6,
            width: "100%",
            backgroundColor: "#fff",
          }}
        >
          <View
            style={{
              flex: 1,
              backgroundColor: "#fff",
              padding: 20,
            }}
          >
            <View>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: "#000",
                    fontSize: 18,
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                >
                  Cancel Subscription
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  borderColor: "#000",
                  backgroundColor: "#000",
                  height: 2,
                  marginTop: 10,
                }}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                width: "100%",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont,
                }}
              >
                If you cancel your subcription,you will keep the benefits of the
                Gold membership until 3/31.
              </Text>
            </View>

            {loading == false ? (
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    width: "50%",
                  }}
                >
                  <TouchableOpacity
                    block
                    style={{
                      borderRadius: 5,
                      height: 40,
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 5,
                      borderWidth: 1,
                      borderColor: "#000",
                      marginTop: 10,
                      backgroundColor: "#000",
                    }}
                    onPress={this.props.onClose}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#fff",
                        fontFamily: ConstantFontFamily.defaultFont,
                        marginLeft: 5,
                      }}
                    >
                      {"Keep Subscription"}
                    </Text>
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  {loading == false ? (
                    <TouchableOpacity
                      block
                      style={{
                        borderRadius: 5,
                        height: 40,
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 5,
                        borderWidth: 1,
                        borderColor: "#000",
                        marginTop: 10,
                        backgroundColor: "#000",
                      }}
                      onPress={this.handleSubmit}
                    >
                      <Text
                        style={{
                          fontSize: 16,
                          color: "#fff",
                          fontFamily: ConstantFontFamily.defaultFont,
                          marginLeft: 5,
                        }}
                      >
                        {"Cancel Subscription"}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <View
                      style={{
                        borderRadius: 5,
                        height: 40,
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 5,
                        marginTop: 10,
                        backgroundColor: "#fff",
                      }}
                    >
                      <LoaderComponent />
                    </View>
                  )}
                </View>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <LoaderComponent />
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension"),
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList
    : [],
  getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
    ? state.LoginUserDetailsReducer.userFollowFeedList
    : [],
  getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
    ? state.LoginUserDetailsReducer.userFollowTopicsList
    : [],
  getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
    ? state.LoginUserDetailsReducer.userFollowUserList
    : [],
});

const mapDispatchToProps = (dispatch) => ({
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CancelSubscriptionModal
);
