import { takeEvery, call, put } from 'redux-saga/effects';
import { CLIKSFEED, CLIKSFEED_SUCCESS, CLIKSFEED_FAILURE } from "../constants/Action";
import applloClient from '../client';
import { ClikFeed } from '../graphqlSchema/graphqlMutation/TrendingMutation';


const getResponse = (req) => {
    return applloClient.query({
        query: ClikFeed,
        variables: {
            id: "Clik:"+req.id,
            currentPage: req.currentPage,
        },
        fetchPolicy: 'cache-first'
    }).then(res => res);
};

export function* CliksFeedMiddleware({ payload }) {
    try {
       const response = yield call(getResponse, payload);
        yield put({
            type: CLIKSFEED_SUCCESS,
            payload: response
        });
    } catch (err) {
        yield put({
            type: CLIKSFEED_FAILURE,
            payload: err.message
        });
        console.log(err);
    }
}

export default function* CliksFeedMiddlewareWatcher() {
    yield takeEvery(CLIKSFEED, CliksFeedMiddleware);

}