import { fromJS } from "immutable";
import { CLIKMEMBERS_SUCCESS, CLIKMEMBERS_FAILURE } from "../constants/Action";

const initialState = fromJS({
  clikMemberList: []
});

export default function ClikMembersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLIKMEMBERS_SUCCESS:
      return state.set("clikMemberList", payload.data.node.members.edges);
    case CLIKMEMBERS_FAILURE:
      return initialState;
  }
  return state;
}
