import { fromJS } from "immutable";
import {
  POSTCOMMENTDETAILS_SUCCESS,
  POSTCOMMENTDETAILS_FAILURE
} from "../constants/Action";

const initialState = fromJS({
  PostCommentDetails: [],
  selectComment: {
    currentId: null,
    prevId: null
  },
  PostId: "Post:0",
  Title: "",
  Loading: true
});

export default function PostCommentDetailsReducer(
  state = initialState,
  action
) {
  const { type, payload, postId, title, loading } = action;
  switch (type) {
    case POSTCOMMENTDETAILS_SUCCESS:
        return state.set("PostId", postId)
        .set("PostCommentDetails", payload)
        .set("Title", title)
        .set("Loading", loading);
    case POSTCOMMENTDETAILS_FAILURE:
      return initialState;
    case "SET_ACTIVE_ID": {
      return state.set("selectComment", {
        prevId: state.get("selectComment").currentId,
        currentId: payload
      });
    }
    case "POSTCOMMENTDETAILS_RESET":
      return state
        .set("PostCommentDetails", payload)
        .set("PostId", postId)
        .set("Title", title)
        .set("Loading", loading);
  }
  return state;
}
