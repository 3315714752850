import React, { Component } from "react";
import {
  View,
  ScrollView,
  Text,
  Image,
  TouchableHighlight,
  TouchableOpacity,
  Dimensions
} from "react-native";
import { Button } from "react-native-elements";
import ConstantFontFamily from "./constants/FontFamily";
//import ButtonStyle from "../constants/ButtonStyle";
import ButtonStyle from "./constants/ButtonStyle";
import NavigationService from "./library/NavigationService";
import { Platform } from "react-native";
import Colors from "./constants/Colors";

class CrashlyticsErrorBoundary extends Component {
  componentDidMount = async () => {
    window.onerror = function (message, source, lineno, colno, error) {
      if (error || error.name === "ChunkLoadError") {
        window.location.reload(true);
      }
    };
  };
  render() {
    return (
      <ScrollView
        style={{
          flex: 1,
          backgroundColor: Colors.greyBackground
        }}
      >
        <View
          style={
            [
              // ButtonStyle.normalFeedStyleForDashboard,
               {
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            // marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
            // marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
            // marginLeft: 15,
            minHeight: Dimensions.get("window").height,
            backgroundColor: '#fff'
          }]}
        >
          {/* <Text
            style={{
              marginVertical: 10
            }}
          >
            Something went wrong!
          </Text>
          <Text
            style={{
              marginVertical: 10
            }}
          >
            {this.props.error && this.props.error.toString()}
          </Text>
          <Button onPress={this.props.resetError} title={"Try again"} /> */}
          <View style={{ width: "60%" }}>
            <Text
              style={{
                fontFamily: ConstantFontFamily.defaultFont,
                fontWeight: "bold",
                fontSize: 27,
                textAlign: "center"
              }}
            >
              This application may have updated or crashed.
            </Text>
            <Text
              style={{
                fontFamily: ConstantFontFamily.defaultFont,
                fontWeight: "bold",
                fontSize: 27,
                textAlign: "center"
              }}
            >
              Please refresh your browser to
            </Text>
            <Text
              style={{
                fontFamily: ConstantFontFamily.defaultFont,
                fontWeight: "bold",
                fontSize: 27,
                textAlign: "center"
              }}
            >
              see the latest content
            </Text>
          </View>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: 20
            }}
          >
            <Button
              onPress={() => {
                NavigationService.navigate("home")
                // if (Platform.OS == 'web') {
                  window.location.reload();
                // }
              }}
              color="#000"
              title="Refresh"
              titleStyle={ButtonStyle.titleStyle}
              buttonStyle={ButtonStyle.backgroundStyle}
              containerStyle={ButtonStyle.containerStyle}
            />
          </TouchableOpacity>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20
            }}
          >
            {/* <Image
              source={require("./assets/image/Weclikd-white.png")}
              style={{
                height: 38,
                width: 115,
                justifyContent: "flex-start"
              }}
              resizeMode={"contain"}
            /> */}
            {/* <Text
              style={{
                fontFamily: ConstantFontFamily.defaultFont,
                fontWeight: "bold",
                fontSize: 22,
                textAlign: "center",
                paddingLeft:2
              }}
            >
              weclikd
            </Text> */}
          </View>
        </View>
      </ScrollView>
    );
  }
}

export default CrashlyticsErrorBoundary;