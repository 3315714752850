import "@expo/browser-polyfill";
import { takeEvery, call, put, select } from "redux-saga/effects";
import {
  POSTDETAILS,
  POSTDETAILS_SUCCESS,
  POSTDETAILS_FAILURE,
} from "../constants/Action";
import applloClient from "../client";
import {
  PostQuery,
  AdminPostQuery,
} from "../graphqlSchema/graphqlMutation/PostMutation";
import { PostQueryVariables } from "../graphqlSchema/graphqlVariables/PostVariables";
import NavigationService from "../library/NavigationService";
import { Platform, Dimensions } from "react-native";

const isWeb = Platform.OS === "web";

const getResponse = (req, isAdmin) => {
  PostQueryVariables.variables.id = req.id;
  return applloClient
    .query({
      query: PostQuery,
      variables: {
        id: req.id,
        cliks_first: 20,
        topics_first: 20,
        users_first: 20,
        feeds_first: 20,
      },
      fetchPolicy: "no-cache",
    })

    .then((res) => {
      return res;
    });
};

export function* PostDetailsMiddleware({ payload }) {
  const getIsAdmin = (state) => state.AdminReducer.get("isAdmin");
  const isAdmin = yield select(getIsAdmin);
  try {
    if (payload.id != "Post:") {
      const response = yield call(getResponse, payload, isAdmin);
      yield put({
        type: POSTDETAILS_SUCCESS,
        payload: response,
      });
      // if (Dimensions.get("window").width <= 1000) {
      if (payload.navigate == true) {
        if (payload.title) {
          NavigationService.navigate("info", {
            id: payload.id.replace("Post:", ""),
          });
        }
      }
    }

    // }
  } catch (err) {
    yield put({
      type: POSTDETAILS_FAILURE,
      payload: err.message,
      comment: false,
    });
    console.log(err);
  }
}

export default function* PostDetailsMiddlewareWatcher() {
  yield takeEvery(POSTDETAILS, PostDetailsMiddleware);
}
