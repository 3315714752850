import { takeEvery, put } from 'redux-saga/effects';
import { HASSCROLLEDACTION, HASSCROLLEDACTION_SUCCESS, HASSCROLLEDACTION_FAILURE } from "../constants/Action";

export function* HasScrolledMiddleware({ payload }) {
    if (payload != null) {
        yield put({
            type: HASSCROLLEDACTION_SUCCESS,
            payload: payload
        });
    } else {
        yield put({
            type: HASSCROLLEDACTION_FAILURE,
            payload: payload
        });
    }
}

export default function* HasScrolledMiddlewareWatcher() {
    yield takeEvery(HASSCROLLEDACTION, HasScrolledMiddleware);
}