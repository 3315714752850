import { fromJS } from "immutable";
import {
  TRENDINGTOPICS_SUCCESS,
  TRENDINGTOPICS_FAILURE
} from "../constants/Action";

const initialState = fromJS({
  Trending_Topics_List: null
});

export default function TrendingTopicsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TRENDINGTOPICS_SUCCESS:
      return state.set("Trending_Topics_List", payload);
    case TRENDINGTOPICS_FAILURE:
      return initialState;
  }
  return state;
}
