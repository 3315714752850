import { takeEvery, put } from 'redux-saga/effects';
import { CURRENTDEVICEWIDTHACTION, CURRENTDEVICEWIDTHACTION_SUCCESS, CURRENTDEVICEWIDTHACTION_FAILURE } from "../constants/Action";

export function* CurrentDeviceWidthMiddleware({ payload }) {
    if (payload != null) {
        yield put({
            type: CURRENTDEVICEWIDTHACTION_SUCCESS,
            payload: payload
        });
    } else {
        yield put({
            type: CURRENTDEVICEWIDTHACTION_FAILURE,
            payload: payload
        });
    }
}

export default function* CurrentDeviceWidthMiddlewareWatcher() {
    yield takeEvery(CURRENTDEVICEWIDTHACTION, CurrentDeviceWidthMiddleware);
}