import { fromJS } from "immutable";
import { HOMEFEED_SUCCESS, HOMEFEED_FAILURE } from "../constants/Action";

const initialState = fromJS({
  HomefeedList: [],
  HomefeedListPagination: null,
  DiscussionHomeFeedList: [],
  TrendingHomeFeedList: [],
  NewHomeFeedList: [],
  TrendingTopicHomeFeedList: [],
  TrendingClikHomeFeedList: [],
  TrendingUserHomeFeedList: [],
  TrendingExternalFeedHomeFeedList: [],
});

export default function HomeFeedReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HOMEFEED_SUCCESS:
      return state
        .set("HomefeedList", payload.data.node_list.edges)
        .set("HomefeedListPagination", payload.data.node_list.pageInfo);
    case HOMEFEED_FAILURE:
      return initialState;
    case "SET_DISCUSSION_HOME_FEED": {
      return state.set("DiscussionHomeFeedList", payload);
    }
    case "SET_TRENDING_HOME_FEED": {
      return state.set("TrendingHomeFeedList", payload);
    }
    case "SET_NEW_HOME_FEED": {
      return state.set("NewHomeFeedList", payload);
    }
    case "SET_TRENDING_TOPIC_HOME_FEED": {
      return state.set("TrendingTopicHomeFeedList", payload);
    }
    case "SET_TRENDING_CLIK_HOME_FEED": {
      return state.set("TrendingClikHomeFeedList", payload);
    }
    case "SET_TRENDING_USER_HOME_FEED": {
      return state.set("TrendingUserHomeFeedList", payload);
    }
    case "SET_TRENDING_EXTERNAL_HOME_FEED": {
      return state.set("TrendingExternalFeedHomeFeedList", payload);
    }
  }
  return state;
}
