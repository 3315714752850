import { fromJS } from "immutable";
import {
  TOPICS_PROFILE_DETAILS_FAILURE,
  TOPICS_PROFILE_DETAILS_SUCCESS
} from "../constants/Action";

const initialState = fromJS({
  getTrendingTopicsProfileDetails: {}
});

export default function TrendingTopicsProfileReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case TOPICS_PROFILE_DETAILS_SUCCESS:
      return state.set("getTrendingTopicsProfileDetails", fromJS(payload));
    case TOPICS_PROFILE_DETAILS_FAILURE:
      return initialState;
  }
  return state;
}
