import { CLIK_HOVER } from '../constants/Action';


const initialState = {
    isClik: false,
    clikId:''

}

const clikHoverReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIK_HOVER: {
            return {
                isClik: action.payload.isClik,
                clikId:action.payload.clikId
            };
        }
        default:
            return state;
    }
};

export default clikHoverReducer;