import { fromJS } from "immutable";
import {
  INVITESIGNUPMODALACTION_SUCCESS,
  INVITESIGNUPMODALACTION_FAILURE,
} from "../constants/Action";

const initialState = fromJS({
  modalStatus: false,
});

export default function SignUpModalReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case INVITESIGNUPMODALACTION_SUCCESS:
      return state.set("modalStatus", fromJS(payload));
    case INVITESIGNUPMODALACTION_FAILURE:
      return initialState;
  }
  return state;
}
