import { call, put, takeEvery } from "redux-saga/effects";
import applloClient from "../client";
import {
  TRENDINGTOPICS,
  TRENDINGTOPICS_FAILURE,
  TRENDINGTOPICS_SUCCESS
} from "../constants/Action";
import { TRENDING_TOPICS } from "../graphqlSchema/graphqlMutation/TrendingMutation";

const getResponse = req => {
  return applloClient
    .query({
      query: TRENDING_TOPICS,
      variables: {
        first: req.currentPage,
        after: null,
        sort_type: "TRENDING",
        type: "Topic"

      },
      fetchPolicy: "no-cache",
    })
    .then(res => res);
};

export function* TrendingTopicsMiddleware({ payload }) {
  try {
    const response = yield call(getResponse, payload);
    yield put({
      type: TRENDINGTOPICS_SUCCESS,
      // payload: response.data.trending_topics.edges
      payload: response.data.node_list.edges
    });
  } catch (err) {
    yield put({
      type: TRENDINGTOPICS_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* TrendingTopicsMiddlewareWatcher() {
  yield takeEvery(TRENDINGTOPICS, TrendingTopicsMiddleware);
}
