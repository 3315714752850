import { List } from "immutable";
import React, { lazy, Component, Suspense } from "react";

import {
  Animated,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Dimensions
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { getTrendingTopicsProfileDetails } from "../actionCreator/TrendingTopicsProfileAction";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import applloClient from "../client";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import {
  FollowMutation
} from "../graphqlSchema/graphqlMutation/FollowandUnFollowMutation";
import {
  GetChildTopicsMutation,
  TopicQuery
} from "../graphqlSchema/graphqlMutation/TrendingMutation";
import { TopicFollowingMutation } from "../graphqlSchema/graphqlMutation/UserMutation";
import {
  FollowVariables
} from "../graphqlSchema/graphqlVariables/FollowandUnfollowVariables";
import NavigationService from "../library/NavigationService";
import ButtonStyle from "../constants/ButtonStyle";
import { subscriptionAlertOpen } from '../reducers/action/Completed';


const TopicStructure = lazy(() => import("../components/TopicStructure"))


class TopicHierarchyScreen extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.Pagescrollview = null;
    this.state = {
      changeBackPicEnable: null,
      setBackPic: null,
      topicName: "",
      rtopicName: "",
      TopicList: [],
      TopicListHierarchy: [],
      showDeleteIcon: false,
      rtopicParents: "#000",
      parentsOfSelectedTopic: [],
      childrenByTopics: {},
      openTopics: []
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
  };

  componentDidUpdate() {
    if (this.props.getHasScrollTop == true && this.Pagescrollview) {
      this.Pagescrollview.scrollTo({ x: 0, y: 0, animated: true });
      this.props.setHASSCROLLEDACTION(false);
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getTopicProfileDetails = value => {
    applloClient
      .query({
        query: TopicQuery,
        variables: {
          id: "Topic:" + value
        },
        fetchPolicy: "no-cache"
      })
      .then(res => {
        this.setState({
          parentsOfSelectedTopic: res.data.topic.parents
            ? [...res.data.topic.parents, value]
            : [value]
        });
      });
  };
  getChildStructure(arr, selectTopic, childrenOfSelectTopic) {
    let output = [];
    let i = 0;
    if (arr.length > 0)
      output[i] = {
        name: arr[i],
        children:
          selectTopic === arr[i]
            ? [...childrenOfSelectTopic]
            : this.getChildStructure(
              arr.slice(i + 1),
              selectTopic,
              childrenOfSelectTopic
            )
      };

    return output;
  }
  renderTopicListHierarchy = (value, topicId) => {
    this.setState({
      TopicListHierarchy: []
    });
    if (!this.state.childrenByTopics[topicId]) {
      applloClient
        .query({
          query: GetChildTopicsMutation,
          variables: {
            id: "Topic:" + value
          },
          fetchPolicy: "no-cache"
        })
        .then(res => {
          let { children } = res.data.topic;
          this.state.TopicListHierarchy;
          this.setState(({ childrenByTopics, openTopics }) => ({
            childrenByTopics: { ...childrenByTopics, [topicId]: children },
            openTopics: this.handleToggleArr(openTopics, topicId)
          }));

          this.setState({
            TopicListHierarchy: this.getChildStructure(
              this.state.parentsOfSelectedTopic,
              value,
              [{ name: "dynamicText" }, ...children]
            )
          });
        });
    } else {
      this.setState(({ openTopics }) => ({
        openTopics: this.handleToggleArr(openTopics, topicId)
      }));
    }
  };
  handleToggleArr = (inputArr, value) => {
    let arr = [...inputArr];
    arr.indexOf(value) === -1
      ? arr.push(value)
      : arr.splice(arr.indexOf(value), 1);
    return arr;
  };
  handleTopicSelectInput = (topic, parents, item) => {
    this.setState(
      {
        rtopicName: topic,
        TopicList: [],
        TopicListHierarchy: []
      },
      () => {
        if (parents) {
          this.colorCondition(parents);
        } else {
          this.colorCondition("");
        }
        if (topic != "") {
          if (item.banner_pic) {
            let v = item.banner_pic.split("/");
            let k = v[v.length - 1].substring(0, v[v.length - 1].indexOf("."));
            this.setState({
              setBackPic: item.banner_pic,
              showDeleteIcon: true,
              changeBackPicEnable: k
            });
          }

          this.getTopicProfileDetails(topic);
          this.renderTopicListHierarchy(topic, item.id);
        }
        if (this.state.rtopicName == "") {
          this.setState({
            rtopicParents: "",
            TopicList: []
          });
        }
      }
    );
  };

  colorCondition = parent => {
    if (parent.length > 0) {
      this.setState({
        rtopicParents: "#FEC236"
      });
    } else if (parent == "") {
      this.setState({
        rtopicParents: "#009B1A"
      });
    } else if (parent == null) {
      this.setState({
        rtopicParents: "#009B1A"
      });
    }
  };

  goToProfile = async id => {
    this.props.topicId({
      id: id,
      type: "feed"
    });
  };

  getTopicStar = TopicName => {
    let index = 0;
    index = this.props.getUserFollowTopicList.findIndex(
      i =>
        i.node.topic.name == TopicName.toLowerCase().replace("topic:", "")

    );
    if (index == -1) {
      return "TRENDING";
    } else {
      return this.props.getUserFollowTopicList[index].follow_type
    }
  };

  followTopics = topicId => {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    FollowVariables.variables.id = topicId;
    FollowVariables.variables.follow_type = "FOLLOW";
    applloClient
      .query({
        query: FollowMutation,
        ...FollowVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        let topicfollow = await applloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.topics_following.edges
          })
        let userData = {
          data: this.props.profileData,
          externalfollow: this.props.getUserFollowFeedList,
          clikfollow: this.props.getUserFollowCliksList,
          topicfollow: topicfollow,
          userfollow: this.props.getUserFollowUserList

        }
        await this.props.saveLoginUser(userData);
      });
  };

  unfollowTopics = async topicId => {
    FollowVariables.variables.id = topicId;
    FollowVariables.variables.follow_type = "FOLLOW";
    applloClient
      .query({
        query: FollowMutation,
        ...FollowVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        let topicfollow = await applloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.topics_following.edges
          })
        let userData = {
          data: this.props.profileData,
          externalfollow: this.props.getUserFollowFeedList,
          clikfollow: this.props.getUserFollowCliksList,
          topicfollow: topicfollow,
          userfollow: this.props.getUserFollowUserList

        }
        await this.props.saveLoginUser(userData);
      });
  };

  favroiteTopics = async topicId => {
    FollowVariables.variables.id = topicId;
    FollowVariables.variables.follow_type = "FOLLOW";
    applloClient
      .query({
        query: FollowMutation,
        ...FollowVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {

        let topicfollow = await applloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.topics_following.edges
          })
        let userData = {
          data: this.props.profileData,
          externalfollow: this.props.getUserFollowFeedList,
          clikfollow: this.props.getUserFollowCliksList,
          topicfollow: topicfollow,
          userfollow: this.props.getUserFollowUserList

        }
        await this.props.saveLoginUser(userData);
      });
  };

  render() {
    const { openTopics, childrenByTopics, topicName } = this.state;
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: ConstantColors.customeBackgroundColor,
          borderLeftWidth:1,
          borderColor:'#d3d3d3'
           
        }}
      >
        <View>
          {Platform.OS !== "web" ? (
            <Animated.View
              style={{
                position: Platform.OS == "web" ? "sticky" : null,
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                overflow: "hidden",
                borderRadius: 6
              }}
            >
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    backgroundColor: "#000",
                    borderRadius: 6,
                    height: 42
                  }}
                >
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignSelf: "center",
                      paddingLeft: 10
                    }}
                    onPress={() => {
                      this.props.searchOpenBarStatus(false)
                      let nav = this.props.navigation.dangerouslyGetParent()
                        .state;
                      if (nav.routes.length > 1) {
                        this.props.navigation.goBack();
                        return;
                      } else {
                        this.props.navigation.navigate("home");
                      }
                    }}
                  >
                    <Icon
                      color={"#fff"}
                      name="angle-left"
                      type="font-awesome"
                      size={40}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      width: "100%",
                      flex: 1,
                      backgroundColor: "#000",
                      justifyContent: "center",
                      borderRadius: 6
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: 18,
                        fontFamily: ConstantFontFamily.defaultFont
                      }}
                    >
                      Topic Hierarchy
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Animated.View>
          ) : null
          }
        </View>

        <View                    
          style={{
            height:
              Platform.OS !== "web"
                ? null
                : Dimensions.get("window").height - 80
          }}
        >
          <ScrollView 
          style={[Dimensions.get("window").width >= 1200 ? ButtonStyle.normalFeedStyleForDashboard : null,
          {
            marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
            paddingTop: 10,
            marginLeft: 0,
            borderBottomWidth:0
          }]}>
          {this.props.getTrending_Topics_List.map((item, i) => {
            if (!item.node.parents) {
              return (
                <Suspense fallback={null}>
                  <TopicStructure
                    isRoot={true}
                    item={item.node}
                    childrenByTopics={childrenByTopics}
                    openTopics={openTopics}
                    topicName={topicName}
                    handleTopicSelectInput={this.handleTopicSelectInput}
                    getTopicStar={this.getTopicStar}
                    goToProfile={this.goToProfile}
                    followTopics={this.followTopics}
                    unfollowTopics={this.unfollowTopics}
                    favroiteTopics={this.favroiteTopics}
                  />
                </Suspense>
              );
            }
          })}
        </ScrollView>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignSelf: "center",
            alignItems: "center"
          }}
        >
          <Button
            onPress={() => {
              if (this.props.profileData.subscription == "GOLD") {
              return NavigationService.navigate("createtopic");
              } else{
                this.props.subscriptionAlert(true)
              }
            }}
            title="Propose Topic"
            titleStyle={ButtonStyle.titleStyle}
            buttonStyle={ButtonStyle.backgroundStyle}
            containerStyle={ButtonStyle.containerStyle}
          />

          <Button
            onPress={() => {
              return NavigationService.navigate("faq");
            }}
            title="Topic FAQ"
            titleStyle={ButtonStyle.titleStyle}
            buttonStyle={ButtonStyle.backgroundStyle}
            containerStyle={ButtonStyle.containerStyle}
          />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  listTrending_cliks: !state.TrendingCliksReducer.getIn(["Trending_cliks_List"])
    ? List()
    : state.TrendingCliksReducer.getIn(["Trending_cliks_List"]),
  loginStatus: state.UserReducer.get("loginStatus"),
  getTrending_Topics_List: state.TrendingTopicsReducer.get(
    "Trending_Topics_List"
  )
    ? state.TrendingTopicsReducer.get("Trending_Topics_List")
    : List(),
  getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList
    : [],
  getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
    ? state.LoginUserDetailsReducer.userFollowFeedList
    : [],
  getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
    ? state.LoginUserDetailsReducer.userFollowTopicsList
    : [],
  getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
    ? state.LoginUserDetailsReducer.userFollowUserList
    : [],
});

const mapDispatchToProps = dispatch => ({
  setHASSCROLLEDACTION: payload => dispatch(setHASSCROLLEDACTION(payload)),
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  topicId: payload => dispatch(getTrendingTopicsProfileDetails(payload)),
  setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
  subscriptionAlert: (payload) => dispatch(subscriptionAlertOpen(payload)),

});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TopicHierarchyScreen
);