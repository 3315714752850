import { launchImageLibraryAsync } from "expo-image-picker";
import { askAsync, CAMERA_ROLL } from "expo-permissions";
import { List } from "immutable";
import React, { lazy, Component, Suspense } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  Animated,
  // AsyncStorage,
  Dimensions,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Button, Icon, Tooltip } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import applloClient from "../client";
import AppHelper from "../constants/AppHelper";
import ButtonStyle from "../constants/ButtonStyle";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import ConstantTooltip from "../constants/Tooltip";
import { EditClikMutation } from "../graphqlSchema/graphqlMutation/TrendingMutation";
import {
  ClikFollowingMutation
} from "../graphqlSchema/graphqlMutation/UserMutation";
import { EditClikVariables } from "../graphqlSchema/graphqlVariables/PostVariables";
import {
  uploadProfileImageAsync,
} from "../services/UserService";
import { getTrendingCliksProfileDetails } from "../actionCreator/TrendingCliksProfileAction";
import NavigationService from "../library/NavigationService";

const BottomScreen = lazy(() => import("../components/BottomScreen"))

class CreateClikScreen extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.Pagescrollview = null;
    this.state = {
      uploading: false,
      profilePic: "",
      title: "",
      text: "",
      items: [],
      changeBackPicEnable: this.props.clikdetails.get("changeBackPicEnable"),
      setBackPic: this.props.clikdetails.get("banner_pic"),
      setIcon: this.props.clikdetails.get("profile_pic"),
      changeIconPicEnable: this.props.clikdetails.get("changeIconPicEnable"),
      showsVerticalScrollIndicatorView: false,
      currentScreentWidth: 0,
      summary: "",
      focusSummary: false,
      focusClikname: false,
      focusDesc: false,
      focusWeburl: false,
      focusQualification: false,
      SelectRoleItems: "Member",
      clikName: this.props.clikdetails.get("cliksName").toString(),
      description: this.props.clikdetails.get("description").toString(),
      MutipleUserList: [],
      showError: false,
      showcliktooltip: false,
      showaddmembertooltip: false,
      MutipleQualification: this.props.clikdetails.get("qualifications"),
      clikHover: false,
      descriptionHover: false,
      joinclikHover: false,
      website: this.props.clikdetails.get("website"),
      qualification: "",
      UserList: [],
      clikId: this.props.clikdetails.get("clikId"),
      switchValue: this.props.clikdetails.get("invite_only"),
      testArray: [],
      customQualificationArray: [{ text: "" }],
    };
    this.baseState = this.state;
  }

  componentDidMount = async () => {
    let web = this.state.website.toJS();
    this.setState({ website: web[0] })
    this.props.searchOpenBarStatus(false);
    let testArray = [];
    let qualificationArray = [];
    qualificationArray =
      this.props.clikdetails.get("qualifications") &&
      this.props.clikdetails.get("qualifications").toArray();
    if (qualificationArray) {
      for (let i = 0; i < qualificationArray.length; i++) {
        testArray.push({ text: qualificationArray[i] });
      }
    }
    this.setState({ customQualificationArray: testArray });
    this._isMounted = true;
    const profileData = this.props.profileData;
    let userProfilePic = profileData.user.profile_pic
    this.setState({
      profilePic: {
        uri: userProfilePic,
      },
    });
    let joined = [];
    this.props.listTrending_cliks.map(async (value, index) => {
      await joined.push({
        id: value.node.id,
        name: value.node.name,
      });
    });
    this.setState({ items: joined });
  };

  componentDidUpdate() {
    if (this.props.getHasScrollTop == true && this.Pagescrollview) {
      this.Pagescrollview.scrollTo({ x: 0, y: 0, animated: true });
      this.props.setHASSCROLLEDACTION(false);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _askPermission = async (type, failureMessage) => {
    const { status, permissions } = await askAsync(type);
    if (status === "denied") {
      alert(failureMessage);
    }
  };



  pickIcon = async () => {
    await this._askPermission(
      CAMERA_ROLL,
      "We need the camera-roll permission to read pictures from your phone..."
    );
    let pickerResult = await launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [4, 3],
      base64: true,
    });
    let size = pickerResult.uri.length * (3 / 4) - 2;

    this._handleIconImagePicked(pickerResult);

  };

  _handleIconImagePicked = async (pickerResult) => {
    let uploadResponse, uploadResult;
    try {
      this.setState({ uploading: true });
      if (!pickerResult.cancelled) {
        uploadResponse = await uploadProfileImageAsync(pickerResult.uri, true);
        uploadResult = await uploadResponse.json();

        this.setState({
          setIcon: pickerResult.uri,
        });
        this.setState({ changeIconPicEnable: uploadResult.id });
      }
    } catch (e) {
      alert("Upload failed, sorry :(" + e + ")");
    } finally {
      this.setState({ uploading: false });
    }
  };



  editCliks = async () => {
    let __self = this;

    let websites1 = []

    if (this.state.website) {
      websites1.push(this.state.website)
    }
    EditClikVariables.variables.websites = websites1.length > 0 ? websites1[0] : null;

    EditClikVariables.variables.clik_id = this.state.clikId;
    EditClikVariables.variables.description = this.state.description;
    if (this.state.changeIconPicEnable != null) {
      EditClikVariables.variables.profile_pic = this.state.changeIconPicEnable.toString();
    } else {
      EditClikVariables.variables.profile_pic = null
    }

    EditClikVariables.variables.name = this.state.clikName;



    EditClikVariables.variables.qualifications = this.props.clikdetails.get("qualifications")
    this.state.customQualificationArray && this.state.customQualificationArray[0]?.text != ""
      ? this.state.customQualificationArray.map(value => value.text)
      : null;
    EditClikVariables.variables.invite_only = this.state.switchValue;
    EditClikVariables.variables.max_members = 100;
    console.log("_h6in");

    try {
      let id = await AsyncStorage.getItem("UserId");
      await applloClient
        .query({
          query: EditClikMutation,
          ...EditClikVariables,
          fetchPolicy: "no-cache",
        })
        .then(async (res) => {
          if (res.data.clik_update.status.success == true) {
            await this.goToProfile(
              this.state.clikName,
              this.props.clikdetails.get("cliksName")
            );
            await this.setState(this.baseState);
            await applloClient
              .query({
                query: ClikFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 50
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                let userData = {
                  data: this.props.profileData,
                  externalfollow: this.props.getUserFollowFeedList,
                  clikfollow: e.data.node.cliks_following.edges,
                  topicfollow: this.props.getUserFollowTopicList,
                  userfollow: this.props.getUserFollowUserList

                }
                this.props.saveLoginUser(userData);
              })
            if (this.props.profileData) {
               __self.props.getTrendingClicks({
                currentPage: AppHelper.PAGE_LIMIT,
              });
              await this.props.clikIconUpload(true);
            }
          }

        });
    } catch (e) {
      console.log(e);
    }
  };

  goToProfile = (id, name) => {
    this.props.ClikId({
      id: id,
      type: "feed",
      name: name,
    });
  };

  tooglecliktooltip = () => {
    if (this.state.showcliktooltip == false) {
      this.setState({ showcliktooltip: true });
    } else {
      this.setState({ showcliktooltip: false });
    }
  };




  checkClikname = async (name) => {
    var letters = /^[0-9a-zA-Z-]+$/;
    if (name.match(letters) || name == "") {
      if (name[0] == "-") {
        alert("- not allowed at initial of clik name");
        return false;
      }
      this.setState({ clikName: name });
      return true;
    } else {
      alert("Please input alphanumeric characters only");
      return false;
    }
  };



  addcustomQualificationArray = (e) => {
    this.setState({
      customQualificationArray: [
        ...this.state.customQualificationArray,
        { text: "" },
      ],
    });
  };

  handleCustomQualification = (value, index) => {
    let inputArray = [...this.state.customQualificationArray];
    inputArray[index].text = value;
    this.setState({ customQualificationArray: inputArray }, () => { });
  };

  removecustomQualificationArray = (index, e) => {
    this.state.customQualificationArray.splice(index, 1);
    this.setState({
      customQualificationArray: this.state.customQualificationArray,
    });
  };

  render() {

    const { setIcon } = this.state;
    const textStyle = styles.usertext;
    return (
      <View
        style={[
          {
            flex: 1,
            backgroundColor: ConstantColors.customeBackgroundColor,
            width: "100%",
          },
        ]}
      >
        <ScrollView
          ref={(scrollview) => {
            this.Pagescrollview = scrollview;
          }}
          showsVerticalScrollIndicator={false}

          onLayout={(event) => {
            let { x, y, width, height } = event.nativeEvent.layout;
            if (width < 1024) {
              this.setState({
                showsVerticalScrollIndicatorView: true,
                currentScreentWidth: width,
              });
            } else {
              this.setState({
                showsVerticalScrollIndicatorView: false,
                currentScreentWidth: width,
              });
            }
          }}
          style={{
            height:
              Platform.OS !== "web"
                ? null
                : Dimensions.get("window").height - 40,
          }}
        >
          <View>
            {Dimensions.get("window").width <= 750 && (
              <Animated.View
                style={{
                  position: Platform.OS == "web" ? "sticky" : null,
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  overflow: "hidden",
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      backgroundColor: "#000",
                      height: 50,
                    }}
                  >
                    <TouchableOpacity
                      style={ButtonStyle.headerBackStyle}
                      onPress={() => {
                        this.props.searchOpenBarStatus(false);
                        let nav =
                          this.props.navigation.dangerouslyGetParent().state;
                        if (nav.routes.length > 1) {
                          this.props.navigation.goBack();
                          return;
                        } else {
                          this.props.navigation.navigate("home");
                        }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        ButtonStyle.headerTitleStyle,
                        { backgroundColor: "#000" },
                      ]}
                    >
                      <Text
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: 18,
                          fontFamily: ConstantFontFamily.defaultFont,
                        }}
                      >
                        Edit Clik
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Animated.View>
            )}
            <View
              style={[
                Dimensions.get("window").width >= 1200
                  ? ButtonStyle.normalFeedStyleForDashboard
                  : null,
                {
                  marginTop: 30,
                  paddingHorizontal:
                    Dimensions.get("window").width <= 1200 ? 5 : 10,
                  marginHorizontal:
                    Dimensions.get("window").width <= 1200 ? 0 : 10,
                  alignItems: "center",
                  backgroundColor: "#fff",
                  paddingTop: Dimensions.get("window").width <= 750 ? 0 : 10,
                },
              ]}
            >
              <View
                style={[
                  ButtonStyle.borderStyle,
                  ButtonStyle.textAreaShadowStyle,
                  {
                    width: "100%",
                    backgroundColor: "#fff",
                  },
                ]}
              >
                <Text
                  style={{
                    marginTop: 10,
                    fontWeight: "bold",
                    fontSize: 16,
                    textAlign: "center",
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                >
                  A clik is a discussion group. You can share posts to each
                  other and also start a discussion with only members of your
                  clik .
                </Text>
              </View>

              <View
                style={[
                  {
                    backgroundColor: "#fff",
                    marginVertical: 10,
                    width: "100%",
                  },
                ]}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    marginTop: 15,
                    marginBottom: 5,
                    marginRight: 20,
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                >
                </Text>
                <View
                  style={{
                    height: 120,
                    borderWidth: 1,
                    borderColor: "#e6e6e6",
                    backgroundColor: "#fff",
                    borderRadius: Platform.OS == "web" ? 5 : null,
                    width: 120,
                  }}
                >
                  <ImageBackground
                    style={styles.image}
                    imageStyle={{
                      borderRadius: 5,
                    }}
                    source={{
                      uri: setIcon,
                    }}
                    resizeMode={"cover"}
                  >
                    <Icon
                      color={"#000"}
                      iconStyle={{
                        color: "#fff",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      reverse
                      name="camera"
                      type="font-awesome"
                      size={16}
                      containerStyle={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: 1,
                      }}
                      onPress={this.pickIcon}
                    />
                  </ImageBackground>
                </View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <View
                    style={{
                      width: "70%",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                      marginTop: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginTop: 10,
                        marginRight: 20,
                        fontSize: 16,
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Clik Name
                    </Text>
                    {Platform.OS == "web" ? (
                      <View>
                        <Icon
                          color={"#000"}
                          iconStyle={{
                            marginTop: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          name="info-circle"
                          type="font-awesome"
                          size={16}
                          onPress={() => this.tooglecliktooltip()}
                        />
                        {this.state.showcliktooltip == true && (
                          <Tooltip
                            withPointer={false}
                            withOverlay={false}
                            toggleOnPress={true}
                            containerStyle={{
                              left: -40,
                              top: -60,
                              width: 100,
                            }}
                            popover={
                              <Text
                                style={{
                                  fontFamily: ConstantFontFamily.defaultFont,
                                }}
                              >
                                {ConstantTooltip.CreateClik[0].ClikTooltip}
                              </Text>
                            }
                          />
                        )}
                      </View>
                    ) : (
                      <Tooltip
                        withOverlay={false}
                        popover={
                          <Text
                            style={{
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            {ConstantTooltip.CreateClik[0].ClikTooltip}
                          </Text>
                        }
                      >
                        <Icon
                          color={"#000"}
                          iconStyle={{
                            marginTop: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          name="info-circle"
                          type="font-awesome"
                          size={16}
                        />
                      </Tooltip>
                    )}
                  </View>
                  <TouchableOpacity
                    style={{
                      width: "30%",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                    onMouseEnter={() => this.setState({ clikHover: true })}
                    onMouseLeave={() => this.setState({ clikHover: false })}
                  >
                    {(this.state.clikName.length < 3 ||
                      this.state.clikName.length > 24 ||
                      this.state.clikName[this.state.clikName.length - 1] ==
                      "-") && (
                        <Icon
                          color={"#f80403"}
                          iconStyle={{
                            marginTop: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          name="times"
                          type="font-awesome"
                          size={16}
                        />
                      )}
                    {this.state.clikHover == true && Platform.OS == "web" ? (
                      <Tooltip
                        backgroundColor={"#d3d3d3"}
                        withPointer={false}
                        withOverlay={false}
                        toggleOnPress={true}
                        containerStyle={{
                          left: -60,
                          top: -60,
                        }}
                        popover={
                          <Text
                            style={{
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            ClikName should be 3 to 24 Characters
                          </Text>
                        }
                      />
                    ) : null}
                  </TouchableOpacity>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    value={this.state.clikName}
                    placeholder="EnterNameWithOutSpaces"
                    placeholderTextColor="#6D757F"
                    style={[
                      textStyle,
                      this.state.focusClikname
                        ? ButtonStyle.selecttextAreaShadowStyle
                        : ButtonStyle.textAreaShadowStyle,
                      {
                        height: 45,
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        fontFamily: ConstantFontFamily.defaultFont,
                        outline: "none",
                      },
                    ]}
                    onChangeText={(clikName) => this.checkClikname(clikName)}
                    onFocus={() => this.setState({ focusClikname: true })}
                    onBlur={() => this.setState({ focusClikname: false })}
                  />
                </View>
                <View
                  style={{ flexDirection: "row", width: "100%", marginTop: 10 }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      marginRight: 20,
                      fontSize: 16,
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                  >
                    Invite Only
                  </Text>
                  <View
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      title="Yes"
                      buttonStyle={{
                        backgroundColor:
                          this.state.switchValue == true ? "#009B1A" : "#fff",
                        borderColor: "#e1e1e1",
                        borderWidth: 1,
                        height: 30,
                        borderBottomLeftRadius: 6,
                        borderTopLeftRadius: 6,
                      }}
                      titleStyle={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        paddingHorizontal: 10,
                        fontSize: 14,
                        color:
                          this.state.switchValue == true ? "#fff" : "#e1e1e1",
                      }}
                      onPress={() => this.setState({ switchValue: true })}
                    />
                    <Button
                      title="No"
                      buttonStyle={{
                        backgroundColor:
                          this.state.switchValue == false ? "#009B1A" : "#fff",
                        borderColor: "#e1e1e1",
                        borderWidth: 1,
                        height: 30,
                        borderTopRightRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                      titleStyle={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        paddingHorizontal: 10,
                        fontSize: 14,
                        color:
                          this.state.switchValue == false ? "#fff" : "#e1e1e1",
                      }}
                      onPress={() => this.setState({ switchValue: false })}
                    />
                  </View>
                </View>
                <Text
                  style={{
                    fontWeight: "bold",
                    marginTop: 15,
                    marginRight: 20,
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                >
                  Website
                </Text>
                <TextInput
                  value={this.state.website}
                  placeholder="Optional"
                  style={[
                    textStyle,
                    this.state.focusWeburl
                      ? ButtonStyle.selecttextAreaShadowStyle
                      : ButtonStyle.textAreaShadowStyle,
                    {
                      height: 45,
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      fontFamily: ConstantFontFamily.defaultFont,
                      outline: "none",
                    },
                  ]}
                  onChangeText={(website) => this.setState({ website })}
                  onFocus={() => this.setState({ focusWeburl: true })}
                  onBlur={() => this.setState({ focusWeburl: false })}
                />
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <View
                    style={{
                      width: "100%",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                      marginTop: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginTop: 10,
                        marginRight: 20,
                        fontSize: 16,
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Description
                    </Text>
                  </View>
                  <TouchableOpacity
                    style={{
                      width: "30%",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                    onMouseEnter={() =>
                      this.setState({ descriptionHover: true })
                    }
                    onMouseLeave={() =>
                      this.setState({ descriptionHover: false })
                    }
                  >
                    {(this.state.description.length < 50 ||
                      this.state.description.length > 300) && (
                        <Icon
                          color={"#f80403"}
                          iconStyle={{
                            marginTop: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          name="times"
                          type="font-awesome"
                          size={16}
                        />
                      )}
                    {this.state.descriptionHover == true &&
                      Platform.OS == "web" ? (
                      <Tooltip
                        backgroundColor={"#d3d3d3"}
                        withPointer={false}
                        withOverlay={false}
                        toggleOnPress={true}
                        containerStyle={{
                          left: -60,
                          top: -60,
                        }}
                        popover={
                          <Text
                            style={{
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            Description should be 50 to 300 Characters
                          </Text>
                        }
                      />
                    ) : null}
                  </TouchableOpacity>
                </View>
                <TextInput
                  value={this.state.description}
                  multiline={true}
                  numberOfLines={5}
                  placeholder="Enter a description"
                  placeholderTextColor="#6D757F"
                  style={[
                    textStyle,
                    this.state.focusDesc
                      ? ButtonStyle.selecttextAreaShadowStyle
                      : ButtonStyle.textAreaShadowStyle,
                    {
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      fontFamily: ConstantFontFamily.defaultFont,
                      height: Platform.OS == "ios" ? 100 : null,
                      outline: "none",
                    },
                  ]}
                  onChangeText={(description) => this.setState({ description })}
                  onFocus={() => this.setState({ focusDesc: true })}
                  onBlur={() => this.setState({ focusDesc: false })}
                />
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <View
                    style={{
                      width: "70%",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginTop: 15,
                        marginRight: 20,
                        fontSize: 16,
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Prerequisites to Join Clik
                    </Text>
                  </View>
                  <TouchableOpacity
                    style={{
                      width: "30%",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                    onMouseEnter={() => this.setState({ joinclikHover: true })}
                    onMouseLeave={() => this.setState({ joinclikHover: false })}
                  >
                    <Icon
                      name="plus"
                      type="font-awesome"
                      size={18}
                      cursor="pointer"
                      containerStyle={{
                        marginLeft: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onPress={() => this.addcustomQualificationArray()}
                    />
                  </TouchableOpacity>
                </View>
                {this.state.customQualificationArray &&
                  this.state.customQualificationArray.map((item, index) => {
                    return (
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            width: "5%",
                            fontFamily: ConstantFontFamily.defaultFont,
                          }}
                        >
                          {index + 1})
                        </Text>
                        <TextInput
                          style={[
                            this.state.focusQualification
                              ? ButtonStyle.selecttextAreaShadowStyle
                              : ButtonStyle.textAreaShadowStyle,
                            {
                              height: 45,
                              paddingVertical: 5,
                              paddingHorizontal: 10,
                              outline: "none",
                            },
                          ]}
                          placeholder="Enter a qualification"
                          value={item.text}
                          onChangeText={(text) =>
                            this.handleCustomQualification(text, index)
                          }
                          onFocus={() =>
                            this.setState({ focusQualification: true })
                          }
                          onBlur={() =>
                            this.setState({ focusQualification: false })
                          }
                        />
                        <TouchableOpacity
                          style={{ width: "5%" }}
                          onPress={() =>
                            this.removecustomQualificationArray(index)
                          }
                        >
                          <Icon
                            name="trash"
                            type="font-awesome"
                            size={18}
                            cursor="pointer"
                            containerStyle={{
                              marginLeft: "auto",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                    );
                  })}

                <Text
                  style={{
                    fontWeight: "bold",
                    marginTop: 15,
                    marginRight: 20,
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                >
                  Max Number Of Members
                </Text>
                <TextInput
                  placeholder="100"
                  placeholderTextColor="#000"
                  style={[
                    textStyle,
                    this.state.focusSummary
                      ? ButtonStyle.selecttextAreaShadowStyle
                      : ButtonStyle.textAreaShadowStyle,
                    {
                      height: 45,
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      outline: "none",
                      fontFamily: ConstantFontFamily.defaultFont,
                    },
                  ]}
                  onChangeText={(summary) => this.setState({ summary })}
                  onFocus={() => this.setState({ focusSummary: true })}
                  onBlur={() => this.setState({ focusSummary: false })}
                />

                <View
                  style={{
                    marginTop: 20,
                    alignSelf: "center",
                  }}
                >
                  <Button
                    title="SUBMIT"
                    titleStyle={ButtonStyle.wtitleStyle}
                    buttonStyle={ButtonStyle.gbackgroundStyle}
                    containerStyle={[
                      ButtonStyle.containerStyle,
                      { marginVertical: 10 },
                    ]}
                    onPress={() => this.editCliks()}
                  />
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        {Dimensions.get("window").width <= 750 && (
          <Suspense fallback={null}>
            <BottomScreen navigation={NavigationService} />
          </Suspense>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  listTrending_cliks: !state.TrendingCliksReducer.getIn(["Trending_cliks_List"])
    ? List()
    : state.TrendingCliksReducer.getIn(["Trending_cliks_List"]),
  getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  clikdetails: state.EditClikReducer.get("clik"),
});

const mapDispatchToProps = (dispatch) => ({
  getTrendingClicks: (payload) => dispatch(getTrendingClicks(payload)),
  setHASSCROLLEDACTION: (payload) => dispatch(setHASSCROLLEDACTION(payload)),
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
  ClikId: (payload) => dispatch(getTrendingCliksProfileDetails(payload)),
  clikIconUpload: (payload) => dispatch({ type: "ICON_UPLOAD", payload }),
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateClikScreen
);

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: "100%",
  },
  usertext: {
    color: "#000",
    fontSize: 14,
    fontFamily: ConstantFontFamily.defaultFont,
  },
});
