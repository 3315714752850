import React from "react";
import { Button } from "react-native";
import { Animated, Dimensions, View } from "react-native";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import ButtonStyle from "../constants/ButtonStyle";

class ShadowSkeletonPostDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loopArray: [0, 1, 2, 3, 4]
        };
        this.Pagescrollview = null;
        this.circleAnimatedValue = new Animated.Value(0);
    }

    componentDidMount() {
        this.circleAnimated();
    }

    circleAnimated = () => {
        this.circleAnimatedValue.setValue(0);
        Animated.timing(this.circleAnimatedValue, {
            toValue: 1,
            duration: 1000
        }).start(() => {
            setTimeout(() => {
                this.circleAnimated();
            }, 1000);
        });
    };

    render() {
        const translateX = this.circleAnimatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: [-10, Dimensions.get("window").width]
        });
        const translateTitle = this.circleAnimatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: [-10, Dimensions.get("window").width]
        });
        return (
            <>
                <View
                    style={[
                        ButtonStyle.ZeroBorderNotificationStyle,
                        {
                            width: "100%",
                            // marginLeft: 5,
                            backgroundColor: "rgba(227, 227, 227, 0.2)",
                            overflow: "hidden"
                        }
                    ]}
                >
                    <Animated.View
                        style={[
                            ButtonStyle.ZeroBorderNotificationStyle,
                            {
                                width: "50%",
                                height: hp("15%"),
                                backgroundColor: "#e3e3e3",
                                overflow: "hidden",
                                transform: [{ translateX: translateX }]
                            }
                        ]}
                    ></Animated.View>

                </View>
                <View
                    style={[
                        ButtonStyle.ZeroBorderNotificationStyle,
                        {
                            width: "100%",
                            // marginLeft: 5,
                            backgroundColor: "rgba(227, 227, 227, 0.2)",
                            overflow: "hidden"
                        }
                    ]}
                >
                    <Animated.View
                        style={[
                            ButtonStyle.ZeroBorderNotificationStyle,
                            {
                                width: "50%",
                                height: hp("5%"),
                                backgroundColor: "#e3e3e3",
                                overflow: "hidden",
                                transform: [{ translateX: translateX }]
                            }
                        ]}
                    ></Animated.View>

                </View>
                {this.state.loopArray.map((res, index) => {
                    return (
                        <View
                            key={index}
                            style={{
                                width: "100%",
                                // marginBottom: 10
                            }}
                        >
                            <View
                                style={[
                                    ButtonStyle.ZeroBorderNotificationStyle,
                                    {
                                        width: "100%",
                                        // marginLeft: 5,
                                        backgroundColor: "rgba(227, 227, 227, 0.2)",
                                        overflow: "hidden"
                                    }
                                ]}
                            >
                                <Animated.View
                                    style={[
                                        ButtonStyle.ZeroBorderNotificationStyle,
                                        {
                                            width: "50%",
                                            height: hp("15%"),
                                            backgroundColor: "#e3e3e3",
                                            overflow: "hidden",
                                            transform: [{ translateX: translateX }]
                                        }
                                    ]}
                                ></Animated.View>

                            </View>                           
                        </View>
                    );
                })}

                {/* <View
          style={{
            width: "70%",
            marginTop: 10,
            marginLeft: 5,
            backgroundColor: "#fff",
            overflow: "hidden",
            borderRadius: 10,
            borderWidth: 1,
            borderColor: "#c5c5c5",
            alignSelf: "center"
          }}
        >
          <View
            style={{
              width: "100%",
              height: 40,
              borderRadius: 10,
              borderWidth: 1,
              borderColor: "#c5c5c5",
              backgroundColor: "#e3e3e3",
              // transform: [{ translateX: translateTitle }]
            }}
          ></View>
        </View> */}
                {/* <View
          style={{
            width: "95%",
            marginTop: 10,
            marginLeft: 5,
            backgroundColor: "#fff",
            overflow: "hidden",
            borderRadius: 10,
            borderWidth: 1,
            borderColor: "#c5c5c5"
          }}
        >
          <View
            style={{
              width: "100%",
              height: 30,
              borderRadius: 10,
              borderWidth: 1,
              borderColor: "#c5c5c5",
              backgroundColor: "#e3e3e3",
              // transform: [{ translateX: translateTitle }]
            }}
          ></View>
        </View> */}

                {/* <View
          style={{
            width: "95%",
            marginTop: 5,
            marginLeft: 5,
            backgroundColor: "#fff",
            overflow: "hidden",
            borderRadius: 10,
            borderWidth: 1,
            borderColor: "#c5c5c5"
          }}
        >
          <View
            style={{
              width: "100%",
              height: 30,
              borderRadius: 10,
              borderWidth: 1,
              borderColor: "#c5c5c5",
              backgroundColor: "#e3e3e3",
              // transform: [{ translateX: translateTitle }]
            }}
          ></View>
        </View> */}

                {/* <View
          style={{
            width: "95%",
            marginTop: 5,
            marginLeft: 5,
            backgroundColor: "#fff",
            overflow: "hidden",
            borderRadius: 10,
            borderWidth: 1,
            borderColor: "#c5c5c5"
          }}
        >
          <View
            style={{
              width: "100%",
              height: 30,
              borderRadius: 10,
              borderWidth: 1,
              borderColor: "#c5c5c5",
              backgroundColor: "#e3e3e3",
              // transform: [{ translateX: translateTitle }]
            }}
          ></View>
        </View> */}
            </>
        );
    }
}
export default ShadowSkeletonPostDetails;
