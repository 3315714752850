import { launchImageLibraryAsync } from "expo-image-picker";
import { askAsync, CAMERA_ROLL } from "expo-permissions";
import { List } from "immutable";
import React, { lazy, Component, Suspense } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  Animated,
  // AsyncStorage,
  Dimensions,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Icon, Tooltip } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import applloClient from "../client";
import AppHelper from "../constants/AppHelper";
import ButtonStyle from "../constants/ButtonStyle";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import { CreateTopicMutation } from "../graphqlSchema/graphqlMutation/PostMutation";
import {
  SearchTopicMutation,
} from "../graphqlSchema/graphqlMutation/SearchMutation";
import {
  GetChildTopicsMutation,
  TopicQuery,
  ParentTopic
} from "../graphqlSchema/graphqlMutation/TrendingMutation";
import {
  TopicFollowingMutation
} from "../graphqlSchema/graphqlMutation/UserMutation";
import { CreateTopicVariables } from "../graphqlSchema/graphqlVariables/PostVariables";
import {
  SearchTopicVariables,
} from "../graphqlSchema/graphqlVariables/SearchVariables";
import { uploadProfileImageAsync } from "../services/UserService";
import { screen } from '../reducers/action/Completed'

const TataStructure = lazy(() => import("../components/TataStructure"))

const HeaderRight = lazy(() => import("../components/HeaderRight"))

class CreateTopicScreen extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.Pagescrollview = null;
    this.state = {
      value: 50,
      textStyle: "WHITE",
      uploading: false,
      profilePic: "",
      title: "",
      text: "",
      items: [],
      changeBackPicEnable: null,
      setBackPic: null,
      showsVerticalScrollIndicatorView: false,
      currentScreentWidth: 0,
      topicName: "",
      rtopicName: "",
      description: "",
      clikHover: false,
      descriptionHover: false,
      TopicList: [],
      TopicListHierarchy: [],
      UserList: [],
      showDeleteIcon: false,
      inputParentName: false,
      rtopicParents: "#000",
      parentsOfSelectedTopic: [],
      parentId: ""
    };
    this.baseState = this.state;
  }

  componentDidMount = async () => {
    this.props.searchOpenBarStatus(false)
    this.props.screen('createTopic')
    this._isMounted = true;
    const profileData = this.props.profileData;
    let userProfilePic = profileData.user.profile_pic
    this.setState({
      profilePic: {
        uri: userProfilePic
      }
    });
    let joined = [];
    this.props.listTrending_cliks.map(async (value, index) => {
      await joined.push({
        id: value.node.id,
        name: value.node.name
      });
    });
    this.setState({ items: joined });
  };

  componentDidUpdate() {
    if (this.props.getHasScrollTop == true && this.Pagescrollview) {
      this.Pagescrollview.scrollTo({ x: 0, y: 0, animated: true });
      this.props.setHASSCROLLEDACTION(false);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _askPermission = async (type, failureMessage) => {
    const { status, permissions } = await askAsync(type);
    if (status === "denied") {
      alert(failureMessage);
    }
  };

  _pickBannerImage = async () => {
    await this._askPermission(
      CAMERA_ROLL,
      "We need the camera-roll permission to read pictures from your phone..."
    );
    let pickerResult = await launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [4, 3],
      base64: true
    });
    this._handleBannerImagePicked(pickerResult);
  };

  _handleBannerImagePicked = async pickerResult => {
    let uploadResponse, uploadResult;
    try {
      this.setState({ uploading: true });
      if (!pickerResult.cancelled) {
        uploadResponse = await uploadProfileImageAsync(pickerResult.uri, true);
        uploadResult = await uploadResponse.json();
        this.setState({
          setBackPic: pickerResult.uri
        });
        this.setState({
          changeBackPicEnable: uploadResult.id,
          showDeleteIcon: true
        });
      }
    } catch (e) {
      alert("Upload failed, sorry :(" + e + ")");
    } finally {
      this.setState({ uploading: false });
    }
  };

  createTopic = async () => {
    let __self = this;
    CreateTopicVariables.variables.name = this.state.topicName;
    CreateTopicVariables.variables.description = this.state.description;
    CreateTopicVariables.variables.profile_pic = this.state.changeBackPicEnable?.toString();
    CreateTopicVariables.variables.parent_topic_id = this.state.parentId;
    try {
      let id = await AsyncStorage.getItem("UserId");
      await applloClient
        .query({
          query: CreateTopicMutation,
          ...CreateTopicVariables,
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          this.setState(this.baseState);

          let topicfollow = await applloClient
            .query({
              query: TopicFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 50
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.topics_following.edges
            })
          let userData = {
            data: this.props.profileData,
            externalfollow: this.getUserFollowFeedList,
            clikfollow: this.props.getUserFollowCliksList,
            topicfollow: topicfollow,
            userfollow: this.props.getUserFollowUserList

          }
          await this.props.saveLoginUser(userData);
          if (this.props.profileData) {
            await __self.props.getTrendingTopics({
              currentPage: AppHelper.PAGE_LIMIT
            });
            __self.props.navigation.navigate("home");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  checkTopicname = async name => {
    var letters = /^[0-9a-zA-Z-]+$/;
    if (name.match(letters) || name == "") {
      if (name[0] == "-") {
        alert("- not allowed at initial of clik name");
        return false;
      }
      this.setState({ topicName: name });
      return true;
    } else {
      alert("Please input alphanumeric characters only");
      return false;
    }
  };

  checkRTopicname = async name => {
    var letters = /^[0-9a-zA-Z-]+$/;
    if (name.match(letters) || name == "") {
      if (name[0] == "-") {
        alert("- not allowed at initial of clik name");
        return false;
      }
      this.setState(
        {
          rtopicName: name,
          TopicListHierarchy: [],
          rtopicParents: "#000"
        },
        () => {
          this.customRenderTopicSuggestion(name);
        }
      );
      return true;
    } else {
      alert("Please input alphanumeric characters only");
      return false;
    }
  };

  customRenderTopicSuggestion = value => {
    SearchTopicVariables.variables.prefix = value;
    applloClient
      .query({
        query: SearchTopicMutation,
        ...SearchTopicVariables,
        fetchPolicy: "no-cache"
      })
      .then(res => {
        this.setState({
          TopicList: res.data.search.topics,
          TopicListHierarchy: []
        });
      });
  };

  getTopicProfileDetails = value => {
    applloClient
      .query({
        query: TopicQuery,
        variables: {
          id: "Topic:" + value
        },
        fetchPolicy: "no-cache"
      })
      .then(res => {
        this.setState({ parentId: res.data.node.id })
        if (res) {
          applloClient.query({
            query: ParentTopic,
            variables: {
              id: "Topic:" + value,
            },
            fetchPolicy: "no-cache",
          }).then(e => {
            this.setState({
              parentsOfSelectedTopic: res.data.node.parent_topic
                ? [...res.data.node.parent_topic, value]
                : [value],


            });
          })



        }
      });
  };
  getChildStructure(arr, selectTopic, childrenOfSelectTopic) {
    let output = [];
    let i = 0;
    if (arr.length > 0)
      output[i] = {
        name: arr[i],
        children:
          selectTopic === arr[i]
            ? [...childrenOfSelectTopic]
            : this.getChildStructure(
              arr.slice(i + 1),
              selectTopic,
              childrenOfSelectTopic
            )
      };

    return output;
  }
  renderTopicListHierarchy = value => {
    this.setState({
      TopicListHierarchy: []
    });
    if (value) {
      applloClient
        .query({
          query: GetChildTopicsMutation,
          variables: {
            id: "Topic:" + value
          },
          fetchPolicy: "no-cache"
        })
        .then(res => {
          let { subtopics } = res.data.node;
          this.setState({
            TopicListHierarchy: this.getChildStructure(
              this.state.parentsOfSelectedTopic,
              value,
              [{ name: "dynamicText" }, ...subtopics]
            ),
          });
        });
    }
  };

  handleTopicSelectInput = (topic, parents, item) => {
    this.setState(
      {
        rtopicName: topic,
        TopicList: [],
        TopicListHierarchy: []
      },
      () => {
        if (parents) {
          this.colorCondition(parents);
        } else {
          this.colorCondition("");
        }
        if (topic != "") {
          this.getTopicProfileDetails(topic);
          this.renderTopicListHierarchy(topic);
        }
        if (this.state.rtopicName == "") {
          this.setState({
            rtopicParents: "",
            TopicList: []
          });
        }
      }
    );
  };

  colorCondition = parent => {
    if (parent.length > 0) {
      this.setState({
        rtopicParents: "#009B1A"
      });
    } else if (parent == "") {
      this.setState({
        rtopicParents: "#009B1A"
      });
    } else if (parent == null) {
      this.setState({
        rtopicParents: "#009B1A"
      });
    }
  };

  render() {
    const textStyle = styles.usertext;
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: ConstantColors.whiteBackground,
          borderLeftWidth:1,
          borderColor:'#d3d3d3'
        }}
      >
        {/* {Dimensions.get("window").width <= 750 && (
          <Animated.View
            style={{
              position: Platform.OS == "web" ? "sticky" : null,
              top: 0,
              left: 0,
              right: 0,
              zIndex: 10,
              overflow: "hidden",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  backgroundColor: "#000",
                  height: 50
                }}
              >
                <TouchableOpacity
                  style={ButtonStyle.headerBackStyle}
                  onPress={() => {
                    this.props.searchOpenBarStatus(false);
                    let nav = this.props.navigation.dangerouslyGetParent()
                      .state;
                    if (nav.routes.length > 1) {
                      this.props.navigation.goBack();
                      return;
                    } else {
                      this.props.navigation.navigate("home");
                    }
                  }}
                >
                  <Icon
                    color={"#fff"}
                    name="angle-left"
                    type="font-awesome"
                    size={40}
                  />
                </TouchableOpacity>
                {!this.props.getsearchBarStatus &&
                  <TouchableOpacity
                    style={[ButtonStyle.headerTitleStyle, { backgroundColor: "#000" }]}
                  >
                    <Text
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: 18,
                        fontFamily: ConstantFontFamily.defaultFont
                      }}
                    >
                      Propose Topic
                    </Text>
                  </TouchableOpacity>
                }
                <View
                  style={ButtonStyle.headerRightStyle}
                >
                  <Suspense fallback={null}>
                    <HeaderRight navigation={this.props.navigation} />
                  </Suspense>
                </View>
              </View>
            </View>
          </Animated.View>
        )} */}
        <ScrollView
          ref={scrollview => {
            this.Pagescrollview = scrollview;
          }}
          showsVerticalScrollIndicator={false}
          onLayout={event => {
            let { x, y, width, height } = event.nativeEvent.layout;
            if (width < 1024) {
              this.setState({
                showsVerticalScrollIndicatorView: true,
                currentScreentWidth: width
              });
            } else {
              this.setState({
                showsVerticalScrollIndicatorView: false,
                currentScreentWidth: width
              });
            }
          }}
          contentContainerStyle={
            {
              backgroundColor: "#f7f7f7",
              width: '100%'
            }
          }
          style={{
            height:
              Platform.OS !== "web"
                ? null
                : Dimensions.get("window").height - 80
          }}
        >

          <View style={[Dimensions.get("window").width >= 1200 ? ButtonStyle.normalFeedStyleForDashboard : null,
          {
            // marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
            paddingTop: 10,
            paddingHorizontal: 10,
            marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 2,
            borderBottomWidth: 0
          }]}>
            <View
              style={{
                height: 120,
                borderWidth: 1,
                borderColor: "#e6e6e6",
                backgroundColor: "#fff",
                borderRadius: Platform.OS == "web" ? 5 : null,
                width: 120,
              }}
            >
              <ImageBackground
                style={styles.image}
                imageStyle={{
                  borderRadius: 5,
                }}
                source={{
                  uri: this.state.setBackPic,
                }}
                resizeMode={"cover"}
              >
                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  reverse
                  name="camera"
                  type="font-awesome"
                  size={16}
                  containerStyle={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                  onPress={this._pickBannerImage}
                />
              </ImageBackground>
            </View>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <View
                style={{
                  width: "90%",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  marginTop: 5
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    marginRight: 20,
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  Topic Name
                </Text>
              </View>
              <TouchableOpacity
                style={{
                  width: "10%",
                  justifyContent: "flex-end",
                  alignItems: "flex-end"
                }}
                onMouseEnter={() => this.setState({ clikHover: true })}
                onMouseLeave={() => this.setState({ clikHover: false })}
              >
                {(this.state.topicName.length < 3 ||
                  this.state.topicName.length > 24 ||
                  this.state.topicName[this.state.topicName.length - 1] ==
                  "-") && (
                    <Icon
                      color={"#f80403"}
                      iconStyle={{
                        marginTop: 10,
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                      name="times"
                      type="font-awesome"
                      size={16}
                    />
                  )}
                {this.state.clikHover == true && Platform.OS == "web" ? (
                  <Tooltip
                    backgroundColor={"#d3d3d3"}
                    withPointer={false}
                    withOverlay={false}
                    toggleOnPress={true}
                    containerStyle={{
                      left: -60,
                      top: -60
                    }}
                    popover={
                      <Text
                        style={{ fontFamily: ConstantFontFamily.defaultFont }}
                      >
                        Topic Name should be 3 to 24 Characters
                      </Text>
                    }
                  />
                ) : null}
              </TouchableOpacity>
            </View>

            <View style={{ flexDirection: "row", width: "100%" }}>
              <TextInput
                value={this.state.topicName}
                placeholder="2-31 characters, only alphanumeric and underscores."
                placeholderTextColor="#6D757F"
                style={[
                  textStyle,
                  this.state.focusTopic ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                  {
                    height: 45,
                    padding: 5,
                    fontFamily: ConstantFontFamily.defaultFont,
                    backgroundColor: "#fff",
                  }
                ]}
                onChangeText={topicName => this.checkTopicname(topicName)}
                onFocus={() => this.setState({ focusTopic: true })}
                onBlur={() => this.setState({ focusTopic: false })}
              />

            </View>

            <View style={{ flexDirection: "row", width: "100%" }}>
              <View
                style={{
                  width: "90%",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  marginTop: 5
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    marginRight: 20,
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  Parent Topic Name
                </Text>
              </View>
              <TouchableOpacity
                style={{
                  width: "10%",
                  justifyContent: "flex-end",
                  alignItems: "flex-end"
                }}
                onMouseEnter={() => this.setState({ clikHover: true })}
                onMouseLeave={() => this.setState({ clikHover: false })}
              >
                {(this.state.rtopicName.length < 3 ||
                  this.state.rtopicName.length > 24 ||
                  this.state.rtopicName[this.state.rtopicName.length - 1] ==
                  "-") && (
                    <Icon
                      color={"#f80403"}
                      iconStyle={{
                        marginTop: 10,
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                      name="times"
                      type="font-awesome"
                      size={16}
                    />
                  )}
                {this.state.clikHover == true && Platform.OS == "web" ? (
                  <Tooltip
                    backgroundColor={"#d3d3d3"}
                    withPointer={false}
                    withOverlay={false}
                    toggleOnPress={true}
                    containerStyle={{
                      left: -60,
                      top: -60
                    }}
                    popover={
                      <Text
                        style={{ fontFamily: ConstantFontFamily.defaultFont }}
                      >
                        Topic1 name should be 3 to 24 Characters
                      </Text>
                    }
                  />
                ) : null}
              </TouchableOpacity>
            </View>

            <View style={{ flexDirection: "row", width: "100%" }}>
              <View
                style={{
                  width: "100%",
                  marginVertical: 5,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <TextInput
                  value={this.state.rtopicName}
                  placeholder="Type and select an existing topic"
                  placeholderTextColor="#6D757F"
                  onChangeText={rtopicName => {
                    this.checkRTopicname(rtopicName);
                  }}
                  style={[
                    textStyle,
                    this.state.focusParentTopic ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                    {
                      height: 45,
                      padding: 5,
                      fontFamily: ConstantFontFamily.defaultFont,
                      backgroundColor: "#fff",
                    }
                  ]}
                  onFocus={e => {
                    this.setState({
                      inputParentName: true,
                      focusParentTopic: true
                    });
                  }}
                  onBlur={() => this.setState({ focusParentTopic: false })}
                />
                {this.state.inputParentName == true &&
                  this.state.TopicList.map((item, index) => {
                    return (
                      <View
                        key={item.name}
                        style={{
                          backgroundColor: "#FEFEFA",
                          width: "100%",
                          padding: 5
                        }}
                      >
                        <View
                          style={{
                            padding: 5,
                            backgroundColor: item.parents ? "#e3f9d5" : "#e3f9d5",
                            borderRadius: 6,
                            alignSelf: "flex-start",
                            alignItems: "center"
                          }}
                        >
                          <Text
                            style={[ButtonStyle.profileTitleStyle, {
                              color: item.parents ? "#009B1A" : "#009B1A",

                            }]}
                            onPress={() =>
                              this.handleTopicSelectInput(
                                item.name
                              )
                            }
                          >
                            /{item.name.toLowerCase()}
                          </Text>
                        </View>
                      </View>
                    );
                  })}
              </View>
            </View>
            {this.state.TopicListHierarchy.length > 0 && (
              <View>
                <View style={{ flexDirection: "row", width: "100%" }}>
                  <Text

                    style={{
                      fontWeight: "bold",
                      marginVertical: 10,
                      marginRight: 20,
                      fontSize: 16,
                      fontFamily: ConstantFontFamily.defaultFont
                    }}
                  >
                    Topic Hierarchy
                  </Text>
                </View>
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: "#e1e1e1",
                    borderRadius: 20,
                    padding: 10
                  }}
                >
                  <Suspense fallback={null}>
                    <TataStructure
                      isRoot={true}
                      item={this.state.TopicListHierarchy[0]}
                      topicName={this.state.topicName}
                    />
                  </Suspense>
                </View>
              </View>
            )}

            <View style={{ flexDirection: "row", width: "100%" }}>
              <View
                style={{
                  width: "70%",
                  justifyContent: "flex-start",
                  flexDirection: "row"
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    marginRight: 20,
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  Description
                </Text>
              </View>
              <TouchableOpacity
                style={{
                  width: "30%",
                  justifyContent: "flex-end",
                  alignItems: "flex-end"
                }}
                onMouseEnter={() => this.setState({ descriptionHover: true })}
                onMouseLeave={() => this.setState({ descriptionHover: false })}
              >
                {(this.state.description.length < 50 ||
                  this.state.description.length > 300) && (
                    <Icon
                      color={"#f80403"}
                      iconStyle={{
                        marginTop: 10,
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                      name="times"
                      type="font-awesome"
                      size={16}
                    />
                  )}
                {this.state.descriptionHover == true && Platform.OS == "web" ? (
                  <Tooltip
                    backgroundColor={"#d3d3d3"}
                    withPointer={false}
                    withOverlay={false}
                    toggleOnPress={true}
                    containerStyle={{
                      left: -60,
                      top: -60
                    }}
                    popover={
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont
                        }}
                      >
                        Description should be 50 to 300 Characters
                      </Text>
                    }
                  />
                ) : null}
              </TouchableOpacity>
            </View>

            <TextInput
              value={this.state.description}
              multiline={true}
              numberOfLines={5}
              placeholder="What the topic is about. Can paraphrase Wikipedia."
              placeholderTextColor="#6D757F"
              style={[
                textStyle,
                this.state.focusDesc ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                {
                  padding: 5,
                  height: Platform.OS == "ios" ? 100 : 45,
                  fontFamily: ConstantFontFamily.defaultFont,
                  backgroundColor: "#fff",
                }
              ]}
              onChangeText={description => this.setState({ description })}
              onFocus={() => this.setState({ focusDesc: true })}
              onBlur={() => this.setState({ focusDesc: false })}
            />

            <View
              style={{
                marginTop: 20,
                alignSelf: "center"
              }}
            >
              <Button
                title="PROPOSE TOPIC"
                titleStyle={ButtonStyle.wtitleStyle}
                containerStyle={ButtonStyle.containerStyle}
                buttonStyle={ButtonStyle.gbackgroundStyle}
                disabled={
                  this.state.topicName.length < 3 ||
                    this.state.topicName.length > 24 ||
                    this.state.topicName[this.state.topicName.length - 1] == "-" ||
                    this.state.description.length < 50 ||
                    this.state.description.length > 300
                    ? true
                    : false
                }
                onPress={() => this.createTopic()}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  listTrending_cliks: !state.TrendingCliksReducer.getIn(["Trending_cliks_List"])
    ? List()
    : state.TrendingCliksReducer.getIn(["Trending_cliks_List"]),
  getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  getsearchBarStatus: state.AdminReducer.get("searchBarOpenStatus"),
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList
    : [],
  getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
    ? state.LoginUserDetailsReducer.userFollowFeedList
    : [],
  getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
    ? state.LoginUserDetailsReducer.userFollowUserList
    : [],
});

const mapDispatchToProps = dispatch => ({

  getTrendingTopics: payload => dispatch(getTrendingTopics(payload)),
  setHASSCROLLEDACTION: payload => dispatch(setHASSCROLLEDACTION(payload)),
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  searchOpenBarStatus: payload => dispatch({ type: "SEARCHBAR_STATUS", payload }),
  screen: (payload) => dispatch(screen(payload))
});


export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateTopicScreen
);

const styles = StyleSheet.create({
  usertext: {
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },
  image: {
    width: "100%",
    height: "100%",
  },

});
