import React, { Component, lazy, Suspense } from "react";
import {
  Dimensions,
  ScrollView,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet, Platform
} from "react-native";
import { TabBar, TabView } from "react-native-tab-view";
import ShadowSkeletonStar from "../components/ShadowSkeletonStar";
import ConstantFontFamily from "../constants/FontFamily";
import { retry } from "../library/Helper";
import { Button, Icon } from "react-native-elements";
import NavigationService from "../library/NavigationService";
import { connect } from "react-redux";
import { compose } from "recompose";
import ButtonStyle from "../constants/ButtonStyle";
import Colors from "../constants/Colors";

import TrendingCliks from "./TrendingClicks"

import TrendingExternalFeed from "./TrendingExternalFeed"

import TrendingTopics from "./TrendingTopics"
import TrendingUsers from "./TrendingUsers"
import BottomPart from "./BottomPart";

class TrendingTabs extends Component {
  state = {
    index: 0,
    routes: [
      { key: "first", title: "Cliks", icon: "users", type: "font-awesome" },
      { key: "second", title: "Topics", icon: "book", type: "font-awesome" },
      { key: "third", title: "Users", icon: "user", type: "font-awesome" },
      { key: "fourth", title: "Feeds", icon: "rss", type: "font-awesome" },
    ],
    searchedFollowText: this.props.searchedFollowText,
    focus: "Cliks"
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.searchedFollowText != this.props.searchedFollowText) {
      await this.setState({
        searchedFollowText: this.props.searchedFollowText,
      });
    }
  };

  _renderTabBar = (props) => (
    // Dimensions.get("window").width >= 750 &&
    <View style={{ width: '15%', zIndex: 9999, backgroundColor: Colors.blueColor, marginRight: 15, }}>
      <TabBar
        {...props}
        indicatorStyle={{
          backgroundColor: "transparent",
          height: 1,
          borderRadius: 6,
          // width: "18.75%",
          // marginHorizontal: "3.125%"
        }}
        style={{
          backgroundColor: "#fff",
          shadowColor: "transparent",
          height: 60,

        }}
        contentContainerStyle={{
          flexDirection: 'column'
        }}
        labelStyle={[ButtonStyle.profileTitleStyle, {
          color: "#000",
          fontSize: 13,
          height: 60,
          justifyContent: "center",
        }]}
        // renderIcon={({ route, focused, color }) => (
        //   <Icon
        //     name={route.icon}
        //     type={route.type}
        //     color={focused ? route.title == 'Cliks' ? Colors.blueColor :
        //       route.title == 'Topics' ? Colors.greenColor :
        //         route.title == 'Users' ? '#000' :
        //           route.title == 'Feeds' && Colors.orangeColor : "#D3D3D3"}
        //   />
        // )}
        renderLabel={({ route, focused, color }) => {
          return (
            <View style={{ height: 60, width: 70, justifyContent: 'center', alignItems: 'center', backgroundColor: focused ? '#fff' : Colors.blueColor, flexDirection: 'column' }}>
              <Icon
                name={route.icon}
                type={route.type}
                color={focused ?
                  route.title == 'Cliks' ? Colors.blueColor :
                    route.title == 'Topics' ? Colors.greenColor :
                      route.title == 'Users' ? '#000' :
                        route.title == 'Feeds' && Colors.orangeColor : "#D3D3D3"}

              />
            </View>
          )
        }}
      // renderIndicator={({ route, focused, color }) => null}
      />

    </View>
  );

  _handleIndexChange = (index) =>
    this.setState({ index, searchedFollowText: "" }, () => {
      this.props.activeIndex(index);
    });

  _renderLazyPlaceholder = ({ route }) => <ShadowSkeletonStar />;

  _renderScene = ({ route }) => {
    switch (route.key) {
      case "first":
        return (
          <View
            style={{
              width: '75%',
              height: '100%',
              paddingRight: 15
              // flex: 1,
              //marginTop:50
            }}
          >
            {
              <View
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  alignItems: "center",
                  borderColor: "#d3d3d3",
                  borderWidth: 1,
                  borderRadius: 8,
                  marginBottom: 10,
                  backgroundColor: '#fff'
                  //paddingHorizontal: "3%",
                  //visibility: 'hidden'
                }}
              >
                <View style={{ width: "10%", marginRight: "auto" }}>
                  <Icon name="search" size={18} type="font-awesome" />
                </View>

                <TextInput
                  autoFocus={false}
                  // placeholder="Search Weclikd"
                  placeholder={
                    this.state.index == 0
                      ? "Search Cliks"
                      : this.state.index == 1
                        ? "Search Topics"
                        : this.state.index == 2
                          ? "Search Users"
                          : this.state.index == 3 && "Search Feeds"
                  }
                  placeholderTextColor='#706969'
                  onChangeText={(query) => {
                    this.props.onchangetext(query);
                  }}
                  value={this.props.searchedFollowText}
                  style={{
                    color: Platform.OS == 'web' ? '#706969' : "#000",
                    height: 20,
                    width: "74%",
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                    //border: "none",
                    outline: "none",
                    position: "absolute",
                    left: "13%",
                    fontWeight: 'bold',
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                />

                {this.props.searchedFollowText ? (
                  <TouchableOpacity
                    onPress={() => this.props.onpress("")}
                    style={{ marginLeft: "auto", width: "10%" }}
                  >
                    <Icon name="close" size={18} type="font-awesome" />
                  </TouchableOpacity>
                ) : null}
              </View>
            }
            <ScrollView showsVerticalScrollIndicator={false}>

              {/* <Suspense fallback={<ShadowSkeletonStar />}> */}
              <TrendingCliks
                navigation={this.props.navigation}
                searchedFollowText={this.state.searchedFollowText}
              // jumpTo='first'

              />
              {/* </Suspense> */}
            </ScrollView>
            <BottomPart />
          </View>
        );
      case "second":
        return (
          <View
            style={{
              width: '75%',
              height: '100%',
              paddingRight: 15
              // flex: 1,
              // marginTop:50
            }}
          >
            {
              <View
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  alignItems: "center",
                  borderColor: "#d3d3d3",
                  borderWidth: 1,
                  borderRadius: 8,
                  marginBottom: 10,
                  backgroundColor: '#fff'
                  //paddingHorizontal: "3%",
                  //visibility: 'hidden'
                }}
              >
                <View style={{ width: "10%", marginRight: "auto" }}>
                  <Icon name="search" size={18} type="font-awesome" />
                </View>

                <TextInput
                  autoFocus={false}
                  // placeholder="Search Weclikd"
                  placeholder={
                    this.state.index == 0
                      ? "Search Cliks"
                      : this.state.index == 1
                        ? "Search Topics"
                        : this.state.index == 2
                          ? "Search Users"
                          : this.state.index == 3 && "Search Feeds"
                  }
                  placeholderTextColor='#706969'
                  onChangeText={(query) => {
                    this.props.onchangetext(query);
                  }}
                  value={this.props.searchedFollowText}
                  style={{
                    color: Platform.OS == 'web' ? '#706969' : "#000",
                    height: 20,
                    width: "74%",
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                    //border: "none",
                    outline: "none",
                    position: "absolute",
                    left: "13%",
                    fontWeight: 'bold',
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                />

                {this.props.searchedFollowText ? (
                  <TouchableOpacity
                    onPress={() => this.props.onpress("")}
                    style={{ marginLeft: "auto", width: "10%" }}
                  >
                    <Icon name="close" size={18} type="font-awesome" />
                  </TouchableOpacity>
                ) : null}
              </View>
            }
            <ScrollView showsVerticalScrollIndicator={false}>
              {/* <Suspense fallback={<ShadowSkeletonStar />}> */}
              <TrendingTopics
                navigation={this.props.navigation}
                randomItemEvent={this.props.randomItemEvent}
                searchedFollowText={this.state.searchedFollowText}
              // jumpTo='second'
              />
              {/* </Suspense> */}
            </ScrollView>
            {this.props.getUsernameModalStatus == false && (
              <Button
                onPress={() => NavigationService.navigate("topichierarchy")}
                color="#fff"
                title="Topic Hierarchy"
                titleStyle={ButtonStyle.titleStyle}
                buttonStyle={ButtonStyle.backgroundStyle}
                containerStyle={ButtonStyle.containerStyle}
              />
            )}
            <BottomPart />
          </View>
        );
      case "third":
        return (
          <View
            style={{
              width: '75%',
              height: '100%',
              paddingRight: 15
              // flex: 1,
              //  marginTop:50
            }}
          >
            {
              <View
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  alignItems: "center",
                  borderColor: "#d3d3d3",
                  borderWidth: 1,
                  borderRadius: 8,
                  marginBottom: 10,
                  backgroundColor: '#fff'
                  //paddingHorizontal: "3%",
                  //visibility: 'hidden'
                }}
              >
                <View style={{ width: "10%", marginRight: "auto" }}>
                  <Icon name="search" size={18} type="font-awesome" />
                </View>

                <TextInput
                  autoFocus={false}
                  // placeholder="Search Weclikd"
                  placeholder={
                    this.state.index == 0
                      ? "Search Cliks"
                      : this.state.index == 1
                        ? "Search Topics"
                        : this.state.index == 2
                          ? "Search Users"
                          : this.state.index == 3 && "Search Feeds"
                  }
                  placeholderTextColor='#706969'
                  onChangeText={(query) => {
                    this.props.onchangetext(query);
                  }}
                  value={this.props.searchedFollowText}
                  style={{
                    color: Platform.OS == 'web' ? '#706969' : "#000",
                    height: 20,
                    width: "74%",
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                    //border: "none",
                    outline: "none",
                    position: "absolute",
                    left: "13%",
                    fontWeight: 'bold',
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                />

                {this.props.searchedFollowText ? (
                  <TouchableOpacity
                    onPress={() => this.props.onpress("")}
                    style={{ marginLeft: "auto", width: "10%" }}
                  >
                    <Icon name="close" size={18} type="font-awesome" />
                  </TouchableOpacity>
                ) : null}
              </View>
            }
            <ScrollView showsVerticalScrollIndicator={false}>
              {/* <Suspense fallback={<ShadowSkeletonStar />}> */}
              <TrendingUsers
                navigation={this.props.navigation}
                searchedFollowText={this.state.searchedFollowText}
              // jumpTo='third'
              />
              {/* </Suspense> */}
            </ScrollView>
            <BottomPart />
          </View>
        );
      default:
        return (
          <View style={{
            width: '75%',
            height: '100%',
            paddingRight: 15
          }}>
            {
              <View
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  alignItems: "center",
                  borderColor: "#d3d3d3",
                  borderWidth: 1,
                  borderRadius: 8,
                  marginBottom: 10,
                  backgroundColor: '#fff'
                  //paddingHorizontal: "3%",
                  //visibility: 'hidden'
                }}
              >
                <View style={{ width: "10%", marginRight: "auto" }}>
                  <Icon name="search" size={18} type="font-awesome" />
                </View>

                <TextInput
                  autoFocus={false}
                  // placeholder="Search Weclikd"
                  placeholder={
                    this.state.index == 0
                      ? "Search Cliks"
                      : this.state.index == 1
                        ? "Search Topics"
                        : this.state.index == 2
                          ? "Search Users"
                          : this.state.index == 3 && "Search Feeds"
                  }
                  placeholderTextColor='#706969'
                  onChangeText={(query) => {
                    this.props.onchangetext(query);
                  }}
                  value={this.props.searchedFollowText}
                  style={{
                    color: Platform.OS == 'web' ? '#706969' : "#000",
                    height: 20,
                    width: "74%",
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                    //border: "none",
                    outline: "none",
                    position: "absolute",
                    left: "13%",
                    fontWeight: 'bold',
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                />

                {this.props.searchedFollowText ? (
                  <TouchableOpacity
                    onPress={() => this.props.onpress("")}
                    style={{ marginLeft: "auto", width: "10%" }}
                  >
                    <Icon name="close" size={18} type="font-awesome" />
                  </TouchableOpacity>
                ) : null}
              </View>
            }
            <ScrollView showsVerticalScrollIndicator={false}>
              {/* <Suspense fallback={<ShadowSkeletonStar />}> */}
              <TrendingExternalFeed
                navigation={this.props.navigation}
                searchedFollowText={this.state.searchedFollowText}
              // jumpTo='fourth'
              />
              {/* </Suspense> */}
            </ScrollView>
            <BottomPart />
          </View>
        );
    }
  };


  changeTab = (title) => {
    if (title == "Cliks") {
      this.setState({
        index: 0,
        focus: "Cliks"
      })
    } else if (title == "Topics") {
      this.setState({
        index: 1,
        focus: "Topics"
      })
    } else if (title == "Users") {
      this.setState({
        index: 2,
        focus: "Users"
      })
    } else if (title == "Feeds") {
      this.setState({
        index: 3,
        focus: "Feeds"
      })
    }
  }
  render() {
    return (
      // <TabView      
      //   swipeEnabled={false}
      //   navigationState={this.state}
      //   renderScene={this._renderScene}
      //   renderLazyPlaceholder={this._renderLazyPlaceholder}
      //   renderTabBar={this._renderTabBar}
      //   onIndexChange={this._handleIndexChange}
      //   timingConfig={{ duration: 0 }}
      //   // removeClippedSubviews={Platform.OS == "web" && Dimensions.get("window").width >= 750 && Dimensions.get("window").width <= 1200 ? true : false}
      //   style={{
      //     marginRight: 1,
      //     flexDirection: 'row',
      //   }}
      // />
      <View style={{ height: "100%", flexDirection: "row", }}>
        <View style={{ height: "100%", backgroundColor: '#000', flexDirection: 'column', width: '15%', }}>
          {this.state.routes.map((route, index) => {
            // console.log(route);
            return (
              <TouchableOpacity style={{ height: 50, width: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor:  '#000' }} onPress={() => this.changeTab(route.title)}>
                <Icon
                  name={route.icon}
                  type={route.type}
                  color={
                    route.title == 'Cliks' && this.state.focus == "Cliks" ? Colors.blueColor :
                      route.title == 'Topics' && this.state.focus == "Topics" ? Colors.greenColor :
                        route.title == 'Users' && this.state.focus == "Users" ? '#000' :
                          route.title == 'Feeds' && this.state.focus == "Feeds" ? Colors.orangeColor : "#D3D3D3"}
                />
                {/* <Text >{route.title}</Text> */}
              </TouchableOpacity>
            )

          })}

        </View>
        <View style={{ width: Dimensions.get("window").width <= 750  ? '85%' : 250, paddingRight: 15, paddingLeft: 15 }}>
          <View style={{
            width: "100%",
            height: 40,
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 8,
            borderColor: '#d3d3d3',
            borderWidth: 1,
            // paddingRight: "3%",
            marginVertical: 10,
            backgroundColor: '#fff'
          }}>
            <View style={{ width: "16%", marginRight: "auto" }}>
              <Icon name="search" size={18} type="font-awesome" />
            </View>
            <TextInput
              autoFocus={false}
              placeholder={this.state.index == 0 ? "Search Cliks" :
                this.state.index == 1 ? "Search Topics" :
                  this.state.index == 2 ? 'Search User' :
                    this.state.index == 3 && "Search Feeds"}
              placeholderTextColor='#706969'
              onChangeText={(query) => this.props.onchangetext(query)}
              value={this.state.searchedWord}
              style={{
                height: 40,
                width: "74%",
                paddingHorizontal: 10,
                paddingVertical: 10,
                outline: "none",
                position: "absolute",
                left: "13%",
                fontWeight: 'bold',
                fontFamily: ConstantFontFamily.defaultFont,
                color: Platform.OS == 'web' ? '#706969' : "#000"
              }}
            />
            {this.props.searchedFollowText ? (
              <TouchableOpacity
                onPress={() => this.props.onpress("")}
                style={{ marginLeft: "auto", width: "10%" }}
              >
                <Icon name="close" size={18} type="font-awesome" />
              </TouchableOpacity>
            ) : null}
          </View>
          {this.state.focus == "Cliks" ? <View
            // onLayout={(event) => {
            //   let { height } = event.nativeEvent.layout;
            //   this.setState({
            //     CliksListDrawerScreensHeigth: height,
            //   });
            // }}
            style={{
              // flex: 1,              
              // width: '75%',
              height: this.props.windowSize.height-120 ,
              // paddingRight: 15,
              // borderWidth:1
            }}
          >
            <ScrollView showsVerticalScrollIndicator={false}>

              {/* <Suspense fallback={<ShadowSkeletonStar />}> */}
              <TrendingCliks
                navigation={this.props.navigation}
                searchedFollowText={this.state.searchedFollowText}
              // jumpTo='first'

              />
              {/* </Suspense> */}
            </ScrollView>

            {/* <Button
              testID="newClik"
              onPress={() => {
                // if(this.props.NewHomeFeed.length>0){
                NavigationService.navigate("createclik");
                this.props.leftPanelModalFunc(false);
                // }

              }}
              onMouseEnter={() => this.setState({ clikBtnColor: '#000', clikTextColor: '#fff' })}
              onMouseLeave={() => this.setState({ clikBtnColor: Colors.blueColor, clikTextColor: "#fff" })}
              title="NEW CLIK"
              titleStyle={[ButtonStyle.titleStyle, { color: this.state.clikTextColor }]}
              buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.clikBtnColor, paddingHorizontal: 30, paddingVertical: 7, borderWidth: 0 }]}
              containerStyle={[ButtonStyle.containerStyle, { marginLeft: 0 }]}
            /> */}
            <BottomPart />

          </View> : this.state.focus == "Topics" ? <View
            // onLayout={(event) => {
            //   let { height } = event.nativeEvent.layout;
            //   this.setState({
            //     TopicsListDrawerScreensHeigth: height,
            //   });
            // }}
            style={{
              // flex: 1,
              // width: '75%',
              // height: '80%',
              height: this.props.windowSize.height-120 ,
              // paddingRight: 15
            }}
          >

            <ScrollView showsVerticalScrollIndicator={false}>
              {/* <Suspense fallback={<ShadowSkeletonStar />}> */}
              <TrendingTopics
                navigation={this.props.navigation}
                randomItemEvent={this.props.randomItemEvent}
                searchedFollowText={this.state.searchedFollowText}
              // jumpTo='second'
              />
              {/* </Suspense> */}
            </ScrollView>
            {/* {this.props.isAdmin == true && (
              <Button
                onPress={() => {
                  this.props.leftPanelModalFunc(false);
                  return NavigationService.navigate("createtopic");
                }}
                title="PROPOSE TOPIC"
                titleStyle={[ButtonStyle.titleStyle, { color: this.state.topicTextColor, }]}
                buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.topicBtnColor, paddingVertical: 7, borderWidth: 0 }]}
                containerStyle={ButtonStyle.containerStyle}
                onMouseEnter={() => this.setState({ topicBtnColor: '#000', topicTextColor: '#fff' })}
                onMouseLeave={() => this.setState({ topicBtnColor: Colors.blueColor, topicTextColor: "#fff" })}
              />
            )} */}

            {/* {this.props.isAdmin == false && (
              <Button
                onPress={() => NavigationService.navigate("topichierarchy")}
                title="TOPIC HIERARCHY"
                onMouseEnter={() => this.setState({ topicBtnColor: '#000', topicTextColor: '#fff' })}
                onMouseLeave={() => this.setState({ topicBtnColor: Colors.blueColor, topicTextColor: "#fff" })}
                titleStyle={[ButtonStyle.titleStyle, { color: this.state.topicTextColor, }]}
                buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.topicBtnColor, paddingVertical: 7, borderWidth: 0 }]}
                containerStyle={ButtonStyle.containerStyle}
              />
            )} */}
            <BottomPart />

          </View> : this.state.focus == "Users" ? <View
            // onLayout={(event) => {
            //   let { height } = event.nativeEvent.layout;
            //   this.setState({
            //     UserListDrawerScreensHeigth: height,
            //   });
            // }}
            style={{
              // flex: 1
              // width: '75%',
              // height: '80%',
              height: this.props.windowSize.height-120 ,
              // paddingRight: 15
            }}
          >


            <ScrollView showsVerticalScrollIndicator={false}>
              {/* <Suspense fallback={<ShadowSkeletonStar />}> */}
              <TrendingUsers
                navigation={this.props.navigation}
                searchedFollowText={this.state.searchedFollowText}
              // jumpTo='third'
              />
              {/* </Suspense> */}
            </ScrollView>
            {/* <Button
              onPress={() => {
                this.props.leftPanelModalFunc(false);
                return NavigationService.navigate("settings");
              }}
              title="INVITE FRIENDS"
              onMouseEnter={() => this.setState({ userBtnColor: '#000', userTextColor: '#fff' })}
              onMouseLeave={() => this.setState({ userBtnColor: Colors.blueColor, userTextColor: "#fff" })}
              titleStyle={[ButtonStyle.titleStyle, { color: this.state.userTextColor }]}
              buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.userBtnColor, paddingVertical: 7, borderWidth: 0 }]}
              containerStyle={ButtonStyle.containerStyle}
            /> */}
            <BottomPart />
          </View> : this.state.focus == "Feeds" ? <View
            // onLayout={(event) => {
            //   let { height } = event.nativeEvent.layout;
            //   this.setState({
            //     FeedListDrawerScreensHeigth: height,
            //   });
            // }}
            style={{
              // flex: 1
              // width: '75%',
              // height: '80%',
              height: this.props.windowSize.height-120 ,
              // paddingRight: 15
            }}
          >

            <ScrollView showsVerticalScrollIndicator={false}>
              {/* <Suspense fallback={<ShadowSkeletonStar />}> */}
              <TrendingExternalFeed
                navigation={this.props.navigation}
                searchedFollowText={this.state.searchedFollowText}
              // jumpTo='fourth'
              />
              {/* </Suspense> */}
            </ScrollView>
            {/* <Button
              onPress={() => {
                this.props.leftPanelModalFunc(false);
                return NavigationService.navigate("addfeed");
              }}
              color="#fff"
              title="ADD FEED"
              onMouseEnter={() => this.setState({ feedBtnColor: '#000', feedTextColor: '#fff' })}
              onMouseLeave={() => this.setState({ feedBtnColor: Colors.blueColor, feedTextColor: "#fff" })}
              titleStyle={[ButtonStyle.titleStyle, { color: this.state.feedTextColor }]}
              buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.feedBtnColor, paddingVertical: 7, borderWidth: 0 }]}
              containerStyle={ButtonStyle.containerStyle}
            /> */}
            <BottomPart />
          </View> : null}
        </View>
      </View >
    );
  }
}

const mapStateToProps = (state) => ({
  getUsernameModalStatus: state.UsernameModalReducer.get("modalStatus"),
  windowSize: state.windowResizeReducer.windowResize

});

const mapDispatchToProps = (dispatch) => ({
  setSearchBarStatus: (payload) =>
    dispatch({ type: "SEARCH_BAR_STATUS", payload }),
});

//export default TrendingTabs;
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TrendingTabs
);

const styles = StyleSheet.create({
  container: {
    fontSize: 44,
    height: 40,
    backgroundColor: "#000",
  },
});
