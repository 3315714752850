import { PARENT_CONTENTID } from '../constants/Action';


const initialState = {
    id: ''
}

const PostCommentIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case PARENT_CONTENTID: {
            // console.log(action,'actionn');
            return {
                id: action.payload,
            };
        }
        default:
            return state;
    }
};

export default PostCommentIdReducer;