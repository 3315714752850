import { combineReducers } from "redux";
import CliksFeedReducer from "./CliksFeedReducer";
import CreateAccountReducer from "./CreateAccountReducer";
import CurrentDeviceWidthReducer from "./CurrentDeviceWidthReducer";
import HasScrolledReducer from "./HasScrolledReducer";
import HomeFeedReducer from "./HomeFeedReducer";
import LinkPostReducer from "./LinkPostReducer";
import LoginModalReducer from "./LoginModalReducer";
import LoginUserDetailsReducer from "./LoginUserDetailsReducer";
import PostDetailsReducer from "./PostDetailsReducer";
import ResetPasswordModalReducer from "./ResetPasswordModalReducer";
import ShareLinkModalReducer from "./ShareLinkModalReducer";
import SignUpModalReducer from "./SignUpModalReducer";
import TrendingCliksProfileReducer from "./TrendingCliksProfileReducer";
import TrendingCliksReducer from "./TrendingCliksReducer";
import TrendingUsersReducer from "./TrendingUsersReducer";
import UserApproachReducer from "./UserApproachReducer";
import UserFeedReducer from "./UserFeedReducer";
import UsernameModalReducer from "./UsernameModalReducer";
import UserProfileDetailsReducer from "./UserProfileDetailsReducer";
import UserReducer from "./UserReducer";
import VerifyEmailModalReducer from "./VerifyEmailModalReducer";
import FeedReportModalReducer from "./FeedReportModalReducer";
import TrendingTopicsReducer from "./TrendingTopicsReducer";
import TopicsFeedReducer from "./TopicsFeedReducer";
import TrendingTopicsProfileReducer from "./TrendingTopicsProfileReducer";
import ClikUserRequestReducer from "./ClikUserRequestReducer";
import ClikMembersReducer from "./ClikMembersReducer";
import PostCommentDetailsReducer from "./PostCommentDetailsReducer";
import FeedProfileReducer from "./FeedProfileReducer";
import InviteSignUpModalReducer from "./InviteSignUpModalReducer";
import ScreenLoadingReducer from "./ScreenLoadingReducer";
import TrendingExternalFeedsReducer from "./TrendingExternalFeedsReducer";
import AdminReducer from "./AdminReducer";
import EditTopicReducer from "./EditTopicReducer";
import EditFeedReducer from "./EditFeedReducer";
import EditClikReducer from "./EditClikReducer";
import CompletedReducer from './CompletedReducer';
import FocusReducer from './FocusReducer';
import TermArrayReducer from './AddtermReducer';
import TermArrayConcat from './TermConcatReducer';
import FixTabReducer from './FixTabReducer';
import IndexTabReducer from './IndexTab';
import ClikReducer from './ClikcPostReducer';
import NopostReducer from './NopostReducer';
import FixIndexReducer from './SetIndexReducer';
import PostShareReducer from './PostShareReducer'
import get404Reducer from './Ge404Reducer';
import SubscriptionAlertReducer from './SubscriptionAlertReducer';
import TotalCommentReducer from './totalCommentReducer';
import NestedCommentReducer from './nestedCommentReducer';
import commentStatusReducer from './commentStatusReducer';
import commentIdReducer from './commentIdreducer';
import openTookitReducer from './openToolkitReducer';
import UserHighlight from './userHighlightReducer';
import clikHoverReducer from './ClikHoverReducer';
import showDiscussionReducer from './showDiscussonReducer';
import windowResizeReducer from './windowResizeReducer';
import screenNameReducer from './screenNameReducer';
import isLoadingReducer from './isloadingReducer';
import showCommentBox from './showcommentReducer';
import getCommentTitleReducer from './getCommentReducer';
import RepliedIReducer from './getRepliedIdReducer';
import postCommentStatus from './postCommentStatus';
import PostCommentIdReducer from './PostCommentId'

export default function createReducer() {
  return combineReducers({
    UserReducer,
    LoginUserDetailsReducer,
    HomeFeedReducer,
    UserProfileDetailsReducer,
    TrendingUsersReducer,
    TrendingCliksReducer,
    UserFeedReducer,
    PostDetailsReducer,
    TrendingCliksProfileReducer,
    CliksFeedReducer,
    LinkPostReducer,
    LoginModalReducer,
    ResetPasswordModalReducer,
    SignUpModalReducer,
    UsernameModalReducer,
    VerifyEmailModalReducer,
    HasScrolledReducer,
    CurrentDeviceWidthReducer,
    ShareLinkModalReducer,
    CreateAccountReducer,
    UserApproachReducer,
    FeedReportModalReducer,
    TrendingTopicsReducer,
    TopicsFeedReducer,
    TrendingTopicsProfileReducer,
    ClikUserRequestReducer,
    ClikMembersReducer,
    PostCommentDetailsReducer,
    FeedProfileReducer,
    InviteSignUpModalReducer,
    ScreenLoadingReducer,
    TrendingExternalFeedsReducer,
    AdminReducer,
    EditTopicReducer,
    EditFeedReducer,
    EditClikReducer,
    CompletedReducer,
    FocusReducer,
    TermArrayReducer,
    TermArrayConcat,
    FixTabReducer,
    IndexTabReducer,
    ClikReducer,
    NopostReducer,
    FixIndexReducer,
    PostShareReducer,
    get404Reducer,
    SubscriptionAlertReducer,
    TotalCommentReducer,
    NestedCommentReducer,
    commentStatusReducer,
    commentIdReducer,
    openTookitReducer,
    UserHighlight,
    clikHoverReducer,
    showDiscussionReducer,
    windowResizeReducer,
    screenNameReducer,
    isLoadingReducer,
    showCommentBox,
    getCommentTitleReducer,
    RepliedIReducer,
    postCommentStatus,
    PostCommentIdReducer
  });
}
