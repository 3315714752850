import { POSTCOMMENT } from '../constants/Action';


const initialState = {
    postCommentStatus: true,
}

const postCommentStatus = (state = initialState, action) => {

    switch (action.type) {
        case POSTCOMMENT: {
            return {
                postCommentStatus: action.payload,
            };
        }
        default:
            return state;
    }
};

export default postCommentStatus;