import { CHECK_COMMENT__COUNT } from '../constants/Action';


const initialState = {
    status: false,
  }
  
  const commentStatusReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case CHECK_COMMENT__COUNT: {
        return {
          status: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default commentStatusReducer;