import { put, takeEvery } from 'redux-saga/effects';
import { CREATEACCOUNTACTION, CREATEACCOUNTACTION_FAILURE, CREATEACCOUNTACTION_SUCCESS } from "../constants/Action";



export function* CreateAccountMiddleware({ payload }) {
    try {
        yield put({
            type: CREATEACCOUNTACTION_SUCCESS,
            payload: payload
        });
    } catch (err) {
        yield put({
            type: CREATEACCOUNTACTION_FAILURE,
            payload: err.message
        });
        console.log(err);
    }
    if (payload.identifier == 'reset') {
        yield put({
            type: CREATEACCOUNTACTION_FAILURE,
            payload: []
        });

    }
}

export default function* CreateAccountMiddlewareWatcher() {
    yield takeEvery(CREATEACCOUNTACTION, CreateAccountMiddleware);
}