import React from "react";
import {
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    Image,
    Platform
} from "react-native";
import { Icon, Button } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import ButtonStyle from "../constants/ButtonStyle";
import ConstantFontFamily from "../constants/FontFamily";
import NavigationService from "../library/NavigationService";




const SubscriptionAlert = (props) => {
    return (
        <View
            style={{
                width: "70%",
                height: Dimensions.get('window').width >= 750 ? 220 : 500,
                backgroundColor: "#fff",
                justifyContent: "space-between",
                borderRadius: 6
            }}
        >
            <Hoverable>
                {isHovered => (
                    <TouchableOpacity
                        style={{
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            flex: 1,
                            position: "absolute",
                            zIndex: 999999,
                            left: 0,
                            top: 0
                        }}
                        onPress={props.onClose}
                    >
                        <Icon
                            color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                            iconStyle={{
                                color: "#fff",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            reverse
                            name="close"
                            type="antdesign"
                            size={16}
                        />
                    </TouchableOpacity>
                )}
            </Hoverable>
            <View
                style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    backgroundColor: "#000",
                    alignItems: "center",
                    height: 50,
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                }}
            >
                <Image
                    source={require("../assets/logo 102_2.jpeg")}
                    style={{
                        justifyContent: "flex-start",
                        height: 35,
                        width: 35,
                        marginRight: 5,
                        borderRadius: 8
                    }}
                    resizeMode={"contain"}
                />
                <Text
                    style={[ButtonStyle.profileTitleStyle, {
                        fontSize: 20,
                        textAlign: "center",
                        color: "white",
                        fontWeight: 'bold'
                    }]}
                >
                    weclikd
                </Text>
            </View>
            <View
                style={{

                    width: "100%",
                    textAlign: "center",
                    justifyContent: "space-between",


                }}
            >

                <Text style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont,

                }}>This features requires a Gold subscription</Text>

                <View style={{ flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                    <Button
                        title="Upgrade"
                        titleStyle={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#fff",
                            fontFamily: ConstantFontFamily.defaultFont
                        }}
                        buttonStyle={{
                            backgroundColor: "#000",
                            borderRadius: 5,
                            paddingVertical: 7,
                            paddingHorizontal: 25
                        }}
                        containerStyle={[ButtonStyle.containerStyle, { marginTop: 40, marginBottom: 50 }]}
                        onPress={() => {
                            NavigationService.navigate("settings")
                            props.onClose()
                        }}
                    />
                    <Button
                        title="Cancel"
                        titleStyle={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#000",
                            fontFamily: ConstantFontFamily.defaultFont
                        }}
                        buttonStyle={{
                            backgroundColor: "#fff",
                            borderRadius: 5,
                            paddingVertical: 7,
                            paddingHorizontal: 25,
                            borderWidth: 1,
                            borderColor: "#000"
                        }}
                        containerStyle={[ButtonStyle.containerStyle, { marginTop: 40, marginBottom: 50 }]}

                        onPress={() => props.onClose()}
                    />
                </View>



            </View>


        </View>
    )
}

export default SubscriptionAlert;
