import { put, takeEvery } from "redux-saga/effects";
import {
  EDITCLIK,
  EDITCLIK_FAILURE,
  EDITCLIK_SUCCESS
} from "../constants/Action";

export function* EditClikMiddleware({ payload }) {
  try {
    yield put({
      type: EDITCLIK_SUCCESS,
      payload: payload
    });
  } catch (err) {
    yield put({
      type: EDITCLIK_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* EditClikMiddlewareWatcher() {
  yield takeEvery(EDITCLIK, EditClikMiddleware);
}
