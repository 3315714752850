import { takeEvery, put } from 'redux-saga/effects';
import { RESETPASSWORDMODALACTION, RESETPASSWORDMODALACTION_SUCCESS, RESETPASSWORDMODALACTION_FAILURE } from "../constants/Action";

export function* ResetPasswordModalMiddleware({ payload }) {
    if (payload == true) {
        yield put({
            type: RESETPASSWORDMODALACTION_SUCCESS,
            payload: payload
        });
    } else {
        yield put({
            type: RESETPASSWORDMODALACTION_FAILURE,
            payload: payload
        });
    }
}

export default function* ResetPasswordModalMiddlewareWatcher() {
    yield takeEvery(RESETPASSWORDMODALACTION, ResetPasswordModalMiddleware);
}