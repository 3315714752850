import { fromJS } from 'immutable';
import { CLIKUSERREQUEST_SUCCESS, CLIKUSERREQUEST_FAILURE } from "../constants/Action";



const initialState = fromJS({
    ClikUserRequestList: null,
    ClikUserRequestListPagination: null,
});

export default function ClikUserRequestReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CLIKUSERREQUEST_SUCCESS:
            return state
                .set('ClikUserRequestList', payload.data.node.applications.edges)
                .set('ClikUserRequestListPagination', payload.data.node.applications.pageInfo);
        case CLIKUSERREQUEST_FAILURE:
            return initialState;
    }
    return state;
}