import React, { lazy, createRef, Suspense } from "react";
import { retry } from "../library/Helper";
import {
  Animated,
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  YellowBox,
  Image
} from "react-native";
import { Icon } from "react-native-elements";
import {
  Menu,
  MenuTrigger
} from "react-native-popup-menu";
import { TabBar, TabView } from "react-native-tab-view";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import ShadowSkeleton from "../components/ShadowSkeleton";
import ConstantFontFamily from "../constants/FontFamily";
import ButtonStyle from "../constants/ButtonStyle";
import NavigationService from "../library/NavigationService";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import { fixIndex, feedFluctuation, userHighlight, showDiscussion, screen, showbox } from '../reducers/action/Completed';
import Colors from "../constants/Colors";
import Split from 'react-split'
import CreateCommentCard from "../components/CreateCommentCard"
// import "@expo/match-media";
import MediaQuery from 'react-responsive'




const DiscussionHomeFeed = lazy(() => import("../components/DiscussionHomeFeed"))

const NewHomeFeed = lazy(() => import("../components/NewHomeFeed"))

const HeaderRight = lazy(() => import("../components/HeaderRight"))

const SEOMetaData = lazy(() => import("../components/SEOMetaData"))

const TrendingHomeFeed = lazy(() => import("../components/TrendingHomeFeed"))

const CommentDetailScreen = lazy(() => import("./CommentDetailScreen"))

const SearchInputWeb = lazy(() => import("../components/SearchInputWeb"))

const BottomScreen = lazy(() => import("../components/BottomScreen"))


const initRoutes = [
  {
    key: "first",
    title: "New",
    icon: "fire",
    type: "simple-line-icon"
  },
  { key: "second", title: "Trending", icon: "clock-o", type: "font-awesome" },
  {
    key: "third",
    title: "Bookmarks",
    icon: "bookmark",
    type: "font-awesome"
  }
];

let lastTap = null;
class DashboardScreen extends React.PureComponent {
  state = {
    commentDelay: false,
    routes: [...initRoutes],
    index: this.props.index1 && this.props.index1,
    ViewMode: "Default",
    prevFeedList: {
      trending: {},
      discussion: {},
      new: {}
    },
    feedY: 0,
    UnreadNotifications: 0,
    showSearchIcon: true,
    tabPost: false,
    indexStatus: false
  };

  constructor(props) {
    super(props);
    this.Pagescrollview = null;
    YellowBox.ignoreWarnings(["VirtualizedLists should never be nested"]);
    this.flatListRefNew = createRef();
    this.flatListRefDiscussion = createRef();
    this.flatListRefTrending = createRef();
    this.nameInput = createRef()
    this.commentRef = createRef()
  }
  setPrevList = (res, name) => {
    this.setState(prevState => ({
      prevFeedList: { ...prevState.prevFeedList, [name]: res }
    }));
  };



  componentDidMount = async () => {
    this.props.searchOpenBarStatus(false)
    this.props.leftPanelModalFunc(false);
    this.props.showDiscussion(false)
    this.props.showbox(false)
    this.props.setUserr('')
    this.props.setTermWeb('');
    this.props.setTerm('');
    let __self = this;
    if (sessionStorage.getItem('counter') == 1) {
      if (this.props.index1) {
        this.props.fixIndex(this.props.index1)
      }
    } else if (sessionStorage.getItem('counter') >= 2) {
      if (this.props.index1) {
        this.props.fixIndex(this.props.index1)
      }
    } else {
      if (window.location.href == 'http://localhost:19006/' || window.location.href == 'http://electric-block-241402.appspot.com/' || window.location.href == 'https://electric-block-241402.appspot.com/' ||
        window.location.href == "https://weclikd-beta.com/" ||
        window.location.href == "http://weclikd-beta.com/") {
        this.props.setTermWeb('');
        this.props.setTerm('');
        this.props.fixIndex(0)
      } else {
        this.props.fixIndex(this.props.index1)
      }

    }

    this.props.screen("home")
  };



  componentDidUpdate() {
    this.props.setSearchBarStatus(false);
    if (this.props.getHasScrollTop == true && this.Pagescrollview) {
      this.Pagescrollview.scrollToOffset({ animated: true, offset: 0 });
      this.props.setHASSCROLLEDACTION(false);
    }

    // console.log('');
    // if (this.props.screenName != "home") {
    //   this.props.screen("home")
    // }


  }

  goToScrollTop = () => {
    this.props.setHASSCROLLEDACTION(true);
  };

  doScroll = (value, name) => {
    if (name == "new") {
      this.flatListRefNew = value;
    } else if (name == "trending") {
      this.flatListRefTrending = value;
    } else if (name == "discussion") {
      this.flatListRefDiscussion = value;
    }
  };

  scrollFunc = () => {
    {
      this.flatListRefNew.current &&
        this.flatListRefNew.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true
        }),
        this.flatListRefTrending.current &&
        this.flatListRefTrending.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true
        }),
        this.flatListRefDiscussion.current &&
        this.flatListRefDiscussion.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true
        });
    }
  };

  handleDoubleTap = () => {
    if (lastTap !== null) {
      this.scrollFunc();
      clearTimeout(lastTap);
      lastTap = null;
    } else {
      lastTap = setTimeout(() => {
        clearTimeout(lastTap);
        lastTap = null;
      }, 1000);
    }
    this.setState({ tabPost: true })

    this.props.feedFluctuation(true)
  };

  _renderLazyPlaceholder = ({ route }) => <ShadowSkeleton />;

  _handleIndexChange = index => {
    this.props.fixIndex(index)
    this.setState({ index });
    if (index == 1) {
      this.setState({ indexStatus: true })
    } else if (index == 0 && index == 2) {
      this.setState({ indexStatus: false })

    } else {
      this.setState({ indexStatus: false })

    }
    this.onLoadingComment()
    // console.log(Array.from(this.props.trendingList), 'log')
    // if (index == 0 && this.props.trendingList && Array.from(this.props.trendingList).length > 0) {
    //   this.props.feedFluctuation(true)
    //   this.props.setUpdatedPostId(Array.from(this.props.trendingList)[0].node.id)
    //   this.props.setPostCommentReset({
    //     payload: [],
    //     postId: '',
    //     title: '',
    //     loading: true
    //   });
    //   this.props.setPostCommentDetails({
    //     id: "Post:" + Array.from(this.props.trendingList)[0].node.id.replace("Post:", ""),
    //     title: Array.from(this.props.trendingList)[0].node.title,
    //     loading: false
    //   });





    // } else if (index == 1 && this.props.newfeedList && Array.from(this.props.newfeedList).length > 0) {

    //   this.props.feedFluctuation(true)
    //   this.props.setUpdatedPostId(Array.from(this.props.newfeedList)[0].node.id)
    //   this.props.setPostCommentReset({
    //     payload: [],
    //     postId: '',
    //     title: '',
    //     loading: true
    //   });

    //   this.props.setPostCommentDetails({
    //     id: "Post:" + Array.from(this.props.newfeedList)[0].node.id.replace("Post:", ""),
    //     title: Array.from(this.props.newfeedList)[0].node.title,
    //     loading: false
    //   });




    // } else if (index == 2 && this.props.discussionList && Array.from(this.props.discussionList).length > 0) {
    //   this.props.feedFluctuation(true)
    //   this.props.setUpdatedPostId(Array.from(this.props.discussionList)[0].node.id)
    //   this.props.setPostCommentReset({
    //     payload: [],
    //     postId: '',
    //     title: '',
    //     loading: true
    //   });

    //   this.props.setPostCommentDetails({
    //     id: "Post:" + Array.from(this.props.discussionList)[0].node.id.replace("Post:", ""),
    //     title: Array.from(this.props.discussionList)[0].node.title,
    //     loading: false
    //   });


    // }

  };

  _renderTabBar = props =>
    this.props.windowSize.width >= 750 && this.props.isfeed == true ? (

      <View
        style={[
          ButtonStyle.TabbarBorderStyleForDashboard,
          {
            flexDirection: "row",
            height: 55,
            backgroundColor:
              this.props.windowSize.width <= 750 ? "#000" : "#fff",
            alignItems: "center",
            paddingHorizontal: 10,
            paddingVertical: 10,
            marginLeft: 0,
            width: '100%',
            borderBottomWidth: 0
            // marginBottom: 7
            // marginTop: Platform.OS == 'web' ? 15 : 0,
          }
        ]}
      >

        <TabBar
          onTabPress={() => { this.handleDoubleTap(), this.setState({ activeColor: '#009B1A' }) }}

          {...props}
          indicatorStyle={{
            backgroundColor: "transparent",
            height: 2,
            borderRadius: 6
          }}
          style={{
            backgroundColor: 'transparent',
            width: "100%",
            height: 55,
            shadowColor: "transparent",
            justifyContent: 'center',

          }}

          labelStyle={[ButtonStyle.profileTitleStyle, {
            color: "#000",
            fontSize: 13,
          }]}
          // renderIcon={({ route, focused, color, isActive }) =>

          //   this.props.windowSize.width >= 750 && (
          //     <Icon
          //       name={route.icon}
          //       type={route.type}
          //       color={focused ? Colors.blueColor : "#D3D3D3"}
          //     />
          //   )
          // }
          renderLabel={({ route, focused, color, isActive }) => (
            <Text
              style={[ButtonStyle.tabbarTitleStyle, {
                color: focused ?  Colors.blueColor : isActive ? Colors.blueColor : "#D3D3D3",
                flexWrap: "wrap",
                flex: 1,
                width: "100%",
              }]}
            >
              {route.title}
            </Text>
          )}
          renderIndicsator={({ route, focused, color }) => null}
        />

      </View>
    ) : null;

  changeTabStatus = () => {
    this.setState({ tabPost: false })
  }

  _renderScene = ({ route }) => {
    switch (route.key) {
      case "third":
        return this.props.loginStatus == 1 ? (
          <Suspense fallback={<ShadowSkeleton />}>
            <DiscussionHomeFeed
              navigation={this.props.navigation}
              listType={"Home"}
              ViewMode={this.state.ViewMode}
              tabPost={this.state.tabPost}
              setState={this.setState}
              onLoadingComment={this.onLoadingComment}
              changeTabStatus={this.changeTabStatus}
              setPrevList={this.setPrevList}
              prevFeedList={this.state.prevFeedList}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
              index={this.state.index ? this.state.index : 2}
              indexStatus={this.state.indexStatus}
            />
          </Suspense>
        ) : (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Icon
              color={"#000"}
              iconStyle={{
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
              }}
              reverse
              name="sticky-note"
              type="font-awesome"
              size={20}
              containerStyle={{
                alignSelf: "center"
              }}
            />
            <Text
              style={{
                fontSize: 12,
                fontFamily: ConstantFontFamily.defaultFont,
                color: "#000",
                alignSelf: "center"
              }}
            >
              <Text
                onPress={() => this.loginHandle()}
                style={{
                  textDecorationLine: "underline",
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Login
              </Text>{" "}
              to see bookmarked posts
            </Text>
          </View>
        );
      case "second":
        return (
          <Suspense fallback={<ShadowSkeleton />}>
            <TrendingHomeFeed
              navigation={this.props.navigation}
              listType={"Home"}
              ViewMode={this.state.ViewMode}
              onLoadingComment={this.onLoadingComment}
              tabPost={this.state.tabPost}
              setState={this.setState}
              changeTabStatus={this.changeTabStatus}
              setPrevList={this.setPrevList}
              prevFeedList={this.state.prevFeedList}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
              index={this.state.index ? this.state.index : 0}
              indexStatus={this.state.indexStatus}

            />
          </Suspense>
        );
      case "first":
        return (
          <Suspense fallback={<ShadowSkeleton />}>
            <NewHomeFeed
              navigation={this.props.navigation}
              listType={"Home"}
              ViewMode={this.state.ViewMode}
              onLoadingComment={this.onLoadingComment}
              tabPost={this.state.tabPost}
              setState={this.setState}
              changeTabStatus={this.changeTabStatus}
              setPrevList={this.setPrevList}
              prevFeedList={this.state.prevFeedList}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
              index={this.state.index ? this.state.index : 1}
              indexStatus={this.state.indexStatus}

            />

          </Suspense>
        );
      case "zero":
        return (
          <Suspense fallback={<ShadowSkeleton />}>
            <NewHomeFeed
              navigation={this.props.navigation}
              listType={"Home"}
              ViewMode={this.state.ViewMode}
              onLoadingComment={this.onLoadingComment}
              tabPost={this.state.tabPost}
              setState={this.setState}
              changeTabStatus={this.changeTabStatus}
              setPrevList={this.setPrevList}
              prevFeedList={this.state.prevFeedList}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
              index={this.state.index ? this.state.index : 1}
              indexStatus={this.state.indexStatus}

            />
          </Suspense>
        );
    }
  };

  renderTabViewForMobile = () => {
    if (this.props.getTabView == "Bookmarks") {
      return (
        this.props.loginStatus == 1 ? (
          <View style={{ flex: 1 }}>
            <Suspense fallback={null}>
              <DiscussionHomeFeed
                navigation={this.props.navigation}
                listType={"Home"}
                ViewMode={this.state.ViewMode}
                setPrevList={this.setPrevList}
                prevFeedList={this.state.prevFeedList}
                ActiveTab={this.state.routes[this.state.index].title}
                doScroll={this.doScroll}
              />
            </Suspense>
          </View>
        ) : (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Icon
              color={"#000"}
              iconStyle={{
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
              }}
              reverse
              name="sticky-note"
              type="font-awesome"
              size={20}
              containerStyle={{
                alignSelf: "center"
              }}
            />
            <Text
              style={{
                fontSize: 12,
                fontFamily: ConstantFontFamily.defaultFont,
                color: "#000",
                alignSelf: "center"
              }}
            >
              <Text
                onPress={() => this.loginHandle()}
                style={{
                  textDecorationLine: "underline",
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Login
              </Text>{" "}
              to see bookmarked posts
            </Text>
          </View>
        ))
    }

    else if (this.props.getTabView == "New") {
      return (
        <View style={{ flex: 1 }}>
          <Suspense fallback={null}>
            < NewHomeFeed
              navigation={this.props.navigation}
              listType={"Home"}
              ViewMode={this.state.ViewMode}
              setPrevList={this.setPrevList}
              prevFeedList={this.state.prevFeedList}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
            />
          </Suspense>
        </View>)

    }
    else if (this.props.getTabView == "Trending") {
      return (
        <View style={{ flex: 1 }}>
          <Suspense fallback={null}>
            <TrendingHomeFeed
              navigation={this.props.navigation}
              listType={"Home"}
              ViewMode={this.state.ViewMode}
              setPrevList={this.setPrevList}
              prevFeedList={this.state.prevFeedList}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
            />
          </Suspense>
        </View>
      )
    }
    else {
      return (
        <View style={{ flex: 1 }}>
          <Suspense fallback={null}>
            <TrendingHomeFeed
              navigation={this.props.navigation}
              listType={"Home"}
              ViewMode={this.state.ViewMode}
              setPrevList={this.setPrevList}
              prevFeedList={this.state.prevFeedList}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
            />
          </Suspense>
        </View>
      )
    }
  }


  loginHandle = () => {
    this.props.setLoginModalStatus(true);
  };

  listScroll = value => {
    this.setState({
      feedY: value
    });
  };


  onLoadingComment = () => {
    this.setState({ commentDelay: true })
    // console.log(this.props?.PostCommentDetails,'comments');
    // if (this.props?.PostCommentDetails?.length>0) {
    // console.log('is coming');
    setTimeout(() => {

      this.setState({ commentDelay: false })
    }, 1500)
    // }
  }


  render() {
    return (
      <View style={styles.container}>

        {Platform.OS == "web" && (
          <Suspense fallback={null}>

            <SEOMetaData title={"Weclikd"} description={""} image={""} />
          </Suspense>
        )}
        {/* {
          this.props.windowSize.width <= 750 && (


            <Animated.View
              style={{
                position: Platform.OS == "web" ? "sticky" : null,
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                overflow: "hidden",
                borderRadius: 0,
              }}
            >
              <View
                style={{
                  height: this.props.windowSize.width <= 750 ? 50 : 42
                }}
              >
                {this.props.loginStatus == 1 ?
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#000'
                  }}>

                    <TouchableOpacity
                      onPress={this.goToScrollTop}
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        backgroundColor: this.props.windowSize.width <= 750 ? "f4f4f4" : "#000",
                        height: 50,
                        marginLeft: 5,
                        alignItems: 'center'
                      }}
                    >
                      <Image
                        source={require("../assets/image/logolastOne.png")}
                        style={{
                          marginRight: 5,
                          alignSelf: "center",
                          height: 35,
                          width: 35,
                          borderRadius: 8
                        }}
                        resizeMode={"contain"}
                      />
                      {!this.props.getsearchBarStatus &&
                        <Text
                          style={[ButtonStyle.profileTitleStyle, {
                            fontSize: 20,
                            textAlign: "center",
                            color: "white",
                            fontWeight: 'bold'
                          }]}
                        >
                          weclikd
                        </Text>}
                    </TouchableOpacity>

                    {this.props.getsearchBarStatus ?
                      <View style={{ width: '80%', marginHorizontal: 20 }}>
                        <Suspense fallback={null}>

                          <SearchInputWeb
                            navigation={this.props.navigation}
                            refs={ref => {
                              this.input = ref;
                            }}
                            displayType={"web"}
                            press={status => {
                              this.setState({ showSearchIcon: status });
                            }}
                            nameInput={this.nameInput}
                          />
                        </Suspense>

                      </View>
                      :
                      <Suspense fallback={null}>

                        <HeaderRight navigation={this.props.navigation} />
                      </Suspense>}
                  </View>
                  :
                  <View style={{ flexDirection: 'row', backgroundColor: '#000', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 10 }}>

                    <TouchableOpacity
                      onPress={this.goToScrollTop}
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        backgroundColor: this.props.windowSize.width <= 750 ? "f4f4f4" : "#000",
                        height: 50,
                        marginLeft: 5,
                        alignItems: 'center'
                      }}
                    >
                      <Image
                        source={require("../assets/image/logolastOne.png")}
                        style={{
                          marginRight: 5,
                          alignSelf: "center",
                          height: 35,
                          width: 35,
                          borderRadius: 8
                        }}
                        resizeMode={"contain"}
                      />
                      {!this.props.getsearchBarStatus &&
                        <Text
                          style={[ButtonStyle.profileTitleStyle, {
                            fontSize: 20,
                            textAlign: "center",
                            color: "white",
                            fontWeight: 'bold'
                          }]}
                        >
                          weclikd
                        </Text>
                      }
                    </TouchableOpacity>
                    {!this.props.getsearchBarStatus ?
                      <Icon
                        name="search"
                        size={25}
                        type="feather"
                        // iconStyle={styles.actionButtonIcon}
                        iconStyle={{
                          ...styles.actionButtonIcon,
                          color: this.props.windowSize.width <= 750 ? "#fff" : "#fff",
                          marginRight: this.props.windowSize.width <= 750 ? 20 : 30,
                        }}
                        color="#fff"
                        underlayColor="#000"
                        onPress={() => {
                          this.setState({ showSearchIcon: false });
                          this.props.setSearchBarStatus(true);
                          this.props.searchOpenBarStatus(true);
                        }}
                      />
                      :
                      <View style={{ width: '85%', backgroundColor: '#000' }}>
                        <Suspense fallback={null}>

                          <SearchInputWeb
                            navigation={this.props.navigation}
                            refs={ref => {
                              this.input = ref;
                            }}
                            displayType={"web"}
                            press={status => {
                              this.setState({ showSearchIcon: status });
                            }}
                          />
                        </Suspense>

                      </View>
                    }

                    {
                      this.props.windowSize.width <= 1200 &&
                      !this.props.getsearchBarStatus && (
                        <View>
                          <Menu>
                            <MenuTrigger testID="ProfileIcon" onPress={() => { this.props.leftPanelModalFunc(true) }}>
                              <Image
                                source={require("../assets/image/default-image.png")}
                                style={{
                                  height: 27,
                                  width: 27,
                                  borderRadius: 13,
                                  borderWidth: 1,
                                  alignSelf: 'center',
                                  borderColor: this.props.windowSize.width <= 750 ? "#fff" : "#fff",
                                }}
                                navigation={this.props.navigation}
                              />
                            </MenuTrigger>
                          </Menu>
                        </View>

                      )}

                  </View>
                }
              </View>
            </Animated.View>

          )} */}

        <View
          style={{
            // flex: 1,
            flexDirection: "row",
            width: this.props.windowSize-310,
            // justifyContent: "center",
            // marginLeft: this.props.windowSize.width < 1200 && this.props.windowSize.width >= 750 ? 0 : 2,
            height: '100%',
            backgroundColor: Colors.leftPanelColor,
          }}

        >

          {/* <Split style={{
            width: "100%", display: "flex", backgroundColor: Colors.leftPanelColor, flexDirection: "row", padding: 0, margin: 0, height: "100%", zIndex: 999,
          }}
            gutterSize={1}
            // sizes={[20,20]}

            minSize={[this.props.windowSize.width / 2,]}
            // gutterStyle={{

            // }}
            // sizes={[100, 200]}
            cursor="col-resize"
          // expandToMin={true}
          // snapOffset={100}
          > */}

          {
            this.props.windowSize.width <= 750 ?

              <View
                style={{
                  flex: 1,
                  width: "100%",
                  height: '100%',
                }}

              >
                {this.renderTabViewForMobile()}
              </View>

              :


              this.props.show == false &&
              <View
                style={[ButtonStyle.DashboardTabViewStyle, {
                  width: '50%',
                  // this.props.windowSize.width / 2,
                  // flex: 1,                    
                  backgroundColor: Colors.leftPanelColor,
                  
                  // borderWidth: 3,
                  // borderColor: "#000",
                  // borderBottomWidth:0
                }]}
              >

                <TabView
                  swipeEnabled={false}
                  lazy={true}
                  navigationState={this.state}
                  renderScene={this._renderScene}
                  renderLazyPlaceholder={this._renderLazyPlaceholder}
                  renderTabBar={this._renderTabBar}
                  onIndexChange={this._handleIndexChange}
                  timingConfig={{ duration: 0 }}
                  removeClippedSubviews={true}
                  style={{
                    width: '100%'
                    // Platform.OS == "web" && this.props.windowSize.width >= 750 && this.props.windowSize.width <= 1200
                    //   ? '100%' : Platform.OS == "web" && this.props.windowSize.width >= 1200 ? '100%'
                    //     : "100%",
                  }}
                />

              </View>
          }




          {this.props.show == true || this.props.windowSize.width >= 750 ?

            <View
              style={[

                {
                  width: '50%',
                  // this.props.windowSize.width / 2,
                  // Platform.OS == "web" &&
                  //   this.props.windowSize.width >= 750 && this.props.windowSize.width <= 1200
                  //   ? '100%' : Platform.OS == "web" && this.props.windowSize.width >= 1200 ? '49%'
                  //     : "100%",
                  marginBottom: 5,
                  // marginLeft: 2,
                  // marginRight: this.props.windowSize.width >= 750 && this.props.windowSize.width <= 1200 ? 5 : 0,
                  // display:
                  //   this.props.windowSize.width >= 750 && Platform.OS == "web"
                  //     ? "flex"
                  //     : "none",
                  // marginLeft: 5,
                  height: Dimensions.get('window').height - 45,
                  backgroundColor: Colors.lightGray


                }]}
            >
              <View style={{
                height: 55,
                alignItems: "center",
                paddingHorizontal: 10,
                paddingVertical: 10,
                marginLeft: 0,
                width: '100%',
                borderBottomWidth: 0,
                borderColor: '#d3d3d3',
                justifyContent: this.props.show ? 'flex-start' : 'center',
                alignItems: 'center',
                flexDirection: 'row',
                backgroundColor: '#fff',
                marginBottom: 10
                // borderTopWidth: 2,
              }}>
                {this.props.show == true ?
                  <TouchableOpacity style={[ButtonStyle.headerBackStyle, { width: '20%' }]}
                    onPress={() => this.props.showDiscussion(false)}>
                    <Icon
                      color={"#000"}
                      name="angle-left"
                      type="font-awesome"
                      size={40}

                    />
                  </TouchableOpacity> : null}
                < Text style={[ButtonStyle.tabbarTitleStyle, { width: this.props.show ? '55%' : '100%', textAlign: 'center', }]}> Discussion</Text>
              </View>

              <View style={{ height: Dimensions.get('window').height - 120, }}>
                <Suspense fallback={null}>

                  <CommentDetailScreen
                    type="dashboard"
                    navigation={this.props.navigation}
                    postId={this.props.PostDetails && this.props.PostDetails?.node?.id}
                    listScroll={this.listScroll}
                    commentDelay={this.state.commentDelay}
                    showScrollIntoView={this.showScrollIntoView}
                    ActiveTab={this.state.routes[this.state.index].title}
                  />
                </Suspense>
                {/* {(this.props.show == true || this.props.windowSize.width >= 1200) && this.props.isLaoding == false ?
                  <View style={{
                    width: Platform.OS == "web" &&
                      this.props.windowSize.width >= 750 && this.props.windowSize.width <= 1200
                      ? '100%' : Platform.OS == "web" && this.props.windowSize.width >= 1200 ? '100%'
                        : "100%", zIndex: 0, position: 'absolute', bottom: 10,
                  }}>
                    <CreateCommentCard
                      onClose={this.onClose}
                      parent_content_id={
                        this.props.PostId &&
                        this.props.PostId.replace("Trending", "")
                          .replace("New", "")
                          .replace("Discussion", "")
                          .replace("Search", "")
                      }
                      clickList={
                        this.props.PostDetails
                          ? this.props.PostDetails
                          : null
                      }
                      initial="main"
                      topComment={this.props.PostDetails && this.props.PostDetails.node}
                      navigation={this.props.navigation}
                      title={this.props.Title}
                      type={this.props.type}
                      commentRef={this.commentRef}
                    />
                  </View> : null
                } */}
              </View>
            </View> : null
          }

          {/* </Split> */}

        </View >

        {
          this.props.windowSize.width <= 750 &&
          <Suspense fallback={null}>

            <BottomScreen navigation={NavigationService} />
          </Suspense>
        }
      </View >
    );
  }
}

const mapStateToProps = state => ({
  trendingList: state.HomeFeedReducer.get("TrendingHomeFeedList"),
  newfeedList: state.HomeFeedReducer.get("NewHomeFeedList"),
  discussionList: state.HomeFeedReducer.get("DiscussionHomeFeedList"),

  getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  loginStatus: state.UserReducer.get("loginStatus"),
  isAdmin: state.AdminReducer.get("isAdmin"),
  isAdminView: state.AdminReducer.get("isAdminView"),


  PostDetails: state.PostDetailsReducer.get("PostDetails"),
  PostCommentDetails: state.PostCommentDetailsReducer.get("PostCommentDetails"),
  getsearchBarStatus: state.AdminReducer.get("searchBarOpenStatus"),
  getTabView: state.AdminReducer.get("tabType"),
  index1: state.FixIndexReducer.index1,
  isfeed: state.AdminReducer.get("isfeed"),
  PostId: state.PostCommentDetailsReducer.get("PostId"),
  PostDetails: state.PostDetailsReducer.get("PostDetails"),
  show: state.showDiscussionReducer.show,
  windowSize: state.windowResizeReducer.windowResize,
  screenName: state.screenNameReducer.screen,
  isLaoding: state.isLoadingReducer.isloading
});

const mapDispatchToProps = dispatch => ({


  setHASSCROLLEDACTION: payload => dispatch(setHASSCROLLEDACTION(payload)),
  setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  setSearchBarStatus: payload =>
    dispatch({ type: "SEARCH_BAR_STATUS", payload }),
  setPostCommentReset: payload =>
    dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  leftPanelModalFunc: payload => dispatch({ type: 'LEFT_PANEL_OPEN', payload }),
  searchOpenBarStatus: payload => dispatch({ type: "SEARCHBAR_STATUS", payload }),
  setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),
  feedFluctuation: payload => dispatch(feedFluctuation(payload)),
  fixIndex: payload => dispatch(fixIndex(payload)),
  setUpdatedPostId: payload => dispatch({ type: "SET_UPDATED_POSTID", payload }),
  setUserr: (payload) => dispatch(userHighlight(payload)),
  showDiscussion: (payload) => dispatch(showDiscussion(payload)),
  // leftPanelModalFunc: (payload) =>
  //   dispatch({ type: "LEFT_PANEL_OPEN", payload }),
  screen: (payload) => dispatch(screen(payload)),
  setTerm: (payload) => dispatch({ type: "SET_TERM", payload }),
  setTermWeb: (payload) => dispatch({ type: "SET_TERM_WEB", payload }),
  showbox: (payload) => dispatch(showbox(payload))


});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DashboardScreen
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: '#EFEFEF',
  }

});