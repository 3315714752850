import firebase from "firebase/app";
import "firebase/auth";
import React, { Component } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  Animated,
  // AsyncStorage,
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import * as Animatable from "react-native-animatable";
import Accordion from "react-native-collapsible/Accordion";
import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setAdminStatus } from "../actionCreator/AdminAction";
import { setLoginStatus } from "../actionCreator/UserAction";
import HeaderRight from "../components/HeaderRight";
import InviteFriend from "../components/InviteFriend";

import Notification from "../components/Notification";
import PaymentInfo from "../components/PaymentInfo";
import Security from "../components/Security";
import Subscription from "../components/Subscription";
import ButtonStyle from "../constants/ButtonStyle";
import ConstantFontFamily from "../constants/FontFamily";
import ConstantColors from '../constants/Colors'

const getSettingButtonType = async () => {
  await AsyncStorage.getItem('getSettingButtonState', (error, result) => {
    let getSession = result
    AsyncStorage.removeItem("getSettingButtonState");
    return getSession
  })
}

class SettingsScreen extends Component {
  constructor(props) {
    super(props);
    this.CONTENT = [
      {
        title: "Membership",
        content: <Subscription />
      },
      {
        title: "Notifications",
        content: <Notification />
      },
      {
        title: "Payment & Payout Info",
        content: <PaymentInfo />
      },
      {
        title: "Invite Friends",
        content: <InviteFriend />
      },
      {
        title: "Security",
        content: <Security />
      }
    ];
  }

  logout = async () => {
    console.log('logged out');
    await AsyncStorage.multiRemove([
      "userLoginId",
      "MyUserUserId",
      "userIdTokenWeclikd",
      "UserId",
      "admin",
      "rememberMe",
      "skipCredentials"
    ]).then(async r => {
      await firebase
        .auth()
        .signOut()
        .then(
          function () {
            this.props.changeLoginStatus(0);
            this.props.changeAdminStatus(false);
            if (Platform.OS == "web") {
              this.extensionLogout();
            }
            this.props.navigation.navigate("home");
          },
          function (error) {
            console.error("Sign Out Error", error);
          }
        );
    });
  };

  state = {
    activeSections: getSettingButtonType() ? [3] : [0],
    collapsed: true,
    multipleSelect: false
  };

  setSections = sections => {
    this.setState({
      activeSections: sections.includes(undefined) ? [] : sections
    });
  };

  extensionLogout = () => {
    try {
      window.parent.postMessage({ type: "wecklid_logout" }, "*");
    } catch (err) {
      console.log("Extension Logout Error ", err);
    }
  };

  componentDidUpdate = async (prevState) => {
    if (prevState.activeSections != this.state.activeSections) {
      let getSession
      await AsyncStorage.getItem('getSettingActiveSection', async (error, result) => {
        getSession = JSON.parse(result);
        AsyncStorage.removeItem("getSettingActiveSection");
        getSession == 'Upgrade' && this.setState({ activeSections: [0] })

      })
    }

  }


  renderHeader = (section, _, isActive) => {
    return (
      <View
        // duration={400}
        style={
          ([styles.header, isActive ? styles.active : styles.inactive],
          {
            flexDirection: "row",
            paddingHorizontal: 20
          })
        }
        transition="backgroundColor"
      >
        <Icon
          color={"#000"}
          iconStyle={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            justifyContent: "flex-start",
            width: '100%'
          }}
          name={isActive ? "minus" : "plus"}
          type="font-awesome"
          size={20}
          containerStyle={{
            alignSelf: "center"
          }}
        />
        <Text
          style={{
            color: "#000",
            padding: 10,
            borderRadius: 6,
            fontFamily: ConstantFontFamily.defaultFont,
            fontSize: 18,
            fontWeight: "bold",
            width: '90%',
            textAlign: 'center'
          }}
        >
          {section.title}
        </Text>
      </View>
    );
  };

  renderContent(section, _, isActive) {
    return (
      <View
        // duration={400}
        style={[styles.content, isActive ? styles.active : styles.inactive]}
        animation={isActive ? "bounceIn" : undefined}
      >
        {section.content}
      </View>
    );
  }

  render() {
    const { multipleSelect, activeSections } = this.state;
    return (
      <View style={[styles.container, {
        borderLeftWidth: 1,
        borderColor: '#d3d3d3',
        paddingLeft:10
      }]}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          ref={scrollview => {
            this.UserProfilescrollview = scrollview;
          }}
          onLayout={event => {
            let { x, y, width, height } = event.nativeEvent.layout;
            if (width < 1024) {
              this.setState({
                showsVerticalScrollIndicatorView: true,
                currentScreentWidth: width
              });
            } else {
              this.setState({
                showsVerticalScrollIndicatorView: false,
                currentScreentWidth: width
              });
            }
          }}
          style={{
            height:
              Platform.OS !== "web"
                ? null
                : Dimensions.get("window").height - 80,
          }}
        >
          <View>
            {Dimensions.get("window").width <= 750 ? (
              <Animated.View
                style={{
                  position: Platform.OS == "web" ? "sticky" : null,
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  overflow: "hidden",
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      backgroundColor: "#000",
                      //borderRadius: 6,
                      height: 50
                    }}
                  >
                    <TouchableOpacity
                      style={ButtonStyle.headerBackStyle}
                      onPress={() => {
                        let nav = this.props.navigation.dangerouslyGetParent()
                          .state;
                        if (nav.routes.length > 1) {
                          this.props.navigation.goBack();
                          return;
                        } else {
                          this.props.navigation.navigate("home");
                        }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[ButtonStyle.headerTitleStyle, { backgroundColor: '#000' }]}
                    >
                      <Text
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: 18,
                          fontFamily: ConstantFontFamily.defaultFont
                        }}
                      >
                        Settings
                      </Text>
                    </TouchableOpacity>
                    <View style={ButtonStyle.headerRightStyle}>
                      <HeaderRight navigation={this.props.navigation} />
                    </View>
                  </View>
                </View>
              </Animated.View>
            ) : (
              null
            )}

            <View>
              <View style={styles.container}>
                <ScrollView contentContainerStyle={
                  {
                    paddingBottom: 30,
                  }}>
                  <Accordion
                    activeSections={activeSections}
                    sections={this.CONTENT}
                    touchableComponent={TouchableOpacity}
                    expandMultiple={multipleSelect}
                    renderHeader={this.renderHeader}
                    renderContent={this.renderContent}
                    duration={400}
                    onChange={this.setSections}
                    sectionContainerStyle={[ButtonStyle.normalFeedStyleForDashboard, {
                      backgroundColor: '#fff',
                      paddingVertical: 5,
                      width: '100%',
                      marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 2,
                      marginTop: 15,
                    }]}
                  />
                  <TouchableOpacity
                    onPress={this.logout}
                    style={{ backgroundColor: "#F4F4F4", alignItems: 'center' }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        textAlign: "center",
                        backgroundColor: "#fff",
                        color: "#000",
                        padding: 10,
                        borderRadius: 20,
                        borderColor: '#D7D7D7',
                        borderWidth: 1,
                        width: 150,
                        fontFamily: ConstantFontFamily.defaultFont,
                        display: 'none'
                      }}
                    >
                      Logout
                    </Text>
                  </TouchableOpacity>
                </ScrollView>
              </View>
            </View>

          </View>
        </ScrollView>
      </View>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  changeAdminStatus: payload => dispatch(setAdminStatus(payload))
});

export default compose(connect(null, mapDispatchToProps))(SettingsScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ConstantColors.whiteBackground,

  },
  header: {
    backgroundColor: "#FFF",
    padding: 10
  },
  content: {
    backgroundColor: "#fff",
    flex: 1
  },
  active: {
    backgroundColor: "transparent"
  },
  inactive: {
    backgroundColor: "#fff"
  }
});