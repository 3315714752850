import { put, takeEvery } from 'redux-saga/effects';
import { LOGIN_STATUS, LOGIN_STATUS_FAILURE, LOGIN_STATUS_SUCCESS } from "../constants/Action";

export function* UserMiddleware({ payload }) {
  try {
    yield put({
      type: LOGIN_STATUS_SUCCESS,
      payload: payload
    });
  } catch (err) {
    yield put({
      type: LOGIN_STATUS_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* UserMiddlewareWatcher() {
  yield takeEvery(LOGIN_STATUS, UserMiddleware);

}