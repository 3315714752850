import { takeEvery, call, put } from "redux-saga/effects";
import {
  CLIKUSERREQUEST,
  CLIKUSERREQUEST_SUCCESS,
  CLIKUSERREQUEST_FAILURE
} from "../constants/Action";
import applloClient from "../client";
import { ClikUserApplications } from "../graphqlSchema/graphqlMutation/TrendingMutation";

const getResponse = req => {
  return applloClient
    .query({
      query: ClikUserApplications,
      variables: {
        id: "Clik:" + req.id,
        first: req.currentPage,
      },
      fetchPolicy: "no-cache"
    })
    .then(res => res);
};

export function* ClikUserRequestMiddleware({ payload }) {
  try {
    const response = yield call(getResponse, payload);
    yield put({
      type: CLIKUSERREQUEST_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: CLIKUSERREQUEST_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* ClikUserRequestMiddlewareWatcher() {
  yield takeEvery(CLIKUSERREQUEST, ClikUserRequestMiddleware);
}
