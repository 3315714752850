import {CLIKD_SUCCESS} from '../constants/Action';


const initialState ={
    cliks:0
}

const ClikReducer = (state = initialState, action) => {
    switch (action.type) {
      case CLIKD_SUCCESS: {
        return {
            cliks: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default ClikReducer;