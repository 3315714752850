import "firebase/auth";
import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Dimensions
} from "react-native";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setSHARELINKMODALACTION } from "../actionCreator/ShareLinkModalAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import { Icon } from "react-native-elements";
import { setUserApproachAction } from "../actionCreator/UserApproachAction";
import applloClient from "../client";
import {
  GetNumUnreadNotificationsMutation
} from "../graphqlSchema/graphqlMutation/Notification";

class IconMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UnreadNotifications: 0,
      showSearchIcon: true,
    };
  }

  


  componentDidMount = async () => {
    if (this.props.getUserApproach == "login") {
      await this.props.setUserApproachAction({ type: "" });
    }
    if (this.props.getUserApproach == "signUp") {
      this.props.setSignUpModalStatus(true);
    }
    if (this.props.loginStatus == 1) {
      this.getUnreadNotifications();
    }
  };


  getUnreadNotifications = () => {
    applloClient
      .query({
        query: GetNumUnreadNotificationsMutation,
        variables:{
          id: "Account:CurrentUser",
          first: 100,
        },
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        this.setState({
          UnreadNotifications: res.data.node.num_unread_notifications
        });
        let timer = setTimeout(() => {
          if (this.props.loginStatus == 1) {
            this.getUnreadNotifications();
          }
        }, 60000);
      });
  };



  componentWillUnmount = () => {
    this.setState({ showSearchIcon: true });
  };

  render() {
    return (
      <View>
        <View
          style={{
            alignItems: "center",
            justifyContent: 'center',
            height: 50,
          }}
        >
          
          <Icon
            testID="AddLink"
            size={25}
            name="plus"
            type="font-awesome"
            iconStyle={styles.actionButtonIcon}
            color={Dimensions.get("window").width <= 750 ? "#000" : "#fff"}
            underlayColor="#000"
            onPress={() => this.props.setShareLinkModalStatus(!this.props.getShareLinkModalStatus)}
          />
          
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  loginStatus: state.UserReducer.get("loginStatus"),

  getShareLinkModalStatus: state.ShareLinkModalReducer.get("modalStatus"),
  getUserApproach: state.UserApproachReducer.get("setUserApproach"),
});

const mapDispatchToProps = dispatch => ({
  

  setSignUpModalStatus: payload => dispatch(setSIGNUPMODALACTION(payload)),
  setShareLinkModalStatus: payload =>
    dispatch(setSHARELINKMODALACTION(payload)),  
  setUserApproachAction: payload => dispatch(setUserApproachAction(payload)),

});


export default compose(connect(mapStateToProps, mapDispatchToProps))(
  IconMenu
);

const styles = StyleSheet.create({
  actionButtonIcon: {
    backgroundColor: Dimensions.get("window").width <= 750 ? "#fff" : "#000",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center"
  }
});