import {TERMCONCAT_SUCCESS} from '../constants/Action';


const initialState ={
    termArray:[],
}

const TermArrayConcat = (state = initialState, action) => {
    switch (action.type) {
      case TERMCONCAT_SUCCESS: {
        return {
            termArray: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default TermArrayConcat;