import { fromJS } from 'immutable';
import { CREATEACCOUNTACTION_SUCCESS, CREATEACCOUNTACTION_FAILURE } from "../constants/Action";



const initialState = fromJS({
    setCreateAccountData: {}
});

export default function CreateAccountReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATEACCOUNTACTION_SUCCESS:
            return state
                .set('setCreateAccountData', fromJS(payload))
        case CREATEACCOUNTACTION_FAILURE:
            return initialState;
    }
    return state;
}