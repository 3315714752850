import { ISLOADING } from '../constants/Action';


const initialState = {
    isloading: true,
}

const isLoadingReducer = (state = initialState, action) => {

    switch (action.type) {
        case ISLOADING: {
            // console.log(action.payload, 'payloadssdsdgs');
            return {
                isloading: action.payload,
            };
        }
        default:
            return state;
    }
};

export default isLoadingReducer;