import "@expo/browser-polyfill";
import React, { Component } from "react";
import { Button } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import applloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import { ClikJoinMutation } from "../graphqlSchema/graphqlMutation/FollowandUnFollowMutation";
import { ClikJoinVariables } from "../graphqlSchema/graphqlVariables/FollowandUnfollowVariables";

class ClikJoinButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.privacy == true ? "Apply" : "Join"
    };
  }

  toogleButton = () => {
    if (this.state.status == "Join") {
      this.setState({
        status: "Leave"
      });
      this.clikJoin();
    } else if (this.state.status == "Leave") {
      this.setState({
        status: "Join"
      });
    } else if (this.state.status == "Apply") {
      this.setState({
        status: "Pending"
      });
      this.clikJoin();
    }
  };

  getColour = () => {
    if (this.state.status == "Join") {
      return "#159C23";
    } else if (this.state.status == "Leave") {
      return "red";
    } else if (this.state.status == "Apply") {
      return "#159C23";
    } else if (this.state.status == "Pending") {
      return "#e1e1e1";
    }
  };

  clikJoin = async () => {
    ClikJoinVariables.variables.clik_id = this.props.clikId;
    ClikJoinVariables.variables.qualification = "";
    ClikJoinVariables.variables.known_members = [];
    try {
      await applloClient
        .query({
          query: ClikJoinMutation,
          ...ClikJoinVariables,
          fetchPolicy: "no-cache"
        })
        .then(async res => {
        });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <Button
        title={this.state.status}
        buttonStyle={{
          borderRadius: 10,
          backgroundColor: this.getColour(),
          width: "100%",
          alignSelf: "center",
          justifyContent: "center",
          height: 30,
          paddingHorizontal: 10
        }}
        titleStyle={{
          fontFamily: ConstantFontFamily.defaultFont,
          fontSize: 14,
          color: "#fff"
        }}
        onPress={() => this.toogleButton()}
      />
    );
  }
}



export default compose(connect(null, null))(
  ClikJoinButton
);