import React from "react";
import {
  Image,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import "../components/Firebase";
import ConstantFontFamily from "../constants/FontFamily";
import ButtonStyle from "../constants/ButtonStyle";


const LeaveCliksModal = props => {
  

  return (
    <View
      style={{
        width: "100%",
        height:300
      }}
    >
      <Hoverable>
        {isHovered => (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              flex: 1,
              position: "absolute",
              zIndex: 999999,
              left: 0,
              top: 0
            }}
            onPress={props.onClose}
          >
            <Icon
              color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
              iconStyle={{
                color: "#fff",
                justifyContent: "center",
                alignItems: "center"
              }}
              reverse
              name="close"
              type="antdesign"
              size={16}
            />
          </TouchableOpacity>
        )}
      </Hoverable>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#000",
          alignItems: "center",
          height: 50
        }}
      >
        <Image
          source={
            Platform.OS == "web" && props.getCurrentDeviceWidthAction > 750
              ? require("../assets/image/weclickd-logo.png")
              : Platform.OS == "web"
              ? require("../assets/image/weclickd-logo.png")
              : require("../assets/image/weclickd-logo-only-icon.png")
          }
          style={
            Platform.OS == "web" && props.getCurrentDeviceWidthAction > 750
              ? {
                  height: 30,
                  width: Platform.OS == "web" ? 90 : 30,
                  padding: 0,
                  margin: 0,
                  marginVertical: 10
                }
              : {
                  height: 30,
                  width: Platform.OS == "web" ? 90 : 30,
                  padding: 0,
                  margin: 0,
                  marginVertical: 10
                }
          }
        />
      </View>

      <View
        style={{
          borderRadius: 4,
          width: "100%",
          backgroundColor: "#fff",
        }}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{
            backgroundColor: "#fff",
            padding: 10
          }}
        >
          <Text
            style={{
              textAlign: "center",
              color: "#000",
              fontFamily: ConstantFontFamily.defaultFont,
              fontSize: 16,
              fontWeight: "bold",
              marginTop: 10
            }}
          >
            If you leave the clik, you will have to be re-invited to join again.
          </Text>
          <Text
            style={{
              textAlign: "center",
              color: "#000",
              fontFamily: ConstantFontFamily.defaultFont,
              fontSize: 16,
              fontWeight: "bold",
              marginTop: 10
            }}
          >
            Are you sure you want to leave?
          </Text>
        

        <View
          style={{
            flexDirection: "row",
            width: "100%",
            flex: 1
          }}
        >
          <View
            style={{
              alignItems: "flex-start",
              width: "45%",
              margin: 10
            }}
          >
            <Button
              color="#fff"
              title="Back"
              titleStyle={ButtonStyle.titleStyle}
              buttonStyle={ButtonStyle.backgroundStyle}
              containerStyle={ButtonStyle.containerStyle}
              onPress={props.onClose}
            />
          </View>

          <View
            style={{
              alignItems: "flex-end",
              width: "45%",
              margin: 10
            }}
          >
            <Button
              color="#fff"
              title="Leave"
              titleStyle={ButtonStyle.titleStyle}
              buttonStyle={ButtonStyle.backgroundStyle}
              containerStyle={ButtonStyle.containerStyle}
              onPress={props.leaveClik}
            />
          </View>
        </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default connect(null, null)(LeaveCliksModal);


