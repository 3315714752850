import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import { AppLoading } from "expo";
import AppLoading from 'expo-app-loading';
import { loadAsync, FontDisplay } from "expo-font";
import React, { createRef, useEffect, useState } from "react";
import { ApolloProvider } from "react-apollo";
import {
  // AsyncStorage,
  Platform,
  SafeAreaView,
  YellowBox,
  // StatusBar,
  StyleSheet,
  View,
  LogBox,
  Animated,
  Dimensions
} from "react-native";
import ErrorBoundary from "react-native-error-boundary";
import { MenuProvider } from "react-native-popup-menu";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import applloClient from "./client";
import BottomFloatingButton from "./components/BottomFloatingButton";
import Layout from "./components/Layout";
import LeftPanel from "./components/LeftPanel";
import NavigationComponent from "./components/NavigationComponent";
import CrashlyticsErrorBoundary from "./CrashlyticsErrorBoundary";
import configStore from "./initializeStore";
import NavigationService from "./library/NavigationService";
import AppNavigator from "./Navigation/AppNavigator";
import BottomScreen from "./components/BottomScreen";
import { StatusBar } from 'expo-status-bar';
import 'react-native-gesture-handler'
import 'react-native-reanimated';
// import ignoreWarnings from 'react-native-ig
import ignoreWarnings from 'ignore-warnings';
import AsyncStorage from '@react-native-async-storage/async-storage';




const configuretore = configStore();
const stripePromise = loadStripe("pk_test_hFxQCiSLrnSGOUsxfRi9Phxc0031td3fGw");
const backColor = 'red'
LogBox.ignoreAllLogs(true);
console.disableYellowBox = true;
console.ignoredYellowBox = ["Warning: Each", "Warning: Failed", "Animated:useNatveDrver"];
ignoreWarnings([
  "Warning: componentWillMount is deprecated",
  "Warning: componentWillReceiveProps is deprecated",
  "Warning: componentWillReceiveProps has been renamed, and is not recommended for use.",
  "Warning: componentWillMount has been renamed, and is not recommended for use",
  "Animated: `useNativeDriver` was not specified. This is a required option and must be explicitly set to true or false",
  "react-native-logs.fx.ts:22 Animated: `useNativeDriver` was not specified. This is a required option and must be explicitly set to true or false",
  "The resource http://localhost:19006/fonts/Feather.ttf was preloaded",
  "You may test your Stripe.js integration over HTTP. However, live Stripe.js integrations must use HTTPS"

]);
ignoreWarnings([
  "Warning: componentWillMount is deprecated",
  "Warning: componentWillReceiveProps is deprecated",
  "Warning: componentWillReceiveProps has been renamed, and is not recommended for use.",
  "Warning: componentWillMount has been renamed, and is not recommended for use",
  "Attempted import error",
  "Warning: ReactNative.createElement",
  "Warning: Each",
  "Warning: Failed",
  "Animated: `useNativeDriver` was not specified. This is a required option and must be explicitly set to true or false",
  "The resource http://localhost:19006/fonts/Feather.ttf was preloaded",
  "You may test your Stripe.js integration over HTTP. However, live Stripe.js integrations must use HTTPS"


]);

YellowBox.ignoreWarnings(['Animated:']);

ignoreWarnings('error', [
  'The house is on fire',
  'System failure',
  "react-native-logs.fx.ts:22 Animated: `useNativeDriver` was not specified. This is a required option and must be explicitly set to true or false",
  "react-native-logs.fx.ts:22 Animated: `useNativeDriver` was not specified. This is a required option and must be explicitly set to true or false",
  "The resource http://localhost:19006/fonts/Feather.ttf was preloaded",
  "You may test your Stripe.js integration over HTTP. However, live Stripe.js integrations must use HTTPS"

]);

ignoreWarnings('log', 'Hello, world');





export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [width, setWidth] = useState(0);
  const [animatePress, setAnimatePress] = useState(new Animated.Value(1))
  const nameInput = createRef()

  //------------------------------------------ Set Token in Extension ---------------------------------------------
  const handleExtensionToken = async event => {
    try {
      if (await AsyncStorage?.getItem("userIdTokenFirebase")) {
        window.parent.postMessage(
          {
            type: "wecklid_login",
            userIdTokenFirebase: await AsyncStorage.getItem(
              "userIdTokenFirebase"
            ),
            UserName: await AsyncStorage.getItem("UserName")
          },
          "*"
        );
      } else window.parent.postMessage({ type: "wecklid_logout" }, "*");
    } catch (err) {
      console.log("Extension Token Set Error (App.js 41 line) ", err);
    }
  };

  useEffect(() => {
    if (Platform.OS == "web") {
      handleExtensionToken();
      LogBox.ignoreAllLogs();
      LogBox.ignoreLogs(['Animated:'])
      ignoreWarnings([
        "Warning: componentWillMount is deprecated",
        "Warning: componentWillReceiveProps is deprecated",
        "Warning: componentWillReceiveProps has been renamed, and is not recommended for use.",
        "Warning: componentWillMount has been renamed, and is not recommended for use",
        "Attempted import error",
        "Warning: ReactNative.createElement",
        "Warning: Each",
        "Warning: Failed",
        "findAndUpdateNodes was passed a nullish node",
        "VirtualizedList: You have a large list that is slow to update",
        "Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?",
        "Warning: Received `false` for a non-boolean attribute `focus`.",
        "The resource http://localhost:19006/fonts/Feather.ttf was preloaded",
        "You may test your Stripe.js integration over HTTP. However, live Stripe.js integrations must use HTTPS"

      ]);
      ignoreWarnings('error', ["findAndUpdateNodes was passed a nullish node",
        "Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?",
        "Warning: Received `false` for a non-boolean attribute `focus`.",
        "The resource http://localhost:19006/fonts/Feather.ttf was preloaded",
        "You may test your Stripe.js integration over HTTP. However, live Stripe.js integrations must use HTTPS"

      ])

      ignoreWarnings('error', [
        'The house is on fire',
        'System failure'
      ]);

      ignoreWarnings('log', 'Animated');

      YellowBox.ignoreWarnings([
        'Animated: `useNativeDriver` was not specified.',
        "Animated: useNativeDriver is not supported because the native animated module is missing.",
        "The resource http://localhost:19006/fonts/Feather.ttf was preloaded",
        "You may test your Stripe.js integration over HTTP. However, live Stripe.js integrations must use HTTPS"
      ]);

      ignoreWarnings([
        'Animated: `useNativeDriver` was not specified.',
        "Animated: useNativeDriver is not supported because the native animated module is missing.",
        "The resource http://localhost:19006/fonts/Feather.ttf was preloaded",
        "You may test your Stripe.js integration over HTTP. However, live Stripe.js integrations must use HTTPS"
      ]);

      // const animateIn = () => {
      //   Animated.timing(animatePress, {
      //     toValue: 0.5,
      //     duration: 500,
      //     useNativeDriver: true // Add This line
      //   }).start();
      // }


      console.error = (error) => error.apply;

    }
    return () => {
      window.removeEventListener('DOMContentLoaded', handleExtensionToken);
    };
  }, []);
  //------------------------------------------------------------------------------------------------------------

  if (!isLoadingComplete) {
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setLoadingComplete)}
      />
    );
  } else {
    return (
      <SafeAreaView style={styles.container}>
        <ApolloProvider client={applloClient}>
          <Provider store={configuretore.store}>
            <PersistGate persistor={configuretore.persistor} loading={null}>
              {
                <StatusBar style='light' backgroundColor='#000'
                />
              }
              <ErrorBoundary FallbackComponent={CrashlyticsErrorBoundary}>
                <Layout ref={navigatorRef => {
                  NavigationService.setTopLevelNavigator(navigatorRef);
                }}
                  navigation={props.navigation} />
                {Platform.OS == "web" ? (

                  <Elements stripe={stripePromise}>
                    {/* {online ? */}
                    <NavigationComponent
                      ref={navigatorRef => {
                        NavigationService.setTopLevelNavigator(navigatorRef);
                      }}
                      navigation={NavigationService}
                      nameInput={nameInput}
                    />

                    {/* : <NetworkError />} */}


                  </Elements>


                ) : (
                  <View
                    onLayout={event => {
                      let { width, height } = event.nativeEvent.layout;
                      setWidth(width);
                    }}
                    style={{
                      width: "100%",
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center"
                    }}
                  >
                    {Platform.OS == "web" && width >= 750 ? (
                      <LeftPanel
                        ref={navigatorRef => {
                          NavigationService.setTopLevelNavigator(navigatorRef);
                        }}
                        navigation={props.navigation}
                      />
                    ) : null}
                    <View
                      style={{
                        width:
                          Platform.OS == "web" && width >= 750 ? "50%" : "100%",
                        paddingLeft:
                          Platform.OS == "web" && width >= 750 ? 10 : 0
                      }}
                    >
                      <MenuProvider>
                        <AppNavigator
                          ref={navigatorRef => {
                            NavigationService.setTopLevelNavigator(
                              navigatorRef
                            );
                          }}
                        />
                        <BottomScreen navigation={NavigationService} />
                      </MenuProvider>
                    </View>
                    <BottomFloatingButton
                      navigation={props.navigation}
                      modalStataus={() => { }}
                    />
                  </View>
                )}
              </ErrorBoundary>
            </PersistGate>
          </Provider>
        </ApolloProvider>
      </SafeAreaView>
    );
  }
}

async function loadResourcesAsync() {
  await Promise.all([
    loadAsync({
      Avenir: {
        uri: require("./assets/fonts/AvenirBook.ttf"),
        display: FontDisplay.SWAP
      },
      AvenirBlack: {
        uri: require("./assets/fonts/AvenirBlack.ttf"),
        display: FontDisplay.SWAP
      },
      Helvetica: {
        uri: require("./assets/fonts/Helvetica.ttf"),
        display: FontDisplay.SWAP
      },
      HelveticaBold: {
        uri: require("./assets/fonts/Helvetica-Bold.ttf"),
        display: FontDisplay.SWAP
      },
    })
  ]);
}

function handleLoadingError(error) {
  console.warn(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#000"
  },
});


