import { List } from "immutable";
import moment from "moment";
import React, { Component } from "react";
import {
    Image,
    Text,
    TouchableOpacity,
    View,
    Dimensions
} from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";

class FeedUserIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // console.log(this.props.item2, 'eeee')

        return (

            <View style={{
                position: 'absolute',

                // height: this.props?.item?.length >= 1 || this.props?.item2?.length >= 1 ? 32 : this.props?.item?.length >= 2 || this.props?.item2?.length >= 2 ? 64 : 0,
                top: Dimensions.get('window').width <= 750 && this.props?.item?.length >=1 ? -28 :
                Dimensions.get('window').width >750 && this.props?.item?.length == 0 &&  this.props?.item2?.length >= 1  && this.props.summaryHeight < 20 ? 0 :
                Dimensions.get('window').width >750 && this.props?.item?.length == 0 &&  this.props?.item2?.length >= 1  && this.props.summaryHeight < 70 ? -10 : 
                Dimensions.get('window').width >750 && this.props?.item?.length >1 &&  this.props?.item2?.length >= 1  && this.props.summaryHeight < 70 ? 10 :
                Dimensions.get('window').width >750 && this.props?.item?.length ==1 &&  this.props?.item2?.length >= 1  && this.props.summaryHeight < 70 ? -20 :
                Dimensions.get('window').width >750 && this.props?.item?.length == 0 &&  this.props?.item2?.length >= 1  && this.props.summaryHeight > 40 ? -12 : 0,

            }}>
                {/* <Text style={{marginBottom:20}}>{this.props.summaryHeight }c</Text> */}

                {this.props?.item2?.length > 0 &&
                    < Image
                        // source={require("../assets/image/default-image.png")}

                        source={this.props?.item2[0]?.node?.profile_pic ? this.props?.item2[0]?.node?.profile_pic : require("../assets/image/default-image.png")}
                        style={{
                            width: 32,
                            height: 32,
                            borderRadius: 20,
                            marginRight: 5,
                            borderColor: '#fff',
                            borderWidth: 2,
                            position: 'absolute',
                            top: this.props?.item?.length >= 1 ? 60 : 8,
                            left: 0
                        }}
                    />
                }
                { Dimensions.get('window').width >= 750 && this.props?.item2?.length > 1 &&
                    <Image
                        // source={require("../assets/image/default-image.png")}

                        source={this.props?.item2[1]?.node?.profile_pic ? this.props?.item2[1]?.node?.profile_pic : require("../assets/image/default-image.png")}
                        style={{
                            width: 32,
                            height: 32,
                            borderRadius: 20,
                            marginRight: 5,
                            borderColor: '#fff',
                            borderWidth: 2,
                            position: 'absolute',
                            left: 28,
                            top: this.props?.item?.length >= 1 ? 70 : 22
                        }}
                    />
                }
                { Dimensions.get('window').width >= 750 && this.props?.item2?.length > 2 &&
                    <Image
                        // source={require("../assets/image/default-image.png")}
                        source={this.props?.item2[2]?.node?.profile_pic ? this.props?.item2[2]?.node?.profile_pic : require("../assets/image/default-image.png")}
                        style={{
                            width: 32,
                            height: 32,
                            borderRadius: 20,
                            marginRight: 5,
                            borderColor: '#fff',
                            borderWidth: 2,
                            position: 'absolute',
                            left: 0,
                            top: this.props?.item?.length >= 1 ? 34 : 37
                        }}
                    />
                }
            </View>


        );
    }
}

const mapStateToProps = state => ({
    loginStatus: state.UserReducer.get("loginStatus"),
    isAdmin: state.AdminReducer.get("isAdmin"),
    profileData: state.LoginUserDetailsReducer.userLoginDetails,
});

const mapDispatchToProps = dispatch => ({
    setActiveId: payload => dispatch({ type: "SET_ACTIVE_ID", payload })
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    FeedUserIcon
);
