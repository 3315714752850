import { ApolloClient, from, InMemoryCache } from "apollo-client-preset";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import getEnvVars from "./environment";
import {
  getFirebaseToken,
  getRefreshFirebaseToken,
  getMyUserId,
  getWeclikdToken,
} from "./library/Helper";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "./fragmentTypes.json";
import fetch from "node-fetch";
// import { AsyncStorage } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import NavigationService from "./library/NavigationService";
import jwt_decode from "jwt-decode";
import { onError } from "apollo-link-error";
import { Observable } from "apollo-link";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";




const apiUrl = getEnvVars();

let temptoken =
  "eyJhbGciOiJSUzI1NiIsImtpZCI6IjU1NGE3NTQ3Nzg1ODdjOTRjMTY3M2U4ZWEyNDQ2MTZjMGMwNDNjYmMiLCJ0eXAiOiJKV1QifQ.eyJhZG1pbiI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2VsZWN0cmljLWJsb2NrLTI0MTQwMiIsImF1ZCI6ImVsZWN0cmljLWJsb2NrLTI0MTQwMiIsImF1dGhfdGltZSI6MTU5NjE3MjI0MCwidXNlcl9pZCI6ImxVNmRzQ1l6aFBoTmZSWnhtd2g4S3dYZ2pJbzEiLCJzdWIiOiJsVTZkc0NZemhQaE5mUlp4bXdoOEt3WGdqSW8xIiwiaWF0IjoxNTk2MTkwMDMzLCJleHAiOjE1OTYxOTM2MzMsImVtYWlsIjoiY2hheWFuLm1lQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImNoYXlhbi5tZUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.V905BVSLOJF0GuHCV4DVFNLcoRZEkQL0XujxcUEXwn_qLRyRi8fU9OhBq_VJZe5o2WQFCJP1GGAGA6b5wK1opstIJAl_1s-vBAoxIuWQkkWovONaRaWS7PsmMvVNUkEKqCx-73KuPC3zXfeqFEwPd-H9WEWMmAYqgOBJpdB6rMDLyFK8KVDsPYpPZ5L8EGo1E7Aa32vR_XTuV64H0om7WSXinezK-dN_sQsa2w91yov01tOKDx9HC9KX8XF49GZIw7hkrOUrwfcUWQxqCBnFgeC9PWVP9YPQhJZoer3V6cooDb2Q_1RxTFEDGK4OWFSrW_pzXrJ0vMvWyBxEexg3ww";

const customFetch = (uri, options) => {
  const { operationName } = JSON.parse(options.body);
  return fetch(`${apiUrl.GRAPHQL_URL}/${operationName}`, options);
};

const httpLink = createHttpLink({
  fetch: customFetch,
});

const auth1 = async () => {
  let token = await AsyncStorage.getItem("userIdTokenFirebase");
  const firebaseToken =
    token && Date.now() < jwt_decode(token).exp * 1000
      ? await AsyncStorage.getItem("userIdTokenFirebase")
      : await getRefreshFirebaseToken();
  return await {
    firebaseToken,
  };
};

// const authMiddleware = setContext(
//   async (operation) =>
//     await auth().then(async (token) => {
//       let setHeader = {};
//       if (token.firebaseToken) {
//         setHeader = {
//           ...setHeader,
//           Authorization: "Bearer " + token.firebaseToken,
//         };
//       }
//       return {
//         headers: {
//           ...setHeader,
//           "Access-Control-Allow-Origin": "*",
//         },
//       };
//     })
// );

const authMiddleware = setContext(async (_, { headers }) => {
  // it will always get unexpired version of the token
  let storetoken = await AsyncStorage.getItem("userIdTokenFirebase");
  if (storetoken && Date.now() < jwt_decode(storetoken).exp * 1000) {
    return {
      headers: {
        ...headers,
        authorization: storetoken ? `Bearer ${storetoken}` : "",
        "Access-Control-Allow-Origin": "*",
      },
    };
  } else {
    const auth = getAuth()
    if (auth.currentUser) {
      return auth.currentUser.getIdToken(true)
        .then((token) => {
          AsyncStorage.setItem("userIdTokenFirebase", token);
          return {
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : "",
              "Access-Control-Allow-Origin": "*",
            },
          };
        });
    } else {
      console.log("state = unknown (until the callback is invoked)")
      await onAuthStateChanged(auth, async user => {
        if (user) {
          console.log("state = definitely signed in")
          return user.getIdToken(true).then((token) => {
            AsyncStorage.setItem("userIdTokenFirebase", token);
            return {
              headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
                "Access-Control-Allow-Origin": "*",
              },
            };
          });
        }
        else {
          await localStorage.clear();
          await signOut(auth);
        

          // NavigationService.navigate("home"), window.location.reload();

        

          // if (Platform.OS == "web") {
          //   this.extensionLogout();
          // }
          console.log("state = definitely signed out")
        }
      })
      // firebase.auth().onAuthStateChanged((user) => {
      //   if (user) {
      //     return user.getIdToken(true).then((token) => {
      //       AsyncStorage.setItem("userIdTokenFirebase", token);
      //       return {
      //         headers: {
      //           ...headers,
      //           authorization: token ? `Bearer ${token}` : "",
      //           "Access-Control-Allow-Origin": "*",
      //         },
      //       };
      //     });
      //   }
      // });
    }
  }
});

const Errorlink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const auth = getAuth()
    if (graphQLErrors) {
      return new Observable(async (observer) => {
        if (auth.currentUser) {
          return auth
            .currentUser.getIdToken(true)
            .then((token) => {
              AsyncStorage.setItem("userIdTokenFirebase", token);
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: "Bearer " + token,
                },
              });
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              // Retry last failed request
              forward(operation).subscribe(subscriber);
            })

          // });
        } else {
          onAuthStateChanged(auth, async (user) => {
            if (user) {
              return user.getIdToken(true).then((token) => {
                AsyncStorage.setItem("userIdTokenFirebase", token);
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: "Bearer " + token,
                  },
                });
              })
                .then(() => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),

                  };
                  console.log("graphQLErrors", subscriber)
                  // Retry last failed request
                  forward(operation).subscribe(subscriber);

                })
            }
          });
        }

      });

    }
    if (networkError) {
      return new Observable(async (observer) => {
        if (auth.currentUser) {
          return auth
            .currentUser.getIdToken(true)
            .then((token) => {
              AsyncStorage.setItem("userIdTokenFirebase", token);
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: "Bearer " + token,
                },
              });
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              // Retry last failed request
              forward(operation).subscribe(subscriber);
            })

          // });
        } else {
        onAuthStateChanged(auth,async(user) => {
            if (user) {
              return user.getIdToken(true).then((token) => {
                AsyncStorage.setItem("userIdTokenFirebase", token);
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: "Bearer " + token,
                  },
                });
              })
                .then(() => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  // Retry last failed request
                  forward(operation).subscribe(subscriber);
                })
            }
          });
        }
      });
    }
  }
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const applloClient = new ApolloClient({
  link: from([Errorlink, authMiddleware, httpLink]),
  cache: new InMemoryCache({ fragmentMatcher }),
});




export default applloClient



