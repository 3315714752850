import { fromJS } from "immutable";
import {
  FEED_PROFILE_DETAILS_FAILURE,
  FEED_PROFILE_DETAILS_SUCCESS
} from "../constants/Action";

const initialState = fromJS({
  getFeedProfileDetails: {}
});

export default function FeedProfileReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FEED_PROFILE_DETAILS_SUCCESS:
      return state.set("getFeedProfileDetails", fromJS(payload));
    case FEED_PROFILE_DETAILS_FAILURE:
      return initialState;
  }
  return state;
}
