import { takeEvery, put } from 'redux-saga/effects';
import { LOGINMODALACTION, LOGINMODALACTION_SUCCESS, LOGINMODALACTION_FAILURE } from "../constants/Action";

export function* LoginModalMiddleware({ payload }) {
    if (payload == true) {
        yield put({
            type: LOGINMODALACTION_SUCCESS,
            payload: payload
        });
    } else {
        yield put({
            type: LOGINMODALACTION_FAILURE,
            payload: payload
        });
    }
}

export default function* LoginModalMiddlewareWatcher() {
    yield takeEvery(LOGINMODALACTION, LoginModalMiddleware);
}