import { put, takeEvery } from "redux-saga/effects";
import {
  EDITTOPIC,
  EDITTOPIC_FAILURE,
  EDITTOPIC_SUCCESS
} from "../constants/Action";

export function* EditTopicMiddleware({ payload }) {
  try {
    yield put({
      type: EDITTOPIC_SUCCESS,
      payload: payload
    });
  } catch (err) {
    yield put({
      type: EDITTOPIC_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* EditTopicMiddlewareWatcher() {
  yield takeEvery(EDITTOPIC, EditTopicMiddleware);
}
