import { call, put, takeEvery, select } from "redux-saga/effects";
import applloClient from "../client";
import {
  TRENDINGCLICKS,
  TRENDINGCLICKS_FAILURE,
  TRENDINGCLICKS_SUCCESS
} from "../constants/Action";
import { Trending_Cliks_Mutation } from "../graphqlSchema/graphqlMutation/TrendingMutation";

const getResponse = req => {
  return applloClient
    .query({
      query: Trending_Cliks_Mutation,
      variables: {
        // currentPage: req.currentPage
        type: "Clik",
        sort_type: "TRENDING",
        first: 50
      },
      fetchPolicy: "no-cache"
    })
    .then(res => res);
};

const getClikList = (item, followList) => {
  // let newArray = [];
  // item.forEach((obj) => {
  //   let index = followList.findIndex(
  //     (i) => i.node.clik.id == obj.node.id
  //   );
  //   if (index == -1) {
  //     newArray.push({ ...obj });
  //   }
  // });
  let newArray = [];
  let getFollowList = []
  let newArray1 = []

  item.forEach((obj) => {
    let index = followList.findIndex(
      (i) => i.node.clik.id == obj.node.id
    );
    if (index == -1) {
      newArray.push({ ...obj });
    }
  });

  followList.map(e => {
    getFollowList.push({ node: e.node.clik, __typename: "WeclikdNodeEdge" })
  })

  newArray1 = getFollowList.concat(newArray)
  return newArray1
  // return newArray
}

export function* Trending_Clicks_Middleware({ payload }) {
  try {
    const followList = state => state.LoginUserDetailsReducer.userFollowCliksList
    let list = yield select(followList)
    const response = yield call(getResponse, payload);
    const getList = getClikList(response.data.node_list.edges, list)
    // console.log(getList, 'getList');
    yield put({
      type: TRENDINGCLICKS_SUCCESS,
      //   payload: response.data.trending_cliks.edges
      payload: getList
    });
  } catch (err) {
    yield put({
      type: TRENDINGCLICKS_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* Trending_Clicks_MiddlewareWatcher() {
  yield takeEvery(TRENDINGCLICKS, Trending_Clicks_Middleware);
}
