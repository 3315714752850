import { fromJS } from 'immutable';

const initialState = fromJS({
    postShareStatus: false,
    postShareId:""
});

export default function PostShareReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case "POSTSHARESTATUS":
            return state
                .set('postShareStatus', payload);
        case "SHAREDPOSTID":
            return state.set("postShareId",payload)
    }
    return state;
}
