import { fromJS } from 'immutable';
import { CLIKSFEED_SUCCESS, CLIKSFEED_FAILURE } from "../constants/Action";



const initialState = fromJS({
    CliksFeedList: null,
    CliksFeedListPagination: null,
});

export default function CliksFeedReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CLIKSFEED_SUCCESS:
            return state
                .set('CliksFeedList', payload.data.clik.posts.edges)
                .set('CliksFeedListPagination', payload.data.clik.posts.pageInfo);
        case CLIKSFEED_FAILURE:
            return initialState;
    }
    return state;
}