import { put, takeEvery } from "redux-saga/effects";
import {
  EDITFEED,
  EDITFEED_FAILURE,
  EDITFEED_SUCCESS
} from "../constants/Action";

export function* EditFeedMiddleware({ payload }) {
  try {
    yield put({
      type: EDITFEED_SUCCESS,
      payload: payload
    });
  } catch (err) {
    yield put({
      type: EDITFEED_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* EditFeedMiddlewareWatcher() {
  yield takeEvery(EDITFEED, EditFeedMiddleware);
}
