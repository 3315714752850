import React from "react";
import { Animated, Dimensions, View } from "react-native";
import Colors from '../constants/Colors';
import ButtonStyle from "../constants/ButtonStyle";

class ShadowSkeleton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loopArray: [0, 1, 2, 3, 4]
    };
    this.Pagescrollview = null;
    this.circleAnimatedValue = new Animated.Value(0);
  }

  componentDidMount() {
    this.circleAnimated();
  }

  circleAnimated = () => {
    this.circleAnimatedValue.setValue(0);
    Animated.timing(this.circleAnimatedValue, {
      toValue: 1,
      duration: 1000
    }).start(() => {
      setTimeout(() => {
        this.circleAnimated();
      }, 1000);
    });
  };

  render() {
    const translateX = this.circleAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-10, Dimensions.get("window").width]
    });
    const translateTitle = this.circleAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-10, Dimensions.get("window").width]
    });
    return (
      <View style={{
        borderRightWidth: 1,
        borderColor: '#0000'
      }}>
        {/* <SkeletonContent

          containerStyle={{
            width: Dimensions.get('window').width >= 1200 ? "95%" : "96%",
            marginLeft: Dimensions.get('window').width >= 1200 ? 15 : 8,
            marginTop: Dimensions.get('window').width >= 1200 ? 15 : 10,
            height: Dimensions.get('window').width >= 1200 ? 180 : 130,
            shadowOffset: {
              width: 0,
              height: 5,
            },
            shadowOpacity: 0.25,
            shadowRadius: 12,

            elevation: 7,
            borderRadius: 20
          }}
          boneColor={"rgba(0,0,0,0.03)"}
          highlightColor={Colors.greyBackground}
          layout={[{ width: '100%', height: Dimensions.get('window').width >= 1200 ? 180 : 130, borderRadius: 20 }]}
          animationDirection="horizontalRight"
          isLoading={true}
        /> */}
        <View style={[ButtonStyle.normalFeedStyleForDashboard, {
          height: Dimensions.get('window').width >= 1200 ? 180 : 130,
          backgroundColor:"rgba(0,0,0,0.03)"

        }]}></View>
        {this.state.loopArray.map((res, index) => {
          return (
            <View style={[ButtonStyle.normalFeedStyleForDashboard, {
              height: 130,
              backgroundColor:"rgba(0,0,0,0.03)",
    
            }]}></View>

            // <SkeletonContent

            //   containerStyle={{
            //     width: Dimensions.get('window').width >= 1200 ? "94%" : "96%",
            //     marginLeft: Dimensions.get('window').width >= 1200 ? 15 : 8,
            //     marginTop: Dimensions.get('window').width >= 1200 ? 15 : 10,
            //     height: 130,
            //     shadowOffset: {
            //       width: 0,
            //       height: 5,
            //     },
            //     shadowOpacity: 0.25,
            //     shadowRadius: 12,

            //     elevation: 7,
            //     borderRadius: 20
            //   }}
            //   boneColor={"rgba(0,0,0,0.03)"}

            //   layout={[{ width: '100%', height: 130, borderRadius: 20 }]}
            //   animationDirection="horizontalRight"
            //   isLoading={true}
            // />


          );
        })}

      </View>
    );
  }
}
export default ShadowSkeleton;
