import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
// import firebase from "firebase/app";
// import "firebase/auth";
// import { AsyncStorage } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';

let weclikdtoken;
let CurrentUserId;

export const getFirebaseToken = async () => {
  let firebasetoken = await AsyncStorage.getItem("userIdTokenFirebase");
  return firebasetoken;
};

export const getRefreshFirebaseToken = async () => {
  var sendDate = new Date().getTime();
  let Token = "";
  if (firebase.auth().currentUser) {
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async idToken => {
        await AsyncStorage.setItem("userIdTokenFirebase", idToken);
        Token = idToken;
      })
      .catch(error => {
        console.log(error);
      });
  } else {
    await firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        await user
          .getIdToken(true)
          .then(async idToken => {
            await AsyncStorage.setItem("userIdTokenFirebase", idToken);
            Token = idToken;
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  }
  var receiveDate = await new Date().getTime();
  var responseTimeMs = receiveDate - sendDate;
  await _sleep(responseTimeMs);
  return Token;
};

const _sleep = async msec => {
  return new Promise(resolve => setTimeout(resolve, msec));
};

export const getWeclikdToken = async () => {
  weclikdtoken = await AsyncStorage.getItem("userIdTokenWeclikd");
  return weclikdtoken;
};

export const getMyUserId = async () => {
  CurrentUserId = await AsyncStorage.getItem("MyUserUserId");
  return CurrentUserId;
};

export const getLocalStorage = async key => {
  if (!key) {
    return "Please send local storage key name";
  }
  const data = await AsyncStorage.getItem(key);
  return data;
};

export const setLocalStorage = async (key, value = null) => {
  if (!key) {
    return "Please send local storage key name";
  }
  await AsyncStorage.setItem(key, value);
  return true;
};

export const dataURItoBlob = async dataURI => {
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = await atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);
  let mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return await new Blob([ia], { type: mimeString });
};

export const capitalizeFirstLetter = string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};
