import { TOTAL_NESTEDCOMMENTS, GET_PARENT_PATH } from '../constants/Action';


const initialState = {
    nestedComment: 0,
    parentId: "",
    path: []
}

const NestedCommentReducer = (state = initialState, action) => {

    switch (action.type) {
        case TOTAL_NESTEDCOMMENTS: {
            // console.log(action);
            return {
                nestedComment: action.payload.comment,
                parentId: action.payload.parentId,
                path: action.payload.path
            };
        } case GET_PARENT_PATH: {
            console.log(action);
            return {
                path: action.payload
            };
        }
        default:
            return state;
    }
};

export default NestedCommentReducer;