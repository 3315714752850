import { put, takeEvery } from 'redux-saga/effects';
import { LOGIN_USER_DETAILS, LOGIN_USER_DETAILS_FAILURE, LOGIN_USER_DETAILS_SUCCESS } from "../constants/Action";

export function* LoginUserDetailsMiddleware({ payload }) {
    try {
        yield put({
            type: LOGIN_USER_DETAILS_SUCCESS,
            payload: payload
        });
    } catch (err) {
        yield put({
            type: LOGIN_USER_DETAILS_FAILURE,
            payload: err.message
        });
        console.log(err);
    }
}

export default function* LoginUserDetailsMiddlewareWatcher() {
    yield takeEvery(LOGIN_USER_DETAILS, LoginUserDetailsMiddleware);

}