import { fromJS } from "immutable";
import {
  LOGIN_USER_DETAILS_SUCCESS,
  LOGIN_USER_DETAILS_FAILURE
} from "../constants/Action";

const initialState = fromJS({
  userLoginDetails: null,
  userFollowUserList: [],
  userFollowCliksList: [],
  userFollowTopicsList: [],
  userFollowFeedList: []
});

export default function LoginUserDetailsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
      case LOGIN_USER_DETAILS_SUCCESS:
          return {
          ...state,
          userLoginDetails:payload.data,
          userFollowUserList: payload.userfollow,
          userFollowCliksList: payload.clikfollow,
          userFollowTopicsList:payload.topicfollow ,
          userFollowFeedList: payload.externalfollow  
        }
      default:
        return state
  }
  // switch (type) {
  //   case LOGIN_USER_DETAILS_SUCCESS:
  //     console.log(payload, 'payload');
  //     return state
  //       .set("userLoginDetails", fromJS(payload))
  //       .set(
  //         "userFollowUserList",
  //         fromJS(payload.account.my_users[0].users_followed)
  //       )
  //       .set(
  //         "userFollowCliksList",
  //         fromJS(payload.account.my_users[0].cliks_followed)
  //       )
  //       .set(
  //         "userFollowTopicsList",
  //         fromJS(payload.account.my_users[0].topics_followed)
  //       )
  //       .set(
  //         "userFollowFeedList",
  //         fromJS(payload.account.my_users[0].feeds_followed)
  //       );

  //   case LOGIN_USER_DETAILS_FAILURE:
  //     return initialState;
  //   case "LOGIN_USER_DETAILS_RESET":
  //     return initialState;
  //   case "SET_TOPIC_FOLLOW_LIST": {
  //     return state.set("userFollowTopicsList", payload);
  //   }
  // }
  // return state;
}
