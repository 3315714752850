import { HIGHLIGHT_ID ,USER_BOLD} from '../constants/Action';


const initialState = {
    // id: '',
    userHighlight:''

}

const UserHighlight = (state = initialState, action) => {
    switch (action.type) {
     
        case USER_BOLD: {
            console.log(action.payload, 'payloaddd');
            return {
                userHighlight: action.payload,
            };
        }
        default:
            return state;
    }
};

export default UserHighlight;