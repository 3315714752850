import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { retry } from "../library/Helper";
import {
  Dimensions,
  View,
  Platform,
  TouchableOpacity,
  Image,
  Text,
  YellowBox,
  // AsyncStorage,
  Animated,
  ScrollView,
  TextInput
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { MenuProvider, Menu, MenuTrigger } from "react-native-popup-menu";
import { connect } from "react-redux";
import { compose } from "recompose";
import NavigationService from "../library/NavigationService";
import AppNavigator from "../Navigation/AppNavigator";
import ConstantColors from '../constants/Colors'
import ButtonStyle from "../constants/ButtonStyle";
import { get404, windowResize } from "../reducers/action/Completed";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { Icon } from "react-native-elements";
// import "@expo/match-media";
import ConstantFontFamily from "../constants/FontFamily";
import { Hoverable } from "react-native-web-hooks";
import MediaQuery from 'react-responsive';
import { screen } from '../reducers/action/Completed';


const HeaderRight = lazy(() =>
  retry(() => import("../components/HeaderRight"))
);

const SearchInputWeb = lazy(() =>
  retry(() => import("./SearchInputWeb"))
);

const LeftPanel = lazy(() =>
  retry(() => import("./LeftPanel"))
);

const SearchSuggestion = lazy(() =>
  retry(() => import("./SearchSuggestion"))
);


console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
console.disableYellowBox = true;
console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
YellowBox.ignoreWarnings(["Warning: ReactNative.createElement"]);
console.ignoredYellowBox = ["Warning: ReactNative.createElement"];
console.error;
YellowBox.ignoreWarnings([
  "Warning: componentWillMount is deprecated",
  "Warning: componentWillReceiveProps is deprecated",
  "Warning: componentWillReceiveProps has been renamed, and is not recommended for use."
]);


const NavigationComponent = (props) => {
  // constructor(props) {
  //   super(props);
  //   state = {
  //     url: "",
  //     textInputFocus: false
  //   };
  //   nameInput = React.createRef()
  // }

  const nameInput = useRef()

  const [textInputFocus, settextInputFocus] = useState(false)
  const [showSearchIcon, setShowSearchIcon] = useState(false)
  const [url, setUrl] = useState('')
  const [term, setTerm] = useState('')
  const [boxColor, setBoxColor] = useState('')


  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });


  const handleChangeFocus = () => {
    // setState({ textInputFocus: true })
    settextInputFocus(true)
  }

  // const handleChangeFocus1 = () => {
  //   setState({ textInputFocus: falseu })
  // }



  // componentDidMount = async () => {
  //   setState({ url: window.location.href });
  //   YellowBox.ignoreWarnings(["Warning: ReactNative.createElement"]);
  //   console.ignoredYellowBox = ["Warning: ReactNative.createElement"];
  //   if (
  //     window.location.href == "http://localhost:19006/404" ||
  //     window.location.href == "https://localhost:19006/404" ||
  //     window.location.href ==
  //     "http://electric-block-241402.appspot.com/404" ||
  //     window.location.href ==
  //     "https://electric-block-241402.appspot.com/404" ||
  //     window.location.href == "https://weclikd-beta.com/404" ||
  //     window.location.href == "http://weclikd-beta.com/404" ||
  //     window.location.href == "https://electric-block-241402.appspot.com/404"
  //   ) {
  //     props.get404(true)
  //   } else {
  //     props.get404(false)
  //   }

  //   // window.addEventListener('resize', GetResizeStatus)


  // };

  useEffect(() => {
    YellowBox.ignoreWarnings(["Warning: ReactNative.createElement"]);
    console.ignoredYellowBox = ["Warning: ReactNative.createElement"];
    if (
      window.location.href == "http://localhost:19006/404" ||
      window.location.href == "https://localhost:19006/404" ||
      window.location.href ==
      "http://electric-block-241402.appspot.com/404" ||
      window.location.href ==
      "https://electric-block-241402.appspot.com/404" ||
      window.location.href == "https://weclikd-beta.com/404" ||
      window.location.href == "http://weclikd-beta.com/404" ||
      window.location.href == "https://electric-block-241402.appspot.com/404"
    ) {
      props.get404(true)
    } else {
      props.get404(false)
    }
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      props.windowResize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // let route = props.navigation.getCurrentRoute()

  // console.log(route, 'winnn');

  useEffect((prevProps) => {
    // console.log(prevProps, 'prevProps');
  })


  const goToScrollTop = () => {
    props.setHASSCROLLEDACTION(true);
    props.setTermWeb('');
    props.setTerm('');
  };


  return (
    <View
      style={{
        height: "100%",
        backgroundColor: ConstantColors.whiteBackground,
        width: "100%",

      }}
    // onResize={GetResizeStatus()}
    >
      <MenuProvider>
        {
          windowSize.width <= 750 && (


            <Animated.View
              style={{
                position: Platform.OS == "web" ? "sticky" : null,
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                overflow: "hidden",
                borderRadius: 0,
              }}
            >
              <View
                style={{
                  height: 50,
                  backgroundColor: '#000'
                }}
              >
                {props.loginStatus == 1 ?
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#000'
                  }}>
                    {(props.screenName == "createPost" || props.screenName == "createClik" || props.screenName == "addFeed" || props.screenName == "createUser" || props.screenName == "createTopic") && <><TouchableOpacity
                      style={ButtonStyle.headerBackStyle}
                      onPress={() => {
                        props.searchOpenBarStatus(false);
                        // props.screen('home')s
                        props.navigation.goBack();
                        // let nav =
                        //   this.props.navigation.dangerouslyGetParent().state;
                        // if (nav.routes.length > 1) {
                        //   this.props.navigation.goBack();
                        //   return;
                        // } else {
                        //   this.props.navigation.navigate("home");
                        // }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>

                      {!props.getsearchBarStatus && (
                        <TouchableOpacity
                          style={[
                            ButtonStyle.headerTitleStyle,
                            { backgroundColor: "#000" },
                          ]}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: 18,
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            {props.screenName == "createPost" ? "Create Post" : props.screenName == "createClik" ? "Create Clik" : props.screenName == "addFeed" ? "Add Feed" : props.screenName == "createUser" ? "ivite friend" : props.screenName == "createTopic" ? "Propose Topic" : null}
                          </Text>
                        </TouchableOpacity>
                      )}</>}
                    {props.screenName == "faq" && <><TouchableOpacity
                      style={{
                        // flex: 1,
                        flexDirection: "row",
                        backgroundColor: "#000",
                        height: 50,
                        marginLeft: 5,
                        alignItems: 'center'
                      }}
                      onPress={() => {
                        props.searchOpenBarStatus(false);
                        // props.screen('home')
                        props.navigation.goBack();
                        // let nav = props.navigation.dangerouslyGetParent()
                        //   .state;
                        // if (nav.routes.length > 1) {
                        //   props.navigation.goBack();
                        //   return;
                        // } else {
                        //   props.navigation.navigate("home");
                        // }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          flex: 1,
                          backgroundColor: "#000",
                          justifyContent: "center",
                          marginLeft: 10,
                          borderRadius: 6
                        }}
                      >
                        <Text
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 18,
                            fontFamily: ConstantFontFamily.defaultFont
                          }}
                        >
                          FAQ
                        </Text>
                      </TouchableOpacity></>}
                    {props.screenName == "privacyScreen" && <><TouchableOpacity
                      style={{
                        // flex: 1,
                        flexDirection: "row",
                        backgroundColor: "#000",
                        height: 50,
                        marginLeft: 5,
                        alignItems: 'center'
                      }}
                      onPress={() => {
                        props.searchOpenBarStatus(false);
                        // props.screen('home')
                        props.navigation.goBack();
                        // let nav = props.navigation.dangerouslyGetParent()
                        //   .state;
                        // if (nav.routes.length > 1) {
                        //   props.navigation.goBack();
                        //   return;
                        // } else {
                        //   props.navigation.navigate("home");
                        // }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          flex: 1,
                          backgroundColor: "#000",
                          justifyContent: "center",
                          marginLeft: 10,
                          borderRadius: 6
                        }}
                      >
                        <Text
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: 18,
                            fontFamily: ConstantFontFamily.defaultFont
                          }}
                        >
                          Privacy Policy
                        </Text>
                      </TouchableOpacity></>}
                    {props.screenName == "search" && (
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          backgroundColor: "#000",
                          height: 50,
                          alignItems: "center",
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            // flex: 1,
                            flexDirection: "row",
                            backgroundColor: windowSize.width <= 750 ? "f4f4f4" : "#000",
                            height: 50,
                            marginLeft: 5,
                            alignItems: 'center'
                          }}
                          onPress={() => {
                            props.searchOpenBarStatus(false);
                            // props.screen('home')
                            props.navigation.goBack();
                            // let nav = props.navigation.dangerouslyGetParent()
                            //   .state;
                            // if (nav.routes.length > 1) {
                            //   props.navigation.goBack();
                            //   return;
                            // } else {
                            //   props.navigation.navigate("home");
                            // }
                          }}
                        >
                          <Icon
                            color={"#fff"}
                            name="angle-left"
                            type="font-awesome"
                            size={40}
                          />
                        </TouchableOpacity>
                        <View
                          style={{
                            backgroundColor: "#fff",
                            width:
                              windowSize.width <= 750
                                ? windowSize.width - 70
                                : windowSize.width >= 750 &&
                                  windowSize.width <= 1200
                                  ? windowSize.width - 100
                                  : 445,
                            height: 34,
                            borderRadius: 0,
                            flexDirection: "row",
                            borderColor: "#388bfd",
                            borderWidth: 0,
                            borderRadius: 6,
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingHorizontal: "2%",
                            marginLeft: 10
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              width: "90%",
                              alignItems: "center",
                            }}
                          >
                            <Icon
                              iconStyle={{ marginRight: "auto" }}
                              name="search"
                              color={"#333333"}
                              size={18}
                              type="font-awesome"
                            />
                            <TextInput
                              style={{
                                width: "90%",
                                paddingLeft: 15,
                                height: 32,
                                borderColor: "#388bfd",
                                borderWidth: 0,
                                borderRadius: 6,
                                backgroundColor: "#fff",
                                outline: "none",
                                color: "#000",
                                fontSize: 14,
                                fontFamily: ConstantFontFamily.defaultFont,
                              }}
                              nameInput={props.nameInput}
                              placeholder="Search Weclikd"
                              onChangeText={(term) => {
                                setTerm(term);
                                props.setTermWeb(term);
                              }}
                              value={
                                props.termWeb ? props.termWeb : term
                              }
                              onSubmitEditing={() => {
                                // this.getSuggestion(this.state.term);
                                props.setTermWeb(term);
                                props.setTerm(term);
                              }}
                              onFocus={() =>
                                setBoxColor('#fff')
                              }
                              returnKeyType={"search"}
                            />
                          </View>

                          {windowSize.width <= 1200 && (
                            <Icon
                              name="times"
                              onPress={() => {
                                props.setTerm("");
                                props.setTermWeb("");
                                // this.setState({ term: "" });
                                setTerm('')
                              }}
                              size={20}
                              type="font-awesome"
                            />
                          )}
                        </View>
                      </View>
                    )}
                    {props.screenName == "home" && <TouchableOpacity
                      onPress={goToScrollTop}
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        backgroundColor: windowSize.width <= 750 ? "f4f4f4" : "#000",
                        height: 50,
                        marginLeft: 5,
                        alignItems: 'center'
                      }}
                    >
                      <Image
                        source={require("../assets/image/logolastOne.png")}
                        style={{
                          marginRight: 5,
                          alignSelf: "center",
                          height: 35,
                          width: 35,
                          borderRadius: 8
                        }}
                        resizeMode={"contain"}
                      />
                      {!props.getsearchBarStatus &&
                        <Text
                          style={[ButtonStyle.profileTitleStyle, {
                            fontSize: 20,
                            textAlign: "center",
                            color: "white",
                            fontWeight: 'bold'
                          }]}
                        >
                          weclikd
                        </Text>}
                    </TouchableOpacity>}
                    {(props.screenName == "notificationScreen" || props.screenName == "termsScreen" || props.screenName == "analytic") && <> <TouchableOpacity
                      style={{
                        // flex: 1,
                        flexDirection: "row",
                        backgroundColor: windowSize.width <= 750 ? "f4f4f4" : "#000",
                        height: 50,
                        marginLeft: 5,
                        alignItems: 'center'
                      }}
                      onPress={() => {
                        props.searchOpenBarStatus(false)
                        // props.screen('home')
                        props.navigation.goBack()

                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>
                      {!props.getsearchBarStatus &&
                        <TouchableOpacity
                          // style={{justifyContent:'center'}}
                          style={[ButtonStyle.headerTitleStyle, { backgroundColor: '#000', marginLeft: 10 }]}
                        >
                          <Text
                            style={{
                              textAlign: 'center',
                              color: "#fff",
                              fontWeight: "bold",
                              fontSize: 18,
                              fontFamily: ConstantFontFamily.defaultFont
                            }}
                          >
                            {props.screenName == "notificationScreen" ? 'Notifications' :props.screenName == "analytic"? "Analytics": props.screenName == "termsScreen" ? "Terms And Condition" : null}
                          </Text>
                        </TouchableOpacity>
                      }</>}

                    {props.screenName == "clikScreen" &&
                      <>
                        <TouchableOpacity
                          style={{
                            // flex: 1,
                            flexDirection: "row",
                            backgroundColor: "#000",
                            height: 50,
                            marginLeft: 5,
                            // alignItems: 'center'
                          }}
                          onPress={() => {
                            props.searchOpenBarStatus(false);
                            props.navigation.goBack();
                            props.screen("home")
                            // let nav =
                            //   props.navigation.dangerouslyGetParent().state;
                            // if (nav.routes.length > 1) {
                            //   if (state.cliksselectItem == "USERS") {
                            //     setState({
                            //       cliksselectItem: "FEED",
                            //     });
                            //   } else {
                            //     props.navigation.goBack();
                            //   }
                            // } else {
                            //   props.navigation.navigate("home");
                            // }
                          }}
                        >
                          <Icon
                            color={"#fff"}
                            name="angle-left"
                            type="font-awesome"
                            size={40}
                          />
                        </TouchableOpacity>
                        {props.cliksDetails.size > 0 &&
                          !props.getsearchBarStatus && (
                            <TouchableOpacity
                              style={{
                                flex: props.loginStatus
                                  ? 1
                                  : !props.loginStatus && Platform.OS == "web"
                                    ? 1
                                    : !props.loginStatus &&
                                    Platform.OS != "web" &&
                                    0,
                                flexDirection: "row",
                                alignSelf: "center",
                                alignItems: "center",
                                marginRight: 5,
                                marginLeft: 10
                              }}
                            >
                              {/* {props.loginStatus == 1 &&
                                // state.showIcon.toString().startsWith("#") 
                                (
                                  <Image
                                    // source={state.showIcon}
                                    style={{
                                      width: 22,
                                      height: 22,
                                    }}
                                  />
                                )} */}
                              <TouchableOpacity
                                style={{
                                  paddingVertical: 5,
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onPress={() =>
                                  CliksProfilescrollview.scrollTo({
                                    x: 0,
                                    y: 0,
                                    animated: true,
                                  })
                                }
                              >
                                <View
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: 30,
                                    paddingVertical: 5,
                                    backgroundColor: "#000",
                                    borderRadius: 6,
                                    alignSelf: "flex-start",
                                    marginBottom: 5,
                                    marginLeft: 5
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontWeight: "bold",
                                      fontSize: 18,
                                      fontFamily: ConstantFontFamily.defaultFont,
                                    }}
                                  >
                                    #
                                    {props.cliksDetails
                                      .getIn(["data", "node"]).get('name')}
                                  </Text>
                                </View>
                              </TouchableOpacity>

                            </TouchableOpacity>
                          )}
                      </>
                    }
                    {props.screenName == "topicScreen" && <><TouchableOpacity
                      style={{
                        // flex: 1,
                        flexDirection: "row",
                        backgroundColor: windowSize.width <= 750 ? "f4f4f4" : "#000",
                        height: 50,
                        marginLeft: 5,
                        alignItems: 'center'
                      }}
                      onPress={() => {
                        props.searchOpenBarStatus(false);
                        props.screen("home")
                        props.navigation.goBack();

                        //   let nav =
                        //     props.navigation.dangerouslyGetParent().state;
                        //   if (nav.routes.length > 1) {
                        //     props.navigation.goBack();
                        //     return;
                        //   } else {
                        //     props.navigation.navigate("home");
                        //   }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>
                      {props.topicsDetails.size != 0 &&
                        !props.getsearchBarStatus && (
                          <TouchableOpacity
                            style={{
                              flex: props.loginStatus
                                ? 1
                                : !props.loginStatus && Platform.OS == "web"
                                  ? 1
                                  : !props.loginStatus &&
                                  Platform.OS != "web" &&
                                  0,
                              flexDirection: "row",
                              alignSelf: "center",
                              alignItems: "center",
                              marginLeft: 10

                            }}
                          >
                            <View
                              style={{
                                paddingVertical: 5,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <TouchableOpacity
                                style={{
                                  // backgroundColor: "#e3f9d5",
                                  borderRadius: 6,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: 5,
                                  height: 30,
                                  alignSelf: "center",
                                  paddingHorizontal: 5,
                                }}
                                onPress={() =>
                                  TopicsProfilescrollview.scrollTo({
                                    x: 0,
                                    y: 0,
                                    animated: true,
                                  })
                                }
                              >
                                <Hoverable>
                                  {(isHovered) => (
                                    <Text
                                      style={{
                                        color: "#fff",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        fontFamily: ConstantFontFamily.defaultFont,
                                        textDecorationLine:
                                          isHovered == true ? "underline" : "none",
                                      }}
                                    >
                                      {"/"}
                                      {props.topicsDetails.getIn(["data", "node"]).get("name")}
                                    </Text>
                                  )}
                                </Hoverable>
                              </TouchableOpacity>
                            </View>
                          </TouchableOpacity>
                        )}
                    </>
                    }
                    {props.screenName == 'profileScreen' && <>
                      <TouchableOpacity
                        style={{
                          // flex: 1,
                          flexDirection: "row",
                          backgroundColor: windowSize.width <= 750 ? "f4f4f4" : "#000",
                          height: 50,
                          marginLeft: 5,
                          // alignItems: 'center'
                        }}
                        onPress={() => {
                          props.searchOpenBarStatus(false);
                          props.screen("home")
                          props.navigation.goBack();
                          // let nav =
                          //   props.navigation.dangerouslyGetParent().state;
                          // if (nav.routes.length > 1) {
                          //   props.navigation.goBack();
                          //   return;
                          // } else {
                          //   props.navigation.navigate("home");
                          // }
                        }}
                      >
                        <Icon
                          color={"#fff"}
                          name="angle-left"
                          type="font-awesome"
                          size={40}
                        />
                      </TouchableOpacity>
                      {!props.getsearchBarStatus && (
                        <TouchableOpacity
                          style={{
                            flex: props.loginStatus
                              ? 1
                              : !props.loginStatus && Platform.OS == "web"
                                ? 1
                                : !props.loginStatus &&
                                Platform.OS != "web" &&
                                0,

                            flexDirection: "row",
                            alignSelf: "center",
                            alignItems: "center",
                            marginLeft: 10
                          }}
                        >

                          <Text
                            style={{
                              textAlign: "center",
                              alignSelf: "center",
                              color: "#fff",
                              fontWeight: "bold",
                              fontSize: 18,
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            {"@" + props.userDetails.getIn(["node", "full_name"])}
                          </Text>


                        </TouchableOpacity>
                      )}</>}

                    {props.screenName == 'feedScreen' && <><TouchableOpacity
                      style={{
                        // flex: 1,
                        flexDirection: "row",
                        backgroundColor: windowSize.width <= 750 ? "f4f4f4" : "#000",
                        height: 50,
                        marginLeft: 5,
                        // alignItems: 'center'
                      }}
                      onPr
                      onPress={() => {
                        props.searchOpenBarStatus(false);
                        // props.screen('home')
                        props.navigation.goBack();
                        // let nav =
                        //   props.navigation.dangerouslyGetParent().state;
                        // if (nav.routes.length > 1) {
                        //   props.navigation.goBack();
                        //   return;
                        // } else {
                        //   props.navigation.navigate("home");
                        // }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>{props.feedDetails.size != 0 &&
                      !props.getsearchBarStatus && (
                        <TouchableOpacity
                          style={{
                            flex: props.loginStatus
                              ? 1
                              : !props.loginStatus && Platform.OS == "web"
                                ? 1
                                : !props.loginStatus &&
                                Platform.OS != "web" &&
                                0,
                            flexDirection: "row",
                            alignSelf: "center",
                            alignItems: "center",
                            marginLeft: 10
                          }}

                        >
                          <Text
                            style={{
                              color: "#fff",
                              fontWeight: "bold",
                              fontSize: 18,
                              fontFamily: ConstantFontFamily.defaultFont,
                              flex: props.loginStatus
                                ? 1
                                : !props.loginStatus &&
                                  Dimensions.get("window").width <= 750
                                  ? 1
                                  : 0,
                              flexWrap: "wrap",
                              flexShrink: 1,
                            }}
                          >
                            {props.feedDetails.getIn(["data", "node"]).get("name")}
                          </Text>

                        </TouchableOpacity>
                      )}</>}

                    {props.screenName == "postDetail" && <><TouchableOpacity
                      style={{
                        // flex: 1,
                        flexDirection: "row",
                        backgroundColor: windowSize.width <= 750 ? "f4f4f4" : "#000",
                        height: 50,
                        marginLeft: 5,
                        alignItems: 'center'
                      }}
                      onPress={() => {
                        props.searchOpenBarStatus(false);
                        // props.screen('home')
                        props.navigation.goBack();
                        // let nav =
                        //   props.navigation.dangerouslyGetParent().state;
                        // props.showComment(false);
                        // if (nav.routes.length > 1) {
                        //   props.navigation.goBack();
                        //   return;
                        // } else {
                        //   props.navigation.navigate("home");
                        // }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>
                      {!props.getsearchBarStatus && (
                        <TouchableOpacity
                          style={{
                            flex: props.loginStatus
                              ? 1
                              : !props.loginStatus && Platform.OS == "web"
                                ? 1
                                : !props.loginStatus &&
                                Platform.OS != "web" &&
                                0,
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              fontWeight: "bold",
                              fontSize: 18,
                              marginLeft: 5,
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            Discussions
                          </Text>
                        </TouchableOpacity>
                      )}</>}

                    {props.getsearchBarStatus ?
                      <View style={{ width: '85%', marginHorizontal: 20 }}>
                        <Suspense fallback={null}>

                          <SearchInputWeb
                            navigation={props.navigation}
                            refs={ref => {
                              let input = ref;
                            }}
                            displayType={"web"}
                            press={status => {
                              // setState({ showSearchIcon: status });
                              setShowSearchIcon(status)
                            }}
                            nameInput={nameInput}
                          />
                        </Suspense>

                      </View>
                      :
                      <Suspense fallback={null}>

                        <HeaderRight navigation={props.navigation} />
                      </Suspense>}

                  </View>
                  :
                  <View style={{ flexDirection: 'row', backgroundColor: '#000', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 10 }}>

                    <TouchableOpacity
                      onPress={goToScrollTop}
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        backgroundColor: windowSize.width <= 750 ? "f4f4f4" : "#000",
                        height: 50,
                        marginLeft: 5,
                        alignItems: 'center'
                      }}
                    >
                      <Image
                        source={require("../assets/image/logolastOne.png")}
                        style={{
                          marginRight: 5,
                          alignSelf: "center",
                          height: 35,
                          width: 35,
                          borderRadius: 8
                        }}
                        resizeMode={"contain"}
                      />
                      {!props.getsearchBarStatus &&
                        <Text
                          style={[ButtonStyle.profileTitleStyle, {
                            fontSize: 20,
                            textAlign: "center",
                            color: "white",
                            fontWeight: 'bold'
                          }]}
                        >
                          weclikd
                        </Text>
                      }
                    </TouchableOpacity>
                    {!props.getsearchBarStatus ?
                      <Icon
                        name="search"
                        size={25}
                        type="feather"
                        // iconStyle={styles.actionButtonIcon}
                        iconStyle={{
                          color: windowSize.width <= 750 ? "#fff" : "#fff",
                          marginRight: windowSize.width <= 750 ? 20 : 30,
                        }}
                        color="#fff"
                        underlayColor="#000"
                        onPress={() => {
                          // setState({ showSearchIcon: false });
                          setShowSearchIcon(false)
                          props.setSearchBarStatus(true);
                          props.searchOpenBarStatus(true);
                        }}
                      />
                      :
                      <View style={{ width: '85%', backgroundColor: '#000' }}>
                        <Suspense fallback={null}>

                          <SearchInputWeb
                            navigation={props.navigation}
                            refs={ref => {
                              let input = ref;
                            }}
                            displayType={"web"}
                            press={status => {
                              // setState({ showSearchIcon: status });
                              setShowSearchIcon(status)
                            }}
                            nameInput={nameInput}
                          />
                        </Suspense>

                      </View>
                    }

                    {
                      windowSize.width <= 1200 &&
                      !props.getsearchBarStatus && (
                        <View>
                          <Menu>
                            <MenuTrigger testID="ProfileIcon" onPress={() => { props.leftPanelModalFunc(true) }}>
                              <Image
                                source={require("../assets/image/default-image.png")}
                                style={{
                                  height: 27,
                                  width: 27,
                                  borderRadius: 13,
                                  borderWidth: 1,
                                  alignSelf: 'center',
                                  borderColor: windowSize.width <= 750 ? "#fff" : "#fff",
                                }}
                                navigation={props.navigation}
                              />
                            </MenuTrigger>
                          </Menu>
                        </View>

                      )}

                  </View>
                }
              </View>
            </Animated.View >

          )}
        {
          windowSize.width >= 750 &&

          <View
            style={{
              width: "100%",
              flexDirection: "row",
              height: 50,
              backgroundColor: '#000',
              alignItems: 'center',
              // paddingRight: 10,
            }}
          >

            <View
              testID="HomeLogo"

              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                display:
                  windowSize.width >= 750 &&
                    Platform.OS == "web"
                    ? "flex"
                    : "none",
                alignItems: "center",
                width: windowSize.width <= 750 ? '100%' :
                  windowSize.width >= 750 && windowSize.width <= 1200 ? '35%' : 310,
                // windowSize.width <= 750 ? '96%' :
                //   windowSize.width >= 750 && windowSize.width <= 1200 ? '35%' :
                //     windowSize.width >= 1200 && windowSize.width <= 1200 ? '50%' : '19%',
                // paddingLeft: 7,
                // marginRight: 15,
                // marginLeft: 25,
                // paddingLeft: 10,
              }}
            >
              <TouchableOpacity style={{ width:60, justifyContent: 'center', height: 50, alignItems: 'center', }}
                onPress={() => {
                  NavigationService.navigate("home")
                  // window.location.reload();
                  props.screen('home')
                  props.setTermWeb('');
                  props.setTerm('');
                  props.get404(false)
                }}>
                <Image
                  source={require("../assets/image/favIcon.png")}
                  style={{
                    // justifyContent: "flex-start",
                    height: 35,
                    width: 28,
                    // marginRight: 10,
                    borderRadius: 8
                  }}
                  resizeMode={"contain"}
                />
              </TouchableOpacity>
              {/* {
                <Text
                  style={[ButtonStyle.profileTitleStyle, {
                    fontSize: 20,
                    textAlign: "center",
                    color: "white",
                    fontWeight: 'bold'
                  }]}
                >
                  weclikd
                </Text>
              } */}
            </View>



            <View
              style={{
                flexDirection: 'row',
                width:
                  windowSize.width >= 1200
                    ? (windowSize.width - 310) : windowSize.width >= 750 && windowSize.width <= 1200 ? '65%' : '58%',
                // marginLeft: 5
              }}
            >
              {windowSize.width >= 1200 &&

                <View style={{ width: '50%', alignSelf: 'center', }}>
                  <Suspense fallback={null}>

                    <SearchInputWeb
                      navigation={props.navigation}
                      refs={ref => {
                        let input = ref;
                      }}
                      key={Math.random()}
                      displayType={"web"}
                      nameInput={nameInput}
                      textInputFocus={textInputFocus}
                      handleChangeFocus={handleChangeFocus}
                    />
                  </Suspense>

                </View>
              }
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: windowSize.width >= 750 && windowSize.width <= 1200 ? '100%' : '49%',
                  // marginLeft: 15
                }}
              >
                <Suspense fallback={null}>
                  <HeaderRight navigation={NavigationService} />
                </Suspense>
              </View>
            </View>
          </View>

        }

        <View
          style={{
            width: "100%",
            // flex: 1,
            flexDirection: "row",
            height: windowSize.width <= 750 ? hp('100%') : windowSize.height - 50,
            // justifyContent: "center",
            backgroundColor: ConstantColors.whiteBackground,
          }}
        >
          {windowSize.width > 750 && props.is404 == false && (

            <Suspense fallback={null}>

              <LeftPanel
                ref={navigatorRef => {
                  NavigationService.setTopLevelNavigator(navigatorRef);
                }}
                navigation={NavigationService}
              />
            </Suspense>

          )}

          <View
            style={{
              // backgroundColor: 'red',
              width: windowSize.width <= 750 ? '100%' : windowSize.width - 310,
              paddingLeft: 0,
              justifyContent: "center",
              height: windowSize.height - 50
            }}
          >
            <View style={{
              alignSelf: "flex-start",
              width: windowSize.width >= 1200 ? "50%" : "89%",
              marginLeft:
                Platform.OS == "web"
                  ? windowSize.width >= 750
                    ? 0
                    : 10
                  : "5%",
              position: "absolute",
              top: 0,
              zIndex: 9,


            }}>
              <ScrollView showsVerticalScrollIndicator={false}>
                <Suspense fallback={null}>

                  <SearchSuggestion nameInput={nameInput} />
                </Suspense>
              </ScrollView>
            </View>
            <AppNavigator
              ref={navigatorRef => {
                NavigationService.setTopLevelNavigator(navigatorRef);
              }}
              windowSize={windowSize}
            />

          </View>

        </View>
      </MenuProvider >
    </View >
  );

}


const mapStateToProps = (state) => ({
  is404: state.get404Reducer.is404,
  trendingList: state.HomeFeedReducer.get("TrendingHomeFeedList"),
  newfeedList: state.HomeFeedReducer.get("NewHomeFeedList"),
  discussionList: state.HomeFeedReducer.get("DiscussionHomeFeedList"),

  getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  loginStatus: state.UserReducer.get("loginStatus"),
  isAdmin: state.AdminReducer.get("isAdmin"),
  isAdminView: state.AdminReducer.get("isAdminView"),


  PostDetails: state.PostDetailsReducer.get("PostDetails"),
  PostCommentDetails: state.PostCommentDetailsReducer.get("PostCommentDetails"),
  getsearchBarStatus: state.AdminReducer.get("searchBarOpenStatus"),
  getTabView: state.AdminReducer.get("tabType"),
  index1: state.FixIndexReducer.index1,
  isfeed: state.AdminReducer.get("isfeed"),
  PostId: state.PostCommentDetailsReducer.get("PostId"),
  PostDetails: state.PostDetailsReducer.get("PostDetails"),
  show: state.showDiscussionReducer.show,
  windowSize: state.windowResizeReducer.windowResize,
  cliksDetails: state.TrendingCliksProfileReducer.get(
    "getTrendingCliksProfileDetails"
  ),
  topicsDetails: state.TrendingTopicsProfileReducer.get(
    "getTrendingTopicsProfileDetails"
  ),
  userDetails: state.UserProfileDetailsReducer.get(
    "getCurrentUserProfileDetails"
  ),
  feedDetails: state.FeedProfileReducer.get("getFeedProfileDetails"),
  screenName: state.screenNameReducer.screen

});

const mapDispatchToProps = (dispatch) => ({
  get404: (payload) => dispatch(get404(payload)),
  windowResize: (payload) => dispatch(windowResize(payload)),
  leftPanelModalFunc: payload => dispatch({ type: 'LEFT_PANEL_OPEN', payload }),
  setHASSCROLLEDACTION: payload => dispatch(setHASSCROLLEDACTION(payload)),
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
  screen: (payload) => dispatch(screen(payload)),
  setTerm: (payload) => dispatch({ type: "SET_TERM", payload }),
  setTermWeb: (payload) => dispatch({ type: "SET_TERM_WEB", payload }),
})


export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NavigationComponent
);

