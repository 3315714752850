import { INDEXTAB_SUCCESS } from '../constants/Action';


const initialState = {
    index: 0
}

const IndexTabReducer = (state = initialState, action) => {
    switch (action.type) {
        case INDEXTAB_SUCCESS: {
            return {
                index: action.payload,
            };
        }
        default:
            return state;
    }
};

export default IndexTabReducer;