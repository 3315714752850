import React, { Component, lazy, Suspense } from "react";
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Image
} from "react-native";
import { TabBar, TabView } from "react-native-tab-view";
import ShadowSkeletonStar from "../components/ShadowSkeletonStar";
import ConstantFontFamily from "../constants/FontFamily";
import { retry } from "../library/Helper";
import { Button, Icon } from "react-native-elements";
import NavigationService from "../library/NavigationService";
import { connect } from "react-redux";
import { compose } from "recompose";
import ButtonStyle from "../constants/ButtonStyle";
// import { AsyncStorage } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from "react-native";
import Colors from "../constants/Colors";
import BottomPart from "./BottomPart";
import { Hoverable } from "react-native-web-hooks";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import { screen } from '../reducers/action/Completed'

// import CliksListDrawerScreens from './CliksListDrawerScreens';


const CliksListDrawerScreens = lazy(() =>
  retry(() => import("../components/CliksListDrawerScreens"))
);

const FeedListDrawerScreen = lazy(() =>
  retry(() => import("./FeedListDrawerScreen")))

const TopicsListDrawerScreens = lazy(() =>
  retry(() => import("./TopicsListDrawerScreens")))

const UserListDrawerScreens = lazy(() =>
  retry(() => import("./UserListDrawerScreens")))

class DrawerScreens extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    index: 0,
    searchedWord: this.props.searchedWord,
    cal: 0,
    FeedListDrawerScreensHeigth: 0,
    UserListDrawerScreensHeigth: 0,
    CliksListDrawerScreensHeigth: 0,
    TopicsListDrawerScreensHeigth: 0,
    routes: [
      { key: "first", title: "Cliks", icon: "users", type: "font-awesome" },
      { key: "second", title: "Topics", icon: "book", type: "font-awesome" },
      { key: "third", title: "Users", icon: "user", type: "font-awesome" },
      { key: "fourth", title: "Feeds", icon: "rss", type: "font-awesome" },
    ],
    loading: false,
    clikTextColor: '#fff',
    clikBtnColor: Colors.blueColor,
    topicBtnColor: Colors.blueColor,
    topicTextColor: '#fff',
    userBtnColor: Colors.blueColor,
    userTextColor: '#fff',
    feedBtnColor: Colors.blueColor,
    feedTextColor: '#fff',
    focus: "Cliks"
  };

  changeColor = (isHovered) => {
    if (isHovered == true) {
      switch (this.state.primaryIndex) {
        case 0:
          return "#009B1A";
        case 1:
          return "#4C82B6";
        case 2:
          return "#FEC236";
        default:
          return "#F34225";
      }
    }
  };

  styleChange = () => {
    if (this.state.index == 0) {
      return this.state.TopicsListDrawerScreensHeigth + 50;
    } else if (this.state.index == 1) {
      return this.state.CliksListDrawerScreensHeigth + 50;
    } else if (this.state.index == 2) {
      return this.state.UserListDrawerScreensHeigth + 50;
    } else if (this.state.index == 3) {
      return this.state.FeedListDrawerScreensHeigth + 50;
    }
  };

  searchTextField = (value) => {
    this.setState({ searchedWord: value });
  };

  _renderTabBar = (props) => (
    <View style={{ width: '15%', zIndex: 9999, backgroundColor: Colors.blueColor, marginRight: 15, }}>
      <TabBar
        {...props}

        indicatorStyle={{
          backgroundColor: "transparent",
          height: 1,
          borderRadius: 6,
        }}
        style={{
          backgroundColor: '#fff',
          shadowColor: "transparent",
          height: 60,
          width: '100%',

        }}
        contentContainerStyle={{
          flexDirection: 'column'
        }}
        // renderIcon={({ route, focused, color }) => (
        //   <Icon
        //     name={route.icon}
        //     type={route.type}
        //     color={focused ?
        //       route.title == 'Cliks' ? Colors.blueColor :
        //         route.title == 'Topics' ? Colors.greenColor :
        //           route.title == 'Users' ? '#000' :
        //             route.title == 'Feeds' && Colors.orangeColor : "#D3D3D3"}

        //   />

        // )}
        renderLabel={({ route, focused, color }) => {
          console.log(route, 'route')
          return (
            <View style={{ height: 50, width: 70, justifyContent: 'center', alignItems: 'center', backgroundColor: focused ? '#F2F3F5' : Colors.blueColor, flexDirection: 'column' }}>
              <Icon
                name={route.icon}
                type={route.type}
                color={focused ?
                  route.title == 'Cliks' ? Colors.blueColor :
                    route.title == 'Topics' ? Colors.greenColor :
                      route.title == 'Users' ? '#000' :
                        route.title == 'Feeds' && Colors.orangeColor : "#D3D3D3"}
              />

            </View>
          )
        }}
      />


    </View>
  );

  getSettingButtonState = (type) => {
    AsyncStorage.setItem("getSettingButtonState", true);
  };

  _handleIndexChange = (index) => {
    this.props.activeIndex(index);
    this.setState({ index, searchedWord: "" });
  };
  componentDidUpdate = async (prevProps) => {

    if (prevProps.searchedWord != this.props.searchedWord) {
      this.setState({ searchedWord: this.props.searchedWord });
    }
  };


  invitefriend = () => {
    this.getSettingButtonState("Invite Friends");
    this.props.leftPanelModalFunc(false);
    NavigationService.navigate("settings");
  }
  _renderLazyPlaceholder = ({ route }) => <ShadowSkeletonStar />;

  // _renderScene = ({ route }) => {

  //   switch (route.key) {
  //     case "fourth":
  //       return (
  //         <View
  //           onLayout={(event) => {
  //             let { height } = event.nativeEvent.layout;
  //             this.setState({
  //               FeedListDrawerScreensHeigth: height,
  //             });
  //           }}
  //           style={{
  //             // flex: 1
  //             width: '75%',
  //             height: '100%',
  //             paddingRight: 15
  //           }}
  //         >
  //           <View
  //             style={{
  //               width: "100%",
  //               height: 40,
  //               justifyContent: "flex-start",
  //               flexDirection: "row",
  //               alignItems: "center",
  //               borderRadius: 8,
  //               borderColor: '#f4f4f4',
  //               borderWidth: 1,
  //               paddingRight: "3%",
  //               marginBottom: 10,
  //               backgroundColor: '#fff'
  //             }}
  //           >
  //             <View style={{ width: "10%", marginRight: "auto" }}>
  //               <Icon name="search" size={18} type="font-awesome" />
  //             </View>

  //             <TextInput
  //               autoFocus={false}
  //               placeholder={this.state.index == 0 ? "Search Cliks" :
  //                 this.state.index == 1 ? "Search Topics" :
  //                   this.state.index == 2 ? 'Search User' :
  //                     this.state.index == 3 && "Search Feeds"}
  //               placeholderTextColor='#706969'
  //               onChangeText={(query) => this.searchTextField(query)}
  //               value={this.state.searchedWord}
  //               style={{
  //                 height: 40,
  //                 width: "74%",
  //                 paddingHorizontal: 10,
  //                 outline: "none",
  //                 position: "absolute",
  //                 left: "13%",
  //                 fontWeight: 'bold',
  //                 fontFamily: ConstantFontFamily.defaultFont,
  //                 color: Platform.OS == 'web' ? '#706969' : "#000"
  //               }}
  //             />
  //             {this.state.searchedWord ? (
  //               <TouchableOpacity
  //                 onPress={() => this.setState({ searchedWord: "" })}
  //                 style={{ marginLeft: "auto", width: "10%" }}
  //               >
  //                 <Icon name="close" size={18} type="font-awesome" />
  //               </TouchableOpacity>
  //             ) : null}
  //           </View>

  //           <ScrollView showsVerticalScrollIndicator={false}>
  //             <Suspense fallback={null}>
  //               <FeedListDrawerScreen
  //                 current={this.state.index}
  //                 navigation={this.props.navigation}
  //                 searchedWord={this.state.searchedWord}
  //               />
  //             </Suspense>
  //           </ScrollView>
  //           <Button
  //             onPress={() => {
  //               this.props.leftPanelModalFunc(false);
  //               return NavigationService.navigate("addfeed");
  //             }}
  //             color="#fff"
  //             title="ADD FEED"
  //             onMouseEnter={() => this.setState({ feedBtnColor: '#000', feedTextColor: '#fff' })}
  //             onMouseLeave={() => this.setState({ feedBtnColor: Colors.blueColor, feedTextColor: "#fff" })}
  //             titleStyle={[ButtonStyle.titleStyle, { color: this.state.feedTextColor }]}
  //             buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.feedBtnColor, paddingVertical: 7, borderWidth: 0 }]}
  //             containerStyle={ButtonStyle.containerStyle}
  //           />
  //           <BottomPart />
  //         </View>
  //       );
  //     case "third":
  //       return (
  //         <View
  //           onLayout={(event) => {
  //             let { height } = event.nativeEvent.layout;
  //             this.setState({
  //               UserListDrawerScreensHeigth: height,
  //             });
  //           }}
  //           style={{
  //             // flex: 1
  //             width: '75%',
  //             height: '100%',
  //             paddingRight: 15
  //           }}
  //         >
  //           <View
  //             style={{
  //               width: "100%",
  //               height: 40,
  //               justifyContent: "flex-start",
  //               flexDirection: "row",
  //               alignItems: "center",
  //               borderRadius: 8,
  //               borderColor: '#f4f4f4',
  //               borderWidth: 1,
  //               paddingRight: "3%",
  //               marginBottom: 10,
  //               backgroundColor: '#fff'
  //             }}
  //           >
  //             <View style={{ width: "10%", marginRight: "auto" }}>
  //               <Icon name="search" size={18} type="font-awesome" />
  //             </View>

  //             <TextInput
  //               autoFocus={false}
  //               placeholder={this.state.index == 0 ? "Search Cliks" :
  //                 this.state.index == 1 ? "Search Topics" :
  //                   this.state.index == 2 ? 'Search User' :
  //                     this.state.index == 3 && "Search Feeds"}
  //               placeholderTextColor='#706969'
  //               onChangeText={(query) => this.searchTextField(query)}
  //               value={this.state.searchedWord}
  //               style={{
  //                 height: 40,
  //                 width: "74%",
  //                 paddingHorizontal: 10,
  //                 paddingVertical: 10,
  //                 outline: "none",
  //                 position: "absolute",
  //                 left: "13%",
  //                 fontWeight: 'bold',
  //                 fontFamily: ConstantFontFamily.defaultFont,
  //                 color: Platform.OS == 'web' ? '#706969' : "#000"
  //               }}
  //             />
  //             {this.state.searchedWord ? (
  //               <TouchableOpacity
  //                 onPress={() => this.setState({ searchedWord: "" })}
  //                 style={{ marginLeft: "auto", width: "10%" }}
  //               >
  //                 <Icon name="close" size={18} type="font-awesome" />
  //               </TouchableOpacity>
  //             ) : null}
  //           </View>

  //           <ScrollView showsVerticalScrollIndicator={false}>
  //             <Suspense fallback={null}>
  //               <UserListDrawerScreens
  //                 current={this.state.index}
  //                 navigation={this.props.navigation}
  //                 searchedWord={this.state.searchedWord}
  //               />
  //             </Suspense>
  //           </ScrollView>
  //           <Button
  //             onPress={() => {
  //               this.props.leftPanelModalFunc(false);
  //               return NavigationService.navigate("settings");
  //             }}
  //             title="INVITE FRIENDS"
  //             onMouseEnter={() => this.setState({ userBtnColor: '#000', userTextColor: '#fff' })}
  //             onMouseLeave={() => this.setState({ userBtnColor: Colors.blueColor, userTextColor: "#fff" })}
  //             titleStyle={[ButtonStyle.titleStyle, { color: this.state.userTextColor }]}
  //             buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.userBtnColor, paddingVertical: 7, borderWidth: 0 }]}
  //             containerStyle={ButtonStyle.containerStyle}
  //           />
  //           <BottomPart />
  //         </View>
  //       );
  //     case "second":
  //       return (
  //         <View
  //           onLayout={(event) => {
  //             let { height } = event.nativeEvent.layout;
  //             this.setState({
  //               TopicsListDrawerScreensHeigth: height,
  //             });
  //           }}
  //           style={{
  //             // flex: 1,
  //             width: '75%',
  //             height: '100%',
  //             paddingRight: 15
  //           }}
  //         >
  //           <View
  //             style={{
  //               width: "100%",
  //               height: 40,
  //               justifyContent: "flex-start",
  //               flexDirection: "row",
  //               alignItems: "center",
  //               borderRadius: 8,
  //               borderColor: '#f4f4f4',
  //               borderWidth: 1,
  //               paddingRight: "3%",
  //               marginBottom: 10,
  //               backgroundColor: '#fff'
  //             }}
  //           >
  //             <View style={{ width: "10%", marginRight: "auto" }}>
  //               <Icon name="search" size={18} type="font-awesome" />
  //             </View>

  //             <TextInput
  //               autoFocus={false}
  //               placeholder={this.state.index == 0 ? "Search Cliks" :
  //                 this.state.index == 1 ? "Search Topics" :
  //                   this.state.index == 2 ? 'Search User' :
  //                     this.state.index == 3 && "Search Feeds"}
  //               placeholderTextColor='#706969'
  //               onChangeText={(query) => this.searchTextField(query)}
  //               value={this.state.searchedWord}
  //               style={{
  //                 height: 40,
  //                 width: "74%",
  //                 paddingHorizontal: 10,
  //                 paddingVertical: 10,
  //                 outline: "none",
  //                 position: "absolute",
  //                 left: "13%",
  //                 fontWeight: 'bold',
  //                 fontFamily: ConstantFontFamily.defaultFont,
  //                 color: Platform.OS == 'web' ? '#706969' : "#000"
  //               }}
  //             />
  //             {this.state.searchedWord ? (
  //               <TouchableOpacity
  //                 onPress={() => this.setState({ searchedWord: "" })}
  //                 style={{ marginLeft: "auto", width: "10%" }}
  //               >
  //                 <Icon name="close" size={18} type="font-awesome" />
  //               </TouchableOpacity>
  //             ) : null}
  //           </View>
  //           <ScrollView showsVerticalScrollIndicator={false}>
  //             <Suspense fallback={null}>
  //               <TopicsListDrawerScreens
  //                 navigation={this.props.navigation}
  //                 current={this.state.index}
  //                 searchedWord={this.state.searchedWord}
  //               />
  //             </Suspense>
  //           </ScrollView>
  //           {this.props.isAdmin == true && (
  //             <Button
  //               onPress={() => {
  //                 this.props.leftPanelModalFunc(false);
  //                 return NavigationService.navigate("createtopic");
  //               }}
  //               title="PROPOSE TOPIC"
  //               titleStyle={[ButtonStyle.titleStyle, { color: this.state.topicTextColor, }]}
  //               buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.topicBtnColor, paddingVertical: 7, borderWidth: 0 }]}
  //               containerStyle={ButtonStyle.containerStyle}
  //               onMouseEnter={() => this.setState({ topicBtnColor: '#000', topicTextColor: '#fff' })}
  //               onMouseLeave={() => this.setState({ topicBtnColor: Colors.blueColor, topicTextColor: "#fff" })}
  //             />
  //           )}

  //           {this.props.isAdmin == false && (
  //             <Button
  //               onPress={() => NavigationService.navigate("topichierarchy")}
  //               title="TOPIC HIERARCHY"
  //               onMouseEnter={() => this.setState({ topicBtnColor: '#000', topicTextColor: '#fff' })}
  //               onMouseLeave={() => this.setState({ topicBtnColor: Colors.blueColor, topicTextColor: "#fff" })}
  //               titleStyle={[ButtonStyle.titleStyle, { color: this.state.topicTextColor, }]}
  //               buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.topicBtnColor, paddingVertical: 7, borderWidth: 0 }]}
  //               containerStyle={ButtonStyle.containerStyle}
  //             />
  //           )}
  //           <BottomPart />

  //         </View>
  //       );
  //     case "first":
  //       return (
  //         <View
  //           onLayout={(event) => {
  //             let { height } = event.nativeEvent.layout;
  //             this.setState({
  //               CliksListDrawerScreensHeigth: height,
  //             });
  //           }}
  //           style={{
  //             // flex: 1,              
  //             width: '75%',
  //             height: '100%',
  //             paddingRight: 15
  //           }}
  //         >
  //           <View
  //             style={{
  //               width: "100%",
  //               height: 40,
  //               justifyContent: "flex-start",
  //               flexDirection: "row",
  //               alignItems: "center",
  //               borderRadius: 8,
  //               borderColor: '#f4f4f4',
  //               borderWidth: 1,
  //               paddingRight: "3%",
  //               marginBottom: 10,
  //               backgroundColor: '#fff'
  //             }}
  //           >
  //             <View style={{ width: "10%", marginRight: "auto" }}>
  //               <Icon name="search" size={18} type="font-awesome" />
  //             </View>

  //             <TextInput
  //               autoFocus={false}
  //               placeholder={this.state.index == 0 ? "Search Cliks" :
  //                 this.state.index == 1 ? "Search Topics" :
  //                   this.state.index == 2 ? 'Search User' :
  //                     this.state.index == 3 && "Search Feeds"}
  //               placeholderTextColor='#706969'
  //               onChangeText={(query) => this.searchTextField(query)}
  //               value={this.state.searchedWord}
  //               style={{
  //                 height: 40,
  //                 width: "74%",
  //                 paddingHorizontal: 10,
  //                 paddingVertical: 10,
  //                 outline: "none",
  //                 position: "absolute",
  //                 left: "13%",
  //                 fontWeight: 'bold',
  //                 fontFamily: ConstantFontFamily.defaultFont,
  //                 color: Platform.OS == 'web' ? '#706969' : "#000"
  //               }}
  //             />
  //             {this.state.searchedWord ? (
  //               <TouchableOpacity
  //                 onPress={() => this.setState({ searchedWord: "" })}
  //                 style={{ marginLeft: "auto", width: "10%" }}
  //               >
  //                 <Icon name="close" size={18} type="font-awesome" />
  //               </TouchableOpacity>
  //             ) : null}
  //           </View>
  //           <Suspense fallback={<ShadowSkeletonStar />}>

  //             <ScrollView showsVerticalScrollIndicator={false}>
  //               <CliksListDrawerScreens
  //                 current={this.state.index}
  //                 navigation={this.props.navigation}
  //                 searchedWord={this.state.searchedWord}
  //               />
  //             </ScrollView>
  //           </Suspense>

  //           <Button
  //             testID="newClik"
  //             onPress={() => {
  //               // if(this.props.NewHomeFeed.length>0){
  //               NavigationService.navigate("createclik");
  //               this.props.leftPanelModalFunc(false);
  //               // }

  //             }}
  //             onMouseEnter={() => this.setState({ clikBtnColor: '#000', clikTextColor: '#fff' })}
  //             onMouseLeave={() => this.setState({ clikBtnColor: Colors.blueColor, clikTextColor: "#fff" })}
  //             title="NEW CLIK"
  //             titleStyle={[ButtonStyle.titleStyle, { color: this.state.clikTextColor }]}
  //             buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.clikBtnColor, paddingHorizontal: 30, paddingVertical: 7, borderWidth: 0 }]}
  //             containerStyle={[ButtonStyle.containerStyle, { marginLeft: 0 }]}
  //           />
  //           <BottomPart />

  //         </View>
  //       );
  //     default:
  //       return (
  //         <View
  //           onLayout={(event) => {
  //             let { height } = event.nativeEvent.layout;
  //             this.setState({
  //               CliksListDrawerScreensHeigth: height,
  //             });
  //           }}
  //           style={{
  //             // flex: 1,              
  //             width: '75%',
  //             height: '100%',
  //             paddingRight: 15
  //           }}
  //         >
  //           <View
  //             style={{
  //               width: "100%",
  //               height: 40,
  //               justifyContent: "flex-start",
  //               flexDirection: "row",
  //               alignItems: "center",
  //               borderRadius: 8,
  //               borderColor: '#f4f4f4',
  //               borderWidth: 1,
  //               paddingRight: "3%",
  //               marginBottom: 10,
  //               backgroundColor: '#fff'
  //             }}
  //           >
  //             <View style={{ width: "10%", marginRight: "auto" }}>
  //               <Icon name="search" size={18} type="font-awesome" />
  //             </View>

  //             <TextInput
  //               autoFocus={false}
  //               placeholder={this.state.index == 0 ? "Search Cliks" :
  //                 this.state.index == 1 ? "Search Topics" :
  //                   this.state.index == 2 ? 'Search User' :
  //                     this.state.index == 3 && "Search Feeds"}
  //               placeholderTextColor='#706969'
  //               onChangeText={(query) => this.searchTextField(query)}
  //               value={this.state.searchedWord}
  //               style={{
  //                 height: 40,
  //                 width: "74%",
  //                 paddingHorizontal: 10,
  //                 paddingVertical: 10,
  //                 outline: "none",
  //                 position: "absolute",
  //                 left: "13%",
  //                 fontWeight: 'bold',
  //                 fontFamily: ConstantFontFamily.defaultFont,
  //                 color: Platform.OS == 'web' ? '#706969' : "#000"
  //               }}
  //             />
  //             {this.state.searchedWord ? (
  //               <TouchableOpacity
  //                 onPress={() => this.setState({ searchedWord: "" })}
  //                 style={{ marginLeft: "auto", width: "10%" }}
  //               >
  //                 <Icon name="close" size={18} type="font-awesome" />
  //               </TouchableOpacity>
  //             ) : null}
  //           </View>
  //           <Suspense fallback={<ShadowSkeletonStar />}>

  //             <ScrollView showsVerticalScrollIndicator={false}>
  //               <CliksListDrawerScreens
  //                 current={this.state.index}
  //                 navigation={this.props.navigation}
  //                 searchedWord={this.state.searchedWord}
  //               />
  //             </ScrollView>
  //           </Suspense>

  //           <Button
  //             testID="newClik"
  //             onPress={() => {
  //               // if(this.props.NewHomeFeed.length>0){
  //               NavigationService.navigate("createclik");
  //               this.props.leftPanelModalFunc(false);
  //               // }

  //             }}
  //             onMouseEnter={() => this.setState({ clikBtnColor: '#000', clikTextColor: '#fff' })}
  //             onMouseLeave={() => this.setState({ clikBtnColor: Colors.blueColor, clikTextColor: "#fff" })}
  //             title="NEW CLIK"
  //             titleStyle={[ButtonStyle.titleStyle, { color: this.state.clikTextColor }]}
  //             buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.clikBtnColor, paddingHorizontal: 30, paddingVertical: 7, borderWidth: 0 }]}
  //             containerStyle={[ButtonStyle.containerStyle, { marginLeft: 0 }]}
  //           />
  //           <BottomPart />

  //         </View>
  //       );
  //   }
  // };

  changeTab = (title) => {
    this.setState({ searchedWord: '' })
    if (title == "Cliks") {
      this.setState({
        index: 0,
        focus: "Cliks"
      })
    } else if (title == "Topics") {
      this.setState({
        index: 1,
        focus: "Topics"
      })
    } else if (title == "Users") {
      this.setState({
        index: 2,
        focus: "Users"
      })
    } else if (title == "Feeds") {
      this.setState({
        index: 3,
        focus: "Feeds"
      })
    }
  }

  goToUserProfile = async (username) => {
    this.props.userId({
      username: username,
      type: "feed",
    });
    NavigationService.navigate("profile", {
      username: username,
      type: "feed",
      postId: ' '
    });
  };

  render() {
    return (
      // <TabView
      //   // lazy
      //   swipeEnabled={false}
      //   navigationState={this.state}
      //   renderScene={this._renderScene}
      //   renderLazyPlaceholder={this._renderLazyPlaceholder}
      //   renderTabBar={this._renderTabBar}
      //   onIndexChange={this._handleIndexChange}
      //   timingConfig={{ duration: 0 }}
      //   style={{
      //     height: this.styleChange(),
      //     flexDirection: 'row',
      //     width: '100%',
      //   }}
      // />
      <View style={{ height: "100%", flexDirection: "row", }}>


        <View style={{ height: "100%", backgroundColor: '#000', flexDirection: 'column', width: 60, }}>
          {this.state.routes.map((route, index) => {
            // console.log(route);
            return (
              <TouchableOpacity style={{ height: 50, width: 60, justifyContent: 'center', alignItems: 'center', backgroundColor:  '#000' }} onPress={() => this.changeTab(route.title)}>
                <Icon
                  name={route.icon}
                  type={route.type}
                  color={
                    route.title == 'Cliks' && this.state.focus == "Cliks" ? Colors.blueColor :
                      route.title == 'Topics' && this.state.focus == "Topics" ? Colors.blueColor :
                        route.title == 'Users' && this.state.focus == "Users" ? Colors.blueColor :
                          route.title == 'Feeds' && this.state.focus == "Feeds" ? Colors.blueColor : "#D3D3D3"}
                />
                {/* <Text >{route.title}</Text> */}
              </TouchableOpacity>
            )

          })}

        </View>
        <View style={{ width: Dimensions.get("window").width <= 750 ? '85%' : 250, paddingRight: 15, paddingLeft: 15, }}>
          {this.props.loginStatus == 1 && Dimensions.get("window").width <= 750 && (
            <Hoverable>
              {isHovered => (
                <TouchableOpacity
                  onPress={() =>
                   { this.goToUserProfile(
                      this.props.profileData &&
                      this.props.profileData.user.username
                    ),
                    this.props.leftPanelModalFunc(false)}

                  }
                >
                  <View
                    style={
                      {
                        height: hp("15%"),
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "transparent",
                        marginTop: 10,
                        paddingVertical: 30,
                        paddingHorizontal: 20,
                        // backgroundColor: ConstantColors.whiteBackground,

                      }
                    }
                  >
                    {this.props.profileData
                      &&
                      this.props.profileData.user.profile_pic
                      ? (
                        <Image
                          source={{
                            uri: this.props.profileData.user.profile_pic
                          }}
                          style={{
                            width: 70,
                            height: 70,
                            borderRadius: 35,
                            borderWidth: 1,
                            borderColor: "#e1e1e1",
                            marginRight: 5
                          }}
                        />
                      ) : (
                        <Image
                          source={require("../assets/image/default-image.png")}
                          style={{
                            width: 70,
                            height: 70,
                            borderRadius: 35,
                            borderWidth: 1,
                            borderColor: "#e1e1e1",
                            marginRight: 5
                          }}
                        />
                      )}

                    <Text
                      style={{
                        color: "#000",
                        fontSize: 22,
                        fontFamily: ConstantFontFamily.defaultFont,
                        textDecorationLine:
                          isHovered == true ? "underline" : "none",
                        position: "relative",
                        top: 8,
                        fontWeight: 'bold',
                        marginRight: 5
                      }}
                    >
                      {this.props.profileData &&
                        "@" +
                        this.props.profileData.user.username}
                    </Text>
                  </View>

                </TouchableOpacity>
              )}
            </Hoverable>
          )}
          <View style={{
            width: "100%",
            height: 40,
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 8,
            borderColor: '#d3d3d3',
            borderWidth: 1,
            // paddingRight: "3%",
            marginVertical: 10,
            backgroundColor: '#fff'
          }}>
            <View style={{ width: "16%", marginRight: "auto" }}>
              <Icon name="search" size={18} type="font-awesome" />
            </View>
            <TextInput
              autoFocus={false}
              placeholder={this.state.index == 0 ? "Search Cliks" :
                this.state.index == 1 ? "Search Topics" :
                  this.state.index == 2 ? 'Search User' :
                    this.state.index == 3 && "Search Feeds"}
              placeholderTextColor='#706969'
              onChangeText={(query) => this.searchTextField(query)}
              value={this.state.searchedWord}
              style={{
                height: 40,
                width: "74%",
                paddingHorizontal: 10,
                paddingVertical: 10,
                outline: "none",
                position: "absolute",
                left: "13%",
                fontWeight: 'bold',
                fontFamily: ConstantFontFamily.defaultFont,
                color: Platform.OS == 'web' ? '#706969' : "#000"
              }}
            />
            {this.state.searchedWord ? (
              <TouchableOpacity
                onPress={() => this.setState({ searchedWord: "" })}
                style={{ marginLeft: "auto", width: "10%" }}
              >
                <Icon name="close" size={18} type="font-awesome" />
              </TouchableOpacity>
            ) : null}
          </View>
          {this.state.focus == "Cliks" ? <View
            onLayout={(event) => {
              let { height } = event.nativeEvent.layout;
              this.setState({
                CliksListDrawerScreensHeigth: height,
              });
            }}
            style={{
              // flex: 1,              
              // width: '75%',
              height: Dimensions.get("window").width <= 750 ? this.props.windowSize.height - 250 : this.props.windowSize.height - 120,
              // paddingRight: 15,
              // borderWidth:1
            }}
          >
            <Suspense fallback={<ShadowSkeletonStar />}>

              <ScrollView showsVerticalScrollIndicator={false}>
                <CliksListDrawerScreens
                  current={this.state.index}
                  navigation={this.props.navigation}
                  searchedWord={this.state.searchedWord}
                />
              </ScrollView>
            </Suspense>

            <Button
              testID="newClik"
              onPress={() => {
                // if(this.props.NewHomeFeed.length>0){
                this.props.screen('createClik')
                NavigationService.navigate("createclik");
                this.props.leftPanelModalFunc(false);
                // }

              }}
              onMouseEnter={() => this.setState({ clikBtnColor: '#000', clikTextColor: '#fff' })}
              onMouseLeave={() => this.setState({ clikBtnColor: Colors.blueColor, clikTextColor: "#fff" })}
              title="NEW CLIK"
              titleStyle={[ButtonStyle.titleStyle, { color: this.state.clikTextColor }]}
              buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.clikBtnColor, paddingHorizontal: 30, paddingVertical: 8, borderWidth: 0 }]}
              containerStyle={[ButtonStyle.containerStyle, { marginLeft: 0 }]}
            />
            <BottomPart />

          </View> : this.state.focus == "Topics" ? <View
            onLayout={(event) => {
              let { height } = event.nativeEvent.layout;
              this.setState({
                TopicsListDrawerScreensHeigth: height,
              });
            }}
            style={{
              // flex: 1,
              // width: '75%',
              // height: '80%',
              height: Dimensions.get("window").width <= 750 ? this.props.windowSize.height - 250 : this.props.windowSize.height - 120,
              // paddingRight: 15
            }}
          >

            <ScrollView showsVerticalScrollIndicator={false}>
              <Suspense fallback={null}>
                <TopicsListDrawerScreens
                  navigation={this.props.navigation}
                  current={this.state.index}
                  searchedWord={this.state.searchedWord}
                />
              </Suspense>
            </ScrollView>
            {this.props.isAdmin == true && (
              <Button
                onPress={() => {
                  this.props.leftPanelModalFunc(false);
                  this.props.screen('createTopic')
                  return NavigationService.navigate("createtopic");
                }}
                title="PROPOSE TOPIC"
                titleStyle={[ButtonStyle.titleStyle, { color: this.state.topicTextColor, }]}
                buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.topicBtnColor, paddingVertical: 8, borderWidth: 0 }]}
                containerStyle={ButtonStyle.containerStyle}
                onMouseEnter={() => this.setState({ topicBtnColor: '#000', topicTextColor: '#fff' })}
                onMouseLeave={() => this.setState({ topicBtnColor: Colors.blueColor, topicTextColor: "#fff" })}
              />
            )}

            {this.props.isAdmin == false && (
              <Button
                onPress={() => {
                  this.props.screen('createTopic')
                  NavigationService.navigate("topichierarchy")
                }}
                title="TOPIC HIERARCHY"
                onMouseEnter={() => this.setState({ topicBtnColor: '#000', topicTextColor: '#fff' })}
                onMouseLeave={() => this.setState({ topicBtnColor: Colors.blueColor, topicTextColor: "#fff" })}
                titleStyle={[ButtonStyle.titleStyle, { color: this.state.topicTextColor, }]}
                buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.topicBtnColor, paddingVertical: 8, borderWidth: 0 }]}
                containerStyle={ButtonStyle.containerStyle}
              />
            )}
            <BottomPart />

          </View> : this.state.focus == "Users" ? <View
            onLayout={(event) => {
              let { height } = event.nativeEvent.layout;
              this.setState({
                UserListDrawerScreensHeigth: height,
              });
            }}
            style={{
              // flex: 1
              // width: '75%',
              // height: '80%',
              height: Dimensions.get("window").width <= 750 ? this.props.windowSize.height - 250 : this.props.windowSize.height - 120,
              // paddingRight: 15
            }}
          >


            <ScrollView showsVerticalScrollIndicator={false}>
              <Suspense fallback={null}>
                <UserListDrawerScreens
                  current={this.state.index}
                  navigation={this.props.navigation}
                  searchedWord={this.state.searchedWord}
                />
              </Suspense>
            </ScrollView>
            <Button
              onPress={() => {
                this.props.screen('createUser')
                this.props.leftPanelModalFunc(false);
                return NavigationService.navigate("settings");
              }}
              title="INVITE FRIENDS"
              onMouseEnter={() => this.setState({ userBtnColor: '#000', userTextColor: '#fff' })}
              onMouseLeave={() => this.setState({ userBtnColor: Colors.blueColor, userTextColor: "#fff" })}
              titleStyle={[ButtonStyle.titleStyle, { color: this.state.userTextColor }]}
              buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.userBtnColor, paddingVertical: 8, borderWidth: 0 }]}
              containerStyle={ButtonStyle.containerStyle}
            />
            <BottomPart />
          </View> : this.state.focus == "Feeds" ? <View
            onLayout={(event) => {
              let { height } = event.nativeEvent.layout;
              this.setState({
                FeedListDrawerScreensHeigth: height,
              });
            }}
            style={{
              // flex: 1
              // width: '75%',
              // height: '80%',
              height: Dimensions.get("window").width <= 750 ? this.props.windowSize.height - 250 : this.props.windowSize.height - 120,
              // paddingRight: 15
            }}
          >

            <ScrollView showsVerticalScrollIndicator={false}>
              <Suspense fallback={null}>
                <FeedListDrawerScreen
                  current={this.state.index}
                  navigation={this.props.navigation}
                  searchedWord={this.state.searchedWord}
                />
              </Suspense>
            </ScrollView>
            <Button
              onPress={() => {
                this.props.screen('addFeed')
                this.props.leftPanelModalFunc(false);
                return NavigationService.navigate("addfeed");
              }}
              color="#fff"
              title="ADD FEED"
              onMouseEnter={() => this.setState({ feedBtnColor: '#000', feedTextColor: '#fff' })}
              onMouseLeave={() => this.setState({ feedBtnColor: Colors.blueColor, feedTextColor: "#fff" })}
              titleStyle={[ButtonStyle.titleStyle, { color: this.state.feedTextColor }]}
              buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.feedBtnColor, paddingVertical: 8, borderWidth: 0 }]}
              containerStyle={ButtonStyle.containerStyle}
            />
            <BottomPart />
          </View> : null}
        </View>
      </View >
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.get("loginStatus"),
  isAdmin: state.AdminReducer.get("isAdmin"),
  isAdminView: state.AdminReducer.get("isAdminView"),
  NewHomeFeed: state.HomeFeedReducer.get("NewHomeFeedList"),
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  windowSize: state.windowResizeReducer.windowResize

});
const mapDispatchToProps = (dispatch) => ({
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  leftPanelModalFunc: (payload) =>
    dispatch({ type: "LEFT_PANEL_OPEN", payload }),
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  screen: (payload) => dispatch(screen(payload))
});
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DrawerScreens
);
