import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import React, { Component } from "react";
import { graphql } from "react-apollo";
import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import { CheckBox, Icon } from "react-native-elements";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import applloClient from "../client";
import "../components/Firebase";
import LoaderComponent from "../components/LoaderComponent";
import ConstantFontFamily from "../constants/FontFamily";
import {
  ChangePaymentInfoMutation,
  ChangeSubscriptionMutation,
  UserLoginMutation,UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation
} from "../graphqlSchema/graphqlMutation/UserMutation";
import NavigationService from "../library/NavigationService";

const createOptions = {
  style: {
    base: {
      width: "100%",
      fontSize: "18px",
      color: "#000",
      letterSpacing: "0.025em",
      fontFamily: "Open Sans, sans-serif",
      "::placeholder": {
        color: "#aab7c4",
      },
      StripeElement: {
        backgroundColor: "red",
      },
    },
    invalid: {
      color: "red",
    },
  },
};

class PaymentModal extends Component {
  state = {
    success: false,
    errorMessage: null,
    paymentMethod: null,
    loading: false,
    typeMonth: false,
    typeYear: true,
  };

  constructor(props) {
    super(props);
    this.email = this.props.profileData.user.email
      ? this.props.profileData.user.email
      : "";
      console.log(this.props.profileData);
      
  }

  handleChange = ({ error }) => {
    if (error) {
      console.log(error.message);
    }
    this.setState({
      errorMessage: null,
      paymentMethod: null,
    });
  };

  handleSubmit = async (evt) => {
    let __self = this;
    if (this.props.stripe) {
      const { stripe, elements } = this.props;
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardNumberElement);
      this.setState({
        loading: true,
      });
      let payload = await this.props.stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: this.email,
        },
      });
      if (payload.error) {
        this.setState({
          errorMessage: payload.error.message,
          paymentMethod: null,
          loading: false,
        });
      } else {
        this.setState(
          {
            paymentMethod: payload.paymentMethod,
            errorMessage: null,
          },
          () => {
            if (payload.paymentMethod) {
              let paymentId = payload.paymentMethod.id;
              // applloClient
              //   .query({
              //     query: ChangePaymentInfoMutation,
              //     variables: {
              //       payment_id: paymentId,
              //       email: __self.email,
              //     },
              //     fetchPolicy: "no-cache",
              //   })
              //   .then((response) => {
                  // if (response) {
                    applloClient
                      .query({
                        query: ChangeSubscriptionMutation,
                        variables: {
                          payment_id: paymentId,
                          subscription: "GOLD",
                          pricing:
                            __self.state.typeMonth == true
                              ? "MONTHLY"
                              : "YEARLY",
                        },
                        fetchPolicy: "no-cache",
                      })
                      .then(async (r) => {
                        let resDataLogin = await applloClient
                          .query({
                            query: UserLoginMutation,
                            variables: {
                              id: "Account:" + "CurrentUser"
                            },
                            fetchPolicy: "no-cache"
                          }).then(res => {
                            return res
                          })

                        let userfollow = await applloClient
                          .query({
                            query: UserFollowingMutation,
                            variables: {
                              id: "User:CurrentUser",
                              first: 20
                            },
                            fetchPolicy: "no-cache"
                          }).then(e => {
                            return e.data.node.users_following.edges
                          })

                        let topicfollow = await applloClient
                          .query({
                            query: TopicFollowingMutation,
                            variables: {
                              id: "User:CurrentUser",
                              first: 20
                            },
                            fetchPolicy: "no-cache"
                          }).then(e => {
                            return e.data.node.topics_following.edges
                          })
                        let clikfollow = await applloClient
                          .query({
                            query: ClikFollowingMutation,
                            variables: {
                              id: "User:CurrentUser",
                              first: 20
                            },
                            fetchPolicy: "no-cache"
                          }).then(e => {
                            return e.data.node.cliks_following.edges
                          })
                        let externalfollow = await applloClient
                          .query({
                            query: ExternalFeedFollowingMutation,
                            variables: {
                              id: "User:CurrentUser",
                              first: 20
                            },
                            fetchPolicy: "no-cache"
                          }).then(e => {
                            return e.data.node.external_feeds_following.edges
                          })
                        let userData = {
                          data: resDataLogin.data.node,
                          externalfollow: externalfollow,
                          clikfollow: clikfollow,
                          topicfollow: topicfollow,
                          userfollow: userfollow

                        }
                        await this.props.saveLoginUser(userData);

                        __self.props.onClose();
                        __self.setState({
                          loading: false,
                        });
                      })
                      .catch((e) => {
                        __self.props.onClose();
                        __self.setState({
                          loading: false,
                        });
                        console.log(e);
                      });
                  // }
                // })
                // .catch((e) => {
                //   __self.props.onClose();
                //   __self.setState({
                //     loading: false,
                //   });
                //   console.log(e);
                // });
            }
          }
        );
      }
    } else {
      __self.setState({
        loading: false,
      });
      console.log("Stripe hasn't loaded yet.");
    }
  };

  __isConform = () => {
    if (this.state.success != true) {
      this.setState({
        success: true,
      });
    } else {
      this.props.onClose();
    }
  };

  render() {
    const { paymentMethod, errorMessage, loading } = this.state;

    return (
      <View
        style={{
          width: "100%",
        }}
      >
        <Hoverable>
          {(isHovered) => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                flex: 1,
                position: "absolute",
                zIndex: 999999,
                left: 0,
                top: 0,
              }}
              onPress={this.props.onClose}
            >
              <Icon
                color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                iconStyle={{
                  color: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                reverse
                name="close"
                type="antdesign"
                size={16}
              />
            </TouchableOpacity>
          )}
        </Hoverable>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#000",
            alignItems: "center",
            height: 50,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
          }}
        >
          <Image
            source={
              Platform.OS == "web" &&
                this.props.getCurrentDeviceWidthAction > 750
                ? require("../assets/image/weclickd-logo.png")
                : Platform.OS == "web"
                  ? require("../assets/image/weclickd-logo.png")
                  : require("../assets/image/weclickd-logo-only-icon.png")
            }
            style={
              Platform.OS == "web" &&
                this.props.getCurrentDeviceWidthAction > 750
                ? {
                  height: 30,
                  width: Platform.OS == "web" ? 90 : 30,
                  padding: 0,
                  margin: 0,
                  marginVertical: 10,
                }
                : {
                  height: 30,
                  width: Platform.OS == "web" ? 90 : 30,
                  padding: 0,
                  margin: 0,
                  marginVertical: 10,
                }
            }
          />
        </View>

        <View
          style={{
            borderRadius: 6,
            width: "100%",
            backgroundColor: "#fff",
          }}
        >
          <View
            style={{
              flex: 1,
              backgroundColor: "#fff",
              padding: 20,
            }}
          >
            <View>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                >
                  Plan
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  marginBottom: 50,
                }}
              >
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <CheckBox
                      title="$5/month"
                      checked={this.state.typeMonth}
                      onPress={(val) => {
                        this.setState({
                          typeYear: false,
                          typeMonth: true,
                        });
                      }}
                      iconType="ionicon"
                      checkedIcon="ios-checkmark-circle"
                      uncheckedIcon="ios-radio-button-off"
                      checkedColor="#000"
                      containerStyle={{
                        backgroundColor: "none",
                        borderWidth: 0,
                        marginVertical: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingVertical: 0,
                        marginTop: 5,
                      }}
                      textStyle={{
                        marginLeft: 0,
                        marginRight: 0,
                        color: "#000",
                        fontSize: 16,
                      }}
                      fontFamily={ConstantFontFamily.defaultFont}
                    />
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <CheckBox
                      title="$50/year (save $10)"
                      checked={this.state.typeYear}
                      onPress={(val) => {
                        this.setState({
                          typeYear: true,
                          typeMonth: false,
                        });
                      }}
                      iconType="ionicon"
                      checkedIcon="ios-checkmark-circle"
                      uncheckedIcon="ios-radio-button-off"
                      checkedColor="#000"
                      containerStyle={{
                        backgroundColor: "none",
                        borderWidth: 0,
                        marginVertical: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingVertical: 0,
                        marginTop: 5,
                      }}
                      textStyle={{
                        marginLeft: 0,
                        marginRight: 0,
                        color: "#000",
                        fontSize: 16,
                      }}
                      fontFamily={ConstantFontFamily.defaultFont}
                    />
                  </View>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                >
                  Credit Card
                </Text>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  marginVertical: 5,
                  fontFamily: ConstantFontFamily.defaultFont,
                  fontSize: 14,
                }}
              >
                Card number{" "}
              </Text>
            </View>

            <View
              style={{
                width: "100%",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: "#aab7c4",
                  borderRightWidth: 0,
                  borderLeftWidth: 0,
                  borderTopWidth: 0,
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    width: wp(37),
                  }}
                >
                  <CardNumberElement
                    onChange={this.handleChange}
                    options={createOptions}
                  />
                </View>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                flexDirection: "row",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  width: "48%",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: "#aab7c4",
                  borderRightWidth: 0,
                  borderLeftWidth: 0,
                  borderTopWidth: 0,
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <View>
                    <Text
                      style={{
                        marginVertical: 5,
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      Expiration
                    </Text>
                    <CardExpiryElement
                      onChange={this.handleChange}
                      options={createOptions}
                    />
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: "#aab7c4",
                  borderRightWidth: 0,
                  borderLeftWidth: 0,
                  borderTopWidth: 0,
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <View>
                    <Text
                      style={{
                        marginVertical: 5,
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      Security Code
                    </Text>
                    <CardCvcElement
                      onChange={this.handleChange}
                      options={createOptions}
                    />
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              {errorMessage && (
                <Text
                  style={{
                    color: "red",
                    marginVertical: 10,
                  }}
                >
                  {errorMessage}
                </Text>
              )}
            </View>

            <View
              style={{
                flexDirection: "row",
                width: "100%",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: "#919191",
                }}
              >
                By clicking "Start my membership," you agree to our{" "}
                <TouchableOpacity
                  onPress={() => {
                    this.props.onClose();
                    NavigationService.navigate("termsandconditions");
                  }}
                >
                  <Text
                    style={{
                      color: "#919191",
                      textDecorationLine: "underline",
                    }}
                  >
                    Terms of Service
                  </Text>
                </TouchableOpacity>
                . Your payment method will be charged a recurring USD $
                {this.state.typeMonth ? 5 + ' monthly' : 50 + ' yearly'} fee, unless you decide
                to cancel. No refunds for memberships canceled between billing
                cycles.
              </Text>
            </View>

            {loading == false ? (
              <TouchableOpacity
                block
                style={{
                  borderRadius: 5,
                  height: 40,
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 5,
                  borderWidth: 1,
                  borderColor: "#000",
                  marginTop: 10,
                  backgroundColor: "#000",
                }}
                onPress={this.handleSubmit}
                disabled={!this.props.stripe}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: "#fff",
                    fontFamily: ConstantFontFamily.defaultFont,
                    marginLeft: 5,
                  }}
                >
                  {"Start my membership"}
                </Text>
              </TouchableOpacity>
            ) : (
              <View
                style={{
                  borderRadius: 5,
                  height: 40,
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 5,
                  borderWidth: 1,
                  borderColor: "#000",
                  marginTop: 10,
                  backgroundColor: "#fff",
                }}
              >
                <LoaderComponent />
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
});

const mapDispatchToProps = (dispatch) => ({
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
});

// const PaymentModalContainerWrapper = compose(
//   graphql(UserLoginMutation, {
//     name: "Login",
//     options: { 
//       variables: {
//         id: "Account:" + "CurrentUser"
//       },
//       fetchPolicy: "no-cache" },
//   })
// )(PaymentModal);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PaymentModal
);
