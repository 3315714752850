import React, { Component } from "react";
import { Dimensions, StyleSheet, Text, View } from "react-native";
import ConstantFontFamily from "../constants/FontFamily";

class SettingsScreen1 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={[styles.text,{fontFamily: ConstantFontFamily.defaultFont}]}>- Welcome to Settings Screen -</Text>
      </View>
    );
  }
}

export default SettingsScreen1;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: Dimensions.get("window").height,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2896d3"
  },
  text: {
    fontSize: 24,
    marginBottom: 60,
    color: "#fff",
    fontWeight: "bold"
  }
});
