import React, { Component,lazy,Suspense } from "react";
import { retry } from "../library/Helper";
import { Image, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import NavigationService from "../library/NavigationService";

const HeaderRight = lazy(() =>
  retry(() => import("../components/HeaderRight"))
);

class NewHeader extends Component {
  constructor(props) {
    super(props);
  }

  goToScrollTop = () => {
    this.props.setHASSCROLLEDACTION(true);
  };

  render() {
    return (
      <View
        style={{
          flexDirection: "row"
        }}
      >
        {this.props.getCurrentDeviceWidthAction > 750 ? (
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              backgroundColor: "#000",
              flex: 1
            }}
          >
            <View
              style={{
                width: "38%",
                height: 60,
                backgroundColor: "#000",
                justifyContent: "center"
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginLeft: '35%'
                }}
              >
                <View
                  style={{
                    flexDirection: "row"
                  }}
                >
                  <View
                    style={{
                      marginLeft: 10,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => NavigationService.navigate("home")}
                    >
                      <Image
                        source={require("../assets/image/weclickd-logo.png")}
                        style={{
                          height: 30,
                          width: 90
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                width: "49%",
                flexDirection: "row",
                height: 60,
                backgroundColor: "#000",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <Suspense fallback={null}>
              <HeaderRight navigation={this.props.navigation} />
              </Suspense>
            </View>
          </View>
        ) : (
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              backgroundColor: "#000",
              height: 60,
              alignItems: "center"
            }}
          >
            <View
              style={{
                height: 60,
                backgroundColor: "#000",
                justifyContent: "center"
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center"
                }}
              >
                <View
                  style={{
                    flexDirection: "row"
                  }}
                >
                  <View
                    style={{
                      marginLeft: 10,
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => NavigationService.navigate("home")}
                    >
                      <Image
                        source={require("../assets/image/weclickd-logo.png")}
                        style={{
                          height: 30,
                          width: 90
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>

            <TouchableOpacity
              onPress={() => this.goToScrollTop()}
              style={{
                width: "100%",
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "flex-end"
              }}
            >
              <Suspense fallback={null}>
              <HeaderRight navigation={this.props.navigation} />
              </Suspense>
             
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = state => ({
  getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension")
});

const mapDispatchToProps = dispatch => ({
  setHASSCROLLEDACTION: payload => dispatch(setHASSCROLLEDACTION(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(NewHeader);
