import React from 'react';
import getEnvVars from '../environment';
import { dataURItoBlob } from '../library/Helper';
import { Platform } from 'react-native';
import axios from 'axios';


const apiUrlMain = getEnvVars();

export const uploadBannerImageAsync = async (uri) => {
    let apiUrl = apiUrlMain.API_URL + 'v1/media/banners';
    let formData = new FormData();
    let headers = {};
    if (Platform.OS != 'web') {
        let fileType = uri[uri.length - 1];
        formData.append('file', {
            uri,
            name: `photo.${fileType}`,
            type: `image/jpeg`,
        });
        headers = {
            Accept: 'application/json',
            "Access-Control-Allow-Origin": "http://localhost:19006",
            'Content-Type': 'multipart/form-data',
        };
    } else {
        let blob = await dataURItoBlob(uri);
        formData.append("file", blob, new Date() + ".jpeg");
    }
    let options = {
        method: 'POST',
        body: formData,
        headers: { ...headers }
    };
    axios(apiUrl, options).then(e => {
        console.log(e, 'eeeeee');
    });
};

export const uploadProfileImageAsync = async (uri, type) => {
   
    let apiUrl = apiUrlMain.API_URL + 'addpic';
    let formData = new FormData();
    let data1 = { url: uri }
    let headers = {};
    if (Platform.OS != 'web') {
        let fileType = uri[uri.length - 1];
        formData.append('file', {
            uri,
            name: `photo.${fileType}`,
            type: `image/jpeg`,
        });
        headers = {
            "Access-control-allow-origin": "*",
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',

        };
    } else {
        let blob = await dataURItoBlob(uri);
        formData.append("file", blob, new Date() + ".jpeg");
       
    }
    if (type == true) {
        let options = {
            method: 'POST',
            body: formData,
            headers: {
                ...headers,
            }
        };
        return fetch(apiUrl, options);
    } else {
        console.log(data1, 'data1');
        let data2 = JSON.stringify(data1)
        let options = {
            method: 'POST',
            body: data2,
            headers: {
                ...headers,
                Accept: 'application.json',
                'Content-Type': 'application/json'
            }
        };
        return fetch(apiUrl, options);

    }

};



export const uploadPostImageAsync = async (uri) => {
    let apiUrl = apiUrlMain.API_URL + 'v1/media/contents';
    let formData = new FormData();
    let headers = {};
    if (Platform.OS != 'web') {
        let fileType = uri[uri.length - 1];
        formData.append('file', {
            uri,
            name: `photo.${fileType}`,
            type: `image/jpeg`,
        });
        headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        };
    } else {
        let blob = await dataURItoBlob(uri);
        formData.append("file", blob, new Date() + ".jpeg");
    }
    let options = {
        method: 'POST',
        body: formData,
        headers: {
            ...headers,
        }
    };
    return fetch(apiUrl, options);
};