import { call, put, takeEvery } from "redux-saga/effects";
import applloClient from "../client";
import {
  TRENDINGUSERS,
  TRENDINGUSERS_FAILURE,
  TRENDINGUSERS_SUCCESS
} from "../constants/Action";
import { Trending_Users_Mutation } from "../graphqlSchema/graphqlMutation/TrendingMutation";

const getResponse = req => {
  return applloClient
    .query({
      query: Trending_Users_Mutation,
      variables: {
        first: 20,
        after: null,
        sort_type: "TRENDING",
        type: "User"
      },
      fetchPolicy: "no-cache"
    })
    .then(res => res);
};

export function* Trending_Users_Middleware({ payload }) {
  try {
    const response = yield call(getResponse, payload);
    yield put({
      type: TRENDINGUSERS_SUCCESS,
      //   payload: response.data.trending_users.edges
      payload: response.data.node_list.edges
    });
  } catch (err) {
    yield put({
      type: TRENDINGUSERS_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* Trending_Users_MiddlewareWatcher() {
  yield takeEvery(TRENDINGUSERS, Trending_Users_Middleware);
}
