import { NavigationActions, DrawerActions,  } from "react-navigation";

let _navigator;

const setTopLevelNavigator = navigatorRef => {
  _navigator = navigatorRef;
};

function navigate(routeName, params) {
  // console.log(routeName, 'routeNamerouteNam');
  _navigator.dispatch(
    NavigationActions.navigate({
      routeName,
      params
    })
  );
}

function goBack() {
  _navigator.dispatch(
    NavigationActions.back({
      key: null
    })
  );
}

function getCurrentRoute() {
  // console.log(NavigationState,'NavigationState');
  let route = _navigator.state.nav;
  while (route.routes) {
    route = route.routes[route.index];
  }
  return route;
}

function toggleDrawer() {
  _navigator.dispatch(DrawerActions.toggleDrawer());
}

export default {
  navigate,
  setTopLevelNavigator,
  goBack,
  getCurrentRoute,
  toggleDrawer
};
