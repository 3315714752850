import React from "react";
import { graphql } from "react-apollo";
import { Text, TextInput, View, Dimensions } from "react-native";
import { Button } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import applloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import {
  ChangeAccountSettingsMutation,
  UserLoginMutation,UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation
} from "../graphqlSchema/graphqlMutation/UserMutation";
import { ChangeAccountSettingsVariables } from "../graphqlSchema/graphqlVariables/UserVariables";
import { AccountSetting } from '../graphqlSchema/graphqlMutation/StripeMutation';
import ButtonStyle from "../constants/ButtonStyle";

class Notification extends React.Component {
  state = {
    switchValue1: null,
    // this.props.profileData &&
    // this.props.profileData.getIn([
    //   "settings",
    //   "email_notifications",
    //   "monthly_earnings"
    // ]),
    switchValue2: null,
    // this.props.profileData &&
    // this.props.profileData.getIn([
    //   "settings",
    //   "email_notifications",
    //   "weclikd_updates"
    // ]),
    switchValue3: null,
    // this.props.profileData &&
    // this.props.profileData.getIn([
    //   "settings",
    //   "email_notifications",
    //   "clik_notifications"
    // ]),
    Email: null,
    // this.props.profileData &&
    // this.props.profileData.getIn([
    //   "settings",
    //   "email_notifications",
    //   "notification_email"
    // ]),
    showTextInput: false
  };

  toggleSwitch1 = async value => {
    await this.setState({ switchValue1: value });
    this.ChangeSubscription();
  };
  toggleSwitch2 = async value => {
    await this.setState({ switchValue2: value });
    this.ChangeSubscription();
  };
  toggleSwitch3 = async value => {
    await this.setState({ switchValue3: value });
    this.ChangeSubscription();
  };

  componentDidMount = async () => {
    applloClient
      .query({
        query: AccountSetting,
        variables: {
          id: "Account:CurrentUser"
        },
        fetchPolicy: "no-cache"
      }).then(e=>{
        this.setState({
          email:e.data.node.settings.email,
          switchValue1:e.data.node.settings.email_notification_monthly_earnings,
          switchValue2:e.data.node.settings.email_notification_weclikd_updates,
          switchValue3:e.data.node.settings.email_notification_clik_invitations
        })
      })

  }

  ChangeSubscription = () => {
     ChangeAccountSettingsVariables.variables.email = this.state.Email;
    ChangeAccountSettingsVariables.variables.email_notification_monthly_earnings = this.state.switchValue1;
    ChangeAccountSettingsVariables.variables.email_notification_clik_invitations = this.state.switchValue3;
    ChangeAccountSettingsVariables.variables.email_notification_weclikd_updates = this.state.switchValue2;
    applloClient
      .query({
        query: ChangeAccountSettingsMutation,
        ...ChangeAccountSettingsVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        let resDataLogin = await applloClient
          .query({
            query: UserLoginMutation,
            variables: {
              id: "Account:" + "CurrentUser"
            },
            fetchPolicy: "no-cache"
          }).then(res => {
            return res
          })

        let userfollow = await applloClient
          .query({
            query: UserFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.users_following.edges
          })

        let topicfollow = await applloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.topics_following.edges
          })
        let clikfollow = await applloClient
          .query({
            query: ClikFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.cliks_following.edges
          })
        let externalfollow = await applloClient
          .query({
            query: ExternalFeedFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.external_feeds_following.edges
          })
        let userData = {
          data: resDataLogin.data.node,
          externalfollow: externalfollow,
          clikfollow: clikfollow,
          topicfollow: topicfollow,
          userfollow: userfollow

        }
        await this.props.saveLoginUser(userData);
      });
  };

  render() {
    return (
      <View
        style={{
          backgroundColor: "#fff",
          paddingHorizontal: 10,
          marginVertical: Dimensions.get("window").width >= 750 ? 10 : 0
        }}
      >
        <Text
          style={{
            color: "#000",
            fontSize: 16,
            fontWeight: "bold",
            fontFamily: ConstantFontFamily.defaultFont,
            marginVertical: 13
          }}
        >
          Login Email
        </Text>

        <Text
          style={{
            color: "#000",
            fontSize: 14,
            //fontWeight: "bold",
            fontFamily: ConstantFontFamily.defaultFont,
            marginVertical: 5
          }}
        >
          {this.props.profileData && this.props.profileData.user?.username}
        </Text>
        <Text
          style={{
            color: "#000",
            fontSize: 16,
            fontWeight: "bold",
            fontFamily: ConstantFontFamily.defaultFont,
            marginTop: 10
          }}
        >
          Notifications Email
        </Text>

        <View style={{ flexDirection: "row", justifyContent: "space-between", width: "100%", height: 50 }}>
          <View
            style={{
              justifyContent: "center",
              // width: this.state.showTextInput ? "70%" :"100%",
              alignSelf: "center"
            }}
          >
            {this.state.showTextInput == false ? (
              <View
                style={{
                  //marginVertical: 5,
                  height: 45,
                  justifyContent: "center"
                }}
              >
                <Text
                  style={{
                    color: "#000",
                    //fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont,
                    fontSize: 14
                  }}
                >
                  {this.state.Email}
                </Text>
              </View>
            ) : (
              <TextInput
                value={this.state.Email}
                style={[ButtonStyle.textAreaShadowStyle, {
                  color: "#000",
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                  width: "100%",
                  marginVertical: 5,
                  height: 45,
                  padding: 5
                }]}
                onChangeText={value => this.setState({ Email: value })}
                onBlur={() => {
                  this.setState({
                    showTextInput: false
                  }),
                    this.ChangeSubscription();
                }}
              />
            )}
          </View>
          <View
            style={{
              //height: 45,
              justifyContent: "flex-end",
              //width: "40%",
              alignItems: "flex-end"
            }}
          >
            {this.state.showTextInput == false && (
              <Button
                onPress={() =>
                  this.setState({
                    showTextInput: true
                  })
                }
                color="#fff"
                title="Change"
                titleStyle={ButtonStyle.titleStyle}
                buttonStyle={[ButtonStyle.backgroundStyle, { paddingHorizontal: Dimensions.get("window").width <= 450 ? 10 : 20 }]}
                containerStyle={ButtonStyle.containerStyle}
              />
            )}
          </View>
        </View>

        <Text
          style={{
            color: "#000",
            fontSize: 16,
            fontFamily: ConstantFontFamily.defaultFont,
            //marginBottom: 10
          }}
        >
          Email Notification Settings
        </Text>
        <View
          style={{ flexDirection: "row", width: "100%", marginVertical: 15 }}
        >
          <View
            style={{
              justifyContent: "flex-start",
              width: "70%",
              alignSelf: "center"
            }}
          >
            <Text
              style={{
                color: "#000",
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 14,
                //fontWeight: "bold"
              }}
            >
              Monthly Earnings
            </Text>
          </View>
          <View
            style={{
              justifyContent: "flex-end",
              width: "30%",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <Button
              title="On"
              buttonStyle={{
                backgroundColor:
                  this.state.switchValue1 == true ? "#009B1A" : "#fff",
                borderColor: "#e1e1e1",
                borderWidth: 1,
                height: Dimensions.get('window').width <= 750 ? 40 : 30,
                borderBottomLeftRadius: 6,
                borderTopLeftRadius: 6
              }}
              titleStyle={{
                fontFamily: ConstantFontFamily.defaultFont,
                paddingHorizontal: 10,
                fontSize: 14,
                color: this.state.switchValue1 == true ? "#fff" : "#e1e1e1"
              }}
              onPress={() => this.toggleSwitch1(true)}
            />
            <Button
              title="Off"
              buttonStyle={{
                backgroundColor:
                  this.state.switchValue1 == false ? "#009B1A" : "#fff",
                borderColor: "#e1e1e1",
                borderWidth: 1,
                height: Dimensions.get('window').width <= 750 ? 40 : 30,
                borderTopRightRadius: 6,
                borderBottomRightRadius: 6
              }}
              titleStyle={{
                fontFamily: ConstantFontFamily.defaultFont,
                paddingHorizontal: 10,
                fontSize: 14,
                color: this.state.switchValue1 == false ? "#fff" : "#e1e1e1"
              }}
              onPress={() => this.toggleSwitch1(false)}
            />
          </View>
        </View>

        <View
          style={{ flexDirection: "row", width: "100%" }}
        >
          <View
            style={{
              justifyContent: "flex-start",
              width: "70%",
              alignSelf: "center"
            }}
          >
            <Text
              style={{
                color: "#000",
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 14,
                //fontWeight: "bold"
              }}
            >
              Weclikd Updates
            </Text>
          </View>
          <View
            style={{
              justifyContent: "flex-end",
              width: "30%",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <Button
              title="On"
              buttonStyle={{
                backgroundColor:
                  this.state.switchValue2 == true ? "#009B1A" : "#fff",
                borderColor: "#e1e1e1",
                borderWidth: 1,
                height: Dimensions.get('window').width <= 750 ? 40 : 30,
                borderBottomLeftRadius: 6,
                borderTopLeftRadius: 6
              }}
              titleStyle={{
                fontFamily: ConstantFontFamily.defaultFont,
                paddingHorizontal: 10,
                fontSize: 14,
                color: this.state.switchValue2 == true ? "#fff" : "#e1e1e1"
              }}
              onPress={() => this.toggleSwitch2(true)}
            />
            <Button
              title="Off"
              buttonStyle={{
                backgroundColor:
                  this.state.switchValue2 == false ? "#009B1A" : "#fff",
                borderColor: "#e1e1e1",
                borderWidth: 1,
                height: 30,
                borderTopRightRadius: 6,
                borderBottomRightRadius: 6
              }}
              titleStyle={{
                fontFamily: ConstantFontFamily.defaultFont,
                paddingHorizontal: 10,
                fontSize: 14,
                color: this.state.switchValue2 == false ? "#fff" : "#e1e1e1"
              }}
              onPress={() => this.toggleSwitch2(false)}
            />
          </View>
        </View>

        <View
          style={{ flexDirection: "row", width: "100%", marginVertical: 15 }}
        >
          <View
            style={{
              justifyContent: "flex-start",
              width: "70%",
              alignSelf: "center"
            }}
          >
            <Text
              style={{
                color: "#000",
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 14,
                //fontWeight: "bold"
              }}
            >
              Clik Invitations
            </Text>
          </View>
          <View
            style={{
              justifyContent: "flex-end",
              width: "30%",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <Button
              title="On"
              buttonStyle={{
                backgroundColor:
                  this.state.switchValue3 == true ? "#009B1A" : "#fff",
                borderColor: "#e1e1e1",
                borderWidth: 1,
                height: Dimensions.get('window').width <= 750 ? 40 : 30,
                borderBottomLeftRadius: 6,
                borderTopLeftRadius: 6
              }}
              titleStyle={{
                fontFamily: ConstantFontFamily.defaultFont,
                paddingHorizontal: 10,
                fontSize: 14,
                color: this.state.switchValue3 == true ? "#fff" : "#e1e1e1"
              }}
              onPress={() => this.toggleSwitch3(true)}
            />
            <Button
              title="Off"
              buttonStyle={{
                backgroundColor:
                  this.state.switchValue3 == false ? "#009B1A" : "#fff",
                borderColor: "#e1e1e1",
                borderWidth: 1,
                height: 30,
                borderTopRightRadius: 6,
                borderBottomRightRadius: 6
              }}
              titleStyle={{
                fontFamily: ConstantFontFamily.defaultFont,
                paddingHorizontal: 10,
                fontSize: 14,
                color: this.state.switchValue3 == false ? "#fff" : "#e1e1e1"
              }}
              onPress={() => {
                this.toggleSwitch3(false);
              }}
            />
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails
});

const mapDispatchToProps = dispatch => ({
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload))
});



export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Notification
);