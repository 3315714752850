import { call, put, takeEvery } from 'redux-saga/effects';
import applloClient from '../client';
import { USERFEED, USERFEED_FAILURE, USERFEED_SUCCESS } from "../constants/Action";
import { UserFeedMutation } from '../graphqlSchema/graphqlMutation/PostMutation';


const getResponse = (req) => {
    return applloClient.query({
        query: UserFeedMutation,
        variables: {
            currentPage: req.currentPage,
            id: "User:"+req.username,
        },
        fetchPolicy: 'no-cache'
    }).then(res => res);
};

export function* UserFeedMiddleware({ payload }) {
    try {
        const response = yield call(getResponse, payload);
        console.log(response, 'response')
        yield put({
            type: USERFEED_SUCCESS,
            payload: response.data.node.posts.edges
        });
    } catch (err) {
        yield put({
            type: USERFEED_FAILURE,
            payload: err.message
        });
        console.log(err);
    }
}

export default function* UserFeedMiddlewareWatcher() {
    yield takeEvery(USERFEED, UserFeedMiddleware);

}


