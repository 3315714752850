import { takeEvery, put } from "redux-saga/effects";
import {
  INVITESIGNUPMODALACTION,
  INVITESIGNUPMODALACTION_SUCCESS,
  INVITESIGNUPMODALACTION_FAILURE,
} from "../constants/Action";

export function* InviteSignUpModalMiddleware({ payload }) {
  if (payload == true) {
    yield put({
      type: INVITESIGNUPMODALACTION_SUCCESS,
      payload: payload,
    });
  } else {
    yield put({
      type: INVITESIGNUPMODALACTION_FAILURE,
      payload: payload,
    });
  }
}

export default function* InviteSignUpModalMiddlewareWatcher() {
  yield takeEvery(INVITESIGNUPMODALACTION, InviteSignUpModalMiddleware);
}
