import { TOOLKIT_OPEN,TOOLKIT_OFF } from '../constants/Action';


const initialState = {
    openId: '',
    toolkitOff:true
}

const openTookitReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOOLKIT_OPEN: {
            // console.log(action.payload,'payy');
            return {
                openId: action.payload,
            };
        }case TOOLKIT_OFF: {
            // console.log(action.payload,'payy');
            return {
                toolkitOff: action.payload,
            };
        }
        default:
            return state;
    }
};

export default openTookitReducer;