import React, { Component } from "react";
import { Animated, Dimensions, View } from "react-native";
import ButtonStyle from "../constants/ButtonStyle";
// import SkeletonContent from 'react-native-skeleton-content';
import Colors from "../constants/Colors";

class ShadowSkeletonComment extends React.Component {
  constructor(props) {
    super(props);
    this.Pagescrollview = null;
    this.circleAnimatedValue = new Animated.Value(0);
  }

  componentDidMount() {
    this.circleAnimated();
  }

  circleAnimated = () => {
    this.circleAnimatedValue.setValue(0);
    Animated.timing(this.circleAnimatedValue, {
      toValue: 1,
      duration: 1000
    }).start(() => {
      setTimeout(() => {
        this.circleAnimated();
      }, 1000);
    });
  };

  render() {
    const translateX = this.circleAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-10, Dimensions.get("window").width]
    });
    const translateTitle = this.circleAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-10, Dimensions.get("window").width]
    });
    return (
      <View>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
          return (
            <View style={[ButtonStyle.normalFeedStyleForDashboard, {
              height: 200,
              backgroundColor:"rgba(0,0,0,0.03)",
              // marginLeft:10
    
            }]}></View>
            // <SkeletonContent

            //   containerStyle={{
            //     width: Dimensions.get('window').width >= 1200 ? "94%" : "96%",
            //     marginLeft: Dimensions.get('window').width >= 1200 ? 15 : 8,
            //     marginTop: Dimensions.get('window').width >= 1200 ? 15 : 10,
            //     // marginBottom: Dimensions.get('window').width >= 1200 ? 15 : 10,
            //     height: 100
            //   }}
            //   boneColor={"rgba(0,0,0,0.03)"}
            //   highlightColor ={Colors.greyBackground}
            //   layout={[{ width: '100%', height: 100, borderRadius: 20 }]}
            //   animationDirection="horizontalRight"
            //   isLoading={true}
            // />
            // <View
            //   style={[
            //     ButtonStyle.normalFeedStyleForDashboard,
            //     {
            //       height: 100,
            //       // marginTop: 10,
            //       backgroundColor: "rgba(227, 227, 227, 0.3)",
            //       overflow: "hidden",                 
            //       width: '100%',
            //       borderRadius:0,
            //       marginLeft:0
            //     }
            //   ]}
            //   key={index}
            // >
            //   <Animated.View
            //     style={[
            //       ButtonStyle.borderStyle,
            //       {
            //         width: "50%",
            //         height: 100,
            //         backgroundColor: "#e3e3e3",
            //         transform: [{ translateX: translateTitle }]
            //       }
            //     ]}
            //   ></Animated.View>
            // </View>
          );
        })}
      </View>
    );
  }
}
export default ShadowSkeletonComment;
