import { HIGHLIGHT_ID } from '../constants/Action';


const initialState = {
    id: '',

}

const commentIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case HIGHLIGHT_ID: {
            // console.log(action.payload, 'payloaddd');
            return {
                id: action.payload,
            };
        }
        default:
            return state;
    }
};

export default commentIdReducer;