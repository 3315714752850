import "firebase/auth";
import React, { Component } from "react";
import {
  Text,
  TouchableOpacity,
  View,  
} from "react-native";
import { connect } from "react-redux";
import { setCreateAccount } from "../actionCreator/CreateAccountAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import { setVERIFYEMAILMODALACTION } from "../actionCreator/VerifyEmailModalAction";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
import {setLoginStatus} from "../actionCreator/UserAction";
import { setAdminStatus } from "../actionCreator/AdminAction";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";


class EarningModalMsg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "unsend",
      buttonName: "Creating account...",
      disableBtn: true,
      UserName: ""
    };
  }

  render() {
    return (
      <View
        style={{
          backgroundColor: "#fff",
          borderColor: "#c5c5c5",
          borderRadius: 6
        }}
      >
        <View>
          <Hoverable>
            {isHovered => (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flex: 1,
                  position: "absolute",
                  zIndex: 999999,
                  left: 0,
                  top: 0
                }}
                onPress={this.props.onClose}
              >
                <Icon
                  color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  reverse
                  name="close"
                  type="antdesign"
                  size={16}
                />
              </TouchableOpacity>
            )}
          </Hoverable>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "#000",
              alignItems: "center",
              height: 50,
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
              width: "100%"
            }}
          >
            <Text style={[styles.TextHeaderStyle, { color: "#fff" }]}>
              Earnings
            </Text>
          </View>
        </View>
        <View
          style={{
            width: "100%",
            padding: 30,
            flex: 1,
            alignItems: "center"
          }}
        >
          <View
            style={{
              overflow: "visible",
              width: "100%",
              backgroundColor: "#fff",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <View>
              <Text style={styles.textStyle}>
                How much you earn depends on a few factors.
              </Text>
              <Text style={styles.textStyle}>
                1) The type and amount of likes you received on your comments
              </Text>
              <Text style={styles.inlinetextStyle}>
                2) Whether you have a subscription
              </Text>
              <Text style={styles.inlinetextStyle}>
                3) Whether each reader has a subscription
              </Text>
              <Text style={styles.inlinetextStyle}>
                4) The amount of likes each reader gives out
              </Text>
              <Text style={styles.textStyle}>
                We calculate the total earnings at the end of the month.
              </Text>
              <Text style={styles.inlinetextStyle}>
                For the current month's earnings, we estimate it based off of
                past data.
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  getCreateAccount: state.CreateAccountReducer.get("setCreateAccountData"),
  getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension")
});

const mapDispatchToProps = dispatch => ({
  setVerifyEmailModalStatus: payload =>
    dispatch(setVERIFYEMAILMODALACTION(payload)),
  setSignUpModalStatus: payload => dispatch(setSIGNUPMODALACTION(payload)),
  setCreateAccount: payload => dispatch(setCreateAccount(payload)),
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  changeAdminStatus: payload => dispatch(setAdminStatus(payload)),
  SignUpFollowModalStatus: payload =>
    dispatch({ type: "SIGNUP_FOLLOW_MODAL", payload }),
  setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload)),
  setMessageModalStatus: payload =>
    dispatch({ type: "MESSAGEMODALSTATUS", payload }),
  setInviteUserDetail: payload =>
    dispatch({ type: "SET_INVITE_USER_DETAIL", payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(EarningModalMsg);
export const styles = {
  TextHeaderStyle: {
    fontSize: 23,
    color: "#000",
    textAlign: "center",
    fontFamily: ConstantFontFamily.defaultFont
  },
  StaticTextStyle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: ConstantFontFamily.defaultFont
  },
  textStyle: {
    marginTop: 20,
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 15,
    fontFamily: ConstantFontFamily.defaultFont
  },
  inlinetextStyle: {
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 15,
    fontFamily: ConstantFontFamily.defaultFont
  }
};
