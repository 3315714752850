// import firebase from "firebase/app";
import { initializeApp } from 'firebase/app';
// import "firebase/auth";
import getEnvVars from '../environment';
import { getAuth } from "firebase/auth";

const env = getEnvVars();

// Initialize Firebase
const firebaseConfig = {
    apiKey: env.APIKEY,
    authDomain: env.AUTHDOMAIN,
    databaseURL: env.DATABASEURL,
    projectId: env.PROJECTID,
    storageBucket: env.STORAGEBUCKET,
    messagingSenderId: env.MESSAGINGSENDERID,
    appId: env.APPID,
    measurementId: env.MEASUREMENTID,
};

console.log(firebaseConfig,'firebaseConfig');

const firebase = initializeApp(firebaseConfig);

const auth = getAuth(firebase);

export default firebase;