import React, { Component } from "react";
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import ConstantFontFamily from "../constants/FontFamily";
import NavigationService from "../library/NavigationService";
import ButtonStyle from "../constants/ButtonStyle";

const expr = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

class SignupInvite extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      textStyle: "WHITE",
      email: "",
      description: "",
      inviteCode: "",
      errorMessage: "",
      typingUsername: false,
      errorForm: true
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  checkUssername = async username => {
    this.setState({
      typingUsername: true
    });
    return await this.ValidateUsername(username);
  };

  ValidateUsername = username => {
    if (!expr.test(username)) {
      let msg =
        "Only Alphabets, Numbers and Underscore and between 3 to 16 characters.";
      this.setState({
        errorMessage: msg
      });
    } else {
      this.setState({
        email: username,
        errorMessage: "",
        errorForm: false
      });
    }
  };

  formSubmit = () => {
    if (this.state.errorForm == false) {
      this.props.handleInviteSignButton();
    }
  };

  render() {
    const textStyle = styles.usertext;
    return (
      <View
        style={{
          width: "100%"
        }}
      >
        <Hoverable>
          {isHovered => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                flex: 1,
                position: "absolute",
                zIndex: 999999,
                left: 0,
                top: 0
              }}
              onPress={this.props.onClose}
            >
              <Icon
                color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                iconStyle={{
                  color: "#fff",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                reverse
                name="close"
                type="antdesign"
                size={16}
              />
            </TouchableOpacity>
          )}
        </Hoverable>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#000",
            alignItems: "center",
            height: 50,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6
          }}
        >
          <Image
            source={
              Platform.OS == "web" &&
              this.props.getCurrentDeviceWidthAction > 750
                ? require("../assets/image/weclickd-logo.png")
                : Platform.OS == "web"
                ? require("../assets/image/weclickd-logo.png")
                : require("../assets/image/weclickd-logo-only-icon.png")
            }
            style={
              Platform.OS == "web" &&
              this.props.getCurrentDeviceWidthAction > 750
                ? {
                    height: 30,
                    width: Platform.OS == "web" ? 90 : 30,
                    padding: 0,
                    margin: 0,
                    marginVertical: 10
                  }
                : {
                    height: 30,
                    width: Platform.OS == "web" ? 90 : 30,
                    padding: 0,
                    margin: 0,
                    marginVertical: 10
                  }
            }
          />
        </View>
        <View
          style={{
            borderRadius: 4,
            overflow: "visible",
            width: "100%",
            backgroundColor: "#fff"
          }}
        >
          <ScrollView
            style={{
              backgroundColor: "#fff",
              borderBottomLeftRadius: 6,
              borderBottomRightRadius: 6
            }}
          >
            <View style={{ flex: 1, margin: 10 }}>
              <Text
                style={{
                  marginTop: 10,
                  fontWeight: "bold",
                  fontSize: 20,
                  fontFamily: ConstantFontFamily.defaultFont,
                  textAlign: "center"
                }}
              >
                Weclikd is in Private Beta
              </Text>

              <Text
                style={{
                  marginTop: 10,
                  fontWeight: "bold",
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                  textAlign: "center"
                }}
              >
                Weclikd is an invite-only community for insightful
              </Text>

              <Text
                style={{
                  marginTop: 10,
                  fontWeight: "bold",
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                  textAlign: "center"
                }}
              >
                discussions where users earn money for their comments.
              </Text>

              <TouchableOpacity
                onPress={() => {
                  this.props.onClose();
                  NavigationService.navigate("faq");
                }}
              >
                <Text
                  style={{
                    marginTop: 10,
                    fontWeight: "bold",
                    fontSize: 14,
                    fontFamily: ConstantFontFamily.defaultFont,
                    textAlign: "center",
                    textDecorationLine: "underline"
                  }}
                >
                  Learn More
                </Text>
              </TouchableOpacity>

              <View style={{ flexDirection: "row", width: "100%" }}>
                <View
                  style={{
                    width: "70%",
                    justifyContent: "flex-start",
                    flexDirection: "row"
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      marginTop: 10,
                      marginRight: 20,
                      fontSize: 16,
                      fontFamily: ConstantFontFamily.defaultFont
                    }}
                  >
                    Your Email
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: "row" }}>
                {/* <TextInput
                  value={this.state.email}
                  placeholder="Email"
                  placeholderTextColor="#6D757F"
                  style={[
                    textStyle,
                    {
                      width: "100%",
                      borderRadius: 6,
                      borderColor: "#e1e1e1",
                      borderWidth: 1,
                      marginTop: 5,
                      height: 45,
                      padding: 5,
                    },
                  ]}
                  onChangeText={(email) => this.setState({ email })}
                /> */}

                <View
                  style={{
                    marginVertical: 10,
                    flexDirection: "row",
                    height: 45,
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  <TextInput
                    onChangeText={UserName => this.checkUssername(UserName)}
                    placeholder="Email"
                    underlineColorAndroid="transparent"
                    style={{
                      height: 45,
                      paddingLeft: 10,
                      borderWidth: 1,
                      borderRadius: 5,
                      color: "#000",
                      backgroundColor: "#fff",
                      fontFamily: ConstantFontFamily.defaultFont,
                      width: "100%"
                    }}
                  />
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "flex-end"
                    }}
                  >
                    {this.state.typingUsername == true ? (
                      this.state.errorMessage == "" ? (
                        <Image
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: 10
                          }}
                          source={require("../assets/image/success.png")}
                        />
                      ) : (
                        <Image
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: 10
                          }}
                          source={require("../assets/image/close-red.png")}
                        />
                      )
                    ) : (
                      <Image
                        style={{
                          width: 20,
                          height: 20,
                          marginRight: 10
                        }}
                        source={require("../assets/image/close-red.png")}
                      />
                    )}
                  </View>
                </View>
              </View>
              <Text
                style={{
                  fontWeight: "bold",
                  marginTop: 10,
                  marginRight: 20,
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Invitation Code (Optional)
              </Text>
              <TextInput
                placeholder="Referrer's username or code from Weclikd"
                value={this.state.inviteCode}
                style={[
                  textStyle,
                  {
                    width: "100%",
                    borderRadius: 6,
                    borderColor: "#e1e1e1",
                    borderWidth: 1,
                    marginTop: 5,
                    height: 45,
                    padding: 5
                  }
                ]}
                onChangeText={inviteCode => this.setState({ inviteCode })}
              />
              <View style={{ flexDirection: "row", width: "100%" }}>
                <View
                  style={{
                    width: "70%",
                    justifyContent: "flex-start",
                    flexDirection: "row"
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      marginTop: 10,
                      marginRight: 20,
                      fontSize: 16,
                      fontFamily: ConstantFontFamily.defaultFont
                    }}
                  >
                    Why you're interested in Weclikd (Optional){" "}
                    {this.state.errorForm.toString()}
                  </Text>
                </View>
              </View>
              <TextInput
                value={this.state.description}
                multiline={true}
                numberOfLines={5}
                placeholder="Write a private message to the founders"
                placeholderTextColor="#6D757F"
                style={[
                  textStyle,
                  {
                    width: "100%",
                    borderRadius: 6,
                    borderColor: "#e1e1e1",
                    borderWidth: 1,
                    marginTop: 5,
                    padding: 5,
                    height: Platform.OS == "ios" ? 100 : null
                  }
                ]}
                onChangeText={description => this.setState({ description })}
              />

              <View
                style={{
                  marginTop: 20,
                  alignSelf: "center"
                }}
              >
                <Button
                  title="Request Invite"
                  titleStyle={ButtonStyle.titleStyle}
                  buttonStyle={ButtonStyle.backgroundStyle}
                  containerStyle={ButtonStyle.containerStyle}
                  disabled={this.state.errorForm ? true : false}
                  onPress={this.formSubmit}
                />
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension")
});

export default compose(connect(mapStateToProps, null))(SignupInvite);

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: "100%"
  },
  usertext: {
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },
  userblacktext: {
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },
  inputIOS: {
    paddingTop: 13,
    paddingHorizontal: 10,
    paddingBottom: 12,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 4,
    backgroundColor: "white",
    color: "black",
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },
  inputAndroid: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: "#fff",
    borderRadius: 8,
    color: "#000",
    backgroundColor: "white",
    paddingRight: 30,
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  }
});
