import { WINDOW_RESIZE } from '../constants/Action';


const initialState = {
  windowResize: {
    width: '',
    height: ''
  },
}

const windowResizeReducer = (state = initialState, action) => {

  switch (action.type) {
    case WINDOW_RESIZE: {
      // console.log(action.payload);
      return {
        windowResize: action.payload,
      };
    }
    default:
      return state;
  }
};

export default windowResizeReducer;