import React, { useEffect, useState } from "react";
import {
  Image,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { Icon, Button } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { postLink } from "../actionCreator/LinkPostAction";
import { setSHARELINKMODALACTION } from "../actionCreator/ShareLinkModalAction";
import applloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import { UrlInfoMutation } from "../graphqlSchema/graphqlMutation/PostMutation";
import { UrlInfoVariables } from "../graphqlSchema/graphqlVariables/PostVariables";
import NavigationService from "../library/NavigationService";
import ProgressBar from "./ProgressBar";
import ButtonStyle from "../constants/ButtonStyle";
import { screen } from '../reducers/action/Completed'

const LinkAddAlertCard = props => {
  let textInput = null;
  useEffect(() => {
    return handleClick();
  }, []);
  const [link, setLink] = useState("");
  const [Error, setError] = useState("");
  const [loading, setloading] = useState(false);
  let [value, setvalue] = useState(0);

  async function getProgress() {
    if (value < 100) {
      setvalue(value + 100);
    }
  }

  function isUrl(s) {
    var regexp = /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }

  async function checkLink() {
    if (link.startsWith("https") == false) {
      setError("All links must start with https.");
      return false;
    }
    if (isUrl(link) == false) {
      setError("Not a valid URL");
      return false;
    }
  }

  async function getContent() {
    if (link.startsWith("https") == false) {
      setError("All links must start with https.");
      return false;
    }
    if (isUrl(link) == false) {
      setError("Not a valid URL");
      return false;
    }
    console.log(link, 'link');
    UrlInfoVariables.variables.link = link;
    await getProgress();
    setloading(true);
    var sendDate = new Date().getTime();
    try {
      applloClient
        .query({
          query: UrlInfoMutation,
          ...UrlInfoVariables,
          fetchPolicy: "no-cache"
        })
        .then(async res => {

          setError("");


          if (res.data.post_unfurl.status.status != "ALREADY_EXISTS") {
            await props.postlink({
              description: res?.data?.post_unfurl?.post?.summary != null ? res?.data?.post_unfurl?.post?.summary : "",
              image: res?.data?.post_unfurl?.post.thumbnail_url ? res.data.post_unfurl.post.thumbnail_url : "",
              title: res?.data?.post_unfurl?.post?.title ? res.data.post_unfurl.post.title : "",
              url: res.data.post_unfurl.post.link,
              withoutUrl: false
            });
            await NavigationService.navigate("createpost");
            setLink("");
            props.setShareLinkModalStatus(false);
            var receiveDate = await new Date().getTime();
            var responseTimeMs = receiveDate - sendDate;
            let timer = setTimeout(() => {
              setloading(false);
            }, responseTimeMs);

          }
          else {
            props.postlink({
              description: "",
              image: "",
              title: "",
              url: link,
              withoutUrl: false
            });
            setloading(false);
            setLink("");
            props.setShareLinkModalStatus(false);
            NavigationService.navigate("feedId", { id: res.data.post_unfurl.post.id.replace("Post:", "") });
          }

        })
        .catch(e => {
          props.postlink({
            description: "",
            image: "",
            title: "",
            url: link,
            withoutUrl: false
          });
          setloading(false);
          setLink("");
          props.setShareLinkModalStatus(false);
          NavigationService.navigate("createpost");
        });
    } catch (e) {
      props.postlink({
        description: "",
        image: "",
        title: "",
        url: link,
        withoutUrl: false
      });
      setloading(false);
      setLink("");
      props.setShareLinkModalStatus(false);
      NavigationService.navigate("createpost");
    }
  }

  function checkEnter(e) {
    var code = e.keyCode || e.which;
    if (code === 13) {
      getContent();
    }
  }

  function handleClick() {
    textInput.focus();
  }

  function navigatePost() {
    props.postlink({
      description: "",
      image: "",
      title: "",
      url: "",
      withoutUrl: true
    });
    setloading(false);
    setLink("");
    props.setShareLinkModalStatus(false);
    NavigationService.navigate("createpost");
  }
  return (
    <View
      style={{
        width: "100%",
      }}
    >
      <Hoverable>
        {isHovered => (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              flex: 1,
              position: "absolute",
              zIndex: 999999,
              left: 0,
              top: 0
            }}
            onPress={props.onClose}
          >
            <Icon
              color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
              iconStyle={{
                color: "#fff",
                justifyContent: "center",
                alignItems: "center"
              }}
              reverse
              name="close"
              type="antdesign"
              size={16}
            />
          </TouchableOpacity>
        )}
      </Hoverable>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#000",
          alignItems: "center",
          height: 50,
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
        }}
      >

        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <Image
            source={require("../assets/image/logolastOne.png")}
            style={{
              height: 35,
              width: 35,
              marginRight: 5,
              borderRadius: 8
            }}
            resizeMode={"contain"}
          />
          <Text
            style={[ButtonStyle.profileTitleStyle, {
              fontSize: 20,
              textAlign: "center",
              color: "white",
              fontWeight: 'bold'
            }]}
          >
            weclikd
          </Text>
        </View>
      </View>
      <View
        style={{
          borderRadius: 0,
          overflow: "visible",
          width: "100%",
          backgroundColor: "#fff",
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6
        }}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6
          }}
        >
          <ProgressBar progress={loading} value={value} />
          <View style={{ flex: 1, margin: 20, marginVertical: 40 }}>

            <TextInput
              maxLength={250}
              onChangeText={value => {
                setLink(value);
              }}
              onKeyPress={checkEnter}
              value={link}
              autoFocus={true}
              placeholder="Share an article worthy a discussion."
              placeholderTextColor="#53555A"
              underlineColorAndroid="transparent"
              style={[ButtonStyle.textAreaShadowStyle, {
                width: "100%",
                alignSelf: "center",
                height: 40,
                paddingLeft: 10,
                fontFamily: ConstantFontFamily.defaultFont,
                textAlign: "left",
                outline: 'none'
              }]}
              ref={e => {
                textInput = e;
              }}
            />
            {link.length >= 250 ?
              <View style={{ alignItems: 'flex-end' }}>
                <Text style={{
                  marginTop: 5,
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                  color: "#eb3225",
                }}>URL is too long. Maximum of 250 characters</Text>
              </View>
              : null}
          </View>

          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            {loading == false ? (
              <Button
                title="Share"
                titleStyle={ButtonStyle.wtitleStyle}
                buttonStyle={ButtonStyle.gbackgroundStyle}
                containerStyle={[ButtonStyle.containerStyle, { marginVertical: 0 }]}
                disabled={link == "" ? true : false}
                onPress={() => {
                  props.screen('createPost')
                  getContent()
                }}
              />
            ) : (
              <Text
                style={{
                  color: "#000",
                  justifyContent: "center",
                  alignSelf: "center",
                  marginBottom: 20,
                  fontWeight: "bold",
                  fontFamily: ConstantFontFamily.defaultFont,
                  fontSize: 14
                }}
              >
                Analyzing Link...
              </Text>
            )}
          </View>
          <Text
            style={{
              color: "red",
              justifyContent: "center",
              alignSelf: "center",
              marginBottom: 15,
              fontWeight: "bold",
              fontFamily: ConstantFontFamily.defaultFont,
              fontSize: 14
            }}
          >
            {Error}
          </Text>

          <View
            style={{
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              height: 20,
              marginVertical: 10
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e1e1e1",
                width: "43%",
                justifyContent: "flex-start",
                height: 1,
                borderRadius: 6,
                backgroundColor: "#e1e1e1"
              }}
            ></View>
            <Text
              style={{
                fontSize: 16,
                color: "#49525D",
                textAlign: "center",
                marginHorizontal: "4%",
                marginVertical: "10%",
                fontFamily: ConstantFontFamily.defaultFont
              }}
            >
              or
            </Text>
            <View
              style={{
                borderWidth: 1,
                borderColor: "#e1e1e1",
                width: "43%",
                justifyContent: "flex-end",
                height: 1,
                borderRadius: 6,
                backgroundColor: "#e1e1e1"
              }}
            ></View>
          </View>
          <Text
            style={{
              color: "#000",
              justifyContent: "center",
              alignSelf: "center",
              marginTop: 40,
              fontWeight: "bold",
              fontFamily: ConstantFontFamily.defaultFont,
              fontSize: 14
            }}
          >
            Create your own discussion prompt.
          </Text>
          <Button
            testID="create1"
            title="Create"
            titleStyle={ButtonStyle.titleStyle}
            buttonStyle={ButtonStyle.backgroundStyle}
            containerStyle={[ButtonStyle.containerStyle, { marginTop: 40, marginBottom: 50 }]}
            onPress={() => {
              props.screen('createPost')
              navigatePost()
            }}
          />
        </ScrollView>
      </View>
    </View>
  );
};



const mapDispatchToProps = dispatch => ({
  postlink: payload => dispatch(postLink(payload)),
  setShareLinkModalStatus: payload =>
    dispatch(setSHARELINKMODALACTION(payload)),
  screen: (payload) => dispatch(screen(payload))
});

export default compose(connect(null, mapDispatchToProps))(
  LinkAddAlertCard
);