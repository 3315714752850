import { fromJS } from "immutable";
import { POSTDETAILS_SUCCESS, POSTDETAILS_FAILURE } from "../constants/Action";

const initialState = fromJS({
  PostDetails: null,
  setComment: false,
  getNotificationId:''
});

export default function PostDetailsReducer(state = initialState, action) {
  const { type, payload, comment } = action;
  switch (type) {
    case POSTDETAILS_SUCCESS:
      return state.set("PostDetails", payload.data);
    case POSTDETAILS_FAILURE:
      return initialState;
    case "SET_COMMENT": {
      return state.set("setComment",payload);
    }
    case "GET_NOTIFICATION_ID":
      return state.set("getNotificationId", fromJS(payload));
  }
  return state;
}
