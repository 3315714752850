
import React, { lazy, Suspense, useState, useRef } from "react";
import { retry } from "../library/Helper";
import {
  Text,
  TouchableOpacity,
  View,
  Dimensions,
} from "react-native";
import applloClient from "../client";
import { graphql } from "react-apollo";

import { Icon, Button } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";


import ProgressBar from "./ProgressBar";

import ButtonStyle from "../constants/ButtonStyle";

import { PostShareMutation } from '../graphqlSchema/graphqlMutation/PostMutation'



const ShareToComponent = lazy(() =>
  retry(() => import("./ShareToComponent"))
);



const PostShareModel = props => {

  const [loading, setloading] = useState(false);
  let [value, setvalue] = useState(0);

  let [topic, setTopic] = useState('')
  let [clikData, setClikData] = useState([])
  let [topicData, setTopicData] = useState([])
  let [userData, setUserData] = useState([])
  let [TopicList, setTopicList] = useState([])
  let [ClikList, setClikList] = useState([])
  let [UserList, setUserList] = useState([])
  let textFocus = useRef(null)










  const handleTopicSelectInput = (topic) => {
    
    if (topicData.length < 3) {
      let index = topicData.findIndex((i) => i.name == topic);
      if (index != -1) {
        alert("topic name already selected");
      } else {
        setTopicData(topicData.concat([{ name: topic }]))
        setTopic('')
        setTopicList([])
        setClikList([])

      }
    } else {
      alert("You can only choose Maximum 3 Topics to Tag");
      setTopic('')
    }
  };



  const handleClikSelectInput = (clik) => {
    

    if (clikData.length < 3) {
      let index = clikData.findIndex((i) => i.name == clik);
      if (index != -1) {
        alert("clik name already selected");
      } else {
        setClikData(clikData.concat([{ name: clik }]))
        setClikList('')
        setTopic('')
        setClikList([])
        setTopicList([])
        setUserList([])

      }
    } else {
      alert("You can only choose Maximum 3 Cliks to Tag");
      setClikList('')

    }
  };
  const handleUserSelectInput = (user) => {
    
    if (userData.length < 3) {
      let index = userData.findIndex((i) => i.name == user);
      if (index != -1) {
        alert("clik name already selected");
      } else {
        setUserData(userData.concat([{ name: user }]))
        setClikList('')
        setTopic('')
        setClikList([])
        setTopicList([])
        setUserList([])
      }
    }
  }

  const sharePost = async () => {

    let usersData = []

    let cliks = []
    let topics = []


    
 

    topicData.map(e=>{
      let topic= "Topic:" + e.name
      topics.push(topic)
    })

    clikData.map(e=>{
      let clik= "Clik:" +e.name
      cliks.push(clik)
    })

    userData.forEach(e => {

      let name = "User:" + e.name
      usersData.push(name)
    })



    await applloClient
      .query({
        query: PostShareMutation,
        variables: {
          post_id: props.sharePostId,
          cliks: cliks.length > 0 ? cliks : null,
          topics: topics.length > 0 ? topics : null,
          users: usersData.length > 0 ? usersData : null

        },
        fetchPolicy: "no-cache",
      }).then(e => {

      })



  }




  const shareComment = async () => {

    let usersData = []

    userData.forEach(e => {
      console.log(e);
      let name = "User:" + e.name
      usersData.push(name)
    })



    await applloClient
      .query({
        query: PostShareMutation,
        variables: {
          post_id: props.sharePostId,
          cliks: clikData.length > 0 ? clikData : null,
          topics: topicData.length > 0 ? topicData : null,
          users: usersData.length > 0 ? usersData : null

        },
        fetchPolicy: "no-cache",
      }).then(e => {
        console.log(e, 'postsharequery');
      })

  }




  return (
    <View
      style={{
        width: "100%",
        height: Dimensions.get('window').width >= 750 ? 480 : 500,
      }}
    >
      <Hoverable>
        {isHovered => (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              flex: 1,
              position: "absolute",
              zIndex: 999999,
              left: 0,
              top: 0
            }}
            onPress={props.onClose}
          >
            <Icon
              color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
              iconStyle={{
                color: "#fff",
                justifyContent: "center",
                alignItems: "center"
              }}
              reverse
              name="close"
              type="antdesign"
              size={16}
            />
          </TouchableOpacity>
        )}
      </Hoverable>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#000",
          alignItems: "center",
          height: 50,
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
        }}
      >
        <Text
          style={[ButtonStyle.profileTitleStyle, {
            fontSize: 20,
            textAlign: "center",
            color: "white",
            fontWeight: 'bold'
          }]}
        >
          {props.sharePostId.startsWith("P") ? "Share Post" : 'Share Comment'}

        </Text>
      </View>
      <View
        style={{
          borderRadius: 0,
          overflow: "visible",
          width: "100%",
          backgroundColor: "#fff",
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6
        }}
      >
        <ProgressBar progress={loading} value={value} />
        <View style={{ marginHorizontal: 20, marginVertical: 20 }}>
          <Suspense fallback={null}>
            <ShareToComponent
              type={props.sharePostId.startsWith("P") ? "sharePost" : props.sharePostId.startsWith("C") ? "shareComment" : null}
              handleUserSelectInput={handleUserSelectInput}
              handleClikSelectInput={handleClikSelectInput}
              handleTopicSelectInput={handleTopicSelectInput}
              textFocus={textFocus}
            />
          </Suspense>
          <Button
            title="Submit"
            titleStyle={ButtonStyle.titleStyle}
            buttonStyle={ButtonStyle.backgroundStyle}
            containerStyle={[ButtonStyle.containerStyle, { marginTop: 40, marginBottom: 50 }]}
            onPress={() => {
              if (props.sharePostId.startsWith("P")) {
                sharePost()
              } else {
                shareComment()
              }
              props.onClose()

            }}
          />


        </View>


      </View>


    </View>
  );
};

const mapStateToProps = state => ({
  sharePostId: state.PostShareReducer.get("postShareId"),
});

const PostShareModelWrapper = graphql(PostShareMutation, {
  name: "Post",
  options: { fetchPolicy: "no-cache" }
})(PostShareModel);



export default compose(connect(mapStateToProps, null))(
  PostShareModelWrapper
);