import { fromJS } from "immutable";
import {
  ADMIN_STATUS_SUCCESS,
  ADMIN_STATUS_FAILURE,
  ADMIN_VIEW_SUCCESS,
  GETFEED_SUCCESS
} from "../constants/Action";

const initialState = fromJS({
  isAdmin: false,
  isAdminView: false,
  searchBarStatus: false,
  setKeyEvent: "",
  setDisplayType: "",
  inviteUserDetails: {},
  messageModalStatus: false,
  loginButtonText: "Login",
  googleLogin: false,
  earningModalStatus: false,
  isAnonomyousLogin: false,
  anonymousToken: "",
  tabType: "",
  openCreateCommentModal: false,
  leftPanelOpenStatus: false,
  searchBarOpenStatus: false,
  analyticsTabType: "",
  profileTabType: "",
  notificationCount: 0,
  commentLevel: 2,
  iconUpload: false,
  profileUpload: false,
  updatedPostId: 0,
  showComment: false,
  focusCreateComment: false,
  feedIconUpload: false,
  topicIconUpload: false,
  setClikTabView: false,
  clikCardShow: false,
  cardDetails: {},
  setIndex: 0,
  isfeed: false
});

export default function AdminReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_STATUS_SUCCESS:
      return state.set("isAdmin", fromJS(payload));
    case ADMIN_VIEW_SUCCESS:
      return state.set("isAdminView", fromJS(payload));
    case "SEARCH_BAR_STATUS":
      return state.set("searchBarStatus", payload);
    case ADMIN_STATUS_FAILURE:
      return initialState;
    case "SET_KEY_EVENT":
      return state.set("setKeyEvent", fromJS(payload));
    case "SET_DISPLAY_TYPE":
      return state.set("setDisplayType", fromJS(payload));

    case "SET_INVITE_USER_DETAIL":
      return state.set("inviteUserDetails", fromJS(payload));

    case "MESSAGEMODALSTATUS":
      return state.set("messageModalStatus", fromJS(payload));

    case "SET_LOGIN_BUTTON_TEXT":
      return state.set("loginButtonText", fromJS(payload));

    case "SET_GOOGLE_LOGIN":
      return state.set("googleLogin", fromJS(payload));

    case "EARNINGMODALSTATUS":
      return state.set("earningModalStatus", fromJS(payload));

    case "ANONYMOUS_USER":
      return state
        .set("isAnonymousLogin", fromJS(payload.value))
        .set("anonymousToken", fromJS(payload.token));

    case "SET_TAB_VIEW":
      return state.set("tabType", fromJS(payload));

    case "OPEN_CREATE_COMMENT":
      return state.set("openCreateCommentModal", fromJS(payload));

    case "LEFT_PANEL_OPEN":
      return state.set("leftPanelOpenStatus", fromJS(payload));

    case "SEARCHBAR_STATUS":
      return state.set("searchBarOpenStatus", fromJS(payload));

    case "SET_TAB_VIEW_ANALYTICS":
      return state.set("analyticsTabType", fromJS(payload));

    case "SET_PROFILE_TAB_VIEW":
      return state.set("profileTabType", fromJS(payload));

    case "NOTIFICATION":
      return state.set("notificationCount", fromJS(payload));

    case "SET_COMMENT_LEVEL":
      return state.set("commentLevel", fromJS(payload));

    case "ICON_UPLOAD":
      return state.set("iconUpload", fromJS(payload));
    case "PROFILE_UPLOAD":
      return state.set("profileUpload", fromJS(payload));

    case "SET_UPDATED_POSTID":
      // console.log(payload,'updatedPostId');
      return state.set("updatedPostId", fromJS(payload));

    case "SHOW_COMMENT":
      return state.set("showComment", fromJS(payload));
    case "FOCUS_CREATE_COMMENT":
      return state.set("focusCreateComment", fromJS(payload));

    case "FEED_ICON_UPLOAD":
      return state.set("feedIconUpload", fromJS(payload));
    case "TOPIC_ICON_UPLOAD":
      return state.set("topicIconUpload", fromJS(payload));
    case "SET_CLIK_TAB_VIEW":
      return state.set("setClikTabView", fromJS(payload));

    case "CLIK_CARD_SHOW":
      return state.set("clikCardShow", fromJS(payload));
    case "CARD_DETAILS":
      return state.set("cardDetails", fromJS(payload));
    case "SET_INDEX":
      return state.set("setIndex", fromJS(payload));
    case GETFEED_SUCCESS:
      return state.set("isfeed", fromJS(payload));
  }
  return state;
}
