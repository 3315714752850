import { createBrowserApp } from "@react-navigation/web";
import Stack from "../Navigation/Stack";
import { createDrawerNavigator } from "react-navigation-drawer";
import LeftPanel from "../components/LeftPanel";

const Drawer = createDrawerNavigator(
  {
    home: {
      screen: Stack,
      path: ""
    }
  },
  {
    initialRouteName: "home",
    contentComponent: LeftPanel,
    overlayColor: "rgba(0, 0, 0, 0.7)",
    drawerLockMode: 'locked-closed',
  }
);

export default createBrowserApp(Stack);