import { takeEvery, call, put } from 'redux-saga/effects';
import { HOMEFEED, HOMEFEED_SUCCESS, HOMEFEED_FAILURE } from "../constants/Action";
import applloClient from '../client';
import { HomeFeedMutation } from '../graphqlSchema/graphqlMutation/FeedMutation';


const getResponse = (req) => {
    return applloClient.query({
        query: HomeFeedMutation,
        variables: {
            first: req.currentPage,
            after: null,
            sort: "NEW",
            type:"Post"
        },
        fetchPolicy: 'no-cache'
    }).then(res => {
        return res
    });
};

export function* HomeFeedMiddleware({ payload }) {
    try {
        const response = yield call(getResponse, payload);
        yield put({
            type: HOMEFEED_SUCCESS,
            payload: response
        });
    } catch (err) {
        yield put({
            type: HOMEFEED_FAILURE,
            payload: err
        });
    }
    if (payload.identifier == 'reset') {
        yield put({
            type: HOMEFEED_FAILURE,
            payload: []
        });

    }
}

export default function* HomeFeedMiddlewareWatcher() {
    yield takeEvery(HOMEFEED, HomeFeedMiddleware);

}