import React, {lazy,Suspense, useState } from "react";
import { retry } from "../library/Helper";
import {
  Image,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Icon, Tooltip } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { setFEEDREPORTMODALACTION } from "../actionCreator/FeedReportModalAction";
import "../components/Firebase";
import ConstantFontFamily from "../constants/FontFamily";
import applloClient from "../client";
import { ContentReportMutation } from "../graphqlSchema/graphqlMutation/FeedMutation";
import ButtonStyle from "../constants/ButtonStyle";

const ReportCliksTopics = lazy(() =>
  retry(() => import("../components/ReportCliksTopics"))
);

const FeedReportModal = props => {
  const [offTopicActive, setoffTopicActive] = useState(false);
  const [lowQualityActive, setlowQualityActive] = useState(false);
  const [hateActive, sethateActive] = useState(false);
  const [submit, setsubmit] = useState(false);
  const [showhatetooltip, setshowhatetooltip] = useState(false);

  const [showCliksSubmit, setshowCliksSubmit] = useState([]);
  const [showTopicsSubmit, setshowTopicsSubmit] = useState([]);

  const offTopicBtn = () => {
    offTopicActive == false
      ? setoffTopicActive(true)
      : setoffTopicActive(false);
  };
  const lowQualityBtn = () => {
    lowQualityActive == false
      ? setlowQualityActive(true)
      : setlowQualityActive(false);
  };
  const hateBtn = () => {
    hateActive == false ? sethateActive(true) : sethateActive(false);
  };
  const submitReport = async id => {
    setsubmit(true);
    setoffTopicActive(false);
    setlowQualityActive(false);
    sethateActive(false);
    props.setFeedReportModalStatus(false);

    props.onClose();

    await applloClient.query({
      query: ContentReportMutation,
      variables: {
        content_id: id,
        report_type: "HATE",
        user_id: props.profileData.user.id,
        action_type: "WARN",
        message: "message"
      },
      fetchPolicy: "no-cache"
    });
  };

  const hatetooltip = () => {
    showhatetooltip == false
      ? setshowhatetooltip(true)
      : setshowhatetooltip(false);
  };

  const submitTopic = item => {
    return setshowTopicsSubmit(item);
  };
  const submitCliks = item => {
    return setshowCliksSubmit(item);
  };

  return (
    <View
      style={{
        width: "100%"
      }}
    >
      <Hoverable>
        {isHovered => (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              flex: 1,
              position: "absolute",
              zIndex: 999999,
              left: 0,
              top: 0
            }}
            onPress={props.onClose}
          >
            <Icon
              color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
              iconStyle={{
                color: "#fff",
                justifyContent: "center",
                alignItems: "center"
              }}
              reverse
              name="close"
              type="antdesign"
              size={16}
            />
          </TouchableOpacity>
        )}
      </Hoverable>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#000",
          alignItems: "center",
          height: 50,
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6
        }}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <Image
            source={require("../assets/image/logolastOne.png")}
            style={{
              height: 35,
              width: 35,
              marginRight: 5,
              borderRadius: 8
            }}
            resizeMode={"contain"}
          />
          <Text
            style={[ButtonStyle.profileTitleStyle, {
              fontSize: 20,
              textAlign: "center",
              color: "white",
              fontWeight: 'bold'
            }]}
          >
            weclikd
          </Text>
        </View>
      </View>
      <TouchableOpacity
        style={{
          borderRadius: 4,
          overflow: "visible",
          width: "100%",
          backgroundColor: "#000"
        }}
      >
        <ScrollView
          style={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              flex: 1,
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Text
              style={{
                alignSelf: "center",
                color: "#000",
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 18,
                fontWeight: "bold",
                marginVertical: 10
              }}
            >
              Report Content
            </Text>
            {Platform.OS == "web" ? (
              <View>
                <Icon
                  color={"#000"}
                  iconStyle={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 10
                  }}
                  name="info-circle"
                  type="font-awesome"
                  size={18}
                  onPress={() => hatetooltip()}
                />
                {showhatetooltip == true && (
                  <Tooltip
                    withPointer={false}
                    withOverlay={false}
                    toggleOnPress={true}
                    containerStyle={{
                      left: -40,
                      width: 100,
                      backgroundColor: "transparent",
                      borderColor: "#617080",
                      borderWidth: 1
                    }}
                    popover={
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontWeight: "bold"
                        }}
                      >
                        Info here
                      </Text>
                    }
                  />
                )}
              </View>
            ) : (
              <Tooltip
                withOverlay={false}
                popover={
                  <Text
                    style={{
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontWeight: "bold"
                    }}
                  >
                    Info here
                  </Text>
                }
              >
                <Icon
                  color={"#000"}
                  iconStyle={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 10
                  }}
                  name="info-circle"
                  type="font-awesome"
                  size={18}
                />
              </Tooltip>
            )}
          </View>

          <View
            style={{
              flexDirection: "row",
              width: "100%"
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                width: "33%"
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <TouchableOpacity
                  disabled={submit == true ? true : false}
                  onPress={() => offTopicBtn()}
                  style={{ flexDirection: "row" }}
                >
                  <Image
                    source={require("../assets/image/extraterrestrial-alien_1f47d.png")}
                    style={[
                      { height: 50, width: 50 },
                      offTopicActive && styles.ActiveImageStyle
                    ]}
                  />
                </TouchableOpacity>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      color: "#000",
                      marginVertical: 10,
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 14,
                      fontWeight: "bold"
                    }}
                  >
                    Off-Topic
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                width: "33%"
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <TouchableOpacity
                  disabled={submit == true ? true : false}
                  onPress={() => lowQualityBtn()}
                  style={{ flexDirection: "row" }}
                >
                  <Image
                    source={require("../assets/image/bugIcon.png")}
                    style={[
                      { height: 50, width: 50 },
                      lowQualityActive && styles.ActiveImageStyle
                    ]}
                  />
                </TouchableOpacity>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      color: "#000",
                      marginVertical: 10,
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 14,
                      fontWeight: "bold"
                    }}
                  >
                    Low Quality
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                width: "33%"
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <TouchableOpacity
                  disabled={submit == true ? true : false}
                  onPress={() => hateBtn()}
                  style={{ flexDirection: "row" }}
                >
                  <Image
                    source={require("../assets/image/skull_1f480.png")}
                    style={[
                      { height: 50, width: 50 },
                      hateActive && styles.ActiveImageStyle
                    ]}
                  />
                </TouchableOpacity>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      color: "#000",
                      marginVertical: 10,
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 14,
                      fontWeight: "bold"
                    }}
                  >
                    Hate
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {offTopicActive == true && (
            <View
              style={{
                flexDirection: "row",
                width: "100%"
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  width: "100%",
                  padding: 10
                }}
              >
                <Suspense fallback={null}>
                <ReportCliksTopics
                  topicsList={props.TopicList}
                  cliksList={props.ClikList}
                  submitTopic={submitTopic}
                  submitCliks={submitCliks}
                />
                </Suspense>
              </View>
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center"
            }}
          >
            <View
              style={{
                marginVertical: 20,
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "center"
              }}
            >
              {submit == false ? (
                <Button
                  title="Submit Report"
                  titleStyle={ButtonStyle.titleStyle}
                  buttonStyle={ButtonStyle.backgroundStyle}
                  containerStyle={ButtonStyle.containerStyle}
                  onPress={() => submitReport(props.PostId)}
                />
              ) : (
                <Text
                  style={{
                    color: "#000",
                    fontFamily: ConstantFontFamily.defaultFont,
                    fontSize: 16,
                    fontWeight: "bold"
                  }}
                >
                  Report Submitted !
                </Text>
              )}
            </View>
          </View>
        </ScrollView>
      </TouchableOpacity>
    </View>
  );
};

const mapStateToProps = state => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
});

const mapDispatchToProps = dispatch => ({
  setFeedReportModalStatus: payload =>
    dispatch(setFEEDREPORTMODALACTION(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedReportModal);

export const styles = {
  ActiveImageStyle: {
    borderColor: "green",
    borderWidth: 3,
    borderRadius: 25
  }
};
