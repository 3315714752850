import { fromJS } from 'immutable';
import { USERFEED_SUCCESS, USERFEED_FAILURE } from "../constants/Action";



const initialState = fromJS({
    UserFeedList: null,
});

export default function UserFeedReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USERFEED_SUCCESS:
            return state
                .set('UserFeedList', payload);
        case USERFEED_FAILURE:
            return initialState;
    }
    return state;
}