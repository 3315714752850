import React, { Component } from "react";
import {
  Animated,
  Dimensions,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import LoaderComponent from "../components/LoaderComponent";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
import {
  getFirebaseToken,
  getMyUserId,
  getWeclikdToken
} from "../library/Helper";

const apiUrlMain = getEnvVars();

class PayoutScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeParam: "",
      stateParam: "",
      loading: true
    };
  }

  componentDidMount() {
    let __self = this;
    if (
      this.props.navigation.state.params.code &&
      this.props.navigation.state.params.state
    ) {
      this.setState(
        {
          codeParam: this.props.navigation.state.params.code,
          stateParam: this.props.navigation.state.params.state
        },
        () => {
          this.checkoutAuth();
        }
      );
    }
    this.timer = setTimeout(() => {
      this.setState({
        loading: false
      });
      this.timerInner = setTimeout(() => {
        __self.props.navigation.navigate("settings");
      }, 3000);
    }, 5000);
  }

  componentWillUnmount() {
    if (this.timer) {
      this.timer.clearTimeout();
      this.timerInner.clearTimeout();
    }
  }

  auth = async () => {
    const firebaseToken = await getFirebaseToken();
    const tempToken = await getWeclikdToken();
    const tempCurrentUserId = await getMyUserId();
    let weclikdToken = tempToken ? tempToken : null;
    let CurrentUserId = tempCurrentUserId ? tempCurrentUserId : null;
    return await {
      firebaseToken,
      weclikdToken,
      CurrentUserId
    };
  };

  checkoutAuth = async () => {
    let apiUrl = apiUrlMain.API_URL + "plutus/payout/token";
    let apiUrlHeader = await this.auth();
    let headersSet = {
      "Weclikd-Authorization": encodeURI("Bearer " + apiUrlHeader.weclikdToken),
      Authorization: encodeURI("Custom " + apiUrlHeader.firebaseToken),
      code: this.state.codeParam,
      state: this.state.stateParam
    };
    let str = "";
    for (let key in headersSet) {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + headersSet[key];
    }
    const uri = apiUrl + "?" + str;
    let r = await fetch(uri).catch(e => {
      console.log(e);
    });
    return true;
  };

  _renderItem = item => {
    return (
      <Hoverable>
        {isHovered => (
          <TouchableOpacity
            style={{
              flexDirection: "column",
              height: 200,
              borderColor: "#000",
              borderWidth: 2,
              borderRadius: 6
            }}
          >
            <View
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 200
              }}
            >
              <View
                style={{
                  flexDirection: "row"
                }}
              >
                <Image
                  style={{
                    width: 50,
                    height: 50
                  }}
                  source={require("../../client/assets/image/weclickd-logo-only-icon.png")}
                />
              </View>
              <Text
                style={{
                  textAlign: "center",
                  color: isHovered == true ? "#009B1A" : "#000",
                  fontFamily: ConstantFontFamily.defaultFont,
                  marginTop: 10
                }}
              >
                {item.title}
              </Text>
            </View>
          </TouchableOpacity>
        )}
      </Hoverable>
    );
  };

  render() {
    const { loading } = this.state;
    return (
      <View style={styles.container}>
        <ScrollView
          ref={scrollview => {
            this.Pagescrollview = scrollview;
          }}
          showsVerticalScrollIndicator={false}
          onLayout={event => {
            let { x, y, width, height } = event.nativeEvent.layout;
            if (width < 1024) {
              this.setState({
                showsVerticalScrollIndicatorView: true,
                currentScreentWidth: width
              });
            } else {
              this.setState({
                showsVerticalScrollIndicatorView: false,
                currentScreentWidth: width
              });
            }
          }}
          style={{
            height:
              Platform.OS !== "web"
                ? null
                : Dimensions.get("window").height - 80
          }}
        >
          <View>
          {Platform.OS !== "web" ? (
            <Animated.View
              style={{
                position: Platform.OS == "web" ? "sticky" : null,
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                overflow: "hidden",
                borderRadius: 6
              }}
            >
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    marginBottom: 10,
                    backgroundColor: "#000",
                    borderRadius: 6,
                    height: 42
                  }}
                >
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignSelf: "center"
                    }}
                    onPress={() => {
                      this.props.searchOpenBarStatus(false)
                      let nav = this.props.navigation.dangerouslyGetParent()
                        .state;
                      if (nav.routes.length > 1) {
                        this.props.navigation.goBack();
                        return;
                      } else {
                        this.props.navigation.navigate("home");
                      }
                    }}
                  >
                    <Icon
                      color={"#fff"}
                      name="angle-left"
                      type="font-awesome"
                      size={40}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      flex: 1,
                      backgroundColor: "#000",
                      justifyContent: "center",
                      marginLeft: 20,
                      borderRadius: 6
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: 18,
                        fontFamily: ConstantFontFamily.defaultFont
                      }}
                    >
                      Payment
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Animated.View>
             ) : (
              <TouchableOpacity
                style={{
                  width: "100%",
                  justifyContent: "center",
                  borderRadius: 6,
                  height: 40
                }}
              >
                <Text
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  Payment
                </Text>
              </TouchableOpacity>
            )}
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  height: Dimensions.get("window").height - 80,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {loading == true ? (
                  <View>
                    <View>
                      <LoaderComponent />
                    </View>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 10
                      }}
                    >
                      <Text
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14
                        }}
                      >
                        Please Wait ...
                      </Text>
                    </View>
                  </View>
                ) : (
                  <View>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Image
                        style={{
                          width: 117,
                          height: 117
                        }}
                        source={require("../assets/image/success_payment.png")}
                      />
                    </View>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 10
                      }}
                    >
                      <Text
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14
                        }}
                      >
                        Your Transaction Was Successful – Thank You
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const mapDispatchToProps = dispatch => ({
searchOpenBarStatus: (payload) =>
dispatch({ type: "SEARCHBAR_STATUS", payload }),
})

export default compose(connect(null, mapDispatchToProps))(PayoutScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ConstantColors.customeBackgroundColor,
    padding: Platform.OS == "web" ? 10 : null
  }
});
