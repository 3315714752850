import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  // AsyncStorage,
  Dimensions,
  Image,
  Platform,
  Text,
  TouchableOpacity,
  View,
  Modal
} from "react-native";
import { Button, Icon } from "react-native-elements";
import {
  MenuOptions,
} from "react-native-popup-menu";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import "../assets/react-draft.css";
import applloClient from "../client";
import ButtonStyle from "../constants/ButtonStyle";
import ConstantFontFamily from "../constants/FontFamily";
import { CreateCommentMutation } from "../graphqlSchema/graphqlMutation/LikeContentMutation";
import { CreateCommentVariables } from "../graphqlSchema/graphqlVariables/LikeContentVariables";
import SearchInputComponent from "./SearchInputComment";
import { setTotalComment, setNestedTotalComment, setCommentStatus, showbox,parentCommentId } from '../reducers/action/Completed';
import { Popable } from 'react-native-popable';
import Colors from "../constants/Colors";


const CreateCommentCard = (props) => {
  const editorReference = useRef();
  const [title, settitle] = useState("");
  const [item, setitem] = useState([]);
  const [click, setclick] = useState(null);
  const [getBorderColor, setBorderColor] = useState("#e8e8e8");
  const [editorState, onChange] = useState(EditorState.createEmpty());
  const [placeholderState, setPlaceholderState] = useState(true);
  const [titleContent, setTitleContent] = useState("characters more");
  const [getSubmitData, setSubmitData] = useState("");
  const [getLockStatus, SetLockStatus] = useState(false);
  const [getSelectedItem, setSelectedItem] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedCliks, setSelectedCliks] = useState({});
  const [opened, setOpened] = useState(false);
  const [getFeedItem, setFeedItem] = useState("");
  const [isReadOnly, setisReadOnly] = useState(false);
  const SearchComment = useRef(null)
  let [clikData, setClikData] = useState([])
  let [userData, setUserData] = useState([])
  let [cancel, setCancel] = useState(false)
  const [searchnput, setSearchInput] = useState(false)
  const [SubmitColor, setSubmitColor] = useState(false)


  // const search = useRef()


  const MAX_LENGTH = 3000;

  useEffect(() => {
    if (props.parent_content_id != getFeedItem) {
      setFeedItem(props.parent_content_id);
      setSelectedCliks({});
      setSelectedUser([]);
      // this, props.focusCreateComment(false);
    }
  }, [props.parent_content_id]);

  useEffect(() => {
    if (props.initial == "child") {
      // editorReference.current.focusEditor();
    }
  });

  useEffect(() => {
    setSearchInput(false)
  }, [])


  const onEditorStateChange = (editorState) => {
    if (props.loginStatus == 0) {
      props.setLoginModalStatus(true);
      settitle("");
    } else {
      if (editorState.getCurrentContent().getPlainText("\u0001").length == 0) {
        setPlaceholderState(false);
      }
      const currentContent = editorState.getCurrentContent();
      const oldContent = editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText("").length;

      if (title.length < 3000) {
        onChange(editorState);
        settitle(editorState.getCurrentContent().getPlainText("\u0001"));
        title.length < 140
          ? setTitleContent("characters more")
          : setTitleContent("characters left");
        setSubmitData(
          draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
      } else {
        title.length < 140
          ? setTitleContent("characters more")
          : setTitleContent("characters left");
      }

      if (currentContentLength > 3000) {
        onChange(editorState);
        setSubmitData(
          draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
        // setisReadOnly (true)
      } else {
        // setisReadOnly (false)
        onChange(editorState);
        settitle(editorState.getCurrentContent().getPlainText("\u0001"));
        title.length < 140
          ? setTitleContent("characters more")
          : setTitleContent("characters left");
        setSubmitData(
          draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
      }
      // console.log(getSubmitData, 'get')
    }
  };



  const submitComment = () => {

    setisReadOnly(false);

    let trimmedData = getSubmitData
      ? getSubmitData.replace(/  +/g, " ")
      : getSubmitData;
    CreateCommentVariables.variables.text = trimmedData;
    CreateCommentVariables.variables.parent_content_id =
      props.parent_content_id;

    if (props.initial != "main") {
      CreateCommentVariables.variables.clik = props.topComment.clik
        ? props.topComment.clik
        : null;
    } else {
      if (click == null) {
        CreateCommentVariables.variables.clik = null;
      } else if (click == "Everyone" || click == "all") {
        CreateCommentVariables.variables.clik = null;
      } else {
        CreateCommentVariables.variables.clik = click
          .toLowerCase()
          .replace("#", "");
      }
    }

    let tempUserId = "User:" + Math.random().toString();
    let username = AsyncStorage.getItem("Username")

    let tempId = "Comment:" + Math.random().toString();
    let createData1 = {
      node: {
        author: { username: username, id: tempUserId },
        id: tempId,
        comment_score: 0,
        text: CreateCommentVariables.variables.text,
        likes_score: 0,
        _typename: "Comment",
        clik: CreateCommentVariables.variables.clik,
      },
      comments: {
        edges: [],
      },
    };
    let clikData1 = []
    let userData1 = []

    // console.log(clikData, userData, 'user clik');


    clikData?.map(e => {
      clikData1.push(e.name)
    })

    userData?.map(i => {
      userData1.push(i.name)
    })


    applloClient
      .query({
        query: CreateCommentMutation,
        variables: {
          parent_id: props.parent_content_id,
          text: trimmedData,
          cliks: clikData1.length > 0 ? clikData1 : null,
          users: userData1.length > 0 ? userData1 : null
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        // console.log(res, 'creationComment');
        settitle("");
        setclick(null);

        let prevCommentList = props.PostCommentDetails;
        let parentId = props.parent_content_id;
        let createData = {
          node: { ...res.data.comment_create.comment },
          comments: {
            edges: [],
          },
        };

        let pathArray = props.path1 ? props.path1 : []
        let pathString = pathArray?.join('/')
        let data = {
          node: {
            cliks: res.data.comment_create.discussion.cliks,
            comment: {
              author: res.data.comment_create.comment.author,
              created: res.data.comment_create.comment.created,
              id: res.data.comment_create.comment.id,
              num_comments: res.data.comment_create.comment.num_comments,
              num_likes: res.data.comment_create.comment.num_likes,
              num_reports: res.data.comment_create.comment.num_reports,
              text: res.data.comment_create.comment.text,
              comments: { edges: [] },
              path: pathString ? pathString : ""


            },
            users: res.data.comment_create.discussion.users,
            id: "Discussion:2767176656819853225",
            locked: res.data.comment_create.discussion.locked,
            private: res.data.comment_create.discussion.private,
            // path: props.path1 ? props.path1 : []
          }
          // id: props.parent_content_id
        }
        // console.log(props.PostCommentDetails);
        props.setCommentStatus(true)
        props.showbox(false);
        if (props.parent_content_id.startsWith('Comment')) {

          let updatedArray = updateNestedArray1(prevCommentList, props.parent_content_id, data)
          if (props.parent_content_id == props.parentId) {
            props.setNestedTotalComment({ comment: props.nestedComment + 1, parentId: props.parent_content_id, path: pathArray })
          } else {
            props.setNestedTotalComment({ comment: 1, parentId: props.parent_content_id, path: pathArray })
          }
          props.setTotalComment(props.totalComment + 1)
          props.setPostCommentDetails(updatedArray)
          props.onClose()

        } else {
          
          props.setTotalComment(props.totalComment + 1)
          props.parentCommentId(props.parent_content_id)
          props.setPostCommentDetails([data, ...prevCommentList])

        }


      })
      .catch((e) => {
        console.log(
          e.message.toLowerCase().replace("CreateCommentMutation error: ", "")
        );
      });
    setPlaceholderState(true);
    setSelectedCliks({});
    setSelectedUser([]);
  };

  useEffect(() => {
    document.addEventListener("touchstart", function () { }, { passive: false });
    let data = [
      {
        label: "Everyone",
        value: "all",
        key: -1,
      },
    ];

    setitem(data);
  }, []);

  useEffect(() => {
    if (getSelectedItem.selectedUser) {
      let newArray = Object.assign(
        [],
        selectedUser,
        getSelectedItem.selectedUser
      );
      setSelectedUser(getSelectedItem.selectedUser);
    }
    if (getSelectedItem.selectedCliks) {
      let newArray = Object.assign({}, getSelectedItem.selectedCliks);

      if (Object.values(getSelectedItem.selectedCliks).length > 0) {
        setOpened(false);
      }
      setSelectedCliks(getSelectedItem.selectedCliks);
    }
  }, [getSelectedItem]);

  useEffect(() => {
    if (props.title) {
    } else {
    }
    editorReference.current.focusEditor()
  }, [props.title]);

  useEffect(() => {
    if (props.UserHighlight) {
      // console.log(editorReference,'editorReference');
      editorReference.current.focusEditor()
      // console.log(props.UserHighlight,'props.UserHighlight');
    } else {
    }
  }, [props.UserHighlight]);



  // console.log(clikData, userData, 'dataaa');


  const goToUserProfile = async (username) => {
    props.onClose();
    await props.userId({
      username: username,
      type: "feed",
    });
    await props.navigation.navigate("profile", {
      username: username,
      type: "feed",
    });
  };

  const customRenderUserTags = (tags) => {
    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          backgroundColor: "#fff",
          width: "100%",
          paddingVertical: 2,
          flexDirection: "column",
        }}
      >
        {tags.map((t, i) => {
          return (
            <View
              key={t.name}
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Text
                style={[
                  ButtonStyle.profileTitleStyle,
                  {
                    color: "grey",
                    padding: 5,
                  },
                ]}
              >
                @{t.name.toLowerCase()}
              </Text>
              <Icon
                color={"grey"}
                name="times"
                type="font-awesome"
                size={20}
                iconStyle={{ marginLeft: 5, cursor: "pointer" }}
                onPress={() => {
                  let newArray = Object.assign([], selectedUser);
                  delete newArray[i];
                  setSelectedUser(Object.assign([], newArray));
                }}
              />
            </View>
          );
        })}
      </View>
    );
  };



  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      return "handled";
    }
  };

  const handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = getLengthOfSelectedText();

    if (
      currentContentLength + pastedText?.length - selectedTextLength >
      MAX_LENGTH
    ) {
      return "handled";
    }
  };


  const editorLabels = {
    "components.controls.blocktype.blockquote": (
      <Icon
        color={"#000"}
        iconStyle={{
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}
        name={"format-line-spacing"}
        type="MaterialCommunityIcons"
        size={20}
        containerStyle={{
          alignSelf: "center",
        }}
      />
    ),
    "components.controls.blocktype.code": (
      <Icon
        color={"#000"}
        iconStyle={{
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "flex-start",
        }}
        name={"code"}
        type="Feather" new reply
        size={20}
        containerStyle={{
          alignSelf: "center",
        }}
      />
    ),
  };

  return (
    <>
      <View
        style={[
          props.initial == "main" ? ButtonStyle.normalFeedStyleForDashboard : null,
          {
            backgroundColor: "#fff",
            marginTop: 0,
            zIndex: 10,
            marginLeft: props.initial == "child" ? 20 : 0,
            // marginRight:'2%',
            borderRadius: 20,
            borderColor: "#d3d3d3",
            width: props.windowSize.width >= 750 && props.initial == "main" ? '100%' : props.windowSize.width >= 750 && props.initial != "main" ? '95%' : '95%',
            borderWidth: 1,
            borderTopWidth: 0,
            marginBottom: props.initial == "main" ? 0 : 10
          },
        ]}
      >
        {/* <View
          style={{
            flexDirection: "row",
            marginTop: props.initial == "main" ? 5 : 0,
            marginBottom: props.initial == "main" ? 5 : 0,
            flexDirection: "row",
            // marginLeft: 10,

          }}
        >
          {props.initial == "main" &&
            item != null &&
            item.length > 0 &&
            item[0] != "" && (
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  position: "relative",
                  justifyContent: "space-between",

                }}
              >
                <MenuOptions
                  optionsContainerStyle={{
                    borderRadius: 6,
                    borderWidth: 1,
                    borderColor: "#e1e1e1",
                    shadowColor: "transparent",

                  }}
                  customStyles={{
                    optionsContainer: {
                      minWidth: 150,
                      marginLeft: -5,
                      width: 260,
                      marginVertical: 5

                    },
                  }}
                >
                  {!selectedUser ||
                    selectedUser.length > 0 ||
                    !selectedCliks.name ? (
                    <SearchInputComponent
                      close={(ele) => setOpened(ele)}
                      click={async (ele) => {
                        setSelectedItem(ele);
                        setclick(ele.selectedCliks.name);
                      }}
                      SearchComment={SearchComment}
                      setClikData={setClikData}
                      setUserData={setUserData}
                      cancel={cancel}
                      setCancel={setCancel}
                      type={props.type}
                    />
                  ) : null}
                </MenuOptions>
                {cancel == false && <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    display: !props.loginStatus ? "none" : "flex",
                    paddingTop: 4,
                    marginRight: 15,
                  }}
                >
                  {getLockStatus && (
                    <View>
                      <Icon
                        size={20}
                        name="lock"
                        type="font-awesome"
                        iconStyle={{ cursor: "pointer" }}
                        color="grey"
                        underlayColor="#fff"
                        onPress={() => {
                          !getLockStatus
                            ? alert(
                              "Discussion will be closed to users and cliks that were invited"
                            )
                            : alert("Discussion will be open to everyone");
                          SetLockStatus(false);
                        }}
                      />
                    </View>
                  )}
                  {!getLockStatus && (
                    <View>
                      <Icon
                        size={20}
                        name="unlock-alt"
                        type="font-awesome"
                        iconStyle={{ cursor: "pointer" }}
                        color="grey"
                        underlayColor="#fff"
                        onPress={() => {
                          !getLockStatus
                            ? alert(
                              "Discussion will be closed to users and cliks that were invited"
                            )
                            : alert("Discussion will be open to everyone");
                          SetLockStatus(true);
                        }}
                      />
                    </View>
                  )}
                </View>}

              </View>
            )}
        </View> */}
        {props.windowSize.width <= 750 && title.length < 3000 && (
          <Text
            style={{
              color: title.length < 140 ? "#de5246" : "#009B1A",
              fontSize: 13,
              fontFamily: ConstantFontFamily.defaultFont,
              alignSelf: "flex-end",
              marginRight: 10,
            }}
          >
            {title
              ? title.length < 140
                ? `${140 - title.length} ${titleContent}`
                : `${3000 - title.length} ${titleContent}`
              : ""}
          </Text>
        )}
        <View
          style={{
            height: Platform.OS != "web" ? hp("25%") : null,
            zIndex: -9
          }}
        >
          <View style={{ flex: 1, zIndex: -9 }}>
            <View
              onBlur={() => {
                return setBorderColor("#e6e6e6");
              }}
              style={{
                borderBottomWidth: 1,
                borderColor: `${getBorderColor}`,
                ...Platform.select({
                  web: {
                    marginHorizontal: 0,
                  },
                  android: {
                    marginTop: 10,

                  },
                  ios: {
                    marginTop: 10,
                  },
                }),
              }}
            >
              <View>
                <View style={{ flexDirection: "column" }}>
                  <Editor
                    testID="createComment"

                    autogrow
                    ref={editorReference}
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                    handleBeforeInput={handleBeforeInput}
                    handlePastedText={handlePastedText}
                    handleReturn={() => {
                      const header = document.querySelector(".demo-editor");
                      document.querySelector(".demo-editor").scrollTop =
                        document.querySelector(".demo-editor").scrollHeight;
                    }}
                    onBlur={() => {
                      if (
                        editorState.getCurrentContent().getPlainText("\u0001")
                          .length == 0
                      ) {
                        setPlaceholderState(true);
                      }
                    }}
                    onChange={() => {
                      document.querySelector(".demo-editor").scrollTop =
                        parseInt(
                          document.querySelector(".demo-editor").scrollHeight
                        ) +
                        parseInt(
                          document.querySelector(".demo-editor").scrollHeight
                        );
                      if (
                        editorState.getCurrentContent().getPlainText("\u0001")
                          .length == 0
                      ) {
                        setPlaceholderState(true);
                      }
                    }}
                    editorStyle={{
                      minHeight: 100,
                      maxHeight: hp('55%'),
                      overflowY: title.length > 0 ? "auto" : "unset",
                      fontSize: 15,
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                      paddingLeft: props.initial != "main" ? 30 : 10,
                      paddingRight: 10,
                      textAlign: "center",
                      marginTop: 2,
                      flexWrap: "wrap",
                      alignItems: "flex-end",
                    }}
                    toolbarStyle={{
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      padding: props.windowSize.width <= 750 ? 0 : 5,
                      // justifyContent: "space-evenly",

                    }}
                    toolbar={{
                      options: ["inline", "blockType", "list", "link"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      blockType: {
                        inDropdown: false,
                        options: ["Blockquote", "Code"],
                        className: "demo-option-custom-wide",
                        dropdownClassName: "demo-dropdown-custom",
                      },

                      list: {
                        options: ["unordered"],
                      },
                      link: {
                        options: ["link"],
                      },
                    }}
                    localization={{ locale: "en", translations: editorLabels }}
                    placeholder={
                      props.TrendingHomeFeedList?.length == 0 || props.NewHomeFeedList?.length == 0 || props.DiscussionHomeFeedList?.length == 0 || props.TrendingClikHomeFeedList?.length == 0 || props.TrendingExternalFeedHomeFeedList?.length == 0 || props.TrendingTopicHomeFeedList?.length == 0 ? "No post or comment to reply to" : props.initial == "child" ? `Replying to @${props.UserHighlight}` : `Replying to \"${props.commentTitle}\"`
                      // props.UserHighlight != "" ? `Replying to @${props.UserHighlight}` : `Replying to \"${props.Title}\"`
                    }

                  />
                </View>
              </View>
            </View>

            {searchnput == false ? <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                paddingLeft: 10,
                // paddingBottom: props.initial == "main" ? 5 : 0,
                paddingRight: 10,
                // paddingTop: 5,
                alignItems: "center",
                zIndex: -1,
                height: 40,
              }}
            >

              <View style={{ flexDirection: "row", }}>
                <Hoverable>
                  {(isHovered) => (
                    <TouchableOpacity
                      onPress={() =>
                        goToUserProfile(
                          props.profileData &&
                          props.profileData.user.username,
                        )
                      }
                    >
                      <View
                        style={{ flexDirection: "row" }}
                      >
                        <Icon
                          size={25}
                          iconStyle={{ fontWeight: '100', marginRight: 10 }}
                          name="add-circle-outline"
                          type="ion-icon"
                          color={"#899198"}
                          onPress={() => setSearchInput(true)}
                        />
                        {/* {props.user
                          &&
                          props.user.getIn(["node", "profile_pic"])
                          ? (
                            <Image
                              source={{
                                uri: props.user.getIn(["node", "profile_pic"]) ? props.user.getIn(["node", "profile_pic"]) : "../assets/image/default-image.png",
                              }}
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: 18,
                                borderWidth: 1,
                                borderColor: "#e1e1e1",
                                marginRight: 5,
                              }}
                            />
                          ) : (
                            <Image
                              source={require("../assets/image/default-image.png")}
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: 18,
                                borderWidth: 1,
                                borderColor: "#e1e1e1",
                                marginRight: 5,
                              }}
                            />
                          )} */}

                        {/* <Text
                          style={{
                            color: "#6D757F",
                            fontSize: 16,
                            fontFamily: ConstantFontFamily.defaultFont,
                            textDecorationLine:
                              isHovered == true ? "underline" : "none",
                            marginRight: 5,
                          }}
                        >
                          {props.profileData &&
                            "@" +
                            props.profileData.user?.username
                          }
                        </Text> */}
                      </View>

                    </TouchableOpacity>
                  )}
                </Hoverable>
                <View style={{ flexDirection: "row" }}>
                {clikData.map((item, i) => {
                  let Bgcolor = ""
                  let color = ""
                  if (item.id.startsWith("Clik")) {
                    Bgcolor = "#fff"
                    color = "#000"
                  } else if (item.id.startsWith("User")) {
                    Bgcolor = "#fff"
                    color = "#000"
                  }
                  return (
                    <TouchableOpacity style={[
                      ButtonStyle.userNameBackgroundStyle, {
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginVertical: 2,
                        marginLeft: 10,
                        justifyContent: "space-between",
                        borderWidth: 1,
                        borderColor: '#000'
                      }]}
                      key={i}

                    >
                      <Text
                        style={[ButtonStyle.userNameTitleStyle, {
                          width: "100%",
                          paddingHorizontal: 5
                        }]}
                      >
                        {item.id.startsWith("Clik") ? "#" : item.id.startsWith("User") ? "@" : null}{item.name}

                      </Text>
                      <Icon
                        // color={
                        //   color
                        // }
                        name="close"
                        type="antdesign"
                        size={16}
                      // iconStyle={{ marginLeft: 5 }}
                      // onPress={() => this.handleItemDelete(i)}
                      />
                    </TouchableOpacity>
                  )
                })}
                {userData.map((item, i) => {
                  let Bgcolor = ""
                  let color = ""
                  if (item.id.startsWith("Clik")) {
                    Bgcolor = "#E8F5FA"
                    color = "#4169e1"
                  } else if (item.id.startsWith("User")) {
                    Bgcolor = "#fff"
                    color = "#000"
                  }
                  return (
                    <TouchableOpacity style={[
                      item.id.startsWith("Clik") ?
                        ButtonStyle.clikNameBackgroundStyle : item.id.startsWith("User") ? ButtonStyle.userNameBackgroundStyle : null, {
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginVertical: 2,
                        marginLeft: 10,
                        justifyContent: "space-between",
                        borderWidth: item.id.startsWith("User") ? 1 : 0,
                        borderColor: item.id.startsWith("User") ? '#000' : 'transparent',
                      }]}
                      key={i}

                    >
                      <Text
                        style={[item.id.startsWith("Clik") ?
                          ButtonStyle.clikNameTitleStyle : item.id.startsWith("User") ? ButtonStyle.userNameTitleStyle : null, {
                          width: "100%",
                          paddingHorizontal: 5
                        }]}
                      >
                        {item.id.startsWith("Clik") ? "#" : item.id.startsWith("User") ? "@" : null}{item.name}

                      </Text>
                      <Icon
                        color={
                          color
                        }
                        name="close"
                        type="antdesign"
                        size={16}
                      // iconStyle={{ marginLeft: 5 }}
                      // onPress={() => this.handleItemDelete(i)}
                      />
                    </TouchableOpacity>
                  )
                })}
              </View>
              </View>


              

              <View
                style={{
                  flexDirection: "row",
                  zIndex: "auto",
                  alignItems: 'center'
                }}

              >
                {props.windowSize.width >= 750 && title.length < 3000 && (
                  <Text
                    style={{
                      color: title.length < 140 ? "#de5246" : "#009B1A",
                      fontSize: 13,
                      fontFamily: ConstantFontFamily.defaultFont,
                      marginRight: 10,
                      // alignSelf: "flex-end",
                    }}
                  >
                    {title
                      ? title.length < 140
                        ? `${140 - title.length} ${titleContent}`
                        : `${3000 - title.length} ${titleContent}`
                      : ""}
                  </Text>
                )}
                {props.initial == "main" && (
                  <Button
                    title="Cancel"
                    titleStyle={[ButtonStyle.cwtitleStyle, {color:'#de5246',marginTop: 4,}]}
                    buttonStyle={[
                      ButtonStyle.crbackgroundStyle,
                      {
                        paddingHorizontal: 10,
                        paddingVertical:
                          props.windowSize.width <= 750 ? 5 : 6,
                        borderRadius: 10,
                        backgroundColor: "#fff",
                        // borderColor: "#C5C5C5",
                      },
                    ]}
                    containerStyle={[
                      ButtonStyle.containerStyle,
                      {
                        marginTop: 10,
                        marginBottom: 5,
                        height: 34,
                        marginRight: 10
                      },
                    ]}
                    onPress={() => {
                      props.showbox(false);
                    }}
                  />
                )}
                {getLockStatus && props.initial == 'main' ? <Hoverable>
                  {(isHovered) => (
                    // <View>
                    // "Lock discussion to only invited cliks and users"
                    <Popable action="hover" content="Unlock the discussion so anyone can join" style={{ width: 300 }} strictPosition={true} position="left">
                      {/* <Text>@morning_cafe</Text> */}
                      {/* {isHovered ? <View> <Text>Discussion will be closed to users and cliks that were invited</Text> </View> : null} */}
                      <Icon
                        size={25}
                        name="lock"
                        type="font-awesome"
                        iconStyle={{ cursor: "pointer" }}
                        style={{ marginRight: 10, }}
                        color="grey"
                        underlayColor="#fff"
                        onPress={() => {
                          SetLockStatus(false);
                        }}
                      />
                      {/* </View> */}
                    </Popable>

                  )}
                </Hoverable> :
                  props.initial == 'main' ? <Popable action="hover" content="Lock discussion to only invited cliks and users" style={{ width: 300 }} strictPosition={true} position="left">
                    <Icon
                      size={25}
                      name="unlock"
                      type="font-awesome"
                      iconStyle={{ cursor: "pointer" }}
                      style={{ marginRight: 10 }}
                      color="grey"
                      underlayColor="#fff"
                      onPress={() => {
                        SetLockStatus(true);
                      }}
                    />
                  </Popable> : null



                }
                {props.initial != "main" && (
                  <Button
                    title="Cancel"
                    titleStyle={ButtonStyle.cwtitleStyle}
                    buttonStyle={[
                      ButtonStyle.crbackgroundStyle,
                      {
                        paddingHorizontal: 10,
                        paddingVertical:
                          props.windowSize.width <= 750 ? 5 : 6,
                        borderRadius: 10,
                        backgroundColor: "#de5246",
                        borderColor: "#C5C5C5",
                      },
                    ]}
                    containerStyle={[
                      ButtonStyle.containerStyle,
                      {
                        marginTop: 5,
                        marginBottom: 5,
                        marginRight: 10,
                        height: 34,
                      },
                    ]}
                    onPress={() => {
                      props.onClose();
                    }}
                  />
                )}




                <TouchableOpacity
                  // testID="SubmitComment"
                  // title="Submit"
                  // titleStyle={
                  //   title.length < 140
                  //     ? ButtonStyle.cdtitleStyle
                  //     : ButtonStyle.cwtitleStyle
                  // }
                  // buttonStyle={
                  //   title.length < 140
                  //     ? [
                  //       ButtonStyle.cdbackgroundStyle,
                  //       {
                  //         paddingVertical:
                  //           props.windowSize.width <= 750 ? 4 : 2,
                  //         borderRadius: 10,
                  //       },
                  //     ]
                  //     : [
                  //       ButtonStyle.cgbackgroundStyle,
                  //       ,
                  //       {
                  //         paddingVertical:
                  //           props.windowSize.width <= 750 ? 4 : 2,
                  //         borderRadius: 10,
                  //       },
                  //     ]
                  // }
                  // containerStyle={[
                  //   ButtonStyle.containerStyle,
                  //   {
                  //     marginTop: 5,
                  //     marginBottom: 5,
                  //     height: 34,
                  //   },
                  // ]}
                  style={
                    {
                      paddingVertical: 3,
                      paddingHorizontal: 10,
                      backgroundColor: title.length < 140 ? "#fff" : Colors.blueColor,
                      borderRadius: 4,
                      justifyContent: 'center'
                    }}
                  disabled={title.length < 140 ? true : false}
                  onPress={() => {
                    submitComment();
                    onChange(
                      EditorState.push(
                        editorState,
                        ContentState.createFromText("")
                      )
                    );
                  }}
                >
                  <Icon
                    size={20}
                    iconStyle={{ fontWeight: '100', }}
                    name="send"
                    type="ion-icon"
                    color={title.length < 140 ? "#899198" : '#fff'}
                  // onPress={() => setSearchInput(true)}
                  />
                </TouchableOpacity>
              </View>
            </View> : <View > <SearchInputComponent
              close={(ele) => setOpened(ele)}
              click={async (ele) => {
                setSelectedItem(ele);
                setclick(ele.selectedCliks.name);
              }}
              // searchRef={search}
              SearchComment={SearchComment}
              setClikData={setClikData}
              setUserData={setUserData}
              cancel={cancel}
              setCancel={setCancel}
              type={props.type}
              setSearchInput={setSearchInput}
            /></View>}

          </View>
        </View>
      </View>
    </>
  );
};
const mapStateToProps = (state) => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  PostCommentDetails: state.PostCommentDetailsReducer.get("PostCommentDetails"),
  loginStatus: state.UserReducer.get("loginStatus"),
  getfocusCreateComment: state.AdminReducer.get("focusCreateComment"),
  user: state.UserProfileDetailsReducer.get(
    "getCurrentUserProfileDetails"
  ),
  totalComment: state.TotalCommentReducer.totalComment,
  nestedComment: state.NestedCommentReducer.nestedComment,
  parentId: state.NestedCommentReducer.parentId,
  path1: state.NestedCommentReducer.path,
  HighlightId: state.commentIdReducer.id,
  UserHighlight: state.UserHighlight.userHighlight,
  Title: state.PostCommentDetailsReducer.get("Title"),
  TrendingHomeFeedList: state.HomeFeedReducer.get('TrendingHomeFeedList'),
  NewHomeFeedList: state.HomeFeedReducer.get('NewHomeFeedList'),
  DiscussionHomeFeedList: state.HomeFeedReducer.get('DiscussionHomeFeedList'),
  TrendingTopicHomeFeedList: state.HomeFeedReducer.get('TrendingTopicHomeFeedList'),
  TrendingClikHomeFeedList: state.HomeFeedReducer.get('TrendingClikHomeFeedList'),
  TrendingExternalFeedHomeFeedList: state.HomeFeedReducer.get('TrendingExternalFeedHomeFeedList'),
  TrendingUserHomeFeedList: state.HomeFeedReducer.get('TrendingUserHomeFeedList'),
  windowSize: state.windowResizeReducer.windowResize,
  commentTitle: state.getCommentTitleReducer.getTitle


});

const mapDispatchToProps = (dispatch) => ({
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  focusCreateComment: (payload) =>
    dispatch({ type: "FOCUS_CREATE_COMMENT", payload }),
  setTotalComment: (payload) => dispatch(setTotalComment(payload)),
  setNestedTotalComment: (payload) => dispatch(setNestedTotalComment(payload)),
  setCommentStatus: (payload) => dispatch(setCommentStatus(payload)),
  showbox: (payload) => dispatch(showbox(payload)),
  parentCommentId:(payload) => dispatch(parentCommentId(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateCommentCard
);


function updateNestedArray1(input, parentId, newData) {

  let repliedData = {
    node: {
      author: newData.node.comment.author,
      comments: { edges: [] },
      created: newData.node.comment.created,
      num_comments: newData.node.comment.num_comments,
      id: newData.node.comment.id,
      num_likes: newData.node.comment.num_likes,
      text: newData.node.comment.text,
      num_reports: newData.node.comment.num_reports,
      path: newData.node.comment.path,
      __typename: "Comment"

    }

  }

  let repliedData1 = []
  let newArray = []

  input.map(e => {
    if (e.node.comment.id == parentId) {
      repliedData1 = e.node.comment.comments.edges
      let arr = repliedData1.unshift(repliedData)
      let obj = {
        node: {
          cliks: e.node.cliks,
          comment: {
            id: e.node.comment.id,
            text: e.node.comment.text,
            comments: arr,
            created: e.node.comment.created,
            author: e.node.comment.author,
            num_comments: e.node.comment.num_comments,
            num_likes: e.node.comment.num_likes

          },
          id: e.node.id,
          locked: e.node.locked,
          private: e.node.private,
          users: e.node.users
        }
      }
      newArray = input
      return newArray
    } else {


      let arr1 = arr(e.node.comment.comments.edges, e)

      let obj1 = {
        node: {
          cliks: e.node.cliks,
          comment: {
            id: e.node.comment.id,
            text: e.node.comment.text,
            comments: arr1,
            created: e.node.comment.created,
            author: e.node.comment.author,
            num_comments: e.node.comment.num_comments,
            num_likes: e.node.comment.num_likes

          },
          id: e.node.id,
          locked: e.node.locked,
          private: e.node.private,
          users: e.node.users
        }
      }
      newArray = input
      return newArray
    }
  })


  function arr(item, e) {
    let replied2 = []
    item.map(i => {
      if (i.node.id == parentId) {

        replied2 = i.node.comments.edges
        let arr2 = replied2.unshift(repliedData)

        let obj1 = {
          node: {
            author: i.node.author,
            comments: arr2,
            created: i.node.created,
            num_comments: i.node.num_comments,
            id: i.node.id,
            num_likes: i.node.num_likes,
            text: i.node.text,
            num_reports: i.node.num_reports,

          }
        }
        return item
      } else {
        arr(i.node.comments.edges, e)
      }
    })
    return replied2
  }

  return newArray

}
