import ConstantFontFamily from "./FontFamily";
import { Dimensions, Platform } from "react-native";
import Colors from '../constants/Colors';

const containerStyle = {
  marginTop: 10,
  marginLeft: 5,
  height: 40,
  alignSelf: "center"
};

const borderStyle = {
  borderColor: "#D7D7D7",
  borderWidth: 1,
  borderRadius: 20
};

const ZeroBorderStyle = {
  borderColor: "#D7D7D7",
  borderWidth: 1,
  borderRadius: 0,
  marginTop: Dimensions.get("window").width <= 750 ? 0 : 10
};
const ZeroBorderNotificationStyle = {
  borderColor: "#D7D7D7",
  borderWidth: 1,
  borderRadius: 0,
  borderTopWidth: 0,
}

const backgroundStyle = {
  backgroundColor: "#fff",
  borderRadius: 12,
  borderColor: "#000",
  borderWidth: 1,
  paddingVertical: 7,
  paddingHorizontal: 25
};

const gbackgroundStyle = {
  backgroundColor: "#009B1A",
  borderRadius: 20,
  borderColor: "#C5C5C5",
  borderWidth: 1,
  paddingVertical: 7,
  paddingHorizontal: 25
};

const dbackgroundStyle = {
  backgroundColor: "#E3E6E8",
  borderRadius: 20,
  borderColor: "#C5C5C5",
  borderWidth: 1,
  paddingVertical: 7,
  paddingHorizontal: 25
};

const rbackgroundStyle = {
  backgroundColor: "#de5246",
  borderRadius: 20,
  borderColor: "#C5C5C5",
  borderWidth: 1,
  paddingVertical: 7,
  paddingHorizontal: 25
};

const titleStyle = {
  fontSize: 14,
  fontWeight: "bold",
  color: "#000",
  fontFamily: ConstantFontFamily.defaultFont
};

const wtitleStyle = {
  fontSize: 14,
  fontWeight: "bold",
  color: "#fff",
  fontFamily: ConstantFontFamily.defaultFont
};

const ctitleStyle = {
  fontSize: 12,
  fontWeight: "bold",
  color: "#000",
  fontFamily: ConstantFontFamily.defaultFont
};

const cwtitleStyle = {
  fontSize: 15,
  fontWeight: "bold",
  color: "#fff",
  fontFamily: ConstantFontFamily.defaultFont
};

const cdtitleStyle = {
  fontSize: 15,
  fontWeight: "bold",
  color: "#99A1A8",
  fontFamily: ConstantFontFamily.defaultFont
};

const cgbackgroundStyle = {
  backgroundColor: "#009B1A",
  borderRadius: 20,
  borderColor: "#C5C5C5",
  borderWidth: 1,
  paddingHorizontal: 10
};

const cdbackgroundStyle = {
  backgroundColor: "#E3E6E8",
  borderRadius: 20,
  borderColor: "#C5C5C5",
  borderWidth: 1,
  paddingHorizontal: 10
};

const crbackgroundStyle = {
  backgroundColor: "#de5246",
  borderRadius: 20,
  // borderColor: "#C5C5C5",
  // borderWidth: 1,
  paddingHorizontal: 10
};

const dtitleStyle = {
  fontSize: 14,
  fontWeight: "bold",
  color: "#99A1A8",
  fontFamily: ConstantFontFamily.defaultFont
};

const headerTitleStyle = {
  flex: 1,
  backgroundColor: "#f4f4f4",
  alignItems: 'flex-start',
  alignSelf: 'center',
  borderRadius: 6,
  marginRight: 10,
}
const headerBackStyle = {
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: 'center',
  marginHorizontal: 10,
  alignSelf: 'center',
}

const shadowStyle = {
  // shadowColor: "#000",
  // shadowOffset: {
  //   width: 0,
  //   height: 2,
  // },
  // shadowOpacity:0.4,
  // shadowRadius: 6.84,

  elevation: 5,

  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 0,
  },
  shadowOpacity: 0.4,
  shadowRadius: 5,
  width: "98%",
  marginTop: 4,
  borderWidth: 0

}

//for profile tab views
const cardShadowStyle = {
  elevation: 5,
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 0,
  },
  shadowOpacity: 0.4,
  shadowRadius: 5,
  width: Dimensions.get("window").width <= 750 ? '97%' : "98%",
  borderWidth: 0,
  borderRadius: 10,
  marginHorizontal: 10
}

const textAreaShadowStyle = {
  // shadowColor: "#000",
  // shadowOffset: {
  //   width: 0,
  //   height: 0,
  // },
  // shadowOpacity: 0.3,
  // shadowRadius: 3,
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 0,
  },
  shadowOpacity: 0.10,
  shadowRadius: 4,
  elevation: 5,
  width: "100%",
  marginVertical: 5,
  borderRadius: 5,
  outline: 'none',
  borderWidth: 1,
  borderColor: '#d7d7d7'
}


const selectShadowStyle = {
  shadowColor: "#009B1A",
  shadowOffset: {
    width: 0,
    height: 0,
  },
  shadowOpacity: 1.5,
  shadowRadius: 7,
  elevation: 9,
  marginTop: 4
}

const selecttextAreaShadowStyle = {
  // shadowColor: '#000', //009B1A
  // shadowOffset: {
  //   width: 0,
  //   height: 0,
  // },
  // shadowOpacity:Platform.OS == 'web' ? 1.5 : 0.5,
  // shadowRadius: 5,
  // elevation: 5,

  // width: "100%",
  // marginVertical: 5,
  // height: 45,
  // borderRadius: 5,
  // outline: 'none',

  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 0,
  },
  shadowOpacity: 0.10,
  shadowRadius: 4,
  elevation: 5,
  width: "100%",
  marginVertical: 5,
  borderRadius: 5,
  outline: 'none',
  borderWidth: 1,
  borderColor: '#d7d7d7'
}
const headerRightStyle = {
  alignItems: 'center',
  justifyContent: 'center'
}



const selectTabStyle = {
  borderColor: '#009B1A',
}
const normalTabStyle = {
  borderColor: '#009B1A',
}


const selectFeedStyle = {
  borderWidth: 2,
  borderColor: Dimensions.get("window").width <= 750 ? '#d7d7d7' : '#009B1A',
  marginRight: 0.1,
}

const normalFeedStyle = {
  borderColor: '#e6e6e6',
  borderTopWidth: 0,
  borderBottomWidth: 1,
}

const TabbarBorderStyle = {
  // borderWidth: 1,
  borderTopWidth: 0,
  borderBottomWidth: 1,
  borderColor: '#e6e6e6',
}

const selectFeedStyleForDashboard = {
  // elevation: 35,

  // shadowColor: '#fff',
  // shadowOffset: {
  //   width: 0,
  //   height: 0,
  // },
  // shadowOpacity: 0.8,
  // shadowRadius: 10,
  // width: Dimensions.get('window').width <=750 ? "100%" : "96%",
  // borderWidth: 1,
  // borderRadius: Dimensions.get('window').width <=750 ? 0 : 15 ,
  // marginBottom: Dimensions.get('window').width <=750 ? 0 : 7,
  // marginTop:7,
  // backgroundColor: Colors.whiteBackground,
  // marginHorizontal:Dimensions.get('window').width <=750 ? 0 :'2%',
  borderColor: Dimensions.get('window').width <= 750 ? "#fff" : Colors.blueColor,
  borderWidth: 1,
  // borderBottomWidth: 1,
  width: '96%',
  marginLeft:'2%',
  backgroundColor:'#fff',
  marginTop:10,
  borderRadius:15
}

// const normalFeedStyleForDashboard = {  

//   // marginLeft: Dimensions.get('window').width >= 1200 && location.pathname.startsWith("/search") == false ? 5 : location.pathname.startsWith("/search") == true ? 15 : '2%',
// }

const normalFeedStyleForDashboard = {
  // width: '100%',
  // borderBottomWidth:1,
  // borderColor:'#d3d3d3',
  // backgroundColor: '#fff',

  // elevation: 35,

  // shadowColor: '#fff',
  // shadowOffset: {
  //   width: 0,
  //   height: 0,
  // },
  // shadowOpacity: 0.8,
  // shadowRadius: 10,
  // width: Dimensions.get('window').width <=750 ? "100%" : "96%",
  // borderWidth: 1,
  // borderRadius: Dimensions.get('window').width <=750 ? 0 : 15 ,
  // marginHorizontal:Dimensions.get('window').width <=750 ? 0 : '2%',

  // marginBottom: Dimensions.get('window').width >= 1200 ? 7 : 0,
  // marginTop: 7,
  backgroundColor: Colors.whiteBackground,
  borderColor: "#d3d3d3",
  borderWidth: 0,
  // borderBottomWidth: 1,
  marginTop:10,
  width: '96%',
  marginLeft:'2%',
  borderRadius:15,
  borderWidth:1

}

const profileShadowStyle = {
  borderTopWidth: 0,
  width: "100%",
  borderWidth: 0,
  marginTop: Dimensions.get('window').width >= 1200 ? 10 : 0,
  backgroundColor: '#fff',
}

const TabbarBorderStyleForDashboard = {
  width: '100%',
  marginLeft: Dimensions.get('window').width >= 1200 ? 5 : 0,
  // borderBottomWidth: 1,
  // borderTopWidth:1,
  borderColor: '#d3d3d3',
}


const TabViewStyle = {
  borderWidth: 1,
  borderColor: '#e6e6e6',
  overflow: 'auto',
  borderTopWidth: 0,
  marginTop: 13,
}

const DashboardTabViewStyle = {
  overflow: 'auto',
  backgroundColor: Colors.whiteBackground,
  borderLeftWidth: 1,
  // borderRightWidth: 1,
  borderColor: '#d3d3d3'
}

const profileTitleStyle = {
  // color: "#4169e1",
  fontSize: 12,
  fontWeight: 'bold',
  fontFamily: ConstantFontFamily.HeaderBoldFont
}

const tabbarTitleStyle = {
  // color: "#4169e1",
  fontSize: 17,
  fontWeight: 'bold',
  fontFamily: ConstantFontFamily.HeaderBoldFont
}
const simpleTextStyle = {
  fontFamily: ConstantFontFamily.defaultFont,
  fontSize: 15
}

const clikNameBackgroundStyle = {
  alignItems: "center",
  // backgroundColor: "#E8F5FA",
  marginVertical: 5,
  marginTop: 10,
  borderRadius: 6,
  justifyContent: "center",
}

const clikNameTitleStyle = {
  fontSize: 14,
  // fontWeight: 'bold',
  fontFamily: ConstantFontFamily.HeaderBoldFont,
  color: '#000',
}
const userNameTitleStyle = {
  fontSize: 12,
  fontWeight: 'bold',
  fontFamily: ConstantFontFamily.HeaderBoldFont,
  color: "#000",
}

const userNameBackgroundStyle = {
  alignItems: "center",
  backgroundColor: "#fff",
  paddingVertical: 5,
  borderRadius: 6,
  justifyContent: "center",
}

const heartIconBackground = {
  alignItems: "center",
  backgroundColor: "#ECF0FC",
  paddingHorizontal: 5,
  // paddingVertical:1,
  borderRadius: 10,
  justifyContent: "center",
  borderColor: '#4169e1',
  borderWidth: 1,
  marginRight: 15,
  minWidth: 50
}
export default {
  backgroundStyle,
  gbackgroundStyle,
  dbackgroundStyle,
  rbackgroundStyle,
  containerStyle,
  titleStyle,
  wtitleStyle,
  dtitleStyle,
  borderStyle,
  ctitleStyle,
  cwtitleStyle,
  cdtitleStyle,
  cgbackgroundStyle,
  cdbackgroundStyle,
  crbackgroundStyle,
  headerTitleStyle,
  headerBackStyle,
  shadowStyle,
  selectShadowStyle,
  headerRightStyle,
  cardShadowStyle, //for card section
  textAreaShadowStyle,
  selecttextAreaShadowStyle,
  profileShadowStyle,
  selectFeedStyle,
  normalFeedStyle,
  ZeroBorderStyle,
  ZeroBorderNotificationStyle,
  profileTitleStyle,
  simpleTextStyle,
  TabbarBorderStyle,
  TabViewStyle,
  selectTabStyle,
  normalTabStyle,
  DashboardTabViewStyle,
  normalFeedStyleForDashboard,
  selectFeedStyleForDashboard,
  TabbarBorderStyleForDashboard,
  clikNameBackgroundStyle,
  clikNameTitleStyle,
  userNameTitleStyle,
  userNameBackgroundStyle,
  heartIconBackground,
  tabbarTitleStyle

};