import { fromJS } from 'immutable';
import { TRENDINGUSERS_SUCCESS, TRENDINGUSERS_FAILURE } from "../constants/Action";

const initialState = fromJS({
    Trending_users_List: null,
});

export default function TrendingUsersReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TRENDINGUSERS_SUCCESS:
            return state
                .set('Trending_users_List', payload);
        case TRENDINGUSERS_FAILURE:
            return initialState;
    }
    return state;
}