import { put, takeEvery } from 'redux-saga/effects';
import { ADMIN_STATUS, ADMIN_VIEW, ADMIN_STATUS_FAILURE, ADMIN_STATUS_SUCCESS, ADMIN_VIEW_SUCCESS } from "../constants/Action";

export function* AdminMiddleware({ payload }) {
  try {
    yield put({
      type: ADMIN_STATUS_SUCCESS,
      payload: payload
    });
    yield put({
      type: ADMIN_VIEW_SUCCESS,
      payload: payload
    });
  } catch (err) {
    yield put({
      type: ADMIN_STATUS_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}
export function* AdminViewMiddleware({ payload }) {
  try {
    yield put({
      type: ADMIN_VIEW_SUCCESS,
      payload: payload
    });
  } catch (err) {
    yield put({
      type: ADMIN_STATUS_FAILURE,
      payload: err.message
    });
  }
}

export default function* AdminMiddlewareWatcher() {
  yield takeEvery(ADMIN_STATUS, AdminMiddleware);
  yield takeEvery(ADMIN_VIEW, AdminViewMiddleware);

}