import firebase from "firebase/app";
import "firebase/auth";
import React, { Component } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  // AsyncStorage,
  Image,
  Platform,
  TextInput,
  ActivityIndicator,
  FlatList,
  Dimensions
} from "react-native";
import { connect } from "react-redux";
import { setCreateAccount } from "../actionCreator/CreateAccountAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import { setVERIFYEMAILMODALACTION } from "../actionCreator/VerifyEmailModalAction";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
import { getLocalStorage, setLocalStorage } from "../library/Helper";
import jwt_decode from "jwt-decode";
import {
  saveUserLoginDaitails,
  setLoginStatus
} from "../actionCreator/UserAction";
import { setAdminStatus } from "../actionCreator/AdminAction";
import { Hoverable } from "react-native-web-hooks";
import { List } from "immutable";
import { Button, Icon } from "react-native-elements";
import ClikJoinButton from "./ClikJoinButton";
import {
  SearchClikMutation,
  DefaultClikListMutation
} from "../graphqlSchema/graphqlMutation/SearchMutation";
import { SearchClikVariables } from "../graphqlSchema/graphqlVariables/SearchVariables";
import applloClient from "../client";
import { graphql } from "react-apollo";
import { fromJS } from "immutable";
import ClikStar from "./ClikStar";
import ButtonStyle from "../constants/ButtonStyle";
import SidePanel from './SidePanel';
import NavigationService from "../library/NavigationService";

const apiUrl = getEnvVars();

class SignUpJoin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showJoin: true,
      listTrending_cliks: [],
      // listTrending_cliks: this.props.listTrending_cliks,
      page: 1,
      loading: true,
      loadingMore: false,
      pageInfo: null,
      apiCall: true,
      refreshing: false
    };
  }

  componentDidMount() {
    this.getJoinClikList();
  }

  getJoinClikList = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null) {
      this.setState({
        loadingMore: true
      });
      applloClient
        .query({
          query: DefaultClikListMutation,
          variables: {
            first: 10,
            after: pageInfo ? pageInfo.endCursor : null
          },
          fetchPolicy: "no-cache"
        })
        .then(res => {
          __self.setState({
            loading: false,
            listTrending_cliks: res.data.clik_list.edges,
            pageInfo: res.data.clik_list.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false
          });
        })
        .catch(e => {
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true
      });
      applloClient
        .query({
          query: DefaultClikListMutation,
          variables: {
            first: 10,
            after: pageInfo ? pageInfo.endCursor : null
          },
          fetchPolicy: "no-cache"
        })
        .then(res => {
          __self.setState({
            loading: false,
            listTrending_cliks: __self.state.listTrending_cliks.concat(
              res.data.clik_list.edges
            ),
            pageInfo: res.data.clik_list.pageInfo,
            apiCall: true,
            loadingMore: false
          });
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  formSubmit = async () => {
    let __self = this;
    let actionCodeSettings = {
      url: apiUrl.APPDYNAMICLINK,
      handleCodeInApp: true
    };
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async function(idToken) {
        return await getLocalStorage("userIdTokenFirebase").then(async res => {
          await setLocalStorage("userIdTokenFirebase", idToken);
          await setLocalStorage(
            "admin",
            jwt_decode(idToken).claims.admin ? "true" : "false"
          );
          await __self.props.changeAdminStatus(
            jwt_decode(idToken).claims.admin ? jwt_decode(idToken).claims.admin : false
          );
          return idToken;
        });
      })
      .then(async res => {
        if (res) {
          await firebase
            .auth()
            .currentUser.sendEmailVerification(actionCodeSettings)
            .then(res => {
              AsyncStorage.removeItem("userIdTokenFirebase");
              __self.props.setCreateAccount({
                username: "",
                email: "",
                password: "",
                first_name: "",
                last_name: ""
              });
              __self.props.setVerifyEmailModalStatus(false);
            })
            .catch(e => {
              __self.props.setVerifyEmailModalStatus(false);
              if (e.code == "auth/too-many-requests") {
                alert(e.message);
              } else {
                console.log(e);
              }
            });
        }
      });
  };

  searchedClikName = clickname => {
    if (clickname == "") {
      this.setState({ listTrending_cliks: this.props.listTrending_cliks });
    } else {
      let tempArray = [];
      let tempData = [];
      SearchClikVariables.variables.prefix = clickname;
      applloClient
        .query({
          query: SearchClikMutation,
          ...SearchClikVariables,
          fetchPolicy: "no-cache"
        })
        .then(res => {
          tempArray = res.data.search.cliks;
          for (let i = 0; i < tempArray.length; i++) {
            tempData.push({ node: tempArray[i] });
          }
          this.setState({ listTrending_cliks: tempData });
        });
    }
  };
  removedSearchedWord = () => {
    this.setState({
      seachedWordTrending: "",
      listTrending_cliks: this.props.listTrending_cliks
    });
  };
  _handleRefresh = () => {
    this.setState(
      {
        page: 1,
        refreshing: true
      },
      () => {
        this.getJoinClikList();
      }
    );
  };

  _renderFooter = () => {
    if (!this.state.loadingMore) return null;
    return (
      <View>
        <ActivityIndicator animating size="large" />
      </View>
    );
  };
  _renderItem = item => {
    var row = item.item.node ? item.item.node : item.item;
    return (
      <View key={item.index}>
        <View
          style={{
            overflow: "hidden",
            marginBottom: 7,
            flexDirection: "row",
            width: "100%"
          }}
        >
          <View
            style={{
              width: "85%",
              justifyContent: "flex-start",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              alignSelf: "center"
            }}
          >
            {row.invite_only == true && (
              <Icon
                name="lock"
                type="font-awesome"
                color={"#e1e1e1"}
                size={20}
                // iconStyle={{
                //   alignSelf:'center'
                // }}
              />
            )}
            <TouchableOpacity
              style={{
                height: 30,
                padding: 5,
                backgroundColor: "#E8F5FA",
                borderRadius: 6,
                alignSelf: "flex-start",
                marginHorizontal: 5
              }}
              onPress={() => this.goToClikProfile(row.name)}
            >
              <Text
                style={{
                  color: "#4169e1",
                  fontWeight: "bold",
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                #{row.name}
              </Text>
            </TouchableOpacity>
            <Text
              numberOfLines={1}
              style={{
                textAlign: "left",
                color: "#000",
                fontSize: 13,
                fontFamily: ConstantFontFamily.defaultFont,
                // whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingHorizontal: 10
              }}
            >
              {row.description}
            </Text>
          </View>
          <View
            style={{
              width: "15%"
              // alignSe/lf: "center"
            }}
          >
            <ClikJoinButton clikId={row.id} privacy={row.invite_only} />
          </View>
        </View>

        {/* {this.state.ClikList.length == 0 && this.state.term != "" && (
      <View>
        <Icon
          color={"#000"}
          iconStyle={{
            color: "#fff",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center"
          }}
          reverse
          name="tags"
          type="font-awesome"
          size={20}
          containerStyle={{
            alignSelf: "center"
          }}
        />
        <Text
          style={{
            fontSize: 14,
            fontWeight: "bold",
            fontFamily: ConstantFontFamily.defaultFont,
            color: "#000",
            alignSelf: "center"
          }}
        >
          No Cliks Results
        </Text>
      </View>
    )} */}
      </View>
    );
  };

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    let perLoadDataCount = 6;
    let halfOfLoadDataCount = perLoadDataCount / 2;
    let lastAddArr =
      this.state.listTrending_cliks.length > 0 &&
      this.state.listTrending_cliks.slice(-perLoadDataCount);

    try {
      if (
        lastAddArr[halfOfLoadDataCount] &&
        viewableItems.length > 0
        // &&
        // this.props.loginStatus == 1
      ) {
        //FlatList
        if (
          viewableItems.find(
            item =>
              item.item.node.id === lastAddArr[halfOfLoadDataCount].node.id
          )
        ) {
          this._handleLoadMore(0);
        }
      }
    } catch (e) {
      console.log(e, "lastAddArr", lastAddArr[halfOfLoadDataCount]);
    }
  };

  _handleLoadMore = distanceFromEnd => {
    this.setState(
      (prevState, nextProps) => ({
        //  loadingMore: true
      }),
      () => {
        if (
          0 <= distanceFromEnd &&
          distanceFromEnd <= 5 &&
          this.state.apiCall == true
        ) {
          this.setState({
            apiCall: false
          });

          this.getJoinClikList();
        }
      }
    );
  };

  render() {
    return (
    <View style={{justifyContent:'space-between',
    height: "100%",flexDirection:'row'}}>
    <View style ={{width:Dimensions.get("window").width - 550}}>
    <SidePanel
      ref={navigatorRef => {
        NavigationService.setTopLevelNavigator(navigatorRef);
      }}
      navigation={NavigationService}
    /></View>
      <View
        style={{
          maxWidth: 550,
          flex: 1,
          alignItems: "center",
          // justifyContent: "space-between",
          padding: 50,
          backgroundColor: "#f4f4f4"
        }}
      >
        {/* <View
          style={{
            overflow: "hidden",
            width: "100%",
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center"
          }}
        > */}
        <View
          style={{
            borderWidth: 1,
            borderColor: "#c5c5c5",
            borderRadius: 10,
            backgroundColor: "#fff",
            marginBottom: 10,
            paddingTop: 10
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row"
            }}
          >
            <Image
              source={require("../assets/image/logo.png")}
              style={{
                height: 60,
                width: 60
              }}
              resizeMode={"contain"}
            />
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 45,
                fontFamily: ConstantFontFamily.defaultFont
              }}
            >
              {" "}
              weclikd
            </Text>
          </View>

          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginVertical: 10,
              marginHorizontal: 50
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 20,
                fontFamily: ConstantFontFamily.defaultFont
              }}
            >
              Join Cliks. A click is a discussion group. Private cliks require
              an admin's invite.
            </Text>
          </View>
        </View>

        {/* <View
            style={{
              borderWidth: 1,
              borderColor: "#c5c5c5",
              borderRadius: 10,
              backgroundColor: "#fff",
              marginBottom: 10,
              paddingTop: 10
            }}
          > */}
        <View
          style={{
            width: "100%",
            height: 40,
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "center",
            borderColor: "#c5c5c5",
            borderWidth: 1,
            borderRadius: 10,
            paddingHorizontal: 10,
            // marginBottom: 10,
            backgroundColor: "#fff",
            marginBottom:10,
          }}
        >
          <View style={{ width: "10%", marginRight: "auto" }}>
            <Icon name="search" size={18} type="font-awesome" />
          </View>

          <TextInput
            autoFocus={true}
            placeholder="Search Weclikd"
            onChangeText={query => this.searchedClikName(query)}
            value={this.state.seachedWordTrending}
            style={{
              height: 40,
              width: "74%",
              paddingHorizontal: 10,
              border: "none",
              outline: "none",
              position: "absolute",
              left: "13%"
            }}
          />

          <TouchableOpacity
            onPress={() => this.removedSearchedWord()}
            style={{ marginLeft: "auto", width: "10%" }}
          >
            <Icon name="close" size={18} type="font-awesome" />
          </TouchableOpacity>
        </View>

        {/* search area end */}

        <View
          style={{
            backgroundColor: "#fff",
            height: 330,
            width: "100%",
            borderWidth: 1,
            borderColor: "#c5c5c5",
            padding: 5,
            borderRadius: 10
          }}
        >
          <FlatList
            extraData={this.state}
            contentContainerStyle={{
              flexDirection: "column",
              height: Dimensions.get("window").height - "10%",
              width: "100%"
            }}
            data={this.state.listTrending_cliks}
            keyExtractor={(item, index) => index.toString()}
            renderItem={this._renderItem}
            showsVerticalScrollIndicator={false}
            onRefresh={this._handleRefresh}
            refreshing={this.state.refreshing}
            onEndReached={({ distanceFromEnd }) => {
              //this._handleLoadMore(distanceFromEnd);
            }}
            onEndReachedThreshold={0.2}
            initialNumToRender={10}
            ListFooterComponent={this._renderFooter}
            onViewableItemsChanged={this.onViewableItemsChanged}
            viewabilityConfig={this.viewabilityConfig}
          />

          {/* </View> */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%"
              // paddingVertical: 20,
              // paddingHorizontal: "5%",
              // borderLeftWidth: 1,
              // borderRightWidth: 1,
              // borderColor: "#c5c5c5"
              // borderRadius: 10
            }}
          >
            <TouchableOpacity
              block
              style={[
                ButtonStyle.backgroundStyle,
                ButtonStyle.containerStyle,
                { alignItems: "center", justifyContent: "center" }
              ]}
              onPress={() => {
                let nav = this.props.navigation.dangerouslyGetParent().state;
                if (nav.routes.length > 1) {
                  this.props.navigation.goBack();
                  return;
                } else {
                  this.props.navigation.navigate("home");
                }
              }}
            >
              <Text style={ButtonStyle.titleStyle}>Back</Text>
            </TouchableOpacity>

            <TouchableOpacity
              block
              style={[
                ButtonStyle.backgroundStyle,
                ButtonStyle.containerStyle,
                { alignItems: "center", justifyContent: "center" }
              ]}
              onPress={() => {
                this.props.navigation.navigate("follow");
              }}
            >
              <Text style={ButtonStyle.titleStyle}>Continue</Text>
            </TouchableOpacity>
          </View>
        </View>
        {/* </View> */}
      </View>
    </View>
    );
  }
}

const mapStateToProps = state => ({
  getCreateAccount: state.CreateAccountReducer.get("setCreateAccountData"),
  getTrending_Topics_List: state.TrendingTopicsReducer.get(
    "Trending_Topics_List"
  )
    ? state.TrendingTopicsReducer.get("Trending_Topics_List")
    : List(),
  listTrending_feeds: !state.TrendingExternalFeedsReducer.getIn([
    "Trending_ExternalFeeds"
  ])
    ? List()
    : state.TrendingExternalFeedsReducer.getIn(["Trending_ExternalFeeds"]),
  listTrending_cliks: !state.TrendingCliksReducer.getIn(["Trending_cliks_List"])
    ? List()
    : state.TrendingCliksReducer.getIn(["Trending_cliks_List"]),
  listTrending_users: !state.TrendingUsersReducer.getIn(["Trending_users_List"])
    ? List()
    : state.TrendingUsersReducer.getIn(["Trending_users_List"]),
  getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension")
});

const mapDispatchToProps = dispatch => ({
  setVerifyEmailModalStatus: payload =>
    dispatch(setVERIFYEMAILMODALACTION(payload)),
  setSignUpModalStatus: payload => dispatch(setSIGNUPMODALACTION(payload)),
  setCreateAccount: payload => dispatch(setCreateAccount(payload)),
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  changeAdminStatus: payload => dispatch(setAdminStatus(payload)),
  SignUpFollowModalStatus: payload =>
    dispatch({ type: "SIGNUP_FOLLOW_MODAL", payload }),
  SignUpJoinModalStatus: payload =>
    dispatch({ type: "SIGNUP_JOIN_MODAL", payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpJoin);
