import "@expo/browser-polyfill";
import _ from "lodash";
import React, { Component } from "react";
import { TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setLikeContent } from "../actionCreator/LikeContentAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";

class ChangeIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      imageList: [1, 2, 3, 4, 5],
      imageColorList: ["NONE", "RED", "SILVER", "GOLD", "DIAMOND"],
      BorderColorIndex: 0,
      score: 0,
      clickEvent: false,
      heartColorList: ["#969faa", "#de5246", "#b0b0b0", "#ffce44", "#4169e1"],
      likeType: 'NONE'
    };
  }

  async componentDidMount() {
    await this.getHeart(this.props.selectedIconList);
    await this.getBorderIndex(this.props.score);
  }

  componentDidUpdate = async prevProps => {

    if (prevProps.selectedIconList !== this.props.selectedIconList) {
      await this.getHeart(this.props.selectedIconList);
    }
    let data = document.getElementById(this.props.PostId);
    if (data != null) {
      data.addEventListener("keydown", event => {
        if (event.keyCode == 76) {
          this.changeIcon();
        }
      });
    }
  };

  getBorderIndex = async score => {
    let tempIndex = 0;
    if (score == 0) {
      tempIndex = 0;
    } else if (0 < score && score <= 25) {
      tempIndex = 1;
    } else if (25 < score && score <= 50) {
      tempIndex = 2;
    } else if (50 < score && score <= 75) {
      tempIndex = 3;
    } else {
      tempIndex = 4;
    }
    await this.setState({
      score: score,
      BorderColorIndex: tempIndex
    });
  };

  getHeart = async liketype => {

    let tempIndex = 0;
    if (liketype == "RED") {
      tempIndex = 1;
    } else if (liketype == "SILVER") {
      tempIndex = 2;
    } else if (liketype == "GOLD") {
      tempIndex = 3;
    } else if (liketype == "DIAMOND") {
      tempIndex = 4;
    } else {
      tempIndex = 0;
    }
    await this.setState({
      index: tempIndex,
      likeType: liketype
    });
  };

  changeIcon = async (value) => {
    let tempValue = value == '#969faa' ? 0 : value == '#de5246' ? 1 : value == '#b0b0b0' ? 2 : value == '#ffce44' ? 3 : value == '#4169e1' ? -1 : 0;
    let countvalue = tempValue + 1
    this.props.hearCountFunc(countvalue)
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    this.setState({
      clickEvent: true
    });
    this.props.onOpen(true);
    if (this.state.index + 1 === this.state.imageList.length) {
      this.setState({
        index: 0
      });
    } else {
      this.setState({
        index: this.state.index + 1
      });
    }
    this.changeIconApi(
      this.state.imageColorList[
      this.state.index + 1 === this.state.imageList.length
        ? 0
        : this.state.index + 1
      ]
    );
  };

  changeIconApi = _.debounce(typeIndex => {
    this.props.LikeContent({
      content_id: this.props.PostId,
      like_type: typeIndex == "NONE" ? "RED" : typeIndex
    });
    this.props.onOpen(false);
  }, 5000);

  render() {
    return (
      <TouchableOpacity
        nativeID={"Heart" + this.props.PostId}
        onPress={() => this.changeIcon(
          this.state.heartColorList[this.state.index]
        )}
      >
        <Icon
          color={
            this.state.index > 0
              ? this.state.heartColorList[this.state.index]
              : this.state.heartColorList[0]
          }
          name={this.state.index > 0 ? "heart" : "heart-o"}
          type="font-awesome"
          size={22}
          iconStyle={{ alignSelf: "center" }}
          containerStyle={{ alignSelf: "center" }}
        />
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = state => ({
  loginStatus: state.UserReducer.get("loginStatus"),
});

const mapDispatchToProps = dispatch => ({
  LikeContent: payload => dispatch(setLikeContent(payload)),
  setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ChangeIcon
);