import React from "react";
import { Button } from "react-native";
import { Animated, Dimensions, View } from "react-native";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import ButtonStyle from "../constants/ButtonStyle";
import Colors from "../constants/Colors";

class ShadowSkeletonForProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loopArray: [0, 1, 2, 3, 4]
    };
    this.Pagescrollview = null;
    this.circleAnimatedValue = new Animated.Value(0);
  }

  componentDidMount() {
    this.circleAnimated();
  }

  circleAnimated = () => {
    this.circleAnimatedValue.setValue(0);
    Animated.timing(this.circleAnimatedValue, {
      toValue: 1,
      duration: 1000
    }).start(() => {
      setTimeout(() => {
        this.circleAnimated();
      }, 1000);
    });
  };

  render() {
    const translateX = this.circleAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-10, Dimensions.get("window").width]
    });
    const translateTitle = this.circleAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-10, Dimensions.get("window").width]
    });
    return (
      <View style={[ButtonStyle.normalFeedStyleForDashboard, {
        height:  hp("20%"),
        backgroundColor:"rgba(0,0,0,0.03)",

      }]}></View>
      // <SkeletonContent

      //   containerStyle={{
      //     width: Dimensions.get('window').width >= 1200 ? "96%" : "100%",
      //     marginLeft: Dimensions.get('window').width >= 1200 ? 15 : 8,
      //     marginTop: Dimensions.get('window').width >= 1200 ? 15 : 10,
      //     height: hp("20%"),
      //     shadowOffset: {
      //       width: 0,
      //       height: 5,
      //     },
      //     shadowOpacity: 0.25,
      //     shadowRadius: 12,

      //     elevation: 7,
      //     borderRadius: 20
      //   }}
      //   boneColor={"rgba(0,0,0,0.03)"}
      //   highlightColor={Colors.greyBackground}
      //   layout={[{ width: '100%', height: hp("20%"), borderRadius: 20 }]}
      //   animationDirection="horizontalRight"
      //   isLoading={true}
      // />
      // <View style={{        
      //   borderColor: '#0000'
      // }}>

      //   <View
      //     style={[ButtonStyle.normalFeedStyleForDashboard,

      //     {
      //       width: Dimensions.get('window').width >= 750 ? '96%' : "100%",
      //       marginHorizontal: Dimensions.get('window').width >= 750 ? 0 : "2%",
      //       backgroundColor: "rgba(227, 227, 227, 0.2)",
      //       overflow: "hidden",
      //       // borderWidth:1,
      //       borderColor: '#D7D7D7',
      //       marginBottom: 10,

      //     }
      //     ]}
      //   >
      //     <Animated.View
      //       style={[
      //         ButtonStyle.normalFeedStyleForDashboard,
      //         {
      //           width: Dimensions.get('window').width >= 750 ? '96%' : "100%",
      //           height: hp("20%"),
      //           backgroundColor: "#e3e3e3",
      //           overflow: "hidden",
      //           transform: [{ translateX: translateX }]
      //         }
      //       ]}
      //     ></Animated.View>
      //   </View>

      // </View>
    );
  }
}
export default ShadowSkeletonForProfile;
