
import {Dimensions} from 'react-native';

const tintColor = '#2f95dc';
const defultBackgroundColor = "#fff";
const blueColor = '#4169e1'
// '#63B5FF'*****;
// 4f86f7
// 00008B
// first : 4368DD
const greenColor = '#12B257'
const orangeColor = '#ff4500'
const greyBackground = '#f7f7f7'
const whiteBackground = '#fff'
const shadowColor = '#1DA1F2'
const lightGray = "#e8e8e8"
const leftPanelColor = "#F2F3F5"
const yellowColor = "#FFD300"
// lighterGray = '#e8e8e8' 

// '#f4f4f4';

// Color blue - #016FD0
// Color Green - #12B257
// Color Orange - #ff4500

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  customeBackgroundColor: defultBackgroundColor,
  blueColor,
  greenColor,
  orangeColor,
  greyBackground,
  whiteBackground,
  shadowColor,
  lightGray,
  leftPanelColor,
  yellowColor
};
