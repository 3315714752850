import React, { Component } from "react";
import { Dimensions, Image, View, Text, Platform } from "react-native";
import ButtonStyle from "../constants/ButtonStyle";
import Colors from '../constants/Colors';
import ConstantFontFamily from "../constants/FontFamily";


class NotFound extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log('notfound');
    return (
      <View
        style={{
          flex: 1,
          alignItems: "center",
          backgroundColor: Colors.greyBackground
        }}
      >


        <View style={[{
          marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
          marginLeft: 0,
          width: '97%',
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems:"center",
          height: 550,
        }]}>
          {/* <Image
            resizeMode={"contain"}
            source={require("../assets/image/404new.png")}
            style={{
              height: 550,
            }}
          /> */}
          <View style={{
            flexDirection:"column",
            justifyContent:"flex-start",

          }}>
            <Text style={{
              fontSize: 170,
              fontFamily: ConstantFontFamily.HeaderBoldFont,
              fontWeight:"bold",
              lineHeight:150
            }}>404</Text>
            <Text style={{
              fontSize:40,
              fontFamily: ConstantFontFamily.defaultFont,
            }}>SOMETHING IS WRONG</Text>
            <Text style={{
              fontSize: 25,
              fontFamily: ConstantFontFamily.defaultFont,
            }}>Page is not found</Text>
          </View>
          <View style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            display:
              Dimensions.get("window").width >= 750 &&
                Platform.OS == "web"
                ? "flex"
                : "none",
            width: 400,
            height: 350,
            backgroundColor: "#000",
            justifyContent: "center",
            alignItems: "center",
            borderRadius:35,
            // marginRight:200
            // boxShadow:"5px 10px"
            // shadowOffset:20
          }}>
            <Image
              resizeMode={"contain"}
              source={require("../assets/logo 102_2.jpeg")}
              style={{
                justifyContent: "flex-start",
                height: 100,
                width: 100,
                marginRight: 5,
                borderRadius: 8,
                // shadowColor: '#000',
                // shadowOffset: {width: 2, height: 4},
                // // shadowOpacity: 0.2,
                // shadowRadius: 3,
              }}
            />
            <Text
              style={[ButtonStyle.profileTitleStyle, {
                fontSize: 65,
                textAlign: "center",
                color: "white",
                // fontWeight: 'bold'
                fontFamily: ConstantFontFamily.defaultFont,

              }]}
            >
              weclikd
            </Text>
          </View>

        </View>
      </View>
    );
  }
}

export default NotFound;
