import { TOTAL_COMMENTS } from '../constants/Action';


const initialState = {
    totalComment: 0,
}

const TotalCommentReducer = (state = initialState, action) => {

    switch (action.type) {
        case TOTAL_COMMENTS: {
            
            return {
                totalComment: action.payload,
            };
        }
        default:
            return state;
    }
};

export default TotalCommentReducer;