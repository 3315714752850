import "@expo/browser-polyfill";
import { openBrowserAsync } from "expo-web-browser";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Dimensions,
  Platform,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { getFeedProfileDetails } from "../actionCreator/FeedProfileAction";
import { setFEEDREPORTMODALACTION } from "../actionCreator/FeedReportModalAction";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import { setPostDetails } from "../actionCreator/PostDetailsAction";
import ChangeIcon from "../components/ChangeIcon";
import ConstantFontFamily from "../constants/FontFamily";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";

let activeID = "";
const CompactFeedList = props => {
  const swiperRef = useRef(null);
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "a few seconds",
      ss: "%ds",
      m: "1m",
      mm: "%dm",
      h: "1h",
      hh: "%dh",
      d: "1d",
      dd: "%dd",
      w: "1w",
      ww: "%dw",
      M: "1M",
      MM: "%dM",
      y: "1y",
      yy: "%dy"
    }
  });
  useEffect(() => {
    if (props.isFocused == false && swiperRef != null) {
      swiperRef.current.goTo(0);
    }
    let data = document.getElementById(props.item.item.node.id);
    if (data != null) {
      data.addEventListener("keydown", event => {
        if (event.keyCode == 82) {
          props.loginStatus == 1
            ? handleBugClick()
            : props.setLoginModalStatus(true);
        }
        if (event.keyCode == 67) {
          props.setPostDetails({
            title: "Home",
            id: "Post:" + activeID.replace("Post:", ""),
            comment: true
          });
          props.setPostCommentDetails({
            id: activeID.replace("Post:", "")
          });
        }
        if (event.keyCode == 13) {
          event.stopImmediatePropagation();
          goToPostDetailsScreen(activeID.replace("Post:", ""));
        }
      });

      data.addEventListener("click", event => {
        if (data.style.borderColor == "red") {
          activeID = data.id;
          data.scrollIntoView();
        }
      });
    }
  });

  let [showBug, setshowBug] = useState(false);
  let [pressBug, setpressBug] = useState(false);
  const openWindow = async link => {
    await openBrowserAsync(link);
  };
  const loginModalStatusEvent = async status => {
    props.loginModalStatusEventParent(status);
  };
  const goToPostDetailsScreen = async id => {
    let title = "Home";
    await props.setPostDetails({
      title: title,
      id: "Post:" + id
    });
    await props.setPostCommentDetails({
      id: id
    });
  };

  const handleClick = e => {
    setshowBug(e);
  };

  const handleBugClick = e => {
    props.setFeedReportModalAction(true);
    setpressBug(true);
  };



  const goToFeedProfile = async id => {
    await props.setFeedDetails({
      id: id,
      type: "feed"
    });
  };

  return (
    <View
      style={{
        marginHorizontal: 5
      }}
    >
      <View
        nativeID={props.item.item.node.id}
        style={{
          width: "100%",
          flexDirection: "row",
          borderColor: "transparent",
          borderWidth: 1,
          padding: 3,
          borderRadius: 6
        }}
      >
        <View
          style={{
            width: "5%",
            alignSelf: "center",
            padding: 3,
            marginLeft: 8
          }}
        >
          <ChangeIcon
            PostId={props.item.item.node.id}
            selectedIconList={props.item.item.node.user_like_type}
            score={props.item.item.node.likes_score}
            loginModalStatus={loginModalStatusEvent}
            onOpen={status => handleClick(status)}
            showStatus={showBug}
            pressBug={pressBug}
          />
        </View>

        <View
          style={{
            width: "20%",
            flexDirection: "row",
            padding: 3,
            alignItems: "center",
            justifyContent: "center",
            display:
              Dimensions.get("window").width > 750 && Platform.OS == "web"
                ? "flex"
                : "none"
          }}
        >
          {props.item.item.node.link == "" ||
          props.item.item.node.link == null ? (
            <Text
              style={{
                textAlign: "left",
                alignSelf: "center",
                color: "#6D757F",
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 13
              }}
            >
              @
              {props.item.item.node.author &&
                props.item.item.node.author.username}
            </Text>
          ) : props.item.item.node.external_feed != null &&
            props.item.item.node.external_feed.icon_url != null ? (
            <View>
              <TouchableOpacity
                style={{
                  alignSelf: "center"
                }}
                onPress={() =>
                  goToFeedProfile(
                    props.item.item.node.external_feed.id.replace(
                      "ExternalFeed:",
                      ""
                    )
                  )
                }
              ></TouchableOpacity>

              <Hoverable>
                {isHovered => (
                  <TouchableOpacity
                    style={{
                      alignSelf: "center"
                    }}
                    onPress={() => openWindow(props.item.item.node.link)}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        alignSelf: "center",
                        color: "#6D757F",
                        fontSize: 11,
                        fontFamily: ConstantFontFamily.defaultFont,
                        textDecorationLine:
                          isHovered == true ? "underline" : "none",
                        paddingHorizontal: 3
                      }}
                    >
                      {props.item.item.node.link
                        .replace("http://", "")
                        .replace("https://", "")
                        .replace("www.", "")
                        .split(/[/?#]/)[0]
                        .substring(0, 16)}
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
            </View>
          ) : (
            <Hoverable>
              {isHovered => (
                <TouchableOpacity
                  style={{
                    alignSelf: "center"
                  }}
                  onPress={() => openWindow(props.item.item.node.link)}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      alignSelf: "center",
                      color: "#6D757F",
                      fontSize: 13,
                      fontFamily: ConstantFontFamily.defaultFont,
                      textDecorationLine:
                        isHovered == true ? "underline" : "none"
                    }}
                  >
                    {props.item.item.node.link
                      .replace("http://", "")
                      .replace("https://", "")
                      .replace("www.", "")
                      .split(/[/?#]/)[0]
                      .substring(0, 12)}
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>
          )}
        </View>

        <TouchableOpacity
          style={{
            width: Dimensions.get("window").width > 750 ? "69%" : "89%",
            flexDirection: "row",
            padding: 3,
            display: "flex",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
          onPress={() =>
            goToPostDetailsScreen(props.item.item.node.id.replace("Post:", ""))
          }
          onLongPress={() => props.highlight(props.item.item.node.id)}
        >
          <Text
            numberOfLines={1}
            style={{
              textAlign: "left",
              alignSelf: "center",
              color: "#000",
              fontWeight: "bold",
              fontFamily: ConstantFontFamily.defaultFont,
              fontSize: 13
            }}
          >
            {props.item.item.node.title}
          </Text>

          <Text
            numberOfLines={1}
            style={{
              alignSelf: "center",
              color: "#000",
              fontFamily: ConstantFontFamily.defaultFont,
              fontSize: 12,
              paddingLeft: 10
            }}
          >
            {props.item.item.node.summary != null
              ? props.item.item.node.summary.length > 300
                ? props.item.item.node.summary.substring(0, 100) + "..."
                : props.item.item.node.summary.substring(0, 100) + "..."
              : null}
          </Text>
        </TouchableOpacity>

        <View
          style={{
            width: "5%",
            flexDirection: "row",
            padding: 5,
            alignSelf: "center",
            justifyContent: "center"
          }}
        >
          {showBug == false ? (
            <Text
              style={{
                textAlign: "center",
                alignSelf: "center",
                color: "#6D757F",
                fontSize: 10,
                fontFamily: ConstantFontFamily.defaultFont
              }}
            >
              {moment
                .utc(props.item.item.node.created)
                .local()
                .fromNow(true)}
            </Text>
          ) : (
            <Icon
              color={pressBug == true ? "#449733" : "#969faa"}
              name={"bug"}
              type="font-awesome"
              size={20}
              iconStyle={{ alignSelf: "center" }}
              containerStyle={{ alignSelf: "center" }}
              onPress={() =>
                props.loginStatus == 1
                  ? handleBugClick()
                  : props.setLoginModalStatus(true)
              }
            />
          )}
        </View>
      </View>
      <View style={{ height: 2, backgroundColor: "#e1e1e1" }}></View>
    </View>
  );
};

const mapStateToProps = state => ({
  loginStatus: state.UserReducer.get("loginStatus")
});

const mapDispatchToProps = dispatch => ({
  setPostDetails: payload => dispatch(setPostDetails(payload)),
  setFeedReportModalAction: payload =>
    dispatch(setFEEDREPORTMODALACTION(payload)),
  setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),
  setFeedDetails: payload => dispatch(getFeedProfileDetails(payload)),
  setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  React.memo(CompactFeedList)
);
