import React from "react";
import {
  View,
  Text,
  Image,
  ImageBackground,
  Dimensions,
  Platform
} from "react-native";
import ConstantFontFamily from "../constants/FontFamily";
import { Icon } from "react-native-elements";

class SidePanel extends React.Component {
  render() {
    return (
      <View
        style={{
          backgroundColor: "#000",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          display:
            Dimensions.get("window").width > 750 && Platform.OS == "web"
              ? "flex"
              : "none"
        }}
      >
        <ImageBackground
          style={{
            width: "90%",
            height: "90%",
            margin: "5%",
            justifyContent: "center",
            alignItems: "center"
          }}
          source={require("../assets/image/logoBg.png")}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                marginVertical: 10,
                alignItems: "center"
              }}
            >
              <View
                style={{ marginRight: 10, transform: [{ rotateY: "180deg" }] }}
              >
                <Icon
                  name="comment"
                  type="font-awesome"
                  color={"#fff"}
                  size={30}
                />
              </View>
              <Text
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 20,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                engage in quality discussions
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginVertical: 10,
                alignItems: "center"
              }}
            >
              <View style={{ marginRight: 10 }}>
                <Icon name="eye" type="font-awesome" color={"#fff"} size={30} />
              </View>
              <Text
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 20,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                gain visibility for your content
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                marginVertical: 10,
                alignItems: "center"
              }}
            >
              <View style={{ marginRight: 10 }}>
                <Image
                  resizeMode={"contain"}
                  source={require("../assets/image/dollarBag.png")}
                  style={{ width: 30, height: 30 }}
                />
              </View>
              <Text
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 20,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                monetize your post
              </Text>
            </View>
          </View>
        </ImageBackground>
      </View>
    );
  }
}
export default SidePanel;
