import { fromJS } from 'immutable';
import {
    POSTLINK_SUCCESS,
    POSTLINK_FAILURE,
    POST_EDIT_DETAILS_SUCCESS,
    POST_EDIT_DETAILS_FAILURE
} from '../constants/Action';
import NavigationService from '../library/NavigationService';


const initialState = fromJS({
    link: "",
    postEditDetails: null
});

export default function LinkPostReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case POSTLINK_SUCCESS:
            return state
                .set('link', fromJS(payload));
        case POSTLINK_FAILURE:
            return state
                .set('link', "");
        case POST_EDIT_DETAILS_SUCCESS:{
            NavigationService.navigate("editpost");
            return state
                .set('postEditDetails', payload);
        }
        case POST_EDIT_DETAILS_FAILURE:
            return state
                .set('postEditDetails', null);
    }
    return state;
}