import { takeEvery, put } from 'redux-saga/effects';
import { VERIFYEMAILMODALACTION, VERIFYEMAILMODALACTION_SUCCESS, VERIFYEMAILMODALACTION_FAILURE } from "../constants/Action";

export function* VerifyEmailModalMiddleware({ payload }) {
    if (payload == true) {
        yield put({
            type: VERIFYEMAILMODALACTION_SUCCESS,
            payload: payload
        });
    } else {
        yield put({
            type: VERIFYEMAILMODALACTION_FAILURE,
            payload: payload
        });
    }
}

export default function* VerifyEmailModalMiddlewareWatcher() {
    yield takeEvery(VERIFYEMAILMODALACTION, VerifyEmailModalMiddleware);
}