import { fromJS } from 'immutable';
import {
    EDITFEED_SUCCESS,
    EDITFEED_FAILURE,
} from '../constants/Action';


const initialState = fromJS({
    feed: "",
});

export default function EditFeedReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case EDITFEED_SUCCESS:
            return state
                .set('feed', fromJS(payload));
        case EDITFEED_FAILURE:
            return initialState;
    }
    return state;
}