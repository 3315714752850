import React, { lazy, Component, Suspense } from "react";
import {
  Animated,
  Platform,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import ConstantFontFamily from "../constants/FontFamily";
import ShadowSkeleton from "../components/ShadowSkeleton";
import ButtonStyle from '../constants/ButtonStyle';
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { Dimensions } from "react-native";

const DrawerScreen = lazy(() => import("../components/DrawerScreens"))

const TrendingTabs = lazy(() => import("../components/TrendingTabs"))

class BookMarksScreen extends Component {
  totalHeigth = 0;
  constructor(props) {
    super(props);
    this.state = {
      randomItemEvent: 0,
      primaryIndex: 0,
      searchedWord: "",
      searchedFollowText: ""
    };
  }

  componentDidMount = () => {
    this.props.searchOpenBarStatus(false);
  };

  calHeightLeftPannelMian = height => {
    if (height > 0 && height != null) {
      this.totalHeigth = height;
    }
  };

  activeIndexAction = index => {
    this.setState({
      primaryIndex: index,
      searchedWord: ""
    });
  };

  activeTrendingIndexAction = index => {
    this.setState({
      searchedFollowText: ""
    });
  };


  searchParaChanged = () => {
    this.setState({ searchedWord: "" });
  };

  searchTextField = value => {
    this.setState({ searchedWord: value });
  };

  render() {
    return (
      <View
        style={{
          padding: Dimensions.get('window').width <= 750 ? 0 : 10,
          flex: 1
        }}
      >
        <Animated.View
          style={{
            position: Platform.OS == "web" ? "sticky" : null,
            top: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            overflow: "hidden"
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                backgroundColor: "#000",
                borderRadius: Dimensions.get('window').width <= 750 ? 0 : 6,
                height: 42
              }}
            >
              <TouchableOpacity
                style={ButtonStyle.headerBackStyle}
                onPress={() => {
                  this.props.searchOpenBarStatus(false);
                  let nav = this.props.navigation.dangerouslyGetParent().state;
                  if (nav.routes.length > 1) {
                    this.props.navigation.goBack();
                    return;
                  } else {
                    this.props.navigation.navigate("home");
                  }
                }}
              >
                <Icon
                  color={"#fff"}
                  name="angle-left"
                  type="font-awesome"
                  size={40}
                />
              </TouchableOpacity>
              <View
                style={{
                  width: "100%",
                  flex: 1,
                  backgroundColor: "#000",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row"
                }}
              >
                <Text
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: ConstantFontFamily.defaultFont,
                    paddingRight: 45,
                    marginLeft: 20
                  }}
                >
                  Favorites
                </Text>
              </View>
            </View>
          </View>
        </Animated.View>
        <View
          style={[ButtonStyle.borderStyle, {
            paddingHorizontal: 10,
            flex: 1,
            height: hp('50%'),
            backgroundColor: "#fff",
            marginTop: 10
          }]}
        >
          {this.props.loginStatus == 1 ? (
            <Suspense fallback={<ShadowSkeleton />}>
              <DrawerScreen
                calHeightLeftPannelSend={this.calHeightLeftPannelMian}
                navigation={this.props.navigation}
                activeIndex={this.activeIndexAction}
                searchParam={this.searchParaChanged}
                searchedWord={this.state.searchedWord}
              />
            </Suspense>
          ) : (
            <Suspense fallback={<ShadowSkeleton />}>
              <TrendingTabs
                navigation={this.props.navigation}
                randomItemEvent={this.state.randomItemEvent}
                searchedFollowText={this.state.searchedWord}
                activeIndex={this.activeTrendingIndexAction}
                onchangetext={query => this.searchTextField(query)}
                onpress={() => this.setState({ searchedFollowText: "" })}
              />
            </Suspense>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  loginStatus: state.UserReducer.get("loginStatus")
});

const mapDispatchToProps = dispatch => ({
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  BookMarksScreen
);
