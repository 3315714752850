import React, { lazy, Suspense, createRef } from "react";
import {
  Animated,
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
} from "react-native";
import { Icon } from "react-native-elements";
import {
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { TabBar, TabView } from "react-native-tab-view";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { getTrendingTopicsProfileDetails } from "../actionCreator/TrendingTopicsProfileAction";
import ShadowSkeletonForProfile from "../components/ShadowSkeletonForProfile";
import ShadowSkeleton from "../components/ShadowSkeleton";
import ConstantFontFamily from "../constants/FontFamily";
import { retry } from "../library/Helper";
import ButtonStyle from "../constants/ButtonStyle";
import NavigationService from "../library/NavigationService";
import Colors from "../constants/Colors";
import { feedFluctuation, showDiscussion, screen } from '../reducers/action/Completed';
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import CreateCommentCard from "../components/CreateCommentCard"
import Overlay from "react-native-modal-overlay";
import { DeleteTopicVariables } from "../graphqlSchema/graphqlVariables/LikeContentVariables";
import { DeleteTopicMutation } from "../graphqlSchema/graphqlMutation/FeedMutation";
import { UserLoginMutation, UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation } from "../graphqlSchema/graphqlMutation/UserMutation";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import { editTopic } from "../actionCreator/TopicEditAction";


let lastTap = null;

const TopicProfileCard = lazy(() => import("../components/TopicProfileCard"))

const HeaderRight = lazy(() => import("../components/HeaderRight"))

const NewHomeFeed = lazy(() => import("../components/NewHomeFeed"))

const SEOMetaData = lazy(() => import("../components/SEOMetaData"))

const TrendingHomeFeed = lazy(() => import("../components/TrendingHomeFeed"))

const DiscussionHomeFeed = lazy(() => import("../components/DiscussionHomeFeed"))

const CommentDetailScreen = lazy(() => import("./CommentDetailScreen"))

const BottomScreen = lazy(() => import("../components/BottomScreen"))

class TopicScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.offset = new Animated.Value(0);
    this.state = {
      commentDelay: false,
      tabPost: false,
      showIcon: "#fff",
      routes: [

        { key: "first", title: "New", icon: "clock-o", type: "font-awesome" },
        {
          key: "second",
          title: "Trending",
          icon: "fire",
          type: "simple-line-icon",
        },
        {
          key: "third",
          title: "Bookmarks",
          icon: "bookmark",
          type: "font-awesome",
        },
      ],
      index: 1,
      id: this.props.navigation.getParam("title", "NO-ID").toUpperCase()
        ? this.props.navigation.getParam("title", "NO-ID").toUpperCase()
        : "",
      ViewMode: "Default",
      scrollY: 0,
      ProfileHeight: 0,
      feedY: 0,
      opentool:false
    };
    this.flatListRefNew = createRef();
    this.flatListRefDiscussion = createRef();
    this.flatListRefTrending = createRef();
  }

  onLoadingComment = () => {
    this.setState({ commentDelay: true });
    setTimeout(() => {
      if (this.props.PostCommentDetails) {
        this.setState({ commentDelay: false });
      }
    }, 500);
  };

  changeTabStatus = () => {
    this.setState({ tabPost: false });
  };

  doScroll = (value, name) => {
    if (name == "new") {
      this.flatListRefNew = value;
    } else if (name == "trending") {
      this.flatListRefTrending = value;
    } else if (name == "discussion") {
      this.flatListRefDiscussion = value;
    }
  };

  scrollFunc = () => {
    {
      this.flatListRefNew.current &&
        this.flatListRefNew.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true,
        }),
        this.flatListRefTrending.current &&
        this.flatListRefTrending.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true,
        }),
        this.flatListRefDiscussion.current &&
        this.flatListRefDiscussion.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true,
        });
    }
  };

  handleDoubleTap = () => {
    if (lastTap !== null) {
      this.scrollFunc();
      clearTimeout(lastTap);
      lastTap = null;
    } else {
      lastTap = setTimeout(() => {
        clearTimeout(lastTap);
        lastTap = null;
      }, 1000);
    }
    this.setState({ tabPost: false });
  };

  _handleIndexChange = (index) => {
    this.setState({ index });

    if (index == 0 && this.props.TrendingTopicHomeFeedList && this.props.TrendingTopicHomeFeedList.length > 0) {
      this.props.feedFluctuation(true)
      this.props.setPostCommentReset({
        payload: [],
        postId: '',
        title: '',
        loading: true
      });
      this.props.setPostCommentDetails({
        id: "Post:" + this.props.TrendingTopicHomeFeedList[0].node.id.replace("Post:", ""),
        title: this.props.TrendingTopicHomeFeedList[0].node.title,
        loading: false
      });



    } else if (index == 1 && this.props.TrendingTopicHomeFeedList && this.props.TrendingTopicHomeFeedList.length > 0) {
      this.props.feedFluctuation(true)
      this.props.setPostCommentReset({
        payload: [],
        postId: '',
        title: '',
        loading: true
      });

      this.props.setPostCommentDetails({
        id: "Post:" + this.props.TrendingTopicHomeFeedList[0].node.id.replace("Post:", ""),
        title: this.props.TrendingTopicHomeFeedList[0].node.title,
        loading: false
      });


    } else if (index == 2 && this.props.TrendingTopicHomeFeedList && this.props.TrendingTopicHomeFeedList.length > 0) {
      this.props.feedFluctuation(true)
      this.props.setPostCommentReset({
        payload: [],
        postId: '',
        title: '',
        loading: true
      });

      this.props.setPostCommentDetails({
        id: "Post:" + this.props.TrendingTopicHomeFeedList[0].node.id.replace("Post:", ""),
        title: this.props.TrendingTopicHomeFeedList[0].node.title,
        loading: false
      });

    }
  };

  _renderTabBar = (props) =>
    Dimensions.get("window").width >= 750 && (
      <View>
        <View
          style={[
            ButtonStyle.TabbarBorderStyleForDashboard,
            {
              flexDirection: "row",
              height: 55,
              backgroundColor:
                Dimensions.get("window").width <= 750 ? "#000" : "#fff",
              alignItems: "center",
              paddingHorizontal: 10,
              paddingVertical: 10,
              marginLeft: 0,
              width: '100%',
              // marginBottom:7
            },
          ]}
        >
          <TabBar
            onTabPress={() => this.handleDoubleTap()}
            {...props}
            indicatorStyle={{
              backgroundColor: "transparent",
              height: 2,
              borderRadius: 6,
            }}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              height: 55,
              shadowColor: "transparent",
              justifyContent: "center",
            }}
            labelStyle={{
              color: "#000",
              fontFamily: ConstantFontFamily.defaultFont,
            }}
            // renderIcon={({ route, focused, color }) =>
            //   Dimensions.get("window").width >= 750 && (
            //     <Icon
            //       name={route.icon}
            //       type={route.type}
            //       color={focused ? Colors.blueColor : "#D3D3D3"}
            //     />
            //   )
            // }
            renderLabel={({ route, focused, color, isActive }) => (
              <Text
                style={[
                  ButtonStyle.tabbarTitleStyle,
                  {
                    color: focused ? Colors.blueColor : isActive ? Colors.blueColor : "#D3D3D3",
                  },
                ]}
              >
                {route.title}
              </Text>
            )}
          />

        </View>
      </View>
    );


  showIcon = (data) => {
    this.setState({
      showIcon: data,
    });
  };

  async componentDidMount() {
    this.props.searchOpenBarStatus(false);
    this.offset = new Animated.Value(0);
    this.getStaticData();
    this.props.showDiscussion(false)
    this.props.screen("topicScreen")

  }

  updateStar = () => {
    this.getStaticData();
    this.props.updateTopicStar(true);
  };

  async getStaticData() {
    let itemId = await this.props.navigation.getParam("title", "NO-ID");
    this.setState({
      id: itemId,
    });
    if (itemId) {
      this.props.topicId({
        id: itemId,
        type: this.props.navigation.getParam("type", "NO-ID"),
      });

      const index = this.props.getUserFollowTopicList.findIndex(
        (i) =>
          i.node.topic.name.toLowerCase() ==
          itemId.replace("%2F", "").toLowerCase().replace("topic:", "")
      );
      if (index != -1) {
        if (
          this.props.getUserFollowTopicList[index].node.follow_type == "FAVORITE"

        ) {
          this.setState({
            showIcon: "#FADB4A",
          });
        }
        if (
          this.props.getUserFollowTopicList[index].node.follow_type == "FOLLOW"

        ) {
          this.setState({
            showIcon: "#E1E1E1",
          });
        }
      } else {
        this.setState({
          showIcon: "#fff",
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.getHasScrollTop == true && this.UserProfilescrollview) {
      this.UserProfilescrollview.scrollTo({ x: 0, y: 0, animated: true });
      this.props.setHASSCROLLEDACTION(false);
    }
    if (
      prevProps.navigation.getParam("title", "NO-ID").toUpperCase() !=
      this.props.navigation.getParam("title", "NO-ID").toUpperCase()
    ) {
      this.getStaticData();
      this.props.searchOpenBarStatus(false);
      this.TopicsProfilescrollview &&
        this.TopicsProfilescrollview.scrollTo({ x: 0, y: 0, animated: true });
    }
  }

  loginHandle = () => {
    this.props.setLoginModalStatus(true);
  };

  listScroll = (value) => {
    this.setState({
      feedY: value,
    });
  };

  openPopUp = () => {
    this.setState({ opentool: true })
  }
  onClose = () => {
    this.setState({
      opentool: false
    });
  };

  showAlert = () => {

    if (Platform.OS === "web") {
      var result = confirm(
        "Are you sure you want to delete /" +
        this.props.topicsDetails.getIn(["data", "node"]).get('name')
          .replace("%2F", "/")
          .toLowerCase()
      );
      if (result == true) {
        DeleteTopicVariables.variables.topic_id = topic.getIn(["id"]);
        applloClient
          .query({
            query: DeleteTopicMutation,
            ...DeleteTopicVariables,
            fetchPolicy: "no-cache"
          })
          .then(async res => {
            let resDataLogin = await applloClient
              .query({
                query: UserLoginMutation,
                variables: {
                  id: "Account:" + "CurrentUser"
                },
                fetchPolicy: "no-cache"
              }).then(res => {
                return res
              })

            let userfollow = await applloClient
              .query({
                query: UserFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.users_following.edges
              })

            let topicfollow = await applloClient
              .query({
                query: TopicFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.topics_following.edges
              })
            let clikfollow = await applloClient
              .query({
                query: ClikFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.cliks_following.edges
              })
            let externalfollow = await applloClient
              .query({
                query: ExternalFeedFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.external_feeds_following.edges
              })
            let userData = {
              data: resDataLogin.data.node,
              externalfollow: externalfollow,
              clikfollow: clikfollow,
              topicfollow: topicfollow,
              userfollow: userfollow

            }
            await this.props.saveLoginUser(userData);
          });
      } else {
      }
    } else {
      Alert.alert(
        "Are you sure you want to delete /" +
        this.props.topicsDetails.getIn(["data", "node"]).get('name')
          .replace("%2F", "/")
          .toLowerCase(),
        [
          {
            text: "NO",
            onPress: () => console.warn("NO Pressed"),
            style: "cancel"
          },
          {
            text: "YES",
            onPress: () => {
              DeleteTopicVariables.variables.topic_id = this.props.topicsDetails.getIn(["data", "node"]).get("id");
              applloClient
                .query({
                  query: DeleteTopicMutation,
                  ...DeleteTopicVariables,
                  fetchPolicy: "no-cache"
                })
                .then(async res => {
                  let resDataLogin = await applloClient
                    .query({
                      query: UserLoginMutation,
                      variables: {
                        id: "Account:" + "CurrentUser"
                      },
                      fetchPolicy: "no-cache"
                    }).then(res => {
                      return res
                    })

                  let userfollow = await applloClient
                    .query({
                      query: UserFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e.data.node.users_following.edges
                    })

                  let topicfollow = await applloClient
                    .query({
                      query: TopicFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e.data.node.topics_following.edges
                    })
                  let clikfollow = await applloClient
                    .query({
                      query: ClikFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e.data.node.cliks_following.edges
                    })
                  let externalfollow = await applloClient
                    .query({
                      query: ExternalFeedFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e.data.node.external_feeds_following.edges
                    })
                  let userData = {
                    data: resDataLogin.data.node,
                    externalfollow: externalfollow,
                    clikfollow: clikfollow,
                    topicfollow: topicfollow,
                    userfollow: userfollow

                  }
                  await props.saveLoginUser(userData);
                });
            }
          }
        ]
      );
    }
  };

  _renderScene = ({ route }) => {
    // console.log(this.state.scrollY,'this.state.scrollYwervwdfvwd');
    switch (route.key) {
      case "third":
        return (
          <View>
            {this.props.loginStatus == 1 ? (
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{
                  height: hp("100%") - 50,
                  paddingBottom: 300,
                  // borderWidth: 3,
                  // borderColor: '#000',
                  // borderBottomWidth: 0,
                  // borderTopWidth:0
                }}
                ref={(ref) => {
                  this.TopicsProfilescrollview = ref;
                }}
                // scrollEventThrottle={16}
                // scrollEnabled={
                //   this.state.listScroll >= this.props.ProfileHeight
                //     ? true
                //     : false
                // }
                scrollEnabled={this.state.scrollY >= 100 ? true : false}
              >
                <Suspense fallback={null}>

                  <DiscussionHomeFeed
                    navigation={this.props.navigation}
                    listType={"Topic"}
                    data={this.props.navigation
                      .getParam("title", "NO-ID")
                      .toLowerCase()}
                    ViewMode={this.state.ViewMode}
                    changeTabStatus={this.changeTabStatus}
                    tabPost={this.state.tabPost}
                    onLoadingComment={this.onLoadingComment}
                    listScroll={this.listScroll}
                    ActiveTab={this.state.routes[this.state.index].title}
                    doScroll={this.doScroll}
                  />
                </Suspense>
              </ScrollView>
            ) : (
              <View>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="sticky-note"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      onPress={() => this.loginHandle()}
                      style={{ textDecorationLine: "underline" }}
                    >
                      Login
                    </Text>{" "}
                    to see bookmarked posts
                  </Text>
                </View>
              </View>
            )}
          </View>
        );
      case "first":
        return (
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
              height: hp("100%") - 50,
              paddingBottom: 300,
              // borderWidth: 3,
              // borderColor: '#000',
              // borderBottomWidth: 0,
              // borderTopWidth:0
            }}
            // ref={(ref) => {
            //   this.TopicsProfilescrollview = ref;
            // }}
            // scrollEventThrottle={16}
            // scrollEnabled={
            //   this.state.listScroll >= this.props.ProfileHeight ? true : false
            // }
            scrollEnabled={this.state.scrollY >= 100 ? true : false}

          >
            <Suspense fallback={null}>
              <NewHomeFeed
                navigation={this.props.navigation}
                listType={"Topic"}
                data={this.props.navigation
                  .getParam("title", "NO-ID")
                  .toLowerCase()}
                ViewMode={this.state.ViewMode}
                changeTabStatus={this.changeTabStatus}
                tabPost={this.state.tabPost}
                onLoadingComment={this.onLoadingComment}
                listScroll={this.listScroll}
                ActiveTab={this.state.routes[this.state.index].title}
                doScroll={this.doScroll}
              />
            </Suspense>
          </ScrollView>
        );
      case "second":
        return (
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
              height: hp("100%"),
              // borderWidth: 3,
              // borderColor: '#000',
              // borderBottomWidth: 0,
              // borderTopWidth:0
              // paddingBottom: 300,
            }}
            ref={(ref) => {
              this.TopicsProfilescrollview = ref;
            }}

            // scrollEventThrottle={16}
            // scrollEnabled={
            //   this.state.listScroll >= this.props.ProfileHeight ? true : false
            // }
            scrollEnabled={this.state.scrollY >= 100 ? true : false}

          >
            <Suspense fallback={null}>

              <TrendingHomeFeed
                navigation={this.props.navigation}
                listType={"Topic"}
                data={this.props.navigation
                  .getParam("title", "NO-ID")
                  .toLowerCase()}
                ViewMode={this.state.ViewMode}
                changeTabStatus={this.changeTabStatus}
                tabPost={this.state.tabPost}
                onLoadingComment={this.onLoadingComment}
                listScroll={this.listScroll}
                ActiveTab={this.state.routes[this.state.index].title}
                doScroll={this.doScroll}
              />
            </Suspense>
          </ScrollView>
        );
      default:
        return (
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
              height: hp("100%") - 50,
              paddingBottom: 300,
              // borderWidth: 3,
              // borderColor: '#000',
              // borderBottomWidth: 0,
              // borderTopWidth:0
            }}
            // ref={(ref) => {
            //   this.TopicsProfilescrollview = ref;
            // }}
            // scrollEventThrottle={16}
            // scrollEnabled={={(ref) => {
            //   this.TopicsProfilescrollview = ref;
            // }}
            // scrollEnabled={
            //   this.state.listScroll >= this.props.ProfileHeight ? true : false
            // }
            scrollEnabled={this.state.scrollY >= 100 ? true : false}

          >
            <Suspense fallback={null}>

              <NewHomeFeed
                navigation={this.props.navigation}
                listType={"Topic"}
                data={this.props.navigation
                  .getParam("title", "NO-ID")
                  .toLowerCase()}
                ViewMode={this.state.ViewMode}
                changeTabStatus={this.changeTabStatus}
                tabPost={this.state.tabPost}
                onLoadingComment={this.onLoadingComment}
                listScroll={this.listScroll}
                ActiveTab={this.state.routes[this.state.index].title}
                doScroll={this.doScroll}
              />
            </Suspense>
          </ScrollView>
        );
    }
  };

  renderTabViewForMobile = () => {
    if (this.props.getTabView == "Bookmarks") {
      return (
        <View>
          {this.props.loginStatus == 1 ? (
            <Suspense fallback={null}>

              <DiscussionHomeFeed
                navigation={this.props.navigation}
                listType={"Topic"}
                data={this.props.navigation
                  .getParam("title", "NO-ID")
                  .toLowerCase()}
                ViewMode={this.state.ViewMode}
                listScroll={this.listScroll}
                ActiveTab={this.state.routes[this.state.index].title}
                doScroll={this.doScroll}
              />
            </Suspense>
          ) : (
            <View>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                  reverse
                  name="sticky-note"
                  type="font-awesome"
                  size={20}
                  containerStyle={{
                    alignSelf: "center",
                  }}
                />
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: ConstantFontFamily.defaultFont,
                    color: "#000",
                    alignSelf: "center",
                  }}
                >
                  <Text
                    onPress={() => this.loginHandle()}
                    style={{ textDecorationLine: "underline" }}
                  >
                    Login
                  </Text>{" "}
                  to see bookmarked posts
                </Text>
              </View>
            </View>
          )}
        </View>
      );
    } else if (this.props.getTabView == "New") {
      return (
        <Suspense fallback={null}>

          <NewHomeFeed
            navigation={this.props.navigation}
            listType={"Topic"}
            data={this.props.navigation.getParam("title", "NO-ID").toLowerCase()}
            ViewMode={this.state.ViewMode}
            listScroll={this.listScroll}
            ActiveTab={this.state.routes[this.state.index].title}
            doScroll={this.doScroll}
          />
        </Suspense>
      );
    } else if (this.props.getTabView == "Trending") {
      return (
        <Suspense fallback={null}>

          <TrendingHomeFeed
            navigation={this.props.navigation}
            listType={"Topic"}
            data={this.props.navigation.getParam("title", "NO-ID").toLowerCase()}
            ViewMode={this.state.ViewMode}
            listScroll={this.listScroll}
            ActiveTab={this.state.routes[this.state.index].title}
            doScroll={this.doScroll}
          />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback={null}>

          <TrendingHomeFeed
            navigation={this.props.navigation}
            listType={"Topic"}
            data={this.props.navigation.getParam("title", "NO-ID").toLowerCase()}
            ViewMode={this.state.ViewMode}
            listScroll={this.listScroll}
            ActiveTab={this.state.routes[this.state.index].title}
            doScroll={this.doScroll}
          />
        </Suspense>
      );
    }
  };

  _renderLazyPlaceholder = ({ route }) => <ShadowSkeleton />;

  render() {

    return (
      <>
        <View style={styles.container}>
          {Platform.OS == "web" && (
            <Suspense fallback={null}>

              <SEOMetaData
                title={
                  this.props.topicsDetails.getIn(["data", "topic"])
                    ? this.props.topicsDetails.getIn(["data", "topic"]).get("name")
                    : ""
                }
                description={
                  this.props.topicsDetails.getIn(["data", "topic"])
                    ? this.props.topicsDetails
                      .getIn(["data", "topic"])
                      .getIn(["description"])
                    : ""
                }
                image={
                  this.props.topicsDetails.getIn(["data", "topic"])
                    ? this.props.topicsDetails
                      .getIn(["data", "topic"])
                      .getIn(["banner_pic"])
                    : ""
                }
              />
            </Suspense>
          )}
          {/* {
            Dimensions.get("window").width <= 750 && (
              <Animated.View
                style={{
                  position: Platform.OS == "web" ? "sticky" : null,
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  overflow: "hidden",
                  borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
                  paddingTop: Dimensions.get("window").width <= 750 ? 0 : 10,
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      backgroundColor: "#000",
                      borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
                      marginBottom:
                        Dimensions.get("window").width <= 750 ? 0 : 10,
                      height: 50,
                    }}
                  >
                    <TouchableOpacity
                      style={[
                        ButtonStyle.headerBackStyle,
                        { marginRight: !this.props.getsearchBarStatus ? 10 : 0 },
                      ]}
                      onPress={() => {
                        this.props.searchOpenBarStatus(false);
                        let nav =
                          this.props.navigation.dangerouslyGetParent().state;
                        if (nav.routes.length > 1) {
                          this.props.navigation.goBack();
                          return;
                        } else {
                          this.props.navigation.navigate("home");
                        }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>

                    {this.props.topicsDetails.size != 0 &&
                      !this.props.getsearchBarStatus && (
                        <TouchableOpacity
                          style={{
                            flex: this.props.loginStatus
                              ? 1
                              : !this.props.loginStatus && Platform.OS == "web"
                                ? 1
                                : !this.props.loginStatus &&
                                Platform.OS != "web" &&
                                0,
                            flexDirection: "row",
                            alignSelf: "center",
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              paddingVertical: 5,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <TouchableOpacity
                              style={{
                                backgroundColor: this.props.topicsDetails
                                  ?.getIn(["data", "node"])
                                  ?.get("parents")
                                  ? "#e3f9d5"
                                  : "#e3f9d5",
                                borderRadius: 6,
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: 5,
                                height: 30,
                                alignSelf: "center",
                                paddingHorizontal: 5,
                              }}
                              onPress={() =>
                                this.TopicsProfilescrollview.scrollTo({
                                  x: 0,
                                  y: 0,
                                  animated: true,
                                })
                              }
                            >
                              <Hoverable>
                                {(isHovered) => (
                                  <Text
                                    style={{
                                      color: this.props.topicsDetails
                                        .getIn(["data", "node"])
                                        ?.get("parents")
                                        ? "#009B1A"
                                        : "#009B1A",
                                      fontSize: 18,
                                      fontWeight: "bold",
                                      fontFamily: ConstantFontFamily.defaultFont,
                                      textDecorationLine:
                                        isHovered == true ? "underline" : "none",
                                    }}
                                  >
                                    {"/"}
                                    {this.props.navigation.state.params.title
                                      .replace("%2F", "/")
                                      .toLowerCase()}
                                  </Text>
                                )}
                              </Hoverable>
                            </TouchableOpacity>
                          </View>
                        </TouchableOpacity>
                      )}
                    <View
                      style={[
                        ButtonStyle.headerRightStyle,
                        { width: this.props.getsearchBarStatus && "95%" },
                      ]}
                    >
                      <Suspense fallback={null}>
                        <HeaderRight navigation={this.props.navigation} />
                      </Suspense>
                    </View>
                  </View>
                </View>
              </Animated.View>
            )
          } */}
          <ScrollView
            contentContainerStyle={{ height: "100%" }}
            ref={(ref) => {
              this.TopicsProfilescrollview = ref;
            }}
            showsVerticalScrollIndicator={false}
            onScroll={(event) => {
              // this.TopicsProfilescrollview.scrollTo({x: 100, y:200, animated:true})
              this.setState({
                scrollY: event.nativeEvent.contentOffset.y,
              })
            }}
            // scrollEnabled={this.state.scrollY <= 190 ? true : false}
            scrollEventThrottle={16}

          >
            {Dimensions.get('window').width >= 1200 || this.props.show == false ?
              <Animated.View
              // onLayout={(event) => {
              //   let { x, y, width, height } = event.nativeEvent.layout;
              //   if (width > 0) {
              //     this.setState({ ProfileHeight: 100 });
              //   }
              // }}
              >
                <Suspense
                  fallback={
                    Dimensions.get("window").width >= 750 ? (
                      <ShadowSkeletonForProfile />
                    ) : null
                  }
                >
                  <TopicProfileCard
                    item={this.props.topicsDetails}
                    navigation={this.props.navigation}
                    icon={this.showIcon}
                    feedY={this.state.feedY}
                    // ProfileHeight={this.state.ProfileHeight}
                    updateStar={this.updateStar}
                    openPopUp={this.openPopUp}
                  />
                </Suspense>
              </Animated.View> : null}
            <View
              // style={
              //   Dimensions.get("window").width >= 750 &&
              //     this.state.scrollY >= this.state.ProfileHeight
              //     ? [
              //       styles.header,
              //       {
              //         flexDirection: "row",
              //         width: "99%",
              //         height: "100%",
              //         // marginHorizontal:
              //         //   Dimensions.get("window").width >= 750 ? 5 : 0,
              //         // top:
              //         //   this.state.scrollY >= this.state.ProfileHeight
              //         //     ? this.state.scrollY
              //         //     : 0,
              //       },
              //     ]
              //     : {
              //       flexDirection: "row",
              //       width: "100%",
              //       height: "100%",
              //     }
              // }
              style={{

                flexDirection: "row",
                width: "100%",
                height: "100%",
                marginLeft: 0,
                // marginLeft: Dimensions.get("window").width < 1200 && Dimensions.get("window").width >= 750 ? 13 : 0,
                backgroundColor: Colors.leftPanelColor,
              }}
            >
              {Dimensions.get("window").width <= 750 ? (
                <View
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {this.renderTabViewForMobile()}
                </View>
              ) : (
                this.props.show == false &&
                <View
                  style={[
                    ButtonStyle.DashboardTabViewStyle,
                    {
                      width:
                        Platform.OS == "web" &&
                          Dimensions.get("window").width >= 750 &&
                          Dimensions.get("window").width <= 1200
                          ? "100%"
                          : Platform.OS == "web" &&
                            Dimensions.get("window").width >= 1200
                            ? "50%"
                            : "100%",
                      borderColor: '#d3d3d3',
                      // borderRightWidth: 1,
                      // borderLeftWidth: 1,
                      backgroundColor: Colors.leftPanelColor

                    },
                  ]}
                >
                  <TabView
                    swipeEnabled={false}
                    lazy
                    navigationState={this.state}
                    renderScene={this._renderScene}
                    renderLazyPlaceholder={this._renderLazyPlaceholder}
                    renderTabBar={this._renderTabBar}
                    onIndexChange={this._handleIndexChange}
                  />
                </View>
              )}

              {this.props.show == true || Dimensions.get('window').width >= 1200 ?
                <View
                  style={[
                    {

                      width: Platform.OS == "web" &&
                        Dimensions.get("window").width >= 750 && Dimensions.get("window").width <= 1200
                        ? '100%' : Platform.OS == "web" && Dimensions.get("window").width >= 1200 ? '50%'
                          : "100%",
                      marginBottom: 5,
                      // marginLeft: 1,
                      marginRight: Dimensions.get("window").width >= 750 && Dimensions.get("window").width <= 1200 ? 5 : 0,
                      display:
                        Dimensions.get("window").width >= 750 && Platform.OS == "web"
                          ? "flex"
                          : "none",
                      // marginLeft: 5,
                      // height: this.state.scrollY > 250 ? Dimensions.get("window").height - 50 : Dimensions.get("window").height - 300,
                      backgroundColor: Colors.lightGray
                    }]}
                >
                  <View style={[ButtonStyle.TabbarBorderStyleForDashboard, {
                    height: 55,
                    alignItems: "center",
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                    marginLeft: 0,
                    width: '100%',
                    justifyContent: this.props.show ? 'flex-start' : 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    backgroundColor: '#fff',
                    marginBottom: 10
                    // borderTopWidth: 2,
                  }]}>
                    {this.props.show == true ?
                      <TouchableOpacity style={[ButtonStyle.headerBackStyle, { width: '20%' }]}
                        onPress={() => this.props.showDiscussion(false)}>
                        <Icon
                          color={"#000"}
                          name="angle-left"
                          type="font-awesome"
                          size={40}

                        />
                      </TouchableOpacity> : null}
                    < Text style={[ButtonStyle.tabbarTitleStyle, { width: this.props.show ? '55%' : '100%', textAlign: 'center' }]}> Discussion</Text>
                  </View>
                  <Suspense fallback={null}>

                    <CommentDetailScreen
                      type="topicPrfoile"
                      navigation={this.props.navigation}
                      postId={this.props.PostDetails && this.props.PostDetails.node.id}
                      listScroll={this.listScroll}
                      ProfileHeight={this.state.ProfileHeight}
                      commentDelay={this.state.commentDelay}
                      scrollY={this.state.scrollY}
                      ActiveTab={this.state.routes[this.state.index].title}
                    />
                  </Suspense>
                </View> : null}
            </View>
          </ScrollView>
          {Dimensions.get("window").width <= 750 && (
            <Suspense fallback={null}>

              <BottomScreen navigation={NavigationService} />
            </Suspense>
          )}
        </View>
        {/* {this.props.show == true || Dimensions.get("window").width >= 1200 ?
          <View style={{
            width: Platform.OS == "web" &&
              Dimensions.get("window").width >= 750 && Dimensions.get("window").width <= 1200
              ? '100%' : Platform.OS == "web" && Dimensions.get("window").width >= 1200 ? '50%'
                : "100%", marginLeft: Dimensions.get("window").width >= 1200 ? '50%' : 0, zIndex: 0, position: 'absolute', bottom: 10,
          }}>
            <CreateCommentCard
              onClose={this.onClose}
              parent_content_id={
                this.props.PostId &&
                this.props.PostId.replace("Trending", "")
                  .replace("New", "")
                  .replace("Discussion", "")
                  .replace("Search", "")
              }
              clickList={
                this.props.PostDetails
                  ? this.props.PostDetails
                  : null
              }
              initial="main"
              topComment={this.props.PostDetails && this.props.PostDetails.node}
              navigation={this.props.navigation}
              title={this.props.Title}
              type={this.props.type}
            />
          </View> : null
        } */}
        {this.state.opentool ? <Overlay
          animationType="fadeInUp"
          visible={this.state.opentool}
          onClose={this.onClose}
          closeOnTouchOutside
          children={
            <View style={{ width: Dimensions.get('window').width, height: Dimensions.get('window').height - 650, backgroundColor: "#fff", borderRadius: 8, position: "absolute", top: 250, bottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>

              <View style={{ marginTop: 20 }}>


                {(this.props.isAdmin == true || this.state.MemberType == "SUPER_ADMIN") &&
                  <TouchableOpacity
                    style={{ marginBottom: 10 }}
                    onPress={async () => {
                      console.log('edit topic');
                      await this.props.editTopic({
                        description: this.props.topicsDetails.getIn(["data", "node"]).get("description"),
                        topicName: this.props.topicsDetails.getIn(["data", "node"])
                          .get("name")
                          .toLowerCase(),
                        profile_pic: this.props.topicsDetails.getIn(["data", "node"]).get("profile_pic"),
                        topic_id: this.props.topicsDetails.getIn(["data", "node"]).get("id"),
                        rtopicName: this.props.topicsDetails.getIn(["data", "node"]).get("parents")
                          ? [... this.props.topicsDetails.getIn(["data", "node"]).get("parents")][
                          [... this.props.topicsDetails.getIn(["data", "node"]).get("parents")].length - 1
                          ]
                          : ""
                      });
                      this.props.navigation.navigate("edittopic");
                      this.onClose()
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontFamily: ConstantFontFamily.defaultFont
                      }}
                    >
                      Edit
                    </Text>
                  </TouchableOpacity>
                }

                <TouchableOpacity style={{ marginBottom: 10 }}>

                  <Text
                    style={{
                      textAlign: "center",
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont
                    }}
                  >
                    Report
                  </Text>

                </TouchableOpacity>
                {(this.props.isAdmin == true || this.state.MemberType == "SUPER_ADMIN") &&
                  <TouchableOpacity style={{ marginBottom: 10 }}
                    onSelect={() => { this.showAlert() }}
                  >

                    <Text
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontFamily: ConstantFontFamily.defaultFont
                      }}
                    >
                      Delete
                    </Text>

                  </TouchableOpacity>
                }

              </View>
            </View>
          }
          childrenWrapperStyle={{
            padding: 0,
            margin: 0,
            borderRadius: 6
          }}
        /> : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.get("loginStatus"),
  topicsDetails: state.TrendingTopicsProfileReducer.get(
    "getTrendingTopicsProfileDetails"
  ),
  getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
    ? state.LoginUserDetailsReducer.userFollowTopicsList
    : [],
  getTabView: state.AdminReducer.get("tabType"),
  getsearchBarStatus: state.AdminReducer.get("searchBarOpenStatus"),
  PostCommentDetails: state.PostCommentDetailsReducer.get("PostCommentDetails"),
  TrendingTopicHomeFeedList: state.HomeFeedReducer.get("TrendingTopicHomeFeedList"),
  PostId: state.PostCommentDetailsReducer.get("PostId"),
  PostDetails: state.PostDetailsReducer.get("PostDetails"),
  show: state.showDiscussionReducer.show,
  screenName: state.screenNameReducer.screen,
  isAdmin: state.AdminReducer.get("isAdmin"),

});

const mapDispatchToProps = (dispatch) => ({
  setHASSCROLLEDACTION: (payload) => dispatch(setHASSCROLLEDACTION(payload)),
  topicId: (payload) => dispatch(getTrendingTopicsProfileDetails(payload)),
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  updateTopicStar: (payload) =>
    dispatch({ type: "TOPIC_ICON_UPLOAD", payload }),
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
  setPostCommentReset: (payload) =>
    dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),
  showDiscussion: (payload) => dispatch(showDiscussion(payload)),
  feedFluctuation: payload => dispatch(feedFluctuation(payload)),
  screen: (payload) => dispatch(screen(payload)),
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  editTopic: payload => dispatch(editTopic(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TopicScreen
);

const styles = StyleSheet.create({
  header: {
    position: Platform.OS == "web" ? "fixed" : null,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  container: {
    flex: 1,
    backgroundColor: Colors.whiteBackground,
    height: "100%",
  },
});
