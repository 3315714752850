// import { logInAsync } from "expo-google-app-auth";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import { graphql } from "react-apollo";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  // AsyncStorage,
  Dimensions,
  Image,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { setAdminStatus } from "../actionCreator/AdminAction";
import { getHomefeedList } from "../actionCreator/HomeFeedAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setRESETPASSWORDMODALACTION } from "../actionCreator/ResetPasswordModalAction";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
import { getTrendingExternalFeeds } from "../actionCreator/TrendingExternalFeedsAction";
import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
import { getTrendingUsers } from "../actionCreator/TrendingUsersAction";
import {
  saveUserLoginDaitails,
  setLoginStatus
} from "../actionCreator/UserAction";
import { setUserApproachAction } from "../actionCreator/UserApproachAction";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import applloClient from "../client";
import "../components/Firebase";
import LoaderComponent from "../components/LoaderComponent";
import AppHelper from "../constants/AppHelper";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
import {
  HomeFeedMutation
} from "../graphqlSchema/graphqlMutation/FeedMutation";
import { UserLoginMutation, UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation } from "../graphqlSchema/graphqlMutation/UserMutation";
import { setLocalStorage } from "../library/Helper";
import NavigationService from "../library/NavigationService";
import ButtonStyle from "../constants/ButtonStyle";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";




const apiUrl = getEnvVars();

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserEmail: "",
      UserPassword: "",
      loading: false,
      rememberMe: false,
      rememberMeData: null,
      access_key: "",
      buttonName: "Login",
      errorMsg: "",
      pageInfo: null,
      page: 1

    };
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    let __self = this;
    let res = JSON.parse(await AsyncStorage.getItem("rememberMe"));
    if (res) {
      __self.setState({
        rememberMe: true,
        UserEmail: res.UserEmail,
        UserPassword: res.UserPassword,
        buttonName: "Login"
      });
    } else {
      __self.setState({
        rememberMe: false,
        buttonName: "Login"
      });
    }
  }

  // componentDidUpdate(){
  //   this.setState({
  //     // rememberMe: false,
  //     buttonName: "Login"
  //   });
  // }
  componentWillUnmount() {
    this._isMounted = false;
  }

  userResetModal = async () => {
    this.props.setLoginModalStatus(false);
    this.props.setResetPasswordModalStatus(true);
  };

  onModalClose = async () => {

    this.setState({ buttonName: "Logged In!" });
    await this.props.setLoginButtonText("Logged In!");
    this.props.setLoginModalStatus(false);
    setTimeout(() => {
      this.props.setLoginModalStatus(false);


      this.props.setLoginButtonText("Login");

    }, 500);
  };

  onClose = async () => {
    this.props.setLoginModalStatus(false);
    this.props.setLoginButtonText("Login");
    this.setState({
      buttonName: "Login"
    });
  };

  getTrendingFeedList = async () => {
    applloClient
      .query({
        query: HomeFeedMutation,
        variables: {
          first: 24,
          after: null,
          sort: "TRENDING",
          type: 'Post'
        },
        fetchPolicy: "no-cache",
      })
      .then(response => {
        this.props.setTrendingHomeFeed(response.data.node_list.edges);
      })
      .catch(e => {
        console.log(e.message);
        this.props.setTrendingHomeFeed([]);
      });
  };

  getNewFeedList = async () => {
    const { page, pageInfo } = this.state;


    applloClient
      .query({
        query: HomeFeedMutation,
        variables: {
          first: 12,
          after: pageInfo ? pageInfo.endCursor : null,
          sort: "NEW",
          type: 'Post'
        },
        fetchPolicy: "no-cache",
      })
      .then(response => {
        this.props.setNewHomeFeed(response.data.node_list.edges);
        this.props.setUpdatedPostId(response.data.node_list.edges[0].node.id)

        this.props.setPostCommentReset({
          payload: [],
          postId: '',
          title: '',
          loading: true
        });
        this.props.setPostCommentDetails({
          id: "Post:" + response.data.node_list.edges[0].node.id.replace("Post:", ""),
          title: response.data.node_list.edges[0].node.title,
          loading: false

        });



      })
      .catch(e => {
        console.log(e);
        this.props.setNewHomeFeed([]);
      });
  };

  getDiscussedFeedList = () => {
    applloClient
      .query({
        query: HomeFeedMutation,
        variables: {
          first: 24,
          after: null,
          sort: "DISCUSSING",
          type: 'Post'
        },
        fetchPolicy: "no-cache",
      })
      .then(response => {
        this.props.setDiscussionHomeFeed(response.data.node_list.edges);
      })
      .catch(e => {
        console.log(e);
        this.props.setDiscussionHomeFeed([]);
      });
  };

  userLogin = async () => {

    let __self = this;
    this.setState({

      buttonName: "Logging in..."
    });
    await this.props.setLoginButtonText("Logging in...");

    const { UserEmail, UserPassword } = this.state;
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, UserEmail, UserPassword)
      .then(async res => {
        console.log(res, 'res');

        return res.user.getIdToken(true).then(function (idToken) {
          console.log(jwt_decode(idToken), 'gkkgk');
          setLocalStorage('uid', jwt_decode(idToken).user_id)
          setLocalStorage(
            "admin",
            jwt_decode(idToken)?.claims?.admin ? true : false
          );
          __self.props.changeAdminStatus(
            jwt_decode(idToken)?.claims?.admin ? jwt_decode(idToken)?.claims?.admin : false
          );


          if (Platform.OS == "web") {
            __self.setUserNameInExtension = __self.setLoginTokenInExtension(
              idToken
            );
          }

          AsyncStorage.setItem("userIdTokenFirebase", idToken);


          return setLocalStorage("userIdTokenFirebase", idToken);
        });

      })
      .then(async res => {
        if (res) {
          console.log(res,'ress');
          await applloClient
            .query({
              query: UserLoginMutation,
              variables: {
                id: "Account:" + "CurrentUser"
              },
              fetchPolicy: "no-cache"
            }).then(async res => {
              console.log(res,'loginmutation');
              let userfollow = await applloClient
                .query({
                  query: UserFollowingMutation,
                  variables: {
                    id: "User:CurrentUser",
                    first: 20
                  },
                  fetchPolicy: "no-cache"
                }).then(e => {
                  
                  return e.data.node.users_following.edges
                })

              let topicfollow = await applloClient
                .query({
                  query: TopicFollowingMutation,
                  variables: {
                    id: "User:CurrentUser",
                    first: 20
                  },
                  fetchPolicy: "no-cache"
                }).then(e => {
                  return e.data.node.topics_following.edges
                })
              let clikfollow = await applloClient
                .query({
                  query: ClikFollowingMutation,
                  variables: {
                    id: "User:CurrentUser",
                    first: 20
                  },
                  fetchPolicy: "no-cache"
                }).then(e => {
                  return e.data.node.cliks_following.edges
                })
              let externalfollow = await applloClient
                .query({
                  query: ExternalFeedFollowingMutation,
                  variables: {
                    id: "User:CurrentUser",
                    first: 20
                  },
                  fetchPolicy: "no-cache"
                }).then(e => {
                  return e.data.node.external_feeds_following.edges
                })
              let userData = {
                data: res.data.node,
                externalfollow: externalfollow,
                clikfollow: clikfollow,
                topicfollow: topicfollow,
                userfollow: userfollow

              }
              await this.props.saveLoginUser(userData);
              AsyncStorage.setItem("skipCredentials", "true");
              this.setState({
                buttonName: "Login"
              });
              this.props.setLoginButtonText("Login");
              await this.props.setGoogleLogin(false);
              await this.props.changeLoginStatus(1);

              await this.props.setAnonymousUser({ "value": false, "token": "" }),
                await __self.getTrendingFeedList();
              await __self.getNewFeedList();
              await __self.getDiscussedFeedList();
              await __self.props.getHomefeed({
                currentPage: AppHelper.PAGE_LIMIT
              });
              await __self.props.getTrendingUsers({
                currentPage: AppHelper.PAGE_LIMIT
              });
              await __self.props.getTrendingClicks({
                currentPage: AppHelper.PAGE_LIMIT
              });
              await __self.props.getTrendingTopics({
                currentPage: AppHelper.PAGE_LIMIT
              });
              await __self.props.getTrendingExternalFeeds({
                currentPage: AppHelper.PAGE_LIMIT
              });
              await AsyncStorage.setItem(
                "userLoginId",
                res.data.node.user.id
              );
              await AsyncStorage.setItem(
                "MyUserUserId",
                res.data.node.user.id
              );
              await AsyncStorage.setItem(
                "userIdTokenWeclikd",
                __self.state.access_key
              );
              await AsyncStorage.setItem(
                "UserId",
                res.data.node.user.id
              );
              await AsyncStorage.setItem(
                "UserName",
                res.data.node.user.username
              );

              await AsyncStorage.setItem(
                "profile",
                res.data.node.user.profile_pic
              );

              if (Platform.OS == "web") {
                await __self.setUserNameInExtension(
                  res.data.node.user.username
                );
              }
              if (Platform.OS != "web") {
                NavigationService.navigate("home")
              }

              await this.props.leftPanelModalFunc(false)

              await __self.onModalClose()




            })


        }
      })

      .catch(error => {
        __self.setState({
          loading: false
        });
        console.log(error);
        if (error.code == "auth/wrong-password") {
          this.setState({
            errorMsg: "Invalid email or password",
            buttonName: "Login"
          });
          this.props.setLoginButtonText("Login");
        } else {

          this.setState({ errorMsg: "User not found", buttonName: "Login" });
          this.props.setLoginButtonText("Login");
        }
        return false;
      });






  };

  loginWithGoogle = async () => {
    if (Platform.OS == "web") {
      await this.loginWithGoogleWeb();

    } else {
      await this.props.leftPanelModalFunc(false)
      await this.loginWithGoogleMobile();
    }
  };

  loginWithGoogleWeb = async () => {

    this.setState({
      buttonName: "Logging in...",
      UserEmail: "",
      UserPassword: ""
    });
    await this.props.setLoginButtonText("Logging in...");
    await this.props.setGoogleLogin(true);
    await this.props.setUserApproachAction({ type: "login" });
    await this.props.setAnonymousUser({ "value": false, "token": "" });
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const auth = getAuth();
    await signInWithPopup(auth, provider);

    this.getNewFeedList()

    await applloClient
      .query({
        query: UserLoginMutation,
        variables: {
          id: "Account:" + "CurrentUser"
        },
        fetchPolicy: "no-cache"
      }).then(async res => {

        let userfollow = await applloClient
          .query({
            query: UserFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.users_following.edges
          })

        let topicfollow = await applloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.topics_following.edges
          })
        let clikfollow = await applloClient
          .query({
            query: ClikFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.cliks_following.edges
          })
        let externalfollow = await applloClient
          .query({
            query: ExternalFeedFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.external_feeds_following.edges
          })
        let userData = {
          data: res.data.node,
          externalfollow: externalfollow,
          clikfollow: clikfollow,
          topicfollow: topicfollow,
          userfollow: userfollow

        }
        await this.props.saveLoginUser(userData);
        setTimeout(() => {
          this.setState({
            buttonName: "Login"
          });
          this.props.setLoginButtonText("Login");
        }, 2000);

        this.props.userId({
          username: res.data.node.user.username,
          type: "feed",
        });
        await AsyncStorage.setItem(
          "userLoginId",
          res.data.node.user.id
        );
        await AsyncStorage.setItem(
          "MyUserUserId",
          res.data.node.user.id
        );
        await AsyncStorage.setItem(
          "userIdTokenWeclikd",
          this.state.access_key
        );
        await AsyncStorage.setItem(
          "UserId",
          res.data.node.user.id
        );
        await this.props.changeLoginStatus(1);
        this.props.onClose()
      })

  };

  loginWithGoogleMobile = async () => {
    try {
      const result = await logInAsync({
        androidClientId: apiUrl.ANDROIDCLIENT_ID,
        iosClientId: apiUrl.IOSCLIENT_ID,
        iosStandaloneAppClientId: apiUrl.IOSCLIENT_ID,
        androidStandaloneAppClientId: apiUrl.ANDROIDCLIENT_ID,
        scopes: ["profile", "email"]
      });
      if (result.type === "success") {
        await this.props.setAnonymousUser({ "value": false, "token": "" }),
          await this.onSignIn(result);
      } else {
        return { cancelled: true };
      }
    } catch (e) {
      return { error: true };
    }
  };

  onSignIn = async googleUser => {
    let __self = this;
    __self.onClose();
    let unsubscribe = await firebase
      .auth()
      .onAuthStateChanged(async function (firebaseUser) {
        var credential = firebase.auth.GoogleAuthProvider.credential(
          googleUser.idToken,
          googleUser.accessToken
        );
        await firebase
          .auth()
          .signInWithCredential(credential)
          .then(async function (result) {

            if ((await result.additionalUserInfo.isNewUser) == true) {
              try {
                return await applloClient
                  .query({
                    query: UserLoginMutation,
                    variables: {
                      id: "Account:" + "CurrentUser"
                    },
                    fetchPolicy: "no-cache"
                  })
              } catch (e) {
                __self.props.setUsernameModalStatus(true);
              }
            } else {
              await firebase
                .auth()
                .currentUser.getIdToken(true)
                .then(async function (idToken) {
                  await AsyncStorage.setItem("userIdTokenFirebase", idToken);
                  await setLocalStorage(
                    "admin",
                    jwt_decode(idToken)?.claims?.admin ? "true" : "false"
                  );

                  await __self.props.changeAdminStatus(
                    jwt_decode(idToken)?.claims?.admin
                      ? jwt_decode(idToken)?.claims?.admin
                      : false
                  );
                })
                .then(async () => {
                  await applloClient
                    .query({
                      query: UserLoginMutation,
                      variables: {
                        id: "Account:" + "CurrentUser"
                      },
                      fetchPolicy: "no-cache"
                    }).then(async res => {

                      let userfollow = await applloClient
                        .query({
                          query: UserFollowingMutation,
                          variables: {
                            id: "User:CurrentUser",
                            first: 20
                          },
                          fetchPolicy: "no-cache"
                        }).then(e => {
                          return e.data.node.users_following.edges
                        })

                      let topicfollow = await applloClient
                        .query({
                          query: TopicFollowingMutation,
                          variables: {
                            id: "User:CurrentUser",
                            first: 20
                          },
                          fetchPolicy: "no-cache"
                        }).then(e => {
                          return e.data.node.topics_following.edges
                        })
                      let clikfollow = await applloClient
                        .query({
                          query: ClikFollowingMutation,
                          variables: {
                            id: "User:CurrentUser",
                            first: 20
                          },
                          fetchPolicy: "no-cache"
                        }).then(e => {
                          return e.data.node.cliks_following.edges
                        })
                      let externalfollow = await applloClient
                        .query({
                          query: ExternalFeedFollowingMutation,
                          variables: {
                            id: "User:CurrentUser",
                            first: 20
                          },
                          fetchPolicy: "no-cache"
                        }).then(e => {
                          return e.data.node.external_feeds_following.edges
                        })
                      let userData = {
                        data: res.data.node,
                        externalfollow: externalfollow,
                        clikfollow: clikfollow,
                        topicfollow: topicfollow,
                        userfollow: userfollow

                      }
                      await this.props.saveLoginUser(userData);
                      await this.props.changeLoginStatus(1);


                      await AsyncStorage.setItem(
                        "userLoginId",
                        res.data.node.user.id
                      );
                      await AsyncStorage.setItem(
                        "MyUserUserId",
                        res.data.node.user.id
                      );
                      await AsyncStorage.setItem(
                        "userIdTokenWeclikd",
                        __self.state.access_key
                      );
                      await AsyncStorage.setItem(
                        "UserId",
                        res.data.node.user.id
                      );

                      __self.onClose();
                      NavigationService.navigate("home");


                    }).catch(error => {
                      console.log(error.message, 'error==========');
                      alert("Invalid email or password");
                      this.setState({ errorMsg: "Invalid email or password" });
                      return false;
                    });
                })

            }
          });
      });
    unsubscribe();
  };

  enterPressed = e => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      this.userLogin();
    }
  };

  handleSignButton = async () => {
    await this.props.setLoginModalStatus(false);
    await this.props.setInviteUserDetail({
      clikName: "",
      inviteKey: "",
      userName: ""
    });
    await this.props.setUsernameModalStatus(true);

  };

  //--------------------------- Set Firebase Token in Extension -----------------------------
  setLoginTokenInExtension = idToken => UserName => {
    try {
      window.parent.postMessage(
        { type: "wecklid_login", userIdTokenFirebase: idToken, UserName },
        "*"
      );
    } catch (e) {
      console.log("extension login Error ", e);
    }
  };
  //------------------------------------------------------------------------------------------

  anonymousUserLogin = async () => {
    let __self = this;
    this.setState({
      buttonName: "Logging in..."
    });
    await this.props.setLoginButtonText("Logging in...");
    await setLocalStorage("userIdTokenFirebase", this.props.getAnonymousToken);
    try {

      await applloClient
        .query({
          query: UserLoginMutation,
          variables: {
            id: "Account:" + "CurrentUser"
          },
          fetchPolicy: "no-cache"
        }).then(async res => {

          let userfollow = await applloClient
            .query({
              query: UserFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.users_following.edges
            })

          let topicfollow = await applloClient
            .query({
              query: TopicFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.topics_following.edges
            })
          let clikfollow = await applloClient
            .query({
              query: ClikFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.cliks_following.edges
            })
          let externalfollow = await applloClient
            .query({
              query: ExternalFeedFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 20
              },
              fetchPolicy: "no-cache"
            }).then(e => {
              return e.data.node.external_feeds_following.edges
            })
          let userData = {
            data: res.data.node,
            externalfollow: externalfollow,
            clikfollow: clikfollow,
            topicfollow: topicfollow,
            userfollow: userfollow

          }
          await this.props.saveLoginUser(userData);
          await this.props.changeLoginStatus(1);
          this.props.setLoginButtonText("Login");
          await this.props.setAnonymousUser({ "value": false, "token": "" }),
            await __self.getTrendingFeedList();
          await __self.getNewFeedList();
          await __self.getDiscussedFeedList();
          await __self.props.getHomefeed({
            currentPage: AppHelper.PAGE_LIMIT
          });
          await __self.props.getTrendingUsers({
            currentPage: AppHelper.PAGE_LIMIT
          });
          await __self.props.getTrendingClicks({
            currentPage: AppHelper.PAGE_LIMIT
          });
          await __self.props.getTrendingTopics({
            currentPage: AppHelper.PAGE_LIMIT
          });
          await __self.props.getTrendingExternalFeeds({
            currentPage: AppHelper.PAGE_LIMIT
          });
          await AsyncStorage.setItem(
            "userLoginId",
            res.data.node.user.id
          );
          await AsyncStorage.setItem(
            "MyUserUserId",
            res.data.node.user.id
          );
          await AsyncStorage.setItem(
            "userIdTokenWeclikd",
            __self.state.access_key
          );
          await AsyncStorage.setItem(
            "UserId",
            res.data.node.user.id
          );
          await AsyncStorage.setItem(
            "UserName",
            res.data.node.user.username
          );
          if (Platform.OS == "web") {
            await __self.setUserNameInExtension(
              res.data.node.user.username
            );
          }
          await this.props.leftPanelModalFunc(false)
          await __self.onModalClose();
        })

    } catch (error) {
      __self.setState({
        loading: false
      });
      console.log(error);
      this.props.setAnonymousUser({ "value": false, "token": "" });
      if (error.code == "auth/wrong-password") {
        this.setState({
          errorMsg: "Invalid email or password",
          buttonName: "Login"
        });
        this.props.setLoginButtonText("Login");
      } else {
        this.setState({ errorMsg: "User not found", buttonName: "Login" });
        this.props.setLoginButtonText("Login");
      }
      return false;
    }
  }
  render() {
    return (
      <View
        style={{
          backgroundColor: "#f4f4f4",
          borderColor: "#c5c5c5",
          borderRadius: 6,
          width: Dimensions.get('window').width >= 750 ? 450 : '100%',
          height: Dimensions.get('window').width >= 750 ? 480 : 500,

        }}
      >
        <View >
          <Hoverable>
            {isHovered => (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flex: 1,
                  position: "absolute",
                  zIndex: 999999,
                  left: 0,
                  top: 0
                }}
                onPress={this.onClose}
              >
                <Icon
                  color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  reverse
                  name="close"
                  type="antdesign"
                  size={16}
                />
              </TouchableOpacity>
            )}
          </Hoverable>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "#000",
              alignItems: "center",
              height: 50,
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
              width: "100%"
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Image
                source={require("../assets/image/logolastOne.png")}
                style={{
                  height: 35,
                  width: 35,
                  marginRight: 5,
                  borderRadius: 8
                }}
                resizeMode={"contain"}
              />
              <Text
                style={[ButtonStyle.profileTitleStyle, {
                  fontSize: 20,
                  textAlign: "center",
                  color: "white",
                  fontWeight: 'bold'
                }]}
              >
                weclikd
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            alignItems: "center",
            flex: 1,
            paddingVertical: 20,
            backgroundColor: "#fff",
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            borderColor: "#c5c5c5",
            height: Platform.OS != 'web' ? 600 : 450,
            width: '100%'
          }}
        >

          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              marginTop: 10
            }}
          >

          </View>
          <View
            style={{ width: '90%' }}
          >
            <View
              style={{
                ...Platform.select({
                  web: {
                    marginTop: 15
                  }
                })
              }}
            >

              <Button
                buttonStyle={[
                  styles.GbuttonStyle,
                  { borderColor: "#c5c5c5", marginTop: 0 }
                ]}
                title="Login with Google"
                titleStyle={styles.Gbuttontextstyle}
                onPress={this.loginWithGoogle}
                icon={
                  <Image
                    source={require("../assets/image/gLogin.png")}
                    style={styles.Gicon}
                  />
                }
              />

              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 50,
                  marginVertical: 10
                }}
              >
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: "#e1e1e1",
                    width: "43%",
                    justifyContent: "flex-start",
                    height: 1,
                    borderRadius: 6,
                    backgroundColor: "#e1e1e1"
                  }}
                ></View>
                <Text
                  style={{
                    fontSize: 16,
                    color: "#49525D",
                    textAlign: "center",
                    marginHorizontal: "4%",
                    marginVertical: "10%",
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  or
                </Text>
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: "#e1e1e1",
                    width: "43%",
                    justifyContent: "flex-end",
                    height: 1,
                    borderRadius: 6,
                    backgroundColor: "#e1e1e1"
                  }}
                ></View>
              </View>

              <TextInput
                value={this.state.UserEmail}
                placeholder="Email"
                placeholderTextColor="#A9A9A9"
                onChangeText={UserEmail =>
                  this.setState({ UserEmail, errorMsg: "" })
                }
                underlineColorAndroid="transparent"
                style={[
                  styles.TextInputStyleClass, ButtonStyle.textAreaShadowStyle,
                  { borderColor: "#d7d7d7" }
                ]}

                testID="userEmail"
              />
              <TextInput
                value={this.state.UserPassword}
                placeholder="Password"
                placeholderTextColor="#A9A9A9"
                onChangeText={UserPassword =>
                  this.setState({ UserPassword, errorMsg: "" })
                }
                onKeyPress={this.enterPressed}
                underlineColorAndroid="transparent"
                style={[
                  styles.TextInputStyleClass, ButtonStyle.textAreaShadowStyle,
                  { borderColor: "#d7d7d7" }
                ]}
                secureTextEntry={true}
                testID="UserPassword"
              />
              <View
                style={{
                  flexDirection: Dimensions.get('window').width > 1200 ? "row" : "column",
                  justifyContent: "space-between"
                }}
              >
                <TouchableOpacity onPress={this.userResetModal}>
                  <Text style={[styles.forgotpasswordStyle, { textAlign: Dimensions.get('window').width > 1200 ? 'right' : 'left', paddingHorizontal: 2 }]}>
                    Forgot Password?
                  </Text>
                </TouchableOpacity>

                <Text
                  style={{
                    fontSize: 12,
                    color: "#49525D",
                    textAlign: "left",
                    fontFamily: ConstantFontFamily.defaultFont,
                    marginTop: 10,
                    paddingHorizontal: 2
                  }}
                >
                  Don't have an account? &nbsp;
                  <Text
                    style={{
                      textDecorationLine: "underline",
                      color: "#49525D",
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                    onPress={this.handleSignButton}
                  >
                    Sign Up
                  </Text>
                </Text>
              </View>
              {this.state.loading == true && <LoaderComponent />}

              <Text
                style={{
                  color: "#E26A64",
                  textAlign: "left",
                  fontSize: 13,
                  textAlign: "center",
                  marginTop: 10,
                  height: 10,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                {this.state.errorMsg}
              </Text>
              <View
                style={{
                  marginVertical: 10,
                  alignContent: 'center'
                }}
              >
                {this.props.getGoogleLogin == true ? (
                  <Button
                    title={this.props.getLoginButtonText}
                    testID="UserLogin1"
                    titleStyle={[
                      ButtonStyle.titleStyle,
                      {
                        color:
                          this.props.getLoginButtonText == "Logged In!" ||
                            this.props.getLoginButtonText == "Logging in..."
                            ? "#fff"
                            : "#000"
                      }
                    ]}
                    buttonStyle={[
                      ButtonStyle.backgroundStyle,
                      {
                        backgroundColor:
                          this.props.getLoginButtonText == "Logged In!" ||
                            this.props.getLoginButtonText == "Logging in..."
                            ? "#009B1A"
                            : "#fff"
                      }
                    ]}
                    containerStyle={ButtonStyle.containerStyle}
                    onPress={this.userLogin}
                    id="btn1"

                  />
                ) : (
                  <Button
                    title={this.props.getLoginButtonText}
                    testID="UserLogin"
                    titleStyle={
                      this.props.getLoginButtonText == "Logged In!" ||
                        this.props.getLoginButtonText == "Logging in..."
                        ? ButtonStyle.wtitleStyle
                        : ButtonStyle.titleStyle
                    }
                    buttonStyle={
                      this.props.getLoginButtonText == "Logged In!" ||
                        this.props.getLoginButtonText == "Logging in..."
                        ? ButtonStyle.gbackgroundStyle
                        : ButtonStyle.backgroundStyle
                    }
                    containerStyle={ButtonStyle.containerStyle}
                    disabled={
                      this.state.UserEmail == "" ||
                        this.state.UserPassword == ""
                        ? true
                        : false
                    }
                    onPress={this.userLogin}
                    id="btn2"
                  />
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({




  getLoginButtonText: state.AdminReducer.get("loginButtonText"),
  getGoogleLogin: state.AdminReducer.get("googleLogin"),

  getAnonymousToken: state.AdminReducer.get("anonymousToken"),

});

const mapDispatchToProps = dispatch => ({
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload)),
  setResetPasswordModalStatus: payload =>
    dispatch(setRESETPASSWORDMODALACTION(payload)),
  setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  setUserApproachAction: payload => dispatch(setUserApproachAction(payload)),


  changeAdminStatus: payload => dispatch(setAdminStatus(payload)),

  getHomefeed: payload => dispatch(getHomefeedList(payload)),
  getTrendingUsers: payload => dispatch(getTrendingUsers(payload)),
  getTrendingTopics: payload => dispatch(getTrendingTopics(payload)),
  getTrendingClicks: payload => dispatch(getTrendingClicks(payload)),
  getTrendingExternalFeeds: payload =>
    dispatch(getTrendingExternalFeeds(payload)),
  setTrendingHomeFeed: payload =>
    dispatch({ type: "SET_TRENDING_HOME_FEED", payload }),
  setNewHomeFeed: payload => dispatch({ type: "SET_NEW_HOME_FEED", payload }),
  setDiscussionHomeFeed: payload =>
    dispatch({ type: "SET_DISCUSSION_HOME_FEED", payload }),
  setLoginButtonText: payload =>
    dispatch({ type: "SET_LOGIN_BUTTON_TEXT", payload }),
  setInviteUserDetail: payload =>
    dispatch({ type: "SET_INVITE_USER_DETAIL", payload }),
  setGoogleLogin: payload => dispatch({ type: "SET_GOOGLE_LOGIN", payload }),
  setAnonymousUser: payload =>
    dispatch({ type: "ANONYMOUS_USER", payload }),
  leftPanelModalFunc: payload => dispatch({ type: 'LEFT_PANEL_OPEN', payload }),
  setUpdatedPostId: payload => dispatch({ type: "SET_UPDATED_POSTID", payload }),
  setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),

  setPostCommentReset: payload =>
    dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),



});

const LoginModalContainerWrapper = graphql(UserLoginMutation, {
  name: "Login",
  options: {
    variables: {
      id: "Account:" + "CurrentUser"
    },
    fetchPolicy: "no-cache"
  }
})(LoginModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginModalContainerWrapper);

export const styles = {
  TextInputStyleClass: {
    marginBottom: 10,
    height: 45,
    paddingLeft: 10,
    borderWidth: 0,
    color: "#000",
    backgroundColor: "#fff",
    fontFamily: ConstantFontFamily.defaultFont,
    fontWeight: "bold"
  },

  forgotpasswordStyle: {
    fontSize: 12,
    color: "#49525D",
    textAlign: "right",
    fontFamily: ConstantFontFamily.defaultFont,
    marginTop: 10
  },


  buttonStyle: {
    marginVertical: 25,
    backgroundColor: "#000",
    borderRadius: 6,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    width: "20%",
    alignSelf: "center"
  },
  GbuttonStyle: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    height: 40,
    alignItems: "center",
    padding: 5,
    borderWidth: 2,
    borderColor: "#000",
    marginTop: 10
  },

  Gbuttontextstyle: {
    fontSize: 16,
    color: "#000",
    fontFamily: ConstantFontFamily.defaultFont,
    marginLeft: 5
  },
  Gicon: {
    height: 25,
    width: 25
  }
};
