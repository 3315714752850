import React, { lazy, Component, Suspense } from "react";
import {
  Animated,
  Dimensions,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import ButtonStyle from "../constants/ButtonStyle";
import { TabBar, TabView } from "react-native-tab-view";
import ShadowSkeleton from "../components/ShadowSkeleton";
import NavigationService from "../library/NavigationService";
import Colors from "../constants/Colors";
import { screen } from '../reducers/action/Completed';


const StripePayment = lazy(() => import("../components/StripePayment"))

const AnalyticsGraph = lazy(() => import("../components/AnalyticsGraph"))

const HeaderRight = lazy(() => import("../components/HeaderRight"))

const BottomScreenAnalytics = lazy(() => import("../components/BottomScreenAnalytics"))



const initRoutes = [
  {
    key: "first",
    title: "Graphs",
    icon: "bar-chart",
    type: "font-awesome",
  },
  {
    key: "second",
    title: "Top Comments",
    icon: "comment-o",
    type: "font-awesome",
  },
  { key: "third", title: "Summary", icon: "bars", type: "font-awesome" },

  { key: "fourth", title: "Stripe", icon: "dollar", type: "font-awesome" },
];

class AnalyticsScreen extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {};
    this.state = {
      index: 0,
      routes: [...initRoutes],
      key: {
        label: "Summary",
        value: "Summary",
      },
    };
  }

  componentDidMount = () => {
    this.props.searchOpenBarStatus(false);
    this.props.screen('analytic')
  };


  _renderLazyPlaceholder = ({ route }) => <ShadowSkeleton />;

  _handleIndexChange = (index) => {
    this.setState({ index });
  };

  _renderScene = ({ route }) => {
    switch (route.key) {
      case "fourth":
        return <Suspense fallback={<ShadowSkeleton />}>
          <StripePayment />
        </Suspense>
          ;
      case "third":
        return (
          <View
            style={[
              ButtonStyle.normalFeedStyleForDashboard,
              {
                marginTop: 10,
                paddingVertical: 10,
                marginLeft: Dimensions.get("window").width <= 1200 ? 5 : "2%",
                backgroundColor: "#fff",
                width: Dimensions.get("window").width <= 750 ? "99%" : "96%",
              },
            ]}
          >
            <View style={[{ margin: 5, paddingHorizontal: 20 }]}>
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont,
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingHorizontal: 20,
                }}
              >
                Post: Section 1.10.32 of "de Finibus Bonorum et Malorum",
                written by Cicero in 45 BC
              </Text>
            </View>
            <View style={{ margin: 10 }}>
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                }}
              >
                Comment: Lorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum
              </Text>
            </View>

            <View
              style={{
                marginHorizontal: 10,
                flexDirection: "row",
                width: "auto",
              }}
            >
              <Text
                style={{
                  width: "70%",
                  fontWeight: "bold",
                  color: "#009B1A",
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                }}
              >
                $215.08
              </Text>

              <View
                style={{
                  width: Dimensions.get("window").width >= 1200 && "30%",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontWeight: "bold",
                      paddingRight: 5,
                      marginTop: 4,
                    }}
                  >
                    100
                  </Text>
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      padding: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                      paddingRight: 10,
                    }}
                    name="heart"
                    type="font-awesome"
                    size={20}
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontWeight: "bold",
                      paddingRight: 5,
                      marginTop: 4,
                    }}
                  >
                    75
                  </Text>
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      padding: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "grey",
                      paddingRight: 10,
                    }}
                    name="heart"
                    type="font-awesome"
                    size={20}
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontWeight: "bold",
                      paddingRight: 5,
                      marginTop: 4,
                    }}
                  >
                    25
                  </Text>
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      padding: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff44f",
                      paddingRight: 10,
                    }}
                    name="heart"
                    type="font-awesome"
                    size={20}
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontWeight: "bold",
                      paddingRight: 5,
                      marginTop: 4,
                    }}
                  >
                    10
                  </Text>

                  <Icon
                    color={"#000"}
                    iconStyle={{
                      padding: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#4169e1",
                      paddingRight: 10,
                    }}
                    name="heart"
                    type="font-awesome"
                    size={20}
                  />
                </View>
              </View>
            </View>
          </View>
        );
      case "second":
        return <Suspense fallback={<ShadowSkeleton />}><AnalyticsGraph /></Suspense>;
      case "first":
        return (
          <>
            <View style={{ alignItems: "center" }}>
              <View
                style={[
                  ButtonStyle.normalFeedStyleForDashboard,
                  {
                    marginTop: 10,
                    paddingVertical: 10,
                    backgroundColor: "#fff",
                    width:
                      Dimensions.get("window").width <= 1200 ? "99%" : "96%",
                    paddingHorizontal:
                      Dimensions.get("window").width <= 750 ? 5 : "2%",
                    marginLeft: 0,
                  },
                ]}
              >
                <View
                  style={{ flex: 1, alignItems: "center", marginBottom: 20 }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                    }}
                  >
                    Current Month
                  </Text>
                </View>

                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                        }}
                      >
                        Estimated Earnings
                      </Text>
                      <Icon
                        color={"#000"}
                        name="info-circle"
                        type="font-awesome"
                        size={18}
                        onPress={() => {
                          this.props.setEarningModalStatus(true);
                        }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      $518
                    </Text>
                  </View>
                </View>

                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      Comments Written
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      20
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "red",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      100
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "grey",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      75
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff44f",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      25
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#4169e1",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      10
                    </Text>
                  </View>
                </View>
              </View>

              <View
                style={[
                  ButtonStyle.normalFeedStyleForDashboard,
                  {
                    marginTop: 10,
                    marginBottom: 30,
                    paddingVertical: 10,
                    backgroundColor: "#fff",
                    paddingHorizontal:
                      Dimensions.get("window").width <= 750 ? 5 : "2%",
                    width:
                      Dimensions.get("window").width <= 1200 ? "97%" : "96%",
                    marginLeft: 0,
                  },
                ]}
              >
                <View
                  style={{ flex: 1, alignItems: "center", marginBottom: 20 }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                    }}
                  >
                    Last Month
                  </Text>
                </View>

                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      Earnings
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      $518
                    </Text>
                  </View>
                </View>

                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      Comments Written
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      20
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "red",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      100
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "grey",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      75
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff44f",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      25
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#4169e1",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      10
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </>
        );
    }
  };

  renderTabViewForMobile = (route) => {
    switch (route.value) {
      case "Stripe":
        return <Suspense fallback={<ShadowSkeleton />}>
          <StripePayment /></Suspense>;
      case "Top Comments":
        return (
          <View
            style={[
              ButtonStyle.shadowStyle,
              {
                borderRadius: 0,
                backgroundColor: "#fff",
                width: "100%",
              },
            ]}
          >
            <View style={[{ margin: 5, paddingHorizontal: 20 }]}>
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont,
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingHorizontal: 20,
                }}
              >
                Post: Section 1.10.32 of "de Finibus Bonorum et Malorum",
                written by Cicero in 45 BC
              </Text>
            </View>
            <View style={{ margin: 10 }}>
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                }}
              >
                Comment: Lorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum
              </Text>
            </View>

            <View
              style={{
                paddingHorizontal: 10,
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text
                style={{
                  width: "70%",
                  fontWeight: "bold",
                  color: "#009B1A",
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                }}
              >
                $215.08
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontWeight: "bold",
                      paddingRight: 5,
                      marginTop: 4,
                    }}
                  >
                    100
                  </Text>
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      padding: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                      paddingRight: 10,
                    }}
                    name="heart"
                    type="font-awesome"
                    size={20}
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontWeight: "bold",
                      paddingRight: 5,
                      marginTop: 4,
                    }}
                  >
                    75
                  </Text>
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      padding: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "grey",
                      paddingRight: 10,
                    }}
                    name="heart"
                    type="font-awesome"
                    size={20}
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontWeight: "bold",
                      paddingRight: 5,
                      marginTop: 4,
                    }}
                  >
                    25
                  </Text>
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      padding: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff44f",
                      paddingRight: 10,
                    }}
                    name="heart"
                    type="font-awesome"
                    size={20}
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={{
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontWeight: "bold",
                      paddingRight: 5,
                      marginTop: 4,
                    }}
                  >
                    10
                  </Text>

                  <Icon
                    color={"#000"}
                    iconStyle={{
                      padding: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#4169e1",
                      paddingRight: 10,
                    }}
                    name="heart"
                    type="font-awesome"
                    size={20}
                  />
                </View>
              </View>
            </View>
          </View>
        );
      case "Graphs":
        return <Suspense fallback={<ShadowSkeleton />}><AnalyticsGraph /></Suspense>;
      case "Summary":
        return (
          <>
            <View style={{ alignItems: "center" }}>
              <View
                style={[
                  {
                    paddingVertical: 10,
                    backgroundColor: "#fff",
                    borderRadius: 0,
                    width:
                      Dimensions.get("window").width <= 1200 ? "100%" : "99%",
                    paddingHorizontal:
                      Dimensions.get("window").width <= 1200 && 10,
                  },
                ]}
              >
                <View
                  style={{ flex: 1, alignItems: "center", marginVertical: 10 }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                    }}
                  >
                    Current Month
                  </Text>
                </View>

                <View style={{ flexDirection: "row", marginBottom: 10 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                        }}
                      >
                        Estimated Earnings{" "}
                      </Text>
                      <Icon
                        color={"#000"}
                        name="info-circle"
                        type="font-awesome"
                        size={18}
                        onPress={() => {
                          this.props.setEarningModalStatus(true);
                        }}
                      />
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      $518
                    </Text>
                  </View>
                </View>

                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      Comments Written
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      20
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "red",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      100
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "grey",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      75
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff44f",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      25
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#4169e1",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      10
                    </Text>
                  </View>
                </View>
              </View>

              <View
                style={[
                  {
                    marginTop: 10,
                    paddingVertical: 10,
                    backgroundColor: "#fff",
                    paddingHorizontal:
                      Dimensions.get("window").width <= 1200 && 5,
                    marginHorizontal:
                      Dimensions.get("window").width <= 1200 ? 5 : 2,
                    borderRadius: 0,
                    width:
                      Dimensions.get("window").width <= 1200 ? "100%" : "99%",
                    borderWidth: 1,
                    borderColor: "#D7D7D7",
                  },
                ]}
              >
                <View
                  style={{ flex: 1, alignItems: "center", marginBottom: 20 }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                    }}
                  >
                    Last Month
                  </Text>
                </View>

                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      Earnings
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      $518
                    </Text>
                  </View>
                </View>

                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      Comments Written
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      20
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "red",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      100
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "grey",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      75
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff44f",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      25
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#4169e1",
                        }}
                        name="heart"
                        type="font-awesome"
                        size={20}
                      />
                      <Text
                        style={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 14,
                          alignSelf: "center",
                        }}
                      >
                        Received
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                      }}
                    >
                      10
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </>
        );
    }
  };

  _renderTabBar = (props) =>
    Dimensions.get("window").width >= 750 && (
      <View
        style={[
          ButtonStyle.TabbarBorderStyleForDashboard,
          {
            flexDirection: "row",
            height: 70,
            backgroundColor:
              Dimensions.get("window").width <= 750 ? "#000" : "#fff",
            alignItems: "center",
            paddingHorizontal: 10,
            paddingVertical: 10,
            marginHorizontal: "2%",
            marginLeft: "2%",
            width: Platform.OS == "web" ? "96%" : "100%",
            marginTop: Platform.OS == "web" ? 15 : 0,
          },
        ]}
      >
        <TabBar
          {...props}
          indicatorStyle={{
            backgroundColor: Colors.blueColor,
            height: 3,
            borderRadius: 0,
            marginBottom: 10,
          }}
          style={{
            backgroundColor: "transparent",
            width: "100%",
            height: 65,
            shadowColor: "transparent",
          }}
          labelStyle={[
            ButtonStyle.profileTitleStyle,
            {
              color: "#000",
              height: 30,
              justifyContent: "center",
            },
          ]}
          renderLabel={({ route, focused, color }) => (
            <Text
              style={[
                ButtonStyle.profileTitleStyle,
                {
                  paddingTop: 10,
                  color: focused ? Colors.blueColor : "#D3D3D3",
                  fontFamily: ConstantFontFamily.defaultFont,
                },
              ]}
            >
              {route.title}
            </Text>
          )}
        />
      </View>
    );

  render() {
    return (
      <>
        <View
          style={[
            {
              flex: 1,
              backgroundColor: ConstantColors.customeBackgroundColor,
              width: "100%",
              height: "100%",
            },
          ]}
        >
          <View>
            {/* {
              Dimensions.get("window").width <= 750 ? (
                <Animated.View
                  style={{
                    position: Platform.OS == "web" ? "sticky" : null,
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    overflow: "hidden",
                  }}
                >
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        backgroundColor: "#000",
                        height: 42,
                      }}
                    >
                      <TouchableOpacity
                        style={ButtonStyle.headerBackStyle}
                        onPress={() => {
                          this.props.searchOpenBarStatus(false);
                          let nav =
                            this.props.navigation.dangerouslyGetParent().state;
                          if (nav.routes.length > 1) {
                            this.props.navigation.goBack();
                            return;
                          } else {
                            this.props.navigation.navigate("home");
                          }
                        }}
                      >
                        <Icon
                          color={"#fff"}
                          name="angle-left"
                          type="font-awesome"
                          size={40}
                        />
                      </TouchableOpacity>
                      {!this.props.getsearchBarStatus && (
                        <TouchableOpacity
                          style={[
                            ButtonStyle.headerTitleStyle,
                            { backgroundColor: "#000" },
                          ]}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: 18,
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            Analytics
                          </Text>
                        </TouchableOpacity>
                      )}
                      <View style={{ alignItems: "flex-end" }}>
                        <Suspense fallback={<ShadowSkeleton />}>
                          <HeaderRight navigation={this.props.navigation} />
                        </Suspense>
                      </View>
                    </View>
                  </View>
                </Animated.View>
              ) : null
            } */}

            <View>
              {Dimensions.get("window").width <= 750 ? (
                <View
                  style={{
                    flex: 1,
                    width: "100%",
                    justifyContent: "flex-start",
                    paddingVertical: 10,
                  }}
                >
                  {this.renderTabViewForMobile(this.state.key)}
                </View>
              ) : (
                <View
                  style={{
                    flex: 1,
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <TabView
                    lazy
                    navigationState={this.state}
                    renderScene={this._renderScene}
                    renderLazyPlaceholder={this._renderLazyPlaceholder}
                    renderTabBar={this._renderTabBar}
                    onIndexChange={this._handleIndexChange}
                    removeClippedSubviews={true}
                  />
                </View>
              )}
            </View>
          </View>
        </View>
        {Dimensions.get("window").width <= 750 && (
          <Suspense fallback={<ShadowSkeleton />}>

            <BottomScreenAnalytics
              navigation={NavigationService}
              call={(id) => {
                this.setState({ key: id });
              }}
            />
          </Suspense>

        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getsearchBarStatus: state.AdminReducer.get("searchBarOpenStatus"),
});
const mapDispatchToProps = (dispatch) => ({
  setEarningModalStatus: (payload) =>
    dispatch({ type: "EARNINGMODALSTATUS", payload }),
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
  screen: (payload) => dispatch(screen(payload))

});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AnalyticsScreen
);

