import { fromJS } from 'immutable';
import { TRENDINGCLICKS_SUCCESS, TRENDINGCLICKS_FAILURE } from "../constants/Action";



const initialState = fromJS({
    Trending_cliks_List: null,
});

export default function TrendingCliksReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case TRENDINGCLICKS_SUCCESS:
            return state
                .set('Trending_cliks_List', payload);
        case TRENDINGCLICKS_FAILURE:
            return initialState;
    }
    return state;
}