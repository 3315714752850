import { fromJS } from 'immutable';
import {
    RESETPASSWORDMODALACTION_SUCCESS, RESETPASSWORDMODALACTION_FAILURE
} from '../constants/Action';


const initialState = fromJS({
    modalStatus: false,
});

export default function ResetPasswordModalReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case RESETPASSWORDMODALACTION_SUCCESS:
            return state
                .set('modalStatus', fromJS(payload));
        case RESETPASSWORDMODALACTION_FAILURE:
            return initialState;
    }
    return state;
}
