import { put, takeEvery } from 'redux-saga/effects';
import { USERAPPROACHACTION, USERAPPROACHACTION_FAILURE, USERAPPROACHACTION_SUCCESS } from "../constants/Action";



export function* UserApproachMiddleware({ payload }) {
    try {
        yield put({
            type: USERAPPROACHACTION_SUCCESS,
            payload: payload
        });
    } catch (err) {
        yield put({
            type: USERAPPROACHACTION_FAILURE,
            payload: err.message
        });
        console.log(err);
    }
    if (payload.identifier == 'reset') {
        yield put({
            type: USERAPPROACHACTION_FAILURE,
            payload: ''
        });

    }
}

export default function* UserApproachMiddlewareWatcher() {
    yield takeEvery(USERAPPROACHACTION, UserApproachMiddleware);
}