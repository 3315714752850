import { fromJS } from "immutable";
import {
  VERIFYEMAILMODALACTION_SUCCESS,
  VERIFYEMAILMODALACTION_FAILURE
} from "../constants/Action";

const initialState = fromJS({
  modalStatus: false,
  signupfollowmodal: false,
  signupjoinmodal: false
});

export default function VerifyEmailModalReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case VERIFYEMAILMODALACTION_SUCCESS:
      return state.set("modalStatus", fromJS(payload));
    case VERIFYEMAILMODALACTION_FAILURE:
      return initialState;
    case "SIGNUP_FOLLOW_MODAL":
      return state.set("signupfollowmodal", payload);
    case "SIGNUP_JOIN_MODAL":
      return state.set("signupjoinmodal", payload);
  }
  return state;
}
