import { SUBSCRIPTIONALERT_SUCCESS } from '../constants/Action';

const initialState = {
  subsAlert: false,
}

const SubscriptionAlertReducer = (state = initialState, action) => {

  switch (action.type) {
    case SUBSCRIPTIONALERT_SUCCESS: {
      return {
        subsAlert: action.payload,
      };
    }
    default:
      return state;
  }
};

export default SubscriptionAlertReducer;