import { SCREEN } from '../constants/Action';


const initialState = {
    screen: "home",
}

const screenNameReducer = (state = initialState, action) => {

    switch (action.type) {
        case SCREEN: {
            return {
                screen: action.payload,
            };
        }
        default:
            return state;
    }
};

export default screenNameReducer;