import { takeEvery, put } from 'redux-saga/effects';
import { SHARELINKMODALACTION, SHARELINKMODALACTION_SUCCESS, SHARELINKMODALACTION_FAILURE } from "../constants/Action";

export function* ShareLinkModalMiddleware({ payload }) {
    if (payload == true) {
        yield put({
            type: SHARELINKMODALACTION_SUCCESS,
            payload: payload
        });
    } else {
        yield put({
            type: SHARELINKMODALACTION_FAILURE,
            payload: payload
        });
    }
}

export default function* ShareLinkModalMiddlewareWatcher() {
    yield takeEvery(SHARELINKMODALACTION, ShareLinkModalMiddleware);
}