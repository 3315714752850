import { takeEvery, call, put } from 'redux-saga/effects';
import { TOPICSFEED, TOPICSFEED_SUCCESS, TOPICSFEED_FAILURE } from "../constants/Action";
import applloClient from '../client';
import { TopicFeed } from '../graphqlSchema/graphqlMutation/TrendingMutation';


const getResponse = (req) => {
    return applloClient.query({
        query: TopicFeed,
        variables: {
            id: "Topic:" + req.id,            
            first: 20,
            sort_type: "NEW",
        },
        fetchPolicy: 'no-cache'
    }).then(res => res);
};

export function* TopicsFeedMiddleware({ payload }) {
    try {
        const response = yield call(getResponse, payload);
        yield put({
            type: TOPICSFEED_SUCCESS,
            payload: response
        });
    } catch (err) {
        yield put({
            type: TOPICSFEED_FAILURE,
            payload: err.message
        });
        console.log(err);
    }
}

export default function* TopicsFeedMiddlewareWatcher() {
    yield takeEvery(TOPICSFEED, TopicsFeedMiddleware);

}