import { fromJS } from 'immutable';
import {
    USERNAMEMODALACTION_SUCCESS, USERNAMEMODALACTION_FAILURE
} from '../constants/Action';


const initialState = fromJS({
    modalStatus: false,
});

export default function UsernameModalReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USERNAMEMODALACTION_SUCCESS:
            return state
                .set('modalStatus', fromJS(payload));
        case USERNAMEMODALACTION_FAILURE:
            return initialState;
    }
    return state;
}
