import { List } from "immutable";
import moment from "moment";
import React, { Component } from "react";
import {
    Image,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";

class FeedImageDisplayUser extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // console.log(this.props, '2222222222');
        return (

            <View style={{
                // justifyContent: this.props.item.length > 0 ? 'center' : 'flex-start',

            }}>
                <Image
                    source={require("../assets/image/default-image.png")}
                    style={{
                        width: 38,
                        height: 38,
                        borderRadius: 10,
                        marginRight: 5,
                        borderColor: '#fff',
                        borderWidth: 2,
                        position: 'absolute',
                        top: -20,
                        left: 2
                    }}
                />
                <Image
                    source={require("../assets/image/default-image.png")}
                    style={{
                        width: 38,
                        height: 38,
                        borderRadius: 10,
                        marginRight: 5,
                        borderColor: '#fff',
                        borderWidth: 2,
                        position: 'absolute',
                        left: 25, top: 0,
                    }}
                />
                <Image
                    source={require("../assets/image/default-image.png")}
                    style={{
                        width: 38,
                        height: 38,
                        borderRadius: 10,
                        marginRight: 5,
                        borderColor: '#fff',
                        borderWidth: 2,
                        position: 'absolute',
                        left: 5,
                        top: 10
                    }}
                />
                {/* {this.props.item.length > 0 &&
                    this.props.currentusername != this.props.item[0]?.node?.username ? (
                    <Image

                        source={this.props.item[0]?.node?.profile_pic ? this.props.item[0]?.node?.profile_pic : require("../assets/image/default-image.png")}
                        style={{
                            width: 38,
                            height: 38,
                            borderRadius: 18,
                            // borderWidth: 1,
                            // borderColor: "#e1e1e1",Users
                            marginRight: 5,
                            position: 'relative',
                            top: 5,
                            left: 0,
                            borderColor: '#fff',
                            borderWidth: 2,
                        }}
                    />
                ) : null}
                {this.props.item.length > 1 &&
                    this.props.currentusername != this.props.item[1]?.node?.username && this.props.item[1]?.node?.username ?
                    <Image

                        source={this.props.item[1].node.profile_pic ? this.props.item[1].node.profile_pic : require("../assets/image/default-image.png")}
                        style={{
                            width: 38,
                            height: 38,
                            borderRadius: 18,
                            borderColor: '#fff',
                            borderWidth: 2,
                            marginRight: 5,
                            position: 'relative',
                            left: 25, top: -20
                        }}
                    />
                    : null}
                {this.props.item.length > 2 &&
                    this.props.currentusername != this.props.item[2]?.node?.username &&
                    <Image
                    // source={require("../assets/image/default-image.png")}

                        source={this.props.item[2].node.profile_pic ? this.props.item[2].node.profile_pic : require("../assets/image/default-image.png")}
                        style={{
                            width: 38,
                            height: 38,
                            borderRadius: 18,
                            borderColor: '#fff',
                            borderWidth: 2,
                            marginRight: 5,
                            position: 'relative', left: 0, top: -40
                        }}
                    />
                } */}
                
            </View>




        );
    }
}

const mapStateToProps = state => ({
    loginStatus: state.UserReducer.get("loginStatus"),
    isAdmin: state.AdminReducer.get("isAdmin"),
    profileData: state.LoginUserDetailsReducer.userLoginDetails,
});

const mapDispatchToProps = dispatch => ({
    setActiveId: payload => dispatch({ type: "SET_ACTIVE_ID", payload })
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    FeedImageDisplayUser
);
