import React, { useState, useEffect } from "react";
import {
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  TextInput
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import "./Firebase";
import ConstantFontFamily from "../constants/FontFamily";
import applloClient from "../client";
import {
  SearchTopicMutation,
  SearchClikMutation
} from "../graphqlSchema/graphqlMutation/SearchMutation";
import {
  SearchTopicVariables,
  SearchClikVariables
} from "../graphqlSchema/graphqlVariables/SearchVariables";
import { PostShareMutation } from "../graphqlSchema/graphqlMutation/PostMutation";
import { fromJS } from "immutable";
import ButtonStyle from "../constants/ButtonStyle";

const FeedShareModal = props => {
  const [offTopicActive, setoffTopicActive] = useState(false);
  const [lowQualityActive, setlowQualityActive] = useState(false);
  const [hateActive, sethateActive] = useState(false);
  const [submit, setsubmit] = useState(false);
  const [ClikList, setClikList] = useState([]);
  const [TopicList, setTopicList] = useState([]);
  const [selectedCliks, setselectedCliks] = useState([]);
  const [selectedTopics, setselectedTopics] = useState([]);
  const [topic, settopic] = useState("");
  const [clik, setclik] = useState("");

  const [quickTopicArray, setquickTopicArray] = useState([]);
  const [quickClikArray, setquickClikArray] = useState([]);

  useEffect(() => {
    let tempTopicArray = props.getUserFollowTopicList.slice(0, 5);
    setquickTopicArray(tempTopicArray);

    let tempClikArray = props.getUserFollowCliksList.slice(0, 5);
    setquickClikArray(tempClikArray);
  });

  const submitReport = async id => {
    let tempTopicArray = [];
    let tempClikArray = [];

    for (let i = 0; i < selectedTopics.length; i++) {
      tempTopicArray.push(selectedTopics[i].name);
    }
    for (let i = 0; i < selectedCliks.length; i++) {
      tempClikArray.push(selectedCliks[i].name);
    }

    setsubmit(true);
    setoffTopicActive(false);
    setlowQualityActive(false);
    sethateActive(false);
    props.setFeedShareModalStatus(false);
    await applloClient
      .query({
        query: PostShareMutation,
        variables: {
          content_id: props.PostId,
          topics: tempTopicArray,
          cliks: tempClikArray
        },
        fetchPolicy: "no-cache"
      })
      .then(response => {
        props.onClose();
        props.getTopic(tempTopicArray);
        props.getClik(tempClikArray);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const customRenderTopicSuggestion = value => {
    let topicsearchData = [];
    let topicsearchArray = [];
    let cliksearchData = [];
    let cliksearchArray = [];

    if (value.charAt(0) == "/") {
      SearchTopicVariables.variables.prefix = value;
      applloClient
        .query({
          query: SearchTopicMutation,
          ...SearchTopicVariables,
          fetchPolicy: "no-cache"
        })
        .then(res => {
          topicsearchArray = res.data.search.topics;
          for (let i = 0; i < topicsearchArray.length; i++) {
            topicsearchData.push({ topic: topicsearchArray[i] });
          }
          setTopicList(fromJS(topicsearchData));
        });
    } else if (value.charAt(0) == "#") {
      SearchClikVariables.variables.prefix = value;
      applloClient
        .query({
          query: SearchClikMutation,
          ...SearchClikVariables,
          fetchPolicy: "no-cache"
        })
        .then(res1 => {
          cliksearchArray = res1.data.search.cliks;
          for (let i = 0; i < cliksearchArray.length; i++) {
            cliksearchData.push({ clik: cliksearchArray[i] });
          }
          setClikList(fromJS(cliksearchData));
        });
    } else if (value === "") {
      setClikList([]);
      setTopicList([]);
    } else {
      SearchTopicVariables.variables.prefix = value;
      applloClient
        .query({
          query: SearchTopicMutation,
          ...SearchTopicVariables,
          fetchPolicy: "no-cache"
        })
        .then(res => {
          topicsearchArray = res.data.search.topics;
          for (let i = 0; i < topicsearchArray.length; i++) {
            topicsearchData.push({ topic: topicsearchArray[i] });
          }
          setTopicList(fromJS(topicsearchData));
        });

      SearchClikVariables.variables.prefix = value;
      applloClient
        .query({
          query: SearchClikMutation,
          ...SearchClikVariables,
          fetchPolicy: "no-cache"
        })
        .then(res1 => {
          cliksearchArray = res1.data.search.cliks;
          for (let i = 0; i < cliksearchArray.length; i++) {
            cliksearchData.push({ clik: cliksearchArray[i] });
          }
          setClikList(fromJS(cliksearchData));
        });
    }
  };

  const handleClikDelete = index => {
    let tagsSelected = [...selectedCliks];
    tagsSelected.splice(index, 1);
    setselectedCliks(tagsSelected);
  };

  const handleTopicDelete = index => {
    let tagsSelected = [...selectedTopics];
    tagsSelected.splice(index, 1);
    setselectedTopics(tagsSelected);
  };

  const handleTopicSelectInput = topic => {
    if (selectedTopics.length < 3) {
      let index = selectedTopics.findIndex(i => i.name == topic);
      if (index != -1) {
        alert("topic name already selected");
      } else {
        setselectedTopics([...selectedTopics, { name: topic }]);
        settopic("");
        setclik("");
        setTopicList([]);
        setClikList([]);
      }
    } else {
      alert("You can only choose Maximum 3 Topics to Tag");
      settopic("");
    }
  };

  const handleClikSelectInput = clik => {
    if (selectedCliks.length < 3) {
      let index = selectedCliks.findIndex(i => i.name == clik);
      if (index != -1) {
        alert("clik name already selected");
      } else {
        setselectedCliks([...selectedCliks, { name: clik }]);
        settopic("");
        setclik("");
        setTopicList([]);
        setClikList([]);
      }
    } else {
      alert("You can only choose Maximum 3 Cliks to Tag");
      setclik("");
    }
  };

  const customRenderTopicTags = tags => {
    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          backgroundColor: "#fff",
          width: "100%",
          padding: 5
        }}
      >
        {selectedTopics.map((t, i) => {
          return (
            <View
              key={t.name}
              style={{
                flexDirection: "row",
                backgroundColor: "#e3f9d5",
                justifyContent: "center",
                alignItems: "center",
                height: 30,
                marginLeft: 5,
                marginTop: 5,
                borderRadius: 6,
                padding: 3
              }}
            >
              <TouchableOpacity key={i} onPress={() => handleTopicDelete(i)}>
                <Text
                  style={{
                    color: "#009B1A",
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  /{t.name.toLowerCase()}
                </Text>
              </TouchableOpacity>
              <Icon
                color={
                  "#009B1A"
                }
                name="times"
                type="font-awesome"
                size={12}
                iconStyle={{ marginLeft: 5 }}
                onPress={() => handleTopicDelete(i)}
              />
            </View>
          );
        })}
      </View>
    );
  };

  const customRenderClikTags = tags => {
    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          backgroundColor: "#fff",
          width: "100%",
          padding: 5
        }}
      >
        {selectedCliks.map((t, i) => {
          return (
            <View
              key={t.name}
              style={{
                flexDirection: "row",
                backgroundColor: "#E8F5FA",
                justifyContent: "center",
                alignItems: "center",
                height: 30,
                marginLeft: 5,
                marginTop: 5,
                borderRadius: 6,
                padding: 3
              }}
            >
              <TouchableOpacity key={i} onPress={() => handleClikDelete(i)}>
                <Text
                  style={{
                    color: "#4169e1",
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  #{t.name}
                </Text>
              </TouchableOpacity>
              <Icon
                color={"#4C82B6"}
                name="times"
                type="font-awesome"
                size={12}
                iconStyle={{ marginLeft: 5 }}
                onPress={() => handleClikDelete(i)}
              />
            </View>
          );
        })}
      </View>
    );
  };

  return (
    <View
      style={{
        width: "100%"
      }}
    >
      <Hoverable>
        {isHovered => (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              flex: 1,
              position: "absolute",
              zIndex: 999999,
              left: 0,
              top: 0
            }}
            onPress={props.onClose}
          >
            <Icon
              color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
              iconStyle={{
                color: "#fff",
                justifyContent: "center",
                alignItems: "center"
              }}
              reverse
              name="close"
              type="antdesign"
              size={16}
            />
          </TouchableOpacity>
        )}
      </Hoverable>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#000",
          alignItems: "center",
          height: 50,
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6
        }}
      >
        <Text
          style={{
            alignSelf: "center",
            color: "#fff",
            fontFamily: ConstantFontFamily.defaultFont,
            fontSize: 18,
            fontWeight: "bold",
            marginVertical: 10
          }}
        >
          {" "}
          Share Post
        </Text>
      </View>
      <View
        style={{
          borderRadius: 4,
          overflow: "visible",
          width: "100%",
          backgroundColor: "#000"
        }}
      >
        <View style={{ paddingHorizontal: "4%", backgroundColor: "#fff" }}>
          <View style={{ width: "100%", marginTop: 20 }}>
            <View
              style={{
                marginVertical: 10,
                width: "100%",
                borderRadius: Platform.OS == "web" ? 6 : 0
              }}
            >
              {selectedTopics.length > 0 && customRenderTopicTags()}

              {selectedCliks.length > 0 && customRenderClikTags()}

              <View
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  alignItems: "center",
                  borderColor: "#e1e1e1",
                  borderWidth: 1,
                  borderRadius: 15,
                  marginTop: 20
                }}
              >
                <View style={{ width: "10%", marginRight: "auto" }}>
                  <Icon name="search" size={18} type="font-awesome" />
                </View>
                <TextInput
                  autoFocus={false}
                  placeholder="share to /topics #clicks"
                  onChangeText={topic => {
                    settopic(topic);
                    setclik(topic);
                    customRenderTopicSuggestion(topic);
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    fontFamily: ConstantFontFamily.defaultFont,
                    fontSize: 14
                  }}
                />
              </View>
              <ScrollView
                style={{ maxHeight: 200 }}
                showsVerticalScrollIndicator={false}
              >
                {TopicList.map((item, index) => {
                  return (
                    <View
                      key={item.name}
                      style={{
                        backgroundColor: "#FEFEFA",
                        width: "100%",
                        padding: 5
                      }}
                    >
                      <View
                        style={{
                          padding: 5,
                          backgroundColor: item.getIn(["topic", "parents"])
                            ? "#e3f9d5"
                            : "#e3f9d5",
                          borderRadius: 6,
                          alignSelf: "flex-start",
                          alignItems: "center"
                        }}
                      >
                        <Text
                          style={{
                            color: item.getIn(["topic", "parents"])
                              ? "#009B1A"
                              : "#009B1A",
                            fontFamily: ConstantFontFamily.defaultFont,
                            fontWeight: "bold"
                          }}
                          onPress={() =>
                            handleTopicSelectInput(
                              item.getIn(["topic", "name"])
                            )
                          }
                        >
                          /{item.getIn(["topic", "name"]).toLowerCase()}
                        </Text>
                      </View>
                    </View>
                  );
                })}
                {ClikList.map((item, index) => {
                  return (
                    <View
                      key={item.name}
                      style={{
                        backgroundColor: "#FEFEFA",
                        width: "100%",
                        padding: 5
                      }}
                    >
                      <View
                        style={{
                          padding: 5,
                          backgroundColor: "#E8F5FA",
                          borderRadius: 6,
                          alignSelf: "flex-start",
                          alignItems: "center"
                        }}
                      >
                        <Text
                          style={{
                            color: "#4169e1",
                            fontFamily: ConstantFontFamily.defaultFont,
                            fontWeight: "bold"
                          }}
                          onPress={() =>
                            handleClikSelectInput(item.getIn(["clik", "name"]))
                          }
                        >
                          #{item.getIn(["clik", "name"])}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </ScrollView>
              <View
                style={{
                  flexDirection: "row",
                  paddingHorizontal: "10%",
                  justifyContent: "space-between"
                }}
              >
                <View style={{ marginVertical: 10, maxHeight: 350 }}>
                  {quickTopicArray.map((item, i) => {
                    return (
                      <TouchableOpacity
                        onPress={() =>
                          handleTopicSelectInput(item.getIn(["topic", "name"]))
                        }
                        style={{
                          marginTop: 10,
                          marginLeft: 5,
                          alignSelf: "center",
                          padding: 5,
                          backgroundColor: item.getIn(["topic", "parents"])
                            ? "#e3f9d5"
                            : "#e3f9d5",
                          borderRadius: 6
                        }}
                      >
                        <Hoverable>
                          {isHovered => (
                            <Text
                              style={{
                                width: "100%",
                                color: item.getIn(["topic", "parents"])
                                  ? "#009B1A"
                                  : "#009B1A",
                                fontSize: 15,
                                fontWeight: "bold",
                                fontFamily:
                                  ConstantFontFamily.defaultFont,
                                textDecorationLine:
                                  isHovered == true ? "underline" : "none"
                              }}
                            >
                              /{item.getIn(["topic", "name"]).toLowerCase()}
                            </Text>
                          )}
                        </Hoverable>
                      </TouchableOpacity>
                    );
                  })}
                </View>
                <View style={{ marginVertical: 10, maxHeight: 350 }}>
                  {quickClikArray.map((item, i) => {
                    return (
                      <TouchableOpacity
                        onPress={() =>
                          handleClikSelectInput(item.getIn(["clik", "name"]))
                        }
                        style={{
                          marginLeft: 5,
                          margin: 5,
                          alignSelf: "center",
                          padding: 5,
                          backgroundColor: "#E8F5FA",
                          borderRadius: 6,
                          maxWidth: "100%"
                        }}
                      >
                        <Hoverable>
                          {isHovered => (
                            <Text
                              style={{
                                width: "100%",
                                textAlign: "left",
                                color: "#4169e1",
                                fontSize: 15,
                                fontWeight: "bold",
                                fontFamily:
                                  ConstantFontFamily.defaultFont,
                                textDecorationLine:
                                  isHovered == true ? "underline" : "none"
                              }}
                            >
                              #{item.getIn(["clik", "name"])}
                            </Text>
                          )}
                        </Hoverable>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 10
                }}
              >
                <Button
                  title="Cancel"
                  buttonStyle={ButtonStyle.backgroundStyle}
                  titleStyle={ButtonStyle.titleStyle}
                  containerStyle={ButtonStyle.containerStyle}
                  onPress={props.onClose}
                />
                <Button
                  title="Share"
                  buttonStyle={ButtonStyle.backgroundStyle}
                  titleStyle={ButtonStyle.titleStyle}
                  containerStyle={ButtonStyle.containerStyle}
                  disabled={
                    selectedCliks.length > 0 || selectedTopics.length > 0
                      ? false
                      : true
                  }
                  onPress={() => submitReport(props.PostId)}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const mapStateToProps = state => ({
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList
    : [],
  getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
    ? state.LoginUserDetailsReducer.userFollowTopicsList
    : []
});

const mapDispatchToProps = dispatch => ({
  setFeedShareModalStatus: payload =>
    dispatch({ type: "SHAREFEEDSTATUS", payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedShareModal);

