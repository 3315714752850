import { takeEvery, put } from 'redux-saga/effects';
import { USERNAMEMODALACTION, USERNAMEMODALACTION_SUCCESS, USERNAMEMODALACTION_FAILURE } from "../constants/Action";

export function* UsernameModalMiddleware({ payload }) {
    if (payload == true) {
        yield put({
            type: USERNAMEMODALACTION_SUCCESS,
            payload: payload
        });
    } else {
        yield put({
            type: USERNAMEMODALACTION_FAILURE,
            payload: payload
        });
    }
}

export default function* UsernameModalMiddlewareWatcher() {
    yield takeEvery(USERNAMEMODALACTION, UsernameModalMiddleware);
}