import { takeEvery, put } from 'redux-saga/effects';
import { FEEDREPORTMODALACTION, FEEDREPORTMODALACTION_SUCCESS, FEEDREPORTMODALACTION_FAILURE } from "../constants/Action";

export function* FeedReportModalMiddleware({ payload }) {
    if (payload == true) {
        yield put({
            type: FEEDREPORTMODALACTION_SUCCESS,
            payload: payload
        });
    } else {
        yield put({
            type: FEEDREPORTMODALACTION_FAILURE,
            payload: payload
        });
    }
}

export default function* FeedReportModalMiddlewareWatcher() {
    yield takeEvery(FEEDREPORTMODALACTION, FeedReportModalMiddleware);
}