import { Component } from "react"
// import { exp } from "react-native-reanimated"
import {
    View,
    ScrollView,
    Text,
    TouchableOpacity,
    TextInput,
    Dimensions,
} from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import ConstantFontFamily from "../constants/FontFamily";
import Colors from '../constants/Colors';
import NavigationService from "../library/NavigationService";
import { connect } from "react-redux";
import { compose } from "recompose";

class BottomPart extends Component {

    goToPage = (pageName) => {
        this.props.leftPanelModalFunc(false)
        if (pageName == 'terms') {
            NavigationService.navigate("termsandconditions");
        } else if (pageName == 'privacy') {
            NavigationService.navigate("privacyPolicy");
        } else if (pageName == 'faq') {
            NavigationService.navigate("faq");
        }
    }

    render() {
        return (
            <View
                style={{
                    // marginTop: 10,
                    width: "100%",
                    flexDirection: "row",
                    height: Dimensions.get("window").width >= 1200 ? hp("3%") : hp("2%"),
                    backgroundColor: '#fff',
                    paddingHorizontal: 20,
                    justifyContent: 'space-between',
                    borderRadius: 20,
                    alignItems: 'center',
                }}
            >
                <Hoverable>
                    {isHovered => (
                        <TouchableOpacity
                            onPress={() => {
                                this.goToPage('terms')
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: "grey",
                                    textAlign: "center",
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    textDecorationLine: isHovered == true ? "underline" : "none"
                                }}
                            >
                                Terms of Service
                            </Text>
                        </TouchableOpacity>
                    )}
                </Hoverable>

                <Hoverable>
                    {isHovered => (
                        <TouchableOpacity
                            onPress={() => {
                                this.goToPage('faq')
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: "grey",
                                    textAlign: "center",
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    textDecorationLine: isHovered == true ? "underline" : "none"
                                }}
                            >
                                FAQ
                            </Text>
                        </TouchableOpacity>
                    )}
                </Hoverable>

                <Hoverable>
                    {isHovered => (
                        <TouchableOpacity
                            onPress={() => {
                                this.goToPage('privacy')
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: "grey",
                                    textAlign: "center",
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    textDecorationLine: isHovered == true ? "underline" : "none"
                                }}
                            >
                                Privacy Policy
                            </Text>
                        </TouchableOpacity>
                    )}
                </Hoverable>
            </View>
        )
    }
}



const mapDispatchToProps = dispatch => ({


    leftPanelModalFunc: (payload) =>
        dispatch({ type: "LEFT_PANEL_OPEN", payload }),




});


export default compose(connect(null, mapDispatchToProps))(
    BottomPart
);

// export default BottomPart