import { fromJS } from "immutable";
import { EDITCLIK_SUCCESS, EDITCLIK_FAILURE } from "../constants/Action";

const initialState = fromJS({
  clik: ""
});

export default function EditClikReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EDITCLIK_SUCCESS:
      return state.set("clik", fromJS(payload));
    case EDITCLIK_FAILURE:
      return initialState;
  }
  return state;
}
