import React, { useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
} from "react-native";
import { Icon } from "react-native-elements";
import RNPickerSelect from "react-native-picker-select";
import { connect } from "react-redux";
import { compose } from "recompose";
import applloClient from "../client";
import AppHelper from "../constants/AppHelper";
import ConstantFontFamily from "../constants/FontFamily";
import {
  KickClikMemberMutation,
  PromoteClikMemberMutation
} from "../graphqlSchema/graphqlMutation/FollowandUnFollowMutation";
import {
  KickClikMemberVariables,
  PromoteClikMemberVariables
} from "../graphqlSchema/graphqlVariables/FollowandUnfollowVariables";
import { listClikMembers } from "../actionCreator/ClikMembersAction";
import { capitalizeFirstLetter } from "../library/Helper";
import NavigationService from "../library/NavigationService";
import ButtonStyle from "../constants/ButtonStyle";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import { Hoverable } from "react-native-web-hooks";

const ClikProfileUserList = props => {
  const inputRefs = {};
  const [reqUserMemberChange, setReqUserMemberChange] = useState("MEMBER");
  const [tempType, settempType] = useState("");



  const promoteMember = async id => {
    if (reqUserMemberChange != "KICK_USER") {
      PromoteClikMemberVariables.variables.clik_id = props.ClikInfo.get("data")
        .get("node")
        .get("id");
      PromoteClikMemberVariables.variables.user_id = id;
      PromoteClikMemberVariables.variables.member_type = reqUserMemberChange;

      try {
        await applloClient
          .query({
            query: PromoteClikMemberMutation,
            ...PromoteClikMemberVariables,
            fetchPolicy: "no-cache"
          })
          .then(async res => {
            await props.setClikMembers({
              id: props.ClikInfo.get("data")
                .get("node")
                .get("id")
                .replace("Clik:", "")
            });
          });
        if (props.item.item.node.member_type == "MEMBER" && reqUserMemberChange == "ADMIN") {
          alert("Promoted " + props.item.item.node.user.username + " to Admin")
        } else if (props.item.item.node.member_type == "ADMIN" && reqUserMemberChange == "MEMBER") {
          alert("Demoted " + props.item.item.node.user.username + " to Memebr")
        } else if ((props.item.item.node.member_type == "ADMIN" || props.item.item.node.member_type == "MEMBER") && reqUserMemberChange == "SUPER_ADMIN") {
          alert("Promoted " + props.item.item.node.user.username + " " + reqUserMemberChange.replace('_', " "))
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      KickClikMemberVariables.variables.clik_id = props.ClikInfo.get("data")
        .get("node")
        .get("id");
      KickClikMemberVariables.variables.user_id = id;
      try {
        await applloClient
          .query({
            query: KickClikMemberMutation,
            ...KickClikMemberVariables,
            fetchPolicy: "no-cache"
          })
          .then(async res => {
            await props.setClikMembers({
              id: props.ClikInfo.get("data")
                .get("node")
                .get("id")
                .replace("Clik:", "")
            });
          });
        alert("Kicked " + props.item.item.node.user.username + " to " + props.ClikInfo.get("data")
          .get("node")
          .get("name"))

      } catch (e) {
        console.log(e);
      }
    }
  };

  const removeCurrentMember = (username) => {

    window.confirm(
      "Are you sure you want to remove " +
      "@" + props.item.item.node.user.username + ' from ' +
      '#' + props.ClikInfo.get("data").get("clik").get("name") + " ?")
    {
      KickClikMemberVariables.variables.clik_id = props.ClikInfo.get("data")
        .get("node")
        .get("id");
      KickClikMemberVariables.variables.user_id = props.item.item.node.user.id;
      try {
        applloClient
          .query({
            query: KickClikMemberMutation,
            ...KickClikMemberVariables,
            fetchPolicy: "no-cache"
          })
          .then(async res => {
            props.getCurrentMember(username)

          });
      } catch (e) {
        console.log(e);
      }
    }

  }

  const goToProfile = (username) => {
    props.userId({
      username: username,
      type: "feed",
    });
    NavigationService.navigate("profile", {
      username: username,
      type: "feed",
      postId: " ",
    });
    props.setPostCommentReset({
      payload: [],
      postId: "",
      title: "",
      loading: true,
    });
    props.leftPanelModalFunc(false);
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{
        backgroundColor: "#fff",
        paddingVertical: 10,
        paddingBottom: 20
      }}
    >
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Hoverable>
          {(isHovered) => (
            <TouchableOpacity
              style={{
                width: "55%",
                flexDirection: "row",
                justifyContent: "flex-start"
              }}
              onPress={() => goToProfile(props.item.item.node.user.username)}
            >
              {props.item.item.node.user.profile_pic == "" ||
                props.item.item.node.user.profile_pic == null ? (
                <Image
                  source={require("../assets/image/default-image.png")}
                  style={{
                    width: 40,
                    height: 40,
                    padding: 0,
                    margin: 5,
                    borderRadius: 20
                  }}
                />
              ) : (
                <Image
                  source={{ uri: props.item.item.node.user.profile_pic }}
                  style={{
                    width: 40,
                    height: 40,
                    padding: 0,
                    margin: 5,
                    borderRadius: 20
                  }}
                />
              )}
              <Text
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontWeight: "bold",
                  alignSelf: 'center',
                  marginLeft: 5,
                  fontFamily: ConstantFontFamily.defaultFont,
                  textDecorationLine:
                    isHovered == true ? "underline" : "none",
                }}
              >
                @{props.item.item.node.user.username}
              </Text>
            </TouchableOpacity>
          )}
        </Hoverable>

        {
          props.MemberType == "SUPER_ADMIN" ?
            props.userDetails.getIn(["user", "username"]) != props.item.item.node.user.username ? (
              <View
                style={{
                  flexDirection: "row",
                  width: '45%',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                <View
                  style={[ButtonStyle.shadowStyle, {
                    width: "66%",
                    justifyContent: "flex-end",
                    borderRadius: 5,
                    borderColor: "#d7d7d7",
                    borderWidth: 1,
                    marginLeft: 5,
                    height: 40,
                    marginTop: 5,
                    alignSelf: "center",
                    marginTop: 0,
                    shadowOpacity: 0.12,
                    marginBottom:5
                  }]}
                >                 

                  <RNPickerSelect
                    value={tempType ? tempType : props.item.item.node.member_type}
                    placeholder={{}}
                    items={AppHelper.MEMBER_PROMOTE_ROLEITEMS}
                    onValueChange={(itemValue) => {
                      setReqUserMemberChange(itemValue);
                      settempType(itemValue)
                    }}
                    onUpArrow={() => {
                      inputRefs.name.focus();
                    }}
                    onDownArrow={() => {
                      inputRefs.picker2.togglePicker();
                    }}
                    style={{ ...styles }}
                    ref={el => {
                      inputRefs.picker = el;
                    }}
                  />
                </View>
                <View style={{
                  width: 40,
                  marginHorizontal: 15,
                  justifyContent: 'center'
                }}>
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#000",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    name="save"
                    type="font-awesome"
                    size={18}
                    containerStyle={{
                      justifyContent: 'center',
                    }}
                    onPress={() =>
                      promoteMember(props.item.item.node.user.id)
                    }
                  />
                </View>

                {props.MemberType == "SUPER_ADMIN" || props.MemberType == "ADMIN" ?
                  <Icon
                    color={"#000"}
                    name="trash"
                    type="font-awesome"
                    size={18}
                    iconStyle={{
                      justifyContent: 'center'
                    }}
                    onPress={() => removeCurrentMember(props.item.item.node.user.username)}
                  />
                  : null}
              </View>
            ) : (
              <View style={{width:'40%'}}>
              <Text
                style={{
                  textAlign: 'center',
                  color: "#000",
                  marginRight: 10,
                  fontSize: 14,
                  width: 110,
                  fontWeight: "bold",
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                {props.item.item.node.member_type == null ? "Member" :
                  props.item.item.node.member_type == "SUPER_ADMIN" ? "Super Admin" :
                    capitalizeFirstLetter(props.item.item.node.member_type.toLowerCase())
                }
              </Text>
              </View>
            )
            : (


              <View
                style={{
                  height: 30,
                  width: 115,
                  marginRight: 5,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Text
                  style={{
                    textAlign: 'center',
                    color: "#000",
                    marginRight: 10,
                    fontSize: 14,
                    width: 110,
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  {props.item.item.node.member_type == null ? "Member" :
                    props.item.item.node.member_type == "SUPER_ADMIN" ? "Super Admin" :
                      capitalizeFirstLetter(props.item.item.node.member_type.toLowerCase())
                  }
                </Text>
                { props.MemberType == "ADMIN" ?
                  <Icon
                    color={"#000"}
                    name="trash"
                    type="font-awesome"
                    size={18}
                    iconStyle={{
                      marginLeft: "auto"
                    }}
                    onPress={() => removeCurrentMember(props.item.item.node.user.username)}
                  />
                  : null}
              </View>

            )
        }
      </View>
    </ScrollView >
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.UserProfileDetailsReducer.get(
    "getCurrentUserProfileDetails"
  ),
})
const mapDispatchToProps = dispatch => ({
  setClikMembers: payload => dispatch(listClikMembers(payload)),
  leftPanelModalFunc: (payload) =>
    dispatch({ type: "LEFT_PANEL_OPEN", payload }),
  setPostCommentReset: (payload) =>
    dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  React.memo(ClikProfileUserList)
);

const styles = StyleSheet.create({

  inputIOS: {
    paddingTop: 13,
    paddingHorizontal: 10,
    paddingBottom: 12,
    borderWidth: 0,
    borderRadius: 6,
    backgroundColor: "white",
    color: "black",
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },
  inputAndroid: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderWidth: 0,
    borderRadius: 6,
    color: "#000",
    backgroundColor: "white",
    paddingRight: 30,
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  }
});