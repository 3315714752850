import { fromJS } from "immutable";
import {
  USER_PROFILE_DETAILS_FAILURE,
  USER_PROFILE_DETAILS_SUCCESS
} from "../constants/Action";

const initialState = fromJS({
  getCurrentUserProfileDetails: {}
});

export default function UserProfileDetailsReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case USER_PROFILE_DETAILS_SUCCESS:
      return state.set("getCurrentUserProfileDetails", fromJS(payload));
    case USER_PROFILE_DETAILS_FAILURE:
      return initialState;
    case "USER_PROFILE_DETAILS_RESET":
      return initialState;
  }
  return state;
}
