import { fromJS } from 'immutable';
import { USERAPPROACHACTION_SUCCESS, USERAPPROACHACTION_FAILURE } from "../constants/Action";



const initialState = fromJS({
    setUserApproach: ''
});

export default function UserApproachReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USERAPPROACHACTION_SUCCESS:
            return state
                .set('setUserApproach', fromJS(payload.type))
        case USERAPPROACHACTION_FAILURE:
            return initialState;
    }
    return state;
}