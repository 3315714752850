import React from "react";
import { Animated, Dimensions, View } from "react-native";
// import SkeletonContent from 'react-native-skeleton-content';
import ButtonStyle from "../constants/ButtonStyle";
import Colors from '../constants/Colors';


class ShadowSkeletonStar extends React.Component {
  constructor(props) {
    super(props);
    this.Pagescrollview = null;
    this.circleAnimatedValue = new Animated.Value(0);
    this.count = [1, 2, 3, 4, 5];
  }

  componentDidMount() {
    this.circleAnimated();
  }

  circleAnimated = () => {
    this.circleAnimatedValue.setValue(0);
    Animated.timing(this.circleAnimatedValue, {
      toValue: 1,
      duration: 1000
    }).start(() => {
      setTimeout(() => {
        this.circleAnimated();
      }, 1000);
    });
  };

  render() {
    const translateX = this.circleAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-10, Dimensions.get("window").width]
    });
    const translateTitle = this.circleAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-10, Dimensions.get("window").width]
    });
    return (
      <View>
        {this.count.map((item, i) => {
          return (
            <View style={[ButtonStyle.normalFeedStyleForDashboard, {
              height: 35,
              backgroundColor:"rgba(0,0,0,0.03)",
              borderRadius:10
    
            }]}></View>
            // <SkeletonContent

            //   containerStyle={{
            //     width: "100%",
            //     justifyContent:'center',
            //     alignItems:'center',
            //     // marginLeft: Dimensions.get('window').width >= 1200 ? 10 : 8,
            //     marginTop: Dimensions.get('window').width >= 1200 ? 15 : 10,
            //     height: 35,
            //     shadowOffset: {
            //       width: 0,
            //       height: 5,
            //     },
            //     shadowOpacity: 0.25,
            //     shadowRadius: 12,

            //     elevation: 7,
            //     borderRadius: 6
            //   }}
            //   boneColor={"rgba(0,0,0,0.03)"}
            //   highlightColor={Colors.greyBackground}

            //   layout={[{ width: '100%', height: 35, borderRadius: 6 }]}
            //   animationDirection="horizontalRight"
            //   isLoading={true}
            // />
          );
        })}
      </View>
    );
  }
}
export default ShadowSkeletonStar;
