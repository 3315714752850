import { fromJS } from 'immutable';
import {
    FEEDREPORTMODALACTION_SUCCESS, FEEDREPORTMODALACTION_FAILURE
} from '../constants/Action';


const initialState = fromJS({
    modalStatus: false,
    shareFeedStatus: false,
    setFeedId:''
});

export default function FeedReportModalReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FEEDREPORTMODALACTION_SUCCESS:
            return state
                .set('modalStatus', fromJS(payload));
        case FEEDREPORTMODALACTION_FAILURE:
            return initialState;

        case 'SHAREFEEDSTATUS':
            return state
            .set('shareFeedStatus', fromJS(payload));
        case "SET_FEED_ID":
                return state.set("setFeedId", fromJS(payload));
    }
    return state;
}
