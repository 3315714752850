
import { Component } from 'react';
import {
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    Platform
} from 'react-native';
import ButtonStyle from "../constants/ButtonStyle";
import { Icon } from "react-native-elements";
import ConstantFontFamily from "../constants/FontFamily";
import { connect } from "react-redux";
import { compose } from "recompose";
import { openToolkit, toolkitOff, showDiscussion, showbox, getCommentTitle, userHighlight, getRepliedId, postCommentStatus } from '../reducers/action/Completed';
import TooltipBox from './TooltipBox';
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setLikeContent } from "../actionCreator/LikeContentAction";
import _ from "lodash";
import { OuterClick } from 'react-outer-click';
import ConstantColor from '../constants/Colors';
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";



class CommonTooltipBottom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showTooltip: false,
            blueLikeCount: 0,
            yellowLikeCount: 0,
            silverLikeCount: 0,
            redLikeCount: 0,
            showRedHeart: true
        }
        this.props1 = this.props.props.isfeed == true ? this.props.props : this.props

    }

    increaseLikeCount = (id, likeColor) => {
        console.log(id, likeColor, 'likeee');

        if (likeColor == 'DIAMOND') {
            this.setState({ blueLikeCount: this.state.blueLikeCount + 1 })
        } else if (likeColor == 'GOLD') {
            this.setState({ yellowLikeCount: this.state.yellowLikeCount + 1 })
        } else if (likeColor == 'SILVER') {
            this.setState({ silverLikeCount: this.state.silverLikeCount + 1 })
        } else if (likeColor == 'RED') {
            this.setState({ redLikeCount: this.state.redLikeCount + 1 })
        }
        this.props.LikeContent({
            content_id: this.props.type == "comment" ? id : this.props.PostId,
            like_type: likeColor
        });
        this.setState({ showTooltip: false, showRedHeart: false })
    }
    render() {
        // console.log(this.props.postCommentId,'postcommentid');
        // console.log(this.props.totalComment,'totalComment');

        const props = this.props1
        // console.log(this.props.totalComment, 'this.props.totalComment');
        // if(this.props.type == "comment" ){
        //     console.log(this.props, 'prrr');
        // }
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', }}>
                {props?.item?.item?.node?.num_likes?.num_gold_likes > 0 || this.props.props?.item?.node?.num_likes?.num_gold_likes > 0 || this.state.yellowLikeCount > 0 ?
                    <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>

                        <Icon
                            color={'#ffce44'}
                            name={"heart"}
                            type="font-awesome"
                            size={12}
                            iconStyle={{ alignSelf: "center" }}
                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                        />
                        <Text
                            style={{
                                width: 15,
                                // textAlign: 'left',
                                paddingLeft: 4,
                                fontSize: 13,
                                fontFamily: ConstantFontFamily.defaultFont,
                                color: "#6D757F"
                            }}
                        >
                            {this.props?.props?.item?.node?.num_likes ? this.props?.props?.item?.node?.num_likes?.num_gold_likes + this.state.yellowLikeCount : props?.item?.item?.node?.num_likes?.num_gold_likes + this.state.yellowLikeCount}
                            {/* {(props?.item?.item?.node?.num_likes?.num_red_likes + props?.item?.item?.node?.num_likes?.num_silver_likes * 2 + props?.item?.item?.node?.num_likes?.num_gold_likes * 3 + props?.item?.item?.node?.num_likes?.num_diamond_likes * 4) > 0 ?
                                (props?.item?.item?.node?.num_likes?.num_red_likes + props?.item?.item?.node?.num_likes?.num_silver_likes * 2 + props?.item?.item?.node?.num_likes?.num_gold_likes * 3 + props?.item?.item?.node?.num_likes?.num_diamond_likes * 4) + this.props.heartCount
                                :
                                this?.props?.heartCount ? this?.props?.heartCount : 0
                            } */}
                        </Text>
                    </View>
                    : null}
                {props?.item?.item?.node?.num_likes?.num_diamond_likes > 0 || this.props.props?.item?.node?.num_likes?.num_diamond_likes > 0 || this.state.blueLikeCount > 0 ?
                    <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>

                        <Icon
                            color={ConstantColor.blueColor}
                            name={"heart"}
                            type="font-awesome"
                            size={12}
                            iconStyle={{ alignSelf: "center" }}
                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                        />
                        <Text
                            style={{
                                width: 15,
                                // textAlign: 'left',
                                paddingLeft: 4,
                                fontSize: 13,
                                fontFamily: ConstantFontFamily.defaultFont,
                                color: "#6D757F"
                            }}
                        >
                            {this.props?.props?.item?.node?.num_likes ? this.props?.props?.item?.node?.num_likes?.num_diamond_likes + this.state.blueLikeCount : props?.item?.item?.node?.num_likes?.num_diamond_likes + this.state.blueLikeCount}
                            {/* {(props?.item?.item?.node?.num_likes?.num_red_likes + props?.item?.item?.node?.num_likes?.num_silver_likes * 2 + props?.item?.item?.node?.num_likes?.num_gold_likes * 3 + props?.item?.item?.node?.num_likes?.num_diamond_likes * 4) > 0 ?
                                (props?.item?.item?.node?.num_likes?.num_red_likes + props?.item?.item?.node?.num_likes?.num_silver_likes * 2 + props?.item?.item?.node?.num_likes?.num_gold_likes * 3 + props?.item?.item?.node?.num_likes?.num_diamond_likes * 4) + this.props.heartCount
                                :
                                this?.props?.heartCount ? this?.props?.heartCount : 0
                            } */}
                        </Text>
                    </View>
                    : null}
                {/* {props?.item?.item?.node?.num_likes?.num_red_likes > 0 || this.props?.props?.item?.node?.num_likes?.num_red_likes > 0 || this.state.redLikeCount > 0 ? */}
                {/* <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>
                    <Icon
                        color={'#de5246'}
                        name={"heart"}
                        type="font-awesome"
                        size={12}
                        iconStyle={{ alignSelf: "center", }}
                        containerStyle={{ alignSelf: "center", paddingVertical: 4 }}
                    />
                    {props?.item?.item?.node?.num_likes?.num_red_likes > 0 || this.props?.props?.item?.node?.num_likes?.num_red_likes > 0 || this.state.redLikeCount > 0 ?
                        <Text
                            style={{
                                width: 15,
                                paddingLeft: 4,
                                fontSize: 14,
                                fontFamily: ConstantFontFamily.defaultFont,
                                color: "#6D757F"
                            }}
                        >
                            {this.props?.props?.item?.node?.num_likes ? this.props?.props?.item?.node?.num_likes?.num_red_likes + this.state.redLikeCount : props?.item?.item?.node?.num_likes?.num_red_likes + this.state.redLikeCount}

                        </Text> : null}
                </View> */}
                {/* : null} */}
                {props?.item?.item?.node?.num_likes?.num_silver_likes > 0 || this.props?.props?.item?.node?.num_likes?.num_silver_likes > 0 || this.state.silverLikeCount > 0 ?
                    <View style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>
                        <Icon
                            color={'#b0b0b0'}
                            name={"heart"}
                            type="font-awesome"
                            size={12}
                            iconStyle={{ alignSelf: "center" }}
                            containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                        />
                        <Text
                            style={{
                                width: 15,
                                paddingLeft: 4,
                                fontSize: 14,
                                fontFamily: ConstantFontFamily.defaultFont,
                                color: "#6D757F"
                            }}
                        >{this.props?.props?.item?.node?.num_likes ? this.props?.props?.item?.node?.num_likes?.num_silver_likes + this.state.silverLikeCount : props?.item?.item?.node?.num_likes?.num_silver_likes + this.state.silverLikeCount}
                        </Text>
                    </View>
                    : null}




                <TouchableOpacity
                    style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}
                    onPress={() =>

                        this.increaseLikeCount(this.props?.props?.item?.item?.node?.id, 'RED')

                    }
                >
                    <Icon
                        color={"#de5246"}
                        name={"heart"}
                        type="font-awesome"
                        size={12}
                        // iconStyle={{ alignSelf: "center",}}
                        containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                    />
                    {props?.item?.item?.node?.num_likes?.num_red_likes > 0 || this.props?.props?.item?.node?.num_likes?.num_red_likes > 0 || this.state.redLikeCount > 0 ?
                        <Text
                            style={{
                                width: 15,
                                // textAlign: 'left',
                                paddingLeft: 4,
                                fontSize: 14,
                                fontFamily: ConstantFontFamily.defaultFont,
                                color: "#6D757F"
                            }}
                        >
                            {this.props?.props?.item?.node?.num_likes ? this.props?.props?.item?.node?.num_likes?.num_red_likes + this.state.redLikeCount : props?.item?.item?.node?.num_likes?.num_red_likes + this.state.redLikeCount}
                        </Text> : null}
                </TouchableOpacity>

                <TouchableOpacity
                    style={[ButtonStyle.heartIconBackground, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}
                    onPress={async () => {
                        // console.log(this.props?.props?.item?.item?.node?.title, 'this.props?.props?.item?.node.title');
                        // this.props.setPostCommentDetails({
                        //     // id: "Post:" + id,
                        //     title: this.props?.props?.item?.item?.node?.title,
                        //     // loading: props.item.item.node.num_comments > 0 ? true : falses
                        // });
                        this.props.focusCreateComment(true)
                        // console.log(this.props.type, 'this.props.type elsee');
                        if (this.props.type == "comment") {
                            // console.log('comment hitting');
                            if (this.props.props.loginStatus == 1) {
                                // console.log(this.props,'this.propsthis.props');
                                // console.log(this.props.item.id,'idsr');
                                await this.props.postCommentStatus(false)
                                await this.props.getRepliedId(this.props.item.id)
                                await this.props.openComment(this.props.item.id)
                                await this.props.focusCreateComment(true)
                                await this.props.setUserr(this.props.item.author.username)
                                await this.props.showbox(false)

                            } else {

                                await props.setLoginModalStatus(true)


                            }
                        } else {
                            console.log('post hitting');
                            await this.props.postCommentStatus(true)
                            await this.props.getCommentTitle(this.props?.props?.item?.item?.node?.title)
                            await this.props.showbox(true)
                            await this.props.handleDoubleTap()
                        }
                    }
                    }
                >
                    <Icon
                        color={ConstantColor.blueColor}
                        name={"comment"}
                        type="font-awesome"
                        size={12}
                        // iconStyle={{ alignSelf: "center",}}
                        containerStyle={{ alignSelf: "center", paddingVertical: 3 }}
                    />
                    {props?.item?.item?.node?.num_comments > 0 || (this.props.postCommentId == props.item?.item?.node?.id && this.props.totalComment > 0) ?
                        <Text
                            style={{
                                // width: 15,
                                // textAlign: 'left',
                                paddingLeft: 4,
                                fontSize: 14,
                                fontFamily: ConstantFontFamily.defaultFont,
                                color: "#6D757F"
                            }}
                        >
                            {this.props.type == "post" ? this.props.postCommentId == props.item?.item?.node?.id ? props?.item?.item?.node?.num_comments + this.props.totalComment :  props?.item?.item?.node?.num_comments  : this.props.type == "comment" ? this.props?.props.item?.node?.num_comments : 0}
                        </Text>
                        : null}
                </TouchableOpacity>

                <View style={{ position: "relative", alignItems: "center", flexDirection: 'row' }}>
                    {this.state.showTooltip && (this.props.openId == props?.item?.item?.node?.id || this.props.openId == this.props?.props?.item?.node?.id) ? <OuterClick
                        onOuterClick={() => {
                            this.setState({ showTooltip: false })
                            this.props.toolkitOff(true)
                        }}
                        style={{ flexDirection: "row", width: "100%", alignItems: "center" }}
                    ><TooltipBox increaseLikeCount={this.increaseLikeCount} type={this.props.type} commentId={this.props?.props?.item?.node?.id} postId={this.props?.props?.item?.item?.node?.id} /></OuterClick> : null}
                    {/* {this.state.showRedHeart ?
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 5 }}>
                            <Icon
                                color={'#de5246'}
                                name={"heart"}
                                type="font-awesome"
                                size={20}
                                iconStyle={{ alignSelf: "center", }}
                                containerStyle={{ alignSelf: "center" }}
                            />

                            {this.props?.props?.item?.node?.num_likes?.num_red_likes ?
                            <Text
                                style={{
                                    width: 15,
                                    paddingLeft: 4,
                                    fontSize: 14,
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    color: "#6D757F"
                                }}
                            >
                                {this.props?.props?.item?.node?.num_likes?.num_red_likes  }

                            </Text> : null}
                        </View> :
                        null} */}
                    <Icon
                        size={25}
                        iconStyle={{ fontWeight: '100' }}
                        name="add-circle-outline"
                        type="ion-icon"
                        color={"#899198"}
                        onPress={() => {
                            if (this.props.openId == props?.item?.item?.node?.id) {
                                this.setState({ showTooltip: !this.state.showTooltip })
                                // this.props.openToolkit(props?.item?.item?.node?.id)
                            } else if (this.props.openId == this.props?.props?.item?.node?.id) {
                                // this.setState({ showTooltip: true })
                                this.setState({ showTooltip: !this.state.showTooltip })
                                // this.props.openToolkit(props?.item?.node?.id)
                            } else {
                                this.setState({ showTooltip: true })

                            }
                            if (this.props.type == "post") {
                                this.props.openToolkit(props?.item?.item?.node?.id)
                            } else {
                                this.props.openToolkit(this.props?.props?.item?.node?.id)
                            }
                        }}
                    />
                </View>

            </View>
        )
    }
}
const mapStateToProps = (state) => ({

    openId: state.openTookitReducer.openId,
    totalComment: state.TotalCommentReducer.totalComment,
    nestedComment: state.NestedCommentReducer.nestedComment,
    PostId: state.PostCommentDetailsReducer.get("PostId"),
    postCommentId: state.PostCommentIdReducer.id




});
const mapDispatchToProps = dispatch => ({
    userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
    sharePostId: (payload) => dispatch({ type: "SHAREDPOSTID", payload }),
    setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
    openToolkit: (payload) => dispatch(openToolkit(payload)),
    focusCreateComment: (payload) =>
        dispatch({ type: "FOCUS_CREATE_COMMENT", payload }),
    LikeContent: payload => dispatch(setLikeContent(payload)),
    toolkitOff: (payload) => dispatch(toolkitOff(payload)),
    setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),
    showDiscussion12: (payload) => dispatch(showDiscussion(payload)),
    showbox: (payload) => dispatch(showbox(payload)),
    getCommentTitle: (payload) => dispatch(getCommentTitle(payload)),
    setUserr: (payload) => dispatch(userHighlight(payload)),
    getRepliedId: (payload) => dispatch(getRepliedId(payload)),
    postCommentStatus: (payload) => dispatch(postCommentStatus(payload))


});
export default compose(connect(mapStateToProps, mapDispatchToProps))(CommonTooltipBottom)
