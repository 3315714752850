import { useEffect } from "react";
import { Platform, Dimensions } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStackNavigator } from "react-navigation-stack";
import AddUsernameCard from "../components/AddUsernameCard";
import SignUpFollow from "../components/SignUpFollow";
import SignUpJoin from "../components/SignUpJoin";
import SignUpMessage from "../components/SignUpMessage";
import SignUpModalStep1 from "../components/SignUpModalStep1";
import VerifyEmail from "../components/VerifyEmail";
import AddFeedScreen from "../screens/AddFeedScreen";
import AnalyticsScreen from "../screens/AnalyticsScreen";
import BookMarksScreen from "../screens/BookMarksScreen";
import CliksProfileScreen from "../screens/CliksProfileScreen";
import CreateClikScreen from "../screens/CreateClikScreen";
import CreatePostScreen from "../screens/CreatePostScreen";
import CreateTopicScreen from "../screens/CreateTopicScreen";
import DashboardScreen from "../screens/DashboardScreen";
import EditClikScreen from "../screens/EditClikScreen";
import EditFeedScreen from "../screens/EditFeedScreen";
import EditPostScreen from "../screens/EditPostScreen";
import EditTopicScreen from "../screens/EditTopicScreen";
import ExternalFeedScreen from "../screens/ExternalFeedScreen";
import FAQScreen from "../screens/FAQScreen";
import NotFound from "../screens/NotFound";
import NotificationScreen from "../screens/NotificationScreen";
import PayoutScreen from "../screens/PayoutScreen";
import PostDetailsScreen from "../screens/PostDetailsScreen";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen";
import ProfileScreen from "../screens/ProfileScreen";
import SearchScreen from "../screens/SerachScreen";
import SettingsScreen from "../screens/SettingsScreen";
import TermsAndConditionsScreen from "../screens/TermsAndConditionsScreen";
import TopicHierarchyScreen from "../screens/TopicHierarchyScreen";
import TopicScreen from "../screens/TopicScreen";
import SettingsScreen1 from "../screens/SettingsScreen1";
import SignUpScreen from "../screens/SignUpScreen";
import CrashlyticsErrorBoundary from "../CrashlyticsErrorBoundary";
// import DemoScreen from '../screens/DemoScreen';

const TopicScreenOnly = props => {
  useEffect(async () => {
    let asyncId;
    await AsyncStorage.getItem('PostId').then(houses => { asyncId = JSON.parse(houses); });
    let postId = asyncId.replace("Post:", "")
    props.navigation.navigate("topicprofile", {
      title: props.navigation.state.params.id,
      type: "feed",
      postId: postId
    });
  });
  return null;
};

const ExternalFeedScreenOnly = props => {
  useEffect(async () => {
    let asyncId;
    await AsyncStorage.getItem('PostId').then(houses => { asyncId = JSON.parse(houses); });
    let postId = asyncId.replace("Post:", "")
    props.navigation.navigate("feedprofile", {
      title: props.navigation.state.params.id,
      type: "feed",
      postId: postId
    });
  });
  return null;
};

const UserProfileScreenOnly = props => {
  useEffect(async () => {
    let asyncId;
    await AsyncStorage.getItem('PostId').then(houses => { asyncId = JSON.parse(houses); });
    let postId = asyncId.replace("Post:", "")
    props.navigation.navigate("profile", {
      username: props.navigation.state.params.username,
      type: "feed",
      postId: postId
    });
  });
  return null;
};

const CliksProfileOnlyScreen = props => {
  useEffect(async () => {
    let asyncId;
    await AsyncStorage.getItem('PostId').then(houses => { asyncId = JSON.parse(houses); });
    let postId = asyncId.replace("Post:", "")
    props.navigation.navigate("cliksprofile", {
      id: props.navigation.state.params.id,
      type: "feed",
      postId: postId
    });
  });
  return null;
};

const publicClikJoinScreen = props => {
  useEffect(() => {
    props.navigation.navigate("SettingsScreen", {
      name: props.navigation.state.params.name
      // type: "feed"
    });
  });
  return null;
};
const Stack = createStackNavigator(
  {
    home: {
      screen: DashboardScreen,
      //screen: CrashlyticsErrorBoundary,
      path: ""
    },
    // demo :{
    //   screen: DemoScreen,
    //   path: "home"
    // },
    profile: {
      screen: ProfileScreen,
      path: "user/:username/:type/:postId"
    },
    profilereload: {
      screen: ProfileScreen,
      path: "user/:username/:type/"
    },
    profileOnly: {
      screen: UserProfileScreenOnly,
      path: "user/:username"
    },
    cliksprofile: {
      screen: CliksProfileScreen,
      path: "clik/:id/:type/:postId"
    },
    cliksprofilereload: {
      screen: CliksProfileScreen,
      path: "clik/:id/:type/"
    },
    cliksInviteprofile: {
      screen: CliksProfileScreen,
      path: "clik/:id/:type/:key"
    },
    cliksprofileOnly: {
      screen: CliksProfileOnlyScreen,
      path: "clik/:id"
    },

    // ========================================================
    // publicClikJoin: {
    //   screen: publicClikJoinScreen,
    //   path: "clik/:clikname/join"
    // },
    // =========================================================
    createpost: {
      screen: CreatePostScreen,
      path: "post"
    },
    info: {
      screen:  Dimensions.get('window').width >= 1200 ? DashboardScreen : PostDetailsScreen,
      // screen: DashboardScreen,
      path: "post/:id"
    },

    feedId: {
      screen: DashboardScreen,
      path: "post/:id"
    },
    commentID:{
      screen: DashboardScreen,
      path: "comment/:id"
    },
    topicprofile: {
      screen: TopicScreen,
      path: "topic/:title/:type/:postId"
    },
    topicprofilereload: {
      screen: TopicScreen,
      path: "topic/:title/:type/"
    },
    topic: {
      screen: TopicScreenOnly,
      path: "topic/:id"
    },
    feedprofile: {
      screen: ExternalFeedScreen,
      path: "feeds/:title/:type/:postId"
    },
    feedprofilereload: {
      screen: ExternalFeedScreen,
      path: "feeds/:title/:type/"
    },
    feed: {
      screen: ExternalFeedScreenOnly,
      path: "feeds/:id"
    },
    createclik: {
      screen: CreateClikScreen
    },
    settings: {
      screen: SettingsScreen
    },
    settings1: {
      screen: SettingsScreen1
    },
    signuppath: {
      screen: SignUpScreen,
      path: "invite/:username"
    },
    notification: {
      screen: NotificationScreen
    },
    search: {
      screen: SearchScreen,
      path: "search"
    },
    bookmark: {
      screen: BookMarksScreen
    },
    createtopic: {
      screen: CreateTopicScreen
    },
    termsandconditions: {
      screen: TermsAndConditionsScreen
    },
    privacyPolicy: {
      screen: PrivacyPolicyScreen
    },
    faq: {
      screen: FAQScreen
    },
    analytics: {
      screen: AnalyticsScreen
    },
    payout: {
      screen: PayoutScreen,
      path: "payout/token/"
    },
    addfeed: {
      screen: AddFeedScreen
    },
    topichierarchy: {
      screen: TopicHierarchyScreen
    },
    editpost: {
      screen: EditPostScreen,
      path: "editpost"
    },
    edittopic: {
      screen: EditTopicScreen,
      path: "edittopic"
    },
    editfeed: {
      screen: EditFeedScreen
    },
    editclik: {
      screen: EditClikScreen
    },
    username: {
      screen: AddUsernameCard,
      path: "signup/username"
    },
    credentials: {
      screen: SignUpModalStep1,
      path: "signup/credentials"
    },
    verification: {
      screen: VerifyEmail,
      path: "signup/verification"
    },
    join: {
      screen: SignUpJoin,
      path: "signup/join"
    },
    follow: {
      screen: SignUpFollow,
      path: "signup/follow"
    },
    message: {
      screen: SignUpMessage,
      path: "signup/message"
    },
    404: {
      screen: Platform.OS == "web" ? NotFound : DashboardScreen
    }
  },
  {
    header: null,
    headerTransparent: true,
    headerMode: "none"
  }
);

export default Stack;
