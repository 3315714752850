import { GET404_SUCCESS } from '../constants/Action';

const initialState = {
    is404: false
}

const get404Reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET404_SUCCESS: {
            return {
                is404: action.payload,
            };
        }
        default:
            return state;
    }
};

export default get404Reducer;