import { call, put, takeEvery } from "redux-saga/effects";
import applloClient from "../client";
import {
  TOPICS_PROFILE_DETAILS,
  TOPICS_PROFILE_DETAILS_FAILURE,
  TOPICS_PROFILE_DETAILS_SUCCESS
} from "../constants/Action";
import { TopicQuery } from "../graphqlSchema/graphqlMutation/TrendingMutation";
import NavigationService from "../library/NavigationService";
import { capitalizeFirstLetter } from "../library/Helper";
// import {
//   AsyncStorage
// } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { get404 } from '../reducers/action/Completed'


const getResponse = async req => {
  return await applloClient
    .query({
      query: TopicQuery,
      variables: {
        id: "Topic:" + req.id
      },
      fetchPolicy: "no-cache"
    })
    .then(res => res);
};

export function* TrendingTopicsProfileMiddleware({ payload }) {
  try {
    const response = yield call(getResponse, payload);
    if (response.data.node == null) {
      NavigationService.navigate("404");
      yield put(get404(true))
    } else{
    if (response.data.node.id == "Topic:None") {
      yield put({
        type: TOPICS_PROFILE_DETAILS_FAILURE,
        payload: {}
      });
      NavigationService.navigate("404");
      yield put(get404(true))

    } else {
      if (payload.type == "feed" || payload.type == "wiki") {
        yield put({
          type: TOPICS_PROFILE_DETAILS_SUCCESS,
          payload: response
        });

        AsyncStorage.setItem(
          'TopicTitle',
          JSON.stringify({ type: payload.type, TopicTitle: payload.id })
        );
        let postId;
        yield AsyncStorage.getItem('PostId').then(houses => { postId = JSON.parse(houses); });
        // console.log(postId,payload.id, '-------------------------------->');
        // NavigationService.navigate("cliksprofileId", {
        //   id: payload.id,
        //   type: payload.type,
        //   postId:postId
        // });
        NavigationService.navigate("topicprofile", {
          title: response.data.node.name,
          type: !payload.type ? "feed" : payload.type,
          postId: ' '
          //,
          //postId:postId
        });
        // NavigationService.navigate("topicprofile", {
        //   title: response.data.topic.name,
        //   type: !payload.type ? "feed" : payload.type
        // });
      } else {
        yield put({
          type: TOPICS_PROFILE_DETAILS_FAILURE,
          payload: {}
        });
        NavigationService.navigate("404");
        yield put(get404(true))

      }
    }
  }
  } catch (err) {
    yield put({
      type: TOPICS_PROFILE_DETAILS_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* TrendingTopicsProfileMiddlewarewareWatcher() {
  yield takeEvery(TOPICS_PROFILE_DETAILS, TrendingTopicsProfileMiddleware);
}
