
import { GETTITLE } from '../constants/Action';

const initialState = {
  getTitle: ''
}

const getCommentTitleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETTITLE: {
      console.log(action)
      return {
        getTitle: action.payload,
      };
    }
    default:
      return state;
  }
};

export default getCommentTitleReducer;