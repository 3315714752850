import React, { createRef } from "react";
import {
  Animated,
  Dimensions,
  FlatList,
  Platform,
  Text,
  View,
  YellowBox,
  ActivityIndicator,
} from "react-native";
import { Icon } from "react-native-elements";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { FlatGrid } from "react-native-super-grid";
import { connect } from "react-redux";
import { compose } from "recompose";
import applloClient from "../client";
import NewHeader from "../components/NewHeader";
import ConstantFontFamily from "../constants/FontFamily";
import {
  ExternalFeedMutation,
  HomeFeedMutation,
} from "../graphqlSchema/graphqlMutation/FeedMutation";
import {
  UserFeedMutation,
  getCommentPost
} from "../graphqlSchema/graphqlMutation/PostMutation";
import {
  ClikFeed,
  TopicFeed,
} from "../graphqlSchema/graphqlMutation/TrendingMutation";
import ShadowSkeleton from "./ShadowSkeleton";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import AdminCompactView from "../components/AdminCompactView";
import CompactFeedList from "../components/CompactFeedList";
import FeedList from "../components/FeedList";
import { feedFluctuation } from "../reducers/action/Completed";
import { setPostDetails } from "../actionCreator/PostDetailsAction";

let feedID = [];
class DiscussionHomeFeed extends React.PureComponent {
  state = {
    modalVisible: false,
    data: [],
    page: 1,
    loading: true,
    loadingMore: false,
    refreshing: false,
    pageInfo: null,
    apiCall: true,
    activeFeed: -1,
    showEmptyIcon: false,
    activeId: "",
    activeTitle: "",
    loadSkeleton: false,
  };

  constructor(props) {
    super(props);
    YellowBox.ignoreWarnings(["VirtualizedLists should never be nested"]);
    this.viewabilityConfig = {
      viewAreaCoveragePercentThreshold: 50,
    };
    this.baseState = this.state;
    this.flatListRef = createRef();
  }



  componentDidMount = async () => {
    await this.props.doScroll(this.flatListRef, "discussion");
    if (this.props.listType == "Home") {

      var id1 = window.location.href.toString().split("/");
      let newId = id1[id1.length - 1];
      let newId1 = newId.replace("#", "")

      if (Platform.OS != "web") {
        this.props.setPostDetails({
          title: null,
          id: "Post:" + newId1,
          navigate: false,
        });

      }
      if (Platform.OS == "web") {
        window.addEventListener(
          "unload",
          function () {
            var count = parseInt(sessionStorage.getItem("counter") || 0);

            sessionStorage.setItem("counter", ++count);
          },
          false
        );


        if (sessionStorage.getItem("counter") == 1) {
          this.props.feedFluctuation(false);
        } else if (sessionStorage.getItem("counter") >= 2) {
          if (
            window.location.href == "http://localhost:19006" ||
            window.location.href ==
            "http://electric-block-241402.appspot.com" ||
            window.location.href ==
            "https://electric-block-241402.appspot.com" ||
            window.location.href == "https://weclikd-beta.com" ||
            window.location.href == "http://weclikd-beta.com" ||
            window.location.href == "https://electric-block-241402.appspot.com"
          ) {

            this.props.feedFluctuation(true);

          } else {
            this.props.feedFluctuation(false);
          }
        } else {
          if (
            window.location.href == "http://localhost:19006" ||
            window.location.href ==
            "http://electric-block-241402.appspot.com" ||
            window.location.href ==
            "https://electric-block-241402.appspot.com" ||
            window.location.href == "https://weclikd-beta.com" ||
            window.location.href == "http://weclikd-beta.com" ||
            window.location.href == "https://electric-block-241402.appspot.com"
          ) {

            this.props.feedFluctuation(true);

          } else if (
            window.location.href == `http://localhost:19006/post/${newId1}` ||
            window.location.href ==
            `http://electric-block-241402.appspot.com/post/${newId1}` ||
            window.location.href ==
            `https://electric-block-241402.appspot.com/post/${newId1}` ||
            window.location.href == `https://weclikd-beta.com/post/${newId1}` ||
            window.location.href == `http://weclikd-beta.com/post/${newId1}` ||
            window.location.href == `https://electric-block-241402.appspot.com/post/${newId1}`) {
            this.props.feedFluctuation(false);




          } else if (
            window.location.href == `http://localhost:19006/comment/${newId1}` ||
            window.location.href ==
            `http://electric-block-241402.appspot.com/comment/${newId1}` ||
            window.location.href ==
            `https://electric-block-241402.appspot.com/comment/${newId1}` ||
            window.location.href == `https://weclikd-beta.com/comment/${newId1}` ||
            window.location.href == `http://weclikd-beta.com/comment/${newId1}` ||
            window.location.href == `https://electric-block-241402.appspot.com/comment/${newId1}`
          ) {
            this.props.feedFluctuation(false);

          }
        }
      }


      this._fetchAllHomeFeeds();
    } else if (this.props.listType == "Clik") {
      this._fetchAllClikFeeds();
    } else if (this.props.listType == "Topic") {
      this._fetchAllTopicFeeds();
    } else if (this.props.listType == "User") {
      this._fetchAllUserFeeds();
    } else if (this.props.listType == "Feed") {
      this._fetchAllExternalFeeds();
    }

    let data = document.getElementById("DiscussionHomeFeed");
    if (data != null) {
      await data.addEventListener("keydown", (event) => {
        if (event.keyCode == 83 || event.keyCode == 40) {
          this.setState({
            activeFeed: this.state.activeFeed + 1,
          });
        }
        if (event.keyCode == 87 || event.keyCode == 38) {
          this.setState({
            activeFeed: this.state.activeFeed - 1,
          });
        }
        if (event.keyCode == 39 || event.keyCode == 68) {
          this.setState({
            activeFeed: this.state.activeFeed + 1,
          });
        }
        if (event.keyCode == 37 || event.keyCode == 65) {
          this.setState({
            activeFeed: this.state.activeFeed - 1,
          });
        }
        if (this.state.activeFeed < this.getUnique(feedID).length) {
          this.setBorderColor();
        }
      });
    }
    if (this.props.changeLoadTab && this.state.loadSkeleton == false) {
      this.props.changeLoadTab();
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.listType == "Topic" && prevProps.data != this.props.data) {
      await this.setState(this.baseState);
      this._fetchAllTopicFeeds();
    } else if (
      this.props.listType == "Clik" &&
      prevProps.data != this.props.data
    ) {
      await this.setState(this.baseState);
      this._fetchAllClikFeeds();
    } else if (
      this.props.listType == "User" &&
      prevProps.data != this.props.data
    ) {
      await this.setState(this.baseState);
      this._fetchAllUserFeeds();
    } else if (
      this.props.listType == "Feed" &&
      prevProps.data != this.props.data
    ) {
      await this.setState(this.baseState);
      this._fetchAllExternalFeeds();
    }

    if ((Platform.OS = "web")) {
      if (this.props.listType == "Home") {
        if (
          window.location.href == "http://localhost:19006/" ||
          window.location.href == "http://electric-block-241402.appspot.com/" ||
          window.location.href ==
          "https://electric-block-241402.appspot.com/" ||
          window.location.href == "http://localhost:19006/post/0" ||
          window.location.href == "https://weclikd-beta.com/" ||
          window.location.href == "http://weclikd-beta.com/"
        ) {
          this.props.feedFluctuation(true);
        }
      }
    }

    setTimeout(() => {
      if (
        this.state.activeId == "" &&
        (this.props.ActiveTab == "Bookmarks" ||
          this.props.ActiveTab == "Discussing") &&
        this.state.data.length > 0
      ) {
        this.makeHighlight(
          this.state.data[0].node.id,
          this.state.data[0].node.title
        );
        this.setBorderColor();
      } else if (this.state.data.length == 0) {
        this.props.setPostCommentReset({
          payload: [],
          postId: "",
          title: "",
          loading: true,
        });
      }
    }, 2000);
    if (this.props.changeLoadTab && this.state.loadSkeleton == false) {
      this.props.changeLoadTab();
    }
  };

  setBorderColor = async () => {
    for (let i = 0; i < this.getUnique(feedID).length; i++) {
      let data = document.getElementById(this.getUnique(feedID)[i]);
      if (data != null && Dimensions.get("window").width >= 1000) {
        if (i == this.state.activeFeed) {
          document.getElementById(this.getUnique(feedID)[i]).click();
        }
      }
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.componentDidMount());
  };

  _handleLoadMore = (distanceFromEnd) => {
    this.setState(
      () => {
        if (
          0 <= distanceFromEnd &&
          distanceFromEnd <= 5 &&
          this.state.apiCall == true
        ) {
          this.setState({
            apiCall: false,
          });
          if (this.props.listType == "Home") {
            this._fetchAllHomeFeeds();
          } else if (this.props.listType == "Clik") {
            this._fetchAllClikFeeds();
          } else if (this.props.listType == "Topic") {
            this._fetchAllTopicFeeds();
          } else if (this.props.listType == "User") {
            this._fetchAllUserFeeds();
          } else if (this.props.listType == "Feed") {
            this._fetchAllExternalFeeds();
          }
        }
      }
    );
  };

  _fetchAllHomeFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: HomeFeedMutation,
          variables: {
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "DISCUSSING",
            type: 'Post'
          },
          fetchPolicy: "no-cache",
        })
        .then(async (response) => {
          if (this.props.isfeed == true) {
            let id = response.data.node_list.edges[0].node.id.replace(
              "Post:",
              ""
            );

            await this.props.setUpdatedPostId(
              ""// response.data.node_list.edges[0].node.id
            );
            await this.props.setPostCommentReset({
              payload: [],
              postId: "",
              title: "",
              loading: true,
            });
            await this.props.setPostCommentDetails({
              id:"",
              title: "",
              loading: false,
            });
          } else {
            let uri = window.location.href.toString().search('post')
            if (uri != -1) {
              this.props.setPostCommentReset({
                payload: [],
                postId: "",
                title: "",
                loading: true,
              });
              var id11 = window.location.href.toString().split("/");
              let newId1 = id11[id11.length - 1];
              this.props.setPostCommentDetails({
                id: "Post:" + newId1,
                title: '',
                loading: false,
              });

            } else {
              var id1 = window.location.href.toString().split("/");
              let newId = id1[id1.length - 1];

              applloClient
                .query({
                  query:
                    getCommentPost,
                  variables: {
                    id: "Comment:" + newId,
                    depth: 10,
                    sort_type: "DISCUSSING",
                  },
                  fetchPolicy: "no-cache",
                }).then(e => {
                  this.props.setPostCommentReset({
                    payload: [],
                    postId: "",
                    title: "",
                    loading: true,
                  });
                  this.props.setPostCommentDetails({
                    id: "Post:" + e?.data?.node?.post?.id?.replace("Post:", ""),
                    title: e?.data?.node?.post?.title,
                    loading: false,
                  });
                  this.props.setUpdatedPostId(e?.data?.node?.post?.id);
                })

            }
          }

          await __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data.node_list.edges),
            pageInfo: response.data.node_list.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
            showEmptyIcon:
              response.data.node_list.edges.length == 0 ? true : false,
            loadSkeleton: false,
          });
          this.props.setDiscussionHomeFeed(response.data.node_list.edges);
          __self.props.setPrevList(
            {
              isLoading: false,
              loading: false,
              data: Array.from(response.data.node_list.edges),
              pageInfo: response.data.node_list.pageInfo,
              page: page + 1,
              apiCall: true,
              loadingMore: false,
              refreshing: false,
            },
            "discussion"
          );
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: HomeFeedMutation,
          variables: {
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "DISCUSSING",
            type: 'Post'
          },
          fetchPolicy: "no-cache",
        })
        .then(async (response) => {
          await __self.setState({
            isLoading: false,
            loading: false,
            data: __self.state.data.concat(response.data.node_list.edges),
            pageInfo: response.data.node_list.pageInfo,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
          });
          this.props.setDiscussionHomeFeed(
            __self.state.data.concat(response.data.node_list.edges)
          );
          __self.props.setPrevList(
            {
              isLoading: false,
              loading: false,
              data: __self.state.data.concat(
                response.data.node_list.edges
              ),
              pageInfo: response.data.node_list.pageInfo,
              apiCall: true,
              loadingMore: false,
              refreshing: false,
            },
            "discussion"
          );
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };

  _fetchAllTopicFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: TopicFeed,
          variables: {
            id: "Topic:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "DISCUSSING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data.node.posts.edges),
            pageInfo: response.data.node.posts.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore:
              response.data.node.posts.pageInfo != null &&
                response.data.node.posts.pageInfo.hasNextPage == true
                ? true
                : false,
            refreshing: false,
            showEmptyIcon:
              response.data.node.posts.edges.length == 0 ? true : false,
            loadSkeleton: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: TopicFeed,
          variables: {
            id: "Topic:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "DISCUSSING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: __self.state.data.concat(response.data.node.posts.edges),
            pageInfo: response.data.node.posts.pageInfo,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };

  _fetchAllClikFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: ClikFeed,
          variables: {
            id: "Clik:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "DISCUSSING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.props.setTrendingClikHomeFeed(response.data.node.posts.edges)

          this.props.setPostCommentReset({
            payload: [],
            postId: '',
            title: '',
            loading: true
          });
          this.props.setPostCommentDetails({
            id: response.data.node.posts.edges.length > 0 && "Post:" + response.data.node.posts.edges[0].node.id.replace("Post:", ""),
            title: response.data.node.posts.edges.length > 0 && response.data.node.posts.edges[0].node.title,
            loading: false
          });

          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data.node.posts.edges),
            pageInfo: response.data.node.posts.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
            showEmptyIcon:
              response.data.node.posts.edges.length == 0 ? true : false,
            loadSkeleton: false,
            // isLoading: false
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        isLoading: false,
      });
      applloClient
        .query({
          query: ClikFeed,
          variables: {
            id: "Clik:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "DISCUSSING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: __self.state.data.concat(response.data.node.posts.edges),
            pageInfo: response.data.node.posts.pageInfo,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };

  _fetchAllUserFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: UserFeedMutation,
          variables: {
            id: "User:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "DISCUSSING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          console.log(response, 'response');
          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data.node.posts.edges),
            pageInfo: response.data.node.posts.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore:
              response.data.node.posts.pageInfo != null &&
                response.data.node.posts.pageInfo.hasNextPage == true
                ? true
                : false,
            refreshing: false,
            showEmptyIcon:
              response.data.node.posts.edges.length == 0 ? true : false,
            loadSkeleton: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: UserFeedMutation,
          variables: {
            id: "User:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "DISCUSSING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: __self.state.data.concat(response.data.node.posts.edges),
            pageInfo: response.data.node.posts.pageInfo,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };

  _fetchAllExternalFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: ExternalFeedMutation,
          variables: {
            id: "ExternalFeed:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "DISCUSSING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data.node.posts.edges),
            pageInfo: response.data.node.posts.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore:
              response.data.node.posts.pageInfo != null &&
                response.data.node.posts.pageInfo.hasNextPage == true
                ? true
                : false,
            refreshing: false,
            showEmptyIcon:
              response.data.node.posts.edges.length == 0
                ? true
                : false,
            loadSkeleton: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: ExternalFeedMutation,
          variables: {
            id: "ExternalFeed:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "DISCUSSING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: __self.state.data.concat(
              response.data.node.posts.edges
            ),
            pageInfo: response.data.node.posts.pageInfo,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };

  _handleRefresh = () => {
    this.setState(
      {
        page: 1,
        refreshing: true,
      },
      () => {
        if (this.props.listType == "Home") {
          this._fetchAllHomeFeeds();
        } else if (this.props.listType == "Clik") {
          this._fetchAllClikFeeds();
        } else if (this.props.listType == "Topic") {
          this._fetchAllTopicFeeds();
        } else if (this.props.listType == "User") {
          this._fetchAllUserFeeds();
        } else if (this.props.listType == "Feed") {
          this._fetchAllExternalFeeds();
        }
      }
    );
  };

  _renderFooter = () => {
    if (!this.state.loadingMore)
    return (
      <View
        style={{
          // borderWidth: this.props.noPost == true ? 0 : 1,
          borderColor: "#d7d7d7",
          marginRight: 1,
          borderBottomWidth: 0,
          borderTopWidth: 0,
          // height: Dimensions.get('window').height/5,
        }}
      >
        <Text
          style={{
            fontFamily: ConstantFontFamily.defaultFont,
            fontSize: 13,
            textAlign: "center",
            paddingVertical: 10,
            color: "grey",
          }}
        >
          {(!this.state.isLoading && this.state.data?.length > 0) || (this.props.isfeed && (!this.state.showEmptyIcon || this.state.data?.length > 1)) || (!this.state.HomeFeedError && this.state.data?.length > 0)
            ? "No more posts"
            : null}
        </Text>
      </View>
    );

  if (this.state.loadingMore || this.state.isLoading)
    return (
      <View style={{ marginVertical: 20 }}>
        <ActivityIndicator animating size="large" color="#000" />
      </View>
    );
};

  _renderItem = (item) => {
    this.getfeedID("Bookmarks" + item.item.node.id);
    if (this.props.ViewMode != "Compact") {
      return this.props.isAdmin == true ? (
        this.state.loadSkeleton == true ? (
          <ShadowSkeleton />
        ) : (
          <FeedList
            loginModalStatusEventParent={this.handleModal}
            item={item}
            navigation={this.props.navigation}
            ViewMode={this.props.ViewMode}
            highlight={this.makeHighlight}
            tabPost={this.props.tabPost}
            onLoadingComment={this.props.onLoadingComment}
            changeTabStatus={this.props.changeTabStatus}
            ActiveTab="Bookmarks"
            deleteItem={this.deleteItemById}
            data={this.state.data}
            index={this.props.index}
          />
        )
      ) :
        this.state.loadSkeleton == true ? (
          <ShadowSkeleton />
        ) : (
          <FeedList
            loginModalStatusEventParent={this.handleModal}
            item={item}
            navigation={this.props.navigation}
            ViewMode={this.props.ViewMode}
            highlight={this.makeHighlight}
            tabPost={this.props.tabPost}
            onLoadingComment={this.props.onLoadingComment}
            changeTabStatus={this.props.changeTabStatus}
            ActiveTab="Bookmarks"
            deleteItem={this.deleteItemById}
            data={this.state.data}
            index={this.props.index}
          />
        );
    } else {
      return this.props.isAdmin == true ? (
        <AdminCompactView
          loginModalStatusEventParent={this.handleModal}
          item={item}
          navigation={this.props.navigation}
          ViewMode={this.props.ViewMode}
          highlight={this.makeHighlight}
          data={this.state.data}
        />
      ) : (
        <CompactFeedList
          loginModalStatusEventParent={this.handleModal}
          item={item}
          navigation={this.props.navigation}
          ViewMode={this.props.ViewMode}
          highlight={this.makeHighlight}
          data={this.state.data}
        />
      );
    }
  };

  deleteItemById = (id) => {
    const filteredData = this.state.data?.filter((item) => item.node.id !== id);
    this.setState({ data: filteredData });
  };

  makeHighlight = async (id, title) => {
    let newId = id.search("Bookmarks") != -1 ? id : "Bookmarks" + id;
    this.setState({
      activeFeed: this.getUnique(feedID).indexOf(newId),
      activeId: id,
      activeTitle: title,
    });
  };
  handleModal = (status) => {
    this.setState({
      modalVisible: status,
    });
  };


  getfeedID = async (id) => {
    let data = feedID;
    await data.push(id);
    feedID = data;
  };

  getUnique = (array) => {
    var uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  };

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    let perLoadDataCount = 24;
    let halfOfLoadDataCount = perLoadDataCount / 2;
    let lastAddArr = this.state.data.slice(-perLoadDataCount);
    try {
      if (
        lastAddArr[halfOfLoadDataCount] &&
        viewableItems.length > 0 &&
        this.props.loginStatus == 1
      ) {
        if (Array.isArray(viewableItems[0].item)) {
          if (
            viewableItems[0].item.find(
              (item) => item.node.id === lastAddArr[halfOfLoadDataCount].node.id
            )
          ) {
            this._handleLoadMore(0);
          }
        }
        else {
          if (
            viewableItems.find(
              (item) =>
                item.item.node.id === lastAddArr[halfOfLoadDataCount].node.id
            )
          ) {
            this._handleLoadMore(0);
          }
        }
      }
    } catch (e) {
      console.log(e, "lastAddArr", lastAddArr[halfOfLoadDataCount]);
    }
  };

  render() {
    return (
      <View
        nativeID={"DiscussionHomeFeed"}
        style={{
          marginTop: Dimensions.get("window").width <= 750 ? 0 : 0,
          flex: 1,
        }}
      >
        {this.state.data.length == 0 && this.state.showEmptyIcon == false && (
          <ShadowSkeleton />
        )}
        {
          this.state.showEmptyIcon == true && (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 20,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                marginRight: 1,
                borderColor: "#d7d7d7",
              }}
            >
              <Icon
                color={"#000"}
                iconStyle={{
                  color: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                }}
                reverse
                name="file"
                type="font-awesome"
                size={20}
                containerStyle={{
                  alignSelf: "center",
                }}
              />
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  fontFamily: ConstantFontFamily.defaultFont,
                  color: "#000",
                  alignSelf: "center",
                }}
              >
                No posts
              </Text>
            </View>
          )
        }
        {(this.props.ViewMode == "Default" ||
          this.props.ViewMode == "Text") && (
            <FlatList
              ref={this.flatListRef}
              data={this.props.isfeed == true ? this.state.data : this.state.data.slice(0, 1)}
              disableVirtualization={true}
              windowSize={20}

              onScroll={(event) => {
                this.props.listType != "Home"
                  ? this.props.listScroll(event.nativeEvent.contentOffset.y)
                  : null;
              }}
              scrollEventThrottle={16}
              keyExtractor={(item, index) => item.node.id}
              renderItem={this._renderItem}
              showsVerticalScrollIndicator={false}
              onRefresh={this._handleRefresh}
              refreshing={this.state.refreshing}
              onEndReachedThreshold={0.2}
              initialNumToRender={8}
              ListFooterComponent={this._renderFooter}
              onViewableItemsChanged={this.onViewableItemsChanged}
              viewabilityConfig={this.viewabilityConfig}
              removeClippedSubviews={false}
            />
          )}

        {this.props.ViewMode == "Card" && (
          <FlatGrid
            ref={this.flatListRef}
            itemDimension={250}
            contentContainerStyle={{ flex: 1 }}
            items={this.state.data}
            renderItem={this._renderItem}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            onScroll={(event) => {
              this.props.listType != "Home"
                ? this.props.listScroll(event.nativeEvent.contentOffset.y)
                : null;
            }}
            scrollEventThrottle={16}
            spacing={10}
            style={{
              flexDirection: "column",
              height: hp("100%") - 50,
              paddingTop: 0,
            }}
            itemContainerStyle={{
              justifyContent: "flex-start",
            }}
            onRefresh={this._handleRefresh}
            refreshing={this.state.refreshing}
            onEndReachedThreshold={0.2}
            ListFooterComponent={this._renderFooter}
            onViewableItemsChanged={this.onViewableItemsChanged}
            viewabilityConfig={this.viewabilityConfig}
            removeClippedSubviews={true}
          />
        )}
        {this.props.ViewMode == "Compact" && (
          <FlatList
            ref={this.flatListRef}
            contentContainerStyle={{
              flexDirection: "column",
              flex: 1,
              height: Dimensions.get("window").height,
              width: "100%",
            }}
            data={this.state.data}
            onScroll={(event) => {
              this.props.listType != "Home"
                ? this.props.listScroll(event.nativeEvent.contentOffset.y)
                : null;
            }}
            scrollEventThrottle={16}
            keyExtractor={(item, index) => index.toString()}
            renderItem={this._renderItem}
            showsVerticalScrollIndicator={false}
            onRefresh={this._handleRefresh}
            refreshing={this.state.refreshing}
            onEndReachedThreshold={0.2}
            ListFooterComponent={this._renderFooter}
            onViewableItemsChanged={this.onViewableItemsChanged}
            viewabilityConfig={this.viewabilityConfig}
            removeClippedSubviews={true}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.get("loginStatus"),
  isAdmin: state.AdminReducer.get("isAdmin"),
  isfeed: state.AdminReducer.get("isfeed"),
});

const mapDispatchToProps = (dispatch) => ({
  setPostDetails: (payload) => dispatch(setPostDetails(payload)),
  setDiscussionHomeFeed: (payload) =>
    dispatch({ type: "SET_DISCUSSION_HOME_FEED", payload }),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
  setPostCommentReset: (payload) =>
    dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  feedFluctuation: (payload) => dispatch(feedFluctuation(payload)),
  setTrendingClikHomeFeed: (payload) =>
    dispatch({ type: "SET_TRENDING_CLIK_HOME_FEED", payload }),
  setUpdatedPostId: (payload) =>
    dispatch({ type: "SET_UPDATED_POSTID", payload }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DiscussionHomeFeed
);
