export default {
    UserReducer: {
        loginStatus: 0
    },
    SignUpReducer: {
        signupStatus: 1
    },
    LoginUserDetailsReducer: {
        userLoginDetails: null
    },
    HomeFeedReducer: {
        HomefeedList: [],
        HomefeedListPagination: {}
    },
    UserProfileDetailsReducer: {
        getCurrentUserProfileDetails: {}
    },
    TrendingUsersReducer: {
        Trending_users_List: []
    },
    TrendingCliksReducer: {
        Trending_cliks_List: []
    },
    UserFeedReducer: {
        UserFeedList: null
    },
    FollowUserReducer: {
        getFollowUser: []
    },
    PostDetailsReducer: {
        PostDetails: null
    },
    TrendingCliksProfileReducer: {
        getTrendingCliksProfileDetails: {}
    },
    CliksFeedReducer: {
        CliksFeedList: null,
        CliksFeedListPagination: null
    },
    FollowCliksReducer: {
        getFollowCliks: []
    }
};
