// import firebase from "firebase/app";
// import "firebase/auth";
import React, { Component } from "react";
import {
  Image,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Platform,
  Dimensions
} from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setRESETPASSWORDMODALACTION } from "../actionCreator/ResetPasswordModalAction";
import ButtonStyle from "../constants/ButtonStyle";
import ConstantFontFamily from "../constants/FontFamily";
import { Button } from "react-native-elements";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserEmail: "",
      fbresponse: "",
      buttonText: "Request Reset"
    };
  }

  resetPassword = async () => {
    let __self = this;
    const { UserEmail } = this.state;
    let auth = firebase.auth();
    let emailAddress = UserEmail;
    if (this.state.buttonText == "Request Reset") {
      await auth
        .sendPasswordResetEmail(emailAddress)
        .then(async function (res) {
          await __self.setState({
            fbresponse: "Password reset link sent to email."
          });
        })
        .catch(async function (error) {
          await __self.setState({
            fbresponse: "You'll receive an email with the reset link shortly."
          });
        });
      await this.setState({
        UserEmail: "",
        buttonText: "Continue"
      });
    } else {
      this.props.onClose();
    }
  };

  onClose = () => {
    this.props.setResetPasswordModalStatus(false);
  };

  render() {
    const { UserEmail, fbresponse } = this.state;
    return (
      <View
        style={{
          overflow: 'hidden',
          borderRadius: 6,
          width: Dimensions.get('window').width >= 750 ? 450 : '100%',
          
        }}
      >
        <Hoverable>
          {isHovered => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                flex: 1,
                position: "absolute",
                zIndex: 999999,
                left: 0,
                top: 0
              }}
              onPress={this.onClose}
            >
              <Icon
                color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                iconStyle={{
                  color: "#fff",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                reverse
                name="close"
                type="antdesign"
                size={16}
              />
            </TouchableOpacity>
          )}
        </Hoverable>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#000",
            alignItems: "center",
            height: 50,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
          }}
        >
          <Image
            source={require("../assets/image/logo.png")}
            style={{
              height: 38,
              width: 115,
              marginRight: 5
            }}
            resizeMode={"contain"}
          // source={
          //   Platform.OS == "web" &&
          //     this.props.getCurrentDeviceWidthAction > 750
          //     ? require("../assets/image/weclickd-logo.png")
          //     : Platform.OS == "web"
          //       ? require("../assets/image/weclickd-logo.png")
          //       : require("../assets/image/weclickd-logo-only-icon.png")
          // }
          // style={
          //   Platform.OS == "web" &&
          //     this.props.getCurrentDeviceWidthAction > 750
          //     ? {
          //       height: 30,
          //       width: Platform.OS == "web" ? 90 : 30,
          //       padding: 0,
          //       margin: 0,
          //       marginVertical: 10
          //     }
          //     : {
          //       height: 30,
          //       width: Platform.OS == "web" ? 90 : 30,
          //       padding: 0,
          //       margin: 0,
          //       marginVertical: 10
          //     }
          // }
          />
        </View>
        <View
          style={{
            overflow: "visible",
            width: "100%",
            backgroundColor: "#fff",
            // alignItems: "center",
            justifyContent: "center",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 20,
              fontFamily: ConstantFontFamily.defaultFont,
              marginTop: 30,
              textAlign: "center"
            }}
          >
            Forgot Password
          </Text>
          {/* <Text
            style={{
              fontWeight: "bold",
              fontSize: 25,
              fontFamily: ConstantFontFamily.defaultFont,
              marginTop: 10,
              textAlign: "center"
            }}
          >
            Password
          </Text> */}
          <ScrollView
            style={{
              backgroundColor: "#fff"
            }}
          >
            <View style={{ margin: 10, marginHorizontal: 20 }}>
              <View
                style={{
                  marginVertical: 20
                }}
              >
                <TextInput
                  value={UserEmail}
                  onChangeText={UserEmail => this.setState({ UserEmail })}
                  underlineColorAndroid="transparent"
                  placeholder={"Email"}
                  style={[ButtonStyle.textAreaShadowStyle, {

                    height: 45,
                    paddingLeft: 10,                    
                    color: "#000",
                    fontFamily: ConstantFontFamily.defaultFont,
                    width: "100%",
                    fontSize: this.state.tempSize
                  }]}
                />
              </View>
              {/* <TouchableOpacity
                block
                style={{
                  marginTop: 20,
                  backgroundColor: "#000",
                  borderRadius: 5,
                  height: 40,
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 5
                }
              }
                onPress={this.resetPassword}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont,
                    color: "#fff"
                  }}
                >
                  {this.state.buttonText}
                </Text>
              </TouchableOpacity> */}

              <Button
                title={this.state.buttonText}
                titleStyle={ButtonStyle.titleStyle}
                buttonStyle={ButtonStyle.backgroundStyle}
                containerStyle={ButtonStyle.containerStyle}
                onPress={this.resetPassword}
              />
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont,
                  color: "red",
                  marginVertical: 10
                }}
              >
                {fbresponse}
              </Text>
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension")
});

const mapDispatchToProps = dispatch => ({
  setResetPasswordModalStatus: payload =>
    dispatch(setRESETPASSWORDMODALACTION(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ResetPassword
);
