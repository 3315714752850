import React, { lazy, Component, Suspense } from "react";

import { graphql } from "react-apollo";
import {
  Animated,
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableHighlight,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Icon, Tooltip } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import { getHomefeedList } from "../actionCreator/HomeFeedAction";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import { setPostDetails } from "../actionCreator/PostDetailsAction";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
import applloClient from "../client";
import AppHelper from "../constants/AppHelper";
import ButtonStyle from "../constants/ButtonStyle";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import { PostEditMutation } from "../graphqlSchema/graphqlMutation/PostMutation";
import {
  SearchClikMutation,
  SearchTopicMutation
} from "../graphqlSchema/graphqlMutation/SearchMutation";
import {
  PostCreateVariables,
  PostEditVariables
} from "../graphqlSchema/graphqlVariables/PostVariables";
import {
  SearchClikVariables,
  SearchTopicVariables
} from "../graphqlSchema/graphqlVariables/SearchVariables";

const HeaderRight = lazy(() => import("../components/HeaderRight"))


class EditPostScreen extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.Pagescrollview = null;
    this.state = {
      uploading: false,
      selectedItems: [],
      title: "",
      text: "",
      uploadMutipleImagePost: [],
      changeBackPicEnable: { uri: "" },
      showsVerticalScrollIndicatorView: false,
      currentScreentWidth: 0,
      conditionIcon: false,

      summary: "",
      selectedTopics: [],
      TopicInputwidth: 400,
      titleHover: false,
      summaryHover: false,
      topic: "",
      TopicList: [],
      clik: "",
      ClikList: [],
      selectedCliks: [],
      id: "",
      quickTopicArray: [],
      quickClikArray: [],
      focusTopic: false,
      focusTitle: false,
      focusSummary: false,
    };
    this.items = [];
    this.changeBannerImage = "";
    this.baseState = this.state;
  }

  componentDidMount = async () => {
    this.props.searchOpenBarStatus(false);
    let tempTopicArray = this.props.getUserFollowTopicList?.slice(0, 5);
    this.setState({ quickTopicArray: tempTopicArray });

    let tempClikArray = this.props.getUserFollowCliksList?.slice(0, 5);
    this.setState({ quickClikArray: tempClikArray });

    this._isMounted = true;
    let joined = [];
    this.props.getUserFollowCliksList?.map(async (value, index) => {
      if (value.node.member_type == "SUPER_ADMIN") {
        await joined.push({
          id: value.node.clik.id,
          name: value.node.clik.name,
        });
      }
    });
    this.items = joined;

    if (this.props.postEditDetails) {
      const {
        id,
        title,
        text,
        summary,
        link,
        thumbnail_pic,
        topics,
        cliks
      } = this.props.postEditDetails;
      this.items = Array.isArray(cliks) ? cliks : [];

      this.setState({
        id,
        title,
        text,
        summary: summary ? summary : "",
        link,
        changeBackPicEnable: {
          uri: thumbnail_pic,
          id: thumbnail_pic
            ? thumbnail_pic
              .split("/")
            [thumbnail_pic.split("/").length - 1].substring(
              0,
              thumbnail_pic
                .split("/")
              [thumbnail_pic.split("/").length - 1].indexOf(".")
            )
            : 0
        },
        selectedTopics: Array.isArray(topics)
          ? topics.map(topic => ({ name: topic }))
          : [],
        selectedCliks: Array.isArray(cliks)
          ? cliks.map(cliks => ({ name: cliks }))
          : []
      });
    }
  };

  componentDidUpdate() {
    if (this.props.getHasScrollTop == true && this.Pagescrollview) {
      this.Pagescrollview.scrollTo({ x: 0, y: 0, animated: true });
      this.props.setHASSCROLLEDACTION(false);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState(this.baseState);
  }

  onSave = async () => {
    let __self = this;
    new Promise(async (resole, reject) => {
      let uploadImageTotal = [];
      if (this.state.uploadMutipleImagePost.length > 0) {
        this.state.uploadMutipleImagePost.forEach(async (element, index) => {
          uploadImageTotal.push(element.id);
        });
      }
      PostEditVariables.variables.title = this.state.title;
      PostEditVariables.variables.text = this.state.text;
      PostEditVariables.variables.summary = this.state.summary;
      PostEditVariables.variables.link = this.state.link;
      PostEditVariables.variables.content_id = this.state.id;

      if (this.state.changeBackPicEnable) {
        PostEditVariables.variables.thumbnail_pic = this.state
          .changeBackPicEnable.id
          ? this.state.changeBackPicEnable.id
          : "";
      }
      if (PostCreateVariables.variables.pictures.length == 0) {
        PostEditVariables.variables.pictures = uploadImageTotal;
      }

      if (this.state.selectedTopics.length > 0) {
        let newUpdateTopicArray = [];
        this.state.selectedTopics.forEach(async (element, index) => {
          await newUpdateTopicArray.push(element.name);
        });
        PostEditVariables.variables.topics = newUpdateTopicArray;
      }

      this.changeBannerImage = null;

      let resData = await this.props.PostCreate(PostEditVariables);
      await __self.props.getHomefeed({
        currentPage: AppHelper.PAGE_LIMIT
      });

      resole(resData);
    })
      .then(async res => {
        new Promise(async (resolve, reject) => {
          await __self.props.getTrendingClicks({
            currentPage: AppHelper.PAGE_LIMIT
          });
          await __self.props.getTrendingTopics({
            currentPage: AppHelper.PAGE_LIMIT
          });
          resolve(res);
        }).then(async r => {
          await __self.props.setPostDetails({
            id: this.state.id,
            title: "Home",
            navigate: true
          });
          await __self.props.setPostCommentDetails({
            id: this.state.id,
            title: this.state.title
          });
          this.setState(this.baseState);
        });
      })
      .catch(e => {
        console.log(e);
      });
  };




  handleTopicInput = e => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      if (!this.state.topic || this.state.topic === "") return;
      var letters = /^[0-9a-zA-Z-/]+$/;
      if (this.state.topic.match(letters)) {
        if (
          this.state.topic[0] == "-" ||
          this.state.topic[this.state.topic.length - 1] == "-"
        ) {
          alert("- not allowed at initial or end of a topic name");
          return false;
        } else if (this.state.selectedTopics.length < 3) {
          let index = this.state.selectedTopics.findIndex(
            i => i.name == this.state.topic
          );
          if (index != -1) {
            alert("topic name already selected");
          } else {
            this.setState({
              selectedTopics: this.state.selectedTopics.concat([
                { name: this.state.topic.replace("/", "") }
              ]),
              topic: "",
              TopicList: [],
              ClikList: []
            });
          }
        } else {
          alert("You can only choose Maximum 3 Topics to Tag");
          this.setState({
            topic: ""
          });
        }
      } else {
        alert("Please input alphanumeric characters only");
        return false;
      }
    }
  };

  handleTopicSelectInput = topic => {
    if (this.state.selectedTopics.length < 3) {
      let index = this.state.selectedTopics.findIndex(i => i.name == topic);
      if (index != -1) {
        alert("topic name already selected");
      } else {
        this.setState({
          selectedTopics: this.state.selectedTopics.concat([{ name: topic }]),
          topic: "",
          TopicList: [],
          ClikList: []
        });
      }
    } else {
      alert("You can only choose Maximum 3 Topics to Tag");
      this.setState({
        topic: ""
      });
    }
  };

  handleTopicDelete = index => {
    let tagsSelected = this.state.selectedTopics;
    tagsSelected.splice(index, 1);
    this.setState({ selectedTopics: tagsSelected });
  };

  customRenderTopicTags = tags => {
    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          backgroundColor: "#fff",
          width: "100%",
          padding: 5
        }}
      >
        {this.state.selectedTopics.map((t, i) => {
          return (
            <View
              key={t.name}
              style={{
                flexDirection: "row",
                backgroundColor: "#e3f9d5",
                justifyContent: "center",
                alignItems: "center",
                height: 30,
                marginLeft: 10,
                marginTop: 5,
                borderRadius: 10,
                padding: 3
              }}
            >
              <TouchableHighlight
                key={i}
                onPress={() => this.handleTopicDelete(i)}
              >
                <Text
                  style={{
                    color: "#009B1A",
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  /{t.name.toLowerCase()}
                </Text>
              </TouchableHighlight>
              <Icon
                color={
                  "#009B1A"
                }
                name="times"
                type="font-awesome"
                size={12}
                iconStyle={{ marginLeft: 5 }}
                onPress={() => this.handleTopicDelete(i)}
              />
            </View>
          );
        })}
      </View>
    );
  };

  customRenderTopicSuggestion = value => {
    let topicsearchData = [];
    let topicsearchArray = [];
    let cliksearchData = [];
    let cliksearchArray = [];

    if (value.charAt(0) == "/") {
      SearchTopicVariables.variables.prefix = value;
      applloClient
        .query({
          query: SearchTopicMutation,
          ...SearchTopicVariables,
          fetchPolicy: "no-cache"
        })
        .then(res => {
          topicsearchArray = res.data.search.topics;
          for (let i = 0; i < topicsearchArray.length; i++) {
            topicsearchData.push({ topic: topicsearchArray[i] });
          }
          this.setState({ TopicList: topicsearchData });
        });
    } else if (value.charAt(0) == "#") {
      SearchClikVariables.variables.prefix = value;
      applloClient
        .query({
          query: SearchClikMutation,
          ...SearchClikVariables,
          fetchPolicy: "no-cache"
        })
        .then(res1 => {
          cliksearchArray = res1.data.search.cliks;
          for (let i = 0; i < cliksearchArray.length; i++) {
            cliksearchData.push({ clik: cliksearchArray[i] });
          }
          this.setState({ ClikList: cliksearchData });
        });
    } else if (value === "") {
      this.setState({ TopicList: [], ClikList: [] });
    } else {
      SearchTopicVariables.variables.prefix = value;
      applloClient
        .query({
          query: SearchTopicMutation,
          ...SearchTopicVariables,
          fetchPolicy: "no-cache"
        })
        .then(res => {
          topicsearchArray = res.data.search.topics;
          for (let i = 0; i < topicsearchArray.length; i++) {
            topicsearchData.push({ topic: topicsearchArray[i] });
          }
          this.setState({ TopicList: topicsearchData });
        });

      SearchClikVariables.variables.prefix = value;
      applloClient
        .query({
          query: SearchClikMutation,
          ...SearchClikVariables,
          fetchPolicy: "no-cache"
        })
        .then(res1 => {
          cliksearchArray = res1.data.search.cliks;
          for (let i = 0; i < cliksearchArray.length; i++) {
            cliksearchData.push({ clik: cliksearchArray[i] });
          }
          this.setState({ ClikList: cliksearchData });
        });
    }
  };

  handleClikSelectInput = clik => {
    if (this.state.selectedCliks.length < 3) {
      let index = this.state.selectedCliks.findIndex(i => i.name == clik);
      if (index != -1) {
        alert("clik name already selected");
      } else {
        this.setState({
          selectedCliks: this.state.selectedCliks.concat([{ name: clik }]),
          clik: "",
          topic: "",
          ClikList: [],
          TopicList: []
        });
      }
    } else {
      alert("You can only choose Maximum 3 Cliks to Tag");
      this.setState({
        clik: ""
      });
    }
  };

  handleClikDelete = index => {
    let tagsSelected = this.state.selectedCliks;
    tagsSelected.splice(index, 1);
    this.setState({ selectedCliks: tagsSelected });
  };

  customRenderClikTags = tags => {
    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          backgroundColor: "#fff",
          width: "100%",
          padding: 5
        }}
      >
        {this.state.selectedCliks.map((t, i) => {
          return (
            <View
              style={{
                flexDirection: "row",
                backgroundColor: "#E8F5FA",
                justifyContent: "center",
                alignItems: "center",
                height: 30,
                marginLeft: 10,
                marginTop: 5,
                borderRadius: 10,
                padding: 3
              }}
            >
              <TouchableHighlight
                key={i}
                onPress={() => this.handleClikDelete(i)}
              >
                <Text
                  style={{
                    color: "#4169e1",
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  #{t.name}
                </Text>
              </TouchableHighlight>
              <Icon
                color={"#4C82B6"}
                name="times"
                type="font-awesome"
                size={12}
                iconStyle={{ marginLeft: 5 }}
                onPress={() => this.handleClikDelete(i)}
              />
            </View>
          );
        })}
      </View>
    );
  };


  render() {
    const { changeBackPicEnable } = this.state;
    const textStyle = styles.usertext;
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: ConstantColors.customeBackgroundColor,
          width: "100%",
          
        }}
      >
        <ScrollView
          ref={scrollview => {
            this.Pagescrollview = scrollview;
          }}
          showsVerticalScrollIndicator={false}
          onLayout={event => {
            let { x, y, width, height } = event.nativeEvent.layout;
            if (width < 1024) {
              this.setState({
                showsVerticalScrollIndicatorView: true,
                currentScreentWidth: width
              });
            } else {
              this.setState({
                showsVerticalScrollIndicatorView: false,
                currentScreentWidth: width
              });
            }
          }}
          style={{
            height:
              Platform.OS !== "web"
                ? null
                : Dimensions.get("window").height - 80
          }}
        >
          {Dimensions.get("window").width <= 750 ? (
            <Animated.View
              style={{
                position: Platform.OS == "web" ? "sticky" : null,
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                overflow: "hidden",
              }}
            >
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    backgroundColor: "#000",
                    height: 50
                  }}
                >
                  <TouchableOpacity
                    style={ButtonStyle.headerBackStyle}
                    onPress={() => {
                      this.props.searchOpenBarStatus(false);
                      let nav = this.props.navigation.dangerouslyGetParent()
                        .state;
                      if (nav.routes.length > 1) {
                        this.props.navigation.goBack();
                        return;
                      } else {
                        this.props.navigation.navigate("home");
                      }
                    }}
                  >
                    <Icon
                      color={"#fff"}
                      name="angle-left"
                      type="font-awesome"
                      size={40}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[ButtonStyle.headerTitleStyle, { backgroundColor: "#000" }]}
                  >
                    <Text
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: 18,
                        fontFamily: ConstantFontFamily.defaultFont
                      }}
                    >
                      Edit Post
                    </Text>
                  </TouchableOpacity>
                  <View style={ButtonStyle.headerRightStyle}>
                    <Suspense fallback={null}>
                      <HeaderRight navigation={this.props.navigation} />
                    </Suspense>
                  </View>
                </View>
              </View>
            </Animated.View>
          ) : (
            null
          )}
          <View style={[Dimensions.get("window").width >= 1200 ? ButtonStyle.normalFeedStyleForDashboard : null,
          {
            backgroundColor: "#fff",
            // marginTop: Dimensions.get("window").width <= 1200 ? 10 : 30,
            paddingHorizontal: Dimensions.get("window").width <= 1200 ? 5 : 10,
            marginHorizontal: Dimensions.get("window").width <= 1200 ? 0 : 10,
            alignItems: 'center',
            // paddingTop: 10,
            borderLeftWidth:1, borderColor:'#d3d3d3' ,
          }]}>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <View
                style={{
                  width: "70%",
                  justifyContent: "flex-start",
                  flexDirection: "row"
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    marginTop: 5,
                    marginRight: 20,
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  Title
                </Text>
              </View>
              <View
                style={{
                  width: "30%",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  flexDirection: 'row'
                }}
              >
                <Text
                  style={{
                    color:
                      this.state.title.length < 10 ||
                        this.state.title.length > 150
                        ? "#de5246"
                        : "#009B1A",
                    fontSize: 13,
                    fontFamily: ConstantFontFamily.defaultFont,
                    marginTop: 2,
                    marginRight: 3
                  }}
                >
                  {
                    this.state.title.length < 10 ? (10 - this.state.title.length) + " characters more"
                      : this.state.title.length > 10 && (150 - this.state.title.length) + " characters left"}
                </Text>
                {(this.state.title.length < 10 ||
                  this.state.title.length > 150) && (
                    <TouchableOpacity
                      onMouseEnter={() => this.setState({ titleHover: true })}
                      onMouseLeave={() => this.setState({ titleHover: false })}
                    >
                      <Icon
                        color={"#f80403"}
                        iconStyle={{
                          marginTop: 10,
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                        name="times"
                        type="font-awesome"
                        size={16}
                      />
                    </TouchableOpacity>
                  )}
                {this.state.titleHover == true && Platform.OS == "web" ? (
                  <Tooltip
                    backgroundColor={"#d3d3d3"}
                    withPointer={false}
                    withOverlay={false}
                    toggleOnPress={true}
                    containerStyle={{
                      left: -150,
                      top: -50
                    }}
                    popover={<Text style={{
                      fontFamily: ConstantFontFamily.defaultFont
                    }}>Between 10 to 150 Characters</Text>}
                  />
                ) : null}
              </View>
            </View>
            <TextInput
              value={this.state.title}
              multiline={true}
              numberOfLines={2}
              maxLength={150}
              placeholder="Enter a title."
              placeholderTextColor="#6D757F"
              style={[
                textStyle,
                this.state.focusTitle ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                {
                  width: "100%",
                  marginTop: 5,
                  padding: 5,
                  height: Platform.OS == "ios" ? 100 : null,
                  outline: 'none'
                }
              ]}
              onChangeText={title => this.setState({ title })}
              onFocus={() => this.setState({ focusTitle: true })}
              onBlur={() => this.setState({ focusTitle: false })}
            />
            <View style={{ flexDirection: "row", width: "100%" }}>
              <View
                style={{
                  width: "70%",
                  justifyContent: "flex-start",
                  flexDirection: "row"
                }}
              >
                <Text
                  style={{
                    fontWeight: "bold",
                    marginTop: 10,
                    marginRight: 20,
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  Summary
                </Text>
              </View>
              <View
                style={{
                  width: "30%",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  flexDirection: "row"
                }}
              >
                <Text
                  style={{
                    color:
                      this.state.summary.length < 50 ||
                        this.state.summary.length > 300
                        ? "#de5246"
                        : "#009B1A",
                    fontSize: 13,
                    fontFamily: ConstantFontFamily.defaultFont,
                    marginTop: 2,
                    marginRight: 3
                  }}
                >
                  {
                    this.state.summary.length < 50 ? (50 - this.state.summary.length) + " characters more"
                      : this.state.summary.length > 50 && (300 - this.state.summary.length) + " characters left"}
                </Text>
                {(this.state.summary.length < 50 ||
                  this.state.summary.length > 300) && (
                    <TouchableOpacity
                      onMouseEnter={() => this.setState({ summaryHover: true })}
                      onMouseLeave={() => this.setState({ summaryHover: false })}
                    >
                      <Icon
                        color={"#f80403"}
                        iconStyle={{
                          marginTop: 10,
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                        name="times"
                        type="font-awesome"
                        size={16}
                      />
                    </TouchableOpacity>
                  )}
                {this.state.summaryHover == true && Platform.OS == "web" ? (
                  <Tooltip
                    backgroundColor={"#d3d3d3"}
                    withPointer={false}
                    withOverlay={false}
                    toggleOnPress={true}
                    containerStyle={{
                      left: -150,
                      top: -50
                    }}
                    popover={<Text>Between 50 to 300 Characters</Text>}
                  />
                ) : null}
              </View>
            </View>
            <TextInput
              value={this.state.summary}
              multiline={true}
              numberOfLines={5}
              maxLength={300}
              placeholder="Enter a concise summary."
              placeholderTextColor="#6D757F"
              style={[
                textStyle,
                this.state.focusSummary ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                {
                  width: "100%",
                  marginTop: 5,
                  padding: 5,
                  height: Platform.OS == "ios" ? 100 : null,
                  outline: 'none'
                }
              ]}
              onChangeText={summary => this.setState({ summary })}
              onFocus={() => this.setState({ focusSummary: true })}
              onBlur={() => this.setState({ focusSummary: false })}
            />
            <View
              style={{ flexDirection: "row", justifyContent: "space-between", alignItems: 'flex-end' }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  marginTop: 10,
                  marginRight: 10,
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Share To
              </Text>
              {this.state.selectedTopics.length == 0 &&
                this.state.selectedCliks.length == 0 && (
                  <TouchableOpacity>
                    <Icon
                      color={"#f80403"}
                      iconStyle={{
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                      name="times"
                      type="font-awesome"
                      size={16}
                    />
                  </TouchableOpacity>
                )}
            </View>
            <View style={{ flexDirection: "row", width: "100%" }}>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row"
                }}
              >
                <View
                  style={{
                    width: "100%",
                  }}
                  onLayout={event => {
                    let { x, y, width, height } = event.nativeEvent.layout;
                    this.setState({ TopicInputwidth: width });
                  }}
                >
                  <View>
                    {this.state.selectedTopics.length > 0 &&
                      this.customRenderTopicTags()}
                    {this.state.selectedCliks.length > 0 &&
                      this.customRenderClikTags()}
                  </View>
                  <View
                    style={[
                      this.state.focusTopic ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                      {
                        width: "100%",
                        height: 40,
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        alignItems: "center",
                      }]}
                  >
                    <View style={{ width: "5%", marginRight: "auto" }}>
                      <Icon name="search" size={18} type="font-awesome" />
                    </View>
                    <TextInput
                      value={this.state.topic}
                      autoFocus={false}
                      placeholder="share to /topics #clicks"
                      onKeyPress={this.handleTopicInput}
                      onChangeText={topic => {
                        this.setState({ topic: topic, clik: topic }),
                          this.customRenderTopicSuggestion(topic);
                      }}
                      style={{
                        width: "90%",
                        border: "none",
                        outline: "none",
                        fontFamily: ConstantFontFamily.defaultFont
                      }}
                      onFocus={() => this.setState({ focusTopic: true })}
                      onBlur={() => this.setState({ focusTopic: false })}
                    />
                  </View>
                  {this.state.topic.length > 0 && (
                    <ScrollView
                      nestedScrollEnabled
                      style={{ maxHeight: 200 }}
                      showsVerticalScrollIndicator={false}
                    >
                      {this.state.TopicList.map((item, index) => {
                        return (
                          <View
                            key={item.name}
                            style={{
                              backgroundColor: "#FEFEFA",
                              width: "100%",
                              padding: 5
                            }}
                          >
                            <View
                              style={{
                                padding: 5,
                                backgroundColor: item.getIn([
                                  "topic",
                                  "parents"
                                ])
                                  ? "#e3f9d5"
                                  : "#e3f9d5",
                                borderRadius: 10,
                                alignSelf: "flex-start",
                                alignItems: "center"
                              }}
                            >
                              <Text
                                style={{
                                  color: item.getIn(["topic", "parents"])
                                    ? "#009B1A"
                                    : "#009B1A",
                                  fontFamily:
                                    ConstantFontFamily.defaultFont,
                                  fontWeight: "bold"
                                }}
                                onPress={() =>
                                  this.handleTopicSelectInput(
                                    item.getIn(["topic", "name"])
                                  )
                                }
                              >
                                /{item.getIn(["topic", "name"]).toLowerCase()}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                      {this.state.ClikList.map((item, index) => {
                        console.log(item,'item');
                        let indexx = this.props.getUserFollowCliksList.findIndex(
                          i =>
                            i.node.clik.name.toLowerCase() ==
                            item.clik.name.toLowerCase()
                        );
                        if (
                          indexx != -1 &&
                          (this.props.getUserFollowCliksList[indexx]?.node.member_type
                            == "SUPER_ADMIN" ||
                            this.props.getUserFollowCliksList[indexx]?.node?.member_type == "ADMIN" ||
                            this.props.getUserFollowCliksList[indexx]?.node?.member_type == "MEMBER")
                        ) {
                          return (
                            <View
                              key={item.name}
                              style={{
                                backgroundColor: "#FEFEFA",
                                width: "100%",
                                padding: 5
                              }}
                            >
                              <View
                                style={{
                                  padding: 5,
                                  backgroundColor: "#E8F5FA",
                                  borderRadius: 10,
                                  alignSelf: "flex-start",
                                  alignItems: "center"
                                }}
                              >
                                <Text
                                  style={{
                                    color: "#4169e1",
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    fontWeight: "bold"
                                  }}
                                  onPress={() =>
                                    this.handleClikSelectInput(
                                      item.clik.name
                                    )
                                  }
                                >
                                  #{item.clik.name}
                                </Text>
                              </View>
                            </View>
                          );
                        }
                      })}
                    </ScrollView>
                  )}

                  <Text
                    style={{
                      fontWeight: "bold",
                      marginTop: 10,
                      marginRight: 10,
                      fontSize: 16,
                      fontFamily: ConstantFontFamily.defaultFont
                    }}
                  >
                    Quick Access
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      paddingHorizontal: "10%",
                      justifyContent: "space-between"
                    }}
                  >
                    <View style={{ marginVertical: 10, maxHeight: 350 }}>
                      {this.state.quickTopicArray.map((item, i) => {
                        return (
                          <TouchableOpacity
                            onPress={() =>
                              this.handleTopicSelectInput(
                                item.node.topic.name
                              )
                            }
                            style={{
                              marginTop: 10,
                              marginLeft: 5,
                              alignSelf: "center",
                              padding: 5,
                              backgroundColor: "#e3f9d5",
                              borderRadius: 6
                            }}
                          >
                            <Hoverable>
                              {isHovered => (
                                <Text
                                  style={{
                                    width: "100%",
                                    color: "#009B1A",
                                    fontSize: 15,
                                    fontFamily:
                                      ConstantFontFamily.defaultFont,
                                    textDecorationLine:
                                      isHovered == true ? "underline" : "none"
                                  }}
                                >
                                  /{item.node.topic.name.toLowerCase()}
                                </Text>
                              )}
                            </Hoverable>
                          </TouchableOpacity>
                        );
                      })}
                    </View>
                    <View style={{ marginVertical: 10, maxHeight: 350 }}>
                      {this.state.quickClikArray?.map((item, i) => {
                       
                        return (
                          <TouchableOpacity
                            onPress={() =>
                              this.handleClikSelectInput(
                                item.node.clik.name
                              )
                            }
                            style={{
                              marginLeft: 5,
                              margin: 5,
                              alignSelf: "center",
                              padding: 5,
                              backgroundColor: "#E8F5FA",
                              borderRadius: 6,
                              maxWidth: "100%"
                            }}
                          >
                            <Hoverable>
                              {isHovered => (
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "left",
                                    color: "#4169e1",
                                    fontSize: 15,
                                    fontFamily:
                                      ConstantFontFamily.defaultFont,
                                    textDecorationLine:
                                      isHovered == true ? "underline" : "none"
                                  }}
                                >
                                  #{item.node.clik.name}
                                </Text>
                              )}
                            </Hoverable>
                          </TouchableOpacity>
                        );
                      })}
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <Text
              style={{
                textAlign: "right",
                fontSize: 14,
                fontFamily: ConstantFontFamily.defaultFont
              }}
            >
              Can't find a topic?&nbsp;
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                  textDecorationLine: "underline"
                }}
                onPress={() => this.props.navigation.navigate("createtopic")}
              >
                Create
              </Text>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
                onPress={() => this.props.navigation.navigate("createtopic")}
              >
                &nbsp;one
              </Text>
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Button
                title="EDIT POST"
                titleStyle={ButtonStyle.wtitleStyle}
                buttonStyle={ButtonStyle.gbackgroundStyle}
                containerStyle={ButtonStyle.containerStyle}
                disabled={
                  this.state.title.length < 10 ||
                    this.state.title.length > 150 ||
                    this.state.summary.length < 50 ||
                    this.state.summary.length > 300 ||
                    (this.state.selectedTopics.length <= 0 &&
                      this.state.selectedCliks.length <= 0)
                    ? true
                    : false
                }
                onPress={this.onSave}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksLis
    : [],
  postEditDetails: state.LinkPostReducer.get("postEditDetails"),
  getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
    ? state.LoginUserDetailsReducer.userFollowTopicsList
    : []
});

const mapDispatchToProps = dispatch => ({
  getHomefeed: payload => dispatch(getHomefeedList(payload)),
  getTrendingClicks: payload => dispatch(getTrendingClicks(payload)),
  setPostDetails: payload => dispatch(setPostDetails(payload)),
  setHASSCROLLEDACTION: payload => dispatch(setHASSCROLLEDACTION(payload)),
  getTrendingTopics: payload => dispatch(getTrendingTopics(payload)),
  setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
});

const EditPostScreenContainerWrapper = graphql(PostEditMutation, {
  name: "PostCreate"
})(EditPostScreen);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditPostScreenContainerWrapper
);

const styles = StyleSheet.create({
  usertext: {
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  }
})