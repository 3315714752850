import { fromJS } from "immutable";
import {
  HASSCROLLEDACTION_SUCCESS,
  HASSCROLLEDACTION_FAILURE
} from "../constants/Action";

const initialState = fromJS({
  hasScrollTop: false,
  setBrowserHeightWidth: { heigh: 0, width: 0 }
});

export default function HasScrolledReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HASSCROLLEDACTION_SUCCESS:
      return state.set("hasScrollTop", fromJS(payload));
    case HASSCROLLEDACTION_FAILURE:
      return initialState;
    case "SET_BROWSER_HEIGHT_WIDTH":
      return state.set("setBrowserHeightWidth", payload);
  }
  return state;
}
