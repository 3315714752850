import { takeEvery, put } from "redux-saga/effects";
import {
  SCREENLOADINGMODALACTION,
  SCREENLOADINGMODALACTION_SUCCESS,
  SCREENLOADINGMODALACTION_FAILURE,
} from "../constants/Action";

export function* ScreenLoadingMiddleware({ payload }) {
  if (payload == true) {
    yield put({
      type: SCREENLOADINGMODALACTION_SUCCESS,
      payload: payload,
    });
  } else {
    yield put({
      type: SCREENLOADINGMODALACTION_FAILURE,
      payload: payload,
    });
  }
}

export default function* ScreenLoadingMiddlewareWatcher() {
  yield takeEvery(SCREENLOADINGMODALACTION, ScreenLoadingMiddleware);
}
