import React, { Component } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  // AsyncStorage,
  Platform,
  Image,
  TextInput,
  Dimensions
} from "react-native";
import { connect } from "react-redux";
import { setCreateAccount } from "../actionCreator/CreateAccountAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import { setVERIFYEMAILMODALACTION } from "../actionCreator/VerifyEmailModalAction";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
import { getLocalStorage, setLocalStorage } from "../library/Helper";
import jwt_decode from "jwt-decode";
import {
  saveUserLoginDaitails,
  setLoginStatus
} from "../actionCreator/UserAction";
import { setAdminStatus } from "../actionCreator/AdminAction";
import { Hoverable } from "react-native-web-hooks";
import { List } from "immutable";
import { Button, Icon } from "react-native-elements";
import applloClient from "../client";
import {
  FollowMutation,
  UnFollowMutation
} from "../graphqlSchema/graphqlMutation/FollowandUnFollowMutation";
import { UserLoginMutation } from "../graphqlSchema/graphqlMutation/UserMutation";
import {
  FollowVariables,
  UnFollowVariables
} from "../graphqlSchema/graphqlVariables/FollowandUnfollowVariables";
import {
  FeedFollowMutation,
  FeedUnFollowMutation
} from "../graphqlSchema/graphqlMutation/FollowandUnFollowMutation";
import {
  FeedFollowVariables,
  FeedUnFollowVariables
} from "../graphqlSchema/graphqlVariables/FollowandUnfollowVariables";
import {
  ClikFollowMutation,
  ClikUnfollowMutation
} from "../graphqlSchema/graphqlMutation/FollowandUnFollowMutation";
import {
  ClikFollowVariables,
  ClikUnfollowVariables
} from "../graphqlSchema/graphqlVariables/FollowandUnfollowVariables";
import {
  UserFollowMutation,
  UserUnfollowMutation
} from "../graphqlSchema/graphqlMutation/FollowandUnFollowMutation";
import {
  UserFollowVariables,
  UserUnfollowVariables
} from "../graphqlSchema/graphqlVariables/FollowandUnfollowVariables";
import DrawerScreen from "./DrawerScreens";
import TrendingTabs from "./TrendingTabs";
import ButtonStyle from "../constants/ButtonStyle";
import SidePanel from './SidePanel';
import NavigationService from "../library/NavigationService";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";

const apiUrl = getEnvVars();

class SignUpFollow extends Component {
  totalHeigth = 0;
  constructor(props) {
    super(props);
    this.state = {
      FollowList: [],
      getHeight: 100,
      randomItemEvent: 0,
      primaryIndex: 0,
      isSearched: false,
      searchedWord: "",
      isActive: false,
      searchedFollowText: ""
    };
  }

  componentDidMount() {
    this.props.setUsernameModalStatus(true);
  }

  setColor = id => {
    let index = this.state.FollowList.indexOf(id);
    if (index != -1) {
      let data = this.state.FollowList;
      data.splice(index, 1);
      this.setState({
        FollowList: data
      });
    } else {
      let data = this.state.FollowList;
      data.push(id);
      this.setState({
        FollowList: data
      });
    }
  };

  followTopics = async topicId => {
    FollowVariables.variables.topic_id = topicId;
    FollowVariables.variables.follow_type = "FOLLOW";
    applloClient
      .query({
        query: FollowMutation,
        ...FollowVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        // let resDataLogin = await this.props.Login();
        // await this.props.saveLoginUser(resDataLogin.data.login);
        // await this.props.getTrendingTopics({
        //   currentPage: AppHelper.PAGE_LIMIT
        // });
      });
  };

  unfollowTopics = async topicId => {
    UnFollowVariables.variables.id = "Topic:" + topicId;
    applloClient
      .query({
        query: UnFollowMutation,
        ...UnFollowVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        // let resDataLogin = await this.props.Login();
        // await this.props.saveLoginUser(resDataLogin.data.login);
        // await this.props.getTrendingTopics({
        //   currentPage: AppHelper.PAGE_LIMIT
        // });
      });
  };

  followFeed = feedid => {
    FeedFollowVariables.variables.feed_id = feedid;
    FeedFollowVariables.variables.follow_type = "FOLLOW";
    applloClient
      .query({
        query: FeedFollowMutation,
        ...FeedFollowVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        // let resDataLogin = await this.props.Login();
        // await this.props.saveLoginUser(resDataLogin.data.login);
        // await this.props.getTrendingExternalFeeds({
        //   currentPage: AppHelper.PAGE_LIMIT
        // });
      });
  };

  unfollowFeed = async Id => {
    FeedUnFollowVariables.variables.feed_id = Id;
    applloClient
      .query({
        query: FeedUnFollowMutation,
        ...FeedUnFollowVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        // let resDataLogin = await this.props.Login();
        // await this.props.saveLoginUser(resDataLogin.data.login);
        // await this.props.getTrendingExternalFeeds({
        //   currentPage: AppHelper.PAGE_LIMIT
        // });
      });
  };

  followCliks = cliksId => {
    ClikFollowVariables.variables.clik_id = cliksId;
    ClikFollowVariables.variables.follow_type = "FOLLOW";
    applloClient
      .query({
        query: ClikFollowMutation,
        ...ClikFollowVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        // let resDataLogin = await this.props.Login();
        // await this.props.saveLoginUser(resDataLogin.data.login);
        // await this.props.getTrendingClicks({
        //   currentPage: AppHelper.PAGE_LIMIT
        // });
      });
  };

  unfollowCliks = async cliksId => {
    ClikUnfollowVariables.variables.clik_id = cliksId;
    applloClient
      .query({
        query: ClikUnfollowMutation,
        ...ClikUnfollowVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        // let resDataLogin = await this.props.Login();
        // await this.props.saveLoginUser(resDataLogin.data.login);
        // await this.props.getTrendingClicks({
        //   currentPage: AppHelper.PAGE_LIMIT
        // });
      });
  };

  followUser = userId => {
    UserFollowVariables.variables.user_id = userId;
    UserFollowVariables.variables.follow_type = "FOLLOW";
    applloClient
      .query({
        query: UserFollowMutation,
        ...UserFollowVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        // let resDataLogin = await this.props.Login();
        // await this.props.saveLoginUser(resDataLogin.data.login);
        // await this.props.getTrendingUsers({
        //   currentPage: AppHelper.PAGE_LIMIT
        // });
      });
  };

  unfollowUser = async userId => {
    UserUnfollowVariables.variables.user_id = userId;
    applloClient
      .query({
        query: UserUnfollowMutation,
        ...UserUnfollowVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        // let resDataLogin = await this.props.Login();
        // await this.props.saveLoginUser(resDataLogin.data.login);
        // await this.props.getTrendingUsers({
        //   currentPage: AppHelper.PAGE_LIMIT
        // });
      });
  };

  calHeightLeftPannelMian = height => {
    if (height > 0 && height != null) {
      this.totalHeigth = height;
    }
  };

  activeIndexAction = index => {
    this.setState({
      primaryIndex: index,
      searchedFollowText: ""
    });
  };

  changeColor = isHovered => {
    if (isHovered == true) {
      switch (this.state.primaryIndex) {
        case 0:
          return "#009B1A";
        case 1:
          return "#4C82B6";
        case 2:
          return "#FEC236";
        default:
          return "#F34225";
      }
    }
  };

  getType = () => {
    switch (this.state.primaryIndex) {
      case 0:
        return "TOPICS";
      case 1:
        return "CLIKS";
      case 2:
        return "USERS";
      default:
        return "FEEDS";
    }
  };

  searchTextField = value => {
    this.setState({ searchedFollowText: value });
  };

  render() {
    return (
      <View style={{
        justifyContent: 'space-between',
        height: "100%", flexDirection: 'row'
      }}>
        <View style={{ width: Dimensions.get("window").width - 550 }}>
          <SidePanel
            ref={navigatorRef => {
              NavigationService.setTopLevelNavigator(navigatorRef);
            }}
            navigation={NavigationService}
          /></View>
        <View
          style={{
            maxWidth: 550,
            backgroundColor: "#f4f4f4",
            padding: 50,
          }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#c5c5c5",
              borderRadius: 10,
              backgroundColor: "#fff",
              marginBottom: 10,
              padding: 10
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row"
              }}
            >
              <Image
                source={require("../assets/image/logo.png")}
                style={{
                  height: 38,
                  width: 115,
                }}
                resizeMode={"contain"}
              />
              {/* <Text
              style={{
                fontWeight: "bold",
                fontSize: 45,
                fontFamily: ConstantFontFamily.defaultFont
              }}
            >
              {" "}
              weclikd
            </Text> */}
            </View>

            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                marginVertical: 10,
                marginHorizontal: 50
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 18,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Follow at least three items. Click on the star once to follow,
                twice to favorite.
            </Text>
            </View>
          </View>
          {/* search area start */}

          <View
            style={{
              borderWidth: 1,
              borderColor: "#c5c5c5",
              borderRadius: 10,
              backgroundColor: "#fff",
              marginBottom: 10,
              padding: 10
            }}
          >
            <View
              style={{
                width: "100%",
                height: 1,
                justifyContent: "flex-start",
                flexDirection: "row",
                alignItems: "center",
                //borderColor: "#000",
                //borderWidth: 1,
                borderRadius: 5,
                paddingHorizontal: "3%",
                //visibility: 'hidden'
              }}
            >
              {/* <View style={{ width: "10%", marginRight: "auto",display:'none' }}>
              <Icon name="search" size={18} type="font-awesome" />
            </View>

            <TextInput
              autoFocus={true}
              placeholder="Search Weclikd"
              onChangeText={query => this.searchTextField(query)}
              value={this.state.searchedFollowText}
              style={{
                height: 40,
                width: "74%",
                paddingHorizontal: 10,
                border: "none",
                outline: "none",
                position: "absolute",
                
                left: "13%"
                ,display:'none'
              }}
            />

            <TouchableOpacity
              onPress={() => this.setState({ searchedFollowText: "" })}
              style={{ marginLeft: "auto", width: "10%" ,display:'none'}}
            >
              <Icon name="close" size={18} type="font-awesome" />
            </TouchableOpacity> */}
            </View>

            {/* search area end */}
            <View style={{ height: 320 }} showsVerticalScrollIndicator={false}>
              <TrendingTabs
                navigation={this.props.navigation}
                randomItemEvent={this.state.randomItemEvent}
                searchedFollowText={this.state.searchedFollowText}
                activeIndex={this.activeIndexAction}
                onchangetext={query => this.searchTextField(query)}
                onpress={() => this.setState({ searchedFollowText: "" })}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                width: "100%",
                paddingHorizontal: "5%",
                justifyContent: "space-between",
                //paddingTop: 20
                //marginBottom:5
              }}
            >
              <TouchableOpacity
                block
                style={[
                  ButtonStyle.backgroundStyle,
                  ButtonStyle.containerStyle,
                  { alignItems: "center", justifyContent: "center" }
                ]}
                onPress={() => {
                  let nav = this.props.navigation.dangerouslyGetParent().state;
                  if (nav.routes.length > 1) {
                    this.props.navigation.goBack();
                    return;
                  } else {
                    this.props.navigation.navigate("home");
                  }
                }}
              >
                <Text style={ButtonStyle.titleStyle}>Back</Text>
              </TouchableOpacity>

              <TouchableOpacity
                block
                style={[
                  ButtonStyle.backgroundStyle,
                  ButtonStyle.containerStyle,
                  { alignItems: "center", justifyContent: "center" }
                ]}
                onPress={() => {
                  this.props.navigation.navigate("message");
                  //this.props.SignUpFollowModalStatus(false),
                  //this.props.SignUpJoinModalStatus(true);
                }}
              >
                <Text style={ButtonStyle.titleStyle}>Continue</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  getCreateAccount: state.CreateAccountReducer.get("setCreateAccountData"),
  getTrending_Topics_List: state.TrendingTopicsReducer.get(
    "Trending_Topics_List"
  )
    ? state.TrendingTopicsReducer.get("Trending_Topics_List")
    : List(),
  listTrending_feeds: !state.TrendingExternalFeedsReducer.getIn([
    "Trending_ExternalFeeds"
  ])
    ? List()
    : state.TrendingExternalFeedsReducer.getIn(["Trending_ExternalFeeds"]),
  listTrending_cliks: !state.TrendingCliksReducer.getIn(["Trending_cliks_List"])
    ? List()
    : state.TrendingCliksReducer.getIn(["Trending_cliks_List"]),
  listTrending_users: !state.TrendingUsersReducer.getIn(["Trending_users_List"])
    ? List()
    : state.TrendingUsersReducer.getIn(["Trending_users_List"]),
  getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension")
});

const mapDispatchToProps = dispatch => ({
  setVerifyEmailModalStatus: payload =>
    dispatch(setVERIFYEMAILMODALACTION(payload)),
  setSignUpModalStatus: payload => dispatch(setSIGNUPMODALACTION(payload)),
  setCreateAccount: payload => dispatch(setCreateAccount(payload)),
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  changeAdminStatus: payload => dispatch(setAdminStatus(payload)),
  SignUpFollowModalStatus: payload =>
    dispatch({ type: "SIGNUP_FOLLOW_MODAL", payload }),
  SignUpJoinModalStatus: payload =>
    dispatch({ type: "SIGNUP_JOIN_MODAL", payload }),
  setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpFollow);
