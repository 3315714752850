import { fromJS } from 'immutable';
import { CLIKS_PROFILE_DETAILS_FAILURE, CLIKS_PROFILE_DETAILS_SUCCESS } from "../constants/Action";




const initialState = fromJS({
    getTrendingCliksProfileDetails: {},
});

export default function TrendingCliksProfileReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CLIKS_PROFILE_DETAILS_SUCCESS:
            // console.log(payload,'clikprofile');
            return state
                .set('getTrendingCliksProfileDetails', fromJS(payload));
        case CLIKS_PROFILE_DETAILS_FAILURE:
            return initialState;
    }
    return state;
}