import React, { Component } from "react";
import {
  Image,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import "../components/Firebase";
import ConstantFontFamily from "../constants/FontFamily";
import ButtonStyle from "../constants/ButtonStyle";

class SubcriptionModal extends Component {
  state = {
    success: false
  };

  constructor(props) {
    super(props);
  }

  __renderView = () => {
    return this.props.type == "BASIC" ? (
      this.state.success != true ? (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Text
            style={{
              color: "#000",
              fontFamily: ConstantFontFamily.defaultFont,
              fontSize: 16,
              fontWeight: "bold",
              marginVertical: 10
            }}
          >
            Basic allows for only 1/3rd of monetization of content ie) if you
            make $100 you will only keep $33. For a price of a coffee, full
            monetization a. premium features are a valuable investment if you
            are an active contributor to our intellectual discussions.
          </Text>
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Image
              source={require("../assets/image/checkmark.png")}
              style={{
                height: 30,
                width: 30,
                padding: 0,
                margin: 0,
                marginRight: 10
              }}
            />

            <Text
              style={{
                color: "#000",
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 16,
                fontWeight: "bold",
                marginVertical: 10
              }}
            >
              Successl Enjoy your basic features.
            </Text>
          </View>
        </View>
      )
    ) : this.state.success != true ? (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Text
          style={{
            color: "#000",
            fontFamily: ConstantFontFamily.defaultFont,
            fontSize: 16,
            fontWeight: "bold",
            marginVertical: 10
          }}
        >
          Premium offers full monetization of your discussion posts a. unlocks
          exclusIve features analytics, dictionary, etc. The subscription also
          enables one to award other users for their intellectual discourse.
        </Text>
      </View>
    ) : (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Image
            source={require("../assets/image/checkmark.png")}
            style={{
              height: 30,
              width: 30,
              padding: 0,
              margin: 0,
              marginRight: 10
            }}
          />
          <Text
            style={{
              color: "#000",
              fontFamily: ConstantFontFamily.defaultFont,
              fontSize: 16,
              fontWeight: "bold",
              marginVertical: 10
            }}
          >
            Successl Enjoy your premium features.
          </Text>
        </View>
      </View>
    );
  };

  __isConform = () => {
    if (this.state.success != true) {
      this.setState({
        success: true
      });
    } else {
      this.props.onClose();
    }
  };

  render() {
    return (
      <View
        style={{
          width: "100%"
        }}
      >
        <Hoverable>
          {isHovered => (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                flex: 1,
                position: "absolute",
                zIndex: 999999,
                left: 0,
                top: 0
              }}
              onPress={this.props.onClose}
            >
              <Icon
                color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                iconStyle={{
                  color: "#fff",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                reverse
                name="close"
                type="antdesign"
                size={16}
              />
            </TouchableOpacity>
          )}
        </Hoverable>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#000",
            alignItems: "center",
            height: 50,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6
          }}
        >
          <Image
            source={
              Platform.OS == "web" &&
              this.props.getCurrentDeviceWidthAction > 750
                ? require("../assets/image/weclickd-logo.png")
                : Platform.OS == "web"
                ? require("../assets/image/weclickd-logo.png")
                : require("../assets/image/weclickd-logo-only-icon.png")
            }
            style={
              Platform.OS == "web" &&
              this.props.getCurrentDeviceWidthAction > 750
                ? {
                    height: 30,
                    width: Platform.OS == "web" ? 90 : 30,
                    padding: 0,
                    margin: 0,
                    marginVertical: 10
                  }
                : {
                    height: 30,
                    width: Platform.OS == "web" ? 90 : 30,
                    padding: 0,
                    margin: 0,
                    marginVertical: 10
                  }
            }
          />
        </View>

        <View
          style={{
            borderRadius: 6,
            width: "100%",
            backgroundColor: "#fff"
          }}
        >
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
              backgroundColor: "#fff",
              height: hp("60%"),
              padding: 20
            }}
          >
            {this.__renderView()}
          </ScrollView>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignSelf: "center",
              margin: 10
            }}
          >
            <View
              style={{
                width: "50%",
                justifyContent: "center",
                flexDirection: "row"
              }}
            >
              <Button
                onPress={this.props.onClose}
                color="#fff"
                title="Back"
                titleStyle={ButtonStyle.titleStyle}
              buttonStyle={ButtonStyle.backgroundStyle}
              containerStyle={ButtonStyle.containerStyle}
              />
            </View>
            <View
              style={{
                width: "50%",
                justifyContent: "center",
                flexDirection: "row"
              }}
            >
              <Button
                onPress={() => this.__isConform()}
                color="#fff"
                title="Continue"
                titleStyle={ButtonStyle.titleStyle}
                buttonStyle={ButtonStyle.backgroundStyle}
                containerStyle={ButtonStyle.containerStyle}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default connect(null, null)(SubcriptionModal);

export const styles = {
  LogoImageStyle: {
    height: 80,
    width: 235
  },
  LogoContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: 25
  }
};
