import {COMPLETED_SUCCESS,COMPLETED_FAILURE} from '../constants/Action';

const initialState = {
    isCompleted:false
}

const CompletedReducer = (state = initialState, action) => {
    switch (action.type) {
      case COMPLETED_SUCCESS: {
        return {
            isCompleted: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default CompletedReducer;