// import firebase from "firebase/app";
// import "firebase/auth";
import React, { createRef, lazy, Suspense } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  // AsyncStorage,
  Dimensions,
  FlatList,
  Platform,
  Text,
  View,
  YellowBox,
  ActivityIndicator,
  Image,
  TouchableOpacity
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { FlatGrid } from "react-native-super-grid";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import applloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import {
  ExternalFeedMutation,
  HomeFeedMutation,
} from "../graphqlSchema/graphqlMutation/FeedMutation";
import {
  UserFeedMutation,
  getCommentPost
} from "../graphqlSchema/graphqlMutation/PostMutation";
import {
  ClikFeed,
  TopicFeed,
} from "../graphqlSchema/graphqlMutation/TrendingMutation";
import { retry } from "../library/Helper";
import ShadowSkeleton from "./ShadowSkeleton";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import ButtonStyle from "../constants/ButtonStyle";
import { feedFluctuation, clikHover, isloading } from "../reducers/action/Completed";
import { setPostDetails } from "../actionCreator/PostDetailsAction";
import AdminCompactView from "./AdminCompactView"
import CompactFeedList from "./CompactFeedList"
import FeedList from "./FeedList"
import ConstantColors from '../constants/Colors';
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";

let feedID = [];
class NewHomeFeed extends React.PureComponent {
  state = {
    modalVisible: false,
    data: [],
    page: 1,
    loading: true,
    loadingMore: false,
    refreshing: false,
    pageInfo: null,
    error: null,
    apiCall: true,
    activeFeed: -1,
    HomeFeedError: false,
    showEmptyIcon: false,
    activeId: "",
    activeTitle: "",
    loadSkeleton: false,
  };
  flatListRef = createRef();
  constructor(props) {
    super(props);


    YellowBox.ignoreWarnings(["VirtualizedLists should never be nested"]);
    this.baseState = this.state;
    this.viewabilityConfig = {
      viewAreaCoveragePercentThreshold: 50,
    };
  }





  componentDidMount = async () => {
    await this.props.doScroll(this.flatListRef, "new");
    this.props.clikHover({ isClik: false, clikId: "" })
    if (this.props.listType == "Home") {

      var id1 = window.location.href.toString().split("/");
      let newId = id1[id1.length - 1];
      let newId1 = newId.replace("#", "")

      // if (Platform.OS != "web") {
      //   this.props.setPostDetails({
      //     title: null,
      //     id: "Post:" + newId1,
      //     navigate: false,
      //   });
      //   // 
      // }


      if (Platform.OS == "web") {
        window.addEventListener(
          "unload",
          function () {
            var count = parseInt(sessionStorage.getItem("counter") || 0);

            sessionStorage.setItem("counter", ++count);
          },
          false
        );



        if (sessionStorage.getItem("counter") == 1) {
          this.props.feedFluctuation(false);
        } else if (sessionStorage.getItem("counter") >= 2) {
          if (
            window.location.href == "http://localhost:19006" ||
            window.location.href ==
            "http://electric-block-241402.appspot.com" ||
            window.location.href ==
            "https://electric-block-241402.appspot.com" ||
            window.location.href == "https://weclikd-beta.com" ||
            window.location.href == "http://weclikd-beta.com" ||
            window.location.href == "https://electric-block-241402.appspot.com"
          ) {

            this.props.feedFluctuation(true);

          } else {
            this.props.feedFluctuation(false);
          }

        } else {
          if (
            window.location.href == "http://localhost:19006" ||
            window.location.href ==
            "http://electric-block-241402.appspot.com" ||
            window.location.href ==
            "https://electric-block-241402.appspot.com" ||
            window.location.href == "https://weclikd-beta.com" ||
            window.location.href == "http://weclikd-beta.com" ||
            window.location.href == "https://electric-block-241402.appspot.com"
          ) {

            this.props.feedFluctuation(true);

          } else if (
            window.location.href == `http://localhost:19006/post/${newId1}` ||
            window.location.href ==
            `http://electric-block-241402.appspot.com/post/${newId1}` ||
            window.location.href ==
            `https://electric-block-241402.appspot.com/post/${newId1}` ||
            window.location.href == `https://weclikd-beta.com/post/${newId1}` ||
            window.location.href == `http://weclikd-beta.com/post/${newId1}`
          ) {
            this.props.feedFluctuation(false);


          } else if (
            window.location.href == `http://localhost:19006/comment/${newId1}` ||
            window.location.href ==
            `http://electric-block-241402.appspot.com/comment/${newId1}` ||
            window.location.href ==
            `https://electric-block-241402.appspot.com/comment/${newId1}` ||
            window.location.href == `https://weclikd-beta.com/comment/${newId1}` ||
            window.location.href == `http://weclikd-beta.com/comment/${newId1}`
          ) {
            this.props.feedFluctuation(false);

          }
        }
      }
      this._fetchAllHomeFeeds();
    } else if (this.props.listType == "Clik") {
      this._fetchAllClikFeeds();
    } else if (this.props.listType == "Topic") {
      this._fetchAllTopicFeeds();
    } else if (this.props.listType == "User") {
      this._fetchAllUserFeeds();
    } else if (this.props.listType == "Feed") {
      this._fetchAllExternalFeeds();
    }

    let data = document.getElementById("NewHomeFeed");
    if (data != null) {
      await data.addEventListener("keydown", (event) => {
        if (event.keyCode == 83 || event.keyCode == 40) {
          this.setState({
            activeFeed: this.state.activeFeed + 1,
          });
        }
        if (event.keyCode == 87 || event.keyCode == 38) {
          this.setState({
            activeFeed: this.state.activeFeed - 1,
          });
        }
        if (event.keyCode == 39 || event.keyCode == 68) {
          this.setState({
            activeFeed: this.state.activeFeed + 1,
          });
        }
        if (event.keyCode == 37 || event.keyCode == 65) {
          this.setState({
            activeFeed: this.state.activeFeed - 1,
          });
        }
        if (this.state.activeFeed < this.getUnique(feedID).length) {
          this.setBorderColor();
        }
      });
    }
    if (this.props.changeLoadTab && this.state.loadSkeleton == false) {
      this.props.changeLoadTab();
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.listType == "Topic" && prevProps.data != this.props.data) {
      await this.setState(this.baseState);
      this._fetchAllTopicFeeds();
    } else if (
      this.props.listType == "Clik" &&
      prevProps.data != this.props.data
    ) {
      this.props.feedFluctuation(true);
      await this.setState(this.baseState);
      this._fetchAllClikFeeds();
    } else if (
      this.props.listType == "User" &&
      prevProps.data != this.props.data
    ) {
      await this.setState(this.baseState);
      this._fetchAllUserFeeds();
    } else if (
      this.props.listType == "Feed" &&
      prevProps.data != this.props.data
    ) {
      await this.setState(this.baseState);
      this._fetchAllExternalFeeds();
    }
    if ((Platform.OS = "web")) {
      if (this.props.listType == "Home") {
        if (
          window.location.href == "http://localhost:19006/" ||
          window.location.href == "http://electric-block-241402.appspot.com/" ||
          window.location.href ==
          "https://electric-block-241402.appspot.com/" ||
          window.location.href == "http://localhost:19006/post/0" ||
          window.location.href == "https://weclikd-beta.com/" ||
          window.location.href == "http://weclikd-beta.com/"
        ) {
          this.props.feedFluctuation(true);
        }
      }
    }

    setTimeout(() => {
      if (
        this.state.activeId == "" &&
        this.state.data.length > 0 &&
        (this.props.ActiveTab == "New" || this.props.ActiveTab == "Reported")
      ) {
        this.makeHighlight(
          this.state.data[0].node.id,
          this.state.data[0].node.title
        );

        this.setBorderColor();
      } else if (this.state.data.length == 0) {
        this.props.setPostCommentReset({
          payload: [],
          postId: "",
          title: "",
          loading: true,
        });
      }
    }, 2000);

    if (this.props.changeLoadTab && this.state.loadSkeleton == false) {
      this.props.changeLoadTab();
    }
  };

  setBorderColor = async () => {
    for (let i = 0; i < this.getUnique(feedID).length; i++) {
      let data = document.getElementById(this.getUnique(feedID)[i]);
      if (data != null && Dimensions.get("window").width >= 1000) {
        if (i == this.state.activeFeed) {
          document.getElementById(this.getUnique(feedID)[i]).click();
        }
      }
    }
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.componentDidMount());
  };
  _handleLoadMoreByBtnClick = () => {
    this.setState(
      () => {
        if (this.state.apiCall == true) {
          this.setState({
            apiCall: false,
          });
          if (this.props.listType == "Home") {
            this._fetchAllHomeFeeds();
          } else if (this.props.listType == "Clik") {
            this._fetchAllClikFeeds();
          } else if (this.props.listType == "Topic") {
            this._fetchAllTopicFeeds();
          } else if (this.props.listType == "User") {
            this._fetchAllUserFeeds();
          } else if (this.props.listType == "Feed") {
            this._fetchAllExternalFeeds();
          }
        }
      }
    );
  };
  _handleLoadMore = (distanceFromEnd) => {
    this.setState(
      () => {
        if (
          0 <= distanceFromEnd &&
          distanceFromEnd <= 5 &&
          this.state.apiCall == true
        ) {
          this.setState({
            apiCall: false,
          });
          if (this.props.listType == "Home") {
            this._fetchAllHomeFeeds();
          } else if (this.props.listType == "Clik") {
            this._fetchAllClikFeeds();
          } else if (this.props.listType == "Topic") {
            this._fetchAllTopicFeeds();
          } else if (this.props.listType == "User") {
            this._fetchAllUserFeeds();
          } else if (this.props.listType == "Feed") {
            this._fetchAllExternalFeeds();
          }
        }
      }
    );
  };

  _fetchAllHomeFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null) {
      this.props.isloading(true)
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query:
            HomeFeedMutation,
          variables: {
            first: this.props.isfeed == true ? 12 : 50,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "NEW",
            type: 'Post'
          },
          fetchPolicy: "no-cache",
        })
        .then(async (response) => {

          this.props.setNewHomeFeed(response.data.node_list.edges);
          if (this.props.isfeed == true) {
            this.props.setUpdatedPostId(
              ""// response.data.node_list.edges[0].node.id
            );
            // await AsyncStorage.setItem(
            //   "dataItem",
            //   JSON.stringify(response.data.node_list.edges[0])
            // );
            let id = response.data.node_list.edges[0].node.id.replace(
              "Post:",
              ""
            );
            this.props.setPostCommentReset({
              payload: [],
              postId: "",
              title: "",
              loading: true,
            });
            this.props.setPostCommentDetails({
              id:"",
                // response.data.node_list.edges[0].node.id.replace(
                //   "Post:",
                //   ""
                // )
              title: "",
              loading: false,
            });
          } else {
            // let uri = window.location.href.toString().search('post')
            // if (uri != -1) {
            //   var id11 = window.location.href.toString().split("/");
            //   let newId1 = id11[id11.length - 1];
            //   this.props.setPostCommentReset({
            //     payload: [],
            //     postId: "",
            //     title: "",
            //     loading: true,
            //   });
            //   this.props.setPostCommentDetails({
            //     id: "Post:" + newId1,
            //     title: '',
            //     loading: false,
            //   });

            // } else {
            //   var id1 = window.location.href.toString().split("/");
            //   let newId = id1[id1.length - 1];

            //   applloClient
            //     .query({
            //       query:
            //         getCommentPost,
            //       variables: {
            //         id: "Comment:" + newId,
            //         depth: 10,
            //         sort_type: "NEW"
            //       },
            //       fetchPolicy: "no-cache",
            //     }).then(e => {
            //       // console.log(e, 'eeeeee');

            //       this.props.setPostCommentReset({
            //         payload: [],
            //         postId: "",
            //         title: "",
            //         loading: true,
            //       });

            //       this.props.setPostCommentDetails({
            //         id: "Post:" + e.data.node.post.id.replace("Post:", ""),
            //         title: e.data.node.post.title,
            //         loading: e.data.node.post.num_comments > 0 ? true : false,
            //       });
            //       this.props.setUpdatedPostId(e.data.node.post.id);
            //     })



            // }

          }
          this.props.isloading(false)

          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data.node_list.edges),
            pageInfo: response.data.node_list.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false,
            HomeFeedError: false,
            showEmptyIcon:
              response.data.node_list.edges.length == 0 ? true : false,
            loadSkeleton: false,
          });
          __self.props.setPrevList(
            {
              isLoading: false,
              loading: false,
              data: Array.from(response.data.node_list.edges),
              pageInfo: response.data.node_list.pageInfo,
              page: page + 1,
              apiCall: true,
              loadingMore: false,
              HomeFeedError: false,
            },
            "new"
          );
        })
        .catch((e) => {
          console.log(e);
          __self.setState({
            isLoading: false,
            data: [],
            loading: false,
            loadingMore: false,
            HomeFeedError: true,
            loadSkeleton: false,
          });
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        loading: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: HomeFeedMutation,
          variables: {
            first: 12,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "NEW",
            type: 'Post'
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: this.state.data.concat(response.data.node_list.edges),
            pageInfo: response.data.node_list.pageInfo,
            apiCall: true,
            loadingMore: false,
            HomeFeedError: false,
            showEmptyIcon:
              response.data.node_list.edges.length == 0 ? true : false,
          });
          this.props.setNewHomeFeed(
            this.state.data.concat(response.data.node_list.edges)
          );
          __self.props.setPrevList(
            {
              isLoading: false,
              loading: false,
              data: this.state.data.concat(response.data.node_list.edges),
              pageInfo: response.data.node_list.pageInfo,
              apiCall: true,
              loadingMore: false,
              HomeFeedError: false,
            },
            "new"
          );
        })
        .catch((e) => {
          console.log(e);
          __self.setState({
            isLoading: false,
            data: [],
            loading: false,
            loadingMore: false,
            HomeFeedError: true,
          });
        });
    }
  };

  _fetchAllTopicFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;
    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: TopicFeed,
          variables: {
            id: "Topic:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "NEW",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.props.setTrendingTopicHomeFeed(response.data.node.posts.edges);
          this.props.setPostCommentReset({
            payload: [],
            postId: "",
            title: "",
            loading: true,
          });
          if (response.data.node.posts.edges.length) {
            // this.props.setPostCommentDetails({
            //   id:
            //     "Post:" +
            //     response.data.node.posts.edges[0].node.id.replace("Post:", ""),
            //   title: response.data.node.posts.edges[0].node.title,
            //   loading: false,
            // });
          }
          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data.node.posts.edges),
            pageInfo: response.data.node.posts.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore:
              response.data.node.posts.pageInfo != null &&
                response.data.node.posts.pageInfo.hasNextPage == true
                ? true
                : false,
            showEmptyIcon:
              response.data.node.posts.edges.length == 0 ? true : false,
            loadSkeleton: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        loading: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: TopicFeed,
          variables: {
            id: "Topic:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "NEW",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: this.state.data.concat(response.data.topic.posts.edges),
            pageInfo: response.data.topic.posts.pageInfo,
            apiCall: true,
            loadingMore:
              response.data.topic.posts.pageInfo != null &&
                response.data.topic.posts.pageInfo.hasNextPage == true
                ? true
                : false,
            showEmptyIcon:
              response.data.topic.posts.edges.length == 0 ? true : false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };
  _fetchAllClikFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;
    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: ClikFeed,
          variables: {
            id: "Clik:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "NEW",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.props.setTrendingClikHomeFeed(response.data.node.posts.edges);

          // this.props.setPostCommentReset({
          //   payload: [],
          //   postId: "",
          //   title: "",
          //   loading: true,
          // });
          // this.props.setPostCommentDetails({
          //   id: response.data.node.posts.edges.length > 0 &&
          //     "Post:" +
          //     response.data.node.posts.edges[0].node.id.replace("Post:", ""),
          //   title: response.data.node.posts.edges.length > 0 && response.data.node.posts.edges[0].node.title,
          //   loading: false,
          // });
          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data.node.posts.edges),
            pageInfo: response.data.node.posts.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore:
              response.data.node.posts.pageInfo != null &&
                response.data.node.posts.pageInfo.hasNextPage == true
                ? true
                : false,
            showEmptyIcon:
              response.data.node.posts.edges.length == 0 ? true : false,
            loadSkeleton: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        loading: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: ClikFeed,
          variables: {
            id: "Clik:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "NEW",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: this.state.data.concat(response.data.clik.posts.edges),
            pageInfo: response.data.clik.posts.pageInfo,
            apiCall: true,
            loadingMore:
              response.data.clik.posts.pageInfo != null &&
                response.data.clik.posts.pageInfo.hasNextPage == true
                ? true
                : false,
            showEmptyIcon:
              response.data.clik.posts.edges.length == 0 ? true : false,
          });

        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };
  _fetchAllUserFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;
    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: UserFeedMutation,
          variables: {
            id: "User:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "NEW",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.props.setTrendingUserHomeFeed(response.data.node.posts.edges);
          // this.props.setPostCommentReset({
          //   payload: [],
          //   postId: "",
          //   title: "",
          //   loading: true,
          // });
          // if (response.data.node.posts.edges.length > 0) {
          //   this.props.setPostCommentDetails({
          //     id:
          //       "Post:" +
          //       response.data.node.posts.edges[0].node.id.replace("Post:", ""),
          //     title: response.data.node.posts.edges[0].node.title,
          //     loading: false,
          //   });
          // }
          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data.node.posts.edges),
            pageInfo: response.data.node.posts.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false,
            showEmptyIcon:
              response.data.node.posts.edges.length == 0 ? true : false,
            loadSkeleton: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        loading: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: UserFeedMutation,
          variables: {
            id: "User:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "NEW",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: this.state.data.concat(response.data.user.posts.edges),
            pageInfo: response.data.user.posts.pageInfo,
            apiCall: true,
            loadingMore: false,
            showEmptyIcon:
              response.data.user.posts.edges.length == 0 ? true : false,
          });
        })

        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };
  _fetchAllExternalFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;
    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: ExternalFeedMutation,
          variables: {
            id: "ExternalFeed:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "NEW",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.props.setTrendingExternalHomeFeed(
            response.data.node.posts.edges
          );
          // this.props.setPostCommentReset({
          //   payload: [],
          //   postId: "",
          //   title: "",
          //   loading: true,
          // });
          // if (response.data.node.posts.edges.length > 0) {
          //   this.props.setPostCommentDetails({
          //     id:
          //       "Post:" +
          //       response.data.node.posts.edges[0].node.id.replace(
          //         "Post:",
          //         ""
          //       ),
          //     title: response.data.node.posts.edges[0].node.title,
          //     loading: false,
          //   })
          // }
          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data.node.posts.edges),
            pageInfo: response.data.node.posts.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore:
              response.data.node.posts.pageInfo != null &&
                response.data.node.posts.pageInfo.hasNextPage == true
                ? true
                : false,
            showEmptyIcon:
              response.data.node.posts.edges.length == 0
                ? true
                : false,
            loadSkeleton: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        loading: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: ExternalFeedMutation,
          variables: {
            id: "ExternalFeed:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "NEW",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: this.state.data.concat(
              response.data.node.posts.edges
            ),
            pageInfo: response.data.node.posts.pageInfo,
            apiCall: true,
            loadingMore:
              response.data.node.posts.pageInfo != null &&
                response.data.node.posts.pageInfo.hasNextPage == true
                ? true
                : false,
            showEmptyIcon:
              response.data.node.posts.edges.length == 0
                ? true
                : false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };



  _renderLogoutFooter = async() => {
    // return (
      // this.state.data.length > 0 && (
        await this.props.setUsernameModalStatus(true)

        // <View
        //   style={{
        //     alignContent: "center",
        //     alignItems: "center",
        //     paddingVertical: 15,
        //   }}
        // >
        //   <Hoverable>
        //     {(isHovered) => (
        //       <Text
        //         style={{
        //           color: isHovered == true ? ConstantColors.blueColor : "#000",
        //           fontSize: 12,
        //           textAlign: "center",
        //           fontWeight: "bold",
        //           fontFamily: ConstantFontFamily.defaultFont,
        //         }}
        //       >
        //         <Text
        //           onPress={() => this.props.setLoginModalStatus(true)}
        //           style={{
        //             textDecorationLine: "underline",
        //             fontSize: 12,
        //             textAlign: "center",
        //             fontWeight: "bold",
        //             fontFamily: ConstantFontFamily.defaultFont,
        //           }}
        //         >
        //           Login
        //         </Text>{" "}
        //         to see more...
        //       </Text>
        //     )}
        //   </Hoverable>
        // </View>
      // )
    // );
  };

  onLayout = async (event) => {
    const { width, height } = event.nativeEvent.layout;
    if (width > 0) {
      let data = document.getElementById("NewHomeFeed");
      if (data != null) {
      }
    }
  };
  _renderFooter = () => {
    if (!this.state.loadingMore)
      return (
        <View
          style={{
            // borderWidth: this.props.noPost == true ? 0 : 1,
            borderColor: "#d7d7d7",
            marginRight: 1,
            borderBottomWidth: 0,
            borderTopWidth: 0,
            // height: Dimensions.get('window').height/5,
          }}
        >
          <Text
            style={{
              fontFamily: ConstantFontFamily.defaultFont,
              fontSize: 13,
              textAlign: "center",
              paddingVertical: 10,
              color: "grey",
            }}
          >
            {(!this.state.isLoading && this.state.data?.length > 0) || (this.props.isfeed && (!this.state.showEmptyIcon || this.state.data?.length > 1)) || (!this.state.HomeFeedError && this.state.data?.length > 0)
              ? "No more posts"
              : null}
          </Text>
        </View>
      );

    if (this.state.loadingMore || this.state.isLoading)
      return (
        <View style={{ marginVertical: 20 }}>
          <ActivityIndicator animating size="large" color="#000" />
        </View>
      );
  };

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    let perLoadDataCount = this.props.listType == "Home" ? 12 : 24;
    let halfOfLoadDataCount = perLoadDataCount / 2;
    let lastAddArr = this.state.data.slice(-perLoadDataCount);

    try {
      if (
        lastAddArr[halfOfLoadDataCount] &&
        viewableItems.length > 0 &&
        this.props.loginStatus == 1
      ) {
        // FlatGrid
        if (Array.isArray(viewableItems[0].item)) {
          if (
            viewableItems[0].item.find(
              (item) => item.node.id === lastAddArr[halfOfLoadDataCount].node.id
            )
          ) {
            this._handleLoadMore(0);
          }
        }
        //FlatList
        else {
          if (
            viewableItems.find(
              (item) =>
                item.item.node.id === lastAddArr[halfOfLoadDataCount].node.id
            )
          ) {
            this._handleLoadMore(0);
          }
        }
      }
    } catch (e) {
      console.log(e, "lastAddArr", lastAddArr[halfOfLoadDataCount]);
    }
  };

  _renderItem = (item) => {
    this.getfeedID("New" + item.item.node.id);
    if (this.props.ViewMode != "Compact") {
      return this.props.isAdmin == true ? (

        this.state.loadSkeleton == true ? (
          <ShadowSkeleton />
        ) : (
          // <Suspense fallback={null}>
          <FeedList
            loginModalStatusEventParent={this.handleModal}
            item={item}
            navigation={this.props.navigation}
            ViewMode={this.props.ViewMode}
            highlight={this.makeHighlight}
            onLoadingComment={this.props.onLoadingComment}
            tabPost={this.props.tabPost}
            changeTabStatus={this.props.changeTabStatus}
            ActiveTab="New"
            deleteItem={this.deleteItemById}
            data={this.state.data}
            index={this.props.index}
            cliks={item.item.node.cliks.edges}
            users={item.item.node.users.edges}

          />
          // </Suspense>
        )
      ) :

        this.state.loadSkeleton == true ? (
          <ShadowSkeleton />
        ) : (
          // <Suspense fallback={null}>
          <FeedList
            loginModalStatusEventParent={this.handleModal}
            item={item}
            navigation={this.props.navigation}
            ViewMode={this.props.ViewMode}
            onLoadingComment={this.props.onLoadingComment}
            highlight={this.makeHighlight}
            tabPost={this.props.tabPost}
            changeTabStatus={this.props.changeTabStatus}
            ActiveTab="New"
            deleteItem={this.deleteItemById}
            data={this.state.data}
            index={this.props.index}
            cliks={item.item.node.cliks.edges}
            users={item.item.node.users.edges}
          />
          // </Suspense>
        );
    } else {
      return this.props.isAdmin == true ? (
        // <Suspense fallback={null}>
        <AdminCompactView
          loginModalStatusEventParent={this.handleModal}
          item={item}
          navigation={this.props.navigation}
          ViewMode={this.props.ViewMode}
          highlight={this.makeHighlight}
          data={this.state.data}
          cliks={item.item.node.cliks.edges}
          users={item.item.node.users.edges}
        />
        // </Suspense>
      ) : (

        // <Suspense fallback={null}>
        <CompactFeedList
          loginModalStatusEventParent={this.handleModal}
          item={item}
          navigation={this.props.navigation}
          ViewMode={this.props.ViewMode}
          highlight={this.makeHighlight}
          data={this.state.data}
          cliks={item.item.node.cliks.edges}
          users={item.item.node.users.edges}
        />
        // </Suspense>
      );
    }
  };

  deleteItemById = (id) => {
    const filteredData = this.state?.data?.filter((item) => item.node.id !== id);
    this.setState({ data: filteredData });
  };
  handleModal = (status) => {
    this.setState({
      modalVisible: status,
    });
  };

  makeHighlight = async (id, title) => {
    let newId = id.search("New") != -1 ? id : "New" + id;
    this.setState({
      activeFeed: this.getUnique(feedID).indexOf(newId),
      activeId: id,
      activeTitle: title,
    });
  };

  onClose = () => {
    this.setState({
      modalVisible: false,
    });
  };

  getfeedID = async (id) => {
    let data = feedID;
    await data.push(id);
    feedID = data;
  };

  getUnique = (array) => {
    var uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  };

  getFirebaseToken = async () => {
    if (firebase.auth().currentUser) {
      await firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(async (idToken) => {
          await AsyncStorage.setItem("userIdTokenFirebase", idToken);
          this._fetchAllHomeFeeds();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          await user
            .getIdToken(true)
            .then(async (idToken) => {
              await AsyncStorage.setItem("userIdTokenFirebase", idToken);
              this._fetchAllHomeFeeds();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  };

  render() {

    return (
      <View
        style={{
          flex: 1,
          marginTop: Dimensions.get("window").width <= 750 ? 0 : 0,
        }}
        nativeID={"NewHomeFeed"}
        onLayout={this.onLayout}

      >
        {this.state.data.length == 0 && this.state.showEmptyIcon == false && this.state.HomeFeedError == false && (
          <ShadowSkeleton />
        )}
        {
          this.state.showEmptyIcon == true && (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 20,
                borderLeftWidth: 1,
                borderRightWidth: 1,
                marginRight: 1,
                borderColor: "transparent",
              }}
            >
              <Icon
                color={"#000"}
                iconStyle={{
                  color: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                }}
                reverse
                name="file"
                type="font-awesome"
                size={20}
                containerStyle={{
                  alignSelf: "center",
                }}
              />
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  fontFamily: ConstantFontFamily.defaultFont,
                  color: "#000",
                  alignSelf: "center",
                }}
              >
                No posts
              </Text>
            </View>
          )
        }
        {this.state.HomeFeedError == true && (
          <TouchableOpacity
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginVertical: 20,
            }}
            onPress={() => this.getFirebaseToken()}

          >
            <Image
              source={require("../assets/error.png")}
              style={{
                justifyContent: "flex-start",
                height: 100,
                width: 100,
                // marginRight: 5,
                borderRadius: 5
              }}
            // resizeMode={"contain"}
            />
            {/* <Button
              onPress={() => this.getFirebaseToken()}
              title={"Try Again"}
              titleStyle={ButtonStyle.titleStyle}
              buttonStyle={ButtonStyle.backgroundStyle}
              containerStyle={ButtonStyle.containerStyle}
            /> */}
          </TouchableOpacity>
        )}
        {(this.props.ViewMode == "Default" ||
          this.props.ViewMode == "Text") && (
            <FlatList
              ref={this.flatListRef}
              // disableVirtualization={true}
              // windowSize={20}
              data={(this.props.isfeed == true && this.props.loginStatus == 1)? this.state.data :
                this.props.isfeed == true && this.props.loginStatus == 0 ? this.state.data.slice(0, 10) : this.state.data.slice(0, 1)}
              onScroll={(event) => {
                this.props.listType != "Home"
                  ? this.props.listScroll(event.nativeEvent.contentOffset.y)
                  : null;
              }}
              scrollEventThrottle={16}
              keyExtractor={(item, index) => item.node.id}
              renderItem={this._renderItem}
              showsVerticalScrollIndicator={false}
              onEndReached = {()=>this.props.loginStatus == 0 ? this.props.setUsernameModalStatus(true) : null}
              onEndReachedThreshold={0.2}
              initialNumToRender={8}
              removeClippedSubviews={false}
              ListFooterComponent={
                (this.props.loginStatus == 1
                  && this.props.isfeed == true )? this._renderFooter  :
                  // (this.props.loginStatus == 0
                  //   && this.props.isfeed == true )? this._renderLogoutFooter : 
                     null
              }
              onViewableItemsChanged={this.onViewableItemsChanged}
              viewabilityConfig={this.viewabilityConfig}

            />
          )}
        {this.props.ViewMode == "Card" && (
          <FlatGrid
            ref={this.flatListRef}
            itemDimension={250}
            // contentContainerStyle={{ flex: 1 }}
            items={this.state.data}
            renderItem={this._renderItem}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            onScroll={(event) => {
              this.props.listType != "Home"
                ? this.props.listScroll(event.nativeEvent.contentOffset.y)
                : null;
            }}
            scrollEventThrottle={16}
            spacing={10}
            style={{
              flexDirection: "column",
              height: Dimensions.get("window").height,
              paddingTop: 0,
            }}
            itemContainerStyle={{
              justifyContent: "flex-start",
            }}
            onEndReachedThreshold={0.02}
            initialNumToRender={8}
            removeClippedSubviews={true}
            ListFooterComponent={
              this.props.loginStatus == 1
                ? this._renderFooter
                : this._renderLogoutFooter
            }
          />
        )}
        {this.props.ViewMode == "Compact" && (
          <FlatList
            ref={this.flatListRef}
            contentContainerStyle={{
              flexDirection: "column",
              // flex: 1,
              height: Dimensions.get("window").height,
              width: "100%",
            }}
            onScroll={(event) => {
              this.props.listType != "Home"
                ? this.props.listScroll(event.nativeEvent.contentOffset.y)
                : null;
            }}
            scrollEventThrottle={16}
            data={this.state.data}
            keyExtractor={(item, index) => index.toString()}
            renderItem={this._renderItem}
            showsVerticalScrollIndicator={false}
            onEndReachedThreshold={0.1}
            initialNumToRender={8}
            removeClippedSubviews={true}
            ListFooterComponent={
              this.props.loginStatus == 1
                ? this._renderFooter
                : this._renderLogoutFooter
            }
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.get("loginStatus"),
  isAdmin: state.AdminReducer.get("isAdmin"),
  isfeed: state.AdminReducer.get("isfeed"),
});

const mapDispatchToProps = (dispatch) => ({
  setPostDetails: (payload) => dispatch(setPostDetails(payload)),
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  setNewHomeFeed: (payload) => dispatch({ type: "SET_NEW_HOME_FEED", payload }),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
  setPostCommentReset: (payload) =>
    dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  feedFluctuation: (payload) => dispatch(feedFluctuation(payload)),
  setTrendingClikHomeFeed: (payload) =>
    dispatch({ type: "SET_TRENDING_CLIK_HOME_FEED", payload }),
  setTrendingTopicHomeFeed: (payload) =>
    dispatch({ type: "SET_TRENDING_TOPIC_HOME_FEED", payload }),
  setTrendingUserHomeFeed: (payload) =>
    dispatch({ type: "SET_TRENDING_USER_HOME_FEED", payload }),
  setTrendingExternalHomeFeed: (payload) =>
    dispatch({ type: "SET_TRENDING_EXTERNAL_HOME_FEED", payload }),
  setUpdatedPostId: (payload) =>
    dispatch({ type: "SET_UPDATED_POSTID", payload }),
  clikHover: (payload) => dispatch(clikHover(payload)),
  isloading: (payload) => dispatch(isloading(payload)),
  setUsernameModalStatus: (payload) =>
    dispatch(setUSERNAMEMODALACTION(payload)),

});


export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NewHomeFeed
);
