import { takeEvery, put } from 'redux-saga/effects';
import { SIGNUPMODALACTION, SIGNUPMODALACTION_SUCCESS, SIGNUPMODALACTION_FAILURE } from "../constants/Action";

export function* SignUpModalMiddleware({ payload }) {
    if (payload == true) {
        yield put({
            type: SIGNUPMODALACTION_SUCCESS,
            payload: payload
        });
    } else {
        yield put({
            type: SIGNUPMODALACTION_FAILURE,
            payload: payload
        });
    }
}

export default function* SignUpModalMiddlewareWatcher() {
    yield takeEvery(SIGNUPMODALACTION, SignUpModalMiddleware);
}