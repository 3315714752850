const defaultFont = "Helvetica";
const HeaderBoldFont = "HelveticaBold";

const Avenir = "Avenir";
const Helvetica = "Helvetica";
const HelveticaBold = "HelveticaBold";


export default {  
  HeaderBoldFont,
  defaultFont,
  Helvetica,
  HelveticaBold
};
