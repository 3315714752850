import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import initialState from './redux-initial-store';
import sagas from './Middleware';
// import { AsyncStorage } from 'react-native';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import immutableTransform from 'redux-persist-transform-immutable';

export default function configStore() {
  const preloadedState = fromJS(initialState);
  const persistConfig = {
    transforms: [immutableTransform()],
    key: 'weclikStorage',
    storage,
  }
  const composeEnhancers =
    process.env.NODE_ENV === 'production'
      ? compose
      : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const saga = createSagaMiddleware();
  const middlewares = [saga];
  const persistedReducer = persistReducer(persistConfig, createReducer())
  // if (__DEV__) {
  //   middlewares.push(createLogger());
  // }
  const store = createStore(
    persistedReducer,
    undefined,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  const persistor = persistStore(store);
  saga.run(sagas);
  return { store, persistor };
}