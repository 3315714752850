import { List } from "immutable";
import React from "react";
import { graphql } from "react-apollo";
import {
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  FlatList,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import applloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import {
  UserFollowMutation,
  UserUnfollowMutation,
} from "../graphqlSchema/graphqlMutation/FollowandUnFollowMutation";
import { UserLoginMutation } from "../graphqlSchema/graphqlMutation/UserMutation";
import {
  UserFollowVariables,
  UserUnfollowVariables,
} from "../graphqlSchema/graphqlVariables/FollowandUnfollowVariables";
import NavigationService from "../library/NavigationService";
import { Button, Icon } from "react-native-elements";
import UserStar from "./UserStar";
import { SearchUserMutation } from "../graphqlSchema/graphqlMutation/SearchMutation";
import { SearchUserVariables } from "../graphqlSchema/graphqlVariables/SearchVariables";
import { Trending_Users_Mutation } from "../graphqlSchema/graphqlMutation/TrendingMutation";
import ButtonStyle from "../constants/ButtonStyle";
import { feedFluctuation,screen } from '../reducers/action/Completed'
import ConstantColors from "../constants/Colors";

class TrendingUsers extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onEndReachedCalledDuringMomentum = true;
    this.viewabilityConfig = {
      viewAreaCoveragePercentThreshold: 50,
    };
    this.state = {
      searchedFollowText: this.props.searchedFollowText,
      listTrending_users: [],
      page: 1,
      loading: true,
      loadingMore: false,
      refreshing: false,
      pageInfo: null,
      error: null,
      apiCall: true,
      followError: false,
      setUserName: ""

    };
  }

  goToProfile = (username) => {
    this.setState({ setUserName: username })

    this.props.screen('profileScreen')
    this.props.userId({
      username: username,
      type: "feed",
    });
    NavigationService.navigate("profile", {
      username: username,
      type: "feed",
      postId: " ",
    });
    this.props.setPostCommentReset({
      payload: [],
      postId: "",
      title: "",
      loading: true,
    });
    this.props.leftPanelModalFunc(false);
    this.props.feedFluctuation(true)
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.searchedFollowText != this.props.searchedFollowText) {
      await this.userSearch(this.props.searchedFollowText);
    } else if (prevProps.searchedFollowText == "") {
      //this.setState({ listTrending_users: this.props.listTrending_users });
    }

    if (prevProps.getProfileUpload != this.props.getProfileUpload) {
      this.setState({ pageInfo: null, page: 1, }, () => {

        applloClient
          .query({
            query: Trending_Users_Mutation,
            variables: {
              type: "User",
              first: 20,
              after: this.state.pageInfo ? this.state.pageInfo.endCursor : null,
              sort_type: "TRENDING",
            },
            fetchPolicy: "no-cache",
          })
          .then((res) => {
            this.setState({
              loading: false,
              listTrending_users: this.getUserList(res.data.node_list.edges),
              pageInfo: res.data.node_list.pageInfo,
              page: this.state.page + 1,
              apiCall: true,
              loadingMore: false,
              followError: false

            }, () => {
              this.props.userProfileUpload(false)
            });
          })
          .catch((e) => {
            console.log(e);
            this.setState({
              followError: true
            })
          });
      })

    }
  };

  userSearch = (search) => {
    this.setState({ search });
    let tempData = [];
    let tempArray = [];

    if (search.length > 0) {
      SearchUserVariables.variables.prefix = search;
      applloClient
        .query({
          query: SearchUserMutation,
          ...SearchUserVariables,
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          tempArray = res.data.search.users;
          for (let i = 0; i < tempArray.length; i++) {
            tempData.push({ node: tempArray[i] });
          }
          this.setState({
            listTrending_users: tempData,
          });
        });
    } else {
      this.setState({ pageInfo: null }, () => {
        this.getTrendingUserList();
      });
      // this.setState({ listTrending_users: this.props.listTrending_users });
    }
  };

  componentDidMount = () => {
    this.getTrendingUserList();
    this.props.userProfileUpload(false)
  };

  _handleRefresh = () => {
    this.setState(
      {
        page: 1,
        refreshing: true,
      },
      () => {
        this.getTrendingUserList();
      }
    );
  };

  _handleLoadMore = (distanceFromEnd) => {
    this.setState(
      (prevState, nextProps) => ({
        //  loadingMore: true
      }),
      () => {
        if (
          0 <= distanceFromEnd &&
          distanceFromEnd <= 5 &&
          this.state.apiCall == true
        ) {
          this.setState({
            apiCall: false,
          });
          this.getTrendingUserList();
        }
      }
    );
  };

  _renderFooter = () => {
    if (!this.state.loadingMore || this.state.followError) return null;
    return (
      <View>
        {this.state.listTrending_users.length > 0 ? <ActivityIndicator animating size="large" color="#000" /> : null}
      </View>
    );
  };

  getTrendingUserList = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
      });
      applloClient
        .query({
          query: Trending_Users_Mutation,
          variables: {
            first: 20,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
            type: "User"
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          __self.setState({
            loading: false,
            listTrending_users: this.getUserList(res.data.node_list.edges),
            pageInfo: res.data.node_list.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false,
            followError: false
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            followError: true
          })
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
      });
      applloClient
        .query({
          query: Trending_Users_Mutation,
          variables: {
            type: "User",
            first: 20,
            after: this.state.pageInfo ? this.state.pageInfo.endCursor : null,
            sort_type: "TRENDING",
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          __self.setState({
            loading: false,
            listTrending_users: __self.state.listTrending_users.concat(
              this.getUserList(res.data.node_list.edges)
            ),
            pageInfo: res.data.node_list.pageInfo,
            apiCall: true,
            loadingMore: false,
            followError: false
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            followError: true
          })
        });
    }
  };

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    let perLoadDataCount = 20;
    let halfOfLoadDataCount = perLoadDataCount / 2;
    let lastAddArr =
      this.state.listTrending_users.length > 0 &&
      this.state.listTrending_users.slice(-perLoadDataCount);
    try {
      if (lastAddArr[halfOfLoadDataCount] && viewableItems.length > 0) {
        viewableItems.find((item) => {
          let userId = item.item.node ? item.item.node.id : item.item.user.id;
          let LastuserId = lastAddArr[halfOfLoadDataCount].node
            ? lastAddArr[halfOfLoadDataCount].node.id
            : lastAddArr[halfOfLoadDataCount].user.id;
          if (userId === LastuserId) {
            this._handleLoadMore(0);
          }
        });
      }
    } catch (e) {
      console.log(e, "lastAddArr", lastAddArr[halfOfLoadDataCount]);
    }
  };

  getUserList = (item) => {
    let newArray = [];
    let getFollowList = []
    let newArray1 = []
    if (this.state.listTrending_users.length > 0) {
      item.forEach((obj) => {
        let index = this.props.getUserFollowUserList.findIndex(
          (i) => i.node.user.id == obj.node.id
        );
        if (index == -1) {
          newArray.push({ ...obj });
        }
      });
      return newArray
    } else {
      item.forEach((obj) => {
        let index = this.props.getUserFollowUserList.findIndex(
          (i) => i.node.user.username == obj.node.username
        );
        if (index == -1) {
          newArray.push({ ...obj });
        }
      });
      this.props.getUserFollowUserList.map(e => {
        getFollowList.push({ node: e.node.user, __typename: "WeclikdNodeEdge" })
      })

      // newArray1 = getFollowList.concat(newArray)
      return newArray
    }

  };

  _renderItem = (item) => {
    var row = item.item.node ? item.item.node : item.item.user;

    return (
      <Hoverable>
        {(isHovered) => (
          <View
            key={item.index}
            style={{
              overflow: "hidden",
              padding: 2,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              backgroundColor: '#fff'
              // isHovered ? '#d3d3d3' : ConstantColors.whiteBackground,
            }}
          >

            <TouchableOpacity
              onPress={() => this.goToProfile(row?.username ? row.username : row?.user?.username)}
              style={{
                flexDirection: "row",
                borderRadius: 4,
                overflow: "hidden",
                width: "85%",
              }}
            >
              <View
                style={{
                  //width: "20%",
                  margin: 0,
                  flexDirection: "row",
                  backgroundColor: "rgba(255,255,255,0.5)",
                }}
              >
                {row.profile_pic ? (
                  <Image
                    source={{
                      uri: row.profile_pic.slice(-3) == "000" || row.profile_pic.slice(-2) == "00" ? require("../assets/image/default-image.png") : row.profile_pic,
                    }}
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: 18,
                    }}
                  />
                ) : (
                  <Image
                    source={require("../assets/image/default-image.png")}
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: 18,
                    }}
                  />
                )}
              </View>
              <View
                style={{
                  //width: "80%",
                  alignSelf: "center",
                  marginLeft: "5%",
                }}
              >
                <Text
                  style={[ButtonStyle.clikNameTitleStyle, {
                    textAlign: "left",
                    color: (this.state.setUserName == (row?.username ? row.username : row?.user?.username)) ? ConstantColors.blueColor : isHovered == true ? ConstantColors.blueColor: '#000',
                    textDecorationLine:
                      isHovered == true ? "underline" : "none",
                  }]}
                >
                  @{row?.username ? row.username : row?.user?.username}
                </Text>
              </View>
            </TouchableOpacity>


            <UserStar
              UserName={row?.id.toLowerCase() ? row.id.toLowerCase() : row?.user?.id.toLowerCase()}
              UserId={row?.id ? row.id : row.user.id}
              ContainerStyle={{
                flex: 1,
                justifyContent: "flex-end",
                width: "20%",
                paddingLeft: 15,
              }}
              ImageStyle={{
                height: 16,
                width: 16,
                alignSelf: "flex-end",
              }}
              updateStar={this.updateStar}
            />
          </View>
        )}
      </Hoverable>
    );
    //}
  };

  updateStar = async () => {
    await this.setState({
      pageInfo: null,
    });
    this.getTrendingUserList();
  };

  render() {
    return (
      <View>
        {this.state.followError == true && (
          <TouchableOpacity
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginVertical: 20,
            }}
            onPress={() => window.location.reload()}

          >
            <Image
              source={require("../assets/error.png")}
              style={{
                justifyContent: "flex-start",
                height: 100,
                width: 100,
                // marginRight: 5,
                borderRadius: 5
              }}
            // resizeMode={"contain"}
            />
            {/* <Button
              onPress={() => this.getFirebaseToken()}
              title={"Try Again"}
              titleStyle={ButtonStyle.titleStyle}
              buttonStyle={ButtonStyle.backgroundStyle}
              containerStyle={ButtonStyle.containerStyle}
            /> */}
          </TouchableOpacity>
        )}
        {this.props.searchedFollowText == "" && this.props.getUserFollowUserList.map((row, index) => {
          // console.log(row);
          return (
            <Hoverable>
              {(isHovered) => (
                <View
                  key={index}
                  style={{
                    overflow: "hidden",
                    padding: 2,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    backgroundColor: '#fff'
                    // isHovered ? '#d3d3d3' : ConstantColors.whiteBackground,
                  }}
                >

                  <TouchableOpacity
                    onPress={() => this.goToProfile(row?.node?.user?.username ? row.node.user.username : row?.user?.username)}
                    style={{
                      flexDirection: "row",
                      borderRadius: 4,
                      overflow: "hidden",
                      width: "85%",
                    }}
                  >
                    <View
                      style={{
                        //width: "20%",
                        // marginVertical: 5,
                        flexDirection: "row",
                        backgroundColor: "rgba(255,255,255,0.5)",
                      }}
                    >
                      {row.profile_pic ? (
                        <Image
                          source={{
                            uri: row?.node?.user?.profile_pic == null ? require("../assets/image/default-image.png") : row?.node?.user?.profile_pic,
                          }}
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: 18,
                          }}
                        />
                      ) : (
                        <Image
                          source={require("../assets/image/default-image.png")}
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: 18,
                          }}
                        />
                      )}
                    </View>
                    <View
                      style={{
                        //width: "80%",
                        alignSelf: "center",
                        marginLeft: "5%",
                      }}
                    >
                      <Text
                        style={[ButtonStyle.clikNameTitleStyle, {
                          textAlign: "left",
                          color: (this.state.setUserName == (row?.username ? row.username : row?.user?.username)) ? ConstantColors.blueColor : isHovered == true ? ConstantColors.blueColor: '#000',
                          textDecorationLine:
                            isHovered == true ? "underline" : "none",
                          
                        }]}
                      >
                        @{row?.node?.user?.username ? row.node?.user?.username : row?.user?.username}
                      </Text>
                    </View>
                  </TouchableOpacity>


                  <UserStar
                    UserName={row?.node?.user?.id.toLowerCase() ? row.node?.user?.id.toLowerCase() : row?.user?.id.toLowerCase()}
                    UserId={row?.node?.user?.id ? row.node.user.id : row.user.id}
                    ContainerStyle={{
                      flex: 1,
                      justifyContent: "flex-end",
                      width: "20%",
                      paddingLeft: 15,
                    }}
                    ImageStyle={{
                      height: 16,
                      width: 16,
                      alignSelf: "flex-end",
                    }}
                    updateStar={this.updateStar}
                  />
                </View>
              )}
            </Hoverable>
          )
        })}
        {/* {this.props.searchedFollowText == "" && this.props.loginStatus == 1 && this.props?.getUserFollowUserList?.length > 0 ?

          <View style={{ width: "100%", height: 1, backgroundColor: "#D5D8DB", marginVertical: 15 }}></View>

          : null} */}
        {this.state.followError == false && <FlatList
          extraData={this.state}
          contentContainerStyle={{
            flexDirection: "column",
            height: Dimensions.get("window").height,
            width: "100%",
          }}
          data={this.state.listTrending_users}
          keyExtractor={(item, index) => index.toString()}
          renderItem={this._renderItem}
          showsVerticalScrollIndicator={false}
          onRefresh={this._handleRefresh}
          refreshing={this.state.refreshing}
          scrollEnabled={false}

          // onEndReached={({ distanceFromEnd }) => {
          //   this._handleLoadMore(distanceFromEnd);
          // }}
          onEndReachedThreshold={0.2}
          initialNumToRender={10}
          ListFooterComponent={this._renderFooter}
          onViewableItemsChanged={this.onViewableItemsChanged}
          viewabilityConfig={this.viewabilityConfig}
        />}

      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  listTrending_users: !state.TrendingUsersReducer.getIn(["Trending_users_List"])
    ? List()
    : state.TrendingUsersReducer.getIn(["Trending_users_List"]),
  getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
    ? state.LoginUserDetailsReducer.userFollowUserList
    : [],
  loginStatus: state.UserReducer.get("loginStatus"),
  getProfileUpload: state.AdminReducer.get("profileUpload"),
});

const mapDispatchToProps = (dispatch) => ({
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  setPostCommentReset: (payload) =>
    dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  leftPanelModalFunc: (payload) =>
    dispatch({ type: "LEFT_PANEL_OPEN", payload }),
  userProfileUpload: (payload) =>
    dispatch({ type: "PROFILE_UPLOAD", payload }),
  feedFluctuation: payload => dispatch(feedFluctuation(payload)),
  screen: (payload) => dispatch(screen(payload))

});

// const TrendingUsersWrapper = compose(
//   graphql(UserLoginMutation, {
//     name: "Login",
//     options: {
//       variables: {
//         id: "Account:" + "CurrentUser"
//       },
//       fetchPolicy: "no-cache"
//     }
//   })
// )(TrendingUsers);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TrendingUsers
);
