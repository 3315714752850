import { call, put, takeEvery } from "redux-saga/effects";
import applloClient from "../client";
import {
  USER_PROFILE_DETAILS,
  USER_PROFILE_DETAILS_FAILURE,
  USER_PROFILE_DETAILS_SUCCESS
} from "../constants/Action";
import { UserQueryMutation } from "../graphqlSchema/graphqlMutation/UserMutation";
import { UserDetailsVariables } from "../graphqlSchema/graphqlVariables/UserVariables";
import NavigationService from "../library/NavigationService";
// import {
//   AsyncStorage
// } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { get404 } from '../reducers/action/Completed'

const getResponse = async req => {
  UserDetailsVariables.variables.id = "User:" + req.username;
  return await applloClient
    .query({
      query: UserQueryMutation,
      ...UserDetailsVariables,
      fetchPolicy: "no-cache"
    })
    .then(res => res);
};

export function* UserProfileDetailsMiddleware({ payload }) {
  try {
    const response = yield call(getResponse, payload);
    if (response.data.node == null) {
      // NavigationService.navigate("404");
      // yield put(get404(true))
    } else{
    if (response.data.node.id == "User:None") {
      yield put({
        type: USER_PROFILE_DETAILS_FAILURE,
        payload: {}
      });
      NavigationService.navigate("404");
      yield put(get404(true))
    } else {
      if (
        payload.type.toLowerCase() == "feed" ||
        payload.type.toLowerCase() == "profile" ||
        payload.type.toLowerCase() == "comments"
      ) {
        yield put({
          type: USER_PROFILE_DETAILS_SUCCESS,
          payload: response.data
        });


        AsyncStorage.setItem(
          'ProfileTitle',
          JSON.stringify({ type: payload.type, ProfileTitle: payload.username })
        );
        let postId;
        yield AsyncStorage.getItem('PostId').then(houses => { postId = JSON.parse(houses); });

        // NavigationService.navigate("profile", {
        //   username: response.data.node.username,
        //   type: payload.type.toLowerCase(),
        //   postId: ' '
          //, 
          //postId:postId
        // });

        // NavigationService.navigate("profile", {
        //   username: response.data.user.username,
        //   type: payload.type.toLowerCase()
        // });
      } else {
        yield put({
          type: USER_PROFILE_DETAILS_FAILURE,
          payload: {}
        });
        NavigationService.navigate("404");
      yield put(get404(true))

      }
    }
  }
  } catch (err) {
    yield put({
      type: USER_PROFILE_DETAILS_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* UserProfileDetailsMiddlewareWatcher() {
  yield takeEvery(USER_PROFILE_DETAILS, UserProfileDetailsMiddleware);
}
