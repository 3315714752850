import {FOCUS_SUCCESS,FOCUS_FAILURE} from '../constants/Action';

const initialState = {
    isFocus:false
}

const FocusReducer = (state = initialState, action) => {
    switch (action.type) {
      case FOCUS_SUCCESS: {
        return {
            isFocus: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default FocusReducer;