import { launchImageLibraryAsync } from "expo-image-picker";
import { askAsync, CAMERA_ROLL } from "expo-permissions";
import { List } from "immutable";
import React, { lazy, Component, Suspense } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  Animated,
  // AsyncStorage,
  Dimensions,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Button, Icon, Tooltip } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import applloClient from "../client";
import AppHelper from "../constants/AppHelper";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import {
  EditTopicMutation,
} from "../graphqlSchema/graphqlMutation/PostMutation";
import {
  GetChildTopicsMutation,
  TopicQuery,
  ParentTopic
} from "../graphqlSchema/graphqlMutation/TrendingMutation";
import {
  SearchTopicMutation,
} from "../graphqlSchema/graphqlMutation/SearchMutation";
import {
  TopicFollowingMutation
} from "../graphqlSchema/graphqlMutation/UserMutation";
import {
  EditTopicVariables,
} from "../graphqlSchema/graphqlVariables/PostVariables";
import {
  SearchTopicVariables,
} from "../graphqlSchema/graphqlVariables/SearchVariables";

import { uploadProfileImageAsync } from "../services/UserService";
import ButtonStyle from "../constants/ButtonStyle";
import NavigationService from "../library/NavigationService";

const TataStructure = lazy(() => import("../components/TataStructure"))

const BottomScreen = lazy(() => import("../components/BottomScreen"))




class EditTopicScreen extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.Pagescrollview = null;
    this.state = {
      uploading: false,
      items: [],
      changeBackPicEnable: this.props.topicdetails.get("changeBackPicEnable"),
      setBackPic: this.props.topicdetails.get("profile_pic"),
      showsVerticalScrollIndicatorView: false,
      currentScreentWidth: 0,
      focusDesc: false,
      focusParentTopic: false,
      focusTopicname: false,
      topicName: this.props.topicdetails.get("topicName"),
      rtopicName: this.props.topicdetails.get("rtopicName"),
      description: this.props.topicdetails.get("description"),
      topic_id: this.props.topicdetails.get("topic_id"),
      clikHover: false,
      descriptionHover: false,
      TopicList: [],
      TopicListHierarchy: [],
      showDeleteIcon: false,
      inputParentName: false,
      rtopicParents: "#000",
      parentsOfSelectedTopic: [],
      changeIconPicEnable: ""

    };
    this.baseState = this.state;
  }

  componentDidMount = async () => {
    this.props.searchOpenBarStatus(false);
    this._isMounted = true;
    if (this.state.rtopicName) {
      this.renderTopicListHierarchy(this.state.rtopicName);
      this.getTopicProfileDetails(this.state.rtopicName);
    }
  };


  pickIcon = async () => {
    await this._askPermission(
      CAMERA_ROLL,
      "We need the camera-roll permission to read pictures from your phone..."
    );
    let pickerResult = await launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [4, 3],
      base64: true,
    });
    let size = pickerResult.uri.length * (3 / 4) - 2;
    this._handleIconImagePicked(pickerResult);
  };

  _handleIconImagePicked = async (pickerResult) => {
    let uploadResponse, uploadResult;
    try {
      this.setState({ uploading: true });
      if (!pickerResult.cancelled) {
        uploadResponse = await uploadProfileImageAsync(pickerResult.uri, true);
        uploadResult = await uploadResponse.json();
        this.setState({
          setBackPic: pickerResult.uri,
        });

        this.setState({ changeIconPicEnable: uploadResult.id });
      }
    } catch (e) {
      alert("Upload failed, sorry :(" + e + ")");
    } finally {
      this.setState({ uploading: false });
    }
  };

  componentDidUpdate() {
    if (this.props.getHasScrollTop == true && this.Pagescrollview) {
      this.Pagescrollview.scrollTo({ x: 0, y: 0, animated: true });
      this.props.setHASSCROLLEDACTION(false);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _askPermission = async (type, failureMessage) => {
    const { status, permissions } = await askAsync(type);
    if (status === "denied") {
      alert(failureMessage);
    }
  };


  editTopic = async () => {
    let __self = this;


    EditTopicVariables.variables.topic_id = this.state.topic_id;
    EditTopicVariables.variables.name = this.state.topicName;
    EditTopicVariables.variables.description = this.state.description;
    EditTopicVariables.variables.profile_pic = this.state.changeIconPicEnable?.toString();
    EditTopicVariables.variables.parent_topic_id = this.state.topic_id;
    try {
      let id = await AsyncStorage.getItem("UserId");
      await applloClient
        .query({
          query: EditTopicMutation,
          ...EditTopicVariables,
          fetchPolicy: "no-cache",
        })
        .then(async (res) => {
          this.setState(this.baseState);
          await applloClient
            .query({
              query: TopicFollowingMutation,
              variables: {
                id: "User:CurrentUser",
                first: 50
              },
              fetchPolicy: "no-cache"
            }).then(e => {


              let userData = {
                data: this.props.profileData,
                externalfollow: this.props.getUserFollowFeedList,
                clikfollow: this.props.getUserFollowCliksList,
                topicfollow: e.data.node.topics_following.edges,
                userfollow: this.props.getUserFollowUserList

              }
              this.props.saveLoginUser(userData);
            })
          if (this.props.profileData) {
            await __self.props.getTrendingTopics({
              currentPage: AppHelper.PAGE_LIMIT,
            });
            this.props.updateTopicStar(true)
            __self.props.navigation.navigate("home");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  checkTopicname = async (name) => {
    var letters = /^[0-9a-zA-Z-]+$/;
    if (name.match(letters) || name == "") {
      if (name[0] == "-") {
        alert("- not allowed at initial of clik name");
        return false;
      }
      this.setState({ topicName: name });
      return true;
    } else {
      alert("Please input alphanumeric characters only");
      return false;
    }
  };

  checkRTopicname = async (name) => {
    var letters = /^[0-9a-zA-Z-]+$/;
    if (name.match(letters) || name == "") {
      if (name[0] == "-") {
        alert("- not allowed at initial of clik name");
        return false;
      }
      this.setState(
        {
          rtopicName: name,
          TopicListHierarchy: [],
          rtopicParents: "#000",
        },
        () => {
          this.customRenderTopicSuggestion(name);
        }
      );
      return true;
    } else {
      alert("Please input alphanumeric characters only");
      return false;
    }
  };

  customRenderTopicSuggestion = (value) => {
    SearchTopicVariables.variables.prefix = value;
    applloClient
      .query({
        query: SearchTopicMutation,
        ...SearchTopicVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        this.setState({
          TopicList: res.data.search.topics,
          TopicListHierarchy: [],
        });
      });
  };

  getTopicProfileDetails = (value) => {
    applloClient
      .query({
        query: TopicQuery,
        variables: {
          id: "Topic:" + value,
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        if (res) {
          await applloClient.query({
            query: ParentTopic,
            variables: {
              id: "Topic:" + value,
            },
            fetchPolicy: "no-cache",
          }).then(e => {
            this.setState({
              parentsOfSelectedTopic: res.data.node.parent_topic
                ? [...res.data.node.parent_topic, value]
                : [value],

            });
          })



        }

      });
  };
  getChildStructure(arr, selectTopic, childrenOfSelectTopic) {
    let output = [];
    let i = 0;
    if (arr.length > 0)
      output[i] = {
        name: arr[i],
        children:
          selectTopic === arr[i]
            ? [...childrenOfSelectTopic]
            : this.getChildStructure(
              arr.slice(i + 1),
              selectTopic,
              childrenOfSelectTopic
            ),
      };

    return output;
  }
  renderTopicListHierarchy = (value) => {
    this.setState({
      TopicListHierarchy: [],
    });
    if (value) {
      applloClient
        .query({
          query: GetChildTopicsMutation,
          variables: {
            id: "Topic:" + value,
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          let { subtopics } = res.data.node;
          this.setState({
            TopicListHierarchy: this.getChildStructure(
              this.state.parentsOfSelectedTopic,
              value,
              [{ name: "dynamicText" }, ...subtopics]
            ),
          });
        });
    }
  };

  handleTopicSelectInput = (topic, parents, item) => {
    this.setState(
      {
        rtopicName: topic,
        TopicList: [],
        TopicListHierarchy: [],
      },
      () => {
        if (parents) {
          this.colorCondition(parents);
        } else {
          this.colorCondition("");
        }
        if (topic != "") {
          if (item.banner_pic) {
            let v = item.banner_pic.split("/");
            let k = v[v.length - 1].substring(0, v[v.length - 1].indexOf("."));
            this.setState({
              setBackPic: item.banner_pic,
              showDeleteIcon: true,
              changeBackPicEnable: k,
            });
          }

          this.getTopicProfileDetails(topic);
          this.renderTopicListHierarchy(topic);
        }
        if (this.state.rtopicName == "") {
          this.setState({
            rtopicParents: "",
            TopicList: [],
          });
        }
      }
    );
  };

  colorCondition = (parent) => {
    if (parent.length > 0) {
      this.setState({
        rtopicParents: "#009B1A",
      });
    } else if (parent == "") {
      this.setState({
        rtopicParents: "#009B1A",
      });
    } else if (parent == null) {
      this.setState({
        rtopicParents: "#009B1A",
      });
    }
  };

  render() {
    const textStyle = styles.usertext;
    return (
      <View
        style={[
          {
            flex: 1,
            backgroundColor: ConstantColors.customeBackgroundColor,
            width: "100%",
          },
        ]}
      >
        <ScrollView
          ref={(scrollview) => {
            this.Pagescrollview = scrollview;
          }}
          showsVerticalScrollIndicator={false}
          onLayout={(event) => {
            let { x, y, width, height } = event.nativeEvent.layout;
            if (width < 1024) {
              this.setState({
                showsVerticalScrollIndicatorView: true,
                currentScreentWidth: width,
              });
            } else {
              this.setState({
                showsVerticalScrollIndicatorView: false,
                currentScreentWidth: width,
              });
            }
          }}
          style={{
            height:
              Platform.OS !== "web"
                ? null
                : Dimensions.get("window").height - 80,
          }}
        >
          <View>
            {Dimensions.get("window").width <= 750 && (
              <Animated.View
                style={{
                  position: Platform.OS == "web" ? "sticky" : null,
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  overflow: "hidden",
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      backgroundColor: "#000",
                      height: 50,
                    }}
                  >
                    <TouchableOpacity
                      style={ButtonStyle.headerBackStyle}
                      onPress={() => {
                        this.props.searchOpenBarStatus(false);
                        let nav =
                          this.props.navigation.dangerouslyGetParent().state;
                        if (nav.routes.length > 1) {
                          this.props.navigation.goBack();
                          return;
                        } else {
                          this.props.navigation.navigate("home");
                        }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        ButtonStyle.headerTitleStyle,
                        { backgroundColor: "#000" },
                      ]}
                    >
                      <Text
                        style={{
                          color: "#fff",
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: 18,
                          fontFamily: ConstantFontFamily.defaultFont,
                        }}
                      >
                        Edit Topic
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Animated.View>
            )}

            <View
              style={[
                Dimensions.get("window").width >= 1200
                  ? ButtonStyle.normalFeedStyleForDashboard
                  : null,
                {
                  backgroundColor: "#fff",
                  paddingHorizontal: 10,
                  marginTop: Dimensions.get("window").width <= 1200 ? 10 : 30,
                  paddingHorizontal:
                    Dimensions.get("window").width <= 1200 ? 5 : 10,
                  marginHorizontal:
                    Dimensions.get("window").width <= 1200 ? 0 : 10,

                  paddingTop: 10,

                },
              ]}
            >

              <View
                style={{
                  height: 120,
                  borderWidth: 1,
                  borderColor: "#e6e6e6",
                  backgroundColor: "#fff",
                  borderRadius: Platform.OS == "web" ? 5 : null,
                  width: 120,
                }}
              >
                <ImageBackground
                  style={styles.image}
                  imageStyle={{
                    borderRadius: 5,
                  }}
                  source={{
                    uri: this.state.setBackPic,
                  }}
                  resizeMode={"cover"}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    reverse
                    name="camera"
                    type="font-awesome"
                    size={16}
                    containerStyle={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 1,
                    }}
                    onPress={this.pickIcon}
                  />
                </ImageBackground>
              </View>

              <View style={{ flexDirection: "row", width: "100%" }}>
                <View
                  style={{
                    width: "90%",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      marginTop: 10,
                      marginRight: 20,
                      fontSize: 16,
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                  >
                    Topic Name
                  </Text>
                </View>
                <TouchableOpacity
                  style={{
                    width: "10%",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                  onMouseEnter={() => this.setState({ clikHover: true })}
                  onMouseLeave={() => this.setState({ clikHover: false })}
                >
                  {(this.state.topicName.length < 3 ||
                    this.state.topicName.length > 24 ||
                    this.state.topicName[this.state.topicName.length - 1] ==
                    "-") && (
                      <Icon
                        color={"#f80403"}
                        iconStyle={{
                          marginTop: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        name="times"
                        type="font-awesome"
                        size={16}
                      />
                    )}
                  {this.state.clikHover == true && Platform.OS == "web" ? (
                    <Tooltip
                      backgroundColor={"#d3d3d3"}
                      withPointer={false}
                      withOverlay={false}
                      toggleOnPress={true}
                      containerStyle={{
                        left: -60,
                        top: -60,
                      }}
                      popover={
                        <Text>Topic Name should be 3 to 24 Characters</Text>
                      }
                    />
                  ) : null}
                </TouchableOpacity>
              </View>


              <View style={{ flexDirection: "row", width: "100%" }}>
                <TextInput
                  value={this.state.topicName}
                  placeholder="2-31 characters, only alphanumeric and underscores."
                  placeholderTextColor="#6D757F"
                  style={[
                    textStyle,
                    this.state.focusTopicname
                      ? ButtonStyle.selecttextAreaShadowStyle
                      : ButtonStyle.textAreaShadowStyle,
                    {
                      width: "100%",
                      marginTop: 5,
                      height: 45,
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      fontFamily: ConstantFontFamily.defaultFont,
                    },
                  ]}
                  onChangeText={(topicName) => this.checkTopicname(topicName)}
                  onFocus={() => this.setState({ focusTopicname: true })}
                  onBlur={() => this.setState({ focusTopicname: false })}
                />
              </View>

              <View style={{ flexDirection: "row", width: "100%" }}>
                <View
                  style={{
                    width: "90%",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      width: "70%",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginTop: 10,
                        marginRight: 20,
                        fontSize: 16,
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Parent Topic Name
                    </Text>
                  </View>
                </View>
                <TouchableOpacity
                  style={{
                    width: "10%",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                  onMouseEnter={() => this.setState({ clikHover: true })}
                  onMouseLeave={() => this.setState({ clikHover: false })}
                >
                  {(this.state.rtopicName.length < 3 ||
                    this.state.rtopicName.length > 24 ||
                    this.state.rtopicName[this.state.rtopicName.length - 1] ==
                    "-") && (
                      <Icon
                        color={"#f80403"}
                        iconStyle={{
                          marginTop: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        name="times"
                        type="font-awesome"
                        size={16}
                      />
                    )}
                  {this.state.clikHover == true && Platform.OS == "web" ? (
                    <Tooltip
                      backgroundColor={"#d3d3d3"}
                      withPointer={false}
                      withOverlay={false}
                      toggleOnPress={true}
                      containerStyle={{
                        left: -60,
                        top: -60,
                      }}
                      popover={
                        <Text>Topic1 name should be 3 to 24 Characters</Text>
                      }
                    />
                  ) : null}
                </TouchableOpacity>
              </View>

              <View style={{ flexDirection: "row", width: "100%" }}>
                <View
                  style={{
                    width: "100%",
                    marginVertical: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInput
                    value={this.state.rtopicName}
                    placeholder="Type and select an existing topic"
                    placeholderTextColor="#6D757F"
                    onChangeText={(rtopicName) => {
                      this.checkRTopicname(rtopicName);
                    }}
                    style={[
                      textStyle,
                      this.state.focusParentTopic
                        ? ButtonStyle.selecttextAreaShadowStyle
                        : ButtonStyle.textAreaShadowStyle,
                      {
                        width: "100%",
                        height: 45,
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        fontFamily: ConstantFontFamily.defaultFont,
                      },
                    ]}
                    // onFocus={(e) => {
                    //   this.setState({
                    //     inputParentName: true,
                    //   });
                    // }}
                    onFocus={() => {
                      this.setState({ focusParentTopic: true, inputParentName: true, })
                    }}
                    onBlur={() => this.setState({ focusParentTopic: false })}
                  />
                  {this.state.inputParentName == true &&
                    this.state.TopicList.map((item, index) => {
                      return (
                        <View
                          key={item.name}
                          style={{
                            backgroundColor: "#FEFEFA",
                            width: "100%",
                            padding: 5,
                          }}
                        >
                          <View
                            style={{
                              padding: 5,
                              backgroundColor: item.parents
                                ? "#e3f9d5"
                                : "#e3f9d5",
                              borderRadius: 5,
                              alignSelf: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                color: item.parents ? "#009B1A" : "#009B1A",
                                fontFamily: ConstantFontFamily.defaultFont,
                                fontWeight: "bold",
                              }}
                              onPress={() =>
                                this.handleTopicSelectInput(
                                  item.name,
                                  item.parents,
                                  item
                                )
                              }
                            >
                              /{item.name.toLowerCase()}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                </View>
              </View>
              {this.state.TopicListHierarchy.length > 0 && (
                <View>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginVertical: 10,
                        marginRight: 20,
                        fontSize: 16,
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Topic Hierarchy
                    </Text>
                  </View>
                  <View
                    style={{
                      borderRadius: 5,
                      padding: 10,
                    }}
                  >
                    <Suspense fallback={null}>
                      <TataStructure
                        isRoot={true}
                        item={this.state.TopicListHierarchy[0]}
                        topicName={this.state.topicName}
                      />
                    </Suspense>
                  </View>
                </View>
              )}

              <View style={{ flexDirection: "row", width: "100%" }}>
                <View
                  style={{
                    width: "70%",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      marginTop: 10,
                      marginRight: 20,
                      fontSize: 16,
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                  >
                    Description
                  </Text>
                </View>
                <TouchableOpacity
                  style={{
                    width: "30%",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                  onMouseEnter={() => this.setState({ descriptionHover: true })}
                  onMouseLeave={() =>
                    this.setState({ descriptionHover: false })
                  }
                >
                  {(this.state.description.length < 50 ||
                    this.state.description.length > 300) && (
                      <Icon
                        color={"#f80403"}
                        iconStyle={{
                          marginTop: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        name="times"
                        type="font-awesome"
                        size={16}
                      />
                    )}
                  {this.state.descriptionHover == true &&
                    Platform.OS == "web" ? (
                    <Tooltip
                      backgroundColor={"#d3d3d3"}
                      withPointer={false}
                      withOverlay={false}
                      toggleOnPress={true}
                      containerStyle={{
                        left: -60,
                        top: -60,
                      }}
                      popover={
                        <Text
                          style={{
                            fontFamily: ConstantFontFamily.defaultFont,
                          }}
                        >
                          Description should be 50 to 300 Characters
                        </Text>
                      }
                    />
                  ) : null}
                </TouchableOpacity>
              </View>

              <TextInput
                value={this.state.description}
                multiline={true}
                numberOfLines={5}
                placeholder="What the topic is about. Can paraphrase Wikipedia."
                placeholderTextColor="#6D757F"
                style={[
                  textStyle,
                  this.state.focusDesc
                    ? ButtonStyle.selecttextAreaShadowStyle
                    : ButtonStyle.textAreaShadowStyle,
                  {
                    width: "100%",
                    marginTop: 5,
                    paddingVertical: 5,
                    paddingHorizontal: 10,
                    height: Platform.OS == "ios" ? 100 : null,
                    fontFamily: ConstantFontFamily.defaultFont,
                  },
                ]}
                onChangeText={(description) => this.setState({ description })}
                onFocus={() => this.setState({ focusDesc: true })}
                onBlur={() => this.setState({ focusDesc: false })}
              />

              <View
                style={{
                  marginTop: 20,
                  alignSelf: "center",
                }}
              >
                <Button
                  title="EDIT TOPIC"
                  titleStyle={ButtonStyle.wtitleStyle}
                  buttonStyle={ButtonStyle.gbackgroundStyle}
                  containerStyle={ButtonStyle.containerStyle}
                  disabled={
                    this.state.topicName.length < 3 ||
                      this.state.topicName.length > 24 ||
                      this.state.topicName[this.state.topicName.length - 1] ==
                      "-" ||
                      this.state.description.length < 50 ||
                      this.state.description.length > 300
                      ? true
                      : false
                  }
                  onPress={() => this.editTopic()}
                />
              </View>
            </View>
          </View>
        </ScrollView>
        {Dimensions.get("window").width <= 750 && (
          <Suspense fallback={null}>
            <BottomScreen navigation={NavigationService} />
          </Suspense>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  listTrending_cliks: !state.TrendingCliksReducer.getIn(["Trending_cliks_List"])
    ? List()
    : state.TrendingCliksReducer.getIn(["Trending_cliks_List"]),
  getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  topicdetails: state.EditTopicReducer.get("topic"),
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList
    : [],
  getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
    ? state.LoginUserDetailsReducer.userFollowFeedList
    : [],
  getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
    ? state.LoginUserDetailsReducer.userFollowUserList
    : [],
});

const mapDispatchToProps = (dispatch) => ({
  getTrendingTopics: (payload) => dispatch(getTrendingTopics(payload)),
  setHASSCROLLEDACTION: (payload) => dispatch(setHASSCROLLEDACTION(payload)),
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
  updateTopicStar: (payload) =>
    dispatch({ type: "TOPIC_ICON_UPLOAD", payload }),
});



export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditTopicScreen
);

const styles = StyleSheet.create({
  usertext: {
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont,
  },
  image: {
    width: "100%",
    height: "100%",
  },

});
