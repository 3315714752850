import { List } from "immutable";
import React, { Component, lazy, Suspense } from "react";
import {
  Animated,
  Dimensions,
  FlatList,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Clipboard,
} from "react-native";
import { Icon } from "react-native-elements";
import {
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { TabBar, TabView } from "react-native-tab-view";
import { connect } from "react-redux";
import { compose } from "recompose";
import { listClikMembers } from "../actionCreator/ClikMembersAction";
import { listClikUserRequest } from "../actionCreator/ClikUserRequestAction";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import { getTrendingCliksProfileDetails } from "../actionCreator/TrendingCliksProfileAction";
import ShadowSkeletonForProfile from "../components/ShadowSkeletonForProfile";
import AppHelper from "../constants/AppHelper";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import { retry } from "../library/Helper";
import ButtonStyle from "../constants/ButtonStyle";
import applloClient from "../client";
import {
  InviteKeyClikProfileMutation,
} from "../graphqlSchema/graphqlMutation/FollowandUnFollowMutation";
import Modal from "modal-enhanced-react-native-web";
import Overlay from "react-native-modal-overlay";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import NavigationService from "../library/NavigationService";
import { showDiscussion, screen } from '../reducers/action/Completed';
import CreateCommentCard from "../components/CreateCommentCard"
import { editClik } from "../actionCreator/ClikEditAction";
import { DeleteContentMutation } from "../graphqlSchema/graphqlMutation/FeedMutation"
import { ClikFollowingMutation } from "../graphqlSchema/graphqlMutation/UserMutation";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";


const CliksProfileCard = lazy(() =>
  retry(() => import("../components/CliksProfileCard"))
);

const ClikMembersList = lazy(() => import("../components/ClikMembersList"))

const ClikProfileUserList = lazy(() => import("../components/ClikProfileUserList"))

const InviteUserModal = lazy(() => import("../components/InviteUserModal"))

const InviteLinkPrivate = lazy(() => import("../components/InviteLinkPrivate"))

const QualificationToJoin = lazy(() => import("../components/QualificationToJoin"))

const InviteViaLink = lazy(() => import("../components/InviteViaLink"))

const InviteKeyJoinModal = lazy(() => import("../components/InviteKeyJoinModal"))


const SEOMetaData = lazy(() => import("../components/SEOMetaData"))


const HeaderRight = lazy(() => import("../components/HeaderRight"))

import CliksProfileFeedScreen from "./CliksProfileFeedScreen"

const BottomScreenProfile = lazy(() => import("../components/BottomScreenProfile"))

const BottomScreenProfileForAdmin = lazy(() => import("../components/BottomScreenProfileForAdmin"))

const InviteViaGoogle = lazy(() => import("../components/InviteViaGoogle"))



class CliksProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.inputRefs = {};
    this.state = {
      modalVisible: false,
      commentDelay: false,
      memberValue: "",
      filterItems: this.props.listClikMembers ? this.props.listClikMembers : "",
      loadTab: false,
      items: [
        {
          label: "Profile",
          value: "FEED",
          key: 0,
        },
        {
          label: "Members",
          value: "USERS",
          key: 1,
        },
        {
          label: "Applications",
          value: "APPLICATIONS",
          key: 2,
        },
      ],
      cliksselectItem: this.props.navigation
        .getParam("type", "NO-ID")
        .toUpperCase(),
      showIcon: "#fff",
      routes: [
        { key: "first", title: "Post" },
        { key: "second", title: "Members" },
        { key: "third", title: "Applications" },
      ],
      index: 0,
      id: "",
      scrollY: 0,
      ProfileHeight: 0,
      feedY: 0,
      showInviteLink: false,
      qualification: "",
      type: "",
      member: "",
      qualification: "",
      invite_key: "",
      status: "Default",
      member_type: "",
      InviteClik: {},
      showInviteKeyJoinModal: false,
      InviteType: "",
      MemberType: "",
      showCard: false,
      tempitemId: "",
      tabValue: 'Post',
      opentool: false,
    };
    this.userPermision = false;
    this.userApprovePermision = false;
  }

  onLoadingComment = () => {
    this.setState({ commentDelay: true });
    setTimeout(() => {
      if (this.props.PostCommentDetails) {
        this.setState({ commentDelay: false });
      }
    }, 1500);
  };


  _handleIndexChange = (index) => {
    this.setState({ index });
    this.props.setIndex(index);
  };


  changeLoadTab = () => {
    this.setState({ loadTab: true });
  };
  _renderTabBar = (props) =>

    Dimensions.get("window").width >= 750 && (
      <View>
        {this.state.loadTab == true ? (
          <View
            style={[
              ButtonStyle.TabbarBorderStyleForDashboard,
              {
                flexDirection: "row",
                height: 55,
                backgroundColor:
                  Dimensions.get("window").width <= 750 ? "#000" : "#fff",
                alignItems: "center",
                paddingHorizontal: 10,
                paddingVertical: 10,
                marginLeft: 0,
                width: '100%',
                borderTopWidth: 1
              },
            ]}
          >
            <TabBar
              onTabPress={({ route }) => {
                console.log(route, 'route');
                this.props.setClikTabView(false);
                if (route.key === "second") {
                  this.props.userId({
                    id:
                      this.props.cliksDetails.getIn(["data", "node"]) &&
                      this.props.cliksDetails
                        .getIn(["data", "node"])
                        .get("name"),
                    type: "members",
                  });
                } else if (route.key === "third") {
                  this.props.userId({
                    id:
                      this.props.cliksDetails.getIn(["data", "node"]) &&
                      this.props.cliksDetails
                        .getIn(["data", "node"])
                        .get("name"),
                    type: "applications",
                  });
                } else if (route.key === "first") {
                  this.props.userId({
                    id:
                      this.props.cliksDetails.getIn(["data", "node"]) &&
                      this.props.cliksDetails
                        .getIn(["data", "node"])
                        .get("name"),
                    type: "feed",
                  });
                }
              }}
              {...props}
              indicatorStyle={{
                backgroundColor: "transparent",
                height: 3,
                borderRadius: 6,
              }}
              style={{
                backgroundColor: "transparent",
                width: "100%",
                shadowColor: "transparent",
                height: 40,
                justifyContent: "center",
                marginVertical: 8,
                // alignItems: "center",
              }}
              labelStyle={{
                color: "#4169e1",
                fontFamily: ConstantFontFamily.defaultFont,
              }}
              renderLabel={({ route, focused, color, isActive }) => (
                <Text
                  style={[ButtonStyle.tabbarTitleStyle, {
                    color: focused ? '#000' : isActive ? '#000' : "#D3D3D3",

                  }]}
                >
                  {route.title}
                </Text>
              )}
            />
          </View>
        ) : null}
      </View>
    );


  _renderUserItem = (item) => {
    return (
      <Suspense fallback={null}>
        <ClikProfileUserList
          item={item}
          navigation={this.props.navigation}
          onLoadingComment={this.onLoadingComment}
          commentDelay={this.state.commentDelay}
          ClikInfo={this.props.cliksDetails}
        />
      </Suspense>
    );
  };

  _renderClikMembersItem = (item) => {
    let itemId = "";
    itemId = this.props.navigation.getParam("id", "NO-ID");
    const index = this.props.getUserFollowCliksList.findIndex(
      (i) =>
        i.node.clik.name.toLowerCase() ==
        itemId.replace("%3A", ":").toLowerCase()
    );
    let tempitemId = this.props.getUserFollowCliksList[index]?.node?.member_type

    return (
      <Suspense fallback={null}>
        <ClikMembersList
          item={item}
          navigation={this.props.navigation}
          ClikInfo={this.props.cliksDetails}
          userApprovePermision={this.userApprovePermision}
          MemberType={tempitemId && tempitemId}
          getCurrentMember={this.getCurrentMember}
        />
      </Suspense>
    );
  };


  getCurrentMember = (username) => {
    this.props.listClikMembers?.splice(
      this.props.listClikMembers.indexOf(username),
      1
    );
    this.setState({ filterItems: this.props.listClikMembers });
  };

  showIcon = (data) => {
    this.setState({
      showIcon: data,
    });
  };

  members = () => {
    this.setState({
      cliksselectItem: "FEED",
    });
  };

  showMembers = (type) => {
    this.setState({
      index: 1,
      InviteType: type,
    });
  };

  showInviteLink = (value, type, member) => {
    this.setState({
      showInviteLink: value,
      type: type,
      member: member,
    });
  };

  onClose = () => {
    this.setState({
      modalVisible: false,
      showInviteKeyJoinModal: false,
      opentool: false
    });
  };


  requestJoin = async () => {
    this.setState({
      index: 1,
    });
    if (
      this.props.cliksDetails?.getIn(["data", "node"])?.get("invite_only") == true
    ) {
      this.getKeyProfile(this.props.navigation.getParam("key", ""));
    }
  };

  getKeyProfile = async (key) => {
    try {
      await applloClient
        .query({
          query: InviteKeyClikProfileMutation,
          variables: {
            invite_key: key,
          },
          fetchPolicy: "no-cache",
        })
        .then(async (res) => {
          this.setState({
            InviteClik: res.data.clik_invite_key,
            invite_key: key,
            showInviteKeyJoinModal: true,
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  async componentDidMount() {
    this.props.showDiscussion(false)
    this.props.SetProfileTabView("FEED");

    await this.props.clikCardShow(false);
    this.setState({ showCard: false });

    this.props.setClikTabView(false);
    this.props.searchOpenBarStatus(false);
    this.getStaticData();
    this.props.screen("clikScreen")

    const cliks = this.props.cliksDetails.getIn(["data", "node"]);

    const id =
      cliks &&
      cliks
        .get("name")
        .replace("Clik%3A", "#")
        .replace("Clik:", "#")
    this.props.userId({
      id: id,
      type: "feed",
    });

  }

  updateStar = () => {
    this.props.searchOpenBarStatus(false);
    this.getStaticData();
    this.props.SetProfileTabView("FEED");
    this.props.clikIconUpload(true);
  };

  setSignupData = async (itemId) => {
    await this.props.setUsernameModalStatus(true);
    await this.props.setInviteUserDetail({
      clikName: itemId,
      inviteKey: this.props.navigation.getParam("key", ""),
      userName: "",
    });
  };

  async getStaticData() {
    let itemId = "";
    setTimeout(() => {
      itemId = this.props.navigation.getParam("id", "NO-ID");
      this.setState({
        id: itemId,
      });
    }, 200);

    if (
      this.props.navigation.getParam("type", "NO-ID") == "join" ||
      this.props.navigation.getParam("type", "NO-ID") == "invite"
    ) {
      this.props.loginStatus == 1
        ? this.requestJoin()
        : this.setSignupData(itemId);
    }

    if (itemId) {
      await this.props.userId({
        id: itemId,
        type: this.props.navigation.getParam("type", "NO-ID"),
      });
      await this.props.setClikUserRequest({
        id: itemId,
        currentPage: AppHelper.PAGE_LIMIT,
      });
      await this.props.setClikMembers({
        id: itemId,
      });
      const index = this.props.getUserFollowCliksList.findIndex(
        (i) =>
          i.node.clik.name.toLowerCase().replace("Clik:", "") ==
          itemId?.replace("%3A", ":").toLowerCase().replace("Clik:", "")
      );
      this.setState({
        MemberType: this.props.getUserFollowCliksList[index].node.member_type,
      });
      if (index != -1) {
        if (
          this.props.getUserFollowCliksList[index].node.member_type ==
          "SUPER_ADMIN"
        ) {
          await this.setState({
            showIcon: require("../assets/image/YBadge.png"),
            MemberType: "SUPER_ADMIN",
            routes: [
              { key: "first", title: "Feed" },
              { key: "second", title: "Members" },
              { key: "third", title: "Applications" },
            ],
          });
        } else if (
          this.props.getUserFollowCliksList[index].node.member_type ==
          "ADMIN"
        ) {
          await this.setState({
            showIcon: require("../assets/image/SBadge.png"),
            MemberType: "ADMIN",
            routes: [
              { key: "first", title: "Feed" },
              { key: "second", title: "Members" },
              { key: "third", title: "Applications" },
            ],
          });
        } else if (
          this.props.getUserFollowCliksList[index].node.member_type ==
          "MEMBER"
        ) {
          await this.setState({
            showIcon: require("../assets/image/badge.png"),
            MemberType: "MEMBER",
            routes: [
              { key: "first", title: "Feed" },
              { key: "second", title: "Members" },
            ],
          });
        } else {
          this.getIconColour(index);
          this.setState({
            routes: [
              { key: "first", title: "Feed" },
              { key: "second", title: "Members" },
            ],
          });
        }
      } else {
        this.getIconColour(index);
        this.setState({
          routes: [
            { key: "first", title: "Feed" },
            { key: "second", title: "Members" },
          ],
        });
      }
    }
  }

  getIconColour = (index) => {
    if (index != -1) {
      if (
        this.props.getUserFollowCliksList[index].node.follow_type == "FAVORITE"
      ) {
        this.setState({
          showIcon: "#FADB4A",
        });
      }
      if (
        this.props.getUserFollowCliksList[index].node.follow_type == "FOLLOW"
      ) {
        this.setState({
          showIcon: "#E1E1E1",
        });
      }
    } else {
      this.setState({
        showIcon: "#fff",
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.getHasScrollTop == true && this.UserProfilescrollview) {
      this.UserProfilescrollview.scrollTo({ x: 0, y: 0, animated: true });
      this.props.setHASSCROLLEDACTION(false);
    }
    if (
      prevProps.navigation.getParam("id", "NO-ID").toUpperCase() !=
      this.props.navigation.getParam("id", "NO-ID").toUpperCase()
    ) {
      let itemId = this.props.navigation.getParam("id", "NO-ID");
      const index = this.props.getUserFollowCliksList && this.props.getUserFollowCliksList.findIndex(
        (i) =>
          i.node.clik.name.toLowerCase().replace("clik:", "") ==
          itemId.replace("%3A", ":").toLowerCase().replace("clik:", "")
      );

      this.setState(
        {
          MemberType: this.props.getUserFollowCliksList && this.props.getUserFollowCliksList[index]?.node?.member_type,
        },
        () => {
          if (
            this.state.MemberType == "SUPER_ADMIN" ||
            this.state.MemberType == "ADMIN"
          ) {
            this.setState({
              routes: [
                { key: "first", title: "Feed" },
                { key: "second", title: "Members" },
                { key: "third", title: "Applications" },
              ],
            });
          } else {
            this.setState({
              routes: [
                { key: "first", title: "Feed" },
                { key: "second", title: "Members" },
              ],
            });
          }
        }
      );

      this.getStaticData();
      this.setState({
        scrollY: 0,
        index: 0,
      });
      this.CliksProfilescrollview.scrollTo({ x: 0, y: 0, animated: true });
      if (
        this.props.navigation.state.params.postId != " "
      ) {
        this.setState({ showCard: true });
        this.props.clikCardShow(true);
        this.props.setClikCardDetails({
          type: this.props.navigation.state.params.type,
          clickTitle: this.props.navigation.state.params.id,
          postId: this.props.navigation.state.params.postId,
        });
      } else if (
        this.props.navigation.state.params.postId == undefined ||
        this.props.navigation.state.params.postId === " "
      ) {
        this.setState({ showCard: false });
        this.props.clikCardShow(false);
      }
    }
    if (this.props.loginStatus == 1) {
      this.getUserPermision();
      this.getUserApprovePermision();
    }
    if (prevProps.listClikMembers != this.props.listClikMembers) {
      this.setState({ filterItems: this.props.listClikMembers });
    }
    // if (this.props.screenName != "clikScreen") {
    //   this.props.screen("clikScreen")
    // }
  }
  getUserPermision = () => {

    const index = this.props.listClikMembers.findIndex(
      (i) =>
        i.node.user.id ==
        this.props.profileData?.user?.id
    );
    if (index != -1) {
      this.userPermision = true;
    } else {
      this.userPermision = false;
    }
  };

  getUserApprovePermision = () => {
    const index = this.props.listClikMembers.findIndex(
      (i) =>
        i.node.user.id ==
        this.props.profileData?.user?.id &&
        (i.node.type == "SUPER_ADMIN" || i.node.type == "ADMIN")
    );
    if (index != -1) {
      this.userApprovePermision = true;
    } else {
      this.userApprovePermision = false;
    }
  };



  listScroll = (value) => {
    this.setState({
      feedY: value,
    });
  };



  // _renderScene = ({ route }) => {
  //   switch (route.key) {
  //     case "third":

  //       return (
  //         <ScrollView
  //           containerStyle={{
  //             paddingBottom: 1,
  //             flex: 1,
  //             width: "96%",
  //             marginTop: 20,
  //           }}
  //           style={[
  //             ButtonStyle.normalFeedStyleForDashboard,
  //             { width: "96%", marginLeft: 15 },
  //           ]}
  //           showsVerticalScrollIndicator={false}
  //         >
  //           <View
  //             style={{
  //               backgroundColor: "#fff",
  //               marginBottom: 10,
  //               borderWidth: 1,
  //               borderTopWidth: 0,
  //               borderColor: "#d7d7d7",

  //             }}
  //           >
  //             <Text
  //               style={{
  //                 textAlign: "left",
  //                 color: "#000",
  //                 fontSize: 16,
  //                 fontWeight: "bold",
  //                 fontFamily: ConstantFontFamily.defaultFont,
  //                 marginVertical: 10,
  //                 marginHorizontal: 10,
  //               }}
  //             >
  //               Member Applications
  //             </Text>
  //             {this.props.listClikUserRequest.length > 0 &&
  //               this.userApprovePermision == true ? (
  //               <View>
  //                 <FlatList
  //                   extraData={this.state}
  //                   data={this.props.listClikUserRequest}
  //                   keyExtractor={(item) => item.node.user.id}
  //                   renderItem={this._renderUserItem}
  //                 />
  //               </View>
  //             ) : (
  //               this.userApprovePermision == true && (
  //                 <View
  //                   style={{
  //                     flexDirection: "column",
  //                     justifyContent: "center",
  //                     alignItems: "center",
  //                     height: hp("15%"),
  //                   }}
  //                 >
  //                   <Icon
  //                     color={"#000"}
  //                     iconStyle={{
  //                       color: "#fff",
  //                       justifyContent: "center",
  //                       alignItems: "center",
  //                       alignSelf: "center",
  //                     }}
  //                     reverse
  //                     name="user-times"
  //                     type="font-awesome"
  //                     size={20}
  //                     containerStyle={{
  //                       alignSelf: "center",
  //                     }}
  //                   />
  //                   <Text
  //                     style={{
  //                       fontSize: 14,
  //                       fontWeight: "bold",
  //                       fontFamily: ConstantFontFamily.defaultFont,
  //                       color: "#000",
  //                       alignSelf: "center",
  //                     }}
  //                   >
  //                     No Applications
  //                   </Text>
  //                 </View>
  //               )
  //             )}
  //           </View>
  //         </ScrollView>
  //       );
  //     case "second":
  //       return (
  //         <ScrollView
  //           containerStyle={{ marginTop: 20, flex: 1, width: "96%" }}
  //           style={[
  //             ButtonStyle.normalFeedStyleForDashboard,
  //             {
  //               width: "96%",
  //               marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
  //               marginBottom: 20,
  //             },
  //           ]}
  //           showsVerticalScrollIndicator={false}
  //         >
  //           {this.props.cliksDetails?.getIn(["node"]) && (
  //             <Suspense fallback={null}>
  //               <QualificationToJoin
  //                 status={"Default"}
  //                 type={this.state.type}
  //                 navigation={this.props.navigation}
  //               />
  //             </Suspense>
  //           )}

  //           {this.state.showInviteLink == true &&
  //             this.props.cliksDetails
  //               .getIn(["data", "node"])
  //               .get("invite_only") == false && <Suspense fallback={null}> <InviteViaLink /></Suspense>}

  //           {this.state.showInviteLink == true &&
  //             this.props.cliksDetails
  //               .getIn(["data", "node"])
  //               .get("invite_only") == true && (
  //               <Suspense fallback={<ShadowSkeletonForProfile />}>
  //                 <InviteLinkPrivate InviteType={this.state.InviteType} />
  //               </Suspense>
  //             )}

  //           {this.userApprovePermision == true && (
  //             <Suspense fallback={<ShadowSkeletonForProfile />}>

  //               <InviteUserModal
  //                 onClose={() => this.onClose()}
  //                 {...this.props}
  //                 ClikInfo={this.props.cliksDetails.getIn(["data", "node"])}
  //                 showHeader={false}
  //               />
  //             </Suspense>
  //           )}
  //           {this.state.MemberType == "ADMIN" ||
  //             this.state.MemberType == "SUPER_ADMIN" ? (
  //             <Suspense fallback={<ShadowSkeletonForProfile />}>
  //               <InviteViaGoogle />
  //             </Suspense>
  //           ) : null}
  //           <View
  //             style={[
  //               {
  //                 backgroundColor: "#fff",
  //                 borderBottomWidth: 1,
  //                 borderColor: "#D7D7D7",
  //               },
  //             ]}
  //           >
  //             <Text
  //               style={{
  //                 textAlign: "left",
  //                 color: "#000",
  //                 fontSize: 16,
  //                 fontWeight: "bold",
  //                 fontFamily: ConstantFontFamily.defaultFont,
  //                 marginVertical: 10,
  //                 marginHorizontal: 10,
  //                 marginTop: 20,
  //               }}
  //             >
  //               Current Members
  //             </Text>
  //             {this.state.filterItems ? (
  //               <View
  //                 style={{
  //                   paddingLeft: 10,
  //                   width: "100%",
  //                   paddingRight: 10,
  //                 }}
  //               >
  //                 <FlatList
  //                   extraData={this.state}
  //                   data={this.state.filterItems}
  //                   keyExtractor={(item) => item.node.user.id}
  //                   renderItem={this._renderClikMembersItem}
  //                 />
  //               </View>
  //             ) : (
  //               <View
  //                 style={{
  //                   flexDirection: "column",
  //                   justifyContent: "center",
  //                   alignItems: "center",
  //                   height: hp("15%"),
  //                 }}
  //               >
  //                 <Icon
  //                   color={"#000"}
  //                   iconStyle={{
  //                     color: "#fff",
  //                     justifyContent: "center",
  //                     alignItems: "center",
  //                     alignSelf: "center",
  //                   }}
  //                   reverse
  //                   name="user-times"
  //                   type="font-awesome"
  //                   size={20}
  //                   containerStyle={{
  //                     alignSelf: "center",
  //                   }}
  //                 />
  //                 <Text
  //                   style={{
  //                     fontSize: 14,
  //                     fontWeight: "bold",
  //                     fontFamily: ConstantFontFamily.defaultFont,
  //                     color: "#000",
  //                     alignSelf: "center",
  //                   }}
  //                 >
  //                   No Member found
  //                 </Text>
  //               </View>
  //             )}
  //           </View>
  //         </ScrollView>
  //       );
  //     case "first":
  //       return (
  //         <View>
  //           {/* <Suspense fallback={null}> */}
  //           <CliksProfileFeedScreen
  //             navigation={this.props.navigation}
  //             listScroll={this.listScroll}
  //             ProfileHeight={this.state.ProfileHeight}
  //             onLoadingComment={this.onLoadingComment}
  //             changeLoadTab={this.changeLoadTab}
  //             loadFalse={() => this.setState({ loadTab: false })}
  //             commentDelay={this.state.commentDelay}
  //             scrollY={this.state.scrollY}
  //             showCard={this.state.showCard}
  //           />
  //           {/* </Suspense> */}
  //         </View>
  //       );
  //     default:
  //       return (
  //         <View>
  //           <Suspense fallback={null}>
  //             <CliksProfileFeedScreen
  //               navigation={this.props.navigation}
  //               listScroll={this.listScroll}
  //               ProfileHeight={this.state.ProfileHeight}
  //               changeLoadTab={this.changeLoadTab}
  //               loadFalse={() => this.setState({ loadTab: false })}
  //               onLoadingComment={this.onLoadingComment}
  //               commentDelay={this.state.commentDelay}
  //               scrollY={this.state.scrollY}
  //               showCard={this.state.showCard}
  //             />
  //           </Suspense>
  //         </View>
  //       );
  //   }
  // };

  renderTabViewForMobile = () => {
    if (this.props.getProfileTabView == "FEED") {
      return (
        // <Suspense fallback={null}>
        <CliksProfileFeedScreen
          navigation={this.props.navigation}
          listScroll={this.listScroll}
          ProfileHeight={this.state.ProfileHeight}
          scrollY={this.state.scrollY}
        />
        // </Suspense>
      );
    } else if (this.props.getProfileTabView == "USERS") {
      return (
        <ScrollView containerStyle={{ marginBottom: 50, flex: 1 }}>
          {this.props.cliksDetails.getIn(["data", "node"]) && (
            <Suspense fallback={null}>
              <QualificationToJoin
                type={this.state.type}
                navigation={this.props.navigation}
              />
            </Suspense>
          )}

          {this.state.showInviteLink == true &&
            this.props.cliksDetails
              .getIn(["data", "clik"])
              .get("invite_only") == false && <Suspense fallback={null}><InviteViaLink /></Suspense>}

          {this.state.showInviteLink == true &&
            this.props.cliksDetails
              .getIn(["data", "clik"])
              .get("invite_only") == true && (
              <Suspense fallback={null}>
                <InviteLinkPrivate InviteType={this.state.InviteType} />
              </Suspense>
            )}

          {this.userApprovePermision == true && (
            <Suspense fallback={null}>
              <InviteUserModal
                onClose={() => this.onClose()}
                {...this.props}
                ClikInfo={this.props.cliksDetails.getIn(["data", "node"])}
                showHeader={false}
              />
            </Suspense>
          )}
          <View
            style={[
              ButtonStyle.ZeroBorderStyle,
              {
                borderWidth: 0,
                borderRadius: 0,
                backgroundColor: "#fff",
                marginBottom: 50,
              },
            ]}
          >
            <Text
              style={{
                textAlign: "left",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont,
                marginVertical: 10,
                marginHorizontal: 10,
              }}
            >
              Current Members
            </Text>
            {this.state.filterItems ? (
              <View
                style={{
                  paddingLeft: 10,
                  width: "100%",
                  paddingRight: 10,
                }}
              >
                <FlatList
                  data={this.state.filterItems}
                  keyExtractor={(item) => item.node.user.id}
                  renderItem={this._renderClikMembersItem}
                />
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: hp("15%"),
                }}
              >
                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                  reverse
                  name="user-times"
                  type="font-awesome"
                  size={20}
                  containerStyle={{
                    alignSelf: "center",
                  }}
                />
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont,
                    color: "#000",
                    alignSelf: "center",
                  }}
                >
                  No Member found
                </Text>
              </View>
            )}
          </View>
        </ScrollView>
      );
    } else if (this.props.getProfileTabView == "APPLICATIONS") {
      return (
        <View>
          <View
            style={[
              ButtonStyle.cardShadowStyle,
              {
                borderWidth: 0,
                borderRadius: 10,
                backgroundColor: "#fff",
                marginVertical: 10,
              },
            ]}
          >
            <Text
              style={{
                textAlign: "left",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont,
                marginVertical: 10,
                marginHorizontal: 10,
              }}
            >
              Qualifications
            </Text>
            {this.props.cliksDetails
              .getIn(["data", "clik"])
              .get("qualifications") == null && (
                <Text
                  style={{
                    textAlign: "center",
                    color: "#000",
                    fontFamily: ConstantFontFamily.defaultFont,
                    fontSize: 16,
                    fontWeight: "bold",
                    margin: 10,
                  }}
                >
                  No prerequisites to join this clik
                </Text>
              )}
            {this.props.cliksDetails
              .getIn(["data", "clik"])
              .get("qualifications") != null &&
              this.props.cliksDetails
                .getIn(["data", "clik"])
                .get("qualifications")
                .map((item, index) => {
                  return (
                    <Text
                      key={index}
                      style={{
                        color: "#000",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 16,
                        fontWeight: "bold",
                        marginBottom: 20,
                        marginLeft: 30,
                      }}
                    >
                      {"\u25CF"} {item}
                    </Text>
                  );
                })}
          </View>
          {this.userApprovePermision == true && (
            <Suspense fallback={null}>
              <InviteUserModal
                onClose={() => this.onClose()}
                {...this.props}
                ClikInfo={this.props.cliksDetails.getIn(["data", "node"])}
                showHeader={false}
              />
            </Suspense>
          )}
          <View
            style={[
              ButtonStyle.cardShadowStyle,
              {
                borderWidth: 0,
                borderRadius: 10,
                backgroundColor: "#fff",
                marginBottom: 10,
              },
            ]}
          >
            <Text
              style={{
                textAlign: "left",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont,
                marginVertical: 10,
                marginHorizontal: 10,
              }}
            >
              Member Applications
            </Text>
            {this.props.listClikUserRequest.length > 0 &&
              this.userApprovePermision == true ? (
              <View>
                <FlatList
                  data={this.state.filterItems}
                  keyExtractor={(item) => item.node.user.id}
                  renderItem={this._renderUserItem}
                />
              </View>
            ) : (
              this.userApprovePermision == true && (
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: hp("15%"),
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="user-times"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    No Applications
                  </Text>
                </View>
              )
            )}
          </View>
        </View>
      );
    } else {
      return (
        <View style={{ flex: 1 }}>
          {/* <Suspense fallback={null}> */}
          <CliksProfileFeedScreen
            navigation={this.props.navigation}
            listScroll={this.listScroll}
            ProfileHeight={this.state.ProfileHeight}
            scrollY={this.state.scrollY}
          />
          {/* </Suspense> */}
        </View>
      );
    }
  };

  selectMemberTab = (tabValue) => {
    console.log(tabValue, 'tabValue')
    this.setState({ tabValue: tabValue });
    // const cliks = this.props.cliksDetails.getIn(["data", "node"]);

    // const id =
    //   cliks &&
    //   cliks
    //     .get("name")
    //     .replace("Clik%3A", "#")
    //     .replace("Clik:", "#")
    const path = location.pathname.replace('/feed', '')
    if (tabValue == "Application") {
      window.history.pushState(null, '', path + 'applications');
      // this.props.userId({
      //   id: id,
      //   type: "applications",
      // });
    }
  };


  openPopUp = () => {
    this.setState({ opentool: true })
  }

  showAlert = () => {

    if (Platform.OS === "web") {
      var result = confirm(
        "Are you sure you want to delete " +
        this.props.cliksDetails.getIn(["data", "node"]).get('name')
          .replace("Clik%3A", "#")
          .replace("Clik:", "#")
      );
      if (result == true) {
        applloClient
          .query({
            query: DeleteContentMutation,
            variables: {
              id: cliks.get("id")
            },
            fetchPolicy: "no-cache"
          })
          .then(async res => {

            await applloClient
              .query({
                query: ClikFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 50
                },
                fetchPolicy: "no-cache"
              }).then(e => {


                let userData = {
                  data: this.props.profileData,
                  externalfollow: this.props.getUserFollowFeedList,
                  clikfollow: e.data.node.cliks_following.edges,
                  topicfollow: this.props.getUserFollowTopicList,
                  userfollow: this.props.getUserFollowUserList

                }
                this.props.saveLoginUser(userData);
                this.props.getTrendingClicks({
                  currentPage: 100
                });
                NavigationService.navigate("home")

              })
          });
      }
    } else {
      this.setState({ opentool: false })
      Alert.alert(
        "Are you sure you want to delete " +
        this.props.cliksDetails.getIn(["data", "node"]).get('name')
          .replace("Clik%3A", "#")
          .replace("Clik:", "#")[
        ({
          text: "NO",
          onPress: () => console.warn("NO Pressed"),
          style: "cancel"
        },
        {
          text: "YES",
          onPress: () => {
            applloClient
              .query({
                query: DeleteContentMutation,
                variables: {
                  id: this.props.cliksDetails
                    .getIn(["id"])
                },
                fetchPolicy: "no-cache"
              })
              .then(async res => {
                await applloClient
                  .query({
                    query: ClikFollowingMutation,
                    variables: {
                      id: "User:CurrentUser",
                      first: 50
                    },
                    fetchPolicy: "no-cache"
                  }).then(e => {


                    let userData = {
                      data: this.props.profileData,
                      externalfollow: this.props.getUserFollowFeedList,
                      clikfollow: e.data.node.cliks_following.edges,
                      topicfollow: this.props.getUserFollowTopicList,
                      userfollow: this.props.getUserFollowUserList

                    }
                    props.saveLoginUser(userData);
                  })
              });
          }
        })
        ]
      );
    }
  };


  render() {
    return (
      <View style={styles.container}>
        {this.state.showInviteKeyJoinModal == true ? (
          Platform.OS !== "web" ? (
            <Suspense fallback={null}>
              <Overlay
                animationType="zoomIn"
                visible={this.state.showInviteKeyJoinModal}
                onClose={() => this.onClose()}
                closeOnTouchOutside
                children={
                  <InviteKeyJoinModal
                    onClose={() => this.onClose()}
                    {...this.props}
                    InviteClik={this.state.InviteClik}
                    invite_key={this.state.invite_key}
                  />
                }
                childrenWrapperStyle={{
                  padding: 0,
                  margin: 0,
                }}
              />
            </Suspense>
          ) : (
            <Suspense fallback={null}>
              <Modal
                isVisible={this.state.showInviteKeyJoinModal}
                onBackdropPress={() => this.onClose()}
                style={{
                  marginHorizontal:
                    Dimensions.get("window").width > 750 ? "30%" : 10,
                  padding: 0,
                }}
              >
                <InviteKeyJoinModal
                  onClose={() => this.onClose()}
                  {...this.props}
                  InviteClik={this.state.InviteClik}
                  invite_key={this.state.invite_key}
                />
              </Modal>
            </Suspense>
          )
        ) : null}

        {Platform.OS == "web" && (
          <Suspense fallback={null}>
            <SEOMetaData
              title={
                this.props.cliksDetails.getIn(["data", "node"])
                  ? this.props.cliksDetails.getIn(["data", "node"]).get("name")
                  : ""
              }
              description={
                this.props.cliksDetails.getIn(["data", "node"])
                  ? this.props.cliksDetails
                    .getIn(["data", "node"])
                    .getIn(["description"])
                  : ""
              }
              image={
                this.props.cliksDetails.getIn(["data", "node"])
                  ? this.props.cliksDetails
                    .getIn(["data", "node"])
                    .getIn(["banner_pic"])
                  : ""
              }
            />
          </Suspense>
        )}
        {/* {
          Dimensions.get("window").width <= 750 && (
            <Animated.View
              style={{
                position: Platform.OS == "web" ? "sticky" : null,
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                overflow: "hidden",
                borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
              }}
            >
              <View
                style={{
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    marginBottom:
                      Dimensions.get("window").width <= 750 ? 0 : 10,
                    backgroundColor: "#000",
                    borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
                    height: 50,
                  }}
                >
                  <TouchableOpacity
                    style={[
                      ButtonStyle.headerBackStyle,
                      { marginRight: !this.props.getsearchBarStatus ? 10 : 0 },
                    ]}
                    onPress={() => {
                      this.props.searchOpenBarStatus(false);
                      let nav =
                        this.props.navigation.dangerouslyGetParent().state;
                      if (nav.routes.length > 1) {
                        if (this.state.cliksselectItem == "USERS") {
                          this.setState({
                            cliksselectItem: "FEED",
                          });
                        } else {
                          this.props.navigation.goBack();
                        }
                      } else {
                        this.props.navigation.navigate("home");
                      }
                    }}
                  >
                    <Icon
                      color={"#fff"}
                      name="angle-left"
                      type="font-awesome"
                      size={40}
                    />
                  </TouchableOpacity>

                  {this.props.cliksDetails.size > 0 &&
                    !this.props.getsearchBarStatus && (
                      <TouchableOpacity
                        style={{
                          flex: this.props.loginStatus
                            ? 1
                            : !this.props.loginStatus && Platform.OS == "web"
                              ? 1
                              : !this.props.loginStatus &&
                              Platform.OS != "web" &&
                              0,
                          flexDirection: "row",
                          alignSelf: "center",
                          alignItems: "center",
                          marginRight: 5,
                        }}
                      >
                        {this.props.loginStatus == 1 &&
                          this.state.showIcon.toString().startsWith("#") ==
                          (
                            <Image
                              source={this.state.showIcon}
                              style={{
                                width: 22,
                                height: 22,
                              }}
                            />
                          )}
                        <TouchableOpacity
                          style={{
                            paddingVertical: 5,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onPress={() =>
                            this.CliksProfilescrollview.scrollTo({
                              x: 0,
                              y: 0,
                              animated: true,
                            })
                          }
                        >
                          <View
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                              height: 30,
                              paddingVertical: 5,
                              backgroundColor: "#000",
                              borderRadius: 6,
                              alignSelf: "flex-start",
                            }}
                          >
                            <Text
                              style={{
                                color: "#fff",
                                fontWeight: "bold",
                                fontSize: 18,
                                fontFamily: ConstantFontFamily.defaultFont,
                              }}
                            >
                              #
                              {this.props.navigation.state.params.id
                                .replace("Clik%3A", "#")
                                .replace("Clik:", "#")}
                            </Text>
                          </View>
                        </TouchableOpacity>

                      </TouchableOpacity>
                    )}
                  <View
                    style={[
                      ButtonStyle.headerRightStyle,
                      { width: this.props.getsearchBarStatus && "95%" },
                    ]}
                  >
                    <Suspense fallback={null}>
                      <HeaderRight navigation={this.props.navigation} />
                    </Suspense>
                  </View>
                </View>
              </View>
            </Animated.View>
          )
        } */}
        <ScrollView
          contentContainerStyle={{ height: "100%" }}
          ref={(ref) => {
            this.CliksProfilescrollview = ref;
          }}
          showsVerticalScrollIndicator={false}
          onScroll={(event) => {
            this.setState({
              scrollY: event.nativeEvent.contentOffset.y,
            });
          }}
          scrollEventThrottle={16}
        >
          {Dimensions.get('window').width >= 1200 || this.props.show == false ?

            <Animated.View
              onLayout={(event) => {
                let { x, y, width, height } = event.nativeEvent.layout;
                if (width > 0) {
                  this.setState({ ProfileHeight: height });
                }
              }}

              style={{ marginLeft: 0, }}
            >
              <Suspense
                fallback={
                  Dimensions.get("window").width <= 750 ? null : (
                    <ShadowSkeletonForProfile />
                  )
                }
              >
                {this.props.cliksDetails.getIn(["data", "node"]) && (
                  <CliksProfileCard
                    item={this.props.cliksDetails}
                    navigation={this.props.navigation}
                    icon={this.showIcon}
                    members={this.members}
                    showMembers={this.showMembers}
                    feedY={this.state.feedY}
                    ProfileHeight={this.state.ProfileHeight}
                    showIcon={this.state.showIcon}
                    showInviteLink={this.showInviteLink}
                    MemberType={this.state.MemberType}
                    updateStar={this.updateStar}
                    selectMemberTab={this.selectMemberTab}
                    openPopUp={this.openPopUp}
                  />
                )}
              </Suspense>
            </Animated.View>
            : null}



          {Dimensions.get("window").width <= 750 ? (
            <View
              style={{
                flex: 1,
                width: "100%",
              }}
            >
              {this.renderTabViewForMobile()}
            </View>
          ) : (
            <View
              style={
                Dimensions.get("window").width >= 750 &&
                  this.state.index == 0 &&
                  this.state.scrollY >= this.state.ProfileHeight + 50
                  ? [
                    styles.header,
                    {
                      flexDirection: "row",
                      width:
                        Platform.OS == "web" &&
                          Dimensions.get("window").width >= 750 &&
                          Dimensions.get("window").width <= 1200
                          ? "100%"
                          : Platform.OS == "web" &&
                            Dimensions.get("window").width >= 1200
                            ? "50%"
                            : "100%",
                      borderWidth: 0,
                      top:
                        this.state.scrollY >= this.state.ProfileHeight + 50
                          ? this.state.scrollY - 60
                          : 0,
                    },
                  ]
                  : {
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                  }
              }
            >
              {/* <TabView
                swipeEnabled={false}
                lazy
                navigationState={this.state}
                renderScene={this._renderScene}
                renderLazyPlaceholder={this._renderLazyPlaceholder}
                renderTabBar={this._renderTabBar}
                onIndexChange={this._handleIndexChange}
                style={{
                  flex: 1,
                }}
              /> */}
              {this.state.tabValue == "Post" ?

                <CliksProfileFeedScreen
                  navigation={this.props.navigation}
                  listScroll={this.listScroll}
                  ProfileHeight={this.state.ProfileHeight}
                  scrollY={this.state.scrollY}
                /> :
                this.state.tabValue == "Member" ?
                  <ScrollView
                    containerStyle={{ marginTop: 0, flex: 1, width: "100%" }}
                    style={[
                      ButtonStyle.normalFeedStyleForDashboard,
                      {
                        width: "100%",
                        marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
                        marginBottom: 20,
                        borderTopWidth: 1,
                        borderColor: '#d3d3d3',
                        borderRadius: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: 0
                      },
                    ]}
                    showsVerticalScrollIndicator={false}
                  >
                    {this.props.cliksDetails?.getIn(["node"]) && (
                      <Suspense fallback={null}>
                        <QualificationToJoin
                          status={"Default"}
                          type={this.state.type}
                          navigation={this.props.navigation}
                        />
                      </Suspense>
                    )}

                    {this.state.showInviteLink == true &&
                      this.props.cliksDetails
                        .getIn(["data", "node"])
                        .get("invite_only") == false && <Suspense fallback={null}> <InviteViaLink /></Suspense>}

                    {this.state.showInviteLink == true &&
                      this.props.cliksDetails
                        .getIn(["data", "node"])
                        .get("invite_only") == true && (
                        <Suspense fallback={<ShadowSkeletonForProfile />}>
                          <InviteLinkPrivate InviteType={this.state.InviteType} />
                        </Suspense>
                      )}

                    {this.userApprovePermision == true && (
                      <Suspense fallback={<ShadowSkeletonForProfile />}>

                        <InviteUserModal
                          onClose={() => this.onClose()}
                          {...this.props}
                          ClikInfo={this.props.cliksDetails.getIn(["data", "node"])}
                          showHeader={false}
                        />
                      </Suspense>
                    )}
                    {this.state.MemberType == "ADMIN" ||
                      this.state.MemberType == "SUPER_ADMIN" ? (
                      <Suspense fallback={<ShadowSkeletonForProfile />}>
                        <InviteViaGoogle />
                      </Suspense>
                    ) : null}
                    <View
                      style={[
                        {
                          backgroundColor: "#fff",
                          borderBottomWidth: 1,
                          borderColor: "#D7D7D7",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          textAlign: "left",
                          color: "#000",
                          fontSize: 16,
                          fontWeight: "bold",
                          fontFamily: ConstantFontFamily.defaultFont,
                          marginVertical: 10,
                          marginHorizontal: 10,
                          marginTop: 20,
                        }}
                      >
                        Current Members
                      </Text>
                      {this.state.filterItems ? (
                        <View
                          style={{
                            paddingLeft: 10,
                            width: "100%",
                            paddingRight: 10,
                          }}
                        >
                          <FlatList
                            extraData={this.state}
                            data={this.state.filterItems}
                            keyExtractor={(item) => item.node.user.id}
                            renderItem={this._renderClikMembersItem}
                          />
                        </View>
                      ) : (
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: hp("15%"),
                          }}
                        >
                          <Icon
                            color={"#000"}
                            iconStyle={{
                              color: "#fff",
                              justifyContent: "center",
                              alignItems: "center",
                              alignSelf: "center",
                            }}
                            reverse
                            name="user-times"
                            type="font-awesome"
                            size={20}
                            containerStyle={{
                              alignSelf: "center",
                            }}
                          />
                          <Text
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              fontFamily: ConstantFontFamily.defaultFont,
                              color: "#000",
                              alignSelf: "center",
                            }}
                          >
                            No Member found
                          </Text>
                        </View>
                      )}
                    </View>
                  </ScrollView>
                  :
                  this.state.tabValue == "Application" ?
                    <ScrollView
                      containerStyle={{
                        paddingBottom: 1,
                        flex: 1,
                        width: "100%",
                        // marginTop: 20,


                      }}
                      style={[
                        ButtonStyle.normalFeedStyleForDashboard,
                        {
                          borderTopWidth: 1, width: '100%',
                          borderColor: '#d3d3d3', borderRadius: 0, marginLeft: 0,
                          marginRight: 0,
                          marginTop: 0
                        },
                      ]}
                      showsVerticalScrollIndicator={false}
                    >
                      <View
                        style={{
                          backgroundColor: "#fff",
                          marginBottom: 10,
                          borderWidth: 1,
                          borderTopWidth: 0,
                          borderColor: "#d7d7d7",
                          borderLeftWidth: 0,
                          borderRightWidth: 0

                        }}
                      >
                        <Text
                          style={{
                            textAlign: "left",
                            color: "#000",
                            fontSize: 16,
                            fontWeight: "bold",
                            fontFamily: ConstantFontFamily.defaultFont,
                            paddingVertical: 10,
                            marginHorizontal: 10,
                          }}
                        >
                          Member Applications
                        </Text>
                        {this.props.listClikUserRequest.length > 0 &&
                          this.userApprovePermision == true ? (
                          <View>
                            <FlatList
                              extraData={this.state}
                              data={this.props.listClikUserRequest}
                              keyExtractor={(item) => item.node.user.id}
                              renderItem={this._renderUserItem}
                            />
                          </View>
                        ) : (
                          this.userApprovePermision == true && (
                            <View
                              style={{
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                height: hp("15%"),
                              }}
                            >
                              <Icon
                                color={"#000"}
                                iconStyle={{
                                  color: "#fff",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignSelf: "center",
                                }}
                                reverse
                                name="user-times"
                                type="font-awesome"
                                size={20}
                                containerStyle={{
                                  alignSelf: "center",
                                }}
                              />
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontWeight: "bold",
                                  fontFamily: ConstantFontFamily.defaultFont,
                                  color: "#000",
                                  alignSelf: "center",
                                }}
                              >
                                No Applications
                              </Text>
                            </View>
                          )
                        )}
                      </View>
                    </ScrollView>
                    :
                    <CliksProfileFeedScreen
                      navigation={this.props.navigation}
                      listScroll={this.listScroll}
                      ProfileHeight={this.state.ProfileHeight}
                      scrollY={this.state.scrollY}
                    />
              }

            </View>
          )}
        </ScrollView>
        {/* {
          this.props.show == true || (Dimensions.get("window").width >= 1200 && this.state.tabValue == "Post")?
            <View style={{
              width: Platform.OS == "web" &&
                Dimensions.get("window").width >= 750 && Dimensions.get("window").width <= 1200
                ? '100%' : Platform.OS == "web" && Dimensions.get("window").width >= 1200 ? '50%'
                  : "100%", marginLeft: Dimensions.get("window").width >= 1200 ? '50%' : 0, zIndex: 999, position: 'absolute', bottom: 10,
            }}>
              <CreateCommentCard
                onClose={this.onClose}
                parent_content_id={
                  this.props.PostId &&
                  this.props.PostId.replace("Trending", "")
                    .replace("New", "")
                    .replace("Discussion", "")
                    .replace("Search", "")
                }
                clickList={
                  this.props.PostDetails
                    ? this.props.PostDetails
                    : null
                }
                initial="main"
                topComment={this.props.PostDetails && this.props.PostDetails.node}
                navigation={this.props.navigation}
                title={this.props.Title}
                type={this.props.type}
              />
            </View> : null
        } */}

        {this.state.opentool ? <Overlay
          animationType="fadeInUp"
          visible={this.state.opentool}
          onClose={this.onClose}
          closeOnTouchOutside
          children={
            <View style={{ width: Dimensions.get('window').width, height: Dimensions.get('window').height - 650, backgroundColor: "#fff", borderRadius: 8, position: "absolute", top: 250, bottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>

              <View style={{ marginTop: 20 }}>
                <TouchableOpacity style={{ justifyContent: 'center', alignItems: "center", marginBottom: 10 }}
                  onPress={() => this.showMembers(this.state.Badge == "icon" ?
                    this.props.cliksDetails && this.props.cliksDetails.getIn(["data", "node"]).get("invite_only") == true
                      ?
                      "Apply" : "Join" : "Invite")}>

                  <Text
                    style={{
                      textAlign: "center",
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                  >
                    {this.state.Badge == "icon" ?
                      this.props.cliksDetails && this.props.cliksDetails.getIn(["data", "node"]).get("invite_only") == true
                        ?
                        "Apply" : "Join" : "Invite"}
                  </Text>

                </TouchableOpacity>

                {(this.props.isAdmin == true || this.state.MemberType == "SUPER_ADMIN") &&
                  <TouchableOpacity
                    style={{ marginBottom: 10 }}
                    onPress={async () => {
                      console.log('hitting');
                      await this.props.editClik({
                        clikId: this.props.cliksDetails && this.props.cliksDetails.getIn(["data", "node"]).get("id"),
                        cliksName: this.props.cliksDetails && this.props.cliksDetails.getIn(["data", "node"]).get("name"),
                        profile_pic: this.props.cliksDetails && this.props.cliksDetails.getIn(["data", "node"]).get("profile_pic"),
                        description: this.props.cliksDetails && this.props.cliksDetails.getIn(["data", "node"]).get("description"),
                        cliksMembers: this.props.cliksDetails && this.props.cliksDetails.getIn(["data", "node"]).get("max_members"),
                        website: this.props.cliksDetails && this.props.cliksDetails.getIn(["data", "node"]).get("websites"),
                        invite_only: this.props.cliksDetails && this.props.cliksDetails.getIn(["data", "node"]).get("invite_only"),
                        qualifications:
                          this.props.cliksDetails && this.props.cliksDetails.getIn(["data", "node"]).get("qualifications")
                      });
                      console.log(this.props.navigation, 'this.props.navigation');
                      this.props.navigation.navigate("editclik");
                      this.onClose()
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontFamily: ConstantFontFamily.defaultFont
                      }}
                    >
                      Edit
                    </Text>
                  </TouchableOpacity>
                }

                <TouchableOpacity style={{ marginBottom: 10 }}>

                  <Text
                    style={{
                      textAlign: "center",
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont
                    }}
                  >
                    Report
                  </Text>

                </TouchableOpacity>
                {(this.props.isAdmin == true || this.state.MemberType == "SUPER_ADMIN") &&
                  <TouchableOpacity style={{ marginBottom: 10 }}
                    onPress={() => { this.showAlert() }}
                  >

                    <Text
                      style={{
                        textAlign: "center",
                        color: "#000",
                        fontFamily: ConstantFontFamily.defaultFont
                      }}
                    >
                      Delete
                    </Text>

                  </TouchableOpacity>
                }

              </View>
            </View>
          }
          childrenWrapperStyle={{
            padding: 0,
            margin: 0,
            borderRadius: 6
          }}
        /> : null}
        {Dimensions.get("window").width <= 750 ? (
          this.state.MemberType == "SUPER_ADMIN" ||
            this.state.MemberType == "ADMIN" ? (
            <Suspense fallback={<ShadowSkeletonForProfile />}>
              <BottomScreenProfileForAdmin navigation={NavigationService} />
            </Suspense>
          ) : (
            <Suspense fallback={<ShadowSkeletonForProfile />}>
              <BottomScreenProfile navigation={NavigationService} />
            </Suspense>
          )
        ) : null}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  cliksDetails: state.TrendingCliksProfileReducer.get(
    "getTrendingCliksProfileDetails"
  ),
  getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  listClikUserRequest: !state.ClikUserRequestReducer.get("ClikUserRequestList")
    ? List()
    : state.ClikUserRequestReducer.get("ClikUserRequestList"),
  listClikMembers: !state.ClikMembersReducer.get("clikMemberList")
    ? List()
    : state.ClikMembersReducer.get("clikMemberList"),
  loginStatus: state.UserReducer.get("loginStatus"),
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList : [],
  getsearchBarStatus: state.AdminReducer.get("searchBarOpenStatus"),
  getProfileTabView: state.AdminReducer.get("profileTabType"),
  PostCommentDetails: state.PostCommentDetailsReducer.get("PostCommentDetails"),
  PostId: state.PostCommentDetailsReducer.get("PostId"),
  PostDetails: state.PostDetailsReducer.get("PostDetails"),
  show: state.showDiscussionReducer.show,
  screenName: state.screenNameReducer.screen,
  isAdmin: state.AdminReducer.get("isAdmin"),
  getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
    ? state.LoginUserDetailsReducer.userFollowTopicsList
    : [],
  getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
    ? state.LoginUserDetailsReducer.userFollowUserList
    : [],

});

const mapDispatchToProps = (dispatch) => ({
  setHASSCROLLEDACTION: (payload) => dispatch(setHASSCROLLEDACTION(payload)),
  userId: (payload) => dispatch(getTrendingCliksProfileDetails(payload)),
  setClikUserRequest: (payload) => dispatch(listClikUserRequest(payload)),
  setClikMembers: (payload) => dispatch(listClikMembers(payload)),
  setUsernameModalStatus: (payload) =>
    dispatch(setUSERNAMEMODALACTION(payload)),
  setInviteUserDetail: (payload) =>
    dispatch({ type: "SET_INVITE_USER_DETAIL", payload }),
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
  SetProfileTabView: (payload) =>
    dispatch({ type: "SET_PROFILE_TAB_VIEW", payload }),
  clikIconUpload: (payload) => dispatch({ type: "ICON_UPLOAD", payload }),
  setClikTabView: (payload) => dispatch({ type: "SET_CLIK_TAB_VIEW", payload }),
  clikCardShow: (payload) => dispatch({ type: "CLIK_CARD_SHOW", payload }),
  setClikCardDetails: (payload) => dispatch({ type: "CARD_DETAILS", payload }),
  setIndex: (payload) => dispatch({ type: "SET_INDEX", payload }),
  showDiscussion: (payload) => dispatch(showDiscussion(payload)),
  screen: (payload) => dispatch(screen(payload)),
  editClik: payload => dispatch(editClik(payload)),
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  getTrendingClicks: payload => dispatch(getTrendingClicks(payload)),

});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CliksProfileScreen
);

const styles = StyleSheet.create({
  header: {
    position: Platform.OS == "web" ? "fixed" : null,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  container: {
    flex: 1,
    backgroundColor: ConstantColors.whiteBackground,
  },
});
