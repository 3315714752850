import firebase from "firebase/app";
import "firebase/auth";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import ActionButton from "react-native-circular-action-menu";
import { Badge, Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setAdminStatus } from "../actionCreator/AdminAction";
import { getHomefeedList } from "../actionCreator/HomeFeedAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setScreenLoadingModalAction } from "../actionCreator/ScreenLoadingModalAction";
import { setSHARELINKMODALACTION } from "../actionCreator/ShareLinkModalAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
import { getTrendingExternalFeeds } from "../actionCreator/TrendingExternalFeedsAction";
import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
import { getTrendingUsers } from "../actionCreator/TrendingUsersAction";
import {
  saveUserLoginDaitails,
  setLoginStatus
} from "../actionCreator/UserAction";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import applloClient from "../client";
import AppHelper from "../constants/AppHelper";
import {
  HomeFeedMutation
} from "../graphqlSchema/graphqlMutation/FeedMutation";
import {
  GetNumUnreadNotificationsMutation,
  MarkNotificationsAsReadMutation
} from "../graphqlSchema/graphqlMutation/Notification";
import { UserLoginMutation,UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation } from "../graphqlSchema/graphqlMutation/UserMutation";
import { setLocalStorage } from "../library/Helper";
import NavigationService from "../library/NavigationService";
import ConstantFontFamily from "../constants/FontFamily";

class BottomFloatingButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexicon: 0,
      UnreadNotifications: 0
    };
  }
  componentDidMount = async () => {
    this.props.setSignUpModalStatus(false);
    this.props.setLoginModalStatus(false);
    if (this.props.loginStatus == 1) {
      this.getUnreadNotifications();
    }
  };

  getUnreadNotifications = () => {
    applloClient
      .query({
        query: GetNumUnreadNotificationsMutation,
        variables:{
          id: "Account:CurrentUser",
          first: 100,
        },
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        this.setState({
          UnreadNotifications: res.data.node.num_unread_notifications
        });
        let timer = setTimeout(() => {
          if (this.props.loginStatus == 1) {
            this.getUnreadNotifications();
          }
        }, 60000);
      });
  };

  setMarkAsRead = () => {
    applloClient
      .query({
        query: MarkNotificationsAsReadMutation,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        this.setState({
          UnreadNotifications: 0
        });
        this.getUnreadNotifications();
      });
  };

  userLogin = async () => {
    let __self = this;
    __self.props.setScreenLoadingModalAction(true);
    await firebase.auth().onAuthStateChanged(async res => {
      if (res) {
        return await res
          .getIdToken(true)
          .then(async function(idToken) {
            await setLocalStorage("userIdTokenFirebase", idToken);
            await setLocalStorage(
              "admin",
              jwt_decode(idToken).admin ? "true" : "false"
            );
            await __self.props.changeAdminStatus(
              jwt_decode(idToken).claims.admin ? jwt_decode(idToken).claims.admin : false
            );

            if (Platform.OS == "web") {
              __self.setUserNameInExtension = __self.setLoginTokenInExtension(
                idToken
              );
            }
            return idToken;
          })
          .then(async res => {
            if (res) {
              let resDataLogin = await applloClient
              .query({
                query: UserLoginMutation,
                variables: {
                  id: "Account:" + "CurrentUser"
                },
                fetchPolicy: "no-cache"
              }).then(res => {
                return res
              })

            let userfollow = await applloClient
              .query({
                query: UserFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.users_following.edges
              })

            let topicfollow = await applloClient
              .query({
                query: TopicFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.topics_following.edges
              })
            let clikfollow = await applloClient
              .query({
                query: ClikFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.cliks_following.edges
              })
            let externalfollow = await applloClient
              .query({
                query: ExternalFeedFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.external_feeds_following.edges
              })
            let userData = {
              data: resDataLogin.data.node,
              externalfollow: externalfollow,
              clikfollow: clikfollow,
              topicfollow: topicfollow,
              userfollow: userfollow

            }
              await __self.props.saveLoginUser(userData);
              await __self.props.changeLoginStatus(1);
              __self.getTrendingFeedList();
              __self.getNewFeedList();
              __self.getDiscussedFeedList();
              __self.props.getHomefeed({
                currentPage: AppHelper.PAGE_LIMIT
              });
              __self.props.getTrendingUsers({
                currentPage: AppHelper.PAGE_LIMIT
              });
              __self.props.getTrendingClicks({
                currentPage: AppHelper.PAGE_LIMIT
              });
              __self.props.getTrendingTopics({
                currentPage: AppHelper.PAGE_LIMIT
              });
              __self.props.getTrendingExternalFeeds({
                currentPage: AppHelper.PAGE_LIMIT
              });
              await AsyncStorage.setItem(
                "userLoginId",
                resDataLogin.data.node.id
              );
              await AsyncStorage.setItem(
                "MyUserUserId",
                resDataLogin.data.node.user.id
              );
              await AsyncStorage.setItem(
                "UserId",
                resDataLogin.data.node.user.id
              );
              await AsyncStorage.setItem(
                "UserName",
                resDataLogin.data.node.user.username
              );
              if (Platform.OS == "web") {
                __self.setUserNameInExtension(
                  resDataLogin.data.node.user.username
                );
              }
              __self.props.setScreenLoadingModalAction(false);
            }
          })
          .catch(error => {
            console.log(error);
            __self.props.setScreenLoadingModalAction(false);
            // alert("Invalid email or password");
            return false;
          });
      }
    });
  };

  getIcon = () => {
    if (this.state.indexicon == 0) {
      this.setState({
        indexicon: 1
      });
    } else {
      if (this.props.loginStatus != 1) {
        this.props.setLoginModalStatus(true);
      } else {
        this.props.setShareLinkModalStatus(true);
      }
      this.setState({
        indexicon: 0
      });
    }
  };

  getBack = () => {
    this.setState({
      indexicon: 0
    });
  };
  gotoprofile = async () => {
    this.props.userId({
      username: this.props.profileData
        .getIn(["my_users"])
        .getIn(["0", "user", "username"])
    });
    NavigationService.navigate("profile", {
      username: this.props.profileData
        .getIn(["my_users"])
        .getIn(["0", "user", "username"]),
      type: "profile"
    });
    this.setState({
      indexicon: 0
    });
  };


  setLoginTokenInExtension = idToken => UserName => {
    try {
      window.parent.postMessage(
        { type: "wecklid_login", userIdTokenFirebase: idToken, UserName },
        "*"
      );
    } catch (e) {
      console.log("extension login Error ", e);
    }
  };
  

  goNotifications = () => {
    NavigationService.navigate("notification");
    this.setMarkAsRead();
    this.setState({
      indexicon: 0
    });
  };

  getTrendingFeedList = async () => {
    applloClient
      .query({
        query:HomeFeedMutation,
        variables: {
          first: 24,
          after: null,
          sort: "TRENDING",
          type:'Post'

        },
        fetchPolicy: "no-cache"
      })
      .then(response => {
        this.props.setTrendingHomeFeed(response.data.node_list.edges);
      })
      .catch(e => {
        console.log(e.message);
        this.props.setTrendingHomeFeed([]);
      });
  };

  getNewFeedList = async () => {
    applloClient
      .query({
        query:HomeFeedMutation,
        variables: {
          first: 24,
          after: null,
          sort: "NEW",
          type:'Post'

        },
        fetchPolicy: "no-cache"
      })
      .then(response => {
        this.props.setNewHomeFeed(response.data.node_list.edges);
      })
      .catch(e => {
        console.log(e);
        this.props.setNewHomeFeed([]);
      });
  };

  getDiscussedFeedList = () => {
    applloClient
      .query({
        query:HomeFeedMutation,
        variables: {
          first: 24,
          after: null,
          sort: "DISCUSSING",
          type:'Post'

        },
        fetchPolicy: "no-cache"
      })
      .then(response => {
        this.props.setDiscussionHomeFeed(response.data.node_list.edges);
      })
      .catch(e => {
        console.log(e);
        this.props.setDiscussionHomeFeed([]);
      });
  };

  showLoginModal = () => {
    this.props.setLoginModalStatus(true);
    this.setState({
      indexicon: 0
    });
  };
  render() {
    console.log('ii');
    const { indexicon } = this.state;
    return (
      <ActionButton
        buttonColor="rgba(0,0,0,1)"
        position="right"
        radius={90}
        onOverlayPress={this.getBack}
        onPress={this.getIcon}
        degrees={0}
        icon={
          indexicon == 0 ? (
            <View>
              <Icon name="plus" type="font-awesome" color="#fff" size={25} />
              {this.state.UnreadNotifications > 0 && (
                <Badge
                  value={this.state.UnreadNotifications}
                  containerStyle={{
                    position: "absolute",
                    top: -15,
                    right: -22
                  }}
                  badgeStyle={{
                    backgroundColor: "#de5246",
                    borderRadius: 10,
                    borderColor: "#de5246",
                    justifyContent: "center"
                  }}
                  textStyle={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: 13,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                />
              )}
            </View>
          ) : (
            <Icon name="edit" type="font-awesome" color="#fff" size={25} />
          )
        }
        zIndex={1000}
        size={60}
      >
        <ActionButton.Item
          buttonColor={"#D8A829"}
          onPress={() => {
            NavigationService.navigate(
              this.props.getCurrentDeviceWidthAction <= 750
                ? "bookmark"
                : "home"
            ),
              this.setState({
                indexicon: 0
              });
          }}
        >
          <Icon
            name={
              this.props.getCurrentDeviceWidthAction <= 750 ? "grade" : "home"
            }
            type="material"
            iconStyle={styles.actionButtonIcon}
            color="#fff"
          />
        </ActionButton.Item>
        <ActionButton.Item
          buttonColor="#159C23"
          onPress={() => {
            NavigationService.navigate("search"),
              this.setState({
                indexicon: 0
              });
          }}
        >
          <Icon
            name="search"
            type="font-awesome"
            iconStyle={styles.actionButtonIcon}
            color="#fff"
          />
        </ActionButton.Item>
        <ActionButton.Item
          buttonColor={"#4C8BF5"}
          onPress={() =>
            this.props.loginStatus == 1
              ? this.gotoprofile()
              : this.showLoginModal()
          }
        >
          <Icon
            name="user"
            type="font-awesome"
            iconStyle={styles.actionButtonIcon}
            color="#fff"
          />
        </ActionButton.Item>
        <ActionButton.Item
          buttonColor="#de5246"
          onPress={() =>
            this.props.loginStatus == 1
              ? this.goNotifications()
              : this.showLoginModal()
          }
        >
          <View style={{ flexDirection: "row" }}>
            <Icon
              name="bell"
              type="font-awesome"
              iconStyle={styles.actionButtonIcon}
              color="#fff"
            />
            <Badge
              value={this.state.UnreadNotifications}
              containerStyle={{
                position: "absolute",
                top: -10,
                right: -10
              }}
              badgeStyle={{
                backgroundColor: "#de5246",
                borderRadius: 10,
                borderColor: "#de5246",
                justifyContent: "center"
              }}
              textStyle={{
                textAlign: "center",
                color: "#fff",
                fontSize: 13,
                fontFamily: ConstantFontFamily.defaultFont
              }}
            />
          </View>
        </ActionButton.Item>
      </ActionButton>
    );
  }
}

const mapStateToProps = state => ({
  loginStatus: state.UserReducer.get("loginStatus"),
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension"),
});

const mapDispatchToProps = dispatch => ({
  setShareLinkModalStatus: payload =>
    dispatch(setSHARELINKMODALACTION(payload)),
  setSignUpModalStatus: payload => dispatch(setSIGNUPMODALACTION(payload)),
  userId: payload => dispatch(getCurrentUserProfileDetails(payload)),
  setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  getHomefeed: payload => dispatch(getHomefeedList(payload)),
  getTrendingUsers: payload => dispatch(getTrendingUsers(payload)),
  getTrendingTopics: payload => dispatch(getTrendingTopics(payload)),
  getTrendingClicks: payload => dispatch(getTrendingClicks(payload)),
  setScreenLoadingModalAction: payload =>
    dispatch(setScreenLoadingModalAction(payload)),
  getTrendingExternalFeeds: payload =>
    dispatch(getTrendingExternalFeeds(payload)),
  changeAdminStatus: payload => dispatch(setAdminStatus(payload)),
  setTrendingHomeFeed: payload =>
    dispatch({ type: "SET_TRENDING_HOME_FEED", payload }),
  setNewHomeFeed: payload => dispatch({ type: "SET_NEW_HOME_FEED", payload }),
  setDiscussionHomeFeed: payload =>
    dispatch({ type: "SET_DISCUSSION_HOME_FEED", payload })
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  BottomFloatingButton
);

const styles = StyleSheet.create({
  actionButtonIcon: {
    fontSize: 23,
    height: 26,
    color: "white",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center"
  }
});
