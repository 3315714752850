import { takeEvery, call, put } from 'redux-saga/effects';
import { CLIKMEMBERS, CLIKMEMBERS_SUCCESS, CLIKMEMBERS_FAILURE } from "../constants/Action";
import applloClient from '../client';
import { ClikMembersMutation } from '../graphqlSchema/graphqlMutation/PostMutation';


const getResponse = (req) => {
    return applloClient.query({
        query: ClikMembersMutation,
        variables: {
            id: "Clik:"+req.id,
            first:20
        },
        fetchPolicy: 'no-cache'
    }).then(res => res);
};

export function* ClikMembersMiddleware({ payload }) {
    try {
       const response = yield call(getResponse, payload);
        yield put({
            type: CLIKMEMBERS_SUCCESS,
            payload: response
        });
    } catch (err) {
        yield put({
            type: CLIKMEMBERS_FAILURE,
            payload: err.message
        });
        console.log(err);
    }
}

export default function* ClikMembersMiddlewareWatcher() {
    yield takeEvery(CLIKMEMBERS, ClikMembersMiddleware);

}