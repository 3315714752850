// import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import React, { createRef, lazy, Suspense } from "react";
// import { graphql } from "react-apollo";
import {
  Animated,
  // AsyncStorage,
  Dimensions,
  FlatList,
  Platform,
  Text,
  View,
  YellowBox,
  ActivityIndicator,
  Image,
  TouchableOpacity
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, Icon } from "react-native-elements";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { FlatGrid } from "react-native-super-grid";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setCreateAccount } from "../actionCreator/CreateAccountAction";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import { getHomefeedList } from "../actionCreator/HomeFeedAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
import { getTrendingUsers } from "../actionCreator/TrendingUsersAction";
import {
  saveUserLoginDaitails,
  setLoginStatus,
} from "../actionCreator/UserAction";
import { setUserApproachAction } from "../actionCreator/UserApproachAction";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import applloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import {
  AdminExternalFeedMutation,
  AdminHomeFeedMutation,
  ExternalFeedMutation,
  HomeFeedMutation,
} from "../graphqlSchema/graphqlMutation/FeedMutation";
import {
  AdminUserFeedMutation,
  UserFeedMutation,
  getCommentPost
} from "../graphqlSchema/graphqlMutation/PostMutation";
import {
  AdminClikFeed,
  AdminTopicFeed,
  ClikFeed,
  TopicFeed,
} from "../graphqlSchema/graphqlMutation/TrendingMutation";
import { UserLoginMutation } from "../graphqlSchema/graphqlMutation/UserMutation";
import ShadowSkeleton from "./ShadowSkeleton";
import { retry } from "../library/Helper";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
// import AdminCompactView from "../components/AdminCompactView";
// import AdminFeedList from "../components/AdminFeedList";
// import CompactFeedList from "../components/CompactFeedList";
// import FeedList from "../components/FeedList";
import ButtonStyle from "../constants/ButtonStyle";
import NavigationService from "../library/NavigationService";
import { NoPost, feedFluctuation } from "../reducers/action/Completed";
import { setPostDetails } from "../actionCreator/PostDetailsAction";
import { get404 } from '../reducers/action/Completed';
import { setTotalComment, setNestedTotalComment, setCommentStatus } from '../reducers/action/Completed'

// import SkeletonLoader from "tiny-skeleton-loader-react";

let feedID = [];

import AdminCompactView from "./AdminCompactView"
import CompactFeedList from "./CompactFeedList"

import FeedList from "./FeedList"
import ConstantColors from '../constants/Colors'

class TrendingHomeFeed extends React.PureComponent {
  state = {
    modalVisible: false,
    showsVerticalScrollIndicatorView: false,
    currentScreentWidth: 0,
    data: [],
    page: 1,
    loading: true,
    loadingMore: false,
    refreshing: false,
    pageInfo: null,
    error: null,
    apiCall: true,
    routes: [
      { key: "first", title: "Trending" },
      { key: "second", title: "New" },
      { key: "third", title: "Discussing" },
    ],
    index: 0,
    activeFeed: -1,
    HomeFeedError: false,
    showEmptyIcon: false,
    activeId: "",
    activeTitle: "",
    loadSkeleton: false,
  };

  constructor(props) {
    super(props);
    this.Pagescrollview = null;
    this.circleAnimatedValue = new Animated.Value(0);
    YellowBox.ignoreWarnings(["VirtualizedLists should never be nested"]);
    this.onEndReachedCalledDuringMomentum = true;
    this.viewabilityConfig = {
      viewAreaCoveragePercentThreshold: 50,
    };
    this.baseState = this.state;
    this.flatListRef = createRef();
  }

  circleAnimated = () => {
    this.circleAnimatedValue.setValue(0);
    Animated.timing(this.circleAnimatedValue, {
      toValue: 1,
      duration: 350,
    }).start(() => {
      setTimeout(() => {
        this.circleAnimated();
      }, 1000);
    });
  };

  componentDidMount = async () => {
    await this.props.doScroll(this.flatListRef, "trending");
    this.props.setCommentStatus(false)
    this.props.setTotalComment(0)
    this.props.setNestedTotalComment({ comment: 0, parentId: '', path: [] })

    if (this.props.listType == "Home") {
      // if (this.props.prevFeedList.trending.data) {
      //   this.setState({ ...this.props.prevFeedList.trending });
      // } else {
      //   this._fetchAllHomeFeeds();
      // }
      // if (sessionStorage.getItem('counter') == 1) {

      //   console.log('1st');
      //   this.props.feedFluctuation(false)
      // } else if (sessionStorage.getItem('counter') >= 2) {

      //   this.props.feedFluctuation(false)
      // } else {

      //   this.props.feedFluctuation(true)

      // }

      var id1 = window.location.href.toString().split("/");
      // console.log(id1);
      let newId = id1[id1.length - 1];
      let newId1 = newId.replace("#", "")
      if (Platform.OS != "web") {


        this.props.setPostDetails({
          title: null,
          id: "Post:" + newId1,
          navigate: false,
        });
      }
      if (Platform.OS == "web") {
        window.addEventListener(
          "unload",
          function () {
            var count = parseInt(sessionStorage.getItem("counter") || 0);

            sessionStorage.setItem("counter", ++count);
          },
          false
        );

        console.log(sessionStorage.getItem("counter"), 'trending');

        if (sessionStorage.getItem("counter") == 1) {
          this.props.feedFluctuation(false);
        } else if (sessionStorage.getItem("counter") >= 2) {

          if (
            window.location.href == "http://localhost:19006" ||
            window.location.href ==
            "http://electric-block-241402.appspot.com" ||
            window.location.href ==
            "https://electric-block-241402.appspot.com" ||
            window.location.href == "https://weclikd-beta.com" ||
            window.location.href == "http://weclikd-beta.com" ||
            window.location.href == "https://electric-block-241402.appspot.com"
          ) {

            this.props.feedFluctuation(true);

          } else {
            this.props.feedFluctuation(false);
          }

        } else {
          if (
            window.location.href == "http://localhost:19006" ||
            window.location.href ==
            "http://electric-block-241402.appspot.com" ||
            window.location.href ==
            "https://electric-block-241402.appspot.com" ||
            window.location.href == "https://weclikd-beta.com" ||
            window.location.href == "http://weclikd-beta.com" ||
            window.location.href == "https://electric-block-241402.appspot.com"
          ) {

            this.props.feedFluctuation(true);

          } else if (
            window.location.href == `http://localhost:19006/post/${newId1}` ||
            window.location.href ==
            `http://electric-block-241402.appspot.com/post/${newId1}` ||
            window.location.href ==
            `https://electric-block-241402.appspot.com/post/${newId1}` ||
            window.location.href == `https://weclikd-beta.com/post/${newId1}` ||
            window.location.href == `http://weclikd-beta.com/post/${newId1}`
          ) {
            this.props.feedFluctuation(false);




          } else if (
            window.location.href == `http://localhost:19006/comment/${newId1}` ||
            window.location.href ==
            `http://electric-block-241402.appspot.com/comment/${newId1}` ||
            window.location.href ==
            `https://electric-block-241402.appspot.com/comment/${newId1}` ||
            window.location.href == `https://weclikd-beta.com/comment/${newId1}` ||
            window.location.href == `http://weclikd-beta.com/comment/${newId1}`
          ) {
            this.props.feedFluctuation(false);

          }
        }
      }

      this._fetchAllHomeFeeds();
    } else if (this.props.listType == "Clik") {
      this.props.feedFluctuation(true);
      this._fetchAllClikFeeds();
    } else if (this.props.listType == "Topic") {
      this._fetchAllTopicFeeds();
    } else if (this.props.listType == "User") {
      this._fetchAllUserFeeds();
    } else if (this.props.listType == "Feed") {
      this._fetchAllExternalFeeds();
    }
    let data = document.getElementById("TrendingHomeFeed");
    //console.log(window.location.pathname,this.props.listType,'===================================',data);
    if (data != null) {
      await data.addEventListener("keydown", (event) => {
        if (event.keyCode == 83 || event.keyCode == 40) {
          this.setState({
            activeFeed: this.state.activeFeed + 1,
          });
        }
        if (event.keyCode == 87 || event.keyCode == 38) {
          this.setState({
            activeFeed: this.state.activeFeed - 1,
          });
        }
        if (event.keyCode == 39 || event.keyCode == 68) {
          this.setState({
            activeFeed: this.state.activeFeed + 1,
          });
        }
        if (event.keyCode == 37 || event.keyCode == 65) {
          this.setState({
            activeFeed: this.state.activeFeed - 1,
          });
        }
        if (this.state.activeFeed < this.getUnique(feedID).length) {
          this.setBorderColor();
        }
      });
    }
    if (this.state.showEmptyIcon == true) {
      this.props.NoPost(true);
    } else {
      this.props.NoPost(false);
    }

    window.addEventListener("popstate", (event) => {
      window.location.reload();
    });
    //}
    // console.log(this.props.data,'datata');
    // this.makeHighlight(
    //   this.state.data[0].node.id,
    //   this.state.data[0].node.title
    // );
    if (this.props.changeLoadTab && this.state.loadSkeleton == false) {
      this.props.changeLoadTab();
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.listType == "Topic" && prevProps.data != this.props.data) {
      this.props.feedFluctuation(true);
      await this.setState(this.baseState);
      this._fetchAllTopicFeeds();
    } else if (
      this.props.listType == "Clik" &&
      prevProps.data != this.props.data
    ) {
      this.props.feedFluctuation(true);
      await this.setState(this.baseState);
      this._fetchAllClikFeeds();
    } else if (
      this.props.listType == "User" &&
      prevProps.data != this.props.data
    ) {
      this.props.feedFluctuation(true);
      await this.setState(this.baseState);
      this._fetchAllUserFeeds();
    } else if (
      this.props.listType == "Feed" &&
      prevProps.data != this.props.data
    ) {
      this.props.feedFluctuation(true);
      await this.setState(this.baseState);
      this._fetchAllExternalFeeds();
    }

    if ((Platform.OS = "web")) {
      if (this.props.listType == "Home") {
        if (
          window.location.href == "http://localhost:19006/" ||
          window.location.href == "http://electric-block-241402.appspot.com/" ||
          window.location.href ==
          "https://electric-block-241402.appspot.com/" ||
          window.location.href == "http://localhost:19006/post/0" ||
          window.location.href == "https://weclikd-beta.com/" ||
          window.location.href == "http://weclikd-beta.com/"
        ) {
          this.props.feedFluctuation(true);
        }
      }
    }

    // if (this.props.listType == "Home") {
    //   if (prevProps.PostId != this.props.PostId) {
    //     this.props.feedFluctuation(false)
    //   } else {
    //     this.props.feedFluctuation(true)
    //   }
    // }

    // if (this.props.ActiveTab == "Trending") {
    //   setTimeout(() => {
    //     if (prevProps.trendingHomeFeedId == this.props.trendingHomeFeedId) {
    //       this.props.setPostCommentDetails({
    //         id:
    //           this.state.activeId != ""
    //             ? this.state.activeId
    //             : this.state.data.length > 0
    //             ? "Trending" + this.state.data[0].node.id
    //             : "",
    //         title:
    //           this.state.activeTitle != ""
    //             ? this.state.activeTitle
    //             : this.state.data.length > 0
    //             ? this.state.data[0].node.title
    //             : ""
    //       });
    //       this.makeHighlight(
    //         this.state.activeId != ""
    //           ? this.state.activeId
    //           : this.state.data.length > 0
    //           ? "Trending" + this.state.data[0].node.id
    //           : "",
    //         this.state.activeTitle != ""
    //           ? this.state.activeTitle
    //           : this.state.data.length > 0
    //           ? this.state.data[0].node.title
    //           : ""
    //       );
    //     }
    //   }, 2000);
    // }    ss
    setTimeout(() => {
      //console.log(window.location.pathname,this.props.listType,'===================================');
      if (
        this.state.activeId == "" &&
        this.props.ActiveTab == "Trending" &&
        this.state.data.length > 0 &&
        window.location.href.search("/post") == -1
      ) {
        this.makeHighlight(
          this.state.data[0].node.id,
          this.state.data[0].node.title
        );
        // let id=this.state.data[0].node.id.replace("Post:", "")
        // AsyncStorage.setItem(
        //   'PostId',
        //   JSON.stringify(id)
        // );
        // let id=this.state.data[0].node.id.replace("Post:", "");
        // NavigationService.navigate("feedId",{id:id});
        // this.props.setPostCommentDetails({
        //   id: this.state.data[0].node.id,
        //   title: this.state.data[0].node.title,
        // });
        // console.log(this.state.data[0].node.id,
        //   this.state.data[0].node.title);

        this.setBorderColor();
      } else if (this.state.data.length == 0 && Dimensions.get("window").width >= 1200) {
        this.props.setPostCommentReset({
          payload: [],
          postId: "",
          title: "",
          loading: true,
        });
      }
    }, 2000);

    if (this.props.changeLoadTab && this.state.loadSkeleton == false) {
      this.props.changeLoadTab();
    }
  };

  setBorderColor = async () => {
    let highlightBorderId;
    // await AsyncStorage.getItem('PostId').then(houses => { highlightBorderId = this.props.ActiveTab + JSON.parse(houses); });
    // for (let i = 0; i < this.getUnique(feedID).length; i++) {
    //   let data = document.getElementById(this.getUnique(feedID)[i]);
    //   if (data != null && Dimensions.get("window").width >= 1000) {
    //     document.getElementById(!highlightBorderId ? highlightBorderId : this.getUnique(feedID)[i]).style.borderColor =
    //       i == this.state.activeFeed ? "green" : "#c5c5c5";
    //     if (i == this.state.activeFeed) {
    //       document.getElementById(this.getUnique(feedID)[i]).click();
    //     }
    //   }
    // }
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.componentDidMount());
  };

  _handleLoadMore = (distanceFromEnd) => {
    this.setState(
      (prevState, nextProps) => ({}),
      () => {
        if (
          0 <= distanceFromEnd &&
          distanceFromEnd <= 5 &&
          this.state.apiCall == true
        ) {
          this.setState({
            apiCall: false,
          });
          if (this.props.listType == "Home") {
            this._fetchAllHomeFeeds();
          } else if (this.props.listType == "Clik") {
            this._fetchAllClikFeeds();
          } else if (this.props.listType == "Topic") {
            this._fetchAllTopicFeeds();
          } else if (this.props.listType == "User") {
            this._fetchAllUserFeeds();
          } else if (this.props.listType == "Feed") {
            this._fetchAllExternalFeeds();
          }
        }
      }
    );
  };

  _fetchAllHomeFeeds = () => {

    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: HomeFeedMutation,
          variables: {
            //first: 24,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
            type: 'Post'
          },
          fetchPolicy: "no-cache",
        })
        .then(async (response) => {
          this.props.setTrendingHomeFeed(response.data.node_list.edges);
          let data1 = response.data.node_list.edges;
          let data2 = "";
          //console.log(this.props.isfeed, 'isfeed');
          if (this.props.isfeed == true) {
            this.props.setUpdatedPostId(
              ""// response.data.node_list.edges[0].node.id
            );
            await AsyncStorage.setItem(
              "dataItem",
              [] // JSON.stringify(response.data.node_list.edges[0])
            );
            let id = response.data.node_list.edges[0].node.id.replace(
              "Post:",
              ""
            );
            // NavigationService.navigate("feedId", { id: id });
            this.props.setPostCommentReset({
              payload: [],
              postId: "",
              title: "",
              loading: true,
            });
            this.props.setPostCommentDetails({
              id:"",
              title: "",
              loading:false,
            });
          } else {
            let uri = window.location.href.toString().search('post')
            if (uri != -1) {
              this.props.setPostCommentReset({
                payload: [],
                postId: "",
                title: "",
                loading: true,
              });
              var id11 = window.location.href.toString().split("/");
              let newId1 = id11[id11.length - 1];
              this.props.setPostCommentDetails({
                id: "Post:" + newId1,
                title: '',
                loading: false,
              });

            } else {
              var id1 = window.location.href.toString().split("/");
              let newId = id1[id1.length - 1];
              // NavigationService.navigate("commentID", { id: newId })

              applloClient
                .query({
                  query:
                    getCommentPost,
                  variables: {
                    id: "Comment:" + newId,
                    depth: 10,
                    sort_type: "NEW"
                  },
                  fetchPolicy: "no-cache",
                }).then(e => {
                  console.log(e, 'eeeeee');
                  this.props.setPostCommentReset({
                    payload: [],
                    postId: "",
                    title: "",
                    loading: true,
                  });
                  this.props.setPostCommentDetails({
                    id: "Post:" + e?.data?.node?.post?.id?.replace("Post:", ""),
                    title: e?.data?.node?.post?.title,
                    loading: false,
                  });
                  this.props.setUpdatedPostId(e?.data?.node?.post?.id);
                })
            }
          }

          await __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data.node_list.edges),
            pageInfo: response.data.node_list.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false,
            HomeFeedError: false,
            refreshing: false,
            showEmptyIcon:
              response.data.node_list.edges.length == 0 ? true : false,
            loadSkeleton: false,
          });
          this.makeHighlight(
            this.state.data[0].node.id,
            this.state.data[0].node.title
          );
          await __self.props.setPrevList(
            {
              isLoading: false,
              loading: false,
              data: Array.from(response.data.node_list.edges),
              pageInfo: response.data.node_list.pageInfo,
              page: page + 1,
              apiCall: true,
              loadingMore: false,
              HomeFeedError: false,
              refreshing: false,
            },
            "trending"
          );
          // if (this.props.ActiveTab == "Trending") {
          //   setTimeout(() => {
          //     this.makeHighlight(
          //       response.data.node_list.edges[0].node.id,
          //       response.data.node_list.edges[0].node.title
          //     );
          //   }, 1000);
          // }
        })
        .catch((e) => {
          console.log(e.message);
          __self.setState({
            isLoading: false,
            data: [],
            loading: false,
            loadingMore: false,
            HomeFeedError: true,
            refreshing: false,
            loadSkeleton: false,
          });
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: HomeFeedMutation,
          variables: {
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
            type: 'Post'
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.props.setTrendingHomeFeed(
            __self.state.data.concat(response.data.node_list.edges)
          );
          __self.setState({
            isLoading: false,
            loading: false,
            data: __self.state.data.concat(response.data.node_list.edges),
            pageInfo: response.data.node_list.pageInfo,
            apiCall: true,
            loadingMore: false,
            HomeFeedError: false,
            refreshing: false,
          });
          __self.props.setPrevList(
            {
              loading: false,
              isLoading: false,
              data: __self.state.data.concat(
                response.data.node_list.edges
              ),
              pageInfo: response.data.node_list.pageInfo,
              apiCall: true,
              loadingMore: false,
              HomeFeedError: false,
              refreshing: false,
            },
            "trending"
          );
        })
        .catch((e) => {
          console.log(e);
          __self.setState({
            isLoading: false,
            data: [],
            loading: false,
            loadingMore: false,
            HomeFeedError: true,
            refreshing: false,
          });
        });
    }
  };

  _fetchAllTopicFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;
    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: TopicFeed,
          variables: {
            id: "Topic:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          if (response.data.node == null) {
            NavigationService.navigate("404");
            this.props.get404(true)
          }
          // this.props.setPostCommentDetails({
          //   id: response.data.topic.posts.edges[0].node.id,
          //   title: response.data.topic.posts.edges[0].node.title
          // });
          if (this.props.isfeed == true) {
            console.log(';topicfeed');
            if (response.data.node.posts.edges.length > 0) {
              this.props.setTrendingTopicHomeFeed(response.data?.node?.posts?.edges);
              this.props.setPostCommentReset({
                payload: [],
                postId: "",
                title: "",
                loading: true,
              });
              this.props.setPostCommentDetails({
                id:
                  "Post:" +
                  response.data.node.posts.edges[0].node.id.replace(
                    "Post:",
                    ""
                  ),
                title: response.data.node.posts.edges[0].node.title,
                loading: false,
              });
            }
          } else {
            if (response.data.node.posts.edges.length > 0) {
              response.data.node.posts.edges.map((e) => {
                if (e.node.id == this.props.updatedPostId) {
                  response.data.node.posts.edges.splice(
                    response.data.node.posts.edges.indexOf(e),
                    1
                  );
                  response.data.node.posts.edges.unshift(e);
                }
              });
            }
          }
          __self.setState({
            loading: false,
            data: Array.from(response.data?.node?.posts?.edges),
            pageInfo: response.data?.node?.posts?.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
            showEmptyIcon:
              response.data.node?.posts?.edges?.length == 0 ? true : false,
            loadSkeleton: false,
          });
          // setTimeout(() => {
          //   this.makeHighlight(
          //     response.data.topic.posts.edges[0].node.id,
          //     response.data.topic.posts.edges[0].node.title
          //   );
          // }, 1000);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
      });
      applloClient
        .query({
          query: TopicFeed,
          variables: {
            id: "Topic:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.props.setTrendingTopicHomeFeed(
            __self.state.data.concat(response.data?.node?.posts?.edges)
          );
          __self.setState({
            isLoading: false,
            loading: false,
            data: __self.state.data.concat(response.data?.node?.posts?.edges),
            pageInfo: response.data?.node?.posts?.pageInfo,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  _fetchAllClikFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    // console.log(this.props.data, '_self.props.data');
    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: ClikFeed,
          variables: {
            id: Dimensions.get('window').width > 750 ? __self.props.data : "Clik:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          // console.log(response, 'clikpofile');
          if (response.data.node == null && dimae) {
            NavigationService.navigate("404");
            this.props.get404(true)
          }
          this.props.setTrendingClikHomeFeed(response.data?.node?.posts?.edges);
          if (this.props.isfeed == true) {
            if (response?.data?.node?.posts?.edges?.length > 0) {
              this.props.setPostCommentReset({
                payload: [],
                postId: "",
                title: "",
                loading: true,
              });
              this.props.setPostCommentDetails({
                id:
                  "Post:" +
                  response?.data?.node?.posts?.edges[0]?.node?.id.replace(
                    "Post:",
                    ""
                  ),
                title: response?.data?.node?.posts?.edges[0]?.node.title,
                loading: false,
              });
            }
          } else {
            // if (this.props.listType == "Clik" || this.props.listType == "Topic" || this.props.listType == "User" || this.props.listType == 'Feed') {
            // this.props.feedFluctuation(false)
            if (response.data?.node?.posts?.edges?.length > 0) {
              response?.data?.node?.posts?.edges?.map((e) => {
                if (e.node.id == this.props.updatedPostId) {
                  response?.data?.node?.posts?.edges?.splice(
                    response?.data?.node?.posts?.edges?.indexOf(e),
                    1
                  );
                  response?.data?.node?.posts?.edges?.unshift(e);
                }
              });
            }
            // }
          }

          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response?.data?.node?.posts?.edges),
            pageInfo: response?.data?.node?.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
            showEmptyIcon:
              response?.data ? response?.data?.node?.posts?.edges?.length == 0 ? true : false : false,
            loadSkeleton: false,
          });
          response?.data?.node?.posts?.edges?.length == 0
            ? this.props.NoPost(true)
            : this.props.NoPost(false);
          // setTimeout(() => {
          //   this.makeHighlight(
          //     response.data.clik.posts.edges[0].node.id,
          //     response.data.clik.posts.edges[0].node.title
          //   );
          // }, 1000);
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: ClikFeed,
          variables: {
            id: __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: __self.state.data.concat(response.data?.node?.posts?.edges),
            pageInfo: response.data?.node?.posts?.pageInfo,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };

  _fetchAllUserFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: UserFeedMutation,
          variables: {
            id: "User:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {

          if (response.data.node == null) {
            NavigationService.navigate("404");
            this.props.get404(true)
          }
          // this.props.setPostCommentDetails({
          //   id: response.data.user.posts.edges[0].node.id,
          //   title: response.data.user.posts.edges[0].node.title
          // });
          this.props.setTrendingUserHomeFeed(response?.data?.node?.posts?.edges);
          if (this.props.isfeed == true) {
            if (response?.data?.node?.posts?.edges?.length > 0) {
              this.props.setPostCommentReset({
                payload: [],
                postId: "",
                title: "",
                loading: true,
              });
              this.props.setPostCommentDetails({
                id:
                  "Post:" +
                  response.data.node.posts.edges[0].node.id.replace(
                    "Post:",
                    ""
                  ),
                title: response.data.node.posts.edges[0].node.title,
                loading: false,
              });
            }
          } else {
            if (response.data?.node?.posts?.edges?.length > 0) {
              response.data.node.posts.edges.map((e) => {
                if (e.node.id == this.props.updatedPostId) {
                  response.data?.node?.posts?.edges?.splice(
                    response.data?.node?.posts?.edges?.indexOf(e),
                    1
                  );
                  response.data?.node?.posts?.edges?.unshift(e);
                }
              });
            }
          }

          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data?.node?.posts?.edges),
            pageInfo: response.data?.node?.posts?.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
            showEmptyIcon:
              response.data.node?.posts?.edges?.length == 0 ? true : false,
            loadSkeleton: false,
          });
          // setTimeout(() => {
          //   this.makeHighlight(
          //     response.data.user.posts.edges[0].node.id,
          //     response.data.user.posts.edges[0].node.title
          //   );
          // }, 1000);
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: UserFeedMutation,
          variables: {
            id: "User:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: __self.state.data.concat(response.data?.node?.posts?.edges),
            pageInfo: response.data?.node?.posts?.pageInfo,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };

  _fetchAllExternalFeeds = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null) {
      this.setState({
        loadingMore: true,
        loadSkeleton: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: ExternalFeedMutation,
          variables: {
            id: "ExternalFeed:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          // this.props.setPostCommentDetails({
          //   id: response.data.external_feed.edges[0].node.id,
          //   title: response.data.external_feed.edges[0].node.title
          // });
          if (response.data.node == null) {
            NavigationService.navigate("404");
            this.props.get404(true)
          }
          this.props.setTrendingExternalHomeFeed(
            response.data.node.posts.edges
          );
          if (this.props.isfeed == true) {
            if (response.data.node.posts.edges.length > 0) {
              this.props.setPostCommentReset({
                payload: [],
                postId: "",
                title: "",
                loading: true,
              });
              this.props.setPostCommentDetails({
                id:
                  "Post:" +
                  response.data.node.posts.edges[0].node.id.replace(
                    "Post:",
                    ""
                  ),
                title: response.data.node.posts.edges[0].node.title,
                loading: false,
              });
            }
          } else {
            if (response.data.node.posts.edges.length > 0) {
              response.data.node.posts.edges.map((e) => {
                if (e.node.id == this.props.updatedPostId) {
                  response.data.node.posts.edges.splice(
                    response.data.node.posts.edges.indexOf(e),
                    1
                  );
                  response.data.node.posts.edges.unshift(e);
                }
              });
            }
          }

          __self.setState({
            isLoading: false,
            loading: false,
            data: Array.from(response.data?.node?.posts?.edges),
            pageInfo: response.data?.node?.posts?.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
            showEmptyIcon:
              response.data.node?.posts?.edges?.length == 0
                ? true
                : false,
            loadSkeleton: false,
          });
          // setTimeout(() => {
          //   this.makeHighlight(
          //     response.data.external_feed.posts.edges[0].node.id,
          //     response.data.external_feed.posts.edges[0].node.title
          //   );
          // }, 1000);
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true,
        isLoading: true,
      });
      applloClient
        .query({
          query: ExternalFeedMutation,
          variables: {
            id: "ExternalFeed:" + __self.props.data,
            first: 24,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          __self.setState({
            isLoading: false,
            loading: false,
            data: __self.state.data.concat(
              response.data?.node?.posts?.edges
            ),
            pageInfo: response.data?.node?.posts?.pageInfo,
            apiCall: true,
            loadingMore: false,
            refreshing: false,
          });
        })
        .catch((e) => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }
  };

  _handleRefresh = () => {
    this.setState(
      {
        page: 1,
        refreshing: true,
      },
      () => {
        if (this.props.listType == "Home") {
          this._fetchAllHomeFeeds();
        } else if (this.props.listType == "Clik") {
          this._fetchAllClikFeeds();
        } else if (this.props.listType == "Topic") {
          this._fetchAllTopicFeeds();
        } else if (this.props.listType == "User") {
          this._fetchAllUserFeeds();
        } else if (this.props.listType == "Feed") {
          this._fetchAllExternalFeeds();
        }
      }
    );
  };

  _renderFooter = () => {
    if (!this.state.loadingMore)
      return (
        <View
          style={{
            // borderWidth: this.props.noPost == true ? 0 : 1,
            borderColor: "#d7d7d7",
            marginRight: 1,
            borderBottomWidth: 0,
            borderTopWidth: 0,
            // height: Dimensions.get('window').height/5,
          }}
        >
          <Text
            style={{
              fontFamily: ConstantFontFamily.defaultFont,
              fontSize: 13,
              textAlign: "center",
              paddingVertical: 10,
              color: "grey",
            }}
          >
            {(!this.state.isLoading && this.state.data?.length > 0) || (this.props.isfeed && (!this.state.showEmptyIcon || this.state.data?.length > 1)) || (!this.state.HomeFeedError && this.state.data?.length > 0)
              ? "No more posts"
              : null}
          </Text>
        </View>
      );

    if (this.state.loadingMore || this.state.isLoading)
      return (
        <View style={{ marginVertical: 20 }}>
          <ActivityIndicator animating size="large" color="#000" />
        </View>
      );
  };

  _renderLogoutFooter = () => {
    return (
      this.state.data.length > 0 && (
        <View
          style={{
            // flex: 1,
            alignContent: "center",
            alignItems: "center",
            paddingVertical: 15,
          }}
        >
          {/* <Icon
            color={"#000"}
            iconStyle={{
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
            name="arrow-down"
            type="font-awesome"
            size={20}
            containerStyle={{
              alignSelf: "center",
            }}
          /> */}
          <Hoverable>
            {(isHovered) => (
              <Text
                style={{
                  color: isHovered == true ? ConstantColors.blueColor : "#000",
                  fontSize: 12,
                  textAlign: "center",
                  fontWeight: "bold",
                  fontFamily: ConstantFontFamily.defaultFont,
                }}
              >
                <Text
                  onPress={() => this.props.setLoginModalStatus(true)}
                  style={{
                    textDecorationLine: "underline",
                    fontSize: 12,
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                >
                  Login
                </Text>{" "}
                to see more...
              </Text>
            )}
          </Hoverable>
        </View>
      )
    );
  };

  _renderItem = (item) => {
    this.getfeedID("Trending" + item.item.node.id);
    if (this.props.ViewMode != "Compact") {
      return this.props.isAdmin == true ? (
        this.state.loadSkeleton == true ? (
          <ShadowSkeleton />
        ) : (
          // <Suspense fallback={null}>

          <FeedList
            loginModalStatusEventParent={this.handleModal}
            item={item}
            navigation={this.props.navigation}
            ViewMode={this.props.ViewMode}
            highlight={this.makeHighlight}
            tabPost={this.props.tabPost}
            changeTabStatus={this.props.changeTabStatus}
            onLoadingComment={this.props.onLoadingComment}
            ActiveTab="Trending"
            deleteItem={this.deleteItemById}
            data={this.state.data}
            index={this.props.index}
            listType={this.props.listType}
          />
          // </Suspense>
        )
      ) : this.state.loadSkeleton == true ? (
        <ShadowSkeleton />
      ) : (
        // <Suspense fallback={null}>

        <FeedList
          loginModalStatusEventParent={this.handleModal}
          item={item}
          navigation={this.props.navigation}
          ViewMode={this.props.ViewMode}
          highlight={this.makeHighlight}
          tabPost={this.props.tabPost}
          changeTabStatus={this.props.changeTabStatus}
          onLoadingComment={this.props.onLoadingComment}
          ActiveTab="Trending"
          deleteItem={this.deleteItemById}
          data={this.state.data}
          index={this.props.index}
          listType={this.props.listType}
        />
        // </Suspense>
      );
    } else {
      return this.props.isAdmin == true ? (
        // <Suspense fallback={null}>
        <AdminCompactView
          loginModalStatusEventParent={this.handleModal}
          item={item}
          navigation={this.props.navigation}
          ViewMode={this.props.ViewMode}
          highlight={this.makeHighlight}
          data={this.state.data}
        />
        // </Suspense>
      ) : (
        // <Suspense fallback={null}>

        <CompactFeedList
          loginModalStatusEventParent={this.handleModal}
          item={item}
          navigation={this.props.navigation}
          ViewMode={this.props.ViewMode}
          highlight={this.makeHighlight}
          data={this.state.data}
        />
        // </Suspense>
      );
    }
  };

  deleteItemById = (id) => {
    const filteredData = this.state?.data?.filter((item) => item.node.id !== id);
    this.setState({ data: filteredData });
  };

  makeHighlight = async (id, title) => {
    let newId = id.search("Trending") != -1 ? id : "Trending" + id;
    this.setState({
      activeFeed: this.getUnique(feedID).indexOf(newId),
      activeId: id,
      activeTitle: title,
    });
    this.setBorderColor();
  };

  handleModal = (status) => {
    this.setState({
      modalVisible: status,
    });
  };

  onClose = () => {
    this.setState({
      modalVisible: false,
    });
  };

  getfeedID = async (id) => {
    let data = feedID;
    await data.push(id);
    feedID = data;
  };

  getUnique = (array) => {
    var uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  };

  onLayout = async (event) => {
    const { width, height } = event.nativeEvent.layout;
    if (width > 0) {
      let data = document.getElementById("TrendingHomeFeed");
      if (data != null) {
      }
    }
  };

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    let perLoadDataCount = 24;
    let halfOfLoadDataCount = perLoadDataCount / 2;
    let lastAddArr = this.state.data.slice(-perLoadDataCount);

    try {
      if (
        lastAddArr[halfOfLoadDataCount] &&
        viewableItems.length > 0 &&
        this.props.loginStatus == 1
      ) {
        // FlatGrid
        if (Array.isArray(viewableItems[0].item)) {
          if (
            viewableItems[0].item.find(
              (item) => item.node.id === lastAddArr[halfOfLoadDataCount].node.id
            )
          ) {
            this._handleLoadMore(0);
          }
        }
        //FlatList
        else {
          if (
            viewableItems.find(
              (item) =>
                item.item.node.id === lastAddArr[halfOfLoadDataCount].node.id
            )
          ) {
            this._handleLoadMore(0);
          }
        }
      }
    } catch (e) {
      console.log(e, "lastAddArr", lastAddArr[halfOfLoadDataCount]);
    }
  };

  // getFirebaseToken = async () => {
  //   if (firebase.auth().currentUser) {
  //     await firebase
  //       .auth()
  //       .currentUser.getIdToken(true)
  //       .then(async (idToken) => {
  //         await AsyncStorage.setItem("userIdTokenFirebase", idToken);
  //         this._fetchAllHomeFeeds();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } else {
  //     await firebase.auth().onAuthStateChanged(async (user) => {
  //       if (user) {
  //         await user
  //           .getIdToken(true)
  //           .then(async (idToken) => {
  //             await AsyncStorage.setItem("userIdTokenFirebase", idToken);
  //             this._fetchAllHomeFeeds();
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //       }
  //     });
  //   }
  // };

  render() {
    return (
      <View
        onLayout={this.onLayout}
        nativeID={"TrendingHomeFeed"}
        style={{
          marginTop: Dimensions.get("window").width <= 750 ? 0 : 0,
          flex: 1,
        }}
      >
        {this.state.data.length == 0 && this.state.showEmptyIcon == false && this.state.HomeFeedError == false && (
          <ShadowSkeleton />
        )}
        {
          // this.state.data.length == 0 &&
          this.state.showEmptyIcon == true && (
            <View
              style={{
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 20,
                // borderLeftWidth: 1,
                // borderRightWidth: 1,
                marginRight: 1,
                // borderColor: '#d7d7d7'
              }}
            >
              <Icon
                color={"#000"}
                iconStyle={{
                  color: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                }}
                reverse
                name="file"
                type="font-awesome"
                size={20}
                containerStyle={{
                  alignSelf: "center",
                }}
              />
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  fontFamily: ConstantFontFamily.defaultFont,
                  color: "#000",
                  alignSelf: "center",
                }}
              >
                No posts
              </Text>
            </View>
          )
        }
        {this.state.HomeFeedError == true && (
          <TouchableOpacity
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginVertical: 20,
            }}
            onPress={() =>
              ''  // this.getFirebaseToken()
            }

          >
            <Image
              source={require("../assets/error.png")}
              style={{
                justifyContent: "flex-start",
                height: 100,
                width: 100,
                // marginRight: 5,
                borderRadius: 5
              }}
            // resizeMode={"contain"}
            />
            {/* <Button
            onPress={() => this.getFirebaseToken()}
            title={"Try Again"}
            titleStyle={ButtonStyle.titleStyle}
            buttonStyle={ButtonStyle.backgroundStyle}
            containerStyle={ButtonStyle.containerStyle}
          /> */}
          </TouchableOpacity>
        )}

        {(this.props.ViewMode == "Default" ||
          this.props.ViewMode == "Text") && (
            <FlatList
              // contentContainerStyle={
              //   Platform.OS == "web" && Dimensions.get("window").width >= 750
              //     ? {
              //         flexDirection: "column",
              //         height: hp("100%") - 50,
              //         minWidth: "100%",
              //         alignSelf: "center"
              //       }
              //     : {}
              // }
              ref={this.flatListRef}
              // data={this.props.isfeed == true ? this.state.data : this.state.data.slice(0, 1)}
              data={(this.props.isfeed == true && this.props.loginStatus == 1)? this.state.data :
                this.props.isfeed == true && this.props.loginStatus == 0 ? this.state.data.slice(0, 10) : this.state.data.slice(0, 1)}

              onScroll={(event) => {
                this.props.listType != "Home"
                  ? this.props.listScroll(event.nativeEvent.contentOffset.y)
                  : null;
              }}
              scrollEventThrottle={16}
              keyExtractor={(item, index) => item.node.id}
              renderItem={this._renderItem}
              showsVerticalScrollIndicator={false}
              onRefresh={this._handleRefresh}
              refreshing={this.state.refreshing}
              onEndReached = {()=>this.props.loginStatus == 0 ? this.props.setUsernameModalStatus(true) : null}
              onEndReachedThreshold={0.2}
              initialNumToRender={8}
              ListFooterComponent={
                (this.props.loginStatus == 1
                  && this.props.isfeed == true )? this._renderFooter  : null
              }
              onViewableItemsChanged={this.onViewableItemsChanged}
              viewabilityConfig={this.viewabilityConfig}
              removeClippedSubviews={false}
            // contentContainerStyle = {{ flex: 1 }}
            />
          )}
        {this.props.ViewMode == "Card" && (
          <FlatGrid
            ref={this.flatListRef}
            itemDimension={250}
            items={this.props.isfeed == true ? this.state.data : this.state.data.slice(0, 1)}
            renderItem={this._renderItem}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            onScroll={(event) => {
              this.props.listType != "Home"
                ? this.props.listScroll(event.nativeEvent.contentOffset.y)
                : null;
            }}
            scrollEventThrottle={16}
            spacing={10}
            style={{
              flexDirection: "column",
              height: hp("100%") - 50,
              paddingTop: 0,
            }}
            itemContainerStyle={{
              justifyContent: "flex-start",
            }}
            onRefresh={this._handleRefresh}
            refreshing={this.state.refreshing}
            onEndReached={({ distanceFromEnd }) => {
              // this._handleLoadMore(distanceFromEnd);
            }}
            onEndReachedThreshold={0.2}
            initialNumToRender={10}
            ListFooterComponent={
              this.props.loginStatus == 1
                ? this._renderFooter
                : this._renderLogoutFooter
            }
            onViewableItemsChanged={this.onViewableItemsChanged}
            viewabilityConfig={this.viewabilityConfig}
            removeClippedSubviews={true}
          />
        )}
        {this.props.ViewMode == "Compact" && (
          <FlatList
            ref={this.flatListRef}
            contentContainerStyle={{
              flexDirection: "column",
              height: hp("100%") - 50,
              width: "100%",
            }}
            data={this.state.data}
            onScroll={(event) => {
              this.props.listType != "Home"
                ? this.props.listScroll(event.nativeEvent.contentOffset.y)
                : null;
            }}
            scrollEventThrottle={16}
            keyExtractor={(item, index) => index.toString()}
            renderItem={this._renderItem}
            showsVerticalScrollIndicator={false}
            onRefresh={this._handleRefresh}
            refreshing={this.state.refreshing}
            onEndReached={({ distanceFromEnd }) => {
              // this._handleLoadMore(distanceFromEnd);
            }}
            onEndReachedThreshold={0.2}
            initialNumToRender={10}
            ListFooterComponent={
              this.props.loginStatus == 1
                ? this._renderFooter
                : this._renderLogoutFooter
            }
            onViewableItemsChanged={this.onViewableItemsChanged}
            viewabilityConfig={this.viewabilityConfig}
            removeClippedSubviews={true}
          // contentContainerStyle = {{ flex: 1 }}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  listHomeFeed: state.HomeFeedReducer.get("HomefeedList"),
  homefeedListPagination: state.HomeFeedReducer.get("HomefeedListPagination"),
  getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  getCreateAccount: state.CreateAccountReducer.get("setCreateAccountData"),
  getUserApproach: state.UserApproachReducer.get("setUserApproach"),
  loginStatus: state.UserReducer.get("loginStatus"),
  getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension"),
  isAdmin: state.AdminReducer.get("isAdmin"),
  isAdminView: state.AdminReducer.get("isAdminView"),
  TrendingHomeFeed: state.HomeFeedReducer.get("TrendingHomeFeedList"),
  trendingHomeFeedId: state.PostCommentDetailsReducer.get("PostId"),
  noPost: state.NopostReducer.noPost,
  isfeed: state.AdminReducer.get("isfeed"),
  PostId: state.PostCommentDetailsReducer.get("PostId"),
  updatedPostId: state.AdminReducer.get("updatedPostId"),
  TrendingClikHomeFeedList: state.HomeFeedReducer.get(
    "TrendingClikHomeFeedList"
  ),
});

const mapDispatchToProps = (dispatch) => ({
  setPostDetails: (payload) => dispatch(setPostDetails(payload)),
  getHomefeed: (payload) => dispatch(getHomefeedList(payload)),
  getTrendingUsers: (payload) => dispatch(getTrendingUsers(payload)),
  getTrendingTopics: (payload) => dispatch(getTrendingTopics(payload)),
  setSignUpModalStatus: (payload) => dispatch(setSIGNUPMODALACTION(payload)),
  setUsernameModalStatus: (payload) =>
    dispatch(setUSERNAMEMODALACTION(payload)),
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
  changeLoginStatus: (payload) => dispatch(setLoginStatus(payload)),
  getTrendingClicks: (payload) => dispatch(getTrendingClicks(payload)),
  setHASSCROLLEDACTION: (payload) => dispatch(setHASSCROLLEDACTION(payload)),
  setCreateAccount: (payload) => dispatch(setCreateAccount(payload)),
  setUserApproachAction: (payload) => dispatch(setUserApproachAction(payload)),
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  setTrendingHomeFeed: (payload) =>
    dispatch({ type: "SET_TRENDING_HOME_FEED", payload }),
  setTrendingTopicHomeFeed: (payload) =>
    dispatch({ type: "SET_TRENDING_TOPIC_HOME_FEED", payload }),
  setTrendingClikHomeFeed: (payload) =>
    dispatch({ type: "SET_TRENDING_CLIK_HOME_FEED", payload }),
  setTrendingUserHomeFeed: (payload) =>
    dispatch({ type: "SET_TRENDING_USER_HOME_FEED", payload }),
  setTrendingExternalHomeFeed: (payload) =>
    dispatch({ type: "SET_TRENDING_EXTERNAL_HOME_FEED", payload }),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
  setPostCommentReset: (payload) =>
    dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  NoPost: (payload) => dispatch(NoPost(payload)),
  feedFluctuation: (payload) => dispatch(feedFluctuation(payload)),
  setUpdatedPostId: (payload) =>
    dispatch({ type: "SET_UPDATED_POSTID", payload }),
  get404: (payload) => dispatch(get404(payload)),
  setTotalComment: (payload) => dispatch(setTotalComment(payload)),
  setNestedTotalComment: (payload) => dispatch(setNestedTotalComment(payload)),
  setCommentStatus: (payload) => dispatch(setCommentStatus(payload)),
  setUsernameModalStatus: (payload) =>
  dispatch(setUSERNAMEMODALACTION(payload)),
});



export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TrendingHomeFeed
);

