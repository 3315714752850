import React from "react";
import { graphql } from "react-apollo";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  // AsyncStorage,
  Dimensions,
  Image,
  Text,
  TextInput,
  View,
  TouchableOpacity
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import {
  saveUserLoginDaitails,
  setLoginStatus
} from "../actionCreator/UserAction";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import applloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import {
  AccountCreateMutation,
  CheckUsernameMutation,
  UserLoginMutation
} from "../graphqlSchema/graphqlMutation/UserMutation";
import "firebase/auth";
import { setLocalStorage } from "../library/Helper";
import AppHelper from "../constants/AppHelper";
import ButtonStyle from "../constants/ButtonStyle";
import NavigationService from "../library/NavigationService";
import { setCreateAccount } from "../actionCreator/CreateAccountAction";
import { AccountCreateVariables } from "../graphqlSchema/graphqlVariables/UserVariables";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
import { getTrendingUsers } from "../actionCreator/TrendingUsersAction";
import { getTrendingExternalFeeds } from "../actionCreator/TrendingExternalFeedsAction";
import { InviteKeyClikProfileMutation } from "../graphqlSchema/graphqlMutation/FollowandUnFollowMutation";
import { Hoverable } from "react-native-web-hooks";
import { UserQueryMutation } from "../graphqlSchema/graphqlMutation/UserMutation";

const expr = /^[a-zA-Z0-9_]{3,16}$/;

class AddUsernameCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserNameData: "",
      errorMessage: "",
      typingUsername: false,
      errorForm: true,
      clikName: "",
      inviteKey: "",
      userName: "",
      InviteClik: {},
      inviteUsername: "",
      inviterProfilePic: "",
      tempSize: 16,
    };
  }

  checkUssername = async username => {
    this.setState({
      typingUsername: true
    });
    return await this.ValidateUsername(username);
  };

  ValidateUsername = username => {
    if (!expr.test(username)) {
      let msg =
        "Only Alphabets, Numbers and Underscore and between 3 to 16 characters.";
      this.setState({
        errorMessage: msg
      });
    } else {
      this.setState({
        UserNameData: username,
        errorMessage: "",
        errorForm: false
      });
    }
  };

  formSubmit = async () => {

    let __self = this;
    await applloClient
      .query({
        query: CheckUsernameMutation,
        variables: {
          username: this.state.UserNameData
        },
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        if (res) {
          if (!res.data.username_check.status.success) {
            __self.setState({
              errorMessage: res.data.username_check.status.user_msg
            });
          } else {
            let UserName = __self.state.UserNameData;
            let newUser = {}
            if (this.props.getGoogleLogin) {
              newUser = {
                UserPassword: "",
                UserName: UserName,
                UserFirstName: "",
                UserLastName: "",
                UserEmail: ""
              }
              this.checkOutnextStep()
            }
            else {
              newUser = {
                UserPassword: "",
                UserName: UserName,
                UserFirstName: "",
                UserLastName: "",
                UserEmail: ""
              }
            };
            setLocalStorage("newUserFirebase", JSON.stringify(newUser)).then(
              async () => {
                (await AsyncStorage.getItem("skipCredentials")) == "true"
                  ? this.props.navigation.navigate("verification")
                  : this.props.setUsernameModalStatus(false);
                if (this.props.getGoogleLogin) {
                  this.props.setMessageModalStatus(true)
                } else {
                  this.props.setSignUpModalStatus(true);
                }

              }
            );
          }
        }
      })
      .catch(e => console.log(e));

  };



  componentDidMount = () => {

    this.setState(
      {
        clikName:
          this.props.inviteUserDetails &&
          this.props.inviteUserDetails.get("clikName"),
        inviteKey:
          this.props.inviteUserDetails &&
          this.props.inviteUserDetails.get("inviteKey"),
        userName:
          this.props.inviteUserDetails &&
          this.props.inviteUserDetails.get("userName")
      }
    );
    if (
      this.props.inviteUserDetails &&
      this.props.inviteUserDetails.get("inviteKey") != ""
    ) {
      this.getKeyProfile(
        this.props.inviteUserDetails &&
        this.props.inviteUserDetails.get("inviteKey")
      );
    }
    if (
      this.props.inviteUserDetails &&
      this.props.inviteUserDetails.get("userName") != ""
    ) {
      this.setState({
        inviteUsername:
          this.props.inviteUserDetails &&
          this.props.inviteUserDetails.get("userName")
      });
      this.getUserPic(
        this.props.inviteUserDetails &&
        this.props.inviteUserDetails.get("userName")
      );
    }
    this.props.setUsernameModalStatus(true);
    if (this.props.getGoogleLogin) {
      AsyncStorage.getItem("googleEmail").then(email => {
        this.setState({
          googleEmail: email ? email : ""
        });
      });
    }
  };

  checkOutnextStep = async () => {
    let __self = this;
    __self.props.setCreateAccount({
      username: __self.state.UserNameData,
      email: __self.state.googleEmail,
      password: __self.state.googleEmail,
      first_name: "",
      last_name: ""
    });
    AccountCreateVariables.variables.email =
      __self.state.UserEmail;
    AccountCreateVariables.variables.username =
      __self.state.UserNameData;
    AccountCreateVariables.variables.clik_invite_key = ""
    AccountCreateVariables.variables.inviter_username = "";
    return await applloClient
      .query({
        query: AccountCreateMutation,
        ...AccountCreateVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        if (res) {
          new Promise(async (resole, reject) => {
            if (res.data.account_create.status.success) {
              let id =
                res.data.account_create.account.my_users[0].id;
              await AsyncStorage.setItem("userLoginId", id);
              await AsyncStorage.setItem("MyUserUserId", id);
              await AsyncStorage.setItem("UserId", id);
              resole(res);
            }
          })
            .then(async () => {
              __self.props.setSignUpModalStatus(false);
              __self.props.setMessageModalStatus(true);
              loginResult = await applloClient
                .query({
                  query: UserLoginMutation,
                  variables: {
                    id: "Account:" + "CurrentUser"
                  },
                  fetchPolicy: "no-cache"
                }).then(res => {
                  return res
                })
              return loginResult
            })
            .then(async res => {
              await this.props.setAnonymousUser({
                value: false,
                token: ""
              }),
                await AsyncStorage.setItem(
                  "userLoginId",
                  res.data.node.id
                );
              await AsyncStorage.setItem(
                "MyUserUserId",
                res.data.node?.user?.id
                  ? res.data.node.user.id
                  : ""
              );
              await AsyncStorage.setItem(
                "UserId",
                res.data.node?.user?.id
                  ? res.data.node.user.id
                  : ""
              );
              await __self.props.getTrendingUsers({
                currentPage: AppHelper.PAGE_LIMIT
              });
              await __self.props.getTrendingClicks({
                currentPage: AppHelper.PAGE_LIMIT
              });
              await __self.props.getTrendingTopics({
                currentPage: AppHelper.PAGE_LIMIT
              });
              await __self.props.getTrendingExternalFeeds({
                currentPage: AppHelper.PAGE_LIMIT
              });
              await __self.props.saveLoginUser(res.data.node);
              await __self.props.changeLoginStatus(1);
            });
        }
      });

  };

  getKeyProfile = async key => {
    try {
      await applloClient
        .query({
          query: InviteKeyClikProfileMutation,
          variables: {
            invite_key: key
          },
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          this.setState({
            InviteClik: res.data.clik_invite_key,
            inviteUsername: res.data.clik_invite_key.inviter.username,
            inviterProfilePic: res.data.clik_invite_key.inviter.profile_pic
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  getUserPic = async key => {
    try {
      await applloClient
        .query({
          query: UserQueryMutation,
          variables: {
            id: "User:" + key
          },
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          this.setState({
            inviterProfilePic: res.data.user.profile_pic
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <View
        style={{
          backgroundColor: "#f4f4f4",
          borderColor: "#c5c5c5",
          borderRadius: 6,
          width: Dimensions.get('window').width >= 750 ? 450 : '100%',
          height: 480,
        }}
      >
        <View>
          <Hoverable>
            {isHovered => (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flex: 1,
                  position: "absolute",
                  zIndex: 999999,
                  left: 0,
                  top: 0
                }}
                onPress={this.props.onClose}
              >
                <Icon
                  color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  reverse
                  name="close"
                  type="antdesign"
                  size={16}
                />
              </TouchableOpacity>
            )}
          </Hoverable>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "#000",
              alignItems: "center",
              height: 50,
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
              width: "100%"
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Image
                source={require("../assets/image/logolastOne.png")}
                style={{
                  justifyContent: "flex-start",
                  height: 35,
                  width: 35,
                  marginRight: 5,
                  borderRadius: 8
                }}
                resizeMode={"contain"}
              />
              {
                <Text
                  style={[ButtonStyle.profileTitleStyle, {
                    fontSize: 20,
                    textAlign: "center",
                    color: "white",
                    fontWeight: 'bold'
                  }]}
                >
                  weclikd
                </Text>
              }
            </View>
          </View>
        </View>

        <View
          style={{
            alignItems: "center",
            flex: 1,
            padding: 20,
            backgroundColor: "#fff",
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            borderColor: "#c5c5c5"
          }}
        >
          <View
            style={{
              alignItems: "center",
              marginTop: 10,
              marginHorizontal: 20
            }}
          >
            {this.props.inviteUserDetails &&
              !this.props.getGoogleLogin &&
              this.props.inviteUserDetails.get("clikName") === "" &&
              this.props.inviteUserDetails &&
              this.props.inviteUserDetails.get("inviteKey") === "" &&
              this.props.inviteUserDetails &&
              this.props.inviteUserDetails.get("userName") === "" && (
                <View>
                  <View style={{ flexDirection: 'row' }}>

                    <Image source={require("../assets/image/speech-bubble.png")} style={{ height: 20, width: 20, marginRight: 10, transform: [{ rotateY: '180deg' }] }} />
                    <Text style={[styles.StaticTextStyle, { fontFamily: ConstantFontFamily.HeaderBoldFont }]}>
                      {" "}
                      engage in quality discussions
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    <Image source={require("../assets/image/eye.png")} style={{ height: 20, width: 20, marginRight: 10, transform: [{ rotateY: '180deg' }] }} />
                    <Text style={[styles.StaticTextStyle, { fontFamily: ConstantFontFamily.HeaderBoldFont }]}>
                      {" "}gain visibility for your content
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    <Image source={require("../assets/image/money-tag.png")} style={{ height: 20, marginRight: 10, width: 20 }} />
                    <Text style={[styles.StaticTextStyle, { fontFamily: ConstantFontFamily.HeaderBoldFont }]}>
                      {" "}
                      monetize your posts and comments
                    </Text>
                  </View>
                </View>
              )}
          </View>
          {this.props.getGoogleLogin &&
            <Text style={[ButtonStyle.profileTitleStyle, { fontSize: 16 }]}>To continue browsing, please sign up for an account.</Text>
          }
          {
            this.props.inviteUserDetails &&
              !this.props.getGoogleLogin &&
              this.props.inviteUserDetails.get("inviteKey") != "" ?
              (
                <View>
                  {this.state.inviterProfilePic ? (
                    <Image
                      source={{
                        uri: this.state.inviterProfilePic
                      }}
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: 30,
                        borderWidth: 1,
                        borderColor: "#e1e1e1",
                        alignSelf: "center",
                        marginBottom: 10
                      }}
                    />
                  ) : (
                    <Image
                      source={require("../assets/image/default-image.png")}
                      style={{
                        width: 60,
                        height: 60,
                        borderRadius: 30,
                        borderWidth: 1,
                        borderColor: "#e1e1e1",
                        alignSelf: "center",
                        marginBottom: 10
                      }}
                    />
                  )}
                  <Text style={[styles.StaticTextStyle, { marginBottom: 10 }]}>
                    @{this.state.inviteUsername} has invited you to join
                  </Text>
                </View>
              ) : null}

          {this.props.inviteUserDetails &&
            !this.props.getGoogleLogin &&
            this.props.inviteUserDetails.get("userName") != "" ? (
            <View>
              {this.state.inviterProfilePic ? (
                <Image
                  source={{
                    uri: this.state.inviterProfilePic
                  }}
                  style={{
                    width: 60,
                    height: 60,
                    borderRadius: 30,
                    borderWidth: 1,
                    borderColor: "#e1e1e1",
                    alignSelf: "center",
                    marginBottom: 10
                  }}
                />
              ) : (
                <Image
                  source={require("../assets/image/default-image.png")}
                  style={{
                    width: 60,
                    height: 60,
                    borderRadius: 30,
                    borderWidth: 1,
                    borderColor: "#e1e1e1",
                    alignSelf: "center",
                    marginBottom: 10
                  }}
                />
              )}
              <Text style={[styles.StaticTextStyle, { marginBottom: 10 }]}>
                @
                {this.props.inviteUserDetails &&
                  this.props.inviteUserDetails.get("userName")}{" "}
                has invited you to join Weclikd
              </Text>
            </View>
          ) : null}

          {this.props.inviteUserDetails &&
            !this.props.getGoogleLogin &&
            this.props.inviteUserDetails.get("clikName") != "" && (
              <View>
                {this.props.inviteUserDetails &&
                  this.props.inviteUserDetails.get("inviteKey") == "" && (
                    <Text
                      style={[styles.StaticTextStyle, { marginBottom: 10 }]}
                    >
                      You were invited to join
                    </Text>
                  )}
                <View
                  style={{
                    width: 150,
                    backgroundColor: "#E8F5FA",
                    alignItems: "center",
                    justifyContent: "center",
                    borderWidth: 1,
                    borderColor: "#c5c5c5",
                    borderRadius: 10,
                    height: 30,
                    marginBottom: 20
                  }}
                >
                  <Text
                    style={{
                      color: "#4169e1",
                      fontSize: 15,
                      fontWeight: "bold",
                      fontFamily: ConstantFontFamily.defaultFont
                    }}
                  >
                    #
                    {this.props.inviteUserDetails &&
                      this.props.inviteUserDetails.get("clikName")}
                  </Text>
                </View>
              </View>
            )}

          <View
            style={{
              width: "100%",
              paddingTop: 10,
              paddingBottom: 10
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
                height: 30
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 10,
                  fontFamily: ConstantFontFamily.defaultFont,
                  color: "red"
                }}
              >
                {this.state.errorMessage}
              </Text>
            </View>

            <Text
              style={{
                fontWeight: "bold",
                fontSize: 16,
                fontFamily: ConstantFontFamily.defaultFont
              }}
            >
              Choose a username
            </Text>
            <View
              style={{
                marginTop: 5,
                flexDirection: "row",
                height: 45,
                alignItems: "center",
                width: "100%"
              }}
            >
              <TextInput
                testID="AddUsername"
                onChangeText={UserName => this.checkUssername(UserName)}
                onFocus={() => this.setState({ tempSize: 16 })}
                placeholder="What should we call you?"
                underlineColorAndroid="transparent"
                style={[ButtonStyle.textAreaShadowStyle, {
                  height: 45,
                  paddingLeft: 10,
                  color: "#000",
                  fontFamily: ConstantFontFamily.defaultFont,
                  width: "100%",
                  fontSize: this.state.tempSize
                }]}
              />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "flex-end"
                }}
              >
                {this.state.typingUsername == true ? (
                  this.state.errorMessage == "" ? (
                    <Icon
                      size={20}
                      name="check"
                      type="font-awesome"
                      iconStyle={{ marginRight: 10 }}
                      color="#009B1A"
                      underlayColor="#000"
                    />
                  ) : (
                    <Icon
                      size={20}
                      name="close"
                      type="font-awesome"
                      iconStyle={{ marginRight: 10 }}
                      color="#de5246"
                      underlayColor="#000"
                    />
                  )
                ) : (
                  <Icon
                    size={20}
                    name="close"
                    type="font-awesome"
                    iconStyle={{ marginRight: 10 }}
                    color="#de5246"
                    underlayColor="#000"
                  />
                )}
              </View>
            </View>
            <View
              style={{
                marginBottom: 20,
                marginTop: 5,
                flexDirection: "row",
                justifyContent: 'flex-end'
              }}
            >
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 12,
                  color: "#49525D",
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Already have an account? &nbsp;
                <Text
                  style={{
                    textDecorationLine: "underline",
                    color: "#49525D",
                    fontSize: 12,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                  onPress={() => {
                    this.props.setLoginModalStatus(true);
                  }}
                >
                  Login
                </Text>
              </Text>
            </View>

            <View
              style={{
                alignItems: "center"
              }}
            >
              <Button
                testID='checkUsername'
                title="Sign up"
                titleStyle={ButtonStyle.titleStyle}
                buttonStyle={ButtonStyle.backgroundStyle}
                containerStyle={ButtonStyle.containerStyle}
                disabled={this.state.errorForm ? true : false}
                onPress={this.formSubmit}
              />
            </View>
          </View>

          <View
            style={{
              width: "100%",
              marginTop: 10,
              alignItems: 'flex-start',
              flexDirection:'row',
              paddingLeft:10
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: "#49525D",
                fontFamily: ConstantFontFamily.defaultFont,
                textAlign: "center"
              }}
            >
              By signing up, you agree to the{" "}
            </Text>

            <Text
              style={{
                fontSize: 12,
                color: "#49525D",
                fontFamily: ConstantFontFamily.defaultFont,
                textAlign: "center"
              }}
            >
              <Text
                style={{
                  textDecorationLine: "underline"
                }}
                onPress={() => {
                  this.props.onClose();
                  NavigationService.navigate("termsandconditions");
                }}
              >
                Terms of service
              </Text>
              <Text>{" "} and {" "}</Text>

              <Text
                style={{
                  textDecorationLine: "underline"
                }}
                onPress={() => {
                  this.props.onClose();
                  NavigationService.navigate("privacyPolicy");
                }}
              >
                Privacy Policy.
              </Text>
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  inviteUserDetails: state.AdminReducer.get("inviteUserDetails"),
  getGoogleLogin: state.AdminReducer.get("googleLogin"),
});

const mapDispatchToProps = dispatch => ({
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  setSignUpModalStatus: payload => dispatch(setSIGNUPMODALACTION(payload)),
  setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload)),
  setCreateAccount: payload => dispatch(setCreateAccount(payload)),
  getTrendingUsers: payload => dispatch(getTrendingUsers(payload)),
  getTrendingTopics: payload => dispatch(getTrendingTopics(payload)),
  getTrendingClicks: payload => dispatch(getTrendingClicks(payload)),
  getTrendingExternalFeeds: payload =>
    dispatch(getTrendingExternalFeeds(payload)),
  setAnonymousUser: payload => dispatch({ type: "ANONYMOUS_USER", payload }),
  setMessageModalStatus: payload =>
    dispatch({ type: "MESSAGEMODALSTATUS", payload }),
});

const AddUsernameCardContainerWrapper = compose(
  graphql(UserLoginMutation, {
    name: "Login",
    options: { fetchPolicy: "no-cache" }
  }),
  graphql(AccountCreateMutation, {
    name: "UserCreate"
  })
)(AddUsernameCard);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUsernameCardContainerWrapper);

export const styles = {
  StaticTextStyle: {
    textAlign: "center",
    fontSize: 15,
    fontFamily: ConstantFontFamily.defaultFont
  },
};
