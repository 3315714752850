import firebase from "firebase/app";
import "firebase/auth";
import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import {  Image, Text, TouchableOpacity, View, Dimensions } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { connect } from "react-redux";
import { setAdminStatus } from "../actionCreator/AdminAction";
import { setCreateAccount } from "../actionCreator/CreateAccountAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import { setLoginStatus } from "../actionCreator/UserAction";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import { setVERIFYEMAILMODALACTION } from "../actionCreator/VerifyEmailModalAction";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
import { getLocalStorage, setLocalStorage } from "../library/Helper";
import SidePanel from './SidePanel';
import NavigationService from "../library/NavigationService";

const apiUrl = getEnvVars();

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "unsend"
    };
  }

  formSubmit = async () => {
    let __self = this;
    let actionCodeSettings = {
      url: apiUrl.APPDYNAMICLINK,
      handleCodeInApp: true
    };
    await firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(async function (idToken) {
        return await getLocalStorage("userIdTokenFirebase").then(async res => {
          await setLocalStorage("userIdTokenFirebase", idToken);
          await setLocalStorage(
            "admin",
            jwt_decode(idToken).claims.admin ? "true" : "false"
          );
          await __self.props.changeAdminStatus(
            jwt_decode(idToken).claims.admin ? jwt_decode(idToken).claims.admin : false
          );
          return idToken;
        });
      })
      .then(async res => {
        if (res) {
          await firebase
            .auth()
            .currentUser.sendEmailVerification(actionCodeSettings)
            .then(res => {
              AsyncStorage.removeItem("userIdTokenFirebase");
              __self.props.setCreateAccount({
                username: "",
                email: "",
                password: "",
                first_name: "",
                last_name: ""
              });
              this.setState({
                status: "resend"
              });
              //__self.props.setVerifyEmailModalStatus(false);
            })
            .catch(e => {
              // __self.props.setVerifyEmailModalStatus(false);
              if (e.code == "auth/too-many-requests") {
                alert(e.message);
              } else {
                console.log(e);
              }
            });
        }
      });
  };

  render() {
    return (
      <View style={{ justifyContent:'space-between',
      height: "100%",flexDirection:'row'}}>
      <View style ={{width:Dimensions.get("window").width - 550}}>
      <SidePanel
        ref={navigatorRef => {
          NavigationService.setTopLevelNavigator(navigatorRef);
        }}
        navigation={NavigationService}
      /></View>
      <View
        style={{
          maxWidth: 550,
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          paddingVertical: 60,
          paddingHorizontal: '10%',
        }}
      >

        <View
          style={{
            overflow: "visible",
            width: "100%",
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center",
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row"
            }}
          >
            <Image
              source={require("../assets/image/logo.png")}
              style={{
                height: 60,
                width: 60
              }}
              resizeMode={"contain"}
            />
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 45,
                fontFamily: ConstantFontFamily.defaultFont
              }}
            >
              {" "}
              weclikd
            </Text>
          </View>


        </View>


        <View style={{ marginTop: 30 }}>
          {this.state.status == "unsend" && (
            <View>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Please verify your email.
                  </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Check your inbox email for Weclikd's email verification.
                  </Text>
            </View>
          )}

          {this.state.status == "resend" && (
            <View>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Email has been resent.
                  </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Check your inbox email for Weclikd's email verification.
                  </Text>
            </View>
          )}

          <TouchableOpacity
            block
            style={{
              marginTop: 20,
              backgroundColor: "#fff",
              borderRadius: 10,
              height: 35,
              alignItems: "center",
              justifyContent: "center",
              padding: 15,
              width: 150,
              alignSelf: "center",
              borderWidth: 1,
              borderColor: '#000'
            }}
            onPress={this.formSubmit}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 16,
                fontFamily: ConstantFontFamily.defaultFont,
                color: "#000"
              }}
            >
              Resend Email
                </Text>
          </TouchableOpacity>
        </View>


        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingHorizontal: '5%',
            width: '100%'
          }}
        >
          <TouchableOpacity
            block
            style={{
              marginTop: 20,
              backgroundColor: "#000",
              borderRadius: 10,
              height: 45,
              alignItems: "center",
              justifyContent: "center",
              padding: 5,
              width: 100
            }}
            onPress={() => {
              let nav = this.props.navigation.dangerouslyGetParent()
                .state;
              if (nav.routes.length > 1) {
                this.props.navigation.goBack();
                return;
              } else {
                this.props.navigation.navigate("home");
              }
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 16,
                fontFamily: ConstantFontFamily.defaultFont,
                color: "#fff"
              }}
            >
              Back
                  </Text>
          </TouchableOpacity>

          <TouchableOpacity
            block
            style={{
              marginTop: 20,
              backgroundColor: "#000",
              borderRadius: 10,
              height: 45,
              alignItems: "center",
              justifyContent: "center",
              padding: 5,
              width: 100
            }}
            onPress={() => {
              //this.props.setVerifyEmailModalStatus(false),
              // this.props.SignUpFollowModalStatus(true);
              this.props.setUsernameModalStatus(true);
              this.props.navigation.navigate("join");
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 16,
                fontFamily: ConstantFontFamily.defaultFont,
                color: "#fff"
              }}
            >
              Next
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{ position: 'absolute', bottom: '5%', width: '100%' }}>
          <View style={{ alignItems: 'center', width: '100%' }}>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 10,
                fontFamily: ConstantFontFamily.defaultFont,
                textAlign: "center"
              }}
            >
              By clicking next, you agree to our{" "}
            </Text>

            <Text
              style={{
                fontWeight: "bold",
                fontSize: 10,
                fontFamily: ConstantFontFamily.defaultFont,
                textAlign: "center"
              }}
            >
              <Text
                style={{
                  textDecorationLine: "underline"
                }}
                onPress={() => {
                  this.props.onClose();
                  NavigationService.navigate("termsandconditions");
                }}
              >
                Terms
                </Text>
              <Text>
                {" "}
                  and that you have read our{" "}
                <Text
                  style={{
                    textDecorationLine: "underline"
                  }}
                  onPress={() => {
                    this.props.onClose();
                    NavigationService.navigate("privacyPolicy");
                  }}
                >
                  Privacy Policy
                  </Text>{" "}
                  and{" "}
                <Text
                  style={{
                    textDecorationLine: "underline",fontFamily: ConstantFontFamily.defaultFont
                  }}
                  onPress={() => {
                    this.props.onClose();
                    NavigationService.navigate("privacyPolicy");
                  }}
                >
                  Content Policy
                  </Text>
                  .
                </Text>
            </Text>
          </View>
        </View>
      </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  getCreateAccount: state.CreateAccountReducer.get("setCreateAccountData"),
  getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension")
});

const mapDispatchToProps = dispatch => ({
  setVerifyEmailModalStatus: payload =>
    dispatch(setVERIFYEMAILMODALACTION(payload)),
  setSignUpModalStatus: payload => dispatch(setSIGNUPMODALACTION(payload)),
  setCreateAccount: payload => dispatch(setCreateAccount(payload)),
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  changeAdminStatus: payload => dispatch(setAdminStatus(payload)),
  SignUpFollowModalStatus: payload =>
    dispatch({ type: "SIGNUP_FOLLOW_MODAL", payload }),
  setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);