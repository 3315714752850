import {FIXTAB_SUCCESS} from '../constants/Action';


const initialState ={
    index:false,
  
}

const FixTabReducer = (state = initialState, action) => {
    switch (action.type) {
      case FIXTAB_SUCCESS: {
        return {
            index: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default FixTabReducer;