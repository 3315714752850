import React, { lazy, Suspense, Component } from "react";
import { retry } from "../library/Helper";
import {
  Dimensions,
  Image,
  Platform,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import ConstantFontFamily from "../constants/FontFamily";
import ConstantColors from '../constants/Colors';
import NavigationService from "../library/NavigationService";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import ButtonStyle from "../constants/ButtonStyle";
import { Button } from "react-native-elements";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";

const DrawerScreen = lazy(() =>
  retry(() => import("./DrawerScreens"))
);

const OurMission = lazy(() =>
  retry(() => import("./OurMission"))
);

const TrendingTabs = lazy(() =>
  retry(() => import("./TrendingTabs"))
);

class LeftPanel extends Component {
  totalHeigth = 0;
  constructor(props) {
    super(props);
    this.state = {
      getHeight: 100,
      randomItemEvent: 0,
      primaryIndex: 0,
      isSearched: false,
      searchedWord: "",
      isActive: false,
      searchedFollowText: ""
    };
  }

  calHeightLeftPannelMian = height => {
    if (height > 0 && height != null) {
      this.totalHeigth = height;
    }
  };

  activeIndexAction = index => {
    this.setState({
      primaryIndex: index,
      searchedWord: ""
    });
  };

  activeTrendingIndexAction = index => {
    this.setState({
      searchedFollowText: ""
    });
  };





  searchParaChanged = () => {
    this.setState({ searchedWord: "" });
  };

  searchTextField = value => {
    this.setState({ searchedWord: value });
  };

  componentDidMount = async () => {
    var newWidth = window.innerWidth;
    var newHeight = window.innerHeight;
    this.props.setBrowserHeightWidth({ heigh: newHeight, width: newWidth });
    window.addEventListener("resize", event => {
      var newWidth = window.innerWidth;
      var newHeight = window.innerHeight;
      this.props.setBrowserHeightWidth({ heigh: newHeight, width: newWidth });
    });
  };

  loginHandle = () => {
    this.props.leftPanelModalFunc(false)
    this.props.setLoginModalStatus(true);
  };

  inviteSignHandle = async () => {
    await this.props.leftPanelModalFunc(false)
    await this.props.setInviteUserDetail({
      clikName: "",
      inviteKey: "",
      userName: ""
    });
    await this.props.setUsernameModalStatus(true);
  };


  goToUserProfile = async username => {
    console.log(username, 'username');
    await this.props.userId({
      username: username,
      type: "feed"
    });
    await NavigationService.navigate("profile", {
      username: username,
      type: "feed",
      postId: ' '
    });
    this.props.leftPanelModalFunc(false)
  };

  goToPage = (pageName) => {
    this.props.leftPanelModalFunc(false)
    if (pageName == 'terms') {
      NavigationService.navigate("termsandconditions");
    } else if (pageName == 'privacy') {
      NavigationService.navigate("privacyPolicy");
    } else if (pageName == 'faq') {
      NavigationService.navigate("faq");
    }
  }
  render() {
    return (
      <View
        style={[{
          flexDirection: "column",
          width: Dimensions.get("window").width <= 750 ? '100%' : 310,
          backgroundColor: Dimensions.get("window").width >= 1200 ? ConstantColors.greyBackground : '#fff',
          // marginLeft: Dimensions.get("window").width > 1200 ? 15 : 0,
          // height: Dimensions.get("window").width <= 750 ? '100%' :'100%',
          marginBottom: 0, 
          paddingRight:Dimensions.get("window").width >= 750 ? 5 : 0,
          backgroundColor: '#fff'

        }]}
      >
        {Dimensions.get("window").width <= 750 &&
          <View >
            <Hoverable>
              {isHovered => (
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    flex: 1,
                    position: "absolute",
                    zIndex: 999999,
                    left: 0,
                    top: 0
                  }}
                  onPress={() => this.props.leftPanelModalFunc(false)}
                >
                  <Icon
                    color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    reverse
                    name="close"
                    type="antdesign"
                    size={16}
                  />
                </TouchableOpacity>
              )}
            </Hoverable>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                backgroundColor: "#000",
                alignItems: "center",
                height: 50,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                width: "100%"
              }}
            >
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <Image
                  source={require("../assets/image/logolastOne.png")}
                  style={{
                    justifyContent: "flex-start",
                    height: 35,
                    width: 35,
                    marginRight: 5,
                    borderRadius: 8
                  }}
                  resizeMode={"contain"}
                />

                <Text
                  style={[ButtonStyle.profileTitleStyle, {
                    fontSize: 20,
                    textAlign: "center",
                    color: "white",
                    fontWeight: 'bold'
                  }]}
                >
                  weclikd
                </Text>

              </View>
            </View>
          </View>
        }
             
          {/* <View
            style={[
              {
                height: Dimensions.get("window").width <= 750 ? hp("15%") : '20%',
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: '#E8F5FA',
                paddingVertical: 20,
                paddingHorizontal: 20,
              }
            ]}
          >
            <Suspense fallback={null}>

              <OurMission />
            </Suspense>
          </View> */}

          {/* {this.props.loginStatus == 1 && Dimensions.get("window").width <= 750 && (
            <Hoverable>
              {isHovered => (
                <TouchableOpacity
                  onPress={() =>
                    this.goToUserProfile(
                      this.props.profileData &&
                      this.props.profileData.user.username
                    )
                  }
                >
                  <View
                    style={
                      {
                        height: hp("15%"),
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "transparent",
                        marginTop: 10,
                        paddingVertical: 30,
                        paddingHorizontal: 20,
                        // backgroundColor: ConstantColors.whiteBackground,

                      }
                    }
                  >
                    {this.props.profileData
                      &&
                      this.props.profileData.user.profile_pic
                      ? (
                        <Image
                          source={{
                            uri: this.props.profileData.user.profile_pic
                          }}
                          style={{
                            width: 70,
                            height: 70,
                            borderRadius: 35,
                            borderWidth: 1,
                            borderColor: "#e1e1e1",
                            marginRight: 5
                          }}
                        />
                      ) : (
                        <Image
                          source={require("../assets/image/default-image.png")}
                          style={{
                            width: 70,
                            height: 70,
                            borderRadius: 35,
                            borderWidth: 1,
                            borderColor: "#e1e1e1",
                            marginRight: 5
                          }}
                        />
                      )}

                    <Text
                      style={{
                        color: "#000",
                        fontSize: 22,
                        fontFamily: ConstantFontFamily.defaultFont,
                        textDecorationLine:
                          isHovered == true ? "underline" : "none",
                        position: "relative",
                        top: 8,
                        fontWeight: 'bold',
                        marginRight: 5
                      }}
                    >
                      {this.props.profileData &&
                        "@" +
                        this.props.profileData.user.username}
                    </Text>
                  </View>

                </TouchableOpacity>
              )}
            </Hoverable>
          )} */}

          {this.props.loginStatus != 1 && Dimensions.get("window").width <= 750 &&
            <View style={{ alignItems: 'center' }}>
              <View>
                {this.props.loginStatus == 0 && (
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >

                    <Button
                      title="Log In"
                      titleStyle={{
                        fontSize: 14,
                        fontFamily: ConstantFontFamily.defaultFont,
                        color: "#fff",
                      }}
                      buttonStyle={{
                        backgroundColor: "#000",
                        borderColor: Dimensions.get('window').width <= 750 ? "#f4f4f4" : "#fff",
                        borderRadius: 6,
                        borderWidth: 1,
                        alignSelf: "center",
                        width: 120,
                        paddingHorizontal: 20,
                        paddingVertical: 5,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      containerStyle={{
                        alignSelf: "center",
                        marginRight: 10,
                      }}
                      testID="welcome"
                      onPress={this.loginHandle}
                    />

                    <Button
                      title="Sign Up"
                      titleStyle={{
                        fontSize: 14,
                        fontFamily: ConstantFontFamily.defaultFont,
                        color: "#000",
                      }}
                      buttonStyle={{
                        backgroundColor: "#fff",
                        borderColor: Dimensions.get('window').width <= 750 ? "#000" : "#fff",
                        borderRadius: 6,
                        borderWidth: 1,
                        alignSelf: "center",
                        width: 120,
                        paddingVertical: 5

                      }}
                      containerStyle={{
                        alignSelf: "center",

                      }}
                      onPress={() => this.inviteSignHandle()}
                    />

                  </View>
                )}
              </View>
            </View>}
          <View
            style={
              {
                // paddingHorizontal: 2,
                height: Dimensions.get("window").width <= 750 ? hp('95%') : this.props.windowSize.height-50 ,
                // paddingTop: 10,
                // paddingBottom: Dimensions.get("window").width <= 1200 && Platform.OS == 'web' ? 20 : 0,

              }
            }
          >
            {this.props.loginStatus == 1 ? (
              <Suspense fallback={null}>
                <DrawerScreen
                  calHeightLeftPannelSend={this.calHeightLeftPannelMian}
                  navigation={this.props.navigation}
                  activeIndex={this.activeIndexAction}
                  searchParam={this.searchParaChanged}
                  searchedWord={this.state.searchedWord}
                />
              </Suspense>
            ) : (
              <Suspense fallback={null}>
                <TrendingTabs
                  navigation={this.props.navigation}
                  randomItemEvent={this.state.randomItemEvent}
                  searchedFollowText={this.state.searchedWord}
                  activeIndex={this.activeTrendingIndexAction}
                  onchangetext={query => this.searchTextField(query)}
                  onpress={() => this.setState({ searchedFollowText: "" })}
                />
              </Suspense>
            )}
          </View>

        {/* </View> */}

      </View>
    );
  }
}

const mapStateToProps = state => ({
  loginStatus: state.UserReducer.get("loginStatus"),
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  windowSize: state.windowResizeReducer.windowResize

});

const mapDispatchToProps = dispatch => ({
  userId: payload => dispatch(getCurrentUserProfileDetails(payload)),
  setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  setBrowserHeightWidth: payload =>
    dispatch({ type: "SET_BROWSER_HEIGHT_WIDTH", payload }),
  leftPanelModalFunc: payload => dispatch({ type: 'LEFT_PANEL_OPEN', payload }),
  setInviteUserDetail: payload =>
    dispatch({ type: "SET_INVITE_USER_DETAIL", payload }),
  setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(LeftPanel);