import { FIXINDEX_SUCCESS} from '../constants/Action';

const initialState = {
    index1: 0
}

const FixIndexReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIXINDEX_SUCCESS: {
            return {
                index1: action.payload,
            };
        }
        default:
            return state;
    }
};

export default FixIndexReducer;