import { ADDTERM_SUCCESS } from '../constants/Action';


const initialState = {
  termArr: '',
}

const TermArrayReducer = (state = initialState, action) => {

  switch (action.type) {
    case ADDTERM_SUCCESS: {
      return {
        termArr: action.payload,
      };
    }
    default:
      return state;
  }
};

export default TermArrayReducer;