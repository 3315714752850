import { GETREPLIEDID } from '../constants/Action';

const initialState = {
    repliedId: '',
}

const RepliedIReducer  = (state = initialState, action) => {

    switch (action.type) {
        case GETREPLIEDID: {
            return {
                repliedId: action.payload,
            };
        }
        default:
            return state;
    }
};

export default RepliedIReducer;