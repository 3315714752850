import { fromJS } from "immutable";
import {
  TRENDINGEXTERNALFEEDS_SUCCESS,
  TRENDINGEXTERNALFEEDS_FAILURE
} from "../constants/Action";

const initialState = fromJS({
  Trending_ExternalFeeds: null
});

export default function TrendingExternalFeedsReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case TRENDINGEXTERNALFEEDS_SUCCESS:
      return state.set("Trending_ExternalFeeds", payload);
    case TRENDINGEXTERNALFEEDS_FAILURE:
      return initialState;
  }
  return state;
}
