import React, { Component } from "react";
import { StyleSheet, Text, View, Dimensions } from "react-native";
import { connect } from "react-redux";
import { compose } from "recompose";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import ConstantFontFamily from "../constants/FontFamily";

class SignUpScreen extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.setInviteUserDetail({
      clikName: "",
      inviteKey: "",
      userName: this.props.navigation.getParam("username", "")
    });
    this.props.setUsernameModalStatus(true);
    this.props.navigation.navigate("home");
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={[styles.text,{fontFamily: ConstantFontFamily.defaultFont}]}>- Welcome to SignUp Screen -</Text>
      </View>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload)),
  setInviteUserDetail: payload =>
    dispatch({ type: "SET_INVITE_USER_DETAIL", payload })
});

export default compose(connect(null, mapDispatchToProps))(SignUpScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: Dimensions.get("window").height,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2896d3"
  },
  text: {
    fontSize: 24,
    marginBottom: 60,
    color: "#fff",
    fontWeight: "bold"
  }
});
