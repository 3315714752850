import { fromJS } from 'immutable';
import {
    CURRENTDEVICEWIDTHACTION_SUCCESS, CURRENTDEVICEWIDTHACTION_FAILURE
} from '../constants/Action';


const initialState = fromJS({
    dimension: 0,
});

export default function CurrentDeviceWidthReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CURRENTDEVICEWIDTHACTION_SUCCESS:
            return state
                .set('dimension', fromJS(payload));
        case CURRENTDEVICEWIDTHACTION_FAILURE:
            return initialState;
    }
    return state;
}
