import { put, takeEvery } from 'redux-saga/effects';
import { 
  POSTLINK, 
  POSTLINK_FAILURE, 
  POSTLINK_SUCCESS,
  POST_EDIT_DETAILS,
  POST_EDIT_DETAILS_SUCCESS,
  POST_EDIT_DETAILS_FAILURE 
} from "../constants/Action";

export function* LinkPostMiddleware({ payload }) {
  try {
    yield put({
      type: POSTLINK_SUCCESS,
      payload: payload
    });
  } catch (err) {
    yield put({
      type: POSTLINK_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export function* PostEditDetailsMiddleware({ payload }) {
  try {
    yield put({
      type: POST_EDIT_DETAILS_SUCCESS,
      payload: payload
    });
  } catch (err) {
    yield put({
      type: POST_EDIT_DETAILS_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* LinkPostMiddlewareWatcher() {
  yield takeEvery(POSTLINK, LinkPostMiddleware);
  yield takeEvery(POST_EDIT_DETAILS, PostEditDetailsMiddleware);
}