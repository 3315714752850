import { fromJS } from 'immutable';
import {
    SCREENLOADINGMODALACTION_SUCCESS, SCREENLOADINGMODALACTION_FAILURE
} from '../constants/Action';


const initialState = fromJS({
    modalStatus: false,
    setTermWeb: '',
    setKeyEventWeb: '',
    setDisplayTypeWeb: {},
    setTerm: '',
});

export default function ScreenLoadingReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SCREENLOADINGMODALACTION_SUCCESS:
            return state
                .set('modalStatus', fromJS(payload));
        case SCREENLOADINGMODALACTION_FAILURE:
            return initialState;
        case "SET_TERM_WEB":
            return state.set("setTermWeb", fromJS(payload));
        case "SET_KEY_EVENT_WEB":
            return state.set("setKeyEventWeb", fromJS(payload));
        case "SET_DISPLAY_TYPE_WEB":
            return state.set("setDisplayTypeWeb", payload);
        case "SET_TERM":
            return state.set("setTerm", fromJS(payload));
    }
    return state;
}
